<template>
  <v-app>
    <loading :parentLoading="$store.getters.isLoading" />
    <app-header />
    <v-main :style="$route.path === '/list' ? 'padding-left:50vw' : ''">
      <cesium />
    </v-main>
    <router-view />
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="`${index+'_'+Date.now()}`"
      v-model="snackbar.showing"
      app
      :timeout="-1"
      top
      right
      :color="snackbar.color"
      rounded="pill"
      :style="`top: ${notification_top_list[index]}px;`"
      class="text-pre-wrap"
      :ref="snackbar.id"
    >
      {{snackbar.text}}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon @click="close_snackbar(snackbar)"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import Cesium from "./components/Cesium.vue";
import { EarthBrainViewer } from "earthbrain-viewer";
import config from "./config.json";
import axios from 'axios';
import { mapMutations, mapState } from 'vuex';
import Loading from '@/components/common/LoadingOverLay.vue';

export default {
  name: "App",
  components: {
    AppHeader,
    Cesium,
    Loading
  },
  async beforeCreate() {
    console.log('login')
    if (config.OAuth) {
      // Add a time stamp to the parameter to prevent unexpected behavior due to cache.
      let sendParams = {};
      sendParams.timestamp = new Date().getTime();
      try {
        const login = await axios.get(`${process.env.VUE_APP_API_BASE}/login/check`, {
                  auth: this.$store.state.authObject,
                  withCredentials: true,
                  params: sendParams
              })
        console.log(login)
        if (!login.data) {
          window.location.href = `${process.env.VUE_APP_API_BASE}/login`;
        } else {
          this.$store.dispatch("getUser")
        }
      } catch (error) {
        window.location.href = `${process.env.VUE_APP_API_BASE}/login`;
      }
    }
  },
  mounted() {

    window.onbeforeunload = function () {
      // clear cookie
      document.cookie = "login_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    };

    // ToDo use following client token:
    // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNDBhZDk4OS0xMjkzLTQ5YjEtOTUzMi03MDQ1N2E1ZWEyMDAiLCJpZCI6MjkwNTIsImlhdCI6MTY0NjAxNTYzOH0.Gj_ao_F4uAhdt2kORDyJxwoOC_tckqMqCQYKiy4VpV8

    var viewer = new EarthBrainViewer({
      cesiumContainer: "Cesium",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNDBhZDk4OS0xMjkzLTQ5YjEtOTUzMi03MDQ1N2E1ZWEyMDAiLCJpZCI6MjkwNTIsImlhdCI6MTY0NjAxNTYzOH0.Gj_ao_F4uAhdt2kORDyJxwoOC_tckqMqCQYKiy4VpV8",
      enableDesignDataOutline: process.env.VUE_APP_ENABLE_DESIGN_DATA_OUTLINE === 'true',
      enableSceneModePicker: process.env.VUE_APP_ENABLE_SCENE_MODE_PICKER === 'true'
    });
    window["viewer"] = viewer;

    this.$store.commit("set_viewer", viewer);

  },
  updated(){
    if(this.notification_top_list.length!=this.snackbars.length){
      this.notification_top_list = [];
      for(let i=0; i<this.snackbars.length; i++){
        let top = 0 ;
        for(let j=0; j<i; j++){
          top += this.$refs[this.snackbars[j].id][0].$el.firstChild.clientHeight + 10;
        }
        this.notification_top_list.push(top);
      }
    }
  },
  data: () => ({
    code: null,
    session_state: null,
    notification_top_list:[]
  }),
  computed: {
    lang: function () {
      return this.$store.getters["lang"];
    },
    ...mapState(['snackbars'])
  },
  watch: {
    lang() {
      this.$nextTick(() => {
        this.$i18n.locale = this.$store.state.lang;
      });
    }
  },
  methods:{
    ...mapMutations(['clear_snackbars']),
    close_snackbar(snackbar){
      snackbar.showing = false ;
      this.clear_snackbars();
    }
  }
};
</script>

<style></style>
