<template>
  <transition>
    <div class="dialog" :style="styles">
      <v-card style="position: absolute; width: 70%">
        <v-card-title class="pa-1 text-subtitle-1" style="color: rgba(255, 255, 255, 0.6)" @mousedown="startDrag">
          {{ $t(title) }}
        </v-card-title>
        <slot></slot>
        <div v-if="dialogIndrag" style="position: fixed; width:100vw; height: 100vh; top: 0; left: 0" @mousemove="drag"
          @mouseup="endDrag"></div>
      </v-card>
    </div>
  </transition>

</template>

<script>
export default {
  name: "DragableDialog",
  props: ["title"],
  mounted: () => {
  },
  data: () => ({
    dialogIndrag: false,
    sectionDialogX: 50,
    sectionDialogY: 50,
    dialoginitialLeft: 0,
    dialoginitialTop: 0,
    dragStartX: 0,
    dragStartY: 0,
  }),
  methods: {
    startDrag(e) {
      console.log("title", this.title);
      this.dialogIndrag = true;
      this.dragStartX = e.pageX;
      this.dragStartY = e.pageY;
      this.dialoginitialLeft = this.sectionDialogX;
      this.dialoginitialTop = this.sectionDialogY;
      if (e.target.id == "paper-view-1") {
        this.dialogIndrag = false;
      }
    },
    drag(e) {
      if (this.dialogIndrag) {
        this.sectionDialogX =
          this.dialoginitialLeft + e.pageX - this.dragStartX;
        this.sectionDialogY =
          this.dialoginitialTop + e.pageY - this.dragStartY;
      }
    },
    endDrag() {
      this.dialogIndrag = false;
    },
  },
  created: function () {
  },
  computed: {
    styles() {
      return {
        left: this.sectionDialogX + "px",
        top: this.sectionDialogY + "px",
        cursor: this.dialogIndrag ? "move" : "pointer"
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: absolute;
  width: 100vw;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 9999999;
  cursor: move;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 8px 16px;
  }

  &__close {
    cursor: pointer;
  }
}
</style>
