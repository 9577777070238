<template>
  <v-container
    id="cesiumViewer"
    fluid
    class="pa-0"
    :style="`height:calc(42vh - ${this.$vuetify.application.top}px - ${this.$vuetify.application.insetFooter}px); transition: all 0.2s; border: 1px solid #434348;`"
  >
  </v-container>
</template>

<script>
import "cesium/Build/Cesium/Widgets/widgets.css";

export default {
  name: "cesiumViewer",
  data: () => ({}),
  methods: {
  },
  mounted: () => {
    console.log("Local Cesium Container Initialized");
  },
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
