<!-- 編集処理ステータスを表示する -->
<template>
  <!-- 処理ステータス -->
  <div class="d-flex align-center">
    <!-- エラー -->
    <div v-show="selectIcon === icon.error">
      <v-tooltip 
        color="red"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            v-bind="attrs"
            v-on="on"
            class="process-error rounded-circle"
          >
            <v-icon
              class="process-error-icon"
              color="white"
              size="12px"
            >
              fa-exclamation
            </v-icon>
          </div>
        </template>
        <span class="white--text">{{ $t("UPLOAD_ERROR") }}</span>
      </v-tooltip>
    </div>
    <!-- 処理中 -->
    <div v-show="selectIcon === icon.processing">
      <v-tooltip 
        color="white"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="rounded-circle"
            v-bind="attrs"
            v-on="on"
            size="20"
          >
          mdi-progress-upload
          </v-icon>
        </template>
        <span class="black--text">{{ $t("UPLOADING") }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      required: true,
      type: String
    },
    processingStatus: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      icon: {
        processing: "processing",
        error: "error"
      }
    }
  },
  computed: {
    /**
     * 表示するアイコン名を返す
     */
    selectIcon() {
      if (this.isError) return this.icon.error;
      else if (this.isProcessing) return this.icon.processing;
      else return "";
    },
    /**
     * 処理中かチェックする
     */
    isProcessing() {
      if (this.processingStatus.PROCESSING.includes(this.status)) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 処理エラーが発生しているかチェックする
     */
    isError() {
      if (this.processingStatus.ERROR.includes(this.status)) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

<style lang="sass" scoped>
// 処理ステータスエラー
.process-error
  background-color: #F44336
  width: 18px
  height: 18px
  text-align: center
.process-error-icon
  top: -2px
  line-height: 18px
</style>