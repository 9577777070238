<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("Addflatground") }} </v-card-title>
    <v-card-text>
      <v-form
        v-model="isValid"
      >
        <v-row>
          <v-col cols="6">
            <v-simple-table dense style="background-color: rgba(41, 41, 47, 0)">
              <tbody>
                <tr>
                  <td style="width: 50%">
                    {{ $t("Flatgroundname") }}
                  </td>
                  <td>
                    <v-text-field
                      hide-details
                      dense
                      v-model="property.name"
                      :rules="[rules.validName]"
                      autofocus
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <v-col class="py-0">
                {{ $t("FLATGROUND_PARAMETERS") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-simple-table
                  dense
                  style="background-color: rgba(41, 41, 47, 0)"
                >
                  <tbody>
                    <tr>
                      <td style="width: 50%">
                        {{ $t("With_Slope") }}
                      </td>
                      <td>
                        <v-checkbox
                          hide-details
                          dense
                          class="ma-0"
                          v-model="withSlope"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <!-- 摺り付けピッチ -->
                    <tr>
                      <td style="width: 50%">
                        {{ $t("Distance") }}
                      </td>
                      <td>
                        <v-text-field
                          :disabled="!withSlope"
                          hide-details
                          dense
                          type="number"
                          min="0"
                          step="0.01"
                          suffix="m"
                          @input="updateInterpolationPitch()"
                          :rules="[
                            rules.orLess(99.99),
                            rules.orMore(0.01),
                            rules.decimalLength(2),
                            rules.numberFormat
                            ]"
                          v-model.number="property.settings.commonSettings.interpolationPitch"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- 切土設定 -->
            <v-row>
              <v-col class="py-0">
                {{ $t("Cutsetting") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <property-flat-mound-settings
                  :property="property"
                  :moundTypeSettings="'cutSettings'"
                >
                </property-flat-mound-settings>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <!-- 盛土設定 -->
            <v-row>
              <v-col class="py-0">
                {{ $t("Fillsetting") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <property-flat-mound-settings
                  :property="property"
                  :moundTypeSettings="'embankmentSettings'"
                >
                </property-flat-mound-settings>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-actions class="justify-end">
              <v-btn 
                @click="closeDialog" 
                color="red"
                :disabled="isFlatGroundAdded"
              >
                {{ $t("CANCEL") }}
              </v-btn>
              <v-btn
                @click="addFlatGround()"
                color="primary"
                :disabled="!isValid || addFlatGroundDisabled || isFlatGroundAdded || addButtonDisabled"
              >
                {{ $t("ADD") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import PropertyFlatMoundSettings from './PropertyFlatMoundSettings.vue';
import rules from '@/utils/validation-rules.js';
import {updateFlatGroundSettingsInViewer} from "./../../utils/cesium-common"


export default {
  name: "PropertyDialogFlat",
  components:{ PropertyFlatMoundSettings },
  props: {
    id: String,
    type: Number,
  },
  beforeMount() {
    this.property = JSON.parse(JSON.stringify(this.$store.getters.defaultFlatGroundProperty));
  },
  mounted() {
    // this.convertRatioSlopesToPercentSlopes();
  },
  data: () => ({
    isValid: false,
    property: null,
    rules: {
      ...rules,
      validName: v => !(/[~@#$^*()+=[\]{}|\\,?:<>!'"/;₹€&`%]/.test(v))
    },
    isFlatGroundAdded: false,
  }),
  computed: {
    withSlope: {
      get() {
        return this.property.settings.commonSettings.withSlope;
      },
      set(value) {
        this.property.settings.commonSettings.withSlope = value;
      }
    },
    roadParameterSlopeUnit() {
      return this.$store.state.roadParameterSlopeUnit;
    },
    cutSettingSlopeUnit() {
      return this.$store.state.cutSettingSlopeUnit;
    },
    fillSettingSlopeUnit() {
      return this.$store.state.fillSettingSlopeUnit;
    },
    addButtonDisabled(){
      if(!this.property) return true
      else return false;
    },
    addFlatGroundDisabled: function () {
      return ( 
        this.property.name === null || this.property.name === '' || /^\s*$/.test(this.property.name) || /[~@#$^*()+=[\]{}|\\,?:<>!'"/;₹€&`%]/.test(this.property.name)
      );
    },
    defaultProperty() { return this.$store.getters.defaultFlatGroundProperty },
  },
  methods: {
    resetProperty() {
      this.property = JSON.parse(JSON.stringify(this.$store.getters.defaultFlatGroundProperty));
      // this.convertRatioSlopesToPercentSlopes();
      this.isFlatGroundAdded = false;
    },
    closeDialog() {
      this.$store.state.modes.inCreationMode = false;
      window["viewer"].removePolygon(this.id);
      this.property = JSON.parse(JSON.stringify(this.$store.getters.defaultFlatGroundProperty));
      this.$emit("close", false);
    },
    async addFlatGround() {
      this.isFlatGroundAdded = true;
    
      this.property.id = this.id;
      this.property.cid = this.id;
      this.property.type = this.type;

      const flatJson = window["viewer"].getFlatGroundJSON(this.id);
      try {
        updateFlatGroundSettingsInViewer(this.id, this.property);
        await axios.post(
          `${process.env.VUE_APP_API_BASE}/obj/${this.$route.query.siteId}`,
          {
            siteId: this.$route.query.siteId,
            objJSON: flatJson,
            objProps: this.property,
          },
          {
            auth: this.$store.state.authObject,
          }
        );

      } catch (e) {
        console.error(e);
      } finally {
        // オブジェクトリストの更新
        await this.$store.dispatch('get_obj_list', this.$route.query.siteId);
        // 追加した平場を可視化する
        const addedObj = this.$store.state.objects.find(
          entity => entity.cid === this.id
        );
        if (addedObj) {
          addedObj.isLoaded = true;
          addedObj.isShow = true;
        }
        this.$emit("close", false);
        this.property = JSON.parse(JSON.stringify(this.$store.getters.defaultFlatGroundProperty));
      }
    },
    isNum(val){
      return typeof val == "number";
    },
    updateInterpolationPitch() {
      this.property.settings.commonSettings.interpolationPitch= this.isNum(this.property.settings.cutSettings.height) ? this.property.settings.commonSettings.interpolationPitch: this.defaultProperty.settings.cutSettings.height;
      if (this.property.settings.commonSettings.interpolationPitch <=0) 
      this.property.settings.commonSettings.interpolationPitch = 5;
      // this.updateFlatGroundSettings();
    },
    // updateFlatGroundMaterialSettings() {
    //   if (this.id === null) return;
    //   window["viewer"].updateFlatGroundMaterialSettings(
    //     this.id,
    //     { 
    //       material: this.property.settings.embankmentSettings.color,
    //       transparency: this.property.transparency,
    //     },
    //     { 
    //       material: this.property.settings.cutSettings.color,
    //       transparency: this.property.transparency,
    //     }
    //   );
    // },
  },
};
</script>