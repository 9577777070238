<template>
  <v-card>
    <v-card-title>
      {{ $t("SELECT_DATA_TYPE") }}
    </v-card-title>
    <v-btn class="closeDialogIcon" fab absolute top right @click="close()">
      <v-icon> mdi-close </v-icon>
    </v-btn>
    <v-card-text>
      <v-container style="width: 90%" class="ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon large> icon-mountain </v-icon>
                </v-col>
                <v-col @click="openTerrainDataDialog('pointcloud')">
                  <v-card-title> {{$t("POINT_CLOUD")}} </v-card-title>
                  <v-card-text
                    >LASer(.las), CSV(.txt, .csv)</v-card-text
                  >
                </v-col>
                <v-dialog
                  hide-overlay
                  v-model="showTerrainDataDialog"
                  width="600px"
                >
                  <add-terrain-data ref="add_terrain_data" @close="closeTerrainDataDialog(apiType, $event)" :allowedFileTypes="terrainDataDialog_allowedFileTypes" :axis_show="terrainDataDialog_axis_show"
                  :is_show="showTerrainDataDialog" /> 
                </v-dialog>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container style="width: 90%" class="ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon large> icon-cubes </v-icon></v-col
                >
                <v-col @click="openTerrainDataDialog('design')">
                  <v-card-title> {{$t("DESIGN")}} </v-card-title>
                  <v-card-text>LandXML(.xml), DXF(.dxf)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import addTerrainData from "./addTerrainData.vue";
import { mapMutations, mapActions } from 'vuex';
export default {
  components: {
    addTerrainData
  },
  name: "SelectType",
  props: {
    id: String,
  },
  mounted: () => {},
  data: () => ({
    showTerrainDataDialog: false,
    isFileDialogOpen: false,
    apiType: "",
    terrainDataDialog_allowedFileTypes: "",
    terrainDataDialog_axis_show: ""
  }),
  methods: {
    ...mapMutations(['set_snackbar']),
    ...mapActions(['get_point_cloud_list', 'get_design_list']),
    close() {
      this.$emit("close");
    },
    openTerrainDataDialog(type) {
      if(type == "pointcloud") {
        this.terrainDataDialog_allowedFileTypes=".txt,.las,.csv";
        this.terrainDataDialog_axis_show = true;
      }
      else  if (type == "design") {
        this.terrainDataDialog_allowedFileTypes=".xml,.dxf";
        this.terrainDataDialog_axis_show = false;
      }
      this.showTerrainDataDialog = true;
      this.$nextTick(()=>{
        this.$refs.add_terrain_data.open();
      })
      
      this.apiType = type;
      this.close();
    },
    closeFileUploaDialog() {
      this.isFileDialogOpen = false;
    },   

    async closeTerrainDataDialog(entity, dialogData) {
      
      this.showTerrainDataDialog = false;
      if (dialogData) {
        this.$store.dispatch('updateIsLoading', true);
        // Yokouchi-san upload code here
        const fd = new FormData();
        fd.append("file", dialogData[0]);
        fd.append("name", dialogData[1]);
        fd.append("date", dialogData[2]);
        fd.append("time", dialogData[3] + ":00");
        if(this.terrainDataDialog_axis_show){
          fd.append("axis", dialogData[4]);
        }

        try {
          await axios.post(
            `${process.env.VUE_APP_API_BASE}/${entity}/${this.$route.query.siteId}`,
            fd,
            {
              auth: this.$store.state.authObject,
            }
          );
          if (entity == "pointcloud") {
            await this.get_point_cloud_list(this.$route.query.siteId)
          } else {
            await this.get_design_list(this.$route.query.siteId)
          }
        } catch (e) {
          if( typeof e.toJSON === 'function' && e.toJSON().status == 504 ){
            this.set_snackbar({
              text: `${dialogData[1]}\n${this.$t('entity_upload_wait_msg')}`,
              color: 'rgba(255, 215, 0, 0.72)'
            });
          } else {
            this.set_snackbar({
              text: `${dialogData[1]} ${this.$t('UPLOAD')} ${this.$t('failed')}.`,
              color: 'rgba(153, 0, 0, 0.72)'
            });
          }
        } finally {
          this.$store.dispatch('updateIsLoading', false);
        }

        // アップロードリクエストから1秒毎にデータリストをリフレッシュ
        // let count = 0;
        // const timeId = setInterval(async () => {
        //   count++;
        //   if (count === 10) {
        //     clearInterval(timeId);
        //   }
        //   try {
        //     if (entity == "pointcloud") {
        //       await this.get_point_cloud_list(this.$route.query.siteId)
        //     } else {
        //       await this.get_design_list(this.$route.query.siteId)
        //     }
        //   } catch (error) {
        //     console.error(error.message);
        //   }
        // },1000);
      }

    }
  },
};
</script>

<style scoped>
.card-btn:hover {
  background-color: #2e2e2e;
}

.icon {
  margin-top: 4%;
}

.closeDialogIcon {
  margin-top: 5%;
}
</style>
