<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("Addtemporaryroad") }} </v-card-title>
    <v-card-text>
      <v-form
        v-model="isValid"
      >
        <v-row>
          <v-col cols="6">
            <v-simple-table dense style="background-color: rgba(41, 41, 47, 0)">
              <tbody>
                <tr>
                  <td style="width: 50%">
                    {{ $t("Roadname") }}
                  </td>
                  <td>
                    <v-text-field
                      hide-details
                      dense
                      v-model="property.name"
                      :rules ="[rules.validName]"
                      autofocus
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <!-- 道路・摺り付け -->
            {{ $t("Roadparameter") }}
            <v-simple-table dense style="background-color: rgba(41, 41, 47, 0)">
              <tbody>
                <!-- 道路幅_左 -->
                <tr>
                  <td style="width: 50%">
                    {{ $t("Widthofroad") }} {{ $t("Left") }}
                  </td>
                  <td>
                    <v-text-field
                      hide-details
                      dense
                      type="number"
                      min="0"
                      step="0.01"
                      suffix="m"
                      @input="updateRoadSettings()"
                      v-model.number="property.settings.commonSettings.leftWidth"
                      :rules="[rules.orLess(99.99), rules.orMore(0), rules.decimalLength(2), rules.numberFormat]"
                    />
                  </td>
                </tr>
                <!-- 道路幅_右 -->
                <tr>
                  <td>
                    <span style="opacity: 0">{{ $t("Widthofroad") }}</span>
                    {{ $t("Right") }}
                  </td>
                  <td>
                    <v-text-field
                      hide-details
                      dense
                      type="number"
                      min="0"
                      step="0.01"
                      suffix="m"
                      @input="updateRoadSettings()"
                      v-model.number="property.settings.commonSettings.rightWidth"
                      :rules="[rules.orLess(99.99), rules.orMore(0), rules.decimalLength(2), rules.numberFormat]"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("Setsuperelevation") }}
                  </td>
                  <td>
                    <v-select
                      v-model="property.settings.commonSettings.isSuperElevation"
                      :items="slopeTypes"
                      item-text="label"
                      item-value="value"
                      hide-details
                      dense
                      class="ma-0"
                      v-on:change="updateRoadSettings()"
                    ></v-select>
                  </td>
                </tr>
                <!-- 片勾配 -->
                <tr v-if="property.settings.commonSettings.isSuperElevation">
                  <td>
                    <div>
                      {{ $t("Superelevation") }}
                    </div>
                  </td>
                  <td>
                    <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(roadParameterSlopeUnit.superElevation)"
                                  :is-editable="true"
                                  :slope-value="property.settings.commonSettings.leftSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.commonSettings.leftSlope"
                                  :max-value="999.99"
                                  :min-value="-999.99"
                                  :decimal-length="2"
                                  :un-valid-zero="roadParameterSlopeUnit.superElevation === 'percent'"
                                  ></SlopeSettingInput>  
                  </td>
                </tr>
                <!-- 横断勾配 -->
                <tr v-else>
                  <td>
                    <div>
                      {{ $t("Crossslope") }}
                    </div>
                  </td>
                  <td>
                    <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(roadParameterSlopeUnit.crossSlope)"
                                  :is-editable="true"
                                  :slope-value="property.settings.commonSettings.leftSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.commonSettings.leftSlope"
                                  :max-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 9999.99 : 999.99"
                                  :min-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 0 : 0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="roadParameterSlopeUnit.crossSlope === 'percent'"
                                  ></SlopeSettingInput>  
                  </td>
                </tr>
                <!-- 摺り付けピッチ -->
                <tr>
                  <td>
                    {{ $t("InterpolationPitch") }}
                  </td>
                  <td>
                    <v-text-field
                      hide-details
                      dense
                      type="number"
                      min="0"
                      step="0.01"
                      suffix="m"
                      @input="updateRoadSettings()"
                      v-model.number="property.settings.commonSettings.interpolationPitch"
                      :rules="[
                        rules.orLess(99.99),
                        rules.orMore(0.01),
                        rules.decimalLength(2),
                        rules.numberFormat
                        ]"
                      value="5"
                    />
                  </td>
                </tr>
                <!-- 路面色 -->
                <tr>
                  <td>
                    {{ $t("Roadsurfacecolor") }}
                  </td>
                  <td>
                    <template>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar
                            :color="property.settings.commonSettings.surfaceColor"
                            size="20px"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-avatar>
                        </template>
                        <v-color-picker
                          class="ma-0"
                          hide-canvas
                          hide-inputs
                          hide-sliders
                          show-swatches
                          mode="hexa"
                          v-model="property.settings.commonSettings.surfaceColor"
                          @input="updateRoadMaterial()"
                        ></v-color-picker>
                      </v-menu>
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- 切土設定 -->
            <v-row>
              <v-col class="py-0">
                {{ $t("Cutsetting") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-simple-table
                  dense
                  style="background-color: rgba(41, 41, 47, 0)"
                >
                  <tbody>
                    <!-- 法面高さ -->
                    <tr>
                      <td style="width: 50%">
                        {{ $t("Slopeheight") }}
                      </td>
                      <td>
                        <v-text-field
                          hide-details
                          dense
                          type="number"
                          min="0"
                          step="0.01"
                          suffix="m"
                          :disabled="cutSlopeCondition()"
                          :rules="[
                            rules.required,
                            rules.orLess(99.99),
                            rules.orMore(0.01),
                            rules.decimalLength(2),
                            rules.numberFormat
                            ]"
                          @input="updateRoadSettings()"
                          v-model.number="property.settings.cutSettings.roadHeight"
                        />
                      </td>
                    </tr>
                    <!-- 法面勾配 -->
                    <tr>
                      <td>
                        {{ $t("Slopegradient") }}
                      </td>
                      <td>

                        <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                  :is-editable="!property.settings.cutSettings.bLeftRightSettings"
                                  :slope-value="property.settings.cutSettings.moundSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.cutSettings.moundSlope"
                                  :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="false"
                                  ></SlopeSettingInput> 
                      

                      </td>
                    </tr>
                    <!-- 小段幅 -->
                    <tr>
                      <td>
                        {{ $t("Bermwidth") }}
                      </td>
                      <td>
                        <v-text-field
                          hide-details
                          dense
                          type="number"
                          min="0"
                          step="0.01"
                          suffix="m"
                          :disabled="cutSlopeCondition()"
                          :rules="[
                            rules.orLess(99.99),
                            rules.orMore(0.01),
                            rules.decimalLength(2),
                            rules.numberFormat
                            ]"
                          @input="updateRoadSettings()"
                          v-model.number="property.settings.cutSettings.shelfWidth"
                        />
                      </td>
                    </tr>
                    <!-- 小段勾配 -->
                    <tr>
                      <td>
                        {{ $t("Bermgradient") }}
                      </td>
                      <td>

                        <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                  :is-editable="!cutSlopeCondition()"
                                  :slope-value="property.settings.cutSettings.shelfSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.cutSettings.shelfSlope"
                                  :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'"
                                  ></SlopeSettingInput>                       

                      </td>
                    </tr>
                    <!-- 左右設定 -->
                    <tr>
                      <td>
                        {{ $t("LeftRightSettings") }}
                      </td>
                      <td>
                        <v-checkbox
                          hide-details
                          dense
                          class="ma-0"
                          @click="updateRoadSettings()"
                          v-model="property.settings.cutSettings.bLeftRightSettings"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <!-- 小段あり -->
                    <tr>
                      <td>
                        {{ $t("Thereisberm") }}
                      </td>
                      <td>
                        <v-checkbox
                          hide-details
                          dense
                          class="ma-0"
                          @click="updateRoadSettings()"
                          v-model="property.settings.cutSettings.generateShelf"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <!-- 道路色 -->
                    <tr>
                      <td>
                        {{ $t("Roadcolor") }}
                      </td>
                      <td>
                        <template>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar
                                :color="property.settings.cutSettings.color"
                                size="20px"
                                v-bind="attrs"
                                v-on="on"
                              >
                              </v-avatar>
                            </template>
                            <v-color-picker
                              class="ma-0"
                              hide-canvas
                              hide-inputs
                              hide-sliders
                              show-swatches
                              mode="hexa"
                              v-model="property.settings.cutSettings.color"
                              @input="updateRoadMaterial()"
                            ></v-color-picker>
                          </v-menu>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <!-- 左右設定 -->
                <v-expansion-panels>
                  <v-expansion-panel
                    style="background-color: rgba(41, 41, 47, 0)"
                  >
                    <v-expansion-panel-header class="px-4 py-0">
                      {{ $t("Leftrightsetting") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <!-- 左切土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Leftcutsetting") }}
                          <v-simple-table
                            dense
                            style="background-color: rgba(41, 41, 47, 0)"
                            class="pa-0"
                          >
                            <tbody>
                                <!-- 法面高さ -->
                                <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    :disabled="cutLeftRightCondition()"
                                    v-model.number="property.settings.cutSettings.leftSlopeHeight"
                                  />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                  :is-editable="property.settings.cutSettings.bLeftRightSettings"
                                  :slope-value="property.settings.cutSettings.leftMoundSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.cutSettings.leftMoundSlope"
                                  :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="false"
                                  ></SlopeSettingInput>
                                

                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :disabled="cutLeftRightCondition()"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.leftShelfWidth"
                                  />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                  :is-editable="!cutLeftRightCondition()"
                                  :slope-value="property.settings.cutSettings.leftShelfSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.cutSettings.leftShelfSlope"
                                  :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'"
                                  ></SlopeSettingInput>

                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                        <!-- 右切土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Rightcutsetting") }}
                          <v-simple-table
                            dense
                            style="background-color: rgba(41, 41, 47, 0)"
                          >
                            <tbody>
                              <!-- 法面高さ -->
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :disabled="cutLeftRightCondition()"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.rightSlopeHeight"
                                  />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                  :is-editable="property.settings.cutSettings.bLeftRightSettings"
                                  :slope-value="property.settings.cutSettings.rightMoundSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.cutSettings.rightMoundSlope"
                                  :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="false"
                                  ></SlopeSettingInput>

                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :disabled="cutLeftRightCondition()"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.rightShelfWidth"
                                  />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                  :is-editable="!cutLeftRightCondition()"
                                  :slope-value="property.settings.cutSettings.rightShelfSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.cutSettings.rightShelfSlope"
                                  :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'"
                                  ></SlopeSettingInput>

                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <!-- 盛土設定 -->
            <v-row>
              <v-col class="py-0">
                {{ $t("Fillsetting") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-simple-table
                  dense
                  style="background-color: rgba(41, 41, 47, 0)"
                >
                  <tbody>
                    <!-- 法面高さ -->
                    <tr>
                      <td style="width: 50%">
                        {{ $t("Slopeheight") }}
                      </td>
                      <td>
                        <v-text-field
                          hide-details
                          dense
                          type="number"
                          min="0"
                          step="0.01"
                          suffix="m"
                          :disabled="embSlopeCondition()"
                          :rules="[
                            rules.required,
                            rules.orLess(99.99),
                            rules.orMore(0.01),
                            rules.decimalLength(2),
                            rules.numberFormat
                            ]"
                          @input="updateRoadSettings()"
                          v-model.number="property.settings.embarkmentSettings.roadHeight"
                        />
                      </td>
                    </tr>
                    <!-- 法面勾配 -->
                    <tr>
                      <td>
                        {{ $t("Slopegradient") }}
                      </td>
                      <td>

                        <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                  :is-editable="!property.settings.embarkmentSettings.bLeftRightSettings"
                                  :slope-value="property.settings.embarkmentSettings.moundSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.embarkmentSettings.moundSlope"
                                  :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="false"
                                  ></SlopeSettingInput>

                      

                      </td>
                    </tr>
                    <!-- 小段幅 -->
                    <tr>
                      <td>
                        {{ $t("Bermwidth") }}
                      </td>
                      <td>
                        <v-text-field
                          hide-details
                          dense
                          type="number"
                          min="0"
                          step="0.01"
                          suffix="m"
                          :disabled="embSlopeCondition()"
                          :rules="[
                            rules.required,
                            rules.orLess(99.99),
                            rules.orMore(0.01),
                            rules.decimalLength(2),
                            rules.numberFormat
                            ]"
                          @input="updateRoadSettings()"
                          v-model.number="property.settings.embarkmentSettings.shelfWidth"
                        />
                      </td>
                    </tr>
                    <!-- 小段勾配 -->
                    <tr>
                      <td>
                        {{ $t("Bermgradient") }}
                      </td>
                      <td>

                        <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                  :is-editable="!embSlopeCondition()"
                                  :slope-value="property.settings.embarkmentSettings.shelfSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.embarkmentSettings.shelfSlope"
                                  :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'"
                                  ></SlopeSettingInput>

                      

                      </td>
                    </tr>
                    <!-- 左右設定 -->
                    <tr>
                      <td>
                        {{ $t("LeftRightSettings") }}
                      </td>
                      <td>
                        <v-checkbox
                          hide-details
                          dense
                          class="ma-0"
                          @click="updateRoadSettings()"
                          v-model="property.settings.embarkmentSettings.bLeftRightSettings"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <!-- 小段あり -->
                    <tr>
                      <td>
                        {{ $t("Thereisberm") }}
                      </td>
                      <td>
                        <v-checkbox
                          hide-details
                          dense
                          class="ma-0"
                          @click="updateRoadSettings()"
                          v-model="property.settings.embarkmentSettings.generateShelf"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <!-- 道路色 -->
                    <tr>
                      <td>
                        {{ $t("Roadcolor") }}
                      </td>
                      <td>
                        <template>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar
                                :color="property.settings.embarkmentSettings.color"
                                size="20px"
                                v-bind="attrs"
                                v-on="on"
                              >
                              </v-avatar>
                            </template>
                            <v-color-picker
                              class="ma-0"
                              hide-canvas
                              hide-inputs
                              hide-sliders
                              show-swatches
                              mode="hexa"
                              v-model="property.settings.embarkmentSettings.color"
                              @input="updateRoadMaterial()"
                            ></v-color-picker>
                          </v-menu>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <!-- 左右設定 -->
                <v-expansion-panels>
                  <v-expansion-panel
                    style="background-color: rgba(41, 41, 47, 0)"
                  >
                    <v-expansion-panel-header class="px-4 py-0">
                      {{ $t("Leftrightsetting") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <!-- 左盛土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Leftfillsetting") }}
                          <v-simple-table
                            dense
                            style="background-color: rgba(41, 41, 47, 0)"
                            class="pa-0"
                          >
                            <tbody>                          
                              <!-- 法面高さ -->
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    :disabled="embLeftRightCondition()"
                                    v-model.number="property.settings.embarkmentSettings.leftSlopeHeight"
                                  />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                  :is-editable="property.settings.embarkmentSettings.bLeftRightSettings"
                                  :slope-value="property.settings.embarkmentSettings.leftMoundSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.embarkmentSettings.leftMoundSlope"
                                  :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="false"
                                  ></SlopeSettingInput>

                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :disabled="embLeftRightCondition()"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    v-model.number="property.settings.embarkmentSettings.leftShelfWidth"
                                  />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                  :is-editable="!embLeftRightCondition()"
                                  :slope-value="property.settings.embarkmentSettings.leftShelfSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.embarkmentSettings.leftShelfSlope"
                                  :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'"
                                  ></SlopeSettingInput>

                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                        <!-- 右盛土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Rightfillsetting") }}
                          <v-simple-table
                            dense
                            style="background-color: rgba(41, 41, 47, 0)"
                          >
                            <tbody>
                              <!-- 法面高さ -->
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    :disabled="embLeftRightCondition()"
                                    v-model.number="property.settings.embarkmentSettings.rightSlopeHeight"
                                  />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                  :is-editable="property.settings.embarkmentSettings.bLeftRightSettings"
                                  :slope-value="property.settings.embarkmentSettings.rightMoundSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.embarkmentSettings.rightMoundSlope"
                                  :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="false"
                                  ></SlopeSettingInput>

                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details
                                    dense
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    suffix="m"
                                    :disabled="embLeftRightCondition()"
                                    :rules="[
                                      rules.required,
                                      rules.orLess(99.99),
                                      rules.orMore(0.01),
                                      rules.decimalLength(2),
                                      rules.numberFormat
                                      ]"
                                    @input="updateRoadSettings()"
                                    v-model.number="property.settings.embarkmentSettings.rightShelfWidth"
                                  />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput 
                                  :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                  :is-editable="!embLeftRightCondition()"
                                  :slope-value="property.settings.embarkmentSettings.rightShelfSlope"
                                  :step="0.01"
                                  @input="updateRoadSettings"
                                  v-model.number="property.settings.embarkmentSettings.rightShelfSlope"
                                  :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2"
                                  :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'"
                                  ></SlopeSettingInput>

                                

                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-actions class="justify-end">
              <v-btn 
                @click="closeDialog" 
                color="red"
                :disabled="isRoadAdded"
              >
                {{ $t("CANCEL") }}
              </v-btn>
              <v-btn
                @click="addRoad()"
                color="primary"
                :disabled="!isValid || addRoadDisabled || isRoadAdded || disableAddButton"
              >
                {{ $t("ADD") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import SlopeSettingInput from "./SlopeSettingInput.vue"
import {UnitTypes} from "../../constant"
import rules from '@/utils/validation-rules.js';
import {updateRoadSettingsInViewer} from "./../../utils/cesium-common"

export default {
  name: "PropertyDialogRoad",
  components:{SlopeSettingInput},
  props: {
    id: String,
    type: Number,
  },
  beforeMount() {
    this.property = JSON.parse(JSON.stringify(this.$store.getters.defaultRoadProperty));
  },
  mounted() { 
      // this.convertRatioSlopesToPercentSlopes();
  },
  data: () => ({
    isValid: false,
    property: null,
    isRoadAdded: false,
    rules: {
          ...rules,
          required: value => !!value || '*',
          validName: v=>!(/[~@#$^*()+=[\]{}|\\,?:<>!'"/;&₹€`%]/.test(v))
        },
    disableAddButton: false
  }),
  computed: {
    roadParameterSlopeUnit() {
      return this.$store.state.roadParameterSlopeUnit;
    },
    cutSettingSlopeUnit() {
      return this.$store.state.cutSettingSlopeUnit;
    },
    fillSettingSlopeUnit() {
      return this.$store.state.fillSettingSlopeUnit;
    },
    addRoadDisabled: function () {
      return (
        this.property.name === null
        || this.property.name === ''
        || /^\s*$/.test(this.property.name)
      );
    },
    slopeTypes() {
      return [
        {
          label: this.$t("Crossslope"),
          value: false,
        },
        {
          label: this.$t("Superelevation"),
          value: true,
        },
      ];
    },
  },
  methods: {
    getSuffix(unitType){
      const unit = UnitTypes.find(type=>type.value === unitType);
      return unit.symbol;
    },
    closeDialog() {
      this.$store.state.modes.inCreationMode = false;
      window["viewer"].deleteRoad(this.id);
      this.$emit("close", false);
      this.property = JSON.parse(JSON.stringify(this.$store.getters.defaultRoadProperty));
    },
    // Disabling condition for cut setting's - shelfSlope, shelfWidth
    cutSlopeCondition() {
      return this.property.settings.cutSettings.bLeftRightSettings || !this.property.settings.cutSettings.generateShelf;
    },
    // Disabling condition for embankment setting's - shelfSlope, shelfWidth
    embSlopeCondition() {
      return this.property.settings.embarkmentSettings.bLeftRightSettings || !this.property.settings.embarkmentSettings.generateShelf;
    },
    // Disabling condition for cut setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
    cutLeftRightCondition() {
      return !(this.property.settings.cutSettings.bLeftRightSettings && this.property.settings.cutSettings.generateShelf);
    },
    // Disabling condition for embankment setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
    embLeftRightCondition() {
      return !(this.property.settings.embarkmentSettings.bLeftRightSettings && this.property.settings.embarkmentSettings.generateShelf);
    },
    async addRoad() {
      this.isRoadAdded = true;

      this.property.id = this.id;
      this.property.cid = this.id;
      this.property.type = this.type;
      // const d = JSON.parse(JSON.stringify(this.property.settings));
      try {
        updateRoadSettingsInViewer(this.id, this.property);

        const roadJson = window["viewer"].getRoadJSON(this.id);
        this.property.info.length = window["viewer"].getLengthOfRoad(this.id);
        this.property.info.height = window["viewer"].getRoadHeightInfo(this.id);
        this.property.info.slope = window["viewer"].getRoadSlopeInfo(this.id);

        console.log(
            {
              siteId: this.$route.query.siteId,
              objJSON: roadJson,
              objProps: this.property,
            }
        );
        await axios.post(
          `${process.env.VUE_APP_API_BASE}/obj/${this.$route.query.siteId}`,
          {
            siteId: this.$route.query.siteId,
            objJSON: roadJson,
            objProps: this.property,
          },
          {
            auth: this.$store.state.authObject
          }
        );
        // オブジェクトリストの更新
        await this.$store.dispatch('get_obj_list', this.$route.query.siteId);
        //
        // this.$store.commit("set_snackbar", {
        //           text: `Road Saved Successfully`,
        //           color: 'rgba( 135, 190, 75, 0.72)'
        //         });
        // console.log("Road Saved Successfully");
      } catch (e) {
        console.error(e);
        this.$store.state.modes.inCreationMode = false;
        window["viewer"].deleteRoad(this.id);
        this.$store.commit("set_snackbar", {
          text: this.$t("ROAD_SAVE_FAILURE"),
          color: 'rgba(153, 0, 0, 0.72)'
        });
      } finally {
        // 追加した仮設道路を可視化する
        const addedObj = this.$store.state.objects.find(
          entity => entity.cid === this.id
        );
        if (addedObj) {
          addedObj.isLoaded = true;
          addedObj.isShow = true;
        }
        this.$emit("close", false);
        this.property = JSON.parse(JSON.stringify(this.$store.getters.defaultRoadProperty));
      }
    },
    isNum(val){
      return typeof val == "number";
    },
    updateRoadSettings() {
      const embarkmentSettings = this.property.settings.embarkmentSettings;
      const embarkmentWidthAndHeightValid =
      embarkmentSettings['roadHeight'] <= 0
      || embarkmentSettings['shelfWidth'] <= 0
      || embarkmentSettings['leftSlopeHeight'] <= 0
      || embarkmentSettings['rightSlopeHeight'] <= 0
      || embarkmentSettings['leftShelfWidth'] <= 0
      || embarkmentSettings['rightShelfWidth'] <= 0;

      const cutSettings = this.property.settings.cutSettings;
      const cutWidthAndHeightValid =
          cutSettings['roadHeight'] <= 0
          || cutSettings['shelfWidth'] <= 0
          || cutSettings['leftSlopeHeight'] <= 0
          || cutSettings['rightSlopeHeight'] <= 0
          || cutSettings['leftShelfWidth'] <= 0
          || cutSettings['rightShelfWidth'] <= 0;

      if(embarkmentWidthAndHeightValid || cutWidthAndHeightValid) {
          this.disableAddButton = true;
          return;
        }
      else this.disableAddButton = false;

      if (this.id === null) return;

      // this.updateSlopes(this.property), 
      //common settings
      this.property.settings.commonSettings.leftWidth = this.isNum(this.property.settings.commonSettings.leftWidth) ? this.property.settings.commonSettings.leftWidth : 2.5;
      this.property.settings.commonSettings.rightWidth = this.isNum(this.property.settings.commonSettings.rightWidth) ? this.property.settings.commonSettings.rightWidth : 2.5;
      this.property.settings.commonSettings.leftSlope = this.isNum(this.property.settings.commonSettings.leftSlope) ? this.property.settings.commonSettings.leftSlope : 50;
      this.property.settings.commonSettings.rightSlope = this.property.settings.commonSettings.leftSlope;
      this.property.settings.commonSettings.interpolationPitch = this.isNum(this.property.settings.commonSettings.interpolationPitch) && ( this.property.settings.commonSettings.interpolationPitch != 0 ) ? this.property.settings.commonSettings.interpolationPitch : 5;

      this.property.settings.commonSettings.rightSlope = this.property.settings.commonSettings.isSuperElevation ? -1 * this.property.settings.commonSettings.rightSlope : this.property.settings.commonSettings.rightSlope;

      //embarkment settings
      this.property.settings.embarkmentSettings.roadHeight = this.isNum(this.property.settings.embarkmentSettings.roadHeight) ? this.property.settings.embarkmentSettings.roadHeight : 5;
      this.property.settings.embarkmentSettings.moundSlope = this.isNum(this.property.settings.embarkmentSettings.moundSlope) ? this.property.settings.embarkmentSettings.moundSlope : 1.5;

      this.property.settings.embarkmentSettings.shelfWidth = this.isNum(this.property.settings.embarkmentSettings.shelfWidth) ? this.property.settings.embarkmentSettings.shelfWidth : 1.5;
      this.property.settings.embarkmentSettings.shelfSlope = this.isNum(this.property.settings.embarkmentSettings.shelfSlope) ? this.property.settings.embarkmentSettings.shelfSlope : 100;
        
      this.property.settings.embarkmentSettings.leftSlopeHeight = this.isNum(this.property.settings.embarkmentSettings.leftSlopeHeight) ? this.property.settings.embarkmentSettings.leftSlopeHeight : 5;
      this.property.settings.embarkmentSettings.leftMoundSlope = this.isNum(this.property.settings.embarkmentSettings.leftMoundSlope) ? this.property.settings.embarkmentSettings.leftMoundSlope : 1.5;
      this.property.settings.embarkmentSettings.rightSlopeHeight = this.isNum(this.property.settings.embarkmentSettings.rightSlopeHeight) ? this.property.settings.embarkmentSettings.rightSlopeHeight : 5;
      this.property.settings.embarkmentSettings.rightMoundSlope = this.isNum(this.property.settings.embarkmentSettings.rightMoundSlope) ? this.property.settings.embarkmentSettings.rightMoundSlope : 1.5;

      this.property.settings.embarkmentSettings.leftShelfWidth = this.isNum(this.property.settings.embarkmentSettings.leftShelfWidth) ? this.property.settings.embarkmentSettings.leftShelfWidth : 1.5;
      this.property.settings.embarkmentSettings.leftShelfSlope = this.isNum(this.property.settings.embarkmentSettings.leftShelfSlope) ? this.property.settings.embarkmentSettings.leftShelfSlope : 100;
      this.property.settings.embarkmentSettings.rightShelfWidth = this.isNum(this.property.settings.embarkmentSettings.rightShelfWidth) ? this.property.settings.embarkmentSettings.rightShelfWidth : 1.5;
      this.property.settings.embarkmentSettings.rightShelfSlope = this.isNum(this.property.settings.embarkmentSettings.rightShelfSlope) ? this.property.settings.embarkmentSettings.rightShelfSlope : 100;

      //cut settings
      this.property.settings.cutSettings.roadHeight = this.isNum(this.property.settings.cutSettings.roadHeight) ? this.property.settings.cutSettings.roadHeight : 5;
      this.property.settings.cutSettings.moundSlope = this.isNum(this.property.settings.cutSettings.moundSlope) ? this.property.settings.cutSettings.moundSlope : 1;

      this.property.settings.cutSettings.shelfWidth = this.isNum(this.property.settings.cutSettings.shelfWidth) ? this.property.settings.cutSettings.shelfWidth : 1.5;
      this.property.settings.cutSettings.shelfSlope = this.isNum(this.property.settings.cutSettings.shelfSlope) ? this.property.settings.cutSettings.shelfSlope : 100;

      this.property.settings.cutSettings.leftSlopeHeight = this.isNum(this.property.settings.cutSettings.leftSlopeHeight) ? this.property.settings.cutSettings.leftSlopeHeight : 5;
      this.property.settings.cutSettings.leftMoundSlope = this.isNum(this.property.settings.cutSettings.leftMoundSlope) ? this.property.settings.cutSettings.leftMoundSlope : 1;
      this.property.settings.cutSettings.rightSlopeHeight = this.isNum(this.property.settings.cutSettings.rightSlopeHeight) ? this.property.settings.cutSettings.rightSlopeHeight : 5;
      this.property.settings.cutSettings.rightMoundSlope = this.isNum(this.property.settings.cutSettings.rightMoundSlope) ? this.property.settings.cutSettings.rightMoundSlope : 1;

      this.property.settings.cutSettings.leftShelfWidth = this.isNum(this.property.settings.cutSettings.leftShelfWidth) ? this.property.settings.cutSettings.leftShelfWidth : 1.5;
      this.property.settings.cutSettings.leftShelfSlope = this.isNum(this.property.settings.cutSettings.leftShelfSlope) ? this.property.settings.cutSettings.leftShelfSlope : 100;
      this.property.settings.cutSettings.rightShelfWidth = this.isNum(this.property.settings.cutSettings.rightShelfWidth) ? this.property.settings.cutSettings.rightShelfWidth : 1.5;
      this.property.settings.cutSettings.rightShelfSlope = this.isNum(this.property.settings.cutSettings.rightShelfSlope) ? this.property.settings.cutSettings.rightShelfSlope : 100;

      // const d = JSON.parse(JSON.stringify(this.property.settings));
      // window["viewer"].updateRoadSettings(this.id, d);
    },
    updateRoadMaterial() {
      window["viewer"].updateRoadMaterialSettings(this.id, 
      {
        surfaceColor: this.property.settings.commonSettings.surfaceColor,
      },
      {
        color: this.property.settings.embarkmentSettings.color,
        transparency: this.property.transparency,
      }, 
      {
        color: this.property.settings.cutSettings.color,
        transparency: this.property.transparency,
      });
    },
  },
};
</script>