<template>
    <div
      style="
        display: inline-flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        left: 10px;
        bottom:41px;
      "
      v-show="show"
    >
      <span class="point_selection_label2">{{$t("Startpoint")}}</span>
      <v-simple-table class="point_selection_table" dense style="background-color: transparent">
        <tbody>
          <tr>
            <td class="point_selection_label1">
              X
            </td>
            <td>
              <v-text-field
                hide-details
                dense
                solo
                light
                @input="updatePoint(1)"
                type="number"
                v-model.number="x1"
                style="width:110px;"
              />
            </td>
          </tr>
          <tr>
            <td class="point_selection_label1">
              Y
            </td>
            <td>
              <v-text-field
                hide-details
                dense
                solo
                light
                @input="updatePoint(1)"
                type="number"
                v-model.number="y1"
                style="width:110px;"
              />
            </td>
          </tr>
          <tr>
            <td class="point_selection_label1">
              Z
            </td>
            <td>
              <v-text-field
                hide-details
                dense
                solo
                light
                @input="updatePoint(1)"
                type="number"
                v-model.number="z1"
                style="width:110px;"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div style="display:flex" class="mt-3">
        <div class="point_selection_label3" style="margin-left:41px">{{$t("Sectionheight")}} : <input type="text" :value="startPointHeightAbovePointCloud" style="color:white;outline:none;width:100px;" readonly></div>
      </div>
      <div style="display:flex" class="mt-3">
        <div class="point_selection_label3" style="margin-left:41px">{{$t("Designaltitude")}} : <input type="text" :value="startPointHeightAboveDesignData" style="color:white;outline:none;width:100px;" readonly></div>
      </div>
      <br>
      <span class="point_selection_label2">{{$t("Endpoint")}}</span>
      <v-simple-table class="point_selection_table" dense style="background-color: transparent">
        <tbody>
          <tr>
            <td class="point_selection_label1">
              X
            </td>
            <td>
              <v-text-field
                hide-details
                dense
                solo
                light
                @input="updatePoint(2)"
                type="number"
                v-model.number="x2"
                style="width:110px;"
              />
            </td>
          </tr>
          <tr>
            <td class="point_selection_label1">
              Y
            </td>
            <td>
              <v-text-field
                hide-details
                dense
                solo
                light
                @input="updatePoint(2)"
                type="number"
                v-model.number="y2"
                style="width:110px;"
              />
            </td>
          </tr>
          <tr>
            <td class="point_selection_label1">
              Z
            </td>
            <td>
              <v-text-field
                hide-details
                dense
                solo
                light
                @input="updatePoint(2)"
                type="number"
                v-model.number="z2"
                style="width:110px;"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      
      <div style="display:flex" class="mt-3">
        <div class="point_selection_label3" style="margin-left:41px">{{$t("Sectionheight")}} : <input type="text" :value="endPointHeightAbovePointCloud" style="color:white;outline:none;width:100px;" readonly></div>
      </div>
      <div style="display:flex" class="mt-3">
        <div class="point_selection_label3" style="margin-left:41px">{{$t("Designaltitude")}} : <input type="text" :value="endPointHeightAboveDesignData" style="color:white;outline:none;width:100px;" readonly></div>
      </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import {convertCoordinateToPointCloudCrs, convertCoordinateToCesiumCrs} from "./../utils/cesium-common";
                // class = "text-field-transparent"

    export default {
        name: "PointSelectionOverlay",
        props: {
            show:Boolean,
        },
        computed: {
          ...mapState(['ai_road_preview_data']),
          x1: {
            get() {
              return this.ai_road_preview_data.x1
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'x1',
                value: newValue
              })
            }
          },
          y1: {
            get() {
              return this.ai_road_preview_data.y1
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'y1',
                value: newValue
              })
            }
          },
          z1: {
            get() {
              return this.ai_road_preview_data.z1
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'z1',
                value: newValue
              })
            }
          },
          x2: {
            get() {
              return this.ai_road_preview_data.x2
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'x2',
                value: newValue
              })
            }
          },
          y2: {
            get() {
              return this.ai_road_preview_data.y2
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'y2',
                value: newValue
              })
            }
          },
          z2: {
            get() {
              return this.ai_road_preview_data.z2
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'z2',
                value: newValue
              })
            }
          },
          startPointHeightAbovePointCloud: {
            get() {
              return this.ai_road_preview_data.startPointHeightAbovePointCloud ? " " + this.ai_road_preview_data.startPointHeightAbovePointCloud.toFixed(2) + "m" :" m "
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'startPointHeightAbovePointCloud',
                value: newValue
              })
            }
          },
          startPointHeightAboveDesignData: {
            get() {
              return this.ai_road_preview_data.startPointHeightAboveDesignData ? " " +  this.ai_road_preview_data.startPointHeightAboveDesignData.toFixed(2)+ " m ":" m "
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'startPointHeightAboveDesignData',
                value: newValue
              })
            }
          },
          endPointHeightAbovePointCloud: {
            get() {
              return this.ai_road_preview_data.endPointHeightAbovePointCloud ?  " " + this.ai_road_preview_data.endPointHeightAbovePointCloud.toFixed(2) + " m ": " m "
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'endPointHeightAbovePointCloud',
                value: newValue
              })
            }
          },
           endPointHeightAboveDesignData: {
            get() {
              return this.ai_road_preview_data.endPointHeightAboveDesignData ? " " +  this.ai_road_preview_data.endPointHeightAboveDesignData.toFixed(2) + " m " : " m "
            },
            set(newValue) {
              this.set_ai_road_preview_data_property({
                name: 'endPointHeightAboveDesignData',
                value: newValue
              })
            }
          },
          
          

        },
        watch: {
        show(isVisivle) {
          if(isVisivle) {
            window["viewer"].setAIRoadPreviewCallback(this.updateCoordinates.bind(this))
          }
    }},
        methods: {
          ...mapMutations(['set_ai_road_preview_data','set_ai_road_preview_data_property']),
          async updatePoint(index) {

            let x = index === 1 ? this.x1 : this.x2;
            let y = index === 1 ? this.y1 : this.y2;
            let z = index === 1 ? this.z1 : this.z2;

            // Commenting as we need to use the cesium coordinates only
            ({ x, y, z } = await convertCoordinateToCesiumCrs({ x, y, z }));

            if (Number.isFinite(x) && Number.isFinite(y) && Number.isFinite(y)) {
              window["viewer"].updateAIRoadPreviewPointAtIndex(index, { x, y, z });
            }

          },         
          async updateCoordinates(Points) {

            // Using cesium coords
            // Converting coordinates from cesium crs to pointcloud's crs.
            const p1 = await convertCoordinateToPointCloudCrs(Points[0]);
            const p2 = await convertCoordinateToPointCloudCrs(Points[1]);

            this.set_ai_road_preview_data( {
              x1:p1.x,
              y1:p1.y,
              z1:p1.z,
              x2:p2.x,
              y2:p2.y,
              z2:p2.z,
              startPointHeightAbovePointCloud:Points[0].heightFromPointCloud,
              startPointHeightAboveDesignData:Points[0].heightFromDesignData,
              endPointHeightAbovePointCloud:Points[1].heightFromPointCloud,
              endPointHeightAboveDesignData:Points[1].heightFromDesignData,
            });

            return [p1, p2]
          },
        }

    };
</script>
<style>
    .point_selection_table{
        margin-left:53px
    }

    .point_selection_table tr:hover {
        background: transparent !important;
    }

    .point_selection_label1{
        padding: 0px !important;
        text-align: right !important;
        font-size: medium !important;
    }

    .point_selection_label3{
        font-size: medium;
    }

    .point_selection_label2{
        margin-bottom: 5px !important;
        font-size: large;
    }

    .point_selection_value{
        background: white;
        color: darkslategray;
        width: 110px;
        padding: 5px;
        text-align: center;
        height: 30px;
    }

    .text-field-transparent  .v-input__slot {
  background: transparent !important;
    }

 
</style>