<template>
  <v-list dense>
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForRoadEdit" :key="index"
      @click="onCMClickForRoadEdit($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    pathId: String,
    pointId: String,
  },
  data: () => ({
    itemsForRoadEdit: [
      { id: 1, title: "Create Arc" },
      { id: 2, title: "ADD" },
      { id: 3, title: "Remove" },
      // { id: 4, title: "Extendroad" },
      { id: 5, title: "Update" },
      { id: 6, title: "Finishdrawing" },
      { id: 7, title: "Undo" },
      { id: 8, title: "Redo" },
      ...(process.env.VUE_APP_ENABLE_SEGMENT_SETTING_CONTEXT_MENU === 'true' ? [{ id: 9, title: "SegmentSetting" }]: [])
    ],
  }),
  methods: {
    onCMClickForRoadEdit(e, id) {
      e.preventDefault();
      const numberOFPathPoints = window["viewer"].getRoadPointLength(this.pathId);
      const clickedIndex = window["viewer"].getControlPointIndexById(this.pathId, this.pointId);
      switch (id) {
        case 1:
          window["viewer"].updateBendToCircle(this.pathId, this.pointId);
          break;
        case 2:
          window["viewer"].addControlPointOnRoad(
            this.pathId,
            this.pointId
          );
          window.dispatchEvent(new CustomEvent("addPointEvent", {
            detail: { roadId: this.pathId, pointIndex: clickedIndex },
          }));
          break;
        case 3:
          window["viewer"].removeControlPoint(
            this.pathId,
            this.pointId
          );
          window.dispatchEvent(new CustomEvent("deletePointEvent", {
            detail: { roadId: this.pathId, pointIndex: clickedIndex },
          }));
          break;
        // case 4:
        //   window["viewer"].roadExtension(this.pathId);
        //   break;
        case 5:
          window.dispatchEvent(new CustomEvent("openPropertyDialog", {
            detail: { id: this.pathId, type: 3, pointId: this.pointId, entityUnderEdit: "ROAD", disabilityConditions: "Z", origin: "CesiumView" },
          }));
          break;
        case 6:
          // eslint-disable-next-line no-case-declarations
          const roads = this.$store.state.objects.filter(
            (road) => road.id === this.pathId
          );
          if (roads.length > 0) {
            this.$store.state.modes.inEditMode = false;
            window["viewer"].enableRoadEdit(false, roads[0].id, () => { });
            roads[0].edit = false;
            // window["viewer"].toggleGlobeVisibility(true);
          }
          window["viewer"].clearCommandManager();
          break;  
        case 7:
          window["viewer"].undo();
          break;
        case 8:
          window["viewer"].redo();
          break;
        case 9:
          //openup dialog box          
          console.log("clicked pont", { id: this.pathId, type: 5, clickedPointIndex: this.pointId, totalPoints: numberOFPathPoints });
          window.dispatchEvent(new CustomEvent("openPropertyDialog", {
            detail: { id: this.pathId, type: 5, clickedPointIndex: clickedIndex, totalPoints: numberOFPathPoints },
          }));
          break;
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
