export default class Design {
  constructor(designId, designName, assetId, processingStatus, processingError, created_at, selected_date, selected_time, properties) {
    // APIから取得するデータ
    this.design_id = designId;
    this.design_name = designName;
    this.asset_id = assetId;
    this.processing_status = processingStatus;
    this.processing_error = processingError;
    this.created_at = new Date(created_at);
    this.selected_date = selected_date;
    this.selected_time =  selected_time;

    // フロント側で設定するデータ
    // Cesiumへのアップロード状況
    this.is_asset_uploaded = 'PENDING';
    // パネルの開閉状況
    this.expansion = false;
    // 設計データの可視状況
    this.visibility = false;
    // タイリング状況
    this.is_tiling = false;
    // タイリング進捗
    this.tiling_progress = false;
    this.properties =  {
      mesh_color: "#FFFFFF",
      transparency: 1
    }

    if(properties) {
      this.properties.mesh_color = properties.mesh_color
      this.properties.transparency = properties.transparency
    }
  }

  // getter
  getId() {
    return this.design_id;
  }

  getName() {
    return this.design_name;
  }

  getAssetId() {
    return this.asset_id;
  }

  getProcessingStatus() {
    return this.processing_status;
  }

  getProcessingError() {
    return this.processing_error;
  }

  getCreatedAt() {
    return this.created_at;
  }

  getTransparency() {
    return this.properties.transparency;
  }

  getIsAssetUploaded() {
    return this.is_asset_uploaded;
  }

  getExpansion() {
    return this.expansion;
  }

  getVisibility() {
    return this.visibility;
  }

  getIsTiling() {
    return this.is_tiling;
  }

  getTilingProgress() {
    return this.tiling_progress;
  }

  getSelectedDate() {
    return this.selected_date;
  }
  getSelectedTime() {
    return this.selected_time;
  }

  getTimeStamp() {
    const date = new Date(this.selected_date + "T" + this.selected_time);
    return this.selected_date && this.selected_time ? [
      date.toLocaleString('en-US', { day:"2-digit"}),
      date.toLocaleString('en-US', { month:"short"})+ ",",
      date.toLocaleString('en-US', { year:"numeric"}),
      date.toLocaleString('en-US', { hour:"numeric", minute:"2-digit"})
  ].join(" ") : "";
  }

  getMeshColor() {
    return this.properties.mesh_color
  }
  // setter
  setId(designId) {
    this.design_id = designId;
  }

  setName(designName) {
    this.design_name = designName;
  }

  setAssetId(assetId) {
    this.asset_id = assetId;
  }

  setProcessingStatus(processingStatus) {
    this.processing_status = processingStatus;
  }

  setProcessingError(processingError) {
    this.processing_error = processingError;
  }

  setCreatedAt(created_at) {
    this.created_at = new Date(created_at);
  }

  setTransparency(transparency) {
    this.properties.transparency = transparency;
  }

  setIsAssetUploaded(isAssetUploaded) {
    this.is_asset_uploaded = isAssetUploaded;
  }

  setExpansion(expansion) {
    this.expansion = expansion;
  }

  setVisibility(visibility) {
    this.visibility = visibility;
  }

  setIsTiling(tiling) {
    this.is_tiling = tiling;
  }

  setTilingProgress(tilingProgress) {
    this.tiling_progress = tilingProgress;
  }

  setSelectedDate(date) {
    this.selected_date = date;
  }
  setSelectedTime(time) {
    this.selected_time = time;
  }
  //color should be in hex format. ex- #ffffff
  setMeshColor(color) {
    this.properties.mesh_color = color
  }


  //functions

  update(data) {
    this.setName(data.design_name);
    // アセットidに変更がある場合
    if (this.getAssetId() !== data.asset_id) { 
      if (this.getIsTiling()) {
        // アセットを読み込み済みであればリムーブする
        window["viewer"].removeTileset(this.getAssetId(), "design");
      }
      // ステータスを初期化する
      this.initialize();
    } 
    this.setAssetId(data.asset_id);
    this.setProcessingStatus(data.processing_status);
    this.setProcessingError(data.processing_error);
    this.setCreatedAt(data.create_at);
    this.setSelectedDate(data.selected_date);
    this.setSelectedTime(data.selected_time);
    this.setMeshColor(data.properties.mesh_color);
    this.setTransparency(data.properties.transparency)

  }

  getPropertiesJSON() {
     return  JSON.stringify(this.properties)
  }

  initialize() {
    this.setIsAssetUploaded('PENDING');
    this.setExpansion(false);
    this.setVisibility(false);
    this.setIsTiling(false);
    this.setTilingProgress(false);
  }
  
}