// 仮設道路、平場関連のモジュール
// import store from '../index'

export const copyRoadProps = (target, source) => {
  target.id = source.id;
  target.cid = source.cid;
  target.type = source.type;
  target.name = source.name;
  target.isOpen = source.isOpen;
  Object.assign(target.panel, source.panel);
  target.cutVolume = source.cutVolume;
  target.fillVolume = source.fillVolume;
  target.transparency = source.transparency;
  Object.assign(target.info, source.info);
  Object.assign(target.settings.commonSettings, source.settings.commonSettings);
  Object.assign(target.settings.embarkmentSettings, source.settings.embarkmentSettings);
  Object.assign(target.settings.cutSettings, source.settings.cutSettings);
  Object.assign(target.cash, source.cash);
}

export const copyFlatProps = (target, source) => {
  target.id = source.id;
  target.cid = source.cid;
  target.type = source.type;
  target.name = source.name;
  target.isOpen = source.isOpen;
  Object.assign(target.panel, source.panel);
  target.cutVolume = source.cutVolume;
  target.fillVolume = source.fillVolume;
  target.transparency = source.transparency;
  if (source.settings.commonSettings !== undefined) Object.assign(target.settings.commonSettings, source.settings.commonSettings);
  Object.assign(target.settings.embankmentSettings, source.settings.embankmentSettings);
  Object.assign(target.settings.cutSettings, source.settings.cutSettings);
}