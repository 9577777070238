<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title>{{ this.$t("Updatecoordinatevalue") }}</v-card-title>
    <v-container>
      <v-row class="text-center">
        <template v-if="this.checkNEZ">
          <v-col cols="15" sm="4">
            <div>Y {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
          <v-col cols="15" sm="4">
            <div>X {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
        </template>
        <template v-else-if="!this.checkNEZ">
          <v-col cols="15" sm="4">
            <div>X {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
          <v-col cols="15" sm="4">
            <div>Y {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
        </template>
        <v-col cols="15" sm="4">
          <div>Z (m)</div>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <template v-if="this.checkNEZ">
          <v-col cols="15" sm="4">
            <v-text-field
              solo
              :error="false"
              class="mt-2"
              v-model="coordinateY"
              :disabled="disableY"
              @input="($event) => checkCoordinatesValidity($event, 'y')"
            >
            </v-text-field>
          </v-col>
          <v-col cols="15" sm="4">
            <v-text-field
              solo
              class="mt-2"
              v-model="coordinateX"
              :disabled="disableX"
              @input="($event) => checkCoordinatesValidity($event, 'x')"
            ></v-text-field>
          </v-col>
        </template>
        <template v-else-if="!this.checkNEZ">
          <v-col cols="15" sm="4">
            <v-text-field
              solo
              :error="false"
              class="mt-2"
              v-model="coordinateX"
              :disabled="disableX"
              @input="($event) => checkCoordinatesValidity($event, 'x')"
            ></v-text-field>
          </v-col>
          <v-col cols="15" sm="4">
            <v-text-field
              solo
              class="mt-2"
              v-model="coordinateY"
              :disabled="disableY"
              @input="($event) => checkCoordinatesValidity($event, 'y')"
            >
            </v-text-field>
          </v-col>
        </template>
        <v-col cols="15" sm="4">
          <v-text-field
            solo
            class="mt-2"
            v-model="coordinateZ"
            :disabled="disableZ"
            @input="($event) => checkCoordinatesValidity($event, 'z')"
          ></v-text-field>
        </v-col>
      </v-row>
      <p
        v-if="!isUpdateValid.x || !isUpdateValid.y || !isUpdateValid.z"
        class="pl-4"
        style="color: #f44336; font-size: 14px"
      >
        Please enter valid input
      </p>
      <div class="text-center">
        <v-btn
          @click="confirmUpdate"
          class="px-9 mx-10"
          color="primary"
          :disabled="!isUpdateValid.x || !isUpdateValid.y || !isUpdateValid.z"
          dark
        >
          {{ this.$t("OK") }}
        </v-btn>
        <v-btn @click="cancelUpdate" class="mx-10" color="warning">
          {{ this.$t("CANCEL") }}
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import {convertCoordinateToPointCloudCrs, convertCoordinateToCesiumCrs} from "./../../utils/cesium-common"

export default {
  name: "UpdateCtrlPointDialog",
  props: {
    id: String,
    pointId: String,
    entityUnderEdit: String,
    disabilityConditions: String,
    origin: String
  },
  beforeMount() {
    this.checkNEZ = this.$store.state.axis === "NEZ" ? true : false;
  },
  async mounted() {
    await this.initCoordinates();
  },
  data: () => ({
    x: 0,
    y: 0,
    z: 0,
    // prevPosition: null,
    isUpdateValid: {x: true, y: true, z: true},
    checkNEZ: null,
    disableX: false,
    disableY: false,
    disableZ: false,
  }),
  computed: {
    coordinateX: {
      get: function () { return this.checkInput(this.x) },
      set: function (str) { this.x = parseFloat(str) }
    },
    coordinateY: {
      get: function () { return this.checkInput(this.y) },
      set: function (str) { this.y = parseFloat(str) }
    },
    coordinateZ: {
      get: function () { return this.checkInput(this.z) },
      set: function (str) { this.z = parseFloat(str) }
    }
  },
  methods: {
    checkInput(input) {
      if (Number.isNaN(input)) {
        return ''
      }
      const strVal = input.toString();
      return strVal.split('.')[1] ? strVal.split('.')[1].length <= 3
          ? strVal
          : input.toFixed(3)
        : strVal;
    },
    async initCoordinates() {
      this.x = 0;
      this.y = 0;
      this.z = 0;
      this.isUpdateValid = { x: true, y: true, z: true };
      this.$nextTick(async () => {
        // placement of Y and X text boxes
        this.checkNEZ = this.$store.state.axis === "NEZ" ? true : false;

        // disability conditions
        if (this.disabilityConditions === "Z") {
          this.disableX = false;
          this.disableY = false;
          this.disableZ = true;
        } else if (this.disabilityConditions === "XY") {
          this.disableX = true;
          this.disableY = true;
          this.disableZ = false;
        } else if (this.disabilityConditions === "NONE") {
          this.disableX = false;
          this.disableY = false;
          this.disableZ = false;
        }

        if (!this.pointId) return;

        let pos = undefined;

        if (this.origin === "CesiumView") {

          // function to get coordinates of point
          pos = this.entityUnderEdit === "ROAD"
            ? window["viewer"].getCtrlPointCordiOnRoad(this.id, this.pointId)
            : window["viewer"].getCtrlPointCordiOnFlatGround(this.id, this.pointId);

        } else if (this.origin === "AltitudeView" && this.entityUnderEdit === "ROAD") {

          const res = window["viewer"].getSlopeBreakPointInfoOnRoad(this.id, parseInt(this.pointId));

          pos = res.point;

        }

        if (!pos) {
          return;
        }


        const response = await convertCoordinateToPointCloudCrs(pos);
        this.x = response.x;
        this.y = response.y;

        if (this.entityUnderEdit === "ROAD") {
          const offset = window["viewer"].getOffsetForAssociatedPointCloudWithRoad(this.id);
          const cesiumAltitude = window["viewer"].getAltitude(pos);
          this.z = cesiumAltitude - offset;
        } else {
          this.z = response.z;
        }

        // this.prevPosition = pos;
      });
    },
    checkCoordinatesValidity(inputValue, prop) {
      this.isUpdateValid[prop] = inputValue && Number.isFinite(Number(inputValue));
    },
    cancelUpdate() {
      // This snippet is not required as now control point is updated on confirm click.
      // if (this.prevPosition) {
      //   this.entityUnderEdit === "ROAD" 
      //     ? window["viewer"].updateCtrlPointCordiOnRoad(this.id, this.pointId, this.prevPosition.x, this.prevPosition.y, this.prevPosition.z) 
      //     : window["viewer"].updateCtrlPointCordiOnFlatGround(this.id, this.pointId, this.prevPosition.x, this.prevPosition.y, this.prevPosition.z);
      // }

      this.$emit("close");
    },
    async confirmUpdate() {
      if (this.isUpdateValid.x && this.isUpdateValid.y && this.isUpdateValid.z && this.x && this.y && this.z) {

        const response = await convertCoordinateToCesiumCrs({x:this.x, y: this.y, z: this.z});
        const outX = Number(response.x);
        const outY = Number(response.y);
        const outZ = Number(response.z);

        if (this.origin === "CesiumView") {

          // function to update coordinates this takes coordinates for cesium crs.
          this.entityUnderEdit === "ROAD"
            ? window["viewer"].updateCtrlPointCordiOnRoad(this.id, this.pointId, outX, outY, outZ)
            : window["viewer"].updateCtrlPointCordiOnFlatGround(this.id, this.pointId, outX, outY, outZ);

        } else if (this.origin === "AltitudeView") {

          if (this.entityUnderEdit === "ROAD") {
            const slopeBreaks = window["viewer"].getSlopeBreaks(this.id);
            slopeBreaks[parseInt(this.pointId)].height = this.z + window["viewer"].getOffsetForAssociatedPointCloudWithRoad(this.id);
            window["viewer"].updateSlopeBreaksFromLongitudinalView(this.id, slopeBreaks);
          }

        }
      }
      this.$emit("close", false); 
    },
  },
};
</script>
<style scoped>
</style>