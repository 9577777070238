<template>
    <v-card>
        <v-card-title>
            {{$t("SELECT_DATA_TYPE")}}
        </v-card-title>
        <v-card-text>
            
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: "UploadData",
  props: {
    id: String,
  },
  mounted: () => {},
  data: () => ({

  }),
  methods: {

  },
};
</script>
