<template>
  <v-card>
    <v-card-title>{{ $t("UPLOAD") }}</v-card-title>
    <v-btn class="closeDialogIcon" fab absolute top right @click="close(false)">
      <v-icon> mdi-close </v-icon>
    </v-btn>
    <v-container style="width: 90%" class="ma-4">
      <v-row>{{$t("SELECT_FILES")}} </v-row>
      <v-row>
          <v-file-input
            outlined
            class="fileInp1"
            v-model="file"
            :accept="allowedFileTypes"
            @change="fileInp1Changed()"
            @click="file = null"
          >
            <template #selection>
              {{ file.name }}
            </template>
        </v-file-input>
      </v-row>
      <v-row>{{$t("ASSET_NAME")}} </v-row>
      <v-row>
          <v-col>
            <v-text-field
              outlined
              :rules="nameRules"
              v-model="name"
              required
            />
          </v-col>
      </v-row>
      <v-row>{{$t("ASSET_CAPTURE_DATE")}}</v-row>
      <v-row>
          <v-col>
            <v-text-field
              outlined
              type="date"
              v-model="date"
              required
            />
          </v-col>
          <v-col>
            <v-text-field
              outlined
              type="time"
              time-format="HH:mm"
              class="text-xs-center"
              v-model="time"
              required
            />
          </v-col>
      </v-row>
      <span v-if="axis_show">
        <v-row>{{$t("axis")}}</v-row>
        <v-row>
          <v-col>
            <v-select
              outlined
              :items="axis_list"
              v-model="axis"
              :disabled="disableAxisSelection"
              required
              @change="updateAxisVal()"
            ></v-select>
          </v-col>
        </v-row>
      </span>
      <v-row>
        <v-btn
          color="primary"
          class="uploadBtn"
          id="upload_btn"
          :disabled="isbtnDisabled"
          rounded
          @click="uploadData()"
        >
          {{$t("UPLOAD")}}
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: "addTerrainData",
  props: {
    allowedFileTypes: String,
    axis_show: Boolean,
    is_show: Boolean
  },
  mounted() {
    this.setDateTime();
  },
  data: () => ({
    file: null,
    name: "",
    nameRules: [
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    date: undefined,
    time: undefined,
    axis_list: ['ENZ','NEZ','ENZRGB' ,'NEZRGB','ENZiRGB' ,'NEZiRGB','ENZRGBi' ,'NEZRGBi'],
    axis: "",
  }),
  computed:{
    disableAxisSelection(){
      return this.file?.name.split(".").pop() === "las";
    },
    isbtnDisabled() {
      if (this.file && this.name && this.date && this.time && this.name.length <=10 && !(/^\s*$/.test(this.name))) {
        return false;
      } else return true;
    },
  },
  watch: {
    is_show(value) {
      if (value) this.setDateTime(); 
    }
  },
  methods: {
    open(){
      this.axis = this.axis_list[0];
      this.$store.state.axis = this.axis_list[0];
    },
    updateAxisVal() {
      this.$store.state.axis = this.axis;
    },
    fileInp1Changed(){
      let txtContainer = document.querySelector(".fileInp1 .v-file-input__text");
      if(txtContainer){
        txtContainer.innerHTML = "";
      }
      if(this.file?.name.split(".").pop() === "las") {
        this.axis = this.axis_list[0];
        this.updateAxisVal()
      } 
    },
    close(openLFDialog) {
      if(openLFDialog){
        let data = [this.file, this.name, this.date, this.time];
        if(this.axis_show){
          data.push(this.axis);
        }
        this.file = undefined;
        this.fileInp1Changed();
        this.name = "";
        this.date = undefined;
        this.time = undefined;
        this.$emit("close", data);
      }
      else {
        this.file = null;
        this.name = "";
        this.date = undefined;
        this.time = undefined;
        this.fileInp1Changed();
        this.$emit("close", undefined);
      }
    },
    uploadData() {
      console.log("--START UPLOAD--");
      if (!this.name || !this.file || !this.date || !this.time) {
        alert("Please fill all the fields !!!");
      } else {
        this.close(true);
      }
    },
    setDateTime() {
      const today = new Date();
      // 日付を設定
      this.date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");

      // 時間を設定
      this.time = 
        today.getHours().toString().padStart(2, "0") +
        ":" +
        today.getMinutes().toString().padStart(2, "0")
    },
  },
};
</script>
<style scoped>
.closeDialogIcon {
  margin-top: 5%;
}

.fileInp1 ::v-deep .v-text-field__slot {
    max-width: 100%;
    overflow: hidden;
}

.fileInp1 ::v-deep .v-file-input__text {
    width: 100%;
    display: block;
    white-space: nowrap;
    height: 50px;
    line-height: 50px;
}

.uploadBtn {
  margin-bottom: 3%;
}

</style>