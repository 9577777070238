<template>
  <div>
    <tool-panel ref="tool_panel" />
    <chart-board ref="chartboard" />
    <v-dialog v-model="showExportDialog" width="32%" max-width="500px" persistent>
      <export-dialog
        :id="id"
        :type="type"
        :pointId="pointId"
        :name="name"
        @close="closePropertyDialog()"
        ref="export_dialog"
      />
    </v-dialog>
    <v-dialog v-model="showPropertyDialogRoad" width="72%" max-width="1072px" persistent>
      <property-dialog-road
        :id="id"
        :type="type"
        @close="closePropertyDialog()"
        ref="property_dialog_road"
      />
    </v-dialog>
    <v-dialog v-model="showPropertyDialogFlat" width="72%" max-width="1072px" persistent>
      <property-dialog-flat
        :id="id"
        :type="type"
        @close="closePropertyDialog()"
        ref="property_dialog_flat"
      />
    </v-dialog>
    <v-dialog v-model="showUpdateCtrlPointDialog" width="32%" max-width="1072px" persistent>
      <update-ctrl-point-dialog
      :id="id"
      :pointId="pointId"
      :entityUnderEdit="entityUnderEdit"
      :disabilityConditions="disabilityConditions"
      :origin="origin"
      @close="closePropertyDialog()"
      ref="update_ctrl_point_dialog"/>
    </v-dialog>
    <v-dialog v-model="showAddRoadTerrainByAI" width="100%" persistent>
      <AddRoadTerrainByAI
        :id="id"
        :type="type"
        @close="closePropertyDialog()"
        ref="property_dialog_road_ai"
      />
    </v-dialog>
    <v-dialog v-model="showAddRoadByJson" width="100%" persistent>
      <AddRoadByJson
        :id="id"
        :type="type"
        @close="closePropertyDialog()"
        ref="property_dialog_json"
      />
    </v-dialog>

    <v-dialog v-model="showSegmentSelection" max-width="460px" persistent>
      <SegmentSelectionDialog
        :id="id"
        :start-index="startIndex"
        :points-count="pointsCount"
        @close="closePropertyDialog()"
        ref="segment_selection_dialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import ToolPanel from "../components/Project/ToolPanel.vue";
import ChartBoard from "../components/Project/ChartBoard/ChartBoard.vue";
import * as cesiumCommon from '@/utils/cesium-common';
import ExportDialog from "../components/Project/ExportDialog.vue";
import PropertyDialogRoad from "../components/Project/PropertyDialogRoad.vue";
import PropertyDialogFlat from "../components/Project/PropertyDialogFlat.vue";
import UpdateCtrlPointDialog from '../components/Project/UpdateCtrlPointDialog.vue';
import AddRoadTerrainByAI from "../components/Project/AddRoadTerrainByAI.vue";
import SegmentSelectionDialog from "../components/Project/SegmentSelectionDialog.vue";
import AddRoadByJson from "../components/Project/AddRoadByJson";
import axios from "axios";
import { mapMutations, mapActions } from 'vuex';

export default {
  name: "ProjectPage",
  components: {
    ToolPanel,
    ChartBoard,
    ExportDialog,
    PropertyDialogRoad,
    PropertyDialogFlat,
    UpdateCtrlPointDialog,
    AddRoadTerrainByAI,
    AddRoadByJson,
    SegmentSelectionDialog
},
  data: () => ({
    showExportDialog: false,
    showPropertyDialogRoad: false,
    showPropertyDialogFlat: false,
    showUpdateCtrlPointDialog: false,
    showAddRoadTerrainByAI: false,
    showAddRoadByJson: false,
    showSegmentSelection:false,
    id: null,
    type: null,
    pointId: null,
    entityUnderEdit: null,
    disabilityConditions: null,
    origin: null,
    startIndex:0,
    pointsCount:0,
    name: null,
  }),
  async mounted() {
    window.addEventListener('popstate', function(){
      window.location.href = window.location.origin;
    });
    window.addEventListener("openPropertyDialog", this.openPropertyDialog);
    await this.$nextTick();
    // this.$refs.chartboard.updateTab();
    // document.getElementsByTagName("body")[0].onresize=()=>{
    //   this.$refs.chartboard.updateTab();
    // }
    this.getSiteInfo();
    this.getPointCloudList();
    try {
      await this.get_design_list(this.$route.query.siteId);
    } catch (error) {
      console.error("GET DESIGN LIST", error.message);
    }
    this.loadObj(this.$route.query.siteId);
  },
  destoroyed() {
    window.removeEventListener("openPropertyDialog");
  },
  destroyed() {
    window["viewer"].clear();
    this.set_point_cloud_list([]);
    this.set_design_list([]);
  },
  methods: {
    ...mapMutations(['set_point_cloud_list', 'set_design_list', 'initObj']),
    ...mapActions(['get_point_cloud_list', 'get_design_list', 'get_obj_list']),
    getSiteInfo() {
      axios
        .get(
          `${process.env.VUE_APP_API_BASE}/sites/${this.$route.query.siteId}`,
          {
            auth: this.$store.state.authObject,
            withCredentials: false
          }
        )
        .then((response) => {
          console.log(response);
          this.$store.commit("setSite", response.data);
        }).catch(err => {
          console.log("GET SITE INFO", err);
        });
    },
    async getPointCloudList() {
      try {
        await this.get_point_cloud_list(this.$route.query.siteId);
        // 点群が存在する場合、最後に更新された点群をタイリングする
        if (this.$store.state.point_cloud_list.length) {
          const lastUpdatedPointCloud = this.$store.state.point_cloud_list.slice().sort((a, b) => {
            const updatedAtA = a.getUpdatedAt();
            const updatedAtB = b.getUpdatedAt();
  
            if (updatedAtA < updatedAtB) return 1; 
            if (updatedAtA > updatedAtB) return -1; 
          })[0];
  
          if (lastUpdatedPointCloud.getAssetId()) {
            if (await lastUpdatedPointCloud.waitAssetUploading() === 'DONE') {
              cesiumCommon.togglePointCloudVisibility(lastUpdatedPointCloud, this.$store.getters.getRoads);
            }
          }
        }
      } catch (error) {
        console.log("GET POINT CLOUD LIST", error);
      }
    },
    loadObj(siteId) {
      this.initObj();
      this.get_obj_list(siteId);
    },
    openPropertyDialog(e) {
      this.id = e.detail.id;
      this.type = e.detail.type;
      this.pointId = e.detail.pointId;
      this.entityUnderEdit = e.detail.entityUnderEdit;
      this.disabilityConditions = e.detail.disabilityConditions;
      this.origin = e.detail.origin;
      switch (this.type) {
        case 0:
          this.showPropertyDialogRoad = true;
          this.$refs.property_dialog_road &&
            this.$refs.property_dialog_road.resetProperty();
          break;
        case 1:
          this.showPropertyDialogFlat = true;
          this.$refs.property_dialog_flat &&
            this.$refs.property_dialog_flat.resetProperty();
          break;
        case 2:
          // 仮設道路AI 地形データ選択ダイアログ(W002-09)
          this.showAddRoadTerrainByAI = true;
          this.$refs.property_dialog_road_ai &&
          this.$refs.property_dialog_road_ai.resetProperty();
          
          this.$refs.property_dialog_road_ai &&
          this.$refs.property_dialog_road_ai.fetchDesignItems();
          
          this.$refs.property_dialog_road_ai &&
          this.$refs.property_dialog_road_ai.fetchPointCloudItems();
          break;
        case 3:
          this.showUpdateCtrlPointDialog = true;
          this.$refs.update_ctrl_point_dialog &&
            this.$refs.update_ctrl_point_dialog.initCoordinates();
          break;
        case 4:
          // 仮設道路AI設定ダイアログ(W002-10)
          console.log("AddRoadByJson dialog");
          this.showAddRoadByJson = true;
          // 画面初期表示
          this.$refs.property_dialog_json &&
            this.$refs.property_dialog_json.setInitialize();
          break;
        case 5:
          // 仮設道路AI設定ダイアログ(W002-10)
          console.log("Show segment Selection dialog");
          //need start index
          //max possibleindex => get from store
          //road id
          //
          console.log("params", e.detail);
          this.startIndex = e.detail.clickedPointIndex;
          this.pointsCount = e.detail.totalPoints;
          this.showSegmentSelection = true;          
          break;
        case 6:
          this.name = e.detail.name;
          this.showExportDialog = true;
          this.$refs.export_dialog &&
            this.$refs.export_dialog.resetProperty();
          break;
        default:
          break;
      }
    },
    closePropertyDialog() {
      this.$store.state.modes.inCreationMode = false;
      this.showExportDialog = false;
      this.showPropertyDialogRoad = false;
      this.showPropertyDialogFlat = false;
      this.showUpdateCtrlPointDialog = false;
      this.showAddRoadTerrainByAI = false;
      this.showAddRoadByJson = false;
      this.showSegmentSelection = false; 
      this.$refs.tool_panel.isRoadAdd = false;
      this.$refs.tool_panel.isFlatAdd = false;
      this.$refs.tool_panel.isAIRoadAdd = false;
      if (this.$refs.property_dialog_road) {
        this.$refs.property_dialog_road.isRoadAdded = false;
      }
      if (this.$refs.property_dialog_flat) {
        this.$refs.property_dialog_flat.isFlatGroundAdded = false;
      }
      // this.$refs.tool_panel.isPreprocessConfirm = false;
    },
  }
};
</script>

<style scoped></style>
