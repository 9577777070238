<template>
  <div v-show="coordinates.length">
  <div class="border" >
    <div class="progress">
      <v-progress-circular v-show="showProgress" :size="16" indeterminate ></v-progress-circular>
    </div>
    <div :class="showProgress?'un_active':'active'">
      <label>Y ({{ $t('MEASUREMENT_SYSTEM') }})</label> <span>{{ metres[1] }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <label>X ({{ $t('MEASUREMENT_SYSTEM') }})</label> <span>{{ metres[0] }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <label>Z ({{ $t('MEASUREMENT_SYSTEM') }})</label> <span>{{ metres[2] }}</span>
    </div>
  </div>
  </div>
</template>
<script>

export default {
  name: "CoordinatePanel",
  data() {
    return {
      metres:[]
    }
  },
  props: {
    coordinates: Array,
    showProgress:Boolean
  },
  watch: {
    coordinates(newValue) {
      this.metres = [`${newValue[0]?.toFixed(3) ?? 0}m`, `${newValue[1]?.toFixed(3) ?? 0}m`, `${newValue[2]?.toFixed(3) ?? 0}m`]; 
    },
  }
};
</script>
<style scoped>
.border {
  position: absolute;
  padding: 4px;
  display: flex;
  z-index: 1;
  right: 53px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-items: center;
}

.progress {
  opacity: 0.8;
  margin-left: 4px;
  margin-right: 4px;
  width: 20px;
}
.active,.un_active label{
  opacity: 0.8;
}

.un_active span {
  opacity: 0.5;
}

</style>