<template>
  <v-container id="Cesium" fluid class="pa-0" @click.left="DisableContextMenu" @contextmenu="show"
    @mousemove="fillXYZCoordinates"
    :style="`height:calc(100vh - ${this.$vuetify.application.top}px - ${this.$vuetify.application.insetFooter}px); transition: all 0.2s; border: 1px solid #434348;`">
    <v-menu v-model="showMenuForRoadCreation" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <road-creation-context-menu></road-creation-context-menu>
    </v-menu>
    <v-menu v-model="showControlPointMenuForRoadEdit" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <road-edition-context-menu :path-id="controlPointPathInfo.pathId"
        :point-id="controlPointPathInfo.pointId"></road-edition-context-menu>
    </v-menu>
    <v-menu v-model="showMenuForFlatGroundCreation" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <flat-ground-creation-context-menu></flat-ground-creation-context-menu>
    </v-menu>

    <v-menu v-model="showMenuForFlatGroundEdit" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <flat-ground-edition-context-menu :flat-ground-id="flatGroundInfo.flatGroundId"
        :point-id="flatGroundInfo.pointId"></flat-ground-edition-context-menu>
    </v-menu>
    <v-menu v-model="showMenuForFlatGroundOutlineEdit" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <flat-ground-outline-edit-context-menu :flat-ground-id="flatGroundInfo.flatGroundId"
        :position="flatGroundInfo.position"></flat-ground-outline-edit-context-menu>
    </v-menu>
    <v-menu v-model="showRoadSegmentMenuForRoadEdit" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <road-segment-context-menu :path-id="roadSegmentPathInfo.pathId"
        :segment-id="roadSegmentPathInfo.segId" :point="roadSegmentPathInfo.position"></road-segment-context-menu>
    </v-menu>
    <v-menu v-model="showMenuForCenterLineRightClick" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <road-center-line-context-menu :path-id="roadCenterLinePathInfo.pathId" :point="roadCenterLinePathInfo.position"></road-center-line-context-menu>
    </v-menu>
    <point-selection-overlay :show="modes.ai_road_point_selection" />

    <v-menu v-model="showMenuForPointSelection" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <point-selection-context-menu></point-selection-context-menu>
    </v-menu>
    <div style="
                    display: inline-flex;
                    flex-direction: column;
                    position: absolute;
                    z-index: 1;
                    right: 10px;
                    top:55px;
                  ">
      <v-btn @click="click_plus" outlined tile class="btn1" style="border-radius: 16px 16px 0px 0px;">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn @click="click_home" outlined tile class="btn1">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn @click="click_minus" outlined tile class="btn1" style="border-radius: 0px 0px 16px 16px;">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </div>
    <CoordinatePanel v-if="this.$route.query.siteId" :coordinates="[...coordinates]" :show-progress="fetchCoordinateProgress" />
  </v-container>
</template>

<style>
.btn1 {
  width: 16px !important;
  min-width: 0px !important;
  background-color: rgba(0, 0, 0);
}
</style>

<script>
import "cesium/Build/Cesium/Widgets/widgets.css";
import PointSelectionOverlay from "./PointSelectionOverlay"
import RoadCreationContextMenu from "./CesiumViewContextMenu/RoadCreationContextMenu.vue"
import RoadEditionContextMenu from "./CesiumViewContextMenu/RoadEditionContextMenu.vue"
import FlatGroundCreationContextMenu from "./CesiumViewContextMenu/FlatGroundCreationContextMenu.vue";
import FlatGroundEditionContextMenu from "./CesiumViewContextMenu/FlatGroundEditionContextMenu.vue";
import FlatGroundOutlineEditContextMenu from "./CesiumViewContextMenu/FlatGroundOutlineEditContextMenu.vue";
import PointSelectionContextMenu from "./CesiumViewContextMenu/PointSelectionContextMenu.vue";
import RoadSegmentContextMenu from "./CesiumViewContextMenu/RoadSegmentContextMenu.vue";
import RoadCenterLineContextMenu from "./CesiumViewContextMenu/RoadCenterLineContextMenu.vue";
import { mapMutations, mapState } from 'vuex';
import axios from "axios";
import CoordinatePanel from "@/components/CoordinatePanel.vue";
import {convertCoordinateToPointCloudCrs} from "./../utils/cesium-common"

const debounce = (func, wait = 1000) => {
  let timerId;
  return function(...args){
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export default {
  components: {
    CoordinatePanel,
    PointSelectionOverlay,
    RoadCreationContextMenu,
    RoadEditionContextMenu,
    FlatGroundCreationContextMenu,
    FlatGroundEditionContextMenu,
    FlatGroundOutlineEditContextMenu,
    PointSelectionContextMenu,
    RoadSegmentContextMenu,
    RoadCenterLineContextMenu
  },
  computed: {
    ...mapState(['modes','panelName'])
  },
  watch:{
    panelName(){
      this.showMenuForRoadCreation = false;
      this.showMenuForFlatGroundCreation = false;
      this.showMenuForRoadEdit = false;
      this.showMenuForFlatGroundEdit = false;
      this.showMenuForFlatGroundOutlineEdit = false;
      this.showMenuForPointSelection = false;
      this.showMenuForCenterLineRightClick = false;
    }
  },
  data: () => ({
    showMenuForRoadCreation: false,
    showMenuForFlatGroundCreation: false,
    showControlPointMenuForRoadEdit: false,
    showRoadSegmentMenuForRoadEdit: false,
    showMenuForFlatGroundEdit: false,
    showMenuForFlatGroundOutlineEdit: false,
    showMenuForPointSelection: false,
    showMenuForCenterLineRightClick: false,
    x: 0,
    y: 0,
    offsetX: 0,
    offsetY: 0,
    isPathUC: false,
    isFlatGroundUC: false,
    isroadOrFlatArea: "Road",
    controlPointPathInfo: { pathId: undefined, pointId: undefined },
    roadSegmentPathInfo: { pathId: undefined, segId: undefined, position: undefined },
    roadCenterLinePathInfo: { pathId: undefined, position: undefined },
    flatGroundInfo: { flatGroundId: undefined, pointId: undefined },
    all_sites: [],
    coordinates: [],
    fetchCoordinateProgress:false,
  }),
  methods: {
    ...mapMutations(['set_modes']),
    convertCoordinate(coordinates){
      const regx = /[°'EN.]/g;
      if (coordinates.length > 0) {
        const latitudeStringArray = coordinates[0].replace(regx, '').split(" ");
        const longitudeStringArray = coordinates[1].replace(regx, '').split(" ");
        const latitudeString = `${latitudeStringArray[0]}.${latitudeStringArray[1]}${latitudeStringArray[2]}`;
        const longitudeString = `${longitudeStringArray[0]}.${longitudeStringArray[1]}${longitudeStringArray[2]}`;
        return [latitudeString,longitudeString];
      }
    },
    async getMetres(coordinates){
      if (coordinates.length>0 && this.$route.query.siteId) {
        const convertedCoords = await convertCoordinateToPointCloudCrs({x: coordinates[0],y:coordinates[1],z:coordinates[2]});
        return [convertedCoords.x, convertedCoords.y, convertedCoords.z];
      }
    },
    openWarningSnackbarForInvalidCtrlPtRoad() {
      this.$store.commit("set_snackbar", {
        text: `${this.$t("Roadctrlptwarning")}`,
        color: 'rgba(245, 124, 0, 0.72)'
      });
    },
    show(e) {
      this.$store.dispatch("changePanel","Cesium")
      e.preventDefault();
      this.showMenuForRoadCreation = false;
      this.showMenuForFlatGroundCreation = false;
      this.showControlPointMenuForRoadEdit = false;
      this.showMenuForFlatGroundEdit = false;
      this.showMenuForFlatGroundOutlineEdit = false;
      this.showMenuForPointSelection = false;
      this.showRoadSegmentMenuForRoadEdit = false;
      this.showMenuForCenterLineRightClick = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.offsetX = e.offsetX;
      this.offsetY = e.offsetY;
      this.$nextTick(() => {
        if (this.$store.state.modes.inEditMode) {
          let isEditableSelected = false;
          const editableRoads = this.$store.state.objects.filter(
            (road) => road.edit === true
          );
          if (editableRoads.length > 0) {
            //{ pathId: string | undefined; entityId: string | undefined, type: Pickable, entity: any}
            const info = window["viewer"].pickPathandPoint(
              e.offsetX,
              e.offsetY,
              "cesiumView"
            );
            if (info.pathId !== undefined && info.entityId !== undefined) {
              const editableRoad = editableRoads.filter(editable => editable.id === info.pathId);
              if (editableRoad.length > 0) {
                if (info.type === "road-control-point") {
                  this.controlPointPathInfo.pathId = info.pathId;
                  this.controlPointPathInfo.pointId = info.entityId;
                  this.showControlPointMenuForRoadEdit = true;
                }
                else if (info.type === "road-segment") {
                  this.roadSegmentPathInfo.pathId = info.pathId;
                  this.roadSegmentPathInfo.segId = info.entityId;
                  this.roadSegmentPathInfo.position = info.position;
                  this.showRoadSegmentMenuForRoadEdit = true;
                } else if (info.type === 'road-center-line') {
                  this.roadCenterLinePathInfo.pathId = info.pathId;
                  this.roadCenterLinePathInfo.position = info.position;
                  this.showMenuForCenterLineRightClick = true;
                }
              }
            }
            // for (const editable of editableRoads) {
            //   if (editable.id === controlPointPathinfo.pathId) {
            //     isEditableSelected = true;
            //     break;
            //   }
            // }
            // if (isEditableSelected) {
            //   if (
            //     controlPointPathinfo.pointId !== undefined &&
            //     controlPointPathinfo.pathId !== undefined
            //   ) {
            //     this.controlPointPathInfo.pathId = controlPointPathinfo.pathId;
            //     this.controlPointPathInfo.pointId = controlPointPathinfo.pointId;
            //     this.showControlPointMenuForRoadEdit = true;
            //   }
            // }
          }
          const editableFlatGround = this.$store.state.objects.filter(
            (flatGround) => flatGround.edit === true
          );
          if (editableFlatGround.length > 0) {
            const flatGroundInfo = window["viewer"].pickFlatGroundEntities(
              e.offsetX,
              e.offsetY
            );
            for (const editable of editableFlatGround) {
              if (editable.id === flatGroundInfo.flatGroundId) {
                isEditableSelected = true;
                break;
              }
            }
            if (isEditableSelected) {
              if (
                flatGroundInfo.flatGroundId !== undefined
              ) {
                this.flatGroundInfo.flatGroundId = flatGroundInfo.flatGroundId;
                this.flatGroundInfo.pointId = flatGroundInfo.pointId;
                if (flatGroundInfo.position) {
                  this.flatGroundInfo.position = flatGroundInfo.position;
                  this.showMenuForFlatGroundOutlineEdit = true;
                } 
                if (this.flatGroundInfo.pointId) {
                  this.showMenuForFlatGroundEdit = true;
                }
              }
            }
          }
        }
        if (this.$store.state.modes.inCreationMode) {
          const isPathUC = window["viewer"].checkRoadUnderCreation();
          const isdragged = window["viewer"].isMouseDraggedAfterRightClick();
          if (isPathUC === true && isdragged == false) {
            this.isPathUC = isPathUC;
            this.showMenuForRoadCreation = true;
          }
          const isFlatGroundUC =
            window["viewer"].checkFlatGroundUnderCreation();
          if (isFlatGroundUC === true && isdragged == false) {
            this.isFlatGroundUC = isFlatGroundUC;
            this.showMenuForFlatGroundCreation = true;
          }
        }
        if (this.modes.ai_road_point_selection) {
          this.showMenuForPointSelection = true;
        }
      });
    },
    DisableContextMenu() {
      this.showMenuForFlatGroundEdit = false;
      this.showMenuForRoadCreation = false;
      this.showMenuForFlatGroundCreation = false;
      this.showControlPointMenuForRoadEdit = false;
      this.showMenuForPointSelection = false;
      this.showRoadSegmentMenuForRoadEdit = false;
      this.showMenuForCenterLineRightClick = false;
    },
    click_plus() {
      window["viewer"].zoomInSite();
    },
    click_home() {
      if (this.$route.query.siteId) {
        //add code here for site location display
        for (const site of this.all_sites) {
          if (site.siteId === this.$route.query.siteId) {
            window["viewer"].focusSiteLocation(Number(site.longitude), Number(site.latitude))
          }
        }

      } else {
        //add code here for globe display")
        window["viewer"].showGlobe();
      }
    },
    click_minus() {
      window["viewer"].zoomOutSite();
    },
    fillDMSCoordinates(e) {
      let dmsCoordinates = window["viewer"].getDMSCoordinates(e.pageX, e.pageY);
      if (dmsCoordinates !== undefined) {
        this.coordinates.length = 0;
        this.coordinates.push(dmsCoordinates[0], dmsCoordinates[1]);
      }
    },
    fillXYZCoordinates: debounce(function(e) {
      this.fetchCoordinateProgress = true;
      let coordinate = window["viewer"].getXYZCoordinates(e.offsetX, e.offsetY);
      if (coordinate !== undefined) {
        this.getMetres(coordinate).then((coords)=>{
          this.coordinates.length = 0;
          this.coordinates.push(...coords);
          this.fetchCoordinateProgress = false;
        })
      }
    }, 100),
  },
  async mounted() {
    console.log("Cesium Container Initialized");
    window.addEventListener("invalidControlPointForRoad", this.openWarningSnackbarForInvalidCtrlPtRoad.bind(this));
    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE}/sites`,
      {
        auth: this.$store.state.authObject
      }
    );
    this.all_sites = response.data.sites;
    // 勾配単位を設定
    window["viewer"].updateLongitudeRenderingType(this.$store.state.longitudinalSectionViewerSlopeUnit.nextGradient);
    window["viewer"].updateCrossSectionRenderingType(this.$store.state.crossSectionViewerSlopeUnit.slopeGradient);
  },
  unmounted() {
    window.removeEventListener("invalidControlPointForRoad", this.openWarningSnackbarForInvalidCtrlPtRoad.bind(this));
  }
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
