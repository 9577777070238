var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{attrs:{"hide-details":"","dense":"","type":"number","min":_vm.minValue,"step":_vm.step,"suffix":_vm.suffixToDisplay,"rules":[
      _vm.rules.required,
      _vm.rules.orLess(_vm.maxValue),
      _vm.rules.orMore(_vm.minValue),
      _vm.rules.decimalLength(_vm.decimalLength),
      _vm.rules.numberFormat
      ],"disabled":!_vm.isEditable},on:{"input":_vm.onChange,"blur":function($event){return _vm.$emit('blur')}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=_vm._n($$v)},expression:"value"}})
}
var staticRenderFns = []

export { render, staticRenderFns }