<template>
    <v-simple-table dense style="background-color: rgba(41, 41, 47, 0)">
        <tbody>
            <!-- 法面高さ -->
            <tr>
                <td style="width: 50%">
                    {{ $t("Slopeheight") }}
                </td>
                <td>
                    <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m" :rules="[
                        rules.orLess(99.99),
                        rules.orMore(0.01),
                        rules.decimalLength(2),
                        rules.numberFormat
                    ]"
                        :disabled="!property.settings[moundTypeSettings].generateShelf || !withSlope"
                        @input="updateHeightDbSide()" v-model.number="property.settings[moundTypeSettings].height" />
                </td>
            </tr>
            <!-- 法面勾配 -->
            <tr>
                <td>
                    {{ $t("Slopegradient") }}
                </td>
                <td>
                    <SlopeSettingInput :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                        :is-editable="withSlope"
                        :slope-value="property.settings[moundTypeSettings].moundSlope" :step="0.01"
                        @input="updateMoundSlopeDbSide()" v-model.number="property.settings[moundTypeSettings].moundSlope"
                        :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99" :min-value="0.01"
                        :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>
                </td>
            </tr>
            <!-- 小段あり -->
            <tr>
                <td>
                    {{ $t("Thereisberm") }}
                </td>
                <td>
                  <v-checkbox
                    :disabled="!withSlope"
                    hide-details
                    dense
                    class="ma-0"
                    @click="emitUpdatedSettings()"
                    v-model="property.settings[moundTypeSettings].generateShelf"
                  ></v-checkbox>
                </td>
            </tr>
            <!-- 小段幅 -->
            <tr>
                <td>
                    {{ $t("Bermwidth") }}
                </td>
                <td>
                    <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m" :rules="[
                        rules.orLess(99.99),
                        rules.orMore(0.01),
                        rules.decimalLength(2),
                        rules.numberFormat
                    ]" :disabled="!property.settings[moundTypeSettings].generateShelf || !withSlope"
                        @input="updateShelfWidthDbSide()"
                        v-model.number="property.settings[moundTypeSettings].shelfWidth" />
                </td>
            </tr>
            <!-- 小段勾配 -->
            <tr>
                <td>
                    {{ $t("Bermgradient") }}
                </td>
                <td>
                    <SlopeSettingInput :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                        :is-editable="property.settings[moundTypeSettings].generateShelf && withSlope"
                        :slope-value="property.settings[moundTypeSettings].shelfSlope" :step="0.01"
                        @input="updateShelfSlopeDbSide()" v-model.number="property.settings[moundTypeSettings].shelfSlope"
                        :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01" :decimal-length="2"
                        :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'"></SlopeSettingInput>

                </td>
            </tr>
            <!-- 水平地形色 -->
            <tr>
                <td>
                    {{ $t("Flatgroundcolor") }}
                </td>
                <td>
                    <template>
                        <v-menu
                          offset-y
                          :disabled="!withSlope"
                        >
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar
                                :style="horizontalTerrainColorStyle"
                                :color="property.settings[moundTypeSettings].color"
                                size="20px"
                                v-bind="attrs"
                                v-on="on">
                              </v-avatar>
                            </template>
                            <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                                v-model="property.settings[moundTypeSettings].color"
                                @input="emitUpdatedSettings()"></v-color-picker>
                        </v-menu>
                    </template>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import SlopeSettingInput from "./SlopeSettingInput.vue"
import rules from '@/utils/validation-rules.js';
import {UnitTypes} from "../../constant";

export default {
    name: "PropertyFlatMoundSettings",
    components: { SlopeSettingInput },
    props: {
        property: Object,
        moundTypeSettings: String,
    },
    methods: {
        getSuffix(unitType) {
            const unit = UnitTypes.find(type => type.value === unitType);
            return unit.symbol;
        },
        updateHeightDbSide() {
            this.property.settings[this.moundTypeSettings].height = this.isNum(this.property.settings[this.moundTypeSettings].height) ? this.property.settings[this.moundTypeSettings].height : this.defaultProperty.settings[this.moundTypeSettings].height;
            this.emitUpdatedSettings();
        },
        updateMoundSlopeDbSide() {
            // this.updateSlopes(this.property);
            this.property.settings[this.moundTypeSettings].moundSlope = this.isNum(this.property.settings[this.moundTypeSettings].moundSlope) ? this.property.settings[this.moundTypeSettings].moundSlope : this.defaultProperty.settings[this.moundTypeSettings].moundSlope;
            this.emitUpdatedSettings();
        },
        updateShelfWidthDbSide() {
            this.property.settings[this.moundTypeSettings].shelfWidth = this.isNum(this.property.settings[this.moundTypeSettings].shelfWidth) ? this.property.settings[this.moundTypeSettings].shelfWidth : this.defaultProperty.settings[this.moundTypeSettings].shelfWidth;
            this.emitUpdatedSettings();
        },
        updateShelfSlopeDbSide() {
            // this.updateSlopes(this.property);
            this.property.settings[this.moundTypeSettings].shelfSlope = this.isNum(this.property.settings[this.moundTypeSettings].shelfSlope) ? this.property.settings[this.moundTypeSettings].shelfSlope : this.defaultProperty.settings[this.moundTypeSettings].shelfSlope;
            this.emitUpdatedSettings();
        },
        isNum(val) {
            return typeof val == "number";
        },
        emitUpdatedSettings() {
            this.$emit('settingsUpdated');
        }
    },
    data: () => ({
        rules: {
            ...rules,
            validName: v => !(/[~@#$^*()+=[\]{}|\\,?:<>!'"/;₹€&`%]/.test(v))
        },
    }),
    computed: {
        /**
         * 水平地形色のスタイル
         */
        horizontalTerrainColorStyle() {
          // 編集不可のときに適用するCSS
          if (!this.withSlope) {
            return  {
                      opacity: 0.5,
                      'pointer-events': 'none'
                    }
          } else {
          // 編集可のときに適用するCSS
            return  {
                      opacity: 1.0 
                    }
          }
        },
        withSlope() {
            return this.property.settings.commonSettings.withSlope;
        },
        fillSettingSlopeUnit() {
            return this.$store.state.fillSettingSlopeUnit;
        },
        defaultProperty() { return this.$store.getters.defaultFlatGroundProperty },
    },
}
</script>