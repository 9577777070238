<template>
  <v-list dense>
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForRoadEdit" :key="index"
      @click="onCMClickForRoadSegmentEdit($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    pathId: String,
    segmentId: String,
    point:Array,
  },

  data: function() {
    return {
      itemsForRoadEdit: [
          { id: 1, title: "Straightline" },
          { id: 2, title: "Curvedline" },
          // { id: 3, title: "ADD" }
        ],
    }
  },
  methods: {
    onCMClickForRoadSegmentEdit(e, id) {
      e.preventDefault();
      switch (id) {
        case 1:
          window["viewer"].updateSegmentToStraight(this.pathId, this.segmentId);
          break;
        case 2:
          window["viewer"].updateSegmentToCurve(this.pathId, this.segmentId);
          break;
        // case 3:
        // window["viewer"].addControlPointOnRoadSegment(
        //     this.pathId,
        //     this.segmentId,
        //     this.point
        //   );
          // window.dispatchEvent(new CustomEvent("addPointEvent", {
          //   detail: { roadId: this.pathId, pointIndex: 0 },
          // }));
          // break;
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
