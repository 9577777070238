<template>
  <v-app-bar app color="header" dark dense clipped-left flat>
    <div style="width: 100%; height: 100%; overflow: hidden">
      <v-img
        src="../assets/SCDesign_header.png"
        height="100%"
        width="210px"
      />
    </div>
    <v-btn icon>
      <v-icon @click="openHelpPDF()">
        icon-Help
      </v-icon>
    </v-btn>
    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on" icon>
            <v-icon color="white"> icon-User </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-content v-if="this.$store.state.user">
              {{ this.$store.state.user.family_name}} {{this.$store.state.user.given_name}} <br>
              {{this.$store.state.user.email}}
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="gotoAccountmanagement()">
            <v-list-item-icon>
              <v-icon small>icon-Setting</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t("Accountmanagement") }} (SMART CONSTRUCTION)</v-list-item-title>
          </v-list-item>
          <v-list-item @click="refresh()">
            <v-list-item-title> {{ $t("Refresh") }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppHeader",
  methods: {
    lang(lang) {
      this.$i18n.locale = lang
    },
    openHelpPDF() {
      window.open("/help/scdesign_user_manual.pdf")
    },
    gotoAccountmanagement() {
      window.open("https://account-manager-pre.pf.smartconstruction.com/")
    },
    refresh() {
      window.location.href = `${process.env.VUE_APP_API_BASE}/login`;
    }
  }
};
</script>

<style>
.v-toolbar__content {
  padding-left: 0px !important;
}
</style>
