import store from "@/store/index";
import axios from "axios";
// import heightmap from "./heightmap";

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const uploadRoadObj = debounce((props) => {
  const json = window["viewer"].getRoadJSON(props.id);
  console.log("road uploaded", { json, props });
  props.json = json;
  store.dispatch("updateObj", { json, props });
}, 300);

export const uploadFlatGroundObj = debounce((props) => {
  const json = window["viewer"].getFlatGroundJSON(props.id);
  props.json = json;
  store.dispatch("updateObj", { json, props });
}, 300);

/**
 * 基準地形の可視不可視を変更する
 * @param {*} pointCloud
 */
export const togglePointCloudVisibility = async (pointCloud, roadList) => {
  if (!pointCloud.getVisibility()) {
    // 不可視から可視への切り替え
    if (pointCloud.getIsTiling()) {
      // タイリング済みの場合、新たにタイリング処理を行わない
      pointCloud.setVisibility(true);
      window["viewer"].updateTilesetVisibility(parseInt(pointCloud.getAssetId()), "pointcloud", pointCloud.getVisibility());
    } else {
      // スピナーを表示する
      store.dispatch("updateIsLoading", true);
      // タイリング中のステータスをtrueにする
      pointCloud.setTilingProgress(true);

      // タイリングのためのデータを取得し、タイリング処理を実行する
      addPointCloudTileset(pointCloud).then(() => {
        // After pointclod is loaded, calculate geometry for roads which were loaded before
        const findAssociateRoadsWithoutGeom = roadList.filter((road) => {
          return JSON.parse(road.json).associatePointCloudId && JSON.parse(road.json).associatePointCloudId === parseInt(pointCloud.asset_id) && road.isLoadedWithoutPointCloud;
        });
        findAssociateRoadsWithoutGeom.forEach((road) => {
          window["viewer"].recalculateRoadGeometry(road.id);
          road.isLoadedWithoutPointCloud = false;
        });
        
        // ステータスを可視状態かつタイリング済みにする
        pointCloud.setVisibility(true);
        pointCloud.setIsTiling(true);
        
        // タイリング中のステータスをfalseに戻す
        pointCloud.setTilingProgress(false);
        // スピナーを非表示にする
        store.dispatch("updateIsLoading", false);
      }).catch(err => {
        console.error(err.message);
        // タイリング中のステータスをfalseに戻す
        pointCloud.setTilingProgress(false);
        // スピナーを非表示にする
        store.dispatch("updateIsLoading", false);
        if(err.message === "conversion error"){
          store.commit("set_snackbar", {
            text: `座標系が異なるため、タイリング中で計算で失敗`,
            color: 'rgba(153, 0, 0, 0.72)'
          });
        }
      });
    }
  } else {
    // 可視から不可視への切り替え
    pointCloud.setVisibility(false);
    window["viewer"].updateTilesetVisibility(parseInt(pointCloud.getAssetId()), "pointcloud", pointCloud.getVisibility());
  }
};

/**
 * 設計データの可視不可視を変更する
 * @param {*} design
 */
export const toggleDesignVisibility = async (design) => {
  if (!design.getVisibility()) {
    // 不可視から可視への切り替え
    if (design.getIsTiling()) {
      // タイリング済みの場合、新たにタイリング処理を行わない
      design.setVisibility(true);
      window["viewer"].updateTilesetVisibility(parseInt(design.getAssetId()), "design", design.getVisibility());
    } else {
      // スピナーを表示する
      store.dispatch("updateIsLoading", true);
      // タイリング中のステータスをtrueにする
      design.setTilingProgress(true);
      try {
        // タイリングのためのデータを取得し、タイリング処理を実行する
        await addDesignDataTileset(design);
        // ステータスを可視状態かつタイリング済みにする
        design.setVisibility(true);
        design.setIsTiling(true);
      } catch (e) {
        console.error(e.message);
        if(e.message === "conversion error"){
          store.commit("set_snackbar", {
            text: `座標系が異なるため、タイリング中で計算で失敗`,
            color: 'rgba(153, 0, 0, 0.72)'
          });
        }
      } finally {
        // タイリング中のステータスをfalseに戻す
        design.setTilingProgress(false);
        // スピナーを非表示にする
        store.dispatch("updateIsLoading", false);
      }
    }
  } else {
    // 可視から不可視への切り替え
    design.setVisibility(false);
    window["viewer"].updateTilesetVisibility(parseInt(design.getAssetId()), "design", design.getVisibility());
  }
};

/**
 * 仮設道路の可視不可視を変更する
 * @param {*} roadEntity
 */
export const toggleRoadVisibility = async (roadEntity, activePointCloudId, isLoadedWithoutPointCloud) => {
  if (roadEntity.isShow && !roadEntity.isLoaded) {
    // TODO Remove window["viewer"]
    roadEntity.id = window["viewer"].loadRoadJSON(roadEntity.json, activePointCloudId);
    roadEntity.isLoaded = true;
    roadEntity.isLoadedWithoutPointCloud = isLoadedWithoutPointCloud;
  } else {
    // TODO Remove window["viewer"]
    window["viewer"].toggleRoadVisibility(roadEntity.id, roadEntity.isShow);
  }
};

/**
 * 平場の可視不可視を変更する
 * @param {*} flatEntity
 */
export const toggleFlatGroundVisibility = async (flatEntity, activePointCloudId) => {
  if (flatEntity.isShow && !flatEntity.isLoaded) {
    flatEntity.id = window["viewer"].loadFlatGroundArea(flatEntity.json, activePointCloudId);
    flatEntity.isLoaded = true;
  } else {
    window["viewer"].toggleFlatGroundVisibility(flatEntity.id, flatEntity.isShow);
  }
};

export const hexToRgb = function (hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 1 };
  }
  throw new Error("Bad Hex");
};

export const convertCoordinateToCesiumCrs = async ({ x, y, z }) => {
  //epsg
  const epsg = store.state.site.epsg;
  const epsg_v = store.state.site.epsg_v;

  //proj string
  const rotation = store.state.site.rotation;
  const origin_easting = store.state.site.origin_easting;
  const origin_northing = store.state.site.origin_northing;
  const origin_latitude = store.state.site.origin_latitude;
  const origin_longitude = store.state.site.origin_longitude;
  const scale_factor = store.state.site.scale_factor;
  const vertical_offset = store.state.site.vertical_offset;
  const incline_x = store.state.site.incline_x;
  const incline_y = store.state.site.incline_y;

  let data;

  if (!(epsg === null || epsg === undefined || epsg === "")) {
    data = {
      from: `EPSG:${epsg}` + (epsg_v ? `+${epsg_v}` : ""),
      to: "EPSG:4978",
      points: [{ x, y, z }],
    };
  }

  if (!data) {
    if( !(rotation === null || rotation === "" || rotation === undefined)) {
      data = {
        stereoParameters: [
          `rotation=${rotation}`,
          `origin_easting=${origin_easting}`,
          `origin_northing=${origin_northing}`,
          `origin_latitude=${origin_latitude}`,
          `origin_longitude=${origin_longitude}`,
          `scale_factor=${scale_factor}`,
          `vertical_offset=${vertical_offset}`,
          `incline_x=${incline_x}`,
          `incline_y=${incline_y}`,
        ],
        stereoParametersKeyword: "--local-to-wgs84",
        points: [{ x, y, z }],
        to: "EPSG:4978",
        from: "EPSG:4326+ESRI:115700",
      };
    }
  }

  let resp_x = NaN;
  let resp_y = NaN;
  let resp_z = NaN;

  try {
    if (data) {
      const url = `${process.env.VUE_APP_API_BASE}/pointCloudTransformer`;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        auth: store.state.authObject,
        withCredentials: false,
      };
      const response = await axios.post(url, data, config);
      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        resp_x = Number(response.data[0].x);
        resp_y = Number(response.data[0].y);
        resp_z = Number(response.data[0].z);
      }
    }
  } catch (e) {
    console.error(e);
  }
  return { x: resp_x, y: resp_y, z: resp_z };
};

export const convertCoordinateToPointCloudCrs = async ({ x, y, z }) => {
  // //epsg
  // const epsg = store.state.site.epsg;
  // const epsg_v = store.state.site.epsg_v;

  // //proj string
  // const rotation = store.state.site.rotation;
  // const origin_easting = store.state.site.origin_easting;
  // const origin_northing = store.state.site.origin_northing;
  // const origin_latitude = store.state.site.origin_latitude;
  // const origin_longitude = store.state.site.origin_longitude;
  // const scale_factor = store.state.site.scale_factor;
  // const vertical_offset = store.state.site.vertical_offset;
  // const incline_x = store.state.site.incline_x;
  // const incline_y = store.state.site.incline_y;

  // let data;

  // if (!(epsg === null || epsg === undefined || epsg === "")) {
  //   data = {
  //     from: "EPSG:4978",
  //     to: `EPSG:${epsg}` + (epsg_v ? `+${epsg_v}` : ""),
  //     points: [{ x, y, z }],
  //   };
  // }

  // if (!data) {
  //   if (!(rotation === null || rotation === undefined || rotation === "")) {
  //     data = {
  //       stereoParameters: [
  //         `rotation=${rotation}`,
  //         `origin_easting=${origin_easting}`,
  //         `origin_northing=${origin_northing}`,
  //         `origin_latitude=${origin_latitude}`,
  //         `origin_longitude=${origin_longitude}`,
  //         `scale_factor=${scale_factor}`,
  //         `vertical_offset=${vertical_offset}`,
  //         `incline_x=${incline_x}`,
  //         `incline_y=${incline_y}`,
  //       ],
  //       stereoParametersKeyword: "--wgs84-to-local",
  //       from: "EPSG:4978",
  //       to: "EPSG:4326+ESRI:115700",
  //       points: [{ x, y, z }],
  //     };
  //   }
  // }

  // let resp_x = NaN;
  // let resp_y = NaN;
  // let resp_z = NaN;

  // try {
  //   if (data) {
  //     const url = `${process.env.VUE_APP_API_BASE}/pointCloudTransformer`;

  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       auth: store.state.authObject,
  //       withCredentials: false,
  //     };
  //     const response = await axios.post(url, data, config);
  //     if (response.data && Array.isArray(response.data) && response.data.length > 0) {
  //       resp_x = Number(response.data[0].x);
  //       resp_y = Number(response.data[0].y);
  //       resp_z = Number(response.data[0].z);
  //     }
  //   }
  // } catch (e) {
  //   console.error(e);
  // }
  const result = await convertCoordinatesToPointCloudCrs([{x,y,z}]) 
  return result.length > 0 ? result[0]:{x:NaN, y:NaN, z:NaN};
};

export const convertCoordinatesToPointCloudCrs = async (coords) => { //{x,y,z}[]
  //epsg
  const epsg = store.state.site.epsg;
  const epsg_v = store.state.site.epsg_v;

  //proj string
  const rotation = store.state.site.rotation;
  const origin_easting = store.state.site.origin_easting;
  const origin_northing = store.state.site.origin_northing;
  const origin_latitude = store.state.site.origin_latitude;
  const origin_longitude = store.state.site.origin_longitude;
  const scale_factor = store.state.site.scale_factor;
  const vertical_offset = store.state.site.vertical_offset;
  const incline_x = store.state.site.incline_x;
  const incline_y = store.state.site.incline_y;

  let data;

  if (!(epsg === null || epsg === undefined || epsg === "")) {
    data = {
      from: "EPSG:4978",
      to: `EPSG:${epsg}` + (epsg_v ? `+${epsg_v}` : ""),
      points: coords,
    };
  }

  if (!data) {
    if (!(rotation === null || rotation === undefined || rotation === "")) {
      data = {
        stereoParameters: [
          `rotation=${rotation}`,
          `origin_easting=${origin_easting}`,
          `origin_northing=${origin_northing}`,
          `origin_latitude=${origin_latitude}`,
          `origin_longitude=${origin_longitude}`,
          `scale_factor=${scale_factor}`,
          `vertical_offset=${vertical_offset}`,
          `incline_x=${incline_x}`,
          `incline_y=${incline_y}`,
        ],
        stereoParametersKeyword: "--wgs84-to-local",
        from: "EPSG:4978",
        to: "EPSG:4326+ESRI:115700",
        points: coords,
      };
    }
  }

  try {
    if (data) {
      const url = `${process.env.VUE_APP_API_BASE}/pointCloudTransformer`;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        auth: store.state.authObject,
        withCredentials: false,
      };
      const response = await axios.post(url, data, config);
      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        return response.data;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};
// export const calculateVerticalOffsetForPointCloud = async ({ x, y, z }) => {
//   const epsg = store.state.site.epsg;
//   const epsg_v = store.state.site.epsg_v;
//   const cesiumCRS = 4978;

//   if (!(epsg === null || epsg === undefined || epsg === "")) {
//     let localizedCoordinate = { x: 0, y: 0, z: 0 };
//     //1) convert first point of vertex buffer into the original site EPSG to get the point from the raw file.
//     const dataToLocalized = {
//       from: `EPSG:${cesiumCRS}`,
//       to: `EPSG:${epsg}`,
//       points: [{ x, y, z }],
//     };
//     const url = `${process.env.VUE_APP_API_BASE}/pointCloudTransformer`;

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//       auth: store.state.authObject,
//       withCredentials: false,
//     };
//     const response = await axios.post(url, dataToLocalized, config);
//     if (response.data && Array.isArray(response.data) && response.data.length > 0) {
//       localizedCoordinate.x = Number(response.data[0].x);
//       localizedCoordinate.y = Number(response.data[0].y);
//       localizedCoordinate.z = Number(response.data[0].z);
//     }

//     if (!(epsg_v === null || epsg_v === undefined || epsg_v === "")) {
//       let localizedCoordinate_Vertical = { x: 0, y: 0, z: 0 };

//       const data = {
//         from: `EPSG:${epsg}`,
//         to: `EPSG:${epsg_v}`,
//         points: [localizedCoordinate],
//       };
//       const response_1 = await axios.post(url, data, config);
//       if (response_1.data && Array.isArray(response_1.data) && response_1.data.length > 0) {
//         localizedCoordinate_Vertical.x = Number(response_1.data[0].x);
//         localizedCoordinate_Vertical.y = Number(response_1.data[0].y);
//         localizedCoordinate_Vertical.z = Number(response_1.data[0].z);
//         return localizedCoordinate.z - localizedCoordinate_Vertical.z;
//       }
//     }
//     return localizedCoordinate.z;
//   }

//   const localizedCoordinate = await convertCoordinateToPointCloudCrs({ x, y, z });
//   return localizedCoordinate.z;

//   // let pointString = `${x},${y},${z}`;
//   // //1) convert first point of vertex buffer into the original site EPSG to get the point from the raw file.
//   // let reqString = `https://epsg.io/trans?data=${pointString}&s_srs=${cesiumCRS}&&t_srs=${epsg}`;
//   // const response_1 = await axios.get(reqString);
//   // let originalPoint_1 = Array.isArray(response_1.data) ? response_1.data[0] : response_1.data;

//   // //Modify the point string
//   // pointString = `${originalPoint_1.x},${originalPoint_1.y},${originalPoint_1.z}`;

//   // //2) convert that original point with srcCRS as horizontal EPSG and tarCRS as vertical EPSG.
//   // reqString = `https://epsg.io/trans?data=${pointString}&s_srs=${epsg}&&t_srs=${epsg_v}`;
//   // const response_2 = await axios.get(reqString);
//   // let originalPoint_2 = Array.isArray(response_2.data) ? response_2.data[0] : response_2.data;

//   // console.log("multiple conversion", originalPoint_1.z - originalPoint_2.z);
//   // return originalPoint_1.z - originalPoint_2.z;
// };


export const addPointCloudTileset = async (point_cloud) => {
  const allGetRequests = ["heightmap","data"].map(req=>
    axios.get(`${process.env.VUE_APP_API_BASE}/pointcloud/${req}/${point_cloud.point_cloud_id}`, {
    auth: store.state.authObject
  }));
  const [heightmapResponse, geometryResponse] = await Promise.all(allGetRequests);
  console.log("heightmapResponse", heightmapResponse);
  console.log("geometryResponse", geometryResponse);

  const localizedPoint = await convertCoordinateToPointCloudCrs({ x: geometryResponse.data[0], y: geometryResponse.data[1], z: geometryResponse.data[2] });
  if (localizedPoint.z !== localizedPoint.z) {
    throw { message: "conversion error" };
  }
  const cesiumAltitude = window["viewer"].getAltitude({ x: geometryResponse.data[0], y: geometryResponse.data[1], z: geometryResponse.data[2] });
  const offset = cesiumAltitude - localizedPoint.z;
  
  return window["viewer"].addTileset(point_cloud.asset_id, "pointcloud", {
    heightMapInput: heightmapResponse.data.hasData ? heightmapResponse.data.height_map: undefined,
    vertexBuffer: geometryResponse.data,
    offset,
  });
};

export const addDesignDataTileset = async(design) =>{
  let response = await axios.get(
      `${process.env.VUE_APP_API_BASE}/design/data/${design.design_id}`,
      {
          auth: store.state.authObject,
      }
  );
  const localizedPoint = await convertCoordinateToPointCloudCrs({x:response.data.vertices[0],y:response.data.vertices[1],z:response.data.vertices[2]});
  if(localizedPoint.z !== localizedPoint.z){
    throw {message:"conversion error"};
  }
  const cesiumAltitude = window["viewer"].getAltitude({x:response.data.vertices[0],y:response.data.vertices[1],z:response.data.vertices[2]});
  const offset = cesiumAltitude - localizedPoint.z; //await calculateVerticalOffsetForPointCloud({x:response.data[0],y:response.data[1],z:response.data[2]});
  return window["viewer"].addTileset(design.asset_id, "design", {
    vertexBuffer: response.data.vertices,
    indexBuffer: response.data.indices,
    offset,
    styleOptions: {
      design: { mesh_color: hexToRgb(design.getMeshColor()) },
      common: { transparency: design.getTransparency() }
    }
  });
}

export const updateRoadSettingsInViewer = (roadId, roadObj) => {
  const isNum = (val) => {
    return typeof val == "number";
  };
  const roadDataJSONStr = roadObj.json ? roadObj.json : window["viewer"].getRoadJSON(roadId);  
  const roadData = JSON.parse(roadDataJSONStr);

  const roadCommonSettings = roadData.roadSettings.commonSetting;
  //common settings
  roadCommonSettings.path.leftWidth = isNum(roadObj.settings.commonSettings.leftWidth) ? roadObj.settings.commonSettings.leftWidth : 2.5;
  roadCommonSettings.path.rightWidth = isNum(roadObj.settings.commonSettings.rightWidth) ? roadObj.settings.commonSettings.rightWidth : 2.5;
  roadCommonSettings.path.leftSlope = isNum(roadObj.settings.commonSettings.leftSlope) ? roadObj.settings.commonSettings.leftSlope : 50;
  roadObj.settings.commonSettings.rightSlope = isNum(roadObj.settings.commonSettings.leftSlope) ? roadObj.settings.commonSettings.leftSlope : 50;
  roadCommonSettings.path.rightSlope = roadObj.settings.commonSettings.isSuperElevation ? -1 * roadObj.settings.commonSettings.rightSlope : roadObj.settings.commonSettings.rightSlope;
  roadCommonSettings.path.roadSlopeType = roadObj.settings.commonSettings.isSuperElevation ? "straight" : "cross";
  roadCommonSettings.path.interpolationPitch =
    isNum(roadObj.settings.commonSettings.interpolationPitch) && roadObj.settings.commonSettings.interpolationPitch != 0 ? roadObj.settings.commonSettings.interpolationPitch : 5;

  if (roadObj.settings.cutSettings.bLeftRightSettings) {
    //separate left and right settings
    roadCommonSettings.cutSetting.left.slopeHeight = isNum(roadObj.settings.cutSettings.leftSlopeHeight) ? roadObj.settings.cutSettings.leftSlopeHeight : 5;
    roadCommonSettings.cutSetting.left.moundSlope = isNum(roadObj.settings.cutSettings.leftMoundSlope) ? roadObj.settings.cutSettings.leftMoundSlope : 1;
    roadCommonSettings.cutSetting.left.shelfWidth = isNum(roadObj.settings.cutSettings.leftShelfWidth) ? roadObj.settings.cutSettings.leftShelfWidth : 1.5;
    roadCommonSettings.cutSetting.left.shelfSlope = isNum(roadObj.settings.cutSettings.leftShelfSlope) ? roadObj.settings.cutSettings.leftShelfSlope : 100;

    roadCommonSettings.cutSetting.right.slopeHeight = isNum(roadObj.settings.cutSettings.rightSlopeHeight) ? roadObj.settings.cutSettings.rightSlopeHeight : 5;
    roadCommonSettings.cutSetting.right.moundSlope = isNum(roadObj.settings.cutSettings.rightMoundSlope) ? roadObj.settings.cutSettings.rightMoundSlope : 1;
    roadCommonSettings.cutSetting.right.shelfWidth = isNum(roadObj.settings.cutSettings.rightShelfWidth) ? roadObj.settings.cutSettings.rightShelfWidth : 1.5;
    roadCommonSettings.cutSetting.right.shelfSlope = isNum(roadObj.settings.cutSettings.rightShelfSlope) ? roadObj.settings.cutSettings.rightShelfSlope : 100;
  } else {
    roadCommonSettings.cutSetting.left.slopeHeight = isNum(roadObj.settings.cutSettings.roadHeight) ? roadObj.settings.cutSettings.roadHeight : 5;
    roadCommonSettings.cutSetting.left.moundSlope = isNum(roadObj.settings.cutSettings.moundSlope) ? roadObj.settings.cutSettings.moundSlope : 1;
    roadCommonSettings.cutSetting.left.shelfWidth = isNum(roadObj.settings.cutSettings.shelfWidth) ? roadObj.settings.cutSettings.shelfWidth : 1.5;
    roadCommonSettings.cutSetting.left.shelfSlope = isNum(roadObj.settings.cutSettings.shelfSlope) ? roadObj.settings.cutSettings.shelfSlope : 100;

    roadCommonSettings.cutSetting.right.slopeHeight = isNum(roadObj.settings.cutSettings.roadHeight) ? roadObj.settings.cutSettings.roadHeight : 5;
    roadCommonSettings.cutSetting.right.moundSlope = isNum(roadObj.settings.cutSettings.moundSlope) ? roadObj.settings.cutSettings.moundSlope : 1;
    roadCommonSettings.cutSetting.right.shelfWidth = isNum(roadObj.settings.cutSettings.shelfWidth) ? roadObj.settings.cutSettings.shelfWidth : 1.5;
    roadCommonSettings.cutSetting.right.shelfSlope = isNum(roadObj.settings.cutSettings.shelfSlope) ? roadObj.settings.cutSettings.shelfSlope : 100;
  }
  roadCommonSettings.cutSetting.generateShelf = roadObj.settings.cutSettings.generateShelf;

  if (roadObj.settings.embarkmentSettings.bLeftRightSettings) {
    //separate left and right settings
    roadCommonSettings.embarkmentSetting.left.slopeHeight = isNum(roadObj.settings.embarkmentSettings.leftSlopeHeight) ? roadObj.settings.embarkmentSettings.leftSlopeHeight : 5;
    roadCommonSettings.embarkmentSetting.left.moundSlope = isNum(roadObj.settings.embarkmentSettings.leftMoundSlope) ? roadObj.settings.embarkmentSettings.leftMoundSlope : 1;
    roadCommonSettings.embarkmentSetting.left.shelfWidth = isNum(roadObj.settings.embarkmentSettings.leftShelfWidth) ? roadObj.settings.embarkmentSettings.leftShelfWidth : 1.5;
    roadCommonSettings.embarkmentSetting.left.shelfSlope = isNum(roadObj.settings.embarkmentSettings.leftShelfSlope) ? roadObj.settings.embarkmentSettings.leftShelfSlope : 100;

    roadCommonSettings.embarkmentSetting.right.slopeHeight = isNum(roadObj.settings.embarkmentSettings.rightSlopeHeight) ? roadObj.settings.embarkmentSettings.rightSlopeHeight : 5;
    roadCommonSettings.embarkmentSetting.right.moundSlope = isNum(roadObj.settings.embarkmentSettings.rightMoundSlope) ? roadObj.settings.embarkmentSettings.rightMoundSlope : 1;
    roadCommonSettings.embarkmentSetting.right.shelfWidth = isNum(roadObj.settings.embarkmentSettings.rightShelfWidth) ? roadObj.settings.embarkmentSettings.rightShelfWidth : 1.5;
    roadCommonSettings.embarkmentSetting.right.shelfSlope = isNum(roadObj.settings.embarkmentSettings.rightShelfSlope) ? roadObj.settings.embarkmentSettings.rightShelfSlope : 100;
  } else {
    roadCommonSettings.embarkmentSetting.left.slopeHeight = isNum(roadObj.settings.embarkmentSettings.roadHeight) ? roadObj.settings.embarkmentSettings.roadHeight : 5;
    roadCommonSettings.embarkmentSetting.left.moundSlope = isNum(roadObj.settings.embarkmentSettings.moundSlope) ? roadObj.settings.embarkmentSettings.moundSlope : 1;
    roadCommonSettings.embarkmentSetting.left.shelfWidth = isNum(roadObj.settings.embarkmentSettings.shelfWidth) ? roadObj.settings.embarkmentSettings.shelfWidth : 1.5;
    roadCommonSettings.embarkmentSetting.left.shelfSlope = isNum(roadObj.settings.embarkmentSettings.shelfSlope) ? roadObj.settings.embarkmentSettings.shelfSlope : 100;

    roadCommonSettings.embarkmentSetting.right.slopeHeight = isNum(roadObj.settings.embarkmentSettings.roadHeight) ? roadObj.settings.embarkmentSettings.roadHeight : 5;
    roadCommonSettings.embarkmentSetting.right.moundSlope = isNum(roadObj.settings.embarkmentSettings.moundSlope) ? roadObj.settings.embarkmentSettings.moundSlope : 1;
    roadCommonSettings.embarkmentSetting.right.shelfWidth = isNum(roadObj.settings.embarkmentSettings.shelfWidth) ? roadObj.settings.embarkmentSettings.shelfWidth : 1.5;
    roadCommonSettings.embarkmentSetting.right.shelfSlope = isNum(roadObj.settings.embarkmentSettings.shelfSlope) ? roadObj.settings.embarkmentSettings.shelfSlope : 100;
  }
  roadCommonSettings.embarkmentSetting.generateShelf = roadObj.settings.embarkmentSettings.generateShelf;

  //segment setting
  //UI has segment setting for the group
  //Viewer has segment setting for individual segment
  const getViewerSegmentSetting = (index) => {
    const commonSetting = JSON.parse(JSON.stringify(roadCommonSettings));
    const segSetting = roadObj.settings.commonSettings.segmentSettings.find((s) => index >= s.startIndex && index <= s.endIndex);
    if (segSetting) {
      commonSetting.path.roadSlopeType = segSetting.slopeType;
      if (commonSetting.path.roadSlopeType === "straight") {
        commonSetting.path.slope = segSetting.slope;
      } else if (commonSetting.path.roadSlopeType === "cross") {
        commonSetting.path.rightSlope = segSetting.slope;
        commonSetting.path.leftSlope = segSetting.slope;
      }
    }
    return commonSetting;
  };

  const viewerSegmentSettings = roadData.roadSettings.segmentSettings;
  for (const setting of viewerSegmentSettings) {
    setting.commonSetting = getViewerSegmentSetting(setting.startIndex);
  }
  window["viewer"].updateRoadSettings(roadId, roadData.roadSettings);
};

export const updateFlatGroundSettingsInViewer = (flatGroundId, flatGroundObj) => {
  const isNum = (val) => {
    return typeof val == "number";
  };
  const flatGroundJSONStr = flatGroundObj.json ? flatGroundObj.json : window["viewer"].getFlatGroundJSON(flatGroundId);  
  const flatGround = JSON.parse(flatGroundJSONStr);

  flatGround.flatGroundSettings.commonSettings.interpolationPitch = isNum(flatGroundObj.settings.commonSettings.interpolationPitch) ? flatGroundObj.settings.commonSettings.interpolationPitch : 5;
  if(flatGround.flatGroundSettings.commonSettings.interpolationPitch <= 0) {
    flatGround.flatGroundSettings.commonSettings.interpolationPitch = 5;
  }
  flatGround.flatGroundSettings.commonSettings.withSlope = flatGroundObj.settings.commonSettings.withSlope;
  //Embankment Settings
  flatGround.flatGroundSettings.embankmentSettings.height = isNum(flatGroundObj.settings.embankmentSettings.height) ? flatGroundObj.settings.embankmentSettings.height : 5;
  flatGround.flatGroundSettings.embankmentSettings.moundSlope = isNum(flatGroundObj.settings.embankmentSettings.moundSlope) ? flatGroundObj.settings.embankmentSettings.moundSlope : 1;
  flatGround.flatGroundSettings.embankmentSettings.shelfWidth = isNum(flatGroundObj.settings.embankmentSettings.shelfWidth) ? flatGroundObj.settings.embankmentSettings.shelfWidth : 1.5;
  flatGround.flatGroundSettings.embankmentSettings.shelfSlope = isNum(flatGroundObj.settings.embankmentSettings.shelfSlope) ? flatGroundObj.settings.embankmentSettings.shelfSlope : 100;
  flatGround.flatGroundSettings.embankmentSettings.generateShelf = flatGroundObj.settings.embankmentSettings.generateShelf;
  flatGround.flatGroundSettings.embankmentSettings.material = flatGroundObj.settings.embankmentSettings.color;
  flatGround.flatGroundSettings.embankmentSettings.transparency = flatGroundObj.transparency;

  //Cut Settings
  flatGround.flatGroundSettings.cutSettings.height = isNum(flatGroundObj.settings.cutSettings.height) ? flatGroundObj.settings.cutSettings.height : 5;
  flatGround.flatGroundSettings.cutSettings.moundSlope = isNum(flatGroundObj.settings.cutSettings.moundSlope) ? flatGroundObj.settings.cutSettings.moundSlope : 1;
  flatGround.flatGroundSettings.cutSettings.shelfWidth = isNum(flatGroundObj.settings.cutSettings.shelfWidth) ? flatGroundObj.settings.cutSettings.shelfWidth : 1.5;
  flatGround.flatGroundSettings.cutSettings.shelfSlope = isNum(flatGroundObj.settings.cutSettings.shelfSlope) ? flatGroundObj.settings.cutSettings.shelfSlope : 100;
  flatGround.flatGroundSettings.cutSettings.generateShelf = flatGroundObj.settings.cutSettings.generateShelf;
  flatGround.flatGroundSettings.cutSettings.material = flatGroundObj.settings.cutSettings.color;
  flatGround.flatGroundSettings.cutSettings.transparency = flatGroundObj.transparency;

  // window["viewer"].updateFlatGroundMaterialSettings(
  //   this.id,
  //   { 
  //     material: this.property.settings.embankmentSettings.color,
  //     transparency: this.property.transparency,
  //   },
  //   { 
  //     material: this.property.settings.cutSettings.color,
  //     transparency: this.property.transparency,
  //   }
  // );


  window["viewer"].updateFlatGroundSettings(flatGroundId, flatGround.flatGroundSettings);
}