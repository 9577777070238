import { i18n } from '../i18n.js'

export const COORDINATE_SYSTEM_SETTING_LIST = [
  {coordinate_system:"EPSG:2000", label: "2000:Anguilla 1957 / British West Indies Grid"},
  {coordinate_system:"EPSG:2001", label: "2001:Antigua 1943 / British West Indies Grid"},
  {coordinate_system:"EPSG:2002", label: "2002:Dominica 1945 / British West Indies Grid"},
  {coordinate_system:"EPSG:2003", label: "2003:Grenada 1953 / British West Indies Grid"},
  {coordinate_system:"EPSG:2004", label: "2004:Montserrat 1958 / British West Indies Grid"},
  {coordinate_system:"EPSG:2005", label: "2005:St. Kitts 1955 / British West Indies Grid"},
  {coordinate_system:"EPSG:2006", label: "2006:St. Lucia 1955 / British West Indies Grid"},
  {coordinate_system:"EPSG:2007", label: "2007:St. Vincent 45 / British West Indies Grid"},
  {coordinate_system:"EPSG:2009", label: "2009:NAD27(CGQ77) / SCoPQ zone 3"},
  {coordinate_system:"EPSG:2010", label: "2010:NAD27(CGQ77) / SCoPQ zone 4"},
  {coordinate_system:"EPSG:2011", label: "2011:NAD27(CGQ77) / SCoPQ zone 5"},
  {coordinate_system:"EPSG:2012", label: "2012:NAD27(CGQ77) / SCoPQ zone 6"},
  {coordinate_system:"EPSG:2013", label: "2013:NAD27(CGQ77) / SCoPQ zone 7"},
  {coordinate_system:"EPSG:2014", label: "2014:NAD27(CGQ77) / SCoPQ zone 8"},
  {coordinate_system:"EPSG:2015", label: "2015:NAD27(CGQ77) / SCoPQ zone 9"},
  {coordinate_system:"EPSG:2016", label: "2016:NAD27(CGQ77) / SCoPQ zone 10"},
  {coordinate_system:"EPSG:2017", label: "2017:NAD27(76) / MTM zone 8"},
  {coordinate_system:"EPSG:2018", label: "2018:NAD27(76) / MTM zone 9"},
  {coordinate_system:"EPSG:2019", label: "2019:NAD27(76) / MTM zone 10"},
  {coordinate_system:"EPSG:2020", label: "2020:NAD27(76) / MTM zone 11"},
  {coordinate_system:"EPSG:2021", label: "2021:NAD27(76) / MTM zone 12"},
  {coordinate_system:"EPSG:2022", label: "2022:NAD27(76) / MTM zone 13"},
  {coordinate_system:"EPSG:2023", label: "2023:NAD27(76) / MTM zone 14"},
  {coordinate_system:"EPSG:2024", label: "2024:NAD27(76) / MTM zone 15"},
  {coordinate_system:"EPSG:2025", label: "2025:NAD27(76) / MTM zone 16"},
  {coordinate_system:"EPSG:2026", label: "2026:NAD27(76) / MTM zone 17"},
  {coordinate_system:"EPSG:2027", label: "2027:NAD27(76) / UTM zone 15N"},
  {coordinate_system:"EPSG:2028", label: "2028:NAD27(76) / UTM zone 16N"},
  {coordinate_system:"EPSG:2029", label: "2029:NAD27(76) / UTM zone 17N"},
  {coordinate_system:"EPSG:2030", label: "2030:NAD27(76) / UTM zone 18N"},
  {coordinate_system:"EPSG:2031", label: "2031:NAD27(CGQ77) / UTM zone 17N"},
  {coordinate_system:"EPSG:2032", label: "2032:NAD27(CGQ77) / UTM zone 18N"},
  {coordinate_system:"EPSG:2033", label: "2033:NAD27(CGQ77) / UTM zone 19N"},
  {coordinate_system:"EPSG:2034", label: "2034:NAD27(CGQ77) / UTM zone 20N"},
  {coordinate_system:"EPSG:2035", label: "2035:NAD27(CGQ77) / UTM zone 21N"},
  {coordinate_system:"EPSG:2039", label: "2039:Israel 1993 / Israeli TM Grid"},
  {coordinate_system:"EPSG:2040", label: "2040:Locodjo 1965 / UTM zone 30N"},
  {coordinate_system:"EPSG:2041", label: "2041:Abidjan 1987 / UTM zone 30N"},
  {coordinate_system:"EPSG:2042", label: "2042:Locodjo 1965 / UTM zone 29N"},
  {coordinate_system:"EPSG:2043", label: "2043:Abidjan 1987 / UTM zone 29N"},
  {coordinate_system:"EPSG:2044", label: "2044:Hanoi 1972 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2045", label: "2045:Hanoi 1972 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2046", label: "2046:Hartebeesthoek94 / Lo15"},
  {coordinate_system:"EPSG:2047", label: "2047:Hartebeesthoek94 / Lo17"},
  {coordinate_system:"EPSG:2048", label: "2048:Hartebeesthoek94 / Lo19"},
  {coordinate_system:"EPSG:2049", label: "2049:Hartebeesthoek94 / Lo21"},
  {coordinate_system:"EPSG:2050", label: "2050:Hartebeesthoek94 / Lo23"},
  {coordinate_system:"EPSG:2051", label: "2051:Hartebeesthoek94 / Lo25"},
  {coordinate_system:"EPSG:2052", label: "2052:Hartebeesthoek94 / Lo27"},
  {coordinate_system:"EPSG:2053", label: "2053:Hartebeesthoek94 / Lo29"},
  {coordinate_system:"EPSG:2054", label: "2054:Hartebeesthoek94 / Lo31"},
  {coordinate_system:"EPSG:2055", label: "2055:Hartebeesthoek94 / Lo33"},
  {coordinate_system:"EPSG:2056", label: "2056:CH1903+ / LV95"},
  {coordinate_system:"EPSG:2057", label: "2057:Rassadiran / Nakhl e Taqi"},
  {coordinate_system:"EPSG:2058", label: "2058:ED50(ED77) / UTM zone 38N"},
  {coordinate_system:"EPSG:2059", label: "2059:ED50(ED77) / UTM zone 39N"},
  {coordinate_system:"EPSG:2060", label: "2060:ED50(ED77) / UTM zone 40N"},
  {coordinate_system:"EPSG:2061", label: "2061:ED50(ED77) / UTM zone 41N"},
  {coordinate_system:"EPSG:2062", label: "2062:Madrid 1870 (Madrid) / Spain LCC"},
  {coordinate_system:"EPSG:2065", label: "2065:S-JTSK (Ferro) / Krovak"},
  {coordinate_system:"EPSG:2066", label: "2066:Mount Dillon / Tobago Grid"},
  {coordinate_system:"EPSG:2067", label: "2067:Naparima 1955 / UTM zone 20N"},
  {coordinate_system:"EPSG:2068", label: "2068:ELD79 / Libya zone 5"},
  {coordinate_system:"EPSG:2069", label: "2069:ELD79 / Libya zone 6"},
  {coordinate_system:"EPSG:2070", label: "2070:ELD79 / Libya zone 7"},
  {coordinate_system:"EPSG:2071", label: "2071:ELD79 / Libya zone 8"},
  {coordinate_system:"EPSG:2072", label: "2072:ELD79 / Libya zone 9"},
  {coordinate_system:"EPSG:2073", label: "2073:ELD79 / Libya zone 10"},
  {coordinate_system:"EPSG:2074", label: "2074:ELD79 / Libya zone 11"},
  {coordinate_system:"EPSG:2075", label: "2075:ELD79 / Libya zone 12"},
  {coordinate_system:"EPSG:2076", label: "2076:ELD79 / Libya zone 13"},
  {coordinate_system:"EPSG:2077", label: "2077:ELD79 / UTM zone 32N"},
  {coordinate_system:"EPSG:2078", label: "2078:ELD79 / UTM zone 33N"},
  {coordinate_system:"EPSG:2079", label: "2079:ELD79 / UTM zone 34N"},
  {coordinate_system:"EPSG:2080", label: "2080:ELD79 / UTM zone 35N"},
  {coordinate_system:"EPSG:2081", label: "2081:Chos Malal 1914 / Argentina 2"},
  {coordinate_system:"EPSG:2082", label: "2082:Pampa del Castillo / Argentina 2"},
  {coordinate_system:"EPSG:2083", label: "2083:Hito XVIII 1963 / Argentina 2"},
  {coordinate_system:"EPSG:2084", label: "2084:Hito XVIII 1963 / UTM zone 19S"},
  {coordinate_system:"EPSG:2087", label: "2087:ELD79 / TM 12 NE"},
  {coordinate_system:"EPSG:2088", label: "2088:Carthage / TM 11 NE"},
  {coordinate_system:"EPSG:2089", label: "2089:Yemen NGN96 / UTM zone 38N"},
  {coordinate_system:"EPSG:2090", label: "2090:Yemen NGN96 / UTM zone 39N"},
  {coordinate_system:"EPSG:2093", label: "2093:Hanoi 1972 / GK 106 NE"},
  {coordinate_system:"EPSG:2094", label: "2094:WGS 72BE / TM 106 NE"},
  {coordinate_system:"EPSG:2095", label: "2095:Bissau / UTM zone 28N"},
  {coordinate_system:"EPSG:2096", label: "2096:Korean 1985 / East Belt"},
  {coordinate_system:"EPSG:2097", label: "2097:Korean 1985 / Central Belt"},
  {coordinate_system:"EPSG:2098", label: "2098:Korean 1985 / West Belt"},
  {coordinate_system:"EPSG:2099", label: "2099:Qatar 1948 / Qatar Grid"},
  {coordinate_system:"EPSG:2100", label: "2100:GGRS87 / Greek Grid"},
  {coordinate_system:"EPSG:2101", label: "2101:Lake / Maracaibo Grid M1"},
  {coordinate_system:"EPSG:2102", label: "2102:Lake / Maracaibo Grid"},
  {coordinate_system:"EPSG:2103", label: "2103:Lake / Maracaibo Grid M3"},
  {coordinate_system:"EPSG:2104", label: "2104:Lake / Maracaibo La Rosa Grid"},
  {coordinate_system:"EPSG:2105", label: "2105:NZGD2000 / Mount Eden 2000"},
  {coordinate_system:"EPSG:2106", label: "2106:NZGD2000 / Bay of Plenty 2000"},
  {coordinate_system:"EPSG:2107", label: "2107:NZGD2000 / Poverty Bay 2000"},
  {coordinate_system:"EPSG:2108", label: "2108:NZGD2000 / Hawkes Bay 2000"},
  {coordinate_system:"EPSG:2109", label: "2109:NZGD2000 / Taranaki 2000"},
  {coordinate_system:"EPSG:2110", label: "2110:NZGD2000 / Tuhirangi 2000"},
  {coordinate_system:"EPSG:2111", label: "2111:NZGD2000 / Wanganui 2000"},
  {coordinate_system:"EPSG:2112", label: "2112:NZGD2000 / Wairarapa 2000"},
  {coordinate_system:"EPSG:2113", label: "2113:NZGD2000 / Wellington 2000"},
  {coordinate_system:"EPSG:2114", label: "2114:NZGD2000 / Collingwood 2000"},
  {coordinate_system:"EPSG:2115", label: "2115:NZGD2000 / Nelson 2000"},
  {coordinate_system:"EPSG:2116", label: "2116:NZGD2000 / Karamea 2000"},
  {coordinate_system:"EPSG:2117", label: "2117:NZGD2000 / Buller 2000"},
  {coordinate_system:"EPSG:2118", label: "2118:NZGD2000 / Grey 2000"},
  {coordinate_system:"EPSG:2119", label: "2119:NZGD2000 / Amuri 2000"},
  {coordinate_system:"EPSG:2120", label: "2120:NZGD2000 / Marlborough 2000"},
  {coordinate_system:"EPSG:2121", label: "2121:NZGD2000 / Hokitika 2000"},
  {coordinate_system:"EPSG:2122", label: "2122:NZGD2000 / Okarito 2000"},
  {coordinate_system:"EPSG:2123", label: "2123:NZGD2000 / Jacksons Bay 2000"},
  {coordinate_system:"EPSG:2124", label: "2124:NZGD2000 / Mount Pleasant 2000"},
  {coordinate_system:"EPSG:2125", label: "2125:NZGD2000 / Gawler 2000"},
  {coordinate_system:"EPSG:2126", label: "2126:NZGD2000 / Timaru 2000"},
  {coordinate_system:"EPSG:2127", label: "2127:NZGD2000 / Lindis Peak 2000"},
  {coordinate_system:"EPSG:2128", label: "2128:NZGD2000 / Mount Nicholas 2000"},
  {coordinate_system:"EPSG:2129", label: "2129:NZGD2000 / Mount York 2000"},
  {coordinate_system:"EPSG:2130", label: "2130:NZGD2000 / Observation Point 2000"},
  {coordinate_system:"EPSG:2131", label: "2131:NZGD2000 / North Taieri 2000"},
  {coordinate_system:"EPSG:2132", label: "2132:NZGD2000 / Bluff 2000"},
  {coordinate_system:"EPSG:2133", label: "2133:NZGD2000 / UTM zone 58S"},
  {coordinate_system:"EPSG:2134", label: "2134:NZGD2000 / UTM zone 59S"},
  {coordinate_system:"EPSG:2135", label: "2135:NZGD2000 / UTM zone 60S"},
  {coordinate_system:"EPSG:2136", label: "2136:Accra / Ghana National Grid"},
  {coordinate_system:"EPSG:2137", label: "2137:Accra / TM 1 NW"},
  {coordinate_system:"EPSG:2138", label: "2138:NAD27(CGQ77) / Quebec Lambert"},
  {coordinate_system:"EPSG:2154", label: "2154:RGF93 v1 / Lambert-93"},
  {coordinate_system:"EPSG:2157", label: "2157:IRENET95 / Irish Transverse Mercator"},
  {coordinate_system:"EPSG:2158", label: "2158:IRENET95 / UTM zone 29N"},
  {coordinate_system:"EPSG:2159", label: "2159:Sierra Leone 1924 / New Colony Grid"},
  {coordinate_system:"EPSG:2160", label: "2160:Sierra Leone 1924 / New War Office Grid"},
  {coordinate_system:"EPSG:2161", label: "2161:Sierra Leone 1968 / UTM zone 28N"},
  {coordinate_system:"EPSG:2162", label: "2162:Sierra Leone 1968 / UTM zone 29N"},
  {coordinate_system:"EPSG:2164", label: "2164:Locodjo 1965 / TM 5 NW"},
  {coordinate_system:"EPSG:2165", label: "2165:Abidjan 1987 / TM 5 NW"},
  {coordinate_system:"EPSG:2169", label: "2169:LUREF / Luxembourg TM"},
  {coordinate_system:"EPSG:2172", label: "2172:Pulkovo 1942(58) / Poland zone II"},
  {coordinate_system:"EPSG:2173", label: "2173:Pulkovo 1942(58) / Poland zone III"},
  {coordinate_system:"EPSG:2174", label: "2174:Pulkovo 1942(58) / Poland zone IV"},
  {coordinate_system:"EPSG:2175", label: "2175:Pulkovo 1942(58) / Poland zone V"},
  {coordinate_system:"EPSG:2176", label: "2176:ETRF2000-PL / CS2000/15"},
  {coordinate_system:"EPSG:2177", label: "2177:ETRF2000-PL / CS2000/18"},
  {coordinate_system:"EPSG:2178", label: "2178:ETRF2000-PL / CS2000/21"},
  {coordinate_system:"EPSG:2179", label: "2179:ETRF2000-PL / CS2000/24"},
  {coordinate_system:"EPSG:2180", label: "2180:ETRF2000-PL / CS92"},
  {coordinate_system:"EPSG:2188", label: "2188:Azores Occidental 1939 / UTM zone 25N"},
  {coordinate_system:"EPSG:2189", label: "2189:Azores Central 1948 / UTM zone 26N"},
  {coordinate_system:"EPSG:2190", label: "2190:Azores Oriental 1940 / UTM zone 26N"},
  {coordinate_system:"EPSG:2193", label: "2193:NZGD2000 / New Zealand Transverse Mercator 2000"},
  {coordinate_system:"EPSG:2195", label: "2195:NAD83(HARN) / UTM zone 2S"},
  {coordinate_system:"EPSG:2196", label: "2196:ETRS89 / Kp2000 Jutland"},
  {coordinate_system:"EPSG:2197", label: "2197:ETRS89 / Kp2000 Zealand"},
  {coordinate_system:"EPSG:2198", label: "2198:ETRS89 / Kp2000 Bornholm"},
  {coordinate_system:"EPSG:2200", label: "2200:ATS77 / New Brunswick Stereographic (ATS77)"},
  {coordinate_system:"EPSG:2201", label: "2201:REGVEN / UTM zone 18N"},
  {coordinate_system:"EPSG:2202", label: "2202:REGVEN / UTM zone 19N"},
  {coordinate_system:"EPSG:2203", label: "2203:REGVEN / UTM zone 20N"},
  {coordinate_system:"EPSG:2204", label: "2204:NAD27 / Tennessee"},
  {coordinate_system:"EPSG:2205", label: "2205:NAD83 / Kentucky North"},
  {coordinate_system:"EPSG:2206", label: "2206:ED50 / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2207", label: "2207:ED50 / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:2208", label: "2208:ED50 / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:2209", label: "2209:ED50 / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:2210", label: "2210:ED50 / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2211", label: "2211:ED50 / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2212", label: "2212:ED50 / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2213", label: "2213:ETRS89 / TM 30 NE"},
  {coordinate_system:"EPSG:2215", label: "2215:Manoca 1962 / UTM zone 32N"},
  {coordinate_system:"EPSG:2216", label: "2216:Qornoq 1927 / UTM zone 22N"},
  {coordinate_system:"EPSG:2217", label: "2217:Qornoq 1927 / UTM zone 23N"},
  {coordinate_system:"EPSG:2218", label: "2218:Scoresbysund 1952 / Greenland zone 5 east"},
  {coordinate_system:"EPSG:2219", label: "2219:ATS77 / UTM zone 19N"},
  {coordinate_system:"EPSG:2220", label: "2220:ATS77 / UTM zone 20N"},
  {coordinate_system:"EPSG:2221", label: "2221:Scoresbysund 1952 / Greenland zone 6 east"},
  {coordinate_system:"EPSG:2222", label: "2222:NAD83 / Arizona East (ft)"},
  {coordinate_system:"EPSG:2223", label: "2223:NAD83 / Arizona Central (ft)"},
  {coordinate_system:"EPSG:2224", label: "2224:NAD83 / Arizona West (ft)"},
  {coordinate_system:"EPSG:2225", label: "2225:NAD83 / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:2226", label: "2226:NAD83 / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:2227", label: "2227:NAD83 / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:2228", label: "2228:NAD83 / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:2229", label: "2229:NAD83 / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:2230", label: "2230:NAD83 / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:2231", label: "2231:NAD83 / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:2232", label: "2232:NAD83 / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:2233", label: "2233:NAD83 / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:2234", label: "2234:NAD83 / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:2235", label: "2235:NAD83 / Delaware (ftUS)"},
  {coordinate_system:"EPSG:2236", label: "2236:NAD83 / Florida East (ftUS)"},
  {coordinate_system:"EPSG:2237", label: "2237:NAD83 / Florida West (ftUS)"},
  {coordinate_system:"EPSG:2238", label: "2238:NAD83 / Florida North (ftUS)"},
  {coordinate_system:"EPSG:2239", label: "2239:NAD83 / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:2240", label: "2240:NAD83 / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:2241", label: "2241:NAD83 / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:2242", label: "2242:NAD83 / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:2243", label: "2243:NAD83 / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:2246", label: "2246:NAD83 / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:2247", label: "2247:NAD83 / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:2248", label: "2248:NAD83 / Maryland (ftUS)"},
  {coordinate_system:"EPSG:2249", label: "2249:NAD83 / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:2250", label: "2250:NAD83 / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:2251", label: "2251:NAD83 / Michigan North (ft)"},
  {coordinate_system:"EPSG:2252", label: "2252:NAD83 / Michigan Central (ft)"},
  {coordinate_system:"EPSG:2253", label: "2253:NAD83 / Michigan South (ft)"},
  {coordinate_system:"EPSG:2254", label: "2254:NAD83 / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:2255", label: "2255:NAD83 / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:2256", label: "2256:NAD83 / Montana (ft)"},
  {coordinate_system:"EPSG:2257", label: "2257:NAD83 / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:2258", label: "2258:NAD83 / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:2259", label: "2259:NAD83 / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:2260", label: "2260:NAD83 / New York East (ftUS)"},
  {coordinate_system:"EPSG:2261", label: "2261:NAD83 / New York Central (ftUS)"},
  {coordinate_system:"EPSG:2262", label: "2262:NAD83 / New York West (ftUS)"},
  {coordinate_system:"EPSG:2263", label: "2263:NAD83 / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:2264", label: "2264:NAD83 / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:2265", label: "2265:NAD83 / North Dakota North (ft)"},
  {coordinate_system:"EPSG:2266", label: "2266:NAD83 / North Dakota South (ft)"},
  {coordinate_system:"EPSG:2267", label: "2267:NAD83 / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:2268", label: "2268:NAD83 / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:2269", label: "2269:NAD83 / Oregon North (ft)"},
  {coordinate_system:"EPSG:2270", label: "2270:NAD83 / Oregon South (ft)"},
  {coordinate_system:"EPSG:2271", label: "2271:NAD83 / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:2272", label: "2272:NAD83 / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:2273", label: "2273:NAD83 / South Carolina (ft)"},
  {coordinate_system:"EPSG:2274", label: "2274:NAD83 / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:2275", label: "2275:NAD83 / Texas North (ftUS)"},
  {coordinate_system:"EPSG:2276", label: "2276:NAD83 / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:2277", label: "2277:NAD83 / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:2278", label: "2278:NAD83 / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:2279", label: "2279:NAD83 / Texas South (ftUS)"},
  {coordinate_system:"EPSG:2280", label: "2280:NAD83 / Utah North (ft)"},
  {coordinate_system:"EPSG:2281", label: "2281:NAD83 / Utah Central (ft)"},
  {coordinate_system:"EPSG:2282", label: "2282:NAD83 / Utah South (ft)"},
  {coordinate_system:"EPSG:2283", label: "2283:NAD83 / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:2284", label: "2284:NAD83 / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:2285", label: "2285:NAD83 / Washington North (ftUS)"},
  {coordinate_system:"EPSG:2286", label: "2286:NAD83 / Washington South (ftUS)"},
  {coordinate_system:"EPSG:2287", label: "2287:NAD83 / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:2288", label: "2288:NAD83 / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:2289", label: "2289:NAD83 / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:2290", label: "2290:ATS77 / Prince Edward Isl. Stereographic (ATS77)"},
  {coordinate_system:"EPSG:2294", label: "2294:ATS77 / MTM Nova Scotia zone 4"},
  {coordinate_system:"EPSG:2295", label: "2295:ATS77 / MTM Nova Scotia zone 5"},
  {coordinate_system:"EPSG:2296", label: "2296:Ammassalik 1958 / Greenland zone 7 east"},
  {coordinate_system:"EPSG:2299", label: "2299:Qornoq 1927 / Greenland zone 2 west"},
  {coordinate_system:"EPSG:2301", label: "2301:Qornoq 1927 / Greenland zone 3 west"},
  {coordinate_system:"EPSG:2303", label: "2303:Qornoq 1927 / Greenland zone 4 west"},
  {coordinate_system:"EPSG:2304", label: "2304:Qornoq 1927 / Greenland zone 5 west"},
  {coordinate_system:"EPSG:2305", label: "2305:Qornoq 1927 / Greenland zone 6 west"},
  {coordinate_system:"EPSG:2306", label: "2306:Qornoq 1927 / Greenland zone 7 west"},
  {coordinate_system:"EPSG:2307", label: "2307:Qornoq 1927 / Greenland zone 8 east"},
  {coordinate_system:"EPSG:2308", label: "2308:Batavia / TM 109 SE"},
  {coordinate_system:"EPSG:2309", label: "2309:WGS 84 / TM 116 SE"},
  {coordinate_system:"EPSG:2310", label: "2310:WGS 84 / TM 132 SE"},
  {coordinate_system:"EPSG:2311", label: "2311:WGS 84 / TM 6 NE"},
  {coordinate_system:"EPSG:2312", label: "2312:Garoua / UTM zone 33N"},
  {coordinate_system:"EPSG:2313", label: "2313:Kousseri / UTM zone 33N"},
  {coordinate_system:"EPSG:2314", label: "2314:Trinidad 1903 / Trinidad Grid (ftCla)"},
  {coordinate_system:"EPSG:2315", label: "2315:Campo Inchauspe / UTM zone 19S"},
  {coordinate_system:"EPSG:2316", label: "2316:Campo Inchauspe / UTM zone 20S"},
  {coordinate_system:"EPSG:2317", label: "2317:PSAD56 / ICN Regional"},
  {coordinate_system:"EPSG:2318", label: "2318:Ain el Abd / Aramco Lambert"},
  {coordinate_system:"EPSG:2319", label: "2319:ED50 / TM27"},
  {coordinate_system:"EPSG:2320", label: "2320:ED50 / TM30"},
  {coordinate_system:"EPSG:2321", label: "2321:ED50 / TM33"},
  {coordinate_system:"EPSG:2322", label: "2322:ED50 / TM36"},
  {coordinate_system:"EPSG:2323", label: "2323:ED50 / TM39"},
  {coordinate_system:"EPSG:2324", label: "2324:ED50 / TM42"},
  {coordinate_system:"EPSG:2325", label: "2325:ED50 / TM45"},
  {coordinate_system:"EPSG:2326", label: "2326:Hong Kong 1980 Grid System"},
  {coordinate_system:"EPSG:2327", label: "2327:Xian 1980 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2328", label: "2328:Xian 1980 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2329", label: "2329:Xian 1980 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2330", label: "2330:Xian 1980 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:2331", label: "2331:Xian 1980 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:2332", label: "2332:Xian 1980 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2333", label: "2333:Xian 1980 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2334", label: "2334:Xian 1980 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:2335", label: "2335:Xian 1980 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:2336", label: "2336:Xian 1980 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:2337", label: "2337:Xian 1980 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:2338", label: "2338:Xian 1980 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2339", label: "2339:Xian 1980 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2340", label: "2340:Xian 1980 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2341", label: "2341:Xian 1980 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2342", label: "2342:Xian 1980 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2343", label: "2343:Xian 1980 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2344", label: "2344:Xian 1980 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2345", label: "2345:Xian 1980 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2346", label: "2346:Xian 1980 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2347", label: "2347:Xian 1980 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2348", label: "2348:Xian 1980 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2349", label: "2349:Xian 1980 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2350", label: "2350:Xian 1980 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2351", label: "2351:Xian 1980 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2352", label: "2352:Xian 1980 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2353", label: "2353:Xian 1980 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2354", label: "2354:Xian 1980 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2355", label: "2355:Xian 1980 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2356", label: "2356:Xian 1980 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2357", label: "2357:Xian 1980 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2358", label: "2358:Xian 1980 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2359", label: "2359:Xian 1980 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2360", label: "2360:Xian 1980 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2361", label: "2361:Xian 1980 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2362", label: "2362:Xian 1980 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2363", label: "2363:Xian 1980 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2364", label: "2364:Xian 1980 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2365", label: "2365:Xian 1980 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2366", label: "2366:Xian 1980 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2367", label: "2367:Xian 1980 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2368", label: "2368:Xian 1980 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2369", label: "2369:Xian 1980 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2370", label: "2370:Xian 1980 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2371", label: "2371:Xian 1980 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2372", label: "2372:Xian 1980 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2373", label: "2373:Xian 1980 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2374", label: "2374:Xian 1980 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2375", label: "2375:Xian 1980 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2376", label: "2376:Xian 1980 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2377", label: "2377:Xian 1980 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2378", label: "2378:Xian 1980 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2379", label: "2379:Xian 1980 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2380", label: "2380:Xian 1980 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2381", label: "2381:Xian 1980 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2382", label: "2382:Xian 1980 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2383", label: "2383:Xian 1980 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2384", label: "2384:Xian 1980 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2385", label: "2385:Xian 1980 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2386", label: "2386:Xian 1980 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2387", label: "2387:Xian 1980 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2388", label: "2388:Xian 1980 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2389", label: "2389:Xian 1980 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2390", label: "2390:Xian 1980 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2391", label: "2391:KKJ / Finland zone 1"},
  {coordinate_system:"EPSG:2392", label: "2392:KKJ / Finland zone 2"},
  {coordinate_system:"EPSG:2393", label: "2393:KKJ / Finland Uniform Coordinate System"},
  {coordinate_system:"EPSG:2393", label: "2393:KKJ / Finland Uniform Coordinate System"},
  {coordinate_system:"EPSG:2394", label: "2394:KKJ / Finland zone 4"},
  {coordinate_system:"EPSG:2395", label: "2395:South Yemen / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:2396", label: "2396:South Yemen / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2397", label: "2397:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:2398", label: "2398:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:2399", label: "2399:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:2401", label: "2401:Beijing 1954 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2402", label: "2402:Beijing 1954 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2403", label: "2403:Beijing 1954 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2404", label: "2404:Beijing 1954 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2405", label: "2405:Beijing 1954 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2406", label: "2406:Beijing 1954 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2407", label: "2407:Beijing 1954 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2408", label: "2408:Beijing 1954 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2409", label: "2409:Beijing 1954 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2410", label: "2410:Beijing 1954 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2411", label: "2411:Beijing 1954 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2412", label: "2412:Beijing 1954 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2413", label: "2413:Beijing 1954 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2414", label: "2414:Beijing 1954 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2415", label: "2415:Beijing 1954 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2416", label: "2416:Beijing 1954 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2417", label: "2417:Beijing 1954 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2418", label: "2418:Beijing 1954 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2419", label: "2419:Beijing 1954 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2420", label: "2420:Beijing 1954 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2421", label: "2421:Beijing 1954 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2422", label: "2422:Beijing 1954 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2423", label: "2423:Beijing 1954 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2424", label: "2424:Beijing 1954 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2425", label: "2425:Beijing 1954 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2426", label: "2426:Beijing 1954 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2427", label: "2427:Beijing 1954 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2428", label: "2428:Beijing 1954 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2429", label: "2429:Beijing 1954 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2430", label: "2430:Beijing 1954 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2431", label: "2431:Beijing 1954 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2432", label: "2432:Beijing 1954 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2433", label: "2433:Beijing 1954 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2434", label: "2434:Beijing 1954 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2435", label: "2435:Beijing 1954 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2436", label: "2436:Beijing 1954 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2437", label: "2437:Beijing 1954 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2438", label: "2438:Beijing 1954 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2439", label: "2439:Beijing 1954 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2440", label: "2440:Beijing 1954 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2441", label: "2441:Beijing 1954 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2442", label: "2442:Beijing 1954 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2443", label: "2443:JGD2000 / Japan Plane Rectangular CS I"},
  {coordinate_system:"EPSG:2444", label: "2444:JGD2000 / Japan Plane Rectangular CS II"},
  {coordinate_system:"EPSG:2445", label: "2445:JGD2000 / Japan Plane Rectangular CS III"},
  {coordinate_system:"EPSG:2446", label: "2446:JGD2000 / Japan Plane Rectangular CS IV"},
  {coordinate_system:"EPSG:2447", label: "2447:JGD2000 / Japan Plane Rectangular CS V"},
  {coordinate_system:"EPSG:2448", label: "2448:JGD2000 / Japan Plane Rectangular CS VI"},
  {coordinate_system:"EPSG:2449", label: "2449:JGD2000 / Japan Plane Rectangular CS VII"},
  {coordinate_system:"EPSG:2450", label: "2450:JGD2000 / Japan Plane Rectangular CS VIII"},
  {coordinate_system:"EPSG:2451", label: "2451:JGD2000 / Japan Plane Rectangular CS IX"},
  {coordinate_system:"EPSG:2452", label: "2452:JGD2000 / Japan Plane Rectangular CS X"},
  {coordinate_system:"EPSG:2453", label: "2453:JGD2000 / Japan Plane Rectangular CS XI"},
  {coordinate_system:"EPSG:2454", label: "2454:JGD2000 / Japan Plane Rectangular CS XII"},
  {coordinate_system:"EPSG:2455", label: "2455:JGD2000 / Japan Plane Rectangular CS XIII"},
  {coordinate_system:"EPSG:2456", label: "2456:JGD2000 / Japan Plane Rectangular CS XIV"},
  {coordinate_system:"EPSG:2457", label: "2457:JGD2000 / Japan Plane Rectangular CS XV"},
  {coordinate_system:"EPSG:2458", label: "2458:JGD2000 / Japan Plane Rectangular CS XVI"},
  {coordinate_system:"EPSG:2459", label: "2459:JGD2000 / Japan Plane Rectangular CS XVII"},
  {coordinate_system:"EPSG:2460", label: "2460:JGD2000 / Japan Plane Rectangular CS XVIII"},
  {coordinate_system:"EPSG:2461", label: "2461:JGD2000 / Japan Plane Rectangular CS XIX"},
  {coordinate_system:"EPSG:2462", label: "2462:Albanian 1987 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:2463", label: "2463:Pulkovo 1995 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2464", label: "2464:Pulkovo 1995 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2465", label: "2465:Pulkovo 1995 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2466", label: "2466:Pulkovo 1995 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2467", label: "2467:Pulkovo 1995 / Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2468", label: "2468:Pulkovo 1995 / Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2469", label: "2469:Pulkovo 1995 / Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2470", label: "2470:Pulkovo 1995 / Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2471", label: "2471:Pulkovo 1995 / Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2472", label: "2472:Pulkovo 1995 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2473", label: "2473:Pulkovo 1995 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2474", label: "2474:Pulkovo 1995 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2475", label: "2475:Pulkovo 1995 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2476", label: "2476:Pulkovo 1995 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2477", label: "2477:Pulkovo 1995 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2478", label: "2478:Pulkovo 1995 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2479", label: "2479:Pulkovo 1995 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2480", label: "2480:Pulkovo 1995 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2481", label: "2481:Pulkovo 1995 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2482", label: "2482:Pulkovo 1995 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2483", label: "2483:Pulkovo 1995 / Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2484", label: "2484:Pulkovo 1995 / Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2485", label: "2485:Pulkovo 1995 / Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2486", label: "2486:Pulkovo 1995 / Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2487", label: "2487:Pulkovo 1995 / Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2488", label: "2488:Pulkovo 1995 / Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2489", label: "2489:Pulkovo 1995 / Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2490", label: "2490:Pulkovo 1995 / Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2491", label: "2491:Pulkovo 1995 / Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2494", label: "2494:Pulkovo 1942 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2495", label: "2495:Pulkovo 1942 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2496", label: "2496:Pulkovo 1942 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2497", label: "2497:Pulkovo 1942 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2498", label: "2498:Pulkovo 1942 / Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2499", label: "2499:Pulkovo 1942 / Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2500", label: "2500:Pulkovo 1942 / Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2501", label: "2501:Pulkovo 1942 / Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2502", label: "2502:Pulkovo 1942 / Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2503", label: "2503:Pulkovo 1942 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2504", label: "2504:Pulkovo 1942 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2505", label: "2505:Pulkovo 1942 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2506", label: "2506:Pulkovo 1942 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2507", label: "2507:Pulkovo 1942 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2508", label: "2508:Pulkovo 1942 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2509", label: "2509:Pulkovo 1942 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2510", label: "2510:Pulkovo 1942 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2511", label: "2511:Pulkovo 1942 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2512", label: "2512:Pulkovo 1942 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2513", label: "2513:Pulkovo 1942 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2514", label: "2514:Pulkovo 1942 / Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2515", label: "2515:Pulkovo 1942 / Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2516", label: "2516:Pulkovo 1942 / Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2517", label: "2517:Pulkovo 1942 / Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2518", label: "2518:Pulkovo 1942 / Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2519", label: "2519:Pulkovo 1942 / Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2520", label: "2520:Pulkovo 1942 / Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2521", label: "2521:Pulkovo 1942 / Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2522", label: "2522:Pulkovo 1942 / Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2523", label: "2523:Pulkovo 1942 / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:2524", label: "2524:Pulkovo 1942 / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:2525", label: "2525:Pulkovo 1942 / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2526", label: "2526:Pulkovo 1942 / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:2527", label: "2527:Pulkovo 1942 / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:2528", label: "2528:Pulkovo 1942 / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:2529", label: "2529:Pulkovo 1942 / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2530", label: "2530:Pulkovo 1942 / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2531", label: "2531:Pulkovo 1942 / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2532", label: "2532:Pulkovo 1942 / 3-degree Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:2533", label: "2533:Pulkovo 1942 / 3-degree Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:2534", label: "2534:Pulkovo 1942 / 3-degree Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2535", label: "2535:Pulkovo 1942 / 3-degree Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2536", label: "2536:Pulkovo 1942 / 3-degree Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:2537", label: "2537:Pulkovo 1942 / 3-degree Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:2538", label: "2538:Pulkovo 1942 / 3-degree Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:2539", label: "2539:Pulkovo 1942 / 3-degree Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:2540", label: "2540:Pulkovo 1942 / 3-degree Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:2541", label: "2541:Pulkovo 1942 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2542", label: "2542:Pulkovo 1942 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2543", label: "2543:Pulkovo 1942 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2544", label: "2544:Pulkovo 1942 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2545", label: "2545:Pulkovo 1942 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2546", label: "2546:Pulkovo 1942 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2547", label: "2547:Pulkovo 1942 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2548", label: "2548:Pulkovo 1942 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2549", label: "2549:Pulkovo 1942 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2551", label: "2551:Pulkovo 1942 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2552", label: "2552:Pulkovo 1942 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2553", label: "2553:Pulkovo 1942 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2554", label: "2554:Pulkovo 1942 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2555", label: "2555:Pulkovo 1942 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2556", label: "2556:Pulkovo 1942 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2557", label: "2557:Pulkovo 1942 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2558", label: "2558:Pulkovo 1942 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2559", label: "2559:Pulkovo 1942 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2560", label: "2560:Pulkovo 1942 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2561", label: "2561:Pulkovo 1942 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2562", label: "2562:Pulkovo 1942 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2563", label: "2563:Pulkovo 1942 / 3-degree Gauss-Kruger zone 46"},
  {coordinate_system:"EPSG:2564", label: "2564:Pulkovo 1942 / 3-degree Gauss-Kruger zone 47"},
  {coordinate_system:"EPSG:2565", label: "2565:Pulkovo 1942 / 3-degree Gauss-Kruger zone 48"},
  {coordinate_system:"EPSG:2566", label: "2566:Pulkovo 1942 / 3-degree Gauss-Kruger zone 49"},
  {coordinate_system:"EPSG:2567", label: "2567:Pulkovo 1942 / 3-degree Gauss-Kruger zone 50"},
  {coordinate_system:"EPSG:2568", label: "2568:Pulkovo 1942 / 3-degree Gauss-Kruger zone 51"},
  {coordinate_system:"EPSG:2569", label: "2569:Pulkovo 1942 / 3-degree Gauss-Kruger zone 52"},
  {coordinate_system:"EPSG:2570", label: "2570:Pulkovo 1942 / 3-degree Gauss-Kruger zone 53"},
  {coordinate_system:"EPSG:2571", label: "2571:Pulkovo 1942 / 3-degree Gauss-Kruger zone 54"},
  {coordinate_system:"EPSG:2572", label: "2572:Pulkovo 1942 / 3-degree Gauss-Kruger zone 55"},
  {coordinate_system:"EPSG:2573", label: "2573:Pulkovo 1942 / 3-degree Gauss-Kruger zone 56"},
  {coordinate_system:"EPSG:2574", label: "2574:Pulkovo 1942 / 3-degree Gauss-Kruger zone 57"},
  {coordinate_system:"EPSG:2575", label: "2575:Pulkovo 1942 / 3-degree Gauss-Kruger zone 58"},
  {coordinate_system:"EPSG:2576", label: "2576:Pulkovo 1942 / 3-degree Gauss-Kruger zone 59"},
  {coordinate_system:"EPSG:2578", label: "2578:Pulkovo 1942 / 3-degree Gauss-Kruger zone 61"},
  {coordinate_system:"EPSG:2579", label: "2579:Pulkovo 1942 / 3-degree Gauss-Kruger zone 62"},
  {coordinate_system:"EPSG:2580", label: "2580:Pulkovo 1942 / 3-degree Gauss-Kruger zone 63"},
  {coordinate_system:"EPSG:2581", label: "2581:Pulkovo 1942 / 3-degree Gauss-Kruger zone 64"},
  {coordinate_system:"EPSG:2582", label: "2582:Pulkovo 1942 / 3-degree Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2583", label: "2583:Pulkovo 1942 / 3-degree Gauss-Kruger CM 24E"},
  {coordinate_system:"EPSG:2584", label: "2584:Pulkovo 1942 / 3-degree Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2585", label: "2585:Pulkovo 1942 / 3-degree Gauss-Kruger CM 30E"},
  {coordinate_system:"EPSG:2586", label: "2586:Pulkovo 1942 / 3-degree Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2587", label: "2587:Pulkovo 1942 / 3-degree Gauss-Kruger CM 36E"},
  {coordinate_system:"EPSG:2588", label: "2588:Pulkovo 1942 / 3-degree Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2589", label: "2589:Pulkovo 1942 / 3-degree Gauss-Kruger CM 42E"},
  {coordinate_system:"EPSG:2590", label: "2590:Pulkovo 1942 / 3-degree Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2591", label: "2591:Pulkovo 1942 / 3-degree Gauss-Kruger CM 48E"},
  {coordinate_system:"EPSG:2592", label: "2592:Pulkovo 1942 / 3-degree Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2593", label: "2593:Pulkovo 1942 / 3-degree Gauss-Kruger CM 54E"},
  {coordinate_system:"EPSG:2594", label: "2594:Pulkovo 1942 / 3-degree Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2595", label: "2595:Pulkovo 1942 / 3-degree Gauss-Kruger CM 60E"},
  {coordinate_system:"EPSG:2596", label: "2596:Pulkovo 1942 / 3-degree Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2597", label: "2597:Pulkovo 1942 / 3-degree Gauss-Kruger CM 66E"},
  {coordinate_system:"EPSG:2598", label: "2598:Pulkovo 1942 / 3-degree Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2599", label: "2599:Pulkovo 1942 / 3-degree Gauss-Kruger CM 72E"},
  {coordinate_system:"EPSG:2601", label: "2601:Pulkovo 1942 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2602", label: "2602:Pulkovo 1942 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2603", label: "2603:Pulkovo 1942 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2604", label: "2604:Pulkovo 1942 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2605", label: "2605:Pulkovo 1942 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2606", label: "2606:Pulkovo 1942 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2607", label: "2607:Pulkovo 1942 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2608", label: "2608:Pulkovo 1942 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2609", label: "2609:Pulkovo 1942 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2610", label: "2610:Pulkovo 1942 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2611", label: "2611:Pulkovo 1942 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2612", label: "2612:Pulkovo 1942 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2613", label: "2613:Pulkovo 1942 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2614", label: "2614:Pulkovo 1942 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2615", label: "2615:Pulkovo 1942 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2616", label: "2616:Pulkovo 1942 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2617", label: "2617:Pulkovo 1942 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2618", label: "2618:Pulkovo 1942 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2619", label: "2619:Pulkovo 1942 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2620", label: "2620:Pulkovo 1942 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2621", label: "2621:Pulkovo 1942 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2622", label: "2622:Pulkovo 1942 / 3-degree Gauss-Kruger CM 138E"},
  {coordinate_system:"EPSG:2623", label: "2623:Pulkovo 1942 / 3-degree Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2624", label: "2624:Pulkovo 1942 / 3-degree Gauss-Kruger CM 144E"},
  {coordinate_system:"EPSG:2625", label: "2625:Pulkovo 1942 / 3-degree Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2626", label: "2626:Pulkovo 1942 / 3-degree Gauss-Kruger CM 150E"},
  {coordinate_system:"EPSG:2627", label: "2627:Pulkovo 1942 / 3-degree Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2628", label: "2628:Pulkovo 1942 / 3-degree Gauss-Kruger CM 156E"},
  {coordinate_system:"EPSG:2629", label: "2629:Pulkovo 1942 / 3-degree Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2630", label: "2630:Pulkovo 1942 / 3-degree Gauss-Kruger CM 162E"},
  {coordinate_system:"EPSG:2631", label: "2631:Pulkovo 1942 / 3-degree Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2632", label: "2632:Pulkovo 1942 / 3-degree Gauss-Kruger CM 168E"},
  {coordinate_system:"EPSG:2633", label: "2633:Pulkovo 1942 / 3-degree Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2634", label: "2634:Pulkovo 1942 / 3-degree Gauss-Kruger CM 174E"},
  {coordinate_system:"EPSG:2635", label: "2635:Pulkovo 1942 / 3-degree Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2636", label: "2636:Pulkovo 1942 / 3-degree Gauss-Kruger CM 180E"},
  {coordinate_system:"EPSG:2637", label: "2637:Pulkovo 1942 / 3-degree Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2638", label: "2638:Pulkovo 1942 / 3-degree Gauss-Kruger CM 174W"},
  {coordinate_system:"EPSG:2639", label: "2639:Pulkovo 1942 / 3-degree Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2640", label: "2640:Pulkovo 1942 / 3-degree Gauss-Kruger CM 168W"},
  {coordinate_system:"EPSG:2641", label: "2641:Pulkovo 1995 / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:2642", label: "2642:Pulkovo 1995 / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:2643", label: "2643:Pulkovo 1995 / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:2644", label: "2644:Pulkovo 1995 / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:2645", label: "2645:Pulkovo 1995 / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:2646", label: "2646:Pulkovo 1995 / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:2647", label: "2647:Pulkovo 1995 / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:2648", label: "2648:Pulkovo 1995 / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:2649", label: "2649:Pulkovo 1995 / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:2650", label: "2650:Pulkovo 1995 / 3-degree Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:2651", label: "2651:Pulkovo 1995 / 3-degree Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:2652", label: "2652:Pulkovo 1995 / 3-degree Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:2653", label: "2653:Pulkovo 1995 / 3-degree Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:2654", label: "2654:Pulkovo 1995 / 3-degree Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:2655", label: "2655:Pulkovo 1995 / 3-degree Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:2656", label: "2656:Pulkovo 1995 / 3-degree Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:2657", label: "2657:Pulkovo 1995 / 3-degree Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:2658", label: "2658:Pulkovo 1995 / 3-degree Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:2659", label: "2659:Pulkovo 1995 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:2660", label: "2660:Pulkovo 1995 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:2661", label: "2661:Pulkovo 1995 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:2662", label: "2662:Pulkovo 1995 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:2663", label: "2663:Pulkovo 1995 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:2664", label: "2664:Pulkovo 1995 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:2665", label: "2665:Pulkovo 1995 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:2666", label: "2666:Pulkovo 1995 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:2667", label: "2667:Pulkovo 1995 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:2668", label: "2668:Pulkovo 1995 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:2669", label: "2669:Pulkovo 1995 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:2670", label: "2670:Pulkovo 1995 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:2671", label: "2671:Pulkovo 1995 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:2672", label: "2672:Pulkovo 1995 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:2673", label: "2673:Pulkovo 1995 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:2674", label: "2674:Pulkovo 1995 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:2675", label: "2675:Pulkovo 1995 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:2676", label: "2676:Pulkovo 1995 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:2677", label: "2677:Pulkovo 1995 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:2678", label: "2678:Pulkovo 1995 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:2679", label: "2679:Pulkovo 1995 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:2680", label: "2680:Pulkovo 1995 / 3-degree Gauss-Kruger zone 46"},
  {coordinate_system:"EPSG:2681", label: "2681:Pulkovo 1995 / 3-degree Gauss-Kruger zone 47"},
  {coordinate_system:"EPSG:2682", label: "2682:Pulkovo 1995 / 3-degree Gauss-Kruger zone 48"},
  {coordinate_system:"EPSG:2683", label: "2683:Pulkovo 1995 / 3-degree Gauss-Kruger zone 49"},
  {coordinate_system:"EPSG:2684", label: "2684:Pulkovo 1995 / 3-degree Gauss-Kruger zone 50"},
  {coordinate_system:"EPSG:2685", label: "2685:Pulkovo 1995 / 3-degree Gauss-Kruger zone 51"},
  {coordinate_system:"EPSG:2686", label: "2686:Pulkovo 1995 / 3-degree Gauss-Kruger zone 52"},
  {coordinate_system:"EPSG:2687", label: "2687:Pulkovo 1995 / 3-degree Gauss-Kruger zone 53"},
  {coordinate_system:"EPSG:2688", label: "2688:Pulkovo 1995 / 3-degree Gauss-Kruger zone 54"},
  {coordinate_system:"EPSG:2689", label: "2689:Pulkovo 1995 / 3-degree Gauss-Kruger zone 55"},
  {coordinate_system:"EPSG:2690", label: "2690:Pulkovo 1995 / 3-degree Gauss-Kruger zone 56"},
  {coordinate_system:"EPSG:2691", label: "2691:Pulkovo 1995 / 3-degree Gauss-Kruger zone 57"},
  {coordinate_system:"EPSG:2692", label: "2692:Pulkovo 1995 / 3-degree Gauss-Kruger zone 58"},
  {coordinate_system:"EPSG:2693", label: "2693:Pulkovo 1995 / 3-degree Gauss-Kruger zone 59"},
  {coordinate_system:"EPSG:2695", label: "2695:Pulkovo 1995 / 3-degree Gauss-Kruger zone 61"},
  {coordinate_system:"EPSG:2696", label: "2696:Pulkovo 1995 / 3-degree Gauss-Kruger zone 62"},
  {coordinate_system:"EPSG:2697", label: "2697:Pulkovo 1995 / 3-degree Gauss-Kruger zone 63"},
  {coordinate_system:"EPSG:2698", label: "2698:Pulkovo 1995 / 3-degree Gauss-Kruger zone 64"},
  {coordinate_system:"EPSG:2699", label: "2699:Pulkovo 1995 / 3-degree Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:2700", label: "2700:Pulkovo 1995 / 3-degree Gauss-Kruger CM 24E"},
  {coordinate_system:"EPSG:2701", label: "2701:Pulkovo 1995 / 3-degree Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:2702", label: "2702:Pulkovo 1995 / 3-degree Gauss-Kruger CM 30E"},
  {coordinate_system:"EPSG:2703", label: "2703:Pulkovo 1995 / 3-degree Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:2704", label: "2704:Pulkovo 1995 / 3-degree Gauss-Kruger CM 36E"},
  {coordinate_system:"EPSG:2705", label: "2705:Pulkovo 1995 / 3-degree Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:2706", label: "2706:Pulkovo 1995 / 3-degree Gauss-Kruger CM 42E"},
  {coordinate_system:"EPSG:2707", label: "2707:Pulkovo 1995 / 3-degree Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:2708", label: "2708:Pulkovo 1995 / 3-degree Gauss-Kruger CM 48E"},
  {coordinate_system:"EPSG:2709", label: "2709:Pulkovo 1995 / 3-degree Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:2710", label: "2710:Pulkovo 1995 / 3-degree Gauss-Kruger CM 54E"},
  {coordinate_system:"EPSG:2711", label: "2711:Pulkovo 1995 / 3-degree Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:2712", label: "2712:Pulkovo 1995 / 3-degree Gauss-Kruger CM 60E"},
  {coordinate_system:"EPSG:2713", label: "2713:Pulkovo 1995 / 3-degree Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:2714", label: "2714:Pulkovo 1995 / 3-degree Gauss-Kruger CM 66E"},
  {coordinate_system:"EPSG:2715", label: "2715:Pulkovo 1995 / 3-degree Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:2716", label: "2716:Pulkovo 1995 / 3-degree Gauss-Kruger CM 72E"},
  {coordinate_system:"EPSG:2717", label: "2717:Pulkovo 1995 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:2718", label: "2718:Pulkovo 1995 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:2719", label: "2719:Pulkovo 1995 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:2720", label: "2720:Pulkovo 1995 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:2721", label: "2721:Pulkovo 1995 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:2722", label: "2722:Pulkovo 1995 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:2723", label: "2723:Pulkovo 1995 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:2724", label: "2724:Pulkovo 1995 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:2725", label: "2725:Pulkovo 1995 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:2726", label: "2726:Pulkovo 1995 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:2727", label: "2727:Pulkovo 1995 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:2728", label: "2728:Pulkovo 1995 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:2729", label: "2729:Pulkovo 1995 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:2730", label: "2730:Pulkovo 1995 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:2731", label: "2731:Pulkovo 1995 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:2732", label: "2732:Pulkovo 1995 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:2733", label: "2733:Pulkovo 1995 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:2734", label: "2734:Pulkovo 1995 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:2735", label: "2735:Pulkovo 1995 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:2736", label: "2736:Tete / UTM zone 36S"},
  {coordinate_system:"EPSG:2737", label: "2737:Tete / UTM zone 37S"},
  {coordinate_system:"EPSG:2738", label: "2738:Pulkovo 1995 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:2739", label: "2739:Pulkovo 1995 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:2740", label: "2740:Pulkovo 1995 / 3-degree Gauss-Kruger CM 138E"},
  {coordinate_system:"EPSG:2741", label: "2741:Pulkovo 1995 / 3-degree Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:2742", label: "2742:Pulkovo 1995 / 3-degree Gauss-Kruger CM 144E"},
  {coordinate_system:"EPSG:2743", label: "2743:Pulkovo 1995 / 3-degree Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:2744", label: "2744:Pulkovo 1995 / 3-degree Gauss-Kruger CM 150E"},
  {coordinate_system:"EPSG:2745", label: "2745:Pulkovo 1995 / 3-degree Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:2746", label: "2746:Pulkovo 1995 / 3-degree Gauss-Kruger CM 156E"},
  {coordinate_system:"EPSG:2747", label: "2747:Pulkovo 1995 / 3-degree Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:2748", label: "2748:Pulkovo 1995 / 3-degree Gauss-Kruger CM 162E"},
  {coordinate_system:"EPSG:2749", label: "2749:Pulkovo 1995 / 3-degree Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:2750", label: "2750:Pulkovo 1995 / 3-degree Gauss-Kruger CM 168E"},
  {coordinate_system:"EPSG:2751", label: "2751:Pulkovo 1995 / 3-degree Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:2752", label: "2752:Pulkovo 1995 / 3-degree Gauss-Kruger CM 174E"},
  {coordinate_system:"EPSG:2753", label: "2753:Pulkovo 1995 / 3-degree Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:2754", label: "2754:Pulkovo 1995 / 3-degree Gauss-Kruger CM 180E"},
  {coordinate_system:"EPSG:2755", label: "2755:Pulkovo 1995 / 3-degree Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:2756", label: "2756:Pulkovo 1995 / 3-degree Gauss-Kruger CM 174W"},
  {coordinate_system:"EPSG:2757", label: "2757:Pulkovo 1995 / 3-degree Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:2758", label: "2758:Pulkovo 1995 / 3-degree Gauss-Kruger CM 168W"},
  {coordinate_system:"EPSG:2759", label: "2759:NAD83(HARN) / Alabama East"},
  {coordinate_system:"EPSG:2760", label: "2760:NAD83(HARN) / Alabama West"},
  {coordinate_system:"EPSG:2761", label: "2761:NAD83(HARN) / Arizona East"},
  {coordinate_system:"EPSG:2762", label: "2762:NAD83(HARN) / Arizona Central"},
  {coordinate_system:"EPSG:2763", label: "2763:NAD83(HARN) / Arizona West"},
  {coordinate_system:"EPSG:2764", label: "2764:NAD83(HARN) / Arkansas North"},
  {coordinate_system:"EPSG:2765", label: "2765:NAD83(HARN) / Arkansas South"},
  {coordinate_system:"EPSG:2766", label: "2766:NAD83(HARN) / California zone 1"},
  {coordinate_system:"EPSG:2767", label: "2767:NAD83(HARN) / California zone 2"},
  {coordinate_system:"EPSG:2768", label: "2768:NAD83(HARN) / California zone 3"},
  {coordinate_system:"EPSG:2769", label: "2769:NAD83(HARN) / California zone 4"},
  {coordinate_system:"EPSG:2770", label: "2770:NAD83(HARN) / California zone 5"},
  {coordinate_system:"EPSG:2771", label: "2771:NAD83(HARN) / California zone 6"},
  {coordinate_system:"EPSG:2772", label: "2772:NAD83(HARN) / Colorado North"},
  {coordinate_system:"EPSG:2773", label: "2773:NAD83(HARN) / Colorado Central"},
  {coordinate_system:"EPSG:2774", label: "2774:NAD83(HARN) / Colorado South"},
  {coordinate_system:"EPSG:2775", label: "2775:NAD83(HARN) / Connecticut"},
  {coordinate_system:"EPSG:2776", label: "2776:NAD83(HARN) / Delaware"},
  {coordinate_system:"EPSG:2777", label: "2777:NAD83(HARN) / Florida East"},
  {coordinate_system:"EPSG:2778", label: "2778:NAD83(HARN) / Florida West"},
  {coordinate_system:"EPSG:2779", label: "2779:NAD83(HARN) / Florida North"},
  {coordinate_system:"EPSG:2780", label: "2780:NAD83(HARN) / Georgia East"},
  {coordinate_system:"EPSG:2781", label: "2781:NAD83(HARN) / Georgia West"},
  {coordinate_system:"EPSG:2782", label: "2782:NAD83(HARN) / Hawaii zone 1"},
  {coordinate_system:"EPSG:2783", label: "2783:NAD83(HARN) / Hawaii zone 2"},
  {coordinate_system:"EPSG:2784", label: "2784:NAD83(HARN) / Hawaii zone 3"},
  {coordinate_system:"EPSG:2785", label: "2785:NAD83(HARN) / Hawaii zone 4"},
  {coordinate_system:"EPSG:2786", label: "2786:NAD83(HARN) / Hawaii zone 5"},
  {coordinate_system:"EPSG:2787", label: "2787:NAD83(HARN) / Idaho East"},
  {coordinate_system:"EPSG:2788", label: "2788:NAD83(HARN) / Idaho Central"},
  {coordinate_system:"EPSG:2789", label: "2789:NAD83(HARN) / Idaho West"},
  {coordinate_system:"EPSG:2790", label: "2790:NAD83(HARN) / Illinois East"},
  {coordinate_system:"EPSG:2791", label: "2791:NAD83(HARN) / Illinois West"},
  {coordinate_system:"EPSG:2792", label: "2792:NAD83(HARN) / Indiana East"},
  {coordinate_system:"EPSG:2793", label: "2793:NAD83(HARN) / Indiana West"},
  {coordinate_system:"EPSG:2794", label: "2794:NAD83(HARN) / Iowa North"},
  {coordinate_system:"EPSG:2795", label: "2795:NAD83(HARN) / Iowa South"},
  {coordinate_system:"EPSG:2796", label: "2796:NAD83(HARN) / Kansas North"},
  {coordinate_system:"EPSG:2797", label: "2797:NAD83(HARN) / Kansas South"},
  {coordinate_system:"EPSG:2798", label: "2798:NAD83(HARN) / Kentucky North"},
  {coordinate_system:"EPSG:2799", label: "2799:NAD83(HARN) / Kentucky South"},
  {coordinate_system:"EPSG:2800", label: "2800:NAD83(HARN) / Louisiana North"},
  {coordinate_system:"EPSG:2801", label: "2801:NAD83(HARN) / Louisiana South"},
  {coordinate_system:"EPSG:2802", label: "2802:NAD83(HARN) / Maine East"},
  {coordinate_system:"EPSG:2803", label: "2803:NAD83(HARN) / Maine West"},
  {coordinate_system:"EPSG:2804", label: "2804:NAD83(HARN) / Maryland"},
  {coordinate_system:"EPSG:2805", label: "2805:NAD83(HARN) / Massachusetts Mainland"},
  {coordinate_system:"EPSG:2806", label: "2806:NAD83(HARN) / Massachusetts Island"},
  {coordinate_system:"EPSG:2807", label: "2807:NAD83(HARN) / Michigan North"},
  {coordinate_system:"EPSG:2808", label: "2808:NAD83(HARN) / Michigan Central"},
  {coordinate_system:"EPSG:2809", label: "2809:NAD83(HARN) / Michigan South"},
  {coordinate_system:"EPSG:2810", label: "2810:NAD83(HARN) / Minnesota North"},
  {coordinate_system:"EPSG:2811", label: "2811:NAD83(HARN) / Minnesota Central"},
  {coordinate_system:"EPSG:2812", label: "2812:NAD83(HARN) / Minnesota South"},
  {coordinate_system:"EPSG:2813", label: "2813:NAD83(HARN) / Mississippi East"},
  {coordinate_system:"EPSG:2814", label: "2814:NAD83(HARN) / Mississippi West"},
  {coordinate_system:"EPSG:2815", label: "2815:NAD83(HARN) / Missouri East"},
  {coordinate_system:"EPSG:2816", label: "2816:NAD83(HARN) / Missouri Central"},
  {coordinate_system:"EPSG:2817", label: "2817:NAD83(HARN) / Missouri West"},
  {coordinate_system:"EPSG:2818", label: "2818:NAD83(HARN) / Montana"},
  {coordinate_system:"EPSG:2819", label: "2819:NAD83(HARN) / Nebraska"},
  {coordinate_system:"EPSG:2820", label: "2820:NAD83(HARN) / Nevada East"},
  {coordinate_system:"EPSG:2821", label: "2821:NAD83(HARN) / Nevada Central"},
  {coordinate_system:"EPSG:2822", label: "2822:NAD83(HARN) / Nevada West"},
  {coordinate_system:"EPSG:2823", label: "2823:NAD83(HARN) / New Hampshire"},
  {coordinate_system:"EPSG:2824", label: "2824:NAD83(HARN) / New Jersey"},
  {coordinate_system:"EPSG:2825", label: "2825:NAD83(HARN) / New Mexico East"},
  {coordinate_system:"EPSG:2826", label: "2826:NAD83(HARN) / New Mexico Central"},
  {coordinate_system:"EPSG:2827", label: "2827:NAD83(HARN) / New Mexico West"},
  {coordinate_system:"EPSG:2828", label: "2828:NAD83(HARN) / New York East"},
  {coordinate_system:"EPSG:2829", label: "2829:NAD83(HARN) / New York Central"},
  {coordinate_system:"EPSG:2830", label: "2830:NAD83(HARN) / New York West"},
  {coordinate_system:"EPSG:2831", label: "2831:NAD83(HARN) / New York Long Island"},
  {coordinate_system:"EPSG:2832", label: "2832:NAD83(HARN) / North Dakota North"},
  {coordinate_system:"EPSG:2833", label: "2833:NAD83(HARN) / North Dakota South"},
  {coordinate_system:"EPSG:2834", label: "2834:NAD83(HARN) / Ohio North"},
  {coordinate_system:"EPSG:2835", label: "2835:NAD83(HARN) / Ohio South"},
  {coordinate_system:"EPSG:2836", label: "2836:NAD83(HARN) / Oklahoma North"},
  {coordinate_system:"EPSG:2837", label: "2837:NAD83(HARN) / Oklahoma South"},
  {coordinate_system:"EPSG:2838", label: "2838:NAD83(HARN) / Oregon North"},
  {coordinate_system:"EPSG:2839", label: "2839:NAD83(HARN) / Oregon South"},
  {coordinate_system:"EPSG:2840", label: "2840:NAD83(HARN) / Rhode Island"},
  {coordinate_system:"EPSG:2841", label: "2841:NAD83(HARN) / South Dakota North"},
  {coordinate_system:"EPSG:2842", label: "2842:NAD83(HARN) / South Dakota South"},
  {coordinate_system:"EPSG:2843", label: "2843:NAD83(HARN) / Tennessee"},
  {coordinate_system:"EPSG:2844", label: "2844:NAD83(HARN) / Texas North"},
  {coordinate_system:"EPSG:2845", label: "2845:NAD83(HARN) / Texas North Central"},
  {coordinate_system:"EPSG:2846", label: "2846:NAD83(HARN) / Texas Central"},
  {coordinate_system:"EPSG:2847", label: "2847:NAD83(HARN) / Texas South Central"},
  {coordinate_system:"EPSG:2848", label: "2848:NAD83(HARN) / Texas South"},
  {coordinate_system:"EPSG:2849", label: "2849:NAD83(HARN) / Utah North"},
  {coordinate_system:"EPSG:2850", label: "2850:NAD83(HARN) / Utah Central"},
  {coordinate_system:"EPSG:2851", label: "2851:NAD83(HARN) / Utah South"},
  {coordinate_system:"EPSG:2852", label: "2852:NAD83(HARN) / Vermont"},
  {coordinate_system:"EPSG:2853", label: "2853:NAD83(HARN) / Virginia North"},
  {coordinate_system:"EPSG:2854", label: "2854:NAD83(HARN) / Virginia South"},
  {coordinate_system:"EPSG:2855", label: "2855:NAD83(HARN) / Washington North"},
  {coordinate_system:"EPSG:2856", label: "2856:NAD83(HARN) / Washington South"},
  {coordinate_system:"EPSG:2857", label: "2857:NAD83(HARN) / West Virginia North"},
  {coordinate_system:"EPSG:2858", label: "2858:NAD83(HARN) / West Virginia South"},
  {coordinate_system:"EPSG:2859", label: "2859:NAD83(HARN) / Wisconsin North"},
  {coordinate_system:"EPSG:2860", label: "2860:NAD83(HARN) / Wisconsin Central"},
  {coordinate_system:"EPSG:2861", label: "2861:NAD83(HARN) / Wisconsin South"},
  {coordinate_system:"EPSG:2862", label: "2862:NAD83(HARN) / Wyoming East"},
  {coordinate_system:"EPSG:2863", label: "2863:NAD83(HARN) / Wyoming East Central"},
  {coordinate_system:"EPSG:2864", label: "2864:NAD83(HARN) / Wyoming West Central"},
  {coordinate_system:"EPSG:2865", label: "2865:NAD83(HARN) / Wyoming West"},
  {coordinate_system:"EPSG:2866", label: "2866:NAD83(HARN) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:2867", label: "2867:NAD83(HARN) / Arizona East (ft)"},
  {coordinate_system:"EPSG:2868", label: "2868:NAD83(HARN) / Arizona Central (ft)"},
  {coordinate_system:"EPSG:2869", label: "2869:NAD83(HARN) / Arizona West (ft)"},
  {coordinate_system:"EPSG:2870", label: "2870:NAD83(HARN) / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:2871", label: "2871:NAD83(HARN) / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:2872", label: "2872:NAD83(HARN) / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:2873", label: "2873:NAD83(HARN) / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:2874", label: "2874:NAD83(HARN) / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:2875", label: "2875:NAD83(HARN) / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:2876", label: "2876:NAD83(HARN) / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:2877", label: "2877:NAD83(HARN) / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:2878", label: "2878:NAD83(HARN) / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:2879", label: "2879:NAD83(HARN) / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:2880", label: "2880:NAD83(HARN) / Delaware (ftUS)"},
  {coordinate_system:"EPSG:2881", label: "2881:NAD83(HARN) / Florida East (ftUS)"},
  {coordinate_system:"EPSG:2882", label: "2882:NAD83(HARN) / Florida West (ftUS)"},
  {coordinate_system:"EPSG:2883", label: "2883:NAD83(HARN) / Florida North (ftUS)"},
  {coordinate_system:"EPSG:2884", label: "2884:NAD83(HARN) / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:2885", label: "2885:NAD83(HARN) / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:2886", label: "2886:NAD83(HARN) / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:2887", label: "2887:NAD83(HARN) / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:2888", label: "2888:NAD83(HARN) / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:2891", label: "2891:NAD83(HARN) / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:2892", label: "2892:NAD83(HARN) / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:2893", label: "2893:NAD83(HARN) / Maryland (ftUS)"},
  {coordinate_system:"EPSG:2894", label: "2894:NAD83(HARN) / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:2895", label: "2895:NAD83(HARN) / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:2896", label: "2896:NAD83(HARN) / Michigan North (ft)"},
  {coordinate_system:"EPSG:2897", label: "2897:NAD83(HARN) / Michigan Central (ft)"},
  {coordinate_system:"EPSG:2898", label: "2898:NAD83(HARN) / Michigan South (ft)"},
  {coordinate_system:"EPSG:2899", label: "2899:NAD83(HARN) / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:2900", label: "2900:NAD83(HARN) / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:2901", label: "2901:NAD83(HARN) / Montana (ft)"},
  {coordinate_system:"EPSG:2902", label: "2902:NAD83(HARN) / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:2903", label: "2903:NAD83(HARN) / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:2904", label: "2904:NAD83(HARN) / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:2905", label: "2905:NAD83(HARN) / New York East (ftUS)"},
  {coordinate_system:"EPSG:2906", label: "2906:NAD83(HARN) / New York Central (ftUS)"},
  {coordinate_system:"EPSG:2907", label: "2907:NAD83(HARN) / New York West (ftUS)"},
  {coordinate_system:"EPSG:2908", label: "2908:NAD83(HARN) / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:2909", label: "2909:NAD83(HARN) / North Dakota North (ft)"},
  {coordinate_system:"EPSG:2910", label: "2910:NAD83(HARN) / North Dakota South (ft)"},
  {coordinate_system:"EPSG:2911", label: "2911:NAD83(HARN) / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:2912", label: "2912:NAD83(HARN) / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:2913", label: "2913:NAD83(HARN) / Oregon North (ft)"},
  {coordinate_system:"EPSG:2914", label: "2914:NAD83(HARN) / Oregon South (ft)"},
  {coordinate_system:"EPSG:2915", label: "2915:NAD83(HARN) / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:2916", label: "2916:NAD83(HARN) / Texas North (ftUS)"},
  {coordinate_system:"EPSG:2917", label: "2917:NAD83(HARN) / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:2918", label: "2918:NAD83(HARN) / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:2919", label: "2919:NAD83(HARN) / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:2920", label: "2920:NAD83(HARN) / Texas South (ftUS)"},
  {coordinate_system:"EPSG:2921", label: "2921:NAD83(HARN) / Utah North (ft)"},
  {coordinate_system:"EPSG:2922", label: "2922:NAD83(HARN) / Utah Central (ft)"},
  {coordinate_system:"EPSG:2923", label: "2923:NAD83(HARN) / Utah South (ft)"},
  {coordinate_system:"EPSG:2924", label: "2924:NAD83(HARN) / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:2925", label: "2925:NAD83(HARN) / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:2926", label: "2926:NAD83(HARN) / Washington North (ftUS)"},
  {coordinate_system:"EPSG:2927", label: "2927:NAD83(HARN) / Washington South (ftUS)"},
  {coordinate_system:"EPSG:2928", label: "2928:NAD83(HARN) / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:2929", label: "2929:NAD83(HARN) / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:2930", label: "2930:NAD83(HARN) / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:2931", label: "2931:Beduaram / TM 13 NE"},
  {coordinate_system:"EPSG:2932", label: "2932:QND95 / Qatar National Grid"},
  {coordinate_system:"EPSG:2933", label: "2933:Segara / UTM zone 50S"},
  {coordinate_system:"EPSG:2935", label: "2935:Pulkovo 1942 / CS63 zone A1"},
  {coordinate_system:"EPSG:2936", label: "2936:Pulkovo 1942 / CS63 zone A2"},
  {coordinate_system:"EPSG:2937", label: "2937:Pulkovo 1942 / CS63 zone A3"},
  {coordinate_system:"EPSG:2938", label: "2938:Pulkovo 1942 / CS63 zone A4"},
  {coordinate_system:"EPSG:2939", label: "2939:Pulkovo 1942 / CS63 zone K2"},
  {coordinate_system:"EPSG:2940", label: "2940:Pulkovo 1942 / CS63 zone K3"},
  {coordinate_system:"EPSG:2941", label: "2941:Pulkovo 1942 / CS63 zone K4"},
  {coordinate_system:"EPSG:2942", label: "2942:Porto Santo / UTM zone 28N"},
  {coordinate_system:"EPSG:2943", label: "2943:Selvagem Grande / UTM zone 28N"},
  {coordinate_system:"EPSG:2945", label: "2945:NAD83(CSRS) / MTM zone 3"},
  {coordinate_system:"EPSG:2946", label: "2946:NAD83(CSRS) / MTM zone 4"},
  {coordinate_system:"EPSG:2947", label: "2947:NAD83(CSRS) / MTM zone 5"},
  {coordinate_system:"EPSG:2948", label: "2948:NAD83(CSRS) / MTM zone 6"},
  {coordinate_system:"EPSG:2949", label: "2949:NAD83(CSRS) / MTM zone 7"},
  {coordinate_system:"EPSG:2950", label: "2950:NAD83(CSRS) / MTM zone 8"},
  {coordinate_system:"EPSG:2951", label: "2951:NAD83(CSRS) / MTM zone 9"},
  {coordinate_system:"EPSG:2952", label: "2952:NAD83(CSRS) / MTM zone 10"},
  {coordinate_system:"EPSG:2953", label: "2953:NAD83(CSRS) / New Brunswick Stereographic"},
  {coordinate_system:"EPSG:2954", label: "2954:NAD83(CSRS) / Prince Edward Isl. Stereographic (NAD83)"},
  {coordinate_system:"EPSG:2955", label: "2955:NAD83(CSRS) / UTM zone 11N"},
  {coordinate_system:"EPSG:2956", label: "2956:NAD83(CSRS) / UTM zone 12N"},
  {coordinate_system:"EPSG:2957", label: "2957:NAD83(CSRS) / UTM zone 13N"},
  {coordinate_system:"EPSG:2958", label: "2958:NAD83(CSRS) / UTM zone 17N"},
  {coordinate_system:"EPSG:2959", label: "2959:NAD83(CSRS) / UTM zone 18N"},
  {coordinate_system:"EPSG:2960", label: "2960:NAD83(CSRS) / UTM zone 19N"},
  {coordinate_system:"EPSG:2961", label: "2961:NAD83(CSRS) / UTM zone 20N"},
  {coordinate_system:"EPSG:2962", label: "2962:NAD83(CSRS) / UTM zone 21N"},
  {coordinate_system:"EPSG:2963", label: "2963:Lisbon 1890 (Lisbon) / Portugal Bonne"},
  {coordinate_system:"EPSG:2964", label: "2964:NAD27 / Alaska Albers"},
  {coordinate_system:"EPSG:2965", label: "2965:NAD83 / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:2966", label: "2966:NAD83 / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:2967", label: "2967:NAD83(HARN) / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:2968", label: "2968:NAD83(HARN) / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:2969", label: "2969:Fort Marigot / UTM zone 20N"},
  {coordinate_system:"EPSG:2970", label: "2970:Guadeloupe 1948 / UTM zone 20N"},
  {coordinate_system:"EPSG:2971", label: "2971:CSG67 / UTM zone 22N"},
  {coordinate_system:"EPSG:2972", label: "2972:RGFG95 / UTM zone 22N"},
  {coordinate_system:"EPSG:2973", label: "2973:Martinique 1938 / UTM zone 20N"},
  {coordinate_system:"EPSG:2975", label: "2975:RGR92 / UTM zone 40S"},
  {coordinate_system:"EPSG:2976", label: "2976:Tahiti 52 / UTM zone 6S"},
  {coordinate_system:"EPSG:2977", label: "2977:Tahaa 54 / UTM zone 5S"},
  {coordinate_system:"EPSG:2978", label: "2978:IGN72 Nuku Hiva / UTM zone 7S"},
  {coordinate_system:"EPSG:2980", label: "2980:Combani 1950 / UTM zone 38S"},
  {coordinate_system:"EPSG:2981", label: "2981:IGN56 Lifou / UTM zone 58S"},
  {coordinate_system:"EPSG:2985", label: "2985:Petrels 1972 / Terre Adelie Polar Stereographic"},
  {coordinate_system:"EPSG:2986", label: "2986:Perroud 1950 / Terre Adelie Polar Stereographic"},
  {coordinate_system:"EPSG:2987", label: "2987:Saint Pierre et Miquelon 1950 / UTM zone 21N"},
  {coordinate_system:"EPSG:2988", label: "2988:MOP78 / UTM zone 1S"},
  {coordinate_system:"EPSG:2991", label: "2991:NAD83 / Oregon LCC (m)"},
  {coordinate_system:"EPSG:2992", label: "2992:NAD83 / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:2993", label: "2993:NAD83(HARN) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:2994", label: "2994:NAD83(HARN) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:2995", label: "2995:IGN53 Mare / UTM zone 58S"},
  {coordinate_system:"EPSG:2996", label: "2996:ST84 Ile des Pins / UTM zone 58S"},
  {coordinate_system:"EPSG:2997", label: "2997:ST71 Belep / UTM zone 58S"},
  {coordinate_system:"EPSG:2998", label: "2998:NEA74 Noumea / UTM zone 58S"},
  {coordinate_system:"EPSG:2999", label: "2999:Grand Comoros / UTM zone 38S"},
  {coordinate_system:"EPSG:3000", label: "3000:Segara / NEIEZ"},
  {coordinate_system:"EPSG:3001", label: "3001:Batavia / NEIEZ"},
  {coordinate_system:"EPSG:3002", label: "3002:Makassar / NEIEZ"},
  {coordinate_system:"EPSG:3003", label: "3003:Monte Mario / Italy zone 1"},
  {coordinate_system:"EPSG:3004", label: "3004:Monte Mario / Italy zone 2"},
  {coordinate_system:"EPSG:3005", label: "3005:NAD83 / BC Albers"},
  {coordinate_system:"EPSG:3006", label: "3006:SWEREF99 TM"},
  {coordinate_system:"EPSG:3007", label: "3007:SWEREF99 12 00"},
  {coordinate_system:"EPSG:3008", label: "3008:SWEREF99 13 30"},
  {coordinate_system:"EPSG:3009", label: "3009:SWEREF99 15 00"},
  {coordinate_system:"EPSG:3010", label: "3010:SWEREF99 16 30"},
  {coordinate_system:"EPSG:3011", label: "3011:SWEREF99 18 00"},
  {coordinate_system:"EPSG:3012", label: "3012:SWEREF99 14 15"},
  {coordinate_system:"EPSG:3013", label: "3013:SWEREF99 15 45"},
  {coordinate_system:"EPSG:3014", label: "3014:SWEREF99 17 15"},
  {coordinate_system:"EPSG:3015", label: "3015:SWEREF99 18 45"},
  {coordinate_system:"EPSG:3016", label: "3016:SWEREF99 20 15"},
  {coordinate_system:"EPSG:3017", label: "3017:SWEREF99 21 45"},
  {coordinate_system:"EPSG:3018", label: "3018:SWEREF99 23 15"},
  {coordinate_system:"EPSG:3019", label: "3019:RT90 7.5 gon V"},
  {coordinate_system:"EPSG:3020", label: "3020:RT90 5 gon V"},
  {coordinate_system:"EPSG:3021", label: "3021:RT90 2.5 gon V"},
  {coordinate_system:"EPSG:3021", label: "3021:RT90 2.5 gon V"},
  {coordinate_system:"EPSG:3022", label: "3022:RT90 0 gon"},
  {coordinate_system:"EPSG:3023", label: "3023:RT90 2.5 gon O"},
  {coordinate_system:"EPSG:3024", label: "3024:RT90 5 gon O"},
  {coordinate_system:"EPSG:3025", label: "3025:RT38 7.5 gon V"},
  {coordinate_system:"EPSG:3026", label: "3026:RT38 5 gon V"},
  {coordinate_system:"EPSG:3027", label: "3027:RT38 2.5 gon V"},
  {coordinate_system:"EPSG:3027", label: "3027:RT38 2.5 gon V"},
  {coordinate_system:"EPSG:3028", label: "3028:RT38 0 gon"},
  {coordinate_system:"EPSG:3029", label: "3029:RT38 2.5 gon O"},
  {coordinate_system:"EPSG:3030", label: "3030:RT38 5 gon O"},
  {coordinate_system:"EPSG:3031", label: "3031:WGS 84 / Antarctic Polar Stereographic"},
  {coordinate_system:"EPSG:3032", label: "3032:WGS 84 / Australian Antarctic Polar Stereographic"},
  {coordinate_system:"EPSG:3033", label: "3033:WGS 84 / Australian Antarctic Lambert"},
  {coordinate_system:"EPSG:3034", label: "3034:ETRS89-extended / LCC Europe"},
  {coordinate_system:"EPSG:3035", label: "3035:ETRS89-extended / LAEA Europe"},
  {coordinate_system:"EPSG:3036", label: "3036:Moznet / UTM zone 36S"},
  {coordinate_system:"EPSG:3037", label: "3037:Moznet / UTM zone 37S"},
  {coordinate_system:"EPSG:3040", label: "3040:ETRS89 / UTM zone 28N (N-E)"},
  {coordinate_system:"EPSG:3041", label: "3041:ETRS89 / UTM zone 29N (N-E)"},
  {coordinate_system:"EPSG:3042", label: "3042:ETRS89 / UTM zone 30N (N-E)"},
  {coordinate_system:"EPSG:3043", label: "3043:ETRS89 / UTM zone 31N (N-E)"},
  {coordinate_system:"EPSG:3044", label: "3044:ETRS89 / UTM zone 32N (N-E)"},
  {coordinate_system:"EPSG:3045", label: "3045:ETRS89 / UTM zone 33N (N-E)"},
  {coordinate_system:"EPSG:3046", label: "3046:ETRS89 / UTM zone 34N (N-E)"},
  {coordinate_system:"EPSG:3047", label: "3047:ETRS89 / UTM zone 35N (N-E)"},
  {coordinate_system:"EPSG:3048", label: "3048:ETRS89 / UTM zone 36N (N-E)"},
  {coordinate_system:"EPSG:3049", label: "3049:ETRS89 / UTM zone 37N (N-E)"},
  {coordinate_system:"EPSG:3052", label: "3052:Reykjavik 1900 / Lambert 1900"},
  {coordinate_system:"EPSG:3053", label: "3053:Hjorsey 1955 / Lambert 1955"},
  {coordinate_system:"EPSG:3054", label: "3054:Hjorsey 1955 / UTM zone 26N"},
  {coordinate_system:"EPSG:3055", label: "3055:Hjorsey 1955 / UTM zone 27N"},
  {coordinate_system:"EPSG:3056", label: "3056:Hjorsey 1955 / UTM zone 28N"},
  {coordinate_system:"EPSG:3057", label: "3057:ISN93 / Lambert 1993"},
  {coordinate_system:"EPSG:3058", label: "3058:Helle 1954 / Jan Mayen Grid"},
  {coordinate_system:"EPSG:3059", label: "3059:LKS92 / Latvia TM"},
  {coordinate_system:"EPSG:3060", label: "3060:IGN72 Grande Terre / UTM zone 58S"},
  {coordinate_system:"EPSG:3061", label: "3061:Porto Santo 1995 / UTM zone 28N"},
  {coordinate_system:"EPSG:3062", label: "3062:Azores Oriental 1995 / UTM zone 26N"},
  {coordinate_system:"EPSG:3063", label: "3063:Azores Central 1995 / UTM zone 26N"},
  {coordinate_system:"EPSG:3064", label: "3064:IGM95 / UTM zone 32N"},
  {coordinate_system:"EPSG:3065", label: "3065:IGM95 / UTM zone 33N"},
  {coordinate_system:"EPSG:3066", label: "3066:ED50 / Jordan TM"},
  {coordinate_system:"EPSG:3067", label: "3067:ETRS89 / TM35FIN(E,N)"},
  {coordinate_system:"EPSG:3068", label: "3068:DHDN / Soldner Berlin"},
  {coordinate_system:"EPSG:3069", label: "3069:NAD27 / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3070", label: "3070:NAD83 / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3071", label: "3071:NAD83(HARN) / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3072", label: "3072:NAD83 / Maine CS2000 East"},
  {coordinate_system:"EPSG:3074", label: "3074:NAD83 / Maine CS2000 West"},
  {coordinate_system:"EPSG:3075", label: "3075:NAD83(HARN) / Maine CS2000 East"},
  {coordinate_system:"EPSG:3077", label: "3077:NAD83(HARN) / Maine CS2000 West"},
  {coordinate_system:"EPSG:3078", label: "3078:NAD83 / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:3079", label: "3079:NAD83(HARN) / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:3080", label: "3080:NAD27 / Shackleford"},
  {coordinate_system:"EPSG:3081", label: "3081:NAD83 / Texas State Mapping System"},
  {coordinate_system:"EPSG:3082", label: "3082:NAD83 / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:3083", label: "3083:NAD83 / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:3084", label: "3084:NAD83(HARN) / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:3085", label: "3085:NAD83(HARN) / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:3086", label: "3086:NAD83 / Florida GDL Albers"},
  {coordinate_system:"EPSG:3087", label: "3087:NAD83(HARN) / Florida GDL Albers"},
  {coordinate_system:"EPSG:3088", label: "3088:NAD83 / Kentucky Single Zone"},
  {coordinate_system:"EPSG:3089", label: "3089:NAD83 / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:3090", label: "3090:NAD83(HARN) / Kentucky Single Zone"},
  {coordinate_system:"EPSG:3091", label: "3091:NAD83(HARN) / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:3092", label: "3092:Tokyo / UTM zone 51N"},
  {coordinate_system:"EPSG:3093", label: "3093:Tokyo / UTM zone 52N"},
  {coordinate_system:"EPSG:3094", label: "3094:Tokyo / UTM zone 53N"},
  {coordinate_system:"EPSG:3095", label: "3095:Tokyo / UTM zone 54N"},
  {coordinate_system:"EPSG:3096", label: "3096:Tokyo / UTM zone 55N"},
  {coordinate_system:"EPSG:3097", label: "3097:JGD2000 / UTM zone 51N"},
  {coordinate_system:"EPSG:3098", label: "3098:JGD2000 / UTM zone 52N"},
  {coordinate_system:"EPSG:3099", label: "3099:JGD2000 / UTM zone 53N"},
  {coordinate_system:"EPSG:3100", label: "3100:JGD2000 / UTM zone 54N"},
  {coordinate_system:"EPSG:3101", label: "3101:JGD2000 / UTM zone 55N"},
  {coordinate_system:"EPSG:3102", label: "3102:American Samoa 1962 / American Samoa Lambert"},
  {coordinate_system:"EPSG:3106", label: "3106:Gulshan 303 / TM 90 NE"},
  {coordinate_system:"EPSG:3106", label: "3106:Gulshan 303 / TM 90 NE"},
  {coordinate_system:"EPSG:3107", label: "3107:GDA94 / SA Lambert"},
  {coordinate_system:"EPSG:3108", label: "3108:ETRS89 / Guernsey Grid"},
  {coordinate_system:"EPSG:3109", label: "3109:ETRS89 / Jersey Transverse Mercator"},
  {coordinate_system:"EPSG:3110", label: "3110:AGD66 / Vicgrid66"},
  {coordinate_system:"EPSG:3111", label: "3111:GDA94 / Vicgrid"},
  {coordinate_system:"EPSG:3112", label: "3112:GDA94 / Geoscience Australia Lambert"},
  {coordinate_system:"EPSG:3113", label: "3113:GDA94 / BCSG02"},
  {coordinate_system:"EPSG:3114", label: "3114:MAGNA-SIRGAS / Colombia Far West zone"},
  {coordinate_system:"EPSG:3115", label: "3115:MAGNA-SIRGAS / Colombia West zone"},
  {coordinate_system:"EPSG:3116", label: "3116:MAGNA-SIRGAS / Colombia Bogota zone"},
  {coordinate_system:"EPSG:3116", label: "3116:MAGNA-SIRGAS / Colombia Bogota zone"},
  {coordinate_system:"EPSG:3117", label: "3117:MAGNA-SIRGAS / Colombia East Central zone"},
  {coordinate_system:"EPSG:3118", label: "3118:MAGNA-SIRGAS / Colombia East zone"},
  {coordinate_system:"EPSG:3119", label: "3119:Douala 1948 / AEF west"},
  {coordinate_system:"EPSG:3120", label: "3120:Pulkovo 1942(58) / Poland zone I"},
  {coordinate_system:"EPSG:3121", label: "3121:PRS92 / Philippines zone 1"},
  {coordinate_system:"EPSG:3122", label: "3122:PRS92 / Philippines zone 2"},
  {coordinate_system:"EPSG:3123", label: "3123:PRS92 / Philippines zone 3"},
  {coordinate_system:"EPSG:3124", label: "3124:PRS92 / Philippines zone 4"},
  {coordinate_system:"EPSG:3125", label: "3125:PRS92 / Philippines zone 5"},
  {coordinate_system:"EPSG:3126", label: "3126:ETRS89 / ETRS-GK19FIN"},
  {coordinate_system:"EPSG:3127", label: "3127:ETRS89 / ETRS-GK20FIN"},
  {coordinate_system:"EPSG:3128", label: "3128:ETRS89 / ETRS-GK21FIN"},
  {coordinate_system:"EPSG:3129", label: "3129:ETRS89 / ETRS-GK22FIN"},
  {coordinate_system:"EPSG:3130", label: "3130:ETRS89 / ETRS-GK23FIN"},
  {coordinate_system:"EPSG:3131", label: "3131:ETRS89 / ETRS-GK24FIN"},
  {coordinate_system:"EPSG:3132", label: "3132:ETRS89 / ETRS-GK25FIN"},
  {coordinate_system:"EPSG:3133", label: "3133:ETRS89 / ETRS-GK26FIN"},
  {coordinate_system:"EPSG:3134", label: "3134:ETRS89 / ETRS-GK27FIN"},
  {coordinate_system:"EPSG:3135", label: "3135:ETRS89 / ETRS-GK28FIN"},
  {coordinate_system:"EPSG:3136", label: "3136:ETRS89 / ETRS-GK29FIN"},
  {coordinate_system:"EPSG:3137", label: "3137:ETRS89 / ETRS-GK30FIN"},
  {coordinate_system:"EPSG:3138", label: "3138:ETRS89 / ETRS-GK31FIN"},
  {coordinate_system:"EPSG:3139", label: "3139:Vanua Levu 1915 / Vanua Levu Grid"},
  {coordinate_system:"EPSG:3140", label: "3140:Viti Levu 1912 / Viti Levu Grid"},
  {coordinate_system:"EPSG:3141", label: "3141:Fiji 1956 / UTM zone 60S"},
  {coordinate_system:"EPSG:3142", label: "3142:Fiji 1956 / UTM zone 1S"},
  {coordinate_system:"EPSG:3144", label: "3144:FD54 / Faroe Lambert"},
  {coordinate_system:"EPSG:3145", label: "3145:ETRS89 / Faroe Lambert"},
  {coordinate_system:"EPSG:3148", label: "3148:Indian 1960 / UTM zone 48N"},
  {coordinate_system:"EPSG:3149", label: "3149:Indian 1960 / UTM zone 49N"},
  {coordinate_system:"EPSG:3152", label: "3152:ST74"},
  {coordinate_system:"EPSG:3153", label: "3153:NAD83(CSRS) / BC Albers"},
  {coordinate_system:"EPSG:3154", label: "3154:NAD83(CSRS) / UTM zone 7N"},
  {coordinate_system:"EPSG:3155", label: "3155:NAD83(CSRS) / UTM zone 8N"},
  {coordinate_system:"EPSG:3156", label: "3156:NAD83(CSRS) / UTM zone 9N"},
  {coordinate_system:"EPSG:3157", label: "3157:NAD83(CSRS) / UTM zone 10N"},
  {coordinate_system:"EPSG:3158", label: "3158:NAD83(CSRS) / UTM zone 14N"},
  {coordinate_system:"EPSG:3159", label: "3159:NAD83(CSRS) / UTM zone 15N"},
  {coordinate_system:"EPSG:3160", label: "3160:NAD83(CSRS) / UTM zone 16N"},
  {coordinate_system:"EPSG:3161", label: "3161:NAD83 / Ontario MNR Lambert"},
  {coordinate_system:"EPSG:3162", label: "3162:NAD83(CSRS) / Ontario MNR Lambert"},
  {coordinate_system:"EPSG:3163", label: "3163:RGNC91-93 / Lambert New Caledonia"},
  {coordinate_system:"EPSG:3164", label: "3164:ST87 Ouvea / UTM zone 58S"},
  {coordinate_system:"EPSG:3165", label: "3165:NEA74 Noumea / Noumea Lambert"},
  {coordinate_system:"EPSG:3166", label: "3166:NEA74 Noumea / Noumea Lambert 2"},
  {coordinate_system:"EPSG:3167", label: "3167:Kertau (RSO) / RSO Malaya (ch)"},
  {coordinate_system:"EPSG:3168", label: "3168:Kertau (RSO) / RSO Malaya (m)"},
  {coordinate_system:"EPSG:3169", label: "3169:RGNC91-93 / UTM zone 57S"},
  {coordinate_system:"EPSG:3170", label: "3170:RGNC91-93 / UTM zone 58S"},
  {coordinate_system:"EPSG:3171", label: "3171:RGNC91-93 / UTM zone 59S"},
  {coordinate_system:"EPSG:3172", label: "3172:IGN53 Mare / UTM zone 59S"},
  {coordinate_system:"EPSG:3173", label: "3173:fk89 / Faroe Lambert FK89"},
  {coordinate_system:"EPSG:3174", label: "3174:NAD83 / Great Lakes Albers"},
  {coordinate_system:"EPSG:3175", label: "3175:NAD83 / Great Lakes and St Lawrence Albers"},
  {coordinate_system:"EPSG:3176", label: "3176:Indian 1960 / TM 106 NE"},
  {coordinate_system:"EPSG:3177", label: "3177:LGD2006 / Libya TM"},
  {coordinate_system:"EPSG:3178", label: "3178:GR96 / UTM zone 18N"},
  {coordinate_system:"EPSG:3179", label: "3179:GR96 / UTM zone 19N"},
  {coordinate_system:"EPSG:3180", label: "3180:GR96 / UTM zone 20N"},
  {coordinate_system:"EPSG:3181", label: "3181:GR96 / UTM zone 21N"},
  {coordinate_system:"EPSG:3182", label: "3182:GR96 / UTM zone 22N"},
  {coordinate_system:"EPSG:3183", label: "3183:GR96 / UTM zone 23N"},
  {coordinate_system:"EPSG:3184", label: "3184:GR96 / UTM zone 24N"},
  {coordinate_system:"EPSG:3185", label: "3185:GR96 / UTM zone 25N"},
  {coordinate_system:"EPSG:3186", label: "3186:GR96 / UTM zone 26N"},
  {coordinate_system:"EPSG:3187", label: "3187:GR96 / UTM zone 27N"},
  {coordinate_system:"EPSG:3188", label: "3188:GR96 / UTM zone 28N"},
  {coordinate_system:"EPSG:3189", label: "3189:GR96 / UTM zone 29N"},
  {coordinate_system:"EPSG:3190", label: "3190:LGD2006 / Libya TM zone 5"},
  {coordinate_system:"EPSG:3191", label: "3191:LGD2006 / Libya TM zone 6"},
  {coordinate_system:"EPSG:3192", label: "3192:LGD2006 / Libya TM zone 7"},
  {coordinate_system:"EPSG:3193", label: "3193:LGD2006 / Libya TM zone 8"},
  {coordinate_system:"EPSG:3194", label: "3194:LGD2006 / Libya TM zone 9"},
  {coordinate_system:"EPSG:3195", label: "3195:LGD2006 / Libya TM zone 10"},
  {coordinate_system:"EPSG:3196", label: "3196:LGD2006 / Libya TM zone 11"},
  {coordinate_system:"EPSG:3197", label: "3197:LGD2006 / Libya TM zone 12"},
  {coordinate_system:"EPSG:3198", label: "3198:LGD2006 / Libya TM zone 13"},
  {coordinate_system:"EPSG:3199", label: "3199:LGD2006 / UTM zone 32N"},
  {coordinate_system:"EPSG:3200", label: "3200:FD58 / Iraq zone"},
  {coordinate_system:"EPSG:3201", label: "3201:LGD2006 / UTM zone 33N"},
  {coordinate_system:"EPSG:3202", label: "3202:LGD2006 / UTM zone 34N"},
  {coordinate_system:"EPSG:3203", label: "3203:LGD2006 / UTM zone 35N"},
  {coordinate_system:"EPSG:3204", label: "3204:WGS 84 / SCAR IMW SP19-20"},
  {coordinate_system:"EPSG:3205", label: "3205:WGS 84 / SCAR IMW SP21-22"},
  {coordinate_system:"EPSG:3206", label: "3206:WGS 84 / SCAR IMW SP23-24"},
  {coordinate_system:"EPSG:3207", label: "3207:WGS 84 / SCAR IMW SQ01-02"},
  {coordinate_system:"EPSG:3208", label: "3208:WGS 84 / SCAR IMW SQ19-20"},
  {coordinate_system:"EPSG:3209", label: "3209:WGS 84 / SCAR IMW SQ21-22"},
  {coordinate_system:"EPSG:3210", label: "3210:WGS 84 / SCAR IMW SQ37-38"},
  {coordinate_system:"EPSG:3211", label: "3211:WGS 84 / SCAR IMW SQ39-40"},
  {coordinate_system:"EPSG:3212", label: "3212:WGS 84 / SCAR IMW SQ41-42"},
  {coordinate_system:"EPSG:3213", label: "3213:WGS 84 / SCAR IMW SQ43-44"},
  {coordinate_system:"EPSG:3214", label: "3214:WGS 84 / SCAR IMW SQ45-46"},
  {coordinate_system:"EPSG:3215", label: "3215:WGS 84 / SCAR IMW SQ47-48"},
  {coordinate_system:"EPSG:3216", label: "3216:WGS 84 / SCAR IMW SQ49-50"},
  {coordinate_system:"EPSG:3217", label: "3217:WGS 84 / SCAR IMW SQ51-52"},
  {coordinate_system:"EPSG:3218", label: "3218:WGS 84 / SCAR IMW SQ53-54"},
  {coordinate_system:"EPSG:3219", label: "3219:WGS 84 / SCAR IMW SQ55-56"},
  {coordinate_system:"EPSG:3220", label: "3220:WGS 84 / SCAR IMW SQ57-58"},
  {coordinate_system:"EPSG:3221", label: "3221:WGS 84 / SCAR IMW SR13-14"},
  {coordinate_system:"EPSG:3222", label: "3222:WGS 84 / SCAR IMW SR15-16"},
  {coordinate_system:"EPSG:3223", label: "3223:WGS 84 / SCAR IMW SR17-18"},
  {coordinate_system:"EPSG:3224", label: "3224:WGS 84 / SCAR IMW SR19-20"},
  {coordinate_system:"EPSG:3225", label: "3225:WGS 84 / SCAR IMW SR27-28"},
  {coordinate_system:"EPSG:3226", label: "3226:WGS 84 / SCAR IMW SR29-30"},
  {coordinate_system:"EPSG:3227", label: "3227:WGS 84 / SCAR IMW SR31-32"},
  {coordinate_system:"EPSG:3228", label: "3228:WGS 84 / SCAR IMW SR33-34"},
  {coordinate_system:"EPSG:3229", label: "3229:WGS 84 / SCAR IMW SR35-36"},
  {coordinate_system:"EPSG:3230", label: "3230:WGS 84 / SCAR IMW SR37-38"},
  {coordinate_system:"EPSG:3231", label: "3231:WGS 84 / SCAR IMW SR39-40"},
  {coordinate_system:"EPSG:3232", label: "3232:WGS 84 / SCAR IMW SR41-42"},
  {coordinate_system:"EPSG:3233", label: "3233:WGS 84 / SCAR IMW SR43-44"},
  {coordinate_system:"EPSG:3234", label: "3234:WGS 84 / SCAR IMW SR45-46"},
  {coordinate_system:"EPSG:3235", label: "3235:WGS 84 / SCAR IMW SR47-48"},
  {coordinate_system:"EPSG:3236", label: "3236:WGS 84 / SCAR IMW SR49-50"},
  {coordinate_system:"EPSG:3237", label: "3237:WGS 84 / SCAR IMW SR51-52"},
  {coordinate_system:"EPSG:3238", label: "3238:WGS 84 / SCAR IMW SR53-54"},
  {coordinate_system:"EPSG:3239", label: "3239:WGS 84 / SCAR IMW SR55-56"},
  {coordinate_system:"EPSG:3240", label: "3240:WGS 84 / SCAR IMW SR57-58"},
  {coordinate_system:"EPSG:3241", label: "3241:WGS 84 / SCAR IMW SR59-60"},
  {coordinate_system:"EPSG:3242", label: "3242:WGS 84 / SCAR IMW SS04-06"},
  {coordinate_system:"EPSG:3243", label: "3243:WGS 84 / SCAR IMW SS07-09"},
  {coordinate_system:"EPSG:3244", label: "3244:WGS 84 / SCAR IMW SS10-12"},
  {coordinate_system:"EPSG:3245", label: "3245:WGS 84 / SCAR IMW SS13-15"},
  {coordinate_system:"EPSG:3246", label: "3246:WGS 84 / SCAR IMW SS16-18"},
  {coordinate_system:"EPSG:3247", label: "3247:WGS 84 / SCAR IMW SS19-21"},
  {coordinate_system:"EPSG:3248", label: "3248:WGS 84 / SCAR IMW SS25-27"},
  {coordinate_system:"EPSG:3249", label: "3249:WGS 84 / SCAR IMW SS28-30"},
  {coordinate_system:"EPSG:3250", label: "3250:WGS 84 / SCAR IMW SS31-33"},
  {coordinate_system:"EPSG:3251", label: "3251:WGS 84 / SCAR IMW SS34-36"},
  {coordinate_system:"EPSG:3252", label: "3252:WGS 84 / SCAR IMW SS37-39"},
  {coordinate_system:"EPSG:3253", label: "3253:WGS 84 / SCAR IMW SS40-42"},
  {coordinate_system:"EPSG:3254", label: "3254:WGS 84 / SCAR IMW SS43-45"},
  {coordinate_system:"EPSG:3255", label: "3255:WGS 84 / SCAR IMW SS46-48"},
  {coordinate_system:"EPSG:3256", label: "3256:WGS 84 / SCAR IMW SS49-51"},
  {coordinate_system:"EPSG:3257", label: "3257:WGS 84 / SCAR IMW SS52-54"},
  {coordinate_system:"EPSG:3258", label: "3258:WGS 84 / SCAR IMW SS55-57"},
  {coordinate_system:"EPSG:3259", label: "3259:WGS 84 / SCAR IMW SS58-60"},
  {coordinate_system:"EPSG:3260", label: "3260:WGS 84 / SCAR IMW ST01-04"},
  {coordinate_system:"EPSG:3261", label: "3261:WGS 84 / SCAR IMW ST05-08"},
  {coordinate_system:"EPSG:3262", label: "3262:WGS 84 / SCAR IMW ST09-12"},
  {coordinate_system:"EPSG:3263", label: "3263:WGS 84 / SCAR IMW ST13-16"},
  {coordinate_system:"EPSG:3264", label: "3264:WGS 84 / SCAR IMW ST17-20"},
  {coordinate_system:"EPSG:3265", label: "3265:WGS 84 / SCAR IMW ST21-24"},
  {coordinate_system:"EPSG:3266", label: "3266:WGS 84 / SCAR IMW ST25-28"},
  {coordinate_system:"EPSG:3267", label: "3267:WGS 84 / SCAR IMW ST29-32"},
  {coordinate_system:"EPSG:3268", label: "3268:WGS 84 / SCAR IMW ST33-36"},
  {coordinate_system:"EPSG:3269", label: "3269:WGS 84 / SCAR IMW ST37-40"},
  {coordinate_system:"EPSG:3270", label: "3270:WGS 84 / SCAR IMW ST41-44"},
  {coordinate_system:"EPSG:3271", label: "3271:WGS 84 / SCAR IMW ST45-48"},
  {coordinate_system:"EPSG:3272", label: "3272:WGS 84 / SCAR IMW ST49-52"},
  {coordinate_system:"EPSG:3273", label: "3273:WGS 84 / SCAR IMW ST53-56"},
  {coordinate_system:"EPSG:3274", label: "3274:WGS 84 / SCAR IMW ST57-60"},
  {coordinate_system:"EPSG:3275", label: "3275:WGS 84 / SCAR IMW SU01-05"},
  {coordinate_system:"EPSG:3276", label: "3276:WGS 84 / SCAR IMW SU06-10"},
  {coordinate_system:"EPSG:3277", label: "3277:WGS 84 / SCAR IMW SU11-15"},
  {coordinate_system:"EPSG:3278", label: "3278:WGS 84 / SCAR IMW SU16-20"},
  {coordinate_system:"EPSG:3279", label: "3279:WGS 84 / SCAR IMW SU21-25"},
  {coordinate_system:"EPSG:3280", label: "3280:WGS 84 / SCAR IMW SU26-30"},
  {coordinate_system:"EPSG:3281", label: "3281:WGS 84 / SCAR IMW SU31-35"},
  {coordinate_system:"EPSG:3282", label: "3282:WGS 84 / SCAR IMW SU36-40"},
  {coordinate_system:"EPSG:3283", label: "3283:WGS 84 / SCAR IMW SU41-45"},
  {coordinate_system:"EPSG:3284", label: "3284:WGS 84 / SCAR IMW SU46-50"},
  {coordinate_system:"EPSG:3285", label: "3285:WGS 84 / SCAR IMW SU51-55"},
  {coordinate_system:"EPSG:3286", label: "3286:WGS 84 / SCAR IMW SU56-60"},
  {coordinate_system:"EPSG:3287", label: "3287:WGS 84 / SCAR IMW SV01-10"},
  {coordinate_system:"EPSG:3288", label: "3288:WGS 84 / SCAR IMW SV11-20"},
  {coordinate_system:"EPSG:3289", label: "3289:WGS 84 / SCAR IMW SV21-30"},
  {coordinate_system:"EPSG:3290", label: "3290:WGS 84 / SCAR IMW SV31-40"},
  {coordinate_system:"EPSG:3291", label: "3291:WGS 84 / SCAR IMW SV41-50"},
  {coordinate_system:"EPSG:3292", label: "3292:WGS 84 / SCAR IMW SV51-60"},
  {coordinate_system:"EPSG:3293", label: "3293:WGS 84 / SCAR IMW SW01-60"},
  {coordinate_system:"EPSG:3294", label: "3294:WGS 84 / USGS Transantarctic Mountains"},
  {coordinate_system:"EPSG:3295", label: "3295:Guam 1963 / Yap Islands"},
  {coordinate_system:"EPSG:3296", label: "3296:RGPF / UTM zone 5S"},
  {coordinate_system:"EPSG:3297", label: "3297:RGPF / UTM zone 6S"},
  {coordinate_system:"EPSG:3298", label: "3298:RGPF / UTM zone 7S"},
  {coordinate_system:"EPSG:3299", label: "3299:RGPF / UTM zone 8S"},
  {coordinate_system:"EPSG:3300", label: "3300:Estonian Coordinate System of 1992"},
  {coordinate_system:"EPSG:3301", label: "3301:Estonian Coordinate System of 1997"},
  {coordinate_system:"EPSG:3302", label: "3302:IGN63 Hiva Oa / UTM zone 7S"},
  {coordinate_system:"EPSG:3303", label: "3303:Fatu Iva 72 / UTM zone 7S"},
  {coordinate_system:"EPSG:3304", label: "3304:Tahiti 79 / UTM zone 6S"},
  {coordinate_system:"EPSG:3305", label: "3305:Moorea 87 / UTM zone 6S"},
  {coordinate_system:"EPSG:3306", label: "3306:Maupiti 83 / UTM zone 5S"},
  {coordinate_system:"EPSG:3307", label: "3307:Nakhl-e Ghanem / UTM zone 39N"},
  {coordinate_system:"EPSG:3308", label: "3308:GDA94 / NSW Lambert"},
  {coordinate_system:"EPSG:3309", label: "3309:NAD27 / California Albers"},
  {coordinate_system:"EPSG:3310", label: "3310:NAD83 / California Albers"},
  {coordinate_system:"EPSG:3311", label: "3311:NAD83(HARN) / California Albers"},
  {coordinate_system:"EPSG:3312", label: "3312:CSG67 / UTM zone 21N"},
  {coordinate_system:"EPSG:3313", label: "3313:RGFG95 / UTM zone 21N"},
  {coordinate_system:"EPSG:3316", label: "3316:Kasai 1953 / Congo TM zone 22"},
  {coordinate_system:"EPSG:3317", label: "3317:Kasai 1953 / Congo TM zone 24"},
  {coordinate_system:"EPSG:3318", label: "3318:IGC 1962 / Congo TM zone 12"},
  {coordinate_system:"EPSG:3319", label: "3319:IGC 1962 / Congo TM zone 14"},
  {coordinate_system:"EPSG:3320", label: "3320:IGC 1962 / Congo TM zone 16"},
  {coordinate_system:"EPSG:3321", label: "3321:IGC 1962 / Congo TM zone 18"},
  {coordinate_system:"EPSG:3322", label: "3322:IGC 1962 / Congo TM zone 20"},
  {coordinate_system:"EPSG:3323", label: "3323:IGC 1962 / Congo TM zone 22"},
  {coordinate_system:"EPSG:3324", label: "3324:IGC 1962 / Congo TM zone 24"},
  {coordinate_system:"EPSG:3325", label: "3325:IGC 1962 / Congo TM zone 26"},
  {coordinate_system:"EPSG:3326", label: "3326:IGC 1962 / Congo TM zone 28"},
  {coordinate_system:"EPSG:3327", label: "3327:IGC 1962 / Congo TM zone 30"},
  {coordinate_system:"EPSG:3328", label: "3328:Pulkovo 1942(58) / GUGiK-80"},
  {coordinate_system:"EPSG:3329", label: "3329:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:3330", label: "3330:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:3331", label: "3331:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:3332", label: "3332:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:3333", label: "3333:Pulkovo 1942(58) / Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3334", label: "3334:Pulkovo 1942(58) / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3335", label: "3335:Pulkovo 1942(58) / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:3336", label: "3336:IGN 1962 Kerguelen / UTM zone 42S"},
  {coordinate_system:"EPSG:3337", label: "3337:Le Pouce 1934 / Mauritius Grid"},
  {coordinate_system:"EPSG:3338", label: "3338:NAD83 / Alaska Albers"},
  {coordinate_system:"EPSG:3339", label: "3339:IGCB 1955 / Congo TM zone 12"},
  {coordinate_system:"EPSG:3340", label: "3340:IGCB 1955 / Congo TM zone 14"},
  {coordinate_system:"EPSG:3341", label: "3341:IGCB 1955 / Congo TM zone 16"},
  {coordinate_system:"EPSG:3342", label: "3342:IGCB 1955 / UTM zone 33S"},
  {coordinate_system:"EPSG:3343", label: "3343:Mauritania 1999 / UTM zone 28N"},
  {coordinate_system:"EPSG:3344", label: "3344:Mauritania 1999 / UTM zone 29N"},
  {coordinate_system:"EPSG:3345", label: "3345:Mauritania 1999 / UTM zone 30N"},
  {coordinate_system:"EPSG:3346", label: "3346:LKS94 / Lithuania TM"},
  {coordinate_system:"EPSG:3347", label: "3347:NAD83 / Statistics Canada Lambert"},
  {coordinate_system:"EPSG:3348", label: "3348:NAD83(CSRS) / Statistics Canada Lambert"},
  {coordinate_system:"EPSG:3350", label: "3350:Pulkovo 1942 / CS63 zone C0"},
  {coordinate_system:"EPSG:3351", label: "3351:Pulkovo 1942 / CS63 zone C1"},
  {coordinate_system:"EPSG:3352", label: "3352:Pulkovo 1942 / CS63 zone C2"},
  {coordinate_system:"EPSG:3353", label: "3353:Mhast (onshore) / UTM zone 32S"},
  {coordinate_system:"EPSG:3354", label: "3354:Mhast (offshore) / UTM zone 32S"},
  {coordinate_system:"EPSG:3355", label: "3355:Egypt Gulf of Suez S-650 TL / Red Belt"},
  {coordinate_system:"EPSG:3358", label: "3358:NAD83(HARN) / North Carolina"},
  {coordinate_system:"EPSG:3360", label: "3360:NAD83(HARN) / South Carolina"},
  {coordinate_system:"EPSG:3361", label: "3361:NAD83(HARN) / South Carolina (ft)"},
  {coordinate_system:"EPSG:3362", label: "3362:NAD83(HARN) / Pennsylvania North"},
  {coordinate_system:"EPSG:3363", label: "3363:NAD83(HARN) / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:3364", label: "3364:NAD83(HARN) / Pennsylvania South"},
  {coordinate_system:"EPSG:3365", label: "3365:NAD83(HARN) / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:3367", label: "3367:IGN Astro 1960 / UTM zone 28N"},
  {coordinate_system:"EPSG:3368", label: "3368:IGN Astro 1960 / UTM zone 29N"},
  {coordinate_system:"EPSG:3369", label: "3369:IGN Astro 1960 / UTM zone 30N"},
  {coordinate_system:"EPSG:3370", label: "3370:NAD27 / UTM zone 59N"},
  {coordinate_system:"EPSG:3371", label: "3371:NAD27 / UTM zone 60N"},
  {coordinate_system:"EPSG:3372", label: "3372:NAD83 / UTM zone 59N"},
  {coordinate_system:"EPSG:3373", label: "3373:NAD83 / UTM zone 60N"},
  {coordinate_system:"EPSG:3374", label: "3374:FD54 / UTM zone 29N"},
  {coordinate_system:"EPSG:3375", label: "3375:GDM2000 / Peninsula RSO"},
  {coordinate_system:"EPSG:3376", label: "3376:GDM2000 / East Malaysia BRSO"},
  {coordinate_system:"EPSG:3377", label: "3377:GDM2000 / Johor Grid"},
  {coordinate_system:"EPSG:3378", label: "3378:GDM2000 / Sembilan and Melaka Grid"},
  {coordinate_system:"EPSG:3379", label: "3379:GDM2000 / Pahang Grid"},
  {coordinate_system:"EPSG:3380", label: "3380:GDM2000 / Selangor Grid"},
  {coordinate_system:"EPSG:3381", label: "3381:GDM2000 / Terengganu Grid"},
  {coordinate_system:"EPSG:3382", label: "3382:GDM2000 / Pinang Grid"},
  {coordinate_system:"EPSG:3383", label: "3383:GDM2000 / Kedah and Perlis Grid"},
  {coordinate_system:"EPSG:3384", label: "3384:GDM2000 / Perak Grid"},
  {coordinate_system:"EPSG:3385", label: "3385:GDM2000 / Kelantan Grid"},
  {coordinate_system:"EPSG:3386", label: "3386:KKJ / Finland zone 0"},
  {coordinate_system:"EPSG:3387", label: "3387:KKJ / Finland zone 5"},
  {coordinate_system:"EPSG:3388", label: "3388:Pulkovo 1942 / Caspian Sea Mercator"},
  {coordinate_system:"EPSG:3389", label: "3389:Pulkovo 1942 / 3-degree Gauss-Kruger zone 60"},
  {coordinate_system:"EPSG:3390", label: "3390:Pulkovo 1995 / 3-degree Gauss-Kruger zone 60"},
  {coordinate_system:"EPSG:3391", label: "3391:Karbala 1979 / UTM zone 37N"},
  {coordinate_system:"EPSG:3392", label: "3392:Karbala 1979 / UTM zone 38N"},
  {coordinate_system:"EPSG:3393", label: "3393:Karbala 1979 / UTM zone 39N"},
  {coordinate_system:"EPSG:3394", label: "3394:Nahrwan 1934 / Iraq zone"},
  {coordinate_system:"EPSG:3395", label: "3395:WGS 84 / World Mercator"},
  {coordinate_system:"EPSG:3396", label: "3396:PD/83 / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3397", label: "3397:PD/83 / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3398", label: "3398:RD/83 / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3399", label: "3399:RD/83 / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:3400", label: "3400:NAD83 / Alberta 10-TM (Forest)"},
  {coordinate_system:"EPSG:3401", label: "3401:NAD83 / Alberta 10-TM (Resource)"},
  {coordinate_system:"EPSG:3402", label: "3402:NAD83(CSRS) / Alberta 10-TM (Forest)"},
  {coordinate_system:"EPSG:3403", label: "3403:NAD83(CSRS) / Alberta 10-TM (Resource)"},
  {coordinate_system:"EPSG:3404", label: "3404:NAD83(HARN) / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:3405", label: "3405:VN-2000 / UTM zone 48N"},
  {coordinate_system:"EPSG:3406", label: "3406:VN-2000 / UTM zone 49N"},
  {coordinate_system:"EPSG:3407", label: "3407:Hong Kong 1963 Grid System"},
  {coordinate_system:"EPSG:3413", label: "3413:WGS 84 / NSIDC Sea Ice Polar Stereographic North"},
  {coordinate_system:"EPSG:3414", label: "3414:SVY21 / Singapore TM"},
  {coordinate_system:"EPSG:3415", label: "3415:WGS 72BE / South China Sea Lambert"},
  {coordinate_system:"EPSG:3416", label: "3416:ETRS89 / Austria Lambert"},
  {coordinate_system:"EPSG:3417", label: "3417:NAD83 / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:3418", label: "3418:NAD83 / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:3419", label: "3419:NAD83 / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:3420", label: "3420:NAD83 / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:3421", label: "3421:NAD83 / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:3422", label: "3422:NAD83 / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:3423", label: "3423:NAD83 / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:3424", label: "3424:NAD83 / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:3425", label: "3425:NAD83(HARN) / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:3426", label: "3426:NAD83(HARN) / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:3427", label: "3427:NAD83(HARN) / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:3428", label: "3428:NAD83(HARN) / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:3429", label: "3429:NAD83(HARN) / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:3430", label: "3430:NAD83(HARN) / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:3431", label: "3431:NAD83(HARN) / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:3432", label: "3432:NAD83(HARN) / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:3433", label: "3433:NAD83 / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:3434", label: "3434:NAD83 / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:3435", label: "3435:NAD83 / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:3436", label: "3436:NAD83 / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:3437", label: "3437:NAD83 / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:3438", label: "3438:NAD83 / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:3439", label: "3439:PSD93 / UTM zone 39N"},
  {coordinate_system:"EPSG:3440", label: "3440:PSD93 / UTM zone 40N"},
  {coordinate_system:"EPSG:3441", label: "3441:NAD83(HARN) / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:3442", label: "3442:NAD83(HARN) / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:3443", label: "3443:NAD83(HARN) / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:3444", label: "3444:NAD83(HARN) / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:3445", label: "3445:NAD83(HARN) / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:3446", label: "3446:NAD83(HARN) / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:3447", label: "3447:ETRS89 / Belgian Lambert 2005"},
  {coordinate_system:"EPSG:3448", label: "3448:JAD2001 / Jamaica Metric Grid"},
  {coordinate_system:"EPSG:3449", label: "3449:JAD2001 / UTM zone 17N"},
  {coordinate_system:"EPSG:3450", label: "3450:JAD2001 / UTM zone 18N"},
  {coordinate_system:"EPSG:3451", label: "3451:NAD83 / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:3452", label: "3452:NAD83 / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:3453", label: "3453:NAD83 / Louisiana Offshore (ftUS)"},
  {coordinate_system:"EPSG:3455", label: "3455:NAD83 / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:3456", label: "3456:NAD83(HARN) / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:3457", label: "3457:NAD83(HARN) / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:3458", label: "3458:NAD83(HARN) / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:3459", label: "3459:NAD83(HARN) / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:3460", label: "3460:Fiji 1986 / Fiji Map Grid"},
  {coordinate_system:"EPSG:3461", label: "3461:Dabola 1981 / UTM zone 28N"},
  {coordinate_system:"EPSG:3462", label: "3462:Dabola 1981 / UTM zone 29N"},
  {coordinate_system:"EPSG:3463", label: "3463:NAD83 / Maine CS2000 Central"},
  {coordinate_system:"EPSG:3464", label: "3464:NAD83(HARN) / Maine CS2000 Central"},
  {coordinate_system:"EPSG:3465", label: "3465:NAD83(NSRS2007) / Alabama East"},
  {coordinate_system:"EPSG:3466", label: "3466:NAD83(NSRS2007) / Alabama West"},
  {coordinate_system:"EPSG:3467", label: "3467:NAD83(NSRS2007) / Alaska Albers"},
  {coordinate_system:"EPSG:3468", label: "3468:NAD83(NSRS2007) / Alaska zone 1"},
  {coordinate_system:"EPSG:3469", label: "3469:NAD83(NSRS2007) / Alaska zone 2"},
  {coordinate_system:"EPSG:3470", label: "3470:NAD83(NSRS2007) / Alaska zone 3"},
  {coordinate_system:"EPSG:3471", label: "3471:NAD83(NSRS2007) / Alaska zone 4"},
  {coordinate_system:"EPSG:3472", label: "3472:NAD83(NSRS2007) / Alaska zone 5"},
  {coordinate_system:"EPSG:3473", label: "3473:NAD83(NSRS2007) / Alaska zone 6"},
  {coordinate_system:"EPSG:3474", label: "3474:NAD83(NSRS2007) / Alaska zone 7"},
  {coordinate_system:"EPSG:3475", label: "3475:NAD83(NSRS2007) / Alaska zone 8"},
  {coordinate_system:"EPSG:3476", label: "3476:NAD83(NSRS2007) / Alaska zone 9"},
  {coordinate_system:"EPSG:3477", label: "3477:NAD83(NSRS2007) / Alaska zone 10"},
  {coordinate_system:"EPSG:3478", label: "3478:NAD83(NSRS2007) / Arizona Central"},
  {coordinate_system:"EPSG:3479", label: "3479:NAD83(NSRS2007) / Arizona Central (ft)"},
  {coordinate_system:"EPSG:3480", label: "3480:NAD83(NSRS2007) / Arizona East"},
  {coordinate_system:"EPSG:3481", label: "3481:NAD83(NSRS2007) / Arizona East (ft)"},
  {coordinate_system:"EPSG:3482", label: "3482:NAD83(NSRS2007) / Arizona West"},
  {coordinate_system:"EPSG:3483", label: "3483:NAD83(NSRS2007) / Arizona West (ft)"},
  {coordinate_system:"EPSG:3484", label: "3484:NAD83(NSRS2007) / Arkansas North"},
  {coordinate_system:"EPSG:3485", label: "3485:NAD83(NSRS2007) / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:3486", label: "3486:NAD83(NSRS2007) / Arkansas South"},
  {coordinate_system:"EPSG:3487", label: "3487:NAD83(NSRS2007) / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:3488", label: "3488:NAD83(NSRS2007) / California Albers"},
  {coordinate_system:"EPSG:3489", label: "3489:NAD83(NSRS2007) / California zone 1"},
  {coordinate_system:"EPSG:3490", label: "3490:NAD83(NSRS2007) / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:3491", label: "3491:NAD83(NSRS2007) / California zone 2"},
  {coordinate_system:"EPSG:3492", label: "3492:NAD83(NSRS2007) / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:3493", label: "3493:NAD83(NSRS2007) / California zone 3"},
  {coordinate_system:"EPSG:3494", label: "3494:NAD83(NSRS2007) / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:3495", label: "3495:NAD83(NSRS2007) / California zone 4"},
  {coordinate_system:"EPSG:3496", label: "3496:NAD83(NSRS2007) / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:3497", label: "3497:NAD83(NSRS2007) / California zone 5"},
  {coordinate_system:"EPSG:3498", label: "3498:NAD83(NSRS2007) / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:3499", label: "3499:NAD83(NSRS2007) / California zone 6"},
  {coordinate_system:"EPSG:3500", label: "3500:NAD83(NSRS2007) / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:3501", label: "3501:NAD83(NSRS2007) / Colorado Central"},
  {coordinate_system:"EPSG:3502", label: "3502:NAD83(NSRS2007) / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:3503", label: "3503:NAD83(NSRS2007) / Colorado North"},
  {coordinate_system:"EPSG:3504", label: "3504:NAD83(NSRS2007) / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:3505", label: "3505:NAD83(NSRS2007) / Colorado South"},
  {coordinate_system:"EPSG:3506", label: "3506:NAD83(NSRS2007) / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:3507", label: "3507:NAD83(NSRS2007) / Connecticut"},
  {coordinate_system:"EPSG:3508", label: "3508:NAD83(NSRS2007) / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:3509", label: "3509:NAD83(NSRS2007) / Delaware"},
  {coordinate_system:"EPSG:3510", label: "3510:NAD83(NSRS2007) / Delaware (ftUS)"},
  {coordinate_system:"EPSG:3511", label: "3511:NAD83(NSRS2007) / Florida East"},
  {coordinate_system:"EPSG:3512", label: "3512:NAD83(NSRS2007) / Florida East (ftUS)"},
  {coordinate_system:"EPSG:3513", label: "3513:NAD83(NSRS2007) / Florida GDL Albers"},
  {coordinate_system:"EPSG:3514", label: "3514:NAD83(NSRS2007) / Florida North"},
  {coordinate_system:"EPSG:3515", label: "3515:NAD83(NSRS2007) / Florida North (ftUS)"},
  {coordinate_system:"EPSG:3516", label: "3516:NAD83(NSRS2007) / Florida West"},
  {coordinate_system:"EPSG:3517", label: "3517:NAD83(NSRS2007) / Florida West (ftUS)"},
  {coordinate_system:"EPSG:3518", label: "3518:NAD83(NSRS2007) / Georgia East"},
  {coordinate_system:"EPSG:3519", label: "3519:NAD83(NSRS2007) / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:3520", label: "3520:NAD83(NSRS2007) / Georgia West"},
  {coordinate_system:"EPSG:3521", label: "3521:NAD83(NSRS2007) / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:3522", label: "3522:NAD83(NSRS2007) / Idaho Central"},
  {coordinate_system:"EPSG:3523", label: "3523:NAD83(NSRS2007) / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:3524", label: "3524:NAD83(NSRS2007) / Idaho East"},
  {coordinate_system:"EPSG:3525", label: "3525:NAD83(NSRS2007) / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:3526", label: "3526:NAD83(NSRS2007) / Idaho West"},
  {coordinate_system:"EPSG:3527", label: "3527:NAD83(NSRS2007) / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:3528", label: "3528:NAD83(NSRS2007) / Illinois East"},
  {coordinate_system:"EPSG:3529", label: "3529:NAD83(NSRS2007) / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:3530", label: "3530:NAD83(NSRS2007) / Illinois West"},
  {coordinate_system:"EPSG:3531", label: "3531:NAD83(NSRS2007) / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:3532", label: "3532:NAD83(NSRS2007) / Indiana East"},
  {coordinate_system:"EPSG:3533", label: "3533:NAD83(NSRS2007) / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:3534", label: "3534:NAD83(NSRS2007) / Indiana West"},
  {coordinate_system:"EPSG:3535", label: "3535:NAD83(NSRS2007) / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:3536", label: "3536:NAD83(NSRS2007) / Iowa North"},
  {coordinate_system:"EPSG:3537", label: "3537:NAD83(NSRS2007) / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:3538", label: "3538:NAD83(NSRS2007) / Iowa South"},
  {coordinate_system:"EPSG:3539", label: "3539:NAD83(NSRS2007) / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:3540", label: "3540:NAD83(NSRS2007) / Kansas North"},
  {coordinate_system:"EPSG:3541", label: "3541:NAD83(NSRS2007) / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:3542", label: "3542:NAD83(NSRS2007) / Kansas South"},
  {coordinate_system:"EPSG:3543", label: "3543:NAD83(NSRS2007) / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:3544", label: "3544:NAD83(NSRS2007) / Kentucky North"},
  {coordinate_system:"EPSG:3545", label: "3545:NAD83(NSRS2007) / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:3546", label: "3546:NAD83(NSRS2007) / Kentucky Single Zone"},
  {coordinate_system:"EPSG:3547", label: "3547:NAD83(NSRS2007) / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:3548", label: "3548:NAD83(NSRS2007) / Kentucky South"},
  {coordinate_system:"EPSG:3549", label: "3549:NAD83(NSRS2007) / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:3550", label: "3550:NAD83(NSRS2007) / Louisiana North"},
  {coordinate_system:"EPSG:3551", label: "3551:NAD83(NSRS2007) / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:3552", label: "3552:NAD83(NSRS2007) / Louisiana South"},
  {coordinate_system:"EPSG:3553", label: "3553:NAD83(NSRS2007) / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:3554", label: "3554:NAD83(NSRS2007) / Maine CS2000 Central"},
  {coordinate_system:"EPSG:3555", label: "3555:NAD83(NSRS2007) / Maine CS2000 East"},
  {coordinate_system:"EPSG:3556", label: "3556:NAD83(NSRS2007) / Maine CS2000 West"},
  {coordinate_system:"EPSG:3557", label: "3557:NAD83(NSRS2007) / Maine East"},
  {coordinate_system:"EPSG:3558", label: "3558:NAD83(NSRS2007) / Maine West"},
  {coordinate_system:"EPSG:3559", label: "3559:NAD83(NSRS2007) / Maryland"},
  {coordinate_system:"EPSG:3560", label: "3560:NAD83 / Utah North (ftUS)"},
  {coordinate_system:"EPSG:3561", label: "3561:Old Hawaiian / Hawaii zone 1"},
  {coordinate_system:"EPSG:3562", label: "3562:Old Hawaiian / Hawaii zone 2"},
  {coordinate_system:"EPSG:3563", label: "3563:Old Hawaiian / Hawaii zone 3"},
  {coordinate_system:"EPSG:3564", label: "3564:Old Hawaiian / Hawaii zone 4"},
  {coordinate_system:"EPSG:3565", label: "3565:Old Hawaiian / Hawaii zone 5"},
  {coordinate_system:"EPSG:3566", label: "3566:NAD83 / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:3567", label: "3567:NAD83 / Utah South (ftUS)"},
  {coordinate_system:"EPSG:3568", label: "3568:NAD83(HARN) / Utah North (ftUS)"},
  {coordinate_system:"EPSG:3569", label: "3569:NAD83(HARN) / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:3570", label: "3570:NAD83(HARN) / Utah South (ftUS)"},
  {coordinate_system:"EPSG:3571", label: "3571:WGS 84 / North Pole LAEA Bering Sea"},
  {coordinate_system:"EPSG:3572", label: "3572:WGS 84 / North Pole LAEA Alaska"},
  {coordinate_system:"EPSG:3573", label: "3573:WGS 84 / North Pole LAEA Canada"},
  {coordinate_system:"EPSG:3574", label: "3574:WGS 84 / North Pole LAEA Atlantic"},
  {coordinate_system:"EPSG:3575", label: "3575:WGS 84 / North Pole LAEA Europe"},
  {coordinate_system:"EPSG:3576", label: "3576:WGS 84 / North Pole LAEA Russia"},
  {coordinate_system:"EPSG:3577", label: "3577:GDA94 / Australian Albers"},
  {coordinate_system:"EPSG:3578", label: "3578:NAD83 / Yukon Albers"},
  {coordinate_system:"EPSG:3579", label: "3579:NAD83(CSRS) / Yukon Albers"},
  {coordinate_system:"EPSG:3580", label: "3580:NAD83 / NWT Lambert"},
  {coordinate_system:"EPSG:3581", label: "3581:NAD83(CSRS) / NWT Lambert"},
  {coordinate_system:"EPSG:3582", label: "3582:NAD83(NSRS2007) / Maryland (ftUS)"},
  {coordinate_system:"EPSG:3583", label: "3583:NAD83(NSRS2007) / Massachusetts Island"},
  {coordinate_system:"EPSG:3584", label: "3584:NAD83(NSRS2007) / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:3585", label: "3585:NAD83(NSRS2007) / Massachusetts Mainland"},
  {coordinate_system:"EPSG:3586", label: "3586:NAD83(NSRS2007) / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:3587", label: "3587:NAD83(NSRS2007) / Michigan Central"},
  {coordinate_system:"EPSG:3588", label: "3588:NAD83(NSRS2007) / Michigan Central (ft)"},
  {coordinate_system:"EPSG:3589", label: "3589:NAD83(NSRS2007) / Michigan North"},
  {coordinate_system:"EPSG:3590", label: "3590:NAD83(NSRS2007) / Michigan North (ft)"},
  {coordinate_system:"EPSG:3591", label: "3591:NAD83(NSRS2007) / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:3592", label: "3592:NAD83(NSRS2007) / Michigan South"},
  {coordinate_system:"EPSG:3593", label: "3593:NAD83(NSRS2007) / Michigan South (ft)"},
  {coordinate_system:"EPSG:3594", label: "3594:NAD83(NSRS2007) / Minnesota Central"},
  {coordinate_system:"EPSG:3595", label: "3595:NAD83(NSRS2007) / Minnesota North"},
  {coordinate_system:"EPSG:3596", label: "3596:NAD83(NSRS2007) / Minnesota South"},
  {coordinate_system:"EPSG:3597", label: "3597:NAD83(NSRS2007) / Mississippi East"},
  {coordinate_system:"EPSG:3598", label: "3598:NAD83(NSRS2007) / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:3599", label: "3599:NAD83(NSRS2007) / Mississippi West"},
  {coordinate_system:"EPSG:3600", label: "3600:NAD83(NSRS2007) / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:3601", label: "3601:NAD83(NSRS2007) / Missouri Central"},
  {coordinate_system:"EPSG:3602", label: "3602:NAD83(NSRS2007) / Missouri East"},
  {coordinate_system:"EPSG:3603", label: "3603:NAD83(NSRS2007) / Missouri West"},
  {coordinate_system:"EPSG:3604", label: "3604:NAD83(NSRS2007) / Montana"},
  {coordinate_system:"EPSG:3605", label: "3605:NAD83(NSRS2007) / Montana (ft)"},
  {coordinate_system:"EPSG:3606", label: "3606:NAD83(NSRS2007) / Nebraska"},
  {coordinate_system:"EPSG:3607", label: "3607:NAD83(NSRS2007) / Nevada Central"},
  {coordinate_system:"EPSG:3608", label: "3608:NAD83(NSRS2007) / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:3609", label: "3609:NAD83(NSRS2007) / Nevada East"},
  {coordinate_system:"EPSG:3610", label: "3610:NAD83(NSRS2007) / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:3611", label: "3611:NAD83(NSRS2007) / Nevada West"},
  {coordinate_system:"EPSG:3612", label: "3612:NAD83(NSRS2007) / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:3613", label: "3613:NAD83(NSRS2007) / New Hampshire"},
  {coordinate_system:"EPSG:3614", label: "3614:NAD83(NSRS2007) / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:3615", label: "3615:NAD83(NSRS2007) / New Jersey"},
  {coordinate_system:"EPSG:3616", label: "3616:NAD83(NSRS2007) / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:3617", label: "3617:NAD83(NSRS2007) / New Mexico Central"},
  {coordinate_system:"EPSG:3618", label: "3618:NAD83(NSRS2007) / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:3619", label: "3619:NAD83(NSRS2007) / New Mexico East"},
  {coordinate_system:"EPSG:3620", label: "3620:NAD83(NSRS2007) / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:3621", label: "3621:NAD83(NSRS2007) / New Mexico West"},
  {coordinate_system:"EPSG:3622", label: "3622:NAD83(NSRS2007) / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:3623", label: "3623:NAD83(NSRS2007) / New York Central"},
  {coordinate_system:"EPSG:3624", label: "3624:NAD83(NSRS2007) / New York Central (ftUS)"},
  {coordinate_system:"EPSG:3625", label: "3625:NAD83(NSRS2007) / New York East"},
  {coordinate_system:"EPSG:3626", label: "3626:NAD83(NSRS2007) / New York East (ftUS)"},
  {coordinate_system:"EPSG:3627", label: "3627:NAD83(NSRS2007) / New York Long Island"},
  {coordinate_system:"EPSG:3628", label: "3628:NAD83(NSRS2007) / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:3629", label: "3629:NAD83(NSRS2007) / New York West"},
  {coordinate_system:"EPSG:3630", label: "3630:NAD83(NSRS2007) / New York West (ftUS)"},
  {coordinate_system:"EPSG:3631", label: "3631:NAD83(NSRS2007) / North Carolina"},
  {coordinate_system:"EPSG:3632", label: "3632:NAD83(NSRS2007) / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:3633", label: "3633:NAD83(NSRS2007) / North Dakota North"},
  {coordinate_system:"EPSG:3634", label: "3634:NAD83(NSRS2007) / North Dakota North (ft)"},
  {coordinate_system:"EPSG:3635", label: "3635:NAD83(NSRS2007) / North Dakota South"},
  {coordinate_system:"EPSG:3636", label: "3636:NAD83(NSRS2007) / North Dakota South (ft)"},
  {coordinate_system:"EPSG:3637", label: "3637:NAD83(NSRS2007) / Ohio North"},
  {coordinate_system:"EPSG:3638", label: "3638:NAD83(NSRS2007) / Ohio South"},
  {coordinate_system:"EPSG:3639", label: "3639:NAD83(NSRS2007) / Oklahoma North"},
  {coordinate_system:"EPSG:3640", label: "3640:NAD83(NSRS2007) / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:3641", label: "3641:NAD83(NSRS2007) / Oklahoma South"},
  {coordinate_system:"EPSG:3642", label: "3642:NAD83(NSRS2007) / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:3643", label: "3643:NAD83(NSRS2007) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:3644", label: "3644:NAD83(NSRS2007) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:3645", label: "3645:NAD83(NSRS2007) / Oregon North"},
  {coordinate_system:"EPSG:3646", label: "3646:NAD83(NSRS2007) / Oregon North (ft)"},
  {coordinate_system:"EPSG:3647", label: "3647:NAD83(NSRS2007) / Oregon South"},
  {coordinate_system:"EPSG:3648", label: "3648:NAD83(NSRS2007) / Oregon South (ft)"},
  {coordinate_system:"EPSG:3649", label: "3649:NAD83(NSRS2007) / Pennsylvania North"},
  {coordinate_system:"EPSG:3650", label: "3650:NAD83(NSRS2007) / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:3651", label: "3651:NAD83(NSRS2007) / Pennsylvania South"},
  {coordinate_system:"EPSG:3652", label: "3652:NAD83(NSRS2007) / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:3653", label: "3653:NAD83(NSRS2007) / Rhode Island"},
  {coordinate_system:"EPSG:3654", label: "3654:NAD83(NSRS2007) / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:3655", label: "3655:NAD83(NSRS2007) / South Carolina"},
  {coordinate_system:"EPSG:3656", label: "3656:NAD83(NSRS2007) / South Carolina (ft)"},
  {coordinate_system:"EPSG:3657", label: "3657:NAD83(NSRS2007) / South Dakota North"},
  {coordinate_system:"EPSG:3658", label: "3658:NAD83(NSRS2007) / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:3659", label: "3659:NAD83(NSRS2007) / South Dakota South"},
  {coordinate_system:"EPSG:3660", label: "3660:NAD83(NSRS2007) / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:3661", label: "3661:NAD83(NSRS2007) / Tennessee"},
  {coordinate_system:"EPSG:3662", label: "3662:NAD83(NSRS2007) / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:3663", label: "3663:NAD83(NSRS2007) / Texas Central"},
  {coordinate_system:"EPSG:3664", label: "3664:NAD83(NSRS2007) / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:3665", label: "3665:NAD83(NSRS2007) / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:3666", label: "3666:NAD83(NSRS2007) / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:3667", label: "3667:NAD83(NSRS2007) / Texas North"},
  {coordinate_system:"EPSG:3668", label: "3668:NAD83(NSRS2007) / Texas North (ftUS)"},
  {coordinate_system:"EPSG:3669", label: "3669:NAD83(NSRS2007) / Texas North Central"},
  {coordinate_system:"EPSG:3670", label: "3670:NAD83(NSRS2007) / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:3671", label: "3671:NAD83(NSRS2007) / Texas South"},
  {coordinate_system:"EPSG:3672", label: "3672:NAD83(NSRS2007) / Texas South (ftUS)"},
  {coordinate_system:"EPSG:3673", label: "3673:NAD83(NSRS2007) / Texas South Central"},
  {coordinate_system:"EPSG:3674", label: "3674:NAD83(NSRS2007) / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:3675", label: "3675:NAD83(NSRS2007) / Utah Central"},
  {coordinate_system:"EPSG:3676", label: "3676:NAD83(NSRS2007) / Utah Central (ft)"},
  {coordinate_system:"EPSG:3677", label: "3677:NAD83(NSRS2007) / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:3678", label: "3678:NAD83(NSRS2007) / Utah North"},
  {coordinate_system:"EPSG:3679", label: "3679:NAD83(NSRS2007) / Utah North (ft)"},
  {coordinate_system:"EPSG:3680", label: "3680:NAD83(NSRS2007) / Utah North (ftUS)"},
  {coordinate_system:"EPSG:3681", label: "3681:NAD83(NSRS2007) / Utah South"},
  {coordinate_system:"EPSG:3682", label: "3682:NAD83(NSRS2007) / Utah South (ft)"},
  {coordinate_system:"EPSG:3683", label: "3683:NAD83(NSRS2007) / Utah South (ftUS)"},
  {coordinate_system:"EPSG:3684", label: "3684:NAD83(NSRS2007) / Vermont"},
  {coordinate_system:"EPSG:3685", label: "3685:NAD83(NSRS2007) / Virginia North"},
  {coordinate_system:"EPSG:3686", label: "3686:NAD83(NSRS2007) / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:3687", label: "3687:NAD83(NSRS2007) / Virginia South"},
  {coordinate_system:"EPSG:3688", label: "3688:NAD83(NSRS2007) / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:3689", label: "3689:NAD83(NSRS2007) / Washington North"},
  {coordinate_system:"EPSG:3690", label: "3690:NAD83(NSRS2007) / Washington North (ftUS)"},
  {coordinate_system:"EPSG:3691", label: "3691:NAD83(NSRS2007) / Washington South"},
  {coordinate_system:"EPSG:3692", label: "3692:NAD83(NSRS2007) / Washington South (ftUS)"},
  {coordinate_system:"EPSG:3693", label: "3693:NAD83(NSRS2007) / West Virginia North"},
  {coordinate_system:"EPSG:3694", label: "3694:NAD83(NSRS2007) / West Virginia South"},
  {coordinate_system:"EPSG:3695", label: "3695:NAD83(NSRS2007) / Wisconsin Central"},
  {coordinate_system:"EPSG:3696", label: "3696:NAD83(NSRS2007) / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:3697", label: "3697:NAD83(NSRS2007) / Wisconsin North"},
  {coordinate_system:"EPSG:3698", label: "3698:NAD83(NSRS2007) / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:3699", label: "3699:NAD83(NSRS2007) / Wisconsin South"},
  {coordinate_system:"EPSG:3700", label: "3700:NAD83(NSRS2007) / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:3701", label: "3701:NAD83(NSRS2007) / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:3702", label: "3702:NAD83(NSRS2007) / Wyoming East"},
  {coordinate_system:"EPSG:3703", label: "3703:NAD83(NSRS2007) / Wyoming East Central"},
  {coordinate_system:"EPSG:3704", label: "3704:NAD83(NSRS2007) / Wyoming West Central"},
  {coordinate_system:"EPSG:3705", label: "3705:NAD83(NSRS2007) / Wyoming West"},
  {coordinate_system:"EPSG:3706", label: "3706:NAD83(NSRS2007) / UTM zone 59N"},
  {coordinate_system:"EPSG:3707", label: "3707:NAD83(NSRS2007) / UTM zone 60N"},
  {coordinate_system:"EPSG:3708", label: "3708:NAD83(NSRS2007) / UTM zone 1N"},
  {coordinate_system:"EPSG:3709", label: "3709:NAD83(NSRS2007) / UTM zone 2N"},
  {coordinate_system:"EPSG:3710", label: "3710:NAD83(NSRS2007) / UTM zone 3N"},
  {coordinate_system:"EPSG:3711", label: "3711:NAD83(NSRS2007) / UTM zone 4N"},
  {coordinate_system:"EPSG:3712", label: "3712:NAD83(NSRS2007) / UTM zone 5N"},
  {coordinate_system:"EPSG:3713", label: "3713:NAD83(NSRS2007) / UTM zone 6N"},
  {coordinate_system:"EPSG:3714", label: "3714:NAD83(NSRS2007) / UTM zone 7N"},
  {coordinate_system:"EPSG:3715", label: "3715:NAD83(NSRS2007) / UTM zone 8N"},
  {coordinate_system:"EPSG:3716", label: "3716:NAD83(NSRS2007) / UTM zone 9N"},
  {coordinate_system:"EPSG:3717", label: "3717:NAD83(NSRS2007) / UTM zone 10N"},
  {coordinate_system:"EPSG:3718", label: "3718:NAD83(NSRS2007) / UTM zone 11N"},
  {coordinate_system:"EPSG:3719", label: "3719:NAD83(NSRS2007) / UTM zone 12N"},
  {coordinate_system:"EPSG:3720", label: "3720:NAD83(NSRS2007) / UTM zone 13N"},
  {coordinate_system:"EPSG:3721", label: "3721:NAD83(NSRS2007) / UTM zone 14N"},
  {coordinate_system:"EPSG:3722", label: "3722:NAD83(NSRS2007) / UTM zone 15N"},
  {coordinate_system:"EPSG:3723", label: "3723:NAD83(NSRS2007) / UTM zone 16N"},
  {coordinate_system:"EPSG:3724", label: "3724:NAD83(NSRS2007) / UTM zone 17N"},
  {coordinate_system:"EPSG:3725", label: "3725:NAD83(NSRS2007) / UTM zone 18N"},
  {coordinate_system:"EPSG:3726", label: "3726:NAD83(NSRS2007) / UTM zone 19N"},
  {coordinate_system:"EPSG:3727", label: "3727:Reunion 1947 / TM Reunion"},
  {coordinate_system:"EPSG:3728", label: "3728:NAD83(NSRS2007) / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:3729", label: "3729:NAD83(NSRS2007) / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:3730", label: "3730:NAD83(NSRS2007) / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:3731", label: "3731:NAD83(NSRS2007) / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:3732", label: "3732:NAD83(NSRS2007) / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:3733", label: "3733:NAD83(NSRS2007) / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:3734", label: "3734:NAD83 / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:3735", label: "3735:NAD83 / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:3736", label: "3736:NAD83 / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:3737", label: "3737:NAD83 / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:3738", label: "3738:NAD83 / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:3739", label: "3739:NAD83 / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:3740", label: "3740:NAD83(HARN) / UTM zone 10N"},
  {coordinate_system:"EPSG:3741", label: "3741:NAD83(HARN) / UTM zone 11N"},
  {coordinate_system:"EPSG:3742", label: "3742:NAD83(HARN) / UTM zone 12N"},
  {coordinate_system:"EPSG:3743", label: "3743:NAD83(HARN) / UTM zone 13N"},
  {coordinate_system:"EPSG:3744", label: "3744:NAD83(HARN) / UTM zone 14N"},
  {coordinate_system:"EPSG:3745", label: "3745:NAD83(HARN) / UTM zone 15N"},
  {coordinate_system:"EPSG:3746", label: "3746:NAD83(HARN) / UTM zone 16N"},
  {coordinate_system:"EPSG:3747", label: "3747:NAD83(HARN) / UTM zone 17N"},
  {coordinate_system:"EPSG:3748", label: "3748:NAD83(HARN) / UTM zone 18N"},
  {coordinate_system:"EPSG:3749", label: "3749:NAD83(HARN) / UTM zone 19N"},
  {coordinate_system:"EPSG:3750", label: "3750:NAD83(HARN) / UTM zone 4N"},
  {coordinate_system:"EPSG:3751", label: "3751:NAD83(HARN) / UTM zone 5N"},
  {coordinate_system:"EPSG:3753", label: "3753:NAD83(HARN) / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:3754", label: "3754:NAD83(HARN) / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:3755", label: "3755:NAD83(HARN) / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:3756", label: "3756:NAD83(HARN) / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:3757", label: "3757:NAD83(HARN) / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:3758", label: "3758:NAD83(HARN) / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:3759", label: "3759:NAD83 / Hawaii zone 3 (ftUS)"},
  {coordinate_system:"EPSG:3760", label: "3760:NAD83(HARN) / Hawaii zone 3 (ftUS)"},
  {coordinate_system:"EPSG:3761", label: "3761:NAD83(CSRS) / UTM zone 22N"},
  {coordinate_system:"EPSG:3762", label: "3762:WGS 84 / South Georgia Lambert"},
  {coordinate_system:"EPSG:3763", label: "3763:ETRS89 / Portugal TM06"},
  {coordinate_system:"EPSG:3764", label: "3764:NZGD2000 / Chatham Island Circuit 2000"},
  {coordinate_system:"EPSG:3765", label: "3765:HTRS96 / Croatia TM"},
  {coordinate_system:"EPSG:3766", label: "3766:HTRS96 / Croatia LCC"},
  {coordinate_system:"EPSG:3767", label: "3767:HTRS96 / UTM zone 33N"},
  {coordinate_system:"EPSG:3768", label: "3768:HTRS96 / UTM zone 34N"},
  {coordinate_system:"EPSG:3769", label: "3769:Bermuda 1957 / UTM zone 20N"},
  {coordinate_system:"EPSG:3770", label: "3770:BDA2000 / Bermuda 2000 National Grid"},
  {coordinate_system:"EPSG:3771", label: "3771:NAD27 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:3772", label: "3772:NAD27 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:3773", label: "3773:NAD27 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:3775", label: "3775:NAD83 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:3776", label: "3776:NAD83 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:3777", label: "3777:NAD83 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:3779", label: "3779:NAD83(CSRS) / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:3780", label: "3780:NAD83(CSRS) / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:3781", label: "3781:NAD83(CSRS) / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:3783", label: "3783:Pitcairn 2006 / Pitcairn TM 2006"},
  {coordinate_system:"EPSG:3784", label: "3784:Pitcairn 1967 / UTM zone 9S"},
  {coordinate_system:"EPSG:3788", label: "3788:NZGD2000 / Auckland Islands TM 2000"},
  {coordinate_system:"EPSG:3789", label: "3789:NZGD2000 / Campbell Island TM 2000"},
  {coordinate_system:"EPSG:3790", label: "3790:NZGD2000 / Antipodes Islands TM 2000"},
  {coordinate_system:"EPSG:3791", label: "3791:NZGD2000 / Raoul Island TM 2000"},
  {coordinate_system:"EPSG:3793", label: "3793:NZGD2000 / Chatham Islands TM 2000"},
  {coordinate_system:"EPSG:3794", label: "3794:Slovenia 1996 / Slovene National Grid"},
  {coordinate_system:"EPSG:3795", label: "3795:NAD27 / Cuba Norte"},
  {coordinate_system:"EPSG:3796", label: "3796:NAD27 / Cuba Sur"},
  {coordinate_system:"EPSG:3797", label: "3797:NAD27 / MTQ Lambert"},
  {coordinate_system:"EPSG:3798", label: "3798:NAD83 / MTQ Lambert"},
  {coordinate_system:"EPSG:3799", label: "3799:NAD83(CSRS) / MTQ Lambert"},
  {coordinate_system:"EPSG:3800", label: "3800:NAD27 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:3801", label: "3801:NAD83 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:3802", label: "3802:NAD83(CSRS) / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:3812", label: "3812:ETRS89 / Belgian Lambert 2008"},
  {coordinate_system:"EPSG:3814", label: "3814:NAD83 / Mississippi TM"},
  {coordinate_system:"EPSG:3815", label: "3815:NAD83(HARN) / Mississippi TM"},
  {coordinate_system:"EPSG:3816", label: "3816:NAD83(NSRS2007) / Mississippi TM"},
  {coordinate_system:"EPSG:3825", label: "3825:TWD97 / TM2 zone 119"},
  {coordinate_system:"EPSG:3826", label: "3826:TWD97 / TM2 zone 121"},
  {coordinate_system:"EPSG:3827", label: "3827:TWD67 / TM2 zone 119"},
  {coordinate_system:"EPSG:3828", label: "3828:TWD67 / TM2 zone 121"},
  {coordinate_system:"EPSG:3829", label: "3829:Hu Tzu Shan 1950 / UTM zone 51N"},
  {coordinate_system:"EPSG:3832", label: "3832:WGS 84 / PDC Mercator"},
  {coordinate_system:"EPSG:3833", label: "3833:Pulkovo 1942(58) / Gauss-Kruger zone 2"},
  {coordinate_system:"EPSG:3834", label: "3834:Pulkovo 1942(83) / Gauss-Kruger zone 2"},
  {coordinate_system:"EPSG:3835", label: "3835:Pulkovo 1942(83) / Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3836", label: "3836:Pulkovo 1942(83) / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3837", label: "3837:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:3838", label: "3838:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:3839", label: "3839:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:3840", label: "3840:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:3841", label: "3841:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:3844", label: "3844:Pulkovo 1942(58) / Stereo70"},
  {coordinate_system:"EPSG:3845", label: "3845:SWEREF99 / RT90 7.5 gon V emulation"},
  {coordinate_system:"EPSG:3846", label: "3846:SWEREF99 / RT90 5 gon V emulation"},
  {coordinate_system:"EPSG:3847", label: "3847:SWEREF99 / RT90 2.5 gon V emulation"},
  {coordinate_system:"EPSG:3848", label: "3848:SWEREF99 / RT90 0 gon emulation"},
  {coordinate_system:"EPSG:3849", label: "3849:SWEREF99 / RT90 2.5 gon O emulation"},
  {coordinate_system:"EPSG:3850", label: "3850:SWEREF99 / RT90 5 gon O emulation"},
  {coordinate_system:"EPSG:3851", label: "3851:NZGD2000 / NZCS2000"},
  {coordinate_system:"EPSG:3852", label: "3852:RSRGD2000 / DGLC2000"},
  {coordinate_system:"EPSG:3854", label: "3854:County ST74"},
  {coordinate_system:"EPSG:3857", label: "3857:WGS 84 / Pseudo-Mercator"},
  {coordinate_system:"EPSG:3873", label: "3873:ETRS89 / GK19FIN"},
  {coordinate_system:"EPSG:3874", label: "3874:ETRS89 / GK20FIN"},
  {coordinate_system:"EPSG:3875", label: "3875:ETRS89 / GK21FIN"},
  {coordinate_system:"EPSG:3876", label: "3876:ETRS89 / GK22FIN"},
  {coordinate_system:"EPSG:3877", label: "3877:ETRS89 / GK23FIN"},
  {coordinate_system:"EPSG:3878", label: "3878:ETRS89 / GK24FIN"},
  {coordinate_system:"EPSG:3879", label: "3879:ETRS89 / GK25FIN"},
  {coordinate_system:"EPSG:3880", label: "3880:ETRS89 / GK26FIN"},
  {coordinate_system:"EPSG:3881", label: "3881:ETRS89 / GK27FIN"},
  {coordinate_system:"EPSG:3882", label: "3882:ETRS89 / GK28FIN"},
  {coordinate_system:"EPSG:3883", label: "3883:ETRS89 / GK29FIN"},
  {coordinate_system:"EPSG:3884", label: "3884:ETRS89 / GK30FIN"},
  {coordinate_system:"EPSG:3885", label: "3885:ETRS89 / GK31FIN"},
  {coordinate_system:"EPSG:3890", label: "3890:IGRS / UTM zone 37N"},
  {coordinate_system:"EPSG:3891", label: "3891:IGRS / UTM zone 38N"},
  {coordinate_system:"EPSG:3892", label: "3892:IGRS / UTM zone 39N"},
  {coordinate_system:"EPSG:3893", label: "3893:ED50 / Iraq National Grid"},
  {coordinate_system:"EPSG:3912", label: "3912:MGI 1901 / Slovene National Grid"},
  {coordinate_system:"EPSG:3920", label: "3920:Puerto Rico / UTM zone 20N"},
  {coordinate_system:"EPSG:3942", label: "3942:RGF93 v1 / CC42"},
  {coordinate_system:"EPSG:3943", label: "3943:RGF93 v1 / CC43"},
  {coordinate_system:"EPSG:3944", label: "3944:RGF93 v1 / CC44"},
  {coordinate_system:"EPSG:3945", label: "3945:RGF93 v1 / CC45"},
  {coordinate_system:"EPSG:3946", label: "3946:RGF93 v1 / CC46"},
  {coordinate_system:"EPSG:3947", label: "3947:RGF93 v1 / CC47"},
  {coordinate_system:"EPSG:3948", label: "3948:RGF93 v1 / CC48"},
  {coordinate_system:"EPSG:3949", label: "3949:RGF93 v1 / CC49"},
  {coordinate_system:"EPSG:3950", label: "3950:RGF93 v1 / CC50"},
  {coordinate_system:"EPSG:3968", label: "3968:NAD83 / Virginia Lambert"},
  {coordinate_system:"EPSG:3969", label: "3969:NAD83(HARN) / Virginia Lambert"},
  {coordinate_system:"EPSG:3970", label: "3970:NAD83(NSRS2007) / Virginia Lambert"},
  {coordinate_system:"EPSG:3976", label: "3976:WGS 84 / NSIDC Sea Ice Polar Stereographic South"},
  {coordinate_system:"EPSG:3978", label: "3978:NAD83 / Canada Atlas Lambert"},
  {coordinate_system:"EPSG:3979", label: "3979:NAD83(CSRS) / Canada Atlas Lambert"},
  {coordinate_system:"EPSG:3986", label: "3986:Katanga 1955 / Katanga Gauss zone A"},
  {coordinate_system:"EPSG:3987", label: "3987:Katanga 1955 / Katanga Gauss zone B"},
  {coordinate_system:"EPSG:3988", label: "3988:Katanga 1955 / Katanga Gauss zone C"},
  {coordinate_system:"EPSG:3989", label: "3989:Katanga 1955 / Katanga Gauss zone D"},
  {coordinate_system:"EPSG:3991", label: "3991:Puerto Rico State Plane CS of 1927"},
  {coordinate_system:"EPSG:3992", label: "3992:Puerto Rico / St. Croix"},
  {coordinate_system:"EPSG:3993", label: "3993:Guam 1963 / Guam SPCS"},
  {coordinate_system:"EPSG:3994", label: "3994:WGS 84 / Mercator 41"},
  {coordinate_system:"EPSG:3995", label: "3995:WGS 84 / Arctic Polar Stereographic"},
  {coordinate_system:"EPSG:3996", label: "3996:WGS 84 / IBCAO Polar Stereographic"},
  {coordinate_system:"EPSG:3997", label: "3997:WGS 84 / Dubai Local TM"},
  {coordinate_system:"EPSG:4026", label: "4026:MOLDREF99 / Moldova TM"},
  {coordinate_system:"EPSG:4037", label: "4037:WGS 84 / TMzn35N"},
  {coordinate_system:"EPSG:4038", label: "4038:WGS 84 / TMzn36N"},
  {coordinate_system:"EPSG:4048", label: "4048:RGRDC 2005 / Congo TM zone 12"},
  {coordinate_system:"EPSG:4049", label: "4049:RGRDC 2005 / Congo TM zone 14"},
  {coordinate_system:"EPSG:4050", label: "4050:RGRDC 2005 / Congo TM zone 16"},
  {coordinate_system:"EPSG:4051", label: "4051:RGRDC 2005 / Congo TM zone 18"},
  {coordinate_system:"EPSG:4056", label: "4056:RGRDC 2005 / Congo TM zone 20"},
  {coordinate_system:"EPSG:4057", label: "4057:RGRDC 2005 / Congo TM zone 22"},
  {coordinate_system:"EPSG:4058", label: "4058:RGRDC 2005 / Congo TM zone 24"},
  {coordinate_system:"EPSG:4059", label: "4059:RGRDC 2005 / Congo TM zone 26"},
  {coordinate_system:"EPSG:4060", label: "4060:RGRDC 2005 / Congo TM zone 28"},
  {coordinate_system:"EPSG:4061", label: "4061:RGRDC 2005 / UTM zone 33S"},
  {coordinate_system:"EPSG:4062", label: "4062:RGRDC 2005 / UTM zone 34S"},
  {coordinate_system:"EPSG:4063", label: "4063:RGRDC 2005 / UTM zone 35S"},
  {coordinate_system:"EPSG:4071", label: "4071:Chua / UTM zone 23S"},
  {coordinate_system:"EPSG:4082", label: "4082:REGCAN95 / UTM zone 27N"},
  {coordinate_system:"EPSG:4083", label: "4083:REGCAN95 / UTM zone 28N"},
  {coordinate_system:"EPSG:4087", label: "4087:WGS 84 / World Equidistant Cylindrical"},
  {coordinate_system:"EPSG:4093", label: "4093:ETRS89 / DKTM1"},
  {coordinate_system:"EPSG:4094", label: "4094:ETRS89 / DKTM2"},
  {coordinate_system:"EPSG:4095", label: "4095:ETRS89 / DKTM3"},
  {coordinate_system:"EPSG:4096", label: "4096:ETRS89 / DKTM4"},
  {coordinate_system:"EPSG:4217", label: "4217:NAD83 / BLM 59N (ftUS)"},
  {coordinate_system:"EPSG:4390", label: "4390:Kertau 1968 / Johor Grid"},
  {coordinate_system:"EPSG:4391", label: "4391:Kertau 1968 / Sembilan and Melaka Grid"},
  {coordinate_system:"EPSG:4392", label: "4392:Kertau 1968 / Pahang Grid"},
  {coordinate_system:"EPSG:4393", label: "4393:Kertau 1968 / Selangor Grid"},
  {coordinate_system:"EPSG:4394", label: "4394:Kertau 1968 / Terengganu Grid"},
  {coordinate_system:"EPSG:4395", label: "4395:Kertau 1968 / Pinang Grid"},
  {coordinate_system:"EPSG:4396", label: "4396:Kertau 1968 / Kedah and Perlis Grid"},
  {coordinate_system:"EPSG:4397", label: "4397:Kertau 1968 / Perak Revised Grid"},
  {coordinate_system:"EPSG:4398", label: "4398:Kertau 1968 / Kelantan Grid"},
  {coordinate_system:"EPSG:4399", label: "4399:NAD27 / BLM 59N (ftUS)"},
  {coordinate_system:"EPSG:4400", label: "4400:NAD27 / BLM 60N (ftUS)"},
  {coordinate_system:"EPSG:4401", label: "4401:NAD27 / BLM 1N (ftUS)"},
  {coordinate_system:"EPSG:4402", label: "4402:NAD27 / BLM 2N (ftUS)"},
  {coordinate_system:"EPSG:4403", label: "4403:NAD27 / BLM 3N (ftUS)"},
  {coordinate_system:"EPSG:4404", label: "4404:NAD27 / BLM 4N (ftUS)"},
  {coordinate_system:"EPSG:4405", label: "4405:NAD27 / BLM 5N (ftUS)"},
  {coordinate_system:"EPSG:4406", label: "4406:NAD27 / BLM 6N (ftUS)"},
  {coordinate_system:"EPSG:4407", label: "4407:NAD27 / BLM 7N (ftUS)"},
  {coordinate_system:"EPSG:4408", label: "4408:NAD27 / BLM 8N (ftUS)"},
  {coordinate_system:"EPSG:4409", label: "4409:NAD27 / BLM 9N (ftUS)"},
  {coordinate_system:"EPSG:4410", label: "4410:NAD27 / BLM 10N (ftUS)"},
  {coordinate_system:"EPSG:4411", label: "4411:NAD27 / BLM 11N (ftUS)"},
  {coordinate_system:"EPSG:4412", label: "4412:NAD27 / BLM 12N (ftUS)"},
  {coordinate_system:"EPSG:4413", label: "4413:NAD27 / BLM 13N (ftUS)"},
  {coordinate_system:"EPSG:4414", label: "4414:NAD83(HARN) / Guam Map Grid"},
  {coordinate_system:"EPSG:4415", label: "4415:Katanga 1955 / Katanga Lambert"},
  {coordinate_system:"EPSG:4417", label: "4417:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:4418", label: "4418:NAD27 / BLM 18N (ftUS)"},
  {coordinate_system:"EPSG:4419", label: "4419:NAD27 / BLM 19N (ftUS)"},
  {coordinate_system:"EPSG:4420", label: "4420:NAD83 / BLM 60N (ftUS)"},
  {coordinate_system:"EPSG:4421", label: "4421:NAD83 / BLM 1N (ftUS)"},
  {coordinate_system:"EPSG:4422", label: "4422:NAD83 / BLM 2N (ftUS)"},
  {coordinate_system:"EPSG:4423", label: "4423:NAD83 / BLM 3N (ftUS)"},
  {coordinate_system:"EPSG:4424", label: "4424:NAD83 / BLM 4N (ftUS)"},
  {coordinate_system:"EPSG:4425", label: "4425:NAD83 / BLM 5N (ftUS)"},
  {coordinate_system:"EPSG:4426", label: "4426:NAD83 / BLM 6N (ftUS)"},
  {coordinate_system:"EPSG:4427", label: "4427:NAD83 / BLM 7N (ftUS)"},
  {coordinate_system:"EPSG:4428", label: "4428:NAD83 / BLM 8N (ftUS)"},
  {coordinate_system:"EPSG:4429", label: "4429:NAD83 / BLM 9N (ftUS)"},
  {coordinate_system:"EPSG:4430", label: "4430:NAD83 / BLM 10N (ftUS)"},
  {coordinate_system:"EPSG:4431", label: "4431:NAD83 / BLM 11N (ftUS)"},
  {coordinate_system:"EPSG:4432", label: "4432:NAD83 / BLM 12N (ftUS)"},
  {coordinate_system:"EPSG:4433", label: "4433:NAD83 / BLM 13N (ftUS)"},
  {coordinate_system:"EPSG:4434", label: "4434:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:4437", label: "4437:NAD83(NSRS2007) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:4438", label: "4438:NAD83 / BLM 18N (ftUS)"},
  {coordinate_system:"EPSG:4439", label: "4439:NAD83 / BLM 19N (ftUS)"},
  {coordinate_system:"EPSG:4455", label: "4455:NAD27 / Pennsylvania South"},
  {coordinate_system:"EPSG:4456", label: "4456:NAD27 / New York Long Island"},
  {coordinate_system:"EPSG:4457", label: "4457:NAD83 / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:4462", label: "4462:WGS 84 / Australian Centre for Remote Sensing Lambert"},
  {coordinate_system:"EPSG:4467", label: "4467:RGSPM06 / UTM zone 21N"},
  {coordinate_system:"EPSG:4471", label: "4471:RGM04 / UTM zone 38S"},
  {coordinate_system:"EPSG:4484", label: "4484:Mexico ITRF92 / UTM zone 11N"},
  {coordinate_system:"EPSG:4485", label: "4485:Mexico ITRF92 / UTM zone 12N"},
  {coordinate_system:"EPSG:4486", label: "4486:Mexico ITRF92 / UTM zone 13N"},
  {coordinate_system:"EPSG:4487", label: "4487:Mexico ITRF92 / UTM zone 14N"},
  {coordinate_system:"EPSG:4488", label: "4488:Mexico ITRF92 / UTM zone 15N"},
  {coordinate_system:"EPSG:4489", label: "4489:Mexico ITRF92 / UTM zone 16N"},
  {coordinate_system:"EPSG:4491", label: "4491:CGCS2000 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:4492", label: "4492:CGCS2000 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:4493", label: "4493:CGCS2000 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:4494", label: "4494:CGCS2000 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:4495", label: "4495:CGCS2000 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:4496", label: "4496:CGCS2000 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:4497", label: "4497:CGCS2000 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:4498", label: "4498:CGCS2000 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:4499", label: "4499:CGCS2000 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:4500", label: "4500:CGCS2000 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:4501", label: "4501:CGCS2000 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:4502", label: "4502:CGCS2000 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4503", label: "4503:CGCS2000 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4504", label: "4504:CGCS2000 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4505", label: "4505:CGCS2000 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4506", label: "4506:CGCS2000 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4507", label: "4507:CGCS2000 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4508", label: "4508:CGCS2000 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4509", label: "4509:CGCS2000 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4510", label: "4510:CGCS2000 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4511", label: "4511:CGCS2000 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4512", label: "4512:CGCS2000 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4513", label: "4513:CGCS2000 / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:4514", label: "4514:CGCS2000 / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:4515", label: "4515:CGCS2000 / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:4516", label: "4516:CGCS2000 / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:4517", label: "4517:CGCS2000 / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:4518", label: "4518:CGCS2000 / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:4519", label: "4519:CGCS2000 / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:4520", label: "4520:CGCS2000 / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:4521", label: "4521:CGCS2000 / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:4522", label: "4522:CGCS2000 / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:4523", label: "4523:CGCS2000 / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:4524", label: "4524:CGCS2000 / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:4525", label: "4525:CGCS2000 / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:4526", label: "4526:CGCS2000 / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:4527", label: "4527:CGCS2000 / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:4528", label: "4528:CGCS2000 / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:4529", label: "4529:CGCS2000 / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:4530", label: "4530:CGCS2000 / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:4531", label: "4531:CGCS2000 / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:4532", label: "4532:CGCS2000 / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:4533", label: "4533:CGCS2000 / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:4534", label: "4534:CGCS2000 / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4535", label: "4535:CGCS2000 / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:4536", label: "4536:CGCS2000 / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4537", label: "4537:CGCS2000 / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:4538", label: "4538:CGCS2000 / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4539", label: "4539:CGCS2000 / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:4540", label: "4540:CGCS2000 / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4541", label: "4541:CGCS2000 / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:4542", label: "4542:CGCS2000 / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4543", label: "4543:CGCS2000 / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:4544", label: "4544:CGCS2000 / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4545", label: "4545:CGCS2000 / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:4546", label: "4546:CGCS2000 / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4547", label: "4547:CGCS2000 / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:4548", label: "4548:CGCS2000 / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4549", label: "4549:CGCS2000 / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:4550", label: "4550:CGCS2000 / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4551", label: "4551:CGCS2000 / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:4552", label: "4552:CGCS2000 / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4553", label: "4553:CGCS2000 / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:4554", label: "4554:CGCS2000 / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4559", label: "4559:RRAF 1991 / UTM zone 20N"},
  {coordinate_system:"EPSG:4568", label: "4568:New Beijing / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:4569", label: "4569:New Beijing / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:4570", label: "4570:New Beijing / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:4571", label: "4571:New Beijing / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:4572", label: "4572:New Beijing / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:4573", label: "4573:New Beijing / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:4574", label: "4574:New Beijing / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:4575", label: "4575:New Beijing / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:4576", label: "4576:New Beijing / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:4577", label: "4577:New Beijing / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:4578", label: "4578:New Beijing / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:4579", label: "4579:New Beijing / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4580", label: "4580:New Beijing / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4581", label: "4581:New Beijing / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4582", label: "4582:New Beijing / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4583", label: "4583:New Beijing / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4584", label: "4584:New Beijing / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4585", label: "4585:New Beijing / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4586", label: "4586:New Beijing / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4587", label: "4587:New Beijing / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4588", label: "4588:New Beijing / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4589", label: "4589:New Beijing / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4647", label: "4647:ETRS89 / UTM zone 32N (zE-N)"},
  {coordinate_system:"EPSG:4652", label: "4652:New Beijing / 3-degree Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:4653", label: "4653:New Beijing / 3-degree Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:4654", label: "4654:New Beijing / 3-degree Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:4655", label: "4655:New Beijing / 3-degree Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:4656", label: "4656:New Beijing / 3-degree Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:4766", label: "4766:New Beijing / 3-degree Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:4767", label: "4767:New Beijing / 3-degree Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:4768", label: "4768:New Beijing / 3-degree Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:4769", label: "4769:New Beijing / 3-degree Gauss-Kruger zone 33"},
  {coordinate_system:"EPSG:4770", label: "4770:New Beijing / 3-degree Gauss-Kruger zone 34"},
  {coordinate_system:"EPSG:4771", label: "4771:New Beijing / 3-degree Gauss-Kruger zone 35"},
  {coordinate_system:"EPSG:4772", label: "4772:New Beijing / 3-degree Gauss-Kruger zone 36"},
  {coordinate_system:"EPSG:4773", label: "4773:New Beijing / 3-degree Gauss-Kruger zone 37"},
  {coordinate_system:"EPSG:4774", label: "4774:New Beijing / 3-degree Gauss-Kruger zone 38"},
  {coordinate_system:"EPSG:4775", label: "4775:New Beijing / 3-degree Gauss-Kruger zone 39"},
  {coordinate_system:"EPSG:4776", label: "4776:New Beijing / 3-degree Gauss-Kruger zone 40"},
  {coordinate_system:"EPSG:4777", label: "4777:New Beijing / 3-degree Gauss-Kruger zone 41"},
  {coordinate_system:"EPSG:4778", label: "4778:New Beijing / 3-degree Gauss-Kruger zone 42"},
  {coordinate_system:"EPSG:4779", label: "4779:New Beijing / 3-degree Gauss-Kruger zone 43"},
  {coordinate_system:"EPSG:4780", label: "4780:New Beijing / 3-degree Gauss-Kruger zone 44"},
  {coordinate_system:"EPSG:4781", label: "4781:New Beijing / 3-degree Gauss-Kruger zone 45"},
  {coordinate_system:"EPSG:4782", label: "4782:New Beijing / 3-degree Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:4783", label: "4783:New Beijing / 3-degree Gauss-Kruger CM 78E"},
  {coordinate_system:"EPSG:4784", label: "4784:New Beijing / 3-degree Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:4785", label: "4785:New Beijing / 3-degree Gauss-Kruger CM 84E"},
  {coordinate_system:"EPSG:4786", label: "4786:New Beijing / 3-degree Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:4787", label: "4787:New Beijing / 3-degree Gauss-Kruger CM 90E"},
  {coordinate_system:"EPSG:4788", label: "4788:New Beijing / 3-degree Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:4789", label: "4789:New Beijing / 3-degree Gauss-Kruger CM 96E"},
  {coordinate_system:"EPSG:4790", label: "4790:New Beijing / 3-degree Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:4791", label: "4791:New Beijing / 3-degree Gauss-Kruger CM 102E"},
  {coordinate_system:"EPSG:4792", label: "4792:New Beijing / 3-degree Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:4793", label: "4793:New Beijing / 3-degree Gauss-Kruger CM 108E"},
  {coordinate_system:"EPSG:4794", label: "4794:New Beijing / 3-degree Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:4795", label: "4795:New Beijing / 3-degree Gauss-Kruger CM 114E"},
  {coordinate_system:"EPSG:4796", label: "4796:New Beijing / 3-degree Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:4797", label: "4797:New Beijing / 3-degree Gauss-Kruger CM 120E"},
  {coordinate_system:"EPSG:4798", label: "4798:New Beijing / 3-degree Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:4799", label: "4799:New Beijing / 3-degree Gauss-Kruger CM 126E"},
  {coordinate_system:"EPSG:4800", label: "4800:New Beijing / 3-degree Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:4812", label: "4812:New Beijing / 3-degree Gauss-Kruger CM 132E"},
  {coordinate_system:"EPSG:4822", label: "4822:New Beijing / 3-degree Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:4826", label: "4826:WGS 84 / Cape Verde National"},
  {coordinate_system:"EPSG:4839", label: "4839:ETRS89 / LCC Germany (N-E)"},
  {coordinate_system:"EPSG:5014", label: "5014:PTRA08 / UTM zone 25N"},
  {coordinate_system:"EPSG:5015", label: "5015:PTRA08 / UTM zone 26N"},
  {coordinate_system:"EPSG:5016", label: "5016:PTRA08 / UTM zone 28N"},
  {coordinate_system:"EPSG:5017", label: "5017:Lisbon 1890 / Portugal Bonne New"},
  {coordinate_system:"EPSG:5018", label: "5018:Lisbon / Portuguese Grid New"},
  {coordinate_system:"EPSG:5041", label: "5041:WGS 84 / UPS North (E,N)"},
  {coordinate_system:"EPSG:5042", label: "5042:WGS 84 / UPS South (E,N)"},
  {coordinate_system:"EPSG:5048", label: "5048:ETRS89 / TM35FIN(N,E)"},
  {coordinate_system:"EPSG:5069", label: "5069:NAD27 / Conus Albers"},
  {coordinate_system:"EPSG:5070", label: "5070:NAD83 / Conus Albers"},
  {coordinate_system:"EPSG:5071", label: "5071:NAD83(HARN) / Conus Albers"},
  {coordinate_system:"EPSG:5072", label: "5072:NAD83(NSRS2007) / Conus Albers"},
  {coordinate_system:"EPSG:5105", label: "5105:ETRS89 / NTM zone 5"},
  {coordinate_system:"EPSG:5106", label: "5106:ETRS89 / NTM zone 6"},
  {coordinate_system:"EPSG:5107", label: "5107:ETRS89 / NTM zone 7"},
  {coordinate_system:"EPSG:5108", label: "5108:ETRS89 / NTM zone 8"},
  {coordinate_system:"EPSG:5109", label: "5109:ETRS89 / NTM zone 9"},
  {coordinate_system:"EPSG:5110", label: "5110:ETRS89 / NTM zone 10"},
  {coordinate_system:"EPSG:5111", label: "5111:ETRS89 / NTM zone 11"},
  {coordinate_system:"EPSG:5112", label: "5112:ETRS89 / NTM zone 12"},
  {coordinate_system:"EPSG:5113", label: "5113:ETRS89 / NTM zone 13"},
  {coordinate_system:"EPSG:5114", label: "5114:ETRS89 / NTM zone 14"},
  {coordinate_system:"EPSG:5115", label: "5115:ETRS89 / NTM zone 15"},
  {coordinate_system:"EPSG:5116", label: "5116:ETRS89 / NTM zone 16"},
  {coordinate_system:"EPSG:5117", label: "5117:ETRS89 / NTM zone 17"},
  {coordinate_system:"EPSG:5118", label: "5118:ETRS89 / NTM zone 18"},
  {coordinate_system:"EPSG:5119", label: "5119:ETRS89 / NTM zone 19"},
  {coordinate_system:"EPSG:5120", label: "5120:ETRS89 / NTM zone 20"},
  {coordinate_system:"EPSG:5121", label: "5121:ETRS89 / NTM zone 21"},
  {coordinate_system:"EPSG:5122", label: "5122:ETRS89 / NTM zone 22"},
  {coordinate_system:"EPSG:5123", label: "5123:ETRS89 / NTM zone 23"},
  {coordinate_system:"EPSG:5124", label: "5124:ETRS89 / NTM zone 24"},
  {coordinate_system:"EPSG:5125", label: "5125:ETRS89 / NTM zone 25"},
  {coordinate_system:"EPSG:5126", label: "5126:ETRS89 / NTM zone 26"},
  {coordinate_system:"EPSG:5127", label: "5127:ETRS89 / NTM zone 27"},
  {coordinate_system:"EPSG:5128", label: "5128:ETRS89 / NTM zone 28"},
  {coordinate_system:"EPSG:5129", label: "5129:ETRS89 / NTM zone 29"},
  {coordinate_system:"EPSG:5130", label: "5130:ETRS89 / NTM zone 30"},
  {coordinate_system:"EPSG:5167", label: "5167:Korean 1985 / East Sea Belt"},
  {coordinate_system:"EPSG:5168", label: "5168:Korean 1985 / Central Belt Jeju"},
  {coordinate_system:"EPSG:5169", label: "5169:Tokyo 1892 / Korea West Belt"},
  {coordinate_system:"EPSG:5170", label: "5170:Tokyo 1892 / Korea Central Belt"},
  {coordinate_system:"EPSG:5171", label: "5171:Tokyo 1892 / Korea East Belt"},
  {coordinate_system:"EPSG:5172", label: "5172:Tokyo 1892 / Korea East Sea Belt"},
  {coordinate_system:"EPSG:5173", label: "5173:Korean 1985 / Modified West Belt"},
  {coordinate_system:"EPSG:5174", label: "5174:Korean 1985 / Modified Central Belt"},
  {coordinate_system:"EPSG:5175", label: "5175:Korean 1985 / Modified Central Belt Jeju"},
  {coordinate_system:"EPSG:5176", label: "5176:Korean 1985 / Modified East Belt"},
  {coordinate_system:"EPSG:5177", label: "5177:Korean 1985 / Modified East Sea Belt"},
  {coordinate_system:"EPSG:5178", label: "5178:Korean 1985 / Unified CS"},
  {coordinate_system:"EPSG:5179", label: "5179:Korea 2000 / Unified CS"},
  {coordinate_system:"EPSG:5180", label: "5180:Korea 2000 / West Belt"},
  {coordinate_system:"EPSG:5181", label: "5181:Korea 2000 / Central Belt"},
  {coordinate_system:"EPSG:5182", label: "5182:Korea 2000 / Central Belt Jeju"},
  {coordinate_system:"EPSG:5183", label: "5183:Korea 2000 / East Belt"},
  {coordinate_system:"EPSG:5184", label: "5184:Korea 2000 / East Sea Belt"},
  {coordinate_system:"EPSG:5185", label: "5185:Korea 2000 / West Belt 2010"},
  {coordinate_system:"EPSG:5186", label: "5186:Korea 2000 / Central Belt 2010"},
  {coordinate_system:"EPSG:5187", label: "5187:Korea 2000 / East Belt 2010"},
  {coordinate_system:"EPSG:5188", label: "5188:Korea 2000 / East Sea Belt 2010"},
  {coordinate_system:"EPSG:5221", label: "5221:S-JTSK (Ferro) / Krovak East North"},
  {coordinate_system:"EPSG:5223", label: "5223:WGS 84 / Gabon TM"},
  {coordinate_system:"EPSG:5224", label: "5224:S-JTSK/05 (Ferro) / Modified Krovak"},
  {coordinate_system:"EPSG:5225", label: "5225:S-JTSK/05 (Ferro) / Modified Krovak East North"},
  {coordinate_system:"EPSG:5234", label: "5234:Kandawala / Sri Lanka Grid"},
  {coordinate_system:"EPSG:5235", label: "5235:SLD99 / Sri Lanka Grid 1999"},
  {coordinate_system:"EPSG:5243", label: "5243:ETRS89 / LCC Germany (E-N)"},
  {coordinate_system:"EPSG:5247", label: "5247:GDBD2009 / Brunei BRSO"},
  {coordinate_system:"EPSG:5253", label: "5253:TUREF / TM27"},
  {coordinate_system:"EPSG:5254", label: "5254:TUREF / TM30"},
  {coordinate_system:"EPSG:5255", label: "5255:TUREF / TM33"},
  {coordinate_system:"EPSG:5256", label: "5256:TUREF / TM36"},
  {coordinate_system:"EPSG:5257", label: "5257:TUREF / TM39"},
  {coordinate_system:"EPSG:5258", label: "5258:TUREF / TM42"},
  {coordinate_system:"EPSG:5259", label: "5259:TUREF / TM45"},
  {coordinate_system:"EPSG:5266", label: "5266:DRUKREF 03 / Bhutan National Grid"},
  {coordinate_system:"EPSG:5269", label: "5269:TUREF / 3-degree Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:5270", label: "5270:TUREF / 3-degree Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:5271", label: "5271:TUREF / 3-degree Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:5272", label: "5272:TUREF / 3-degree Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:5273", label: "5273:TUREF / 3-degree Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:5274", label: "5274:TUREF / 3-degree Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:5275", label: "5275:TUREF / 3-degree Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:5292", label: "5292:DRUKREF 03 / Bumthang TM"},
  {coordinate_system:"EPSG:5293", label: "5293:DRUKREF 03 / Chhukha TM"},
  {coordinate_system:"EPSG:5294", label: "5294:DRUKREF 03 / Dagana TM"},
  {coordinate_system:"EPSG:5295", label: "5295:DRUKREF 03 / Gasa TM"},
  {coordinate_system:"EPSG:5296", label: "5296:DRUKREF 03 / Ha TM"},
  {coordinate_system:"EPSG:5297", label: "5297:DRUKREF 03 / Lhuentse TM"},
  {coordinate_system:"EPSG:5298", label: "5298:DRUKREF 03 / Mongar TM"},
  {coordinate_system:"EPSG:5299", label: "5299:DRUKREF 03 / Paro TM"},
  {coordinate_system:"EPSG:5300", label: "5300:DRUKREF 03 / Pemagatshel TM"},
  {coordinate_system:"EPSG:5301", label: "5301:DRUKREF 03 / Punakha TM"},
  {coordinate_system:"EPSG:5302", label: "5302:DRUKREF 03 / Samdrup Jongkhar TM"},
  {coordinate_system:"EPSG:5303", label: "5303:DRUKREF 03 / Samtse TM"},
  {coordinate_system:"EPSG:5304", label: "5304:DRUKREF 03 / Sarpang TM"},
  {coordinate_system:"EPSG:5305", label: "5305:DRUKREF 03 / Thimphu TM"},
  {coordinate_system:"EPSG:5306", label: "5306:DRUKREF 03 / Trashigang TM"},
  {coordinate_system:"EPSG:5307", label: "5307:DRUKREF 03 / Trongsa TM"},
  {coordinate_system:"EPSG:5308", label: "5308:DRUKREF 03 / Tsirang TM"},
  {coordinate_system:"EPSG:5309", label: "5309:DRUKREF 03 / Wangdue Phodrang TM"},
  {coordinate_system:"EPSG:5310", label: "5310:DRUKREF 03 / Yangtse TM"},
  {coordinate_system:"EPSG:5311", label: "5311:DRUKREF 03 / Zhemgang TM"},
  {coordinate_system:"EPSG:5316", label: "5316:ETRS89 / Faroe TM"},
  {coordinate_system:"EPSG:5320", label: "5320:NAD83 / Teranet Ontario Lambert"},
  {coordinate_system:"EPSG:5321", label: "5321:NAD83(CSRS) / Teranet Ontario Lambert"},
  {coordinate_system:"EPSG:5325", label: "5325:ISN2004 / Lambert 2004"},
  {coordinate_system:"EPSG:5329", label: "5329:Segara (Jakarta) / NEIEZ"},
  {coordinate_system:"EPSG:5330", label: "5330:Batavia (Jakarta) / NEIEZ"},
  {coordinate_system:"EPSG:5331", label: "5331:Makassar (Jakarta) / NEIEZ"},
  {coordinate_system:"EPSG:5337", label: "5337:Aratu / UTM zone 25S"},
  {coordinate_system:"EPSG:5343", label: "5343:POSGAR 2007 / Argentina 1"},
  {coordinate_system:"EPSG:5344", label: "5344:POSGAR 2007 / Argentina 2"},
  {coordinate_system:"EPSG:5345", label: "5345:POSGAR 2007 / Argentina 3"},
  {coordinate_system:"EPSG:5346", label: "5346:POSGAR 2007 / Argentina 4"},
  {coordinate_system:"EPSG:5347", label: "5347:POSGAR 2007 / Argentina 5"},
  {coordinate_system:"EPSG:5348", label: "5348:POSGAR 2007 / Argentina 6"},
  {coordinate_system:"EPSG:5349", label: "5349:POSGAR 2007 / Argentina 7"},
  {coordinate_system:"EPSG:5355", label: "5355:MARGEN / UTM zone 20S"},
  {coordinate_system:"EPSG:5356", label: "5356:MARGEN / UTM zone 19S"},
  {coordinate_system:"EPSG:5357", label: "5357:MARGEN / UTM zone 21S"},
  {coordinate_system:"EPSG:5361", label: "5361:SIRGAS-Chile 2002 / UTM zone 19S"},
  {coordinate_system:"EPSG:5362", label: "5362:SIRGAS-Chile 2002 / UTM zone 18S"},
  {coordinate_system:"EPSG:5367", label: "5367:CR05 / CRTM05"},
  {coordinate_system:"EPSG:5382", label: "5382:SIRGAS-ROU98 / UTM zone 21S"},
  {coordinate_system:"EPSG:5383", label: "5383:SIRGAS-ROU98 / UTM zone 22S"},
  {coordinate_system:"EPSG:5387", label: "5387:Peru96 / UTM zone 18S"},
  {coordinate_system:"EPSG:5389", label: "5389:Peru96 / UTM zone 19S"},
  {coordinate_system:"EPSG:5396", label: "5396:SIRGAS 2000 / UTM zone 26S"},
  {coordinate_system:"EPSG:5456", label: "5456:Ocotepeque 1935 / Costa Rica Norte"},
  {coordinate_system:"EPSG:5457", label: "5457:Ocotepeque 1935 / Costa Rica Sur"},
  {coordinate_system:"EPSG:5459", label: "5459:Ocotepeque 1935 / Guatemala Sur"},
  {coordinate_system:"EPSG:5460", label: "5460:Ocotepeque 1935 / El Salvador Lambert"},
  {coordinate_system:"EPSG:5461", label: "5461:Ocotepeque 1935 / Nicaragua Norte"},
  {coordinate_system:"EPSG:5462", label: "5462:Ocotepeque 1935 / Nicaragua Sur"},
  {coordinate_system:"EPSG:5463", label: "5463:SAD69 / UTM zone 17N"},
  {coordinate_system:"EPSG:5469", label: "5469:Panama-Colon 1911 / Panama Lambert"},
  {coordinate_system:"EPSG:5472", label: "5472:Panama-Colon 1911 / Panama Polyconic"},
  {coordinate_system:"EPSG:5479", label: "5479:RSRGD2000 / MSLC2000"},
  {coordinate_system:"EPSG:5480", label: "5480:RSRGD2000 / BCLC2000"},
  {coordinate_system:"EPSG:5481", label: "5481:RSRGD2000 / PCLC2000"},
  {coordinate_system:"EPSG:5482", label: "5482:RSRGD2000 / RSPS2000"},
  {coordinate_system:"EPSG:5490", label: "5490:RGAF09 / UTM zone 20N"},
  {coordinate_system:"EPSG:5513", label: "5513:S-JTSK / Krovak"},
  {coordinate_system:"EPSG:5514", label: "5514:S-JTSK / Krovak East North"},
  {coordinate_system:"EPSG:5515", label: "5515:S-JTSK/05 / Modified Krovak"},
  {coordinate_system:"EPSG:5516", label: "5516:S-JTSK/05 / Modified Krovak East North"},
  {coordinate_system:"EPSG:5518", label: "5518:CI1971 / Chatham Islands Map Grid"},
  {coordinate_system:"EPSG:5519", label: "5519:CI1979 / Chatham Islands Map Grid"},
  {coordinate_system:"EPSG:5520", label: "5520:DHDN / 3-degree Gauss-Kruger zone 1"},
  {coordinate_system:"EPSG:5523", label: "5523:WGS 84 / Gabon TM 2011"},
  {coordinate_system:"EPSG:5530", label: "5530:SAD69(96) / Brazil Polyconic"},
  {coordinate_system:"EPSG:5531", label: "5531:SAD69(96) / UTM zone 21S"},
  {coordinate_system:"EPSG:5533", label: "5533:SAD69(96) / UTM zone 23S"},
  {coordinate_system:"EPSG:5534", label: "5534:SAD69(96) / UTM zone 24S"},
  {coordinate_system:"EPSG:5535", label: "5535:SAD69(96) / UTM zone 25S"},
  {coordinate_system:"EPSG:5536", label: "5536:Corrego Alegre 1961 / UTM zone 21S"},
  {coordinate_system:"EPSG:5537", label: "5537:Corrego Alegre 1961 / UTM zone 22S"},
  {coordinate_system:"EPSG:5538", label: "5538:Corrego Alegre 1961 / UTM zone 23S"},
  {coordinate_system:"EPSG:5539", label: "5539:Corrego Alegre 1961 / UTM zone 24S"},
  {coordinate_system:"EPSG:5550", label: "5550:PNG94 / PNGMG94 zone 54"},
  {coordinate_system:"EPSG:5551", label: "5551:PNG94 / PNGMG94 zone 55"},
  {coordinate_system:"EPSG:5552", label: "5552:PNG94 / PNGMG94 zone 56"},
  {coordinate_system:"EPSG:5559", label: "5559:Ocotepeque 1935 / Guatemala Norte"},
  {coordinate_system:"EPSG:5562", label: "5562:UCS-2000 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:5563", label: "5563:UCS-2000 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:5564", label: "5564:UCS-2000 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:5565", label: "5565:UCS-2000 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:5566", label: "5566:UCS-2000 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:5567", label: "5567:UCS-2000 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:5568", label: "5568:UCS-2000 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:5569", label: "5569:UCS-2000 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:5588", label: "5588:NAD27 / New Brunswick Stereographic (NAD27)"},
  {coordinate_system:"EPSG:5589", label: "5589:Sibun Gorge 1922 / Colony Grid"},
  {coordinate_system:"EPSG:5596", label: "5596:FEH2010 / Fehmarnbelt TM"},
  {coordinate_system:"EPSG:5623", label: "5623:NAD27 / Michigan East"},
  {coordinate_system:"EPSG:5624", label: "5624:NAD27 / Michigan Old Central"},
  {coordinate_system:"EPSG:5625", label: "5625:NAD27 / Michigan West"},
  {coordinate_system:"EPSG:5627", label: "5627:ED50 / TM 6 NE"},
  {coordinate_system:"EPSG:5629", label: "5629:Moznet / UTM zone 38S"},
  {coordinate_system:"EPSG:5631", label: "5631:Pulkovo 1942(58) / Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5632", label: "5632:PTRA08 / LCC Europe"},
  {coordinate_system:"EPSG:5633", label: "5633:PTRA08 / LAEA Europe"},
  {coordinate_system:"EPSG:5634", label: "5634:REGCAN95 / LCC Europe"},
  {coordinate_system:"EPSG:5635", label: "5635:REGCAN95 / LAEA Europe"},
  {coordinate_system:"EPSG:5636", label: "5636:TUREF / LAEA Europe"},
  {coordinate_system:"EPSG:5637", label: "5637:TUREF / LCC Europe"},
  {coordinate_system:"EPSG:5638", label: "5638:ISN2004 / LAEA Europe"},
  {coordinate_system:"EPSG:5639", label: "5639:ISN2004 / LCC Europe"},
  {coordinate_system:"EPSG:5641", label: "5641:SIRGAS 2000 / Brazil Mercator"},
  {coordinate_system:"EPSG:5643", label: "5643:ED50 / SPBA LCC"},
  {coordinate_system:"EPSG:5644", label: "5644:RGR92 / UTM zone 39S"},
  {coordinate_system:"EPSG:5646", label: "5646:NAD83 / Vermont (ftUS)"},
  {coordinate_system:"EPSG:5649", label: "5649:ETRS89 / UTM zone 31N (zE-N)"},
  {coordinate_system:"EPSG:5650", label: "5650:ETRS89 / UTM zone 33N (zE-N)"},
  {coordinate_system:"EPSG:5651", label: "5651:ETRS89 / UTM zone 31N (N-zE)"},
  {coordinate_system:"EPSG:5652", label: "5652:ETRS89 / UTM zone 32N (N-zE)"},
  {coordinate_system:"EPSG:5653", label: "5653:ETRS89 / UTM zone 33N (N-zE)"},
  {coordinate_system:"EPSG:5654", label: "5654:NAD83(HARN) / Vermont (ftUS)"},
  {coordinate_system:"EPSG:5655", label: "5655:NAD83(NSRS2007) / Vermont (ftUS)"},
  {coordinate_system:"EPSG:5659", label: "5659:Monte Mario / TM Emilia-Romagna"},
  {coordinate_system:"EPSG:5663", label: "5663:Pulkovo 1942(58) / Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5664", label: "5664:Pulkovo 1942(83) / Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5665", label: "5665:Pulkovo 1942(83) / Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5666", label: "5666:PD/83 / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5667", label: "5667:PD/83 / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5668", label: "5668:RD/83 / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5669", label: "5669:RD/83 / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5670", label: "5670:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5671", label: "5671:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5672", label: "5672:Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5673", label: "5673:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5674", label: "5674:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5675", label: "5675:Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5676", label: "5676:DHDN / 3-degree Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5677", label: "5677:DHDN / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5678", label: "5678:DHDN / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5679", label: "5679:DHDN / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5680", label: "5680:DHDN / 3-degree Gauss-Kruger zone 1 (E-N)"},
  {coordinate_system:"EPSG:5682", label: "5682:DB_REF / 3-degree Gauss-Kruger zone 2 (E-N)"},
  {coordinate_system:"EPSG:5683", label: "5683:DB_REF / 3-degree Gauss-Kruger zone 3 (E-N)"},
  {coordinate_system:"EPSG:5684", label: "5684:DB_REF / 3-degree Gauss-Kruger zone 4 (E-N)"},
  {coordinate_system:"EPSG:5685", label: "5685:DB_REF / 3-degree Gauss-Kruger zone 5 (E-N)"},
  {coordinate_system:"EPSG:5700", label: "5700:NZGD2000 / UTM zone 1S"},
  {coordinate_system:"EPSG:5825", label: "5825:AGD66 / ACT Standard Grid"},
  {coordinate_system:"EPSG:5836", label: "5836:Yemen NGN96 / UTM zone 37N"},
  {coordinate_system:"EPSG:5837", label: "5837:Yemen NGN96 / UTM zone 40N"},
  {coordinate_system:"EPSG:5839", label: "5839:Peru96 / UTM zone 17S"},
  {coordinate_system:"EPSG:5842", label: "5842:WGS 84 / TM 12 SE"},
  {coordinate_system:"EPSG:5844", label: "5844:RGRDC 2005 / Congo TM zone 30"},
  {coordinate_system:"EPSG:5858", label: "5858:SAD69(96) / UTM zone 22S"},
  {coordinate_system:"EPSG:5875", label: "5875:SAD69(96) / UTM zone 18S"},
  {coordinate_system:"EPSG:5876", label: "5876:SAD69(96) / UTM zone 19S"},
  {coordinate_system:"EPSG:5877", label: "5877:SAD69(96) / UTM zone 20S"},
  {coordinate_system:"EPSG:5879", label: "5879:Cadastre 1997 / UTM zone 38S"},
  {coordinate_system:"EPSG:5880", label: "5880:SIRGAS 2000 / Brazil Polyconic"},
  {coordinate_system:"EPSG:5887", label: "5887:TGD2005 / Tonga Map Grid"},
  {coordinate_system:"EPSG:5896", label: "5896:VN-2000 / TM-3 zone 481"},
  {coordinate_system:"EPSG:5897", label: "5897:VN-2000 / TM-3 zone 482"},
  {coordinate_system:"EPSG:5897", label: "5897:VN-2000 / TM-3 zone 482"},
  {coordinate_system:"EPSG:5898", label: "5898:VN-2000 / TM-3 zone 491"},
  {coordinate_system:"EPSG:5898", label: "5898:VN-2000 / TM-3 zone 491"},
  {coordinate_system:"EPSG:5899", label: "5899:VN-2000 / TM-3 107-45"},
  {coordinate_system:"EPSG:5921", label: "5921:WGS 84 / EPSG Arctic Regional zone A1"},
  {coordinate_system:"EPSG:5922", label: "5922:WGS 84 / EPSG Arctic Regional zone A2"},
  {coordinate_system:"EPSG:5923", label: "5923:WGS 84 / EPSG Arctic Regional zone A3"},
  {coordinate_system:"EPSG:5924", label: "5924:WGS 84 / EPSG Arctic Regional zone A4"},
  {coordinate_system:"EPSG:5925", label: "5925:WGS 84 / EPSG Arctic Regional zone A5"},
  {coordinate_system:"EPSG:5926", label: "5926:WGS 84 / EPSG Arctic Regional zone B1"},
  {coordinate_system:"EPSG:5927", label: "5927:WGS 84 / EPSG Arctic Regional zone B2"},
  {coordinate_system:"EPSG:5928", label: "5928:WGS 84 / EPSG Arctic Regional zone B3"},
  {coordinate_system:"EPSG:5929", label: "5929:WGS 84 / EPSG Arctic Regional zone B4"},
  {coordinate_system:"EPSG:5930", label: "5930:WGS 84 / EPSG Arctic Regional zone B5"},
  {coordinate_system:"EPSG:5931", label: "5931:WGS 84 / EPSG Arctic Regional zone C1"},
  {coordinate_system:"EPSG:5932", label: "5932:WGS 84 / EPSG Arctic Regional zone C2"},
  {coordinate_system:"EPSG:5933", label: "5933:WGS 84 / EPSG Arctic Regional zone C3"},
  {coordinate_system:"EPSG:5934", label: "5934:WGS 84 / EPSG Arctic Regional zone C4"},
  {coordinate_system:"EPSG:5935", label: "5935:WGS 84 / EPSG Arctic Regional zone C5"},
  {coordinate_system:"EPSG:5936", label: "5936:WGS 84 / EPSG Alaska Polar Stereographic"},
  {coordinate_system:"EPSG:5937", label: "5937:WGS 84 / EPSG Canada Polar Stereographic"},
  {coordinate_system:"EPSG:5938", label: "5938:WGS 84 / EPSG Greenland Polar Stereographic"},
  {coordinate_system:"EPSG:5939", label: "5939:WGS 84 / EPSG Norway Polar Stereographic"},
  {coordinate_system:"EPSG:5940", label: "5940:WGS 84 / EPSG Russia Polar Stereographic"},
  {coordinate_system:"EPSG:6050", label: "6050:GR96 / EPSG Arctic zone 1-25"},
  {coordinate_system:"EPSG:6051", label: "6051:GR96 / EPSG Arctic zone 2-18"},
  {coordinate_system:"EPSG:6052", label: "6052:GR96 / EPSG Arctic zone 2-20"},
  {coordinate_system:"EPSG:6053", label: "6053:GR96 / EPSG Arctic zone 3-29"},
  {coordinate_system:"EPSG:6054", label: "6054:GR96 / EPSG Arctic zone 3-31"},
  {coordinate_system:"EPSG:6055", label: "6055:GR96 / EPSG Arctic zone 3-33"},
  {coordinate_system:"EPSG:6056", label: "6056:GR96 / EPSG Arctic zone 4-20"},
  {coordinate_system:"EPSG:6057", label: "6057:GR96 / EPSG Arctic zone 4-22"},
  {coordinate_system:"EPSG:6058", label: "6058:GR96 / EPSG Arctic zone 4-24"},
  {coordinate_system:"EPSG:6059", label: "6059:GR96 / EPSG Arctic zone 5-41"},
  {coordinate_system:"EPSG:6060", label: "6060:GR96 / EPSG Arctic zone 5-43"},
  {coordinate_system:"EPSG:6061", label: "6061:GR96 / EPSG Arctic zone 5-45"},
  {coordinate_system:"EPSG:6062", label: "6062:GR96 / EPSG Arctic zone 6-26"},
  {coordinate_system:"EPSG:6063", label: "6063:GR96 / EPSG Arctic zone 6-28"},
  {coordinate_system:"EPSG:6064", label: "6064:GR96 / EPSG Arctic zone 6-30"},
  {coordinate_system:"EPSG:6065", label: "6065:GR96 / EPSG Arctic zone 7-11"},
  {coordinate_system:"EPSG:6066", label: "6066:GR96 / EPSG Arctic zone 7-13"},
  {coordinate_system:"EPSG:6067", label: "6067:GR96 / EPSG Arctic zone 8-20"},
  {coordinate_system:"EPSG:6068", label: "6068:GR96 / EPSG Arctic zone 8-22"},
  {coordinate_system:"EPSG:6069", label: "6069:ETRS89 / EPSG Arctic zone 2-22"},
  {coordinate_system:"EPSG:6070", label: "6070:ETRS89 / EPSG Arctic zone 3-11"},
  {coordinate_system:"EPSG:6071", label: "6071:ETRS89 / EPSG Arctic zone 4-26"},
  {coordinate_system:"EPSG:6072", label: "6072:ETRS89 / EPSG Arctic zone 4-28"},
  {coordinate_system:"EPSG:6073", label: "6073:ETRS89 / EPSG Arctic zone 5-11"},
  {coordinate_system:"EPSG:6074", label: "6074:ETRS89 / EPSG Arctic zone 5-13"},
  {coordinate_system:"EPSG:6075", label: "6075:WGS 84 / EPSG Arctic zone 2-24"},
  {coordinate_system:"EPSG:6076", label: "6076:WGS 84 / EPSG Arctic zone 2-26"},
  {coordinate_system:"EPSG:6077", label: "6077:WGS 84 / EPSG Arctic zone 3-13"},
  {coordinate_system:"EPSG:6078", label: "6078:WGS 84 / EPSG Arctic zone 3-15"},
  {coordinate_system:"EPSG:6079", label: "6079:WGS 84 / EPSG Arctic zone 3-17"},
  {coordinate_system:"EPSG:6080", label: "6080:WGS 84 / EPSG Arctic zone 3-19"},
  {coordinate_system:"EPSG:6081", label: "6081:WGS 84 / EPSG Arctic zone 4-30"},
  {coordinate_system:"EPSG:6082", label: "6082:WGS 84 / EPSG Arctic zone 4-32"},
  {coordinate_system:"EPSG:6083", label: "6083:WGS 84 / EPSG Arctic zone 4-34"},
  {coordinate_system:"EPSG:6084", label: "6084:WGS 84 / EPSG Arctic zone 4-36"},
  {coordinate_system:"EPSG:6085", label: "6085:WGS 84 / EPSG Arctic zone 4-38"},
  {coordinate_system:"EPSG:6086", label: "6086:WGS 84 / EPSG Arctic zone 4-40"},
  {coordinate_system:"EPSG:6087", label: "6087:WGS 84 / EPSG Arctic zone 5-15"},
  {coordinate_system:"EPSG:6088", label: "6088:WGS 84 / EPSG Arctic zone 5-17"},
  {coordinate_system:"EPSG:6089", label: "6089:WGS 84 / EPSG Arctic zone 5-19"},
  {coordinate_system:"EPSG:6090", label: "6090:WGS 84 / EPSG Arctic zone 5-21"},
  {coordinate_system:"EPSG:6091", label: "6091:WGS 84 / EPSG Arctic zone 5-23"},
  {coordinate_system:"EPSG:6092", label: "6092:WGS 84 / EPSG Arctic zone 5-25"},
  {coordinate_system:"EPSG:6093", label: "6093:WGS 84 / EPSG Arctic zone 5-27"},
  {coordinate_system:"EPSG:6094", label: "6094:NAD83(NSRS2007) / EPSG Arctic zone 5-29"},
  {coordinate_system:"EPSG:6095", label: "6095:NAD83(NSRS2007) / EPSG Arctic zone 5-31"},
  {coordinate_system:"EPSG:6096", label: "6096:NAD83(NSRS2007) / EPSG Arctic zone 6-14"},
  {coordinate_system:"EPSG:6097", label: "6097:NAD83(NSRS2007) / EPSG Arctic zone 6-16"},
  {coordinate_system:"EPSG:6098", label: "6098:NAD83(CSRS) / EPSG Arctic zone 1-23"},
  {coordinate_system:"EPSG:6099", label: "6099:NAD83(CSRS) / EPSG Arctic zone 2-14"},
  {coordinate_system:"EPSG:6100", label: "6100:NAD83(CSRS) / EPSG Arctic zone 2-16"},
  {coordinate_system:"EPSG:6101", label: "6101:NAD83(CSRS) / EPSG Arctic zone 3-25"},
  {coordinate_system:"EPSG:6102", label: "6102:NAD83(CSRS) / EPSG Arctic zone 3-27"},
  {coordinate_system:"EPSG:6103", label: "6103:NAD83(CSRS) / EPSG Arctic zone 3-29"},
  {coordinate_system:"EPSG:6104", label: "6104:NAD83(CSRS) / EPSG Arctic zone 4-14"},
  {coordinate_system:"EPSG:6105", label: "6105:NAD83(CSRS) / EPSG Arctic zone 4-16"},
  {coordinate_system:"EPSG:6106", label: "6106:NAD83(CSRS) / EPSG Arctic zone 4-18"},
  {coordinate_system:"EPSG:6107", label: "6107:NAD83(CSRS) / EPSG Arctic zone 5-33"},
  {coordinate_system:"EPSG:6108", label: "6108:NAD83(CSRS) / EPSG Arctic zone 5-35"},
  {coordinate_system:"EPSG:6109", label: "6109:NAD83(CSRS) / EPSG Arctic zone 5-37"},
  {coordinate_system:"EPSG:6110", label: "6110:NAD83(CSRS) / EPSG Arctic zone 5-39"},
  {coordinate_system:"EPSG:6111", label: "6111:NAD83(CSRS) / EPSG Arctic zone 6-18"},
  {coordinate_system:"EPSG:6112", label: "6112:NAD83(CSRS) / EPSG Arctic zone 6-20"},
  {coordinate_system:"EPSG:6113", label: "6113:NAD83(CSRS) / EPSG Arctic zone 6-22"},
  {coordinate_system:"EPSG:6114", label: "6114:NAD83(CSRS) / EPSG Arctic zone 6-24"},
  {coordinate_system:"EPSG:6115", label: "6115:WGS 84 / EPSG Arctic zone 1-27"},
  {coordinate_system:"EPSG:6116", label: "6116:WGS 84 / EPSG Arctic zone 1-29"},
  {coordinate_system:"EPSG:6117", label: "6117:WGS 84 / EPSG Arctic zone 1-31"},
  {coordinate_system:"EPSG:6118", label: "6118:WGS 84 / EPSG Arctic zone 1-21"},
  {coordinate_system:"EPSG:6119", label: "6119:WGS 84 / EPSG Arctic zone 2-28"},
  {coordinate_system:"EPSG:6120", label: "6120:WGS 84 / EPSG Arctic zone 2-10"},
  {coordinate_system:"EPSG:6121", label: "6121:WGS 84 / EPSG Arctic zone 2-12"},
  {coordinate_system:"EPSG:6122", label: "6122:WGS 84 / EPSG Arctic zone 3-21"},
  {coordinate_system:"EPSG:6123", label: "6123:WGS 84 / EPSG Arctic zone 3-23"},
  {coordinate_system:"EPSG:6124", label: "6124:WGS 84 / EPSG Arctic zone 4-12"},
  {coordinate_system:"EPSG:6125", label: "6125:ETRS89 / EPSG Arctic zone 5-47"},
  {coordinate_system:"EPSG:6128", label: "6128:Grand Cayman National Grid 1959"},
  {coordinate_system:"EPSG:6129", label: "6129:Sister Islands National Grid 1961"},
  {coordinate_system:"EPSG:6201", label: "6201:NAD27 / Michigan Central"},
  {coordinate_system:"EPSG:6202", label: "6202:NAD27 / Michigan South"},
  {coordinate_system:"EPSG:6204", label: "6204:Macedonia State Coordinate System"},
  {coordinate_system:"EPSG:6210", label: "6210:SIRGAS 2000 / UTM zone 23N"},
  {coordinate_system:"EPSG:6211", label: "6211:SIRGAS 2000 / UTM zone 24N"},
  {coordinate_system:"EPSG:6244", label: "6244:MAGNA-SIRGAS / Arauca urban grid"},
  {coordinate_system:"EPSG:6245", label: "6245:MAGNA-SIRGAS / Armenia urban grid"},
  {coordinate_system:"EPSG:6246", label: "6246:MAGNA-SIRGAS / Barranquilla urban grid"},
  {coordinate_system:"EPSG:6247", label: "6247:MAGNA-SIRGAS / Bogota urban grid"},
  {coordinate_system:"EPSG:6248", label: "6248:MAGNA-SIRGAS / Bucaramanga urban grid"},
  {coordinate_system:"EPSG:6249", label: "6249:MAGNA-SIRGAS / Cali urban grid"},
  {coordinate_system:"EPSG:6250", label: "6250:MAGNA-SIRGAS / Cartagena urban grid"},
  {coordinate_system:"EPSG:6251", label: "6251:MAGNA-SIRGAS / Cucuta urban grid"},
  {coordinate_system:"EPSG:6252", label: "6252:MAGNA-SIRGAS / Florencia urban grid"},
  {coordinate_system:"EPSG:6253", label: "6253:MAGNA-SIRGAS / Ibague urban grid"},
  {coordinate_system:"EPSG:6254", label: "6254:MAGNA-SIRGAS / Inirida urban grid"},
  {coordinate_system:"EPSG:6255", label: "6255:MAGNA-SIRGAS / Leticia urban grid"},
  {coordinate_system:"EPSG:6256", label: "6256:MAGNA-SIRGAS / Manizales urban grid"},
  {coordinate_system:"EPSG:6257", label: "6257:MAGNA-SIRGAS / Medellin urban grid"},
  {coordinate_system:"EPSG:6258", label: "6258:MAGNA-SIRGAS / Mitu urban grid"},
  {coordinate_system:"EPSG:6259", label: "6259:MAGNA-SIRGAS / Mocoa urban grid"},
  {coordinate_system:"EPSG:6260", label: "6260:MAGNA-SIRGAS / Monteria urban grid"},
  {coordinate_system:"EPSG:6261", label: "6261:MAGNA-SIRGAS / Neiva urban grid"},
  {coordinate_system:"EPSG:6262", label: "6262:MAGNA-SIRGAS / Pasto urban grid"},
  {coordinate_system:"EPSG:6263", label: "6263:MAGNA-SIRGAS / Pereira urban grid"},
  {coordinate_system:"EPSG:6264", label: "6264:MAGNA-SIRGAS / Popayan urban grid"},
  {coordinate_system:"EPSG:6265", label: "6265:MAGNA-SIRGAS / Puerto Carreno urban grid"},
  {coordinate_system:"EPSG:6266", label: "6266:MAGNA-SIRGAS / Quibdo urban grid"},
  {coordinate_system:"EPSG:6267", label: "6267:MAGNA-SIRGAS / Riohacha urban grid"},
  {coordinate_system:"EPSG:6268", label: "6268:MAGNA-SIRGAS / San Andres urban grid"},
  {coordinate_system:"EPSG:6269", label: "6269:MAGNA-SIRGAS / San Jose del Guaviare urban grid"},
  {coordinate_system:"EPSG:6270", label: "6270:MAGNA-SIRGAS / Santa Marta urban grid"},
  {coordinate_system:"EPSG:6271", label: "6271:MAGNA-SIRGAS / Sucre urban grid"},
  {coordinate_system:"EPSG:6272", label: "6272:MAGNA-SIRGAS / Tunja urban grid"},
  {coordinate_system:"EPSG:6273", label: "6273:MAGNA-SIRGAS / Valledupar urban grid"},
  {coordinate_system:"EPSG:6274", label: "6274:MAGNA-SIRGAS / Villavicencio urban grid"},
  {coordinate_system:"EPSG:6275", label: "6275:MAGNA-SIRGAS / Yopal urban grid"},
  {coordinate_system:"EPSG:6307", label: "6307:NAD83(CORS96) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:6312", label: "6312:CGRS93 / Cyprus Local Transverse Mercator"},
  {coordinate_system:"EPSG:6316", label: "6316:MGI 1901 / Balkans zone 7"},
  {coordinate_system:"EPSG:6316", label: "6316:MGI 1901 / Balkans zone 7"},
  {coordinate_system:"EPSG:6328", label: "6328:NAD83(2011) / UTM zone 59N"},
  {coordinate_system:"EPSG:6329", label: "6329:NAD83(2011) / UTM zone 60N"},
  {coordinate_system:"EPSG:6330", label: "6330:NAD83(2011) / UTM zone 1N"},
  {coordinate_system:"EPSG:6331", label: "6331:NAD83(2011) / UTM zone 2N"},
  {coordinate_system:"EPSG:6332", label: "6332:NAD83(2011) / UTM zone 3N"},
  {coordinate_system:"EPSG:6333", label: "6333:NAD83(2011) / UTM zone 4N"},
  {coordinate_system:"EPSG:6334", label: "6334:NAD83(2011) / UTM zone 5N"},
  {coordinate_system:"EPSG:6335", label: "6335:NAD83(2011) / UTM zone 6N"},
  {coordinate_system:"EPSG:6336", label: "6336:NAD83(2011) / UTM zone 7N"},
  {coordinate_system:"EPSG:6337", label: "6337:NAD83(2011) / UTM zone 8N"},
  {coordinate_system:"EPSG:6338", label: "6338:NAD83(2011) / UTM zone 9N"},
  {coordinate_system:"EPSG:6339", label: "6339:NAD83(2011) / UTM zone 10N"},
  {coordinate_system:"EPSG:6340", label: "6340:NAD83(2011) / UTM zone 11N"},
  {coordinate_system:"EPSG:6341", label: "6341:NAD83(2011) / UTM zone 12N"},
  {coordinate_system:"EPSG:6342", label: "6342:NAD83(2011) / UTM zone 13N"},
  {coordinate_system:"EPSG:6343", label: "6343:NAD83(2011) / UTM zone 14N"},
  {coordinate_system:"EPSG:6344", label: "6344:NAD83(2011) / UTM zone 15N"},
  {coordinate_system:"EPSG:6345", label: "6345:NAD83(2011) / UTM zone 16N"},
  {coordinate_system:"EPSG:6346", label: "6346:NAD83(2011) / UTM zone 17N"},
  {coordinate_system:"EPSG:6347", label: "6347:NAD83(2011) / UTM zone 18N"},
  {coordinate_system:"EPSG:6348", label: "6348:NAD83(2011) / UTM zone 19N"},
  {coordinate_system:"EPSG:6350", label: "6350:NAD83(2011) / Conus Albers"},
  {coordinate_system:"EPSG:6351", label: "6351:NAD83(2011) / EPSG Arctic zone 5-29"},
  {coordinate_system:"EPSG:6352", label: "6352:NAD83(2011) / EPSG Arctic zone 5-31"},
  {coordinate_system:"EPSG:6353", label: "6353:NAD83(2011) / EPSG Arctic zone 6-14"},
  {coordinate_system:"EPSG:6354", label: "6354:NAD83(2011) / EPSG Arctic zone 6-16"},
  {coordinate_system:"EPSG:6355", label: "6355:NAD83(2011) / Alabama East"},
  {coordinate_system:"EPSG:6356", label: "6356:NAD83(2011) / Alabama West"},
  {coordinate_system:"EPSG:6362", label: "6362:Mexico ITRF92 / LCC"},
  {coordinate_system:"EPSG:6366", label: "6366:Mexico ITRF2008 / UTM zone 11N"},
  {coordinate_system:"EPSG:6367", label: "6367:Mexico ITRF2008 / UTM zone 12N"},
  {coordinate_system:"EPSG:6368", label: "6368:Mexico ITRF2008 / UTM zone 13N"},
  {coordinate_system:"EPSG:6369", label: "6369:Mexico ITRF2008 / UTM zone 14N"},
  {coordinate_system:"EPSG:6370", label: "6370:Mexico ITRF2008 / UTM zone 15N"},
  {coordinate_system:"EPSG:6371", label: "6371:Mexico ITRF2008 / UTM zone 16N"},
  {coordinate_system:"EPSG:6372", label: "6372:Mexico ITRF2008 / LCC"},
  {coordinate_system:"EPSG:6381", label: "6381:UCS-2000 / Ukraine TM zone 7"},
  {coordinate_system:"EPSG:6382", label: "6382:UCS-2000 / Ukraine TM zone 8"},
  {coordinate_system:"EPSG:6383", label: "6383:UCS-2000 / Ukraine TM zone 9"},
  {coordinate_system:"EPSG:6384", label: "6384:UCS-2000 / Ukraine TM zone 10"},
  {coordinate_system:"EPSG:6385", label: "6385:UCS-2000 / Ukraine TM zone 11"},
  {coordinate_system:"EPSG:6386", label: "6386:UCS-2000 / Ukraine TM zone 12"},
  {coordinate_system:"EPSG:6387", label: "6387:UCS-2000 / Ukraine TM zone 13"},
  {coordinate_system:"EPSG:6391", label: "6391:Cayman Islands National Grid 2011"},
  {coordinate_system:"EPSG:6393", label: "6393:NAD83(2011) / Alaska Albers"},
  {coordinate_system:"EPSG:6394", label: "6394:NAD83(2011) / Alaska zone 1"},
  {coordinate_system:"EPSG:6395", label: "6395:NAD83(2011) / Alaska zone 2"},
  {coordinate_system:"EPSG:6396", label: "6396:NAD83(2011) / Alaska zone 3"},
  {coordinate_system:"EPSG:6397", label: "6397:NAD83(2011) / Alaska zone 4"},
  {coordinate_system:"EPSG:6398", label: "6398:NAD83(2011) / Alaska zone 5"},
  {coordinate_system:"EPSG:6399", label: "6399:NAD83(2011) / Alaska zone 6"},
  {coordinate_system:"EPSG:6400", label: "6400:NAD83(2011) / Alaska zone 7"},
  {coordinate_system:"EPSG:6401", label: "6401:NAD83(2011) / Alaska zone 8"},
  {coordinate_system:"EPSG:6402", label: "6402:NAD83(2011) / Alaska zone 9"},
  {coordinate_system:"EPSG:6403", label: "6403:NAD83(2011) / Alaska zone 10"},
  {coordinate_system:"EPSG:6404", label: "6404:NAD83(2011) / Arizona Central"},
  {coordinate_system:"EPSG:6405", label: "6405:NAD83(2011) / Arizona Central (ft)"},
  {coordinate_system:"EPSG:6406", label: "6406:NAD83(2011) / Arizona East"},
  {coordinate_system:"EPSG:6407", label: "6407:NAD83(2011) / Arizona East (ft)"},
  {coordinate_system:"EPSG:6408", label: "6408:NAD83(2011) / Arizona West"},
  {coordinate_system:"EPSG:6409", label: "6409:NAD83(2011) / Arizona West (ft)"},
  {coordinate_system:"EPSG:6410", label: "6410:NAD83(2011) / Arkansas North"},
  {coordinate_system:"EPSG:6411", label: "6411:NAD83(2011) / Arkansas North (ftUS)"},
  {coordinate_system:"EPSG:6412", label: "6412:NAD83(2011) / Arkansas South"},
  {coordinate_system:"EPSG:6413", label: "6413:NAD83(2011) / Arkansas South (ftUS)"},
  {coordinate_system:"EPSG:6414", label: "6414:NAD83(2011) / California Albers"},
  {coordinate_system:"EPSG:6415", label: "6415:NAD83(2011) / California zone 1"},
  {coordinate_system:"EPSG:6416", label: "6416:NAD83(2011) / California zone 1 (ftUS)"},
  {coordinate_system:"EPSG:6417", label: "6417:NAD83(2011) / California zone 2"},
  {coordinate_system:"EPSG:6418", label: "6418:NAD83(2011) / California zone 2 (ftUS)"},
  {coordinate_system:"EPSG:6419", label: "6419:NAD83(2011) / California zone 3"},
  {coordinate_system:"EPSG:6420", label: "6420:NAD83(2011) / California zone 3 (ftUS)"},
  {coordinate_system:"EPSG:6421", label: "6421:NAD83(2011) / California zone 4"},
  {coordinate_system:"EPSG:6422", label: "6422:NAD83(2011) / California zone 4 (ftUS)"},
  {coordinate_system:"EPSG:6423", label: "6423:NAD83(2011) / California zone 5"},
  {coordinate_system:"EPSG:6424", label: "6424:NAD83(2011) / California zone 5 (ftUS)"},
  {coordinate_system:"EPSG:6425", label: "6425:NAD83(2011) / California zone 6"},
  {coordinate_system:"EPSG:6426", label: "6426:NAD83(2011) / California zone 6 (ftUS)"},
  {coordinate_system:"EPSG:6427", label: "6427:NAD83(2011) / Colorado Central"},
  {coordinate_system:"EPSG:6428", label: "6428:NAD83(2011) / Colorado Central (ftUS)"},
  {coordinate_system:"EPSG:6429", label: "6429:NAD83(2011) / Colorado North"},
  {coordinate_system:"EPSG:6430", label: "6430:NAD83(2011) / Colorado North (ftUS)"},
  {coordinate_system:"EPSG:6431", label: "6431:NAD83(2011) / Colorado South"},
  {coordinate_system:"EPSG:6432", label: "6432:NAD83(2011) / Colorado South (ftUS)"},
  {coordinate_system:"EPSG:6433", label: "6433:NAD83(2011) / Connecticut"},
  {coordinate_system:"EPSG:6434", label: "6434:NAD83(2011) / Connecticut (ftUS)"},
  {coordinate_system:"EPSG:6435", label: "6435:NAD83(2011) / Delaware"},
  {coordinate_system:"EPSG:6436", label: "6436:NAD83(2011) / Delaware (ftUS)"},
  {coordinate_system:"EPSG:6437", label: "6437:NAD83(2011) / Florida East"},
  {coordinate_system:"EPSG:6438", label: "6438:NAD83(2011) / Florida East (ftUS)"},
  {coordinate_system:"EPSG:6439", label: "6439:NAD83(2011) / Florida GDL Albers"},
  {coordinate_system:"EPSG:6440", label: "6440:NAD83(2011) / Florida North"},
  {coordinate_system:"EPSG:6441", label: "6441:NAD83(2011) / Florida North (ftUS)"},
  {coordinate_system:"EPSG:6442", label: "6442:NAD83(2011) / Florida West"},
  {coordinate_system:"EPSG:6443", label: "6443:NAD83(2011) / Florida West (ftUS)"},
  {coordinate_system:"EPSG:6444", label: "6444:NAD83(2011) / Georgia East"},
  {coordinate_system:"EPSG:6445", label: "6445:NAD83(2011) / Georgia East (ftUS)"},
  {coordinate_system:"EPSG:6446", label: "6446:NAD83(2011) / Georgia West"},
  {coordinate_system:"EPSG:6447", label: "6447:NAD83(2011) / Georgia West (ftUS)"},
  {coordinate_system:"EPSG:6448", label: "6448:NAD83(2011) / Idaho Central"},
  {coordinate_system:"EPSG:6449", label: "6449:NAD83(2011) / Idaho Central (ftUS)"},
  {coordinate_system:"EPSG:6450", label: "6450:NAD83(2011) / Idaho East"},
  {coordinate_system:"EPSG:6451", label: "6451:NAD83(2011) / Idaho East (ftUS)"},
  {coordinate_system:"EPSG:6452", label: "6452:NAD83(2011) / Idaho West"},
  {coordinate_system:"EPSG:6453", label: "6453:NAD83(2011) / Idaho West (ftUS)"},
  {coordinate_system:"EPSG:6454", label: "6454:NAD83(2011) / Illinois East"},
  {coordinate_system:"EPSG:6455", label: "6455:NAD83(2011) / Illinois East (ftUS)"},
  {coordinate_system:"EPSG:6456", label: "6456:NAD83(2011) / Illinois West"},
  {coordinate_system:"EPSG:6457", label: "6457:NAD83(2011) / Illinois West (ftUS)"},
  {coordinate_system:"EPSG:6458", label: "6458:NAD83(2011) / Indiana East"},
  {coordinate_system:"EPSG:6459", label: "6459:NAD83(2011) / Indiana East (ftUS)"},
  {coordinate_system:"EPSG:6460", label: "6460:NAD83(2011) / Indiana West"},
  {coordinate_system:"EPSG:6461", label: "6461:NAD83(2011) / Indiana West (ftUS)"},
  {coordinate_system:"EPSG:6462", label: "6462:NAD83(2011) / Iowa North"},
  {coordinate_system:"EPSG:6463", label: "6463:NAD83(2011) / Iowa North (ftUS)"},
  {coordinate_system:"EPSG:6464", label: "6464:NAD83(2011) / Iowa South"},
  {coordinate_system:"EPSG:6465", label: "6465:NAD83(2011) / Iowa South (ftUS)"},
  {coordinate_system:"EPSG:6466", label: "6466:NAD83(2011) / Kansas North"},
  {coordinate_system:"EPSG:6467", label: "6467:NAD83(2011) / Kansas North (ftUS)"},
  {coordinate_system:"EPSG:6468", label: "6468:NAD83(2011) / Kansas South"},
  {coordinate_system:"EPSG:6469", label: "6469:NAD83(2011) / Kansas South (ftUS)"},
  {coordinate_system:"EPSG:6470", label: "6470:NAD83(2011) / Kentucky North"},
  {coordinate_system:"EPSG:6471", label: "6471:NAD83(2011) / Kentucky North (ftUS)"},
  {coordinate_system:"EPSG:6472", label: "6472:NAD83(2011) / Kentucky Single Zone"},
  {coordinate_system:"EPSG:6473", label: "6473:NAD83(2011) / Kentucky Single Zone (ftUS)"},
  {coordinate_system:"EPSG:6474", label: "6474:NAD83(2011) / Kentucky South"},
  {coordinate_system:"EPSG:6475", label: "6475:NAD83(2011) / Kentucky South (ftUS)"},
  {coordinate_system:"EPSG:6476", label: "6476:NAD83(2011) / Louisiana North"},
  {coordinate_system:"EPSG:6477", label: "6477:NAD83(2011) / Louisiana North (ftUS)"},
  {coordinate_system:"EPSG:6478", label: "6478:NAD83(2011) / Louisiana South"},
  {coordinate_system:"EPSG:6479", label: "6479:NAD83(2011) / Louisiana South (ftUS)"},
  {coordinate_system:"EPSG:6480", label: "6480:NAD83(2011) / Maine CS2000 Central"},
  {coordinate_system:"EPSG:6481", label: "6481:NAD83(2011) / Maine CS2000 East"},
  {coordinate_system:"EPSG:6482", label: "6482:NAD83(2011) / Maine CS2000 West"},
  {coordinate_system:"EPSG:6483", label: "6483:NAD83(2011) / Maine East"},
  {coordinate_system:"EPSG:6484", label: "6484:NAD83(2011) / Maine East (ftUS)"},
  {coordinate_system:"EPSG:6485", label: "6485:NAD83(2011) / Maine West"},
  {coordinate_system:"EPSG:6486", label: "6486:NAD83(2011) / Maine West (ftUS)"},
  {coordinate_system:"EPSG:6487", label: "6487:NAD83(2011) / Maryland"},
  {coordinate_system:"EPSG:6488", label: "6488:NAD83(2011) / Maryland (ftUS)"},
  {coordinate_system:"EPSG:6489", label: "6489:NAD83(2011) / Massachusetts Island"},
  {coordinate_system:"EPSG:6490", label: "6490:NAD83(2011) / Massachusetts Island (ftUS)"},
  {coordinate_system:"EPSG:6491", label: "6491:NAD83(2011) / Massachusetts Mainland"},
  {coordinate_system:"EPSG:6492", label: "6492:NAD83(2011) / Massachusetts Mainland (ftUS)"},
  {coordinate_system:"EPSG:6493", label: "6493:NAD83(2011) / Michigan Central"},
  {coordinate_system:"EPSG:6494", label: "6494:NAD83(2011) / Michigan Central (ft)"},
  {coordinate_system:"EPSG:6495", label: "6495:NAD83(2011) / Michigan North"},
  {coordinate_system:"EPSG:6496", label: "6496:NAD83(2011) / Michigan North (ft)"},
  {coordinate_system:"EPSG:6497", label: "6497:NAD83(2011) / Michigan Oblique Mercator"},
  {coordinate_system:"EPSG:6498", label: "6498:NAD83(2011) / Michigan South"},
  {coordinate_system:"EPSG:6499", label: "6499:NAD83(2011) / Michigan South (ft)"},
  {coordinate_system:"EPSG:6500", label: "6500:NAD83(2011) / Minnesota Central"},
  {coordinate_system:"EPSG:6501", label: "6501:NAD83(2011) / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:6502", label: "6502:NAD83(2011) / Minnesota North"},
  {coordinate_system:"EPSG:6503", label: "6503:NAD83(2011) / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:6504", label: "6504:NAD83(2011) / Minnesota South"},
  {coordinate_system:"EPSG:6505", label: "6505:NAD83(2011) / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:6506", label: "6506:NAD83(2011) / Mississippi East"},
  {coordinate_system:"EPSG:6507", label: "6507:NAD83(2011) / Mississippi East (ftUS)"},
  {coordinate_system:"EPSG:6508", label: "6508:NAD83(2011) / Mississippi TM"},
  {coordinate_system:"EPSG:6509", label: "6509:NAD83(2011) / Mississippi West"},
  {coordinate_system:"EPSG:6510", label: "6510:NAD83(2011) / Mississippi West (ftUS)"},
  {coordinate_system:"EPSG:6511", label: "6511:NAD83(2011) / Missouri Central"},
  {coordinate_system:"EPSG:6512", label: "6512:NAD83(2011) / Missouri East"},
  {coordinate_system:"EPSG:6513", label: "6513:NAD83(2011) / Missouri West"},
  {coordinate_system:"EPSG:6514", label: "6514:NAD83(2011) / Montana"},
  {coordinate_system:"EPSG:6515", label: "6515:NAD83(2011) / Montana (ft)"},
  {coordinate_system:"EPSG:6516", label: "6516:NAD83(2011) / Nebraska"},
  {coordinate_system:"EPSG:6518", label: "6518:NAD83(2011) / Nevada Central"},
  {coordinate_system:"EPSG:6519", label: "6519:NAD83(2011) / Nevada Central (ftUS)"},
  {coordinate_system:"EPSG:6520", label: "6520:NAD83(2011) / Nevada East"},
  {coordinate_system:"EPSG:6521", label: "6521:NAD83(2011) / Nevada East (ftUS)"},
  {coordinate_system:"EPSG:6522", label: "6522:NAD83(2011) / Nevada West"},
  {coordinate_system:"EPSG:6523", label: "6523:NAD83(2011) / Nevada West (ftUS)"},
  {coordinate_system:"EPSG:6524", label: "6524:NAD83(2011) / New Hampshire"},
  {coordinate_system:"EPSG:6525", label: "6525:NAD83(2011) / New Hampshire (ftUS)"},
  {coordinate_system:"EPSG:6526", label: "6526:NAD83(2011) / New Jersey"},
  {coordinate_system:"EPSG:6527", label: "6527:NAD83(2011) / New Jersey (ftUS)"},
  {coordinate_system:"EPSG:6528", label: "6528:NAD83(2011) / New Mexico Central"},
  {coordinate_system:"EPSG:6529", label: "6529:NAD83(2011) / New Mexico Central (ftUS)"},
  {coordinate_system:"EPSG:6530", label: "6530:NAD83(2011) / New Mexico East"},
  {coordinate_system:"EPSG:6531", label: "6531:NAD83(2011) / New Mexico East (ftUS)"},
  {coordinate_system:"EPSG:6532", label: "6532:NAD83(2011) / New Mexico West"},
  {coordinate_system:"EPSG:6533", label: "6533:NAD83(2011) / New Mexico West (ftUS)"},
  {coordinate_system:"EPSG:6534", label: "6534:NAD83(2011) / New York Central"},
  {coordinate_system:"EPSG:6535", label: "6535:NAD83(2011) / New York Central (ftUS)"},
  {coordinate_system:"EPSG:6536", label: "6536:NAD83(2011) / New York East"},
  {coordinate_system:"EPSG:6537", label: "6537:NAD83(2011) / New York East (ftUS)"},
  {coordinate_system:"EPSG:6538", label: "6538:NAD83(2011) / New York Long Island"},
  {coordinate_system:"EPSG:6539", label: "6539:NAD83(2011) / New York Long Island (ftUS)"},
  {coordinate_system:"EPSG:6540", label: "6540:NAD83(2011) / New York West"},
  {coordinate_system:"EPSG:6541", label: "6541:NAD83(2011) / New York West (ftUS)"},
  {coordinate_system:"EPSG:6542", label: "6542:NAD83(2011) / North Carolina"},
  {coordinate_system:"EPSG:6543", label: "6543:NAD83(2011) / North Carolina (ftUS)"},
  {coordinate_system:"EPSG:6544", label: "6544:NAD83(2011) / North Dakota North"},
  {coordinate_system:"EPSG:6545", label: "6545:NAD83(2011) / North Dakota North (ft)"},
  {coordinate_system:"EPSG:6546", label: "6546:NAD83(2011) / North Dakota South"},
  {coordinate_system:"EPSG:6547", label: "6547:NAD83(2011) / North Dakota South (ft)"},
  {coordinate_system:"EPSG:6548", label: "6548:NAD83(2011) / Ohio North"},
  {coordinate_system:"EPSG:6549", label: "6549:NAD83(2011) / Ohio North (ftUS)"},
  {coordinate_system:"EPSG:6550", label: "6550:NAD83(2011) / Ohio South"},
  {coordinate_system:"EPSG:6551", label: "6551:NAD83(2011) / Ohio South (ftUS)"},
  {coordinate_system:"EPSG:6552", label: "6552:NAD83(2011) / Oklahoma North"},
  {coordinate_system:"EPSG:6553", label: "6553:NAD83(2011) / Oklahoma North (ftUS)"},
  {coordinate_system:"EPSG:6554", label: "6554:NAD83(2011) / Oklahoma South"},
  {coordinate_system:"EPSG:6555", label: "6555:NAD83(2011) / Oklahoma South (ftUS)"},
  {coordinate_system:"EPSG:6556", label: "6556:NAD83(2011) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:6557", label: "6557:NAD83(2011) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:6558", label: "6558:NAD83(2011) / Oregon North"},
  {coordinate_system:"EPSG:6559", label: "6559:NAD83(2011) / Oregon North (ft)"},
  {coordinate_system:"EPSG:6560", label: "6560:NAD83(2011) / Oregon South"},
  {coordinate_system:"EPSG:6561", label: "6561:NAD83(2011) / Oregon South (ft)"},
  {coordinate_system:"EPSG:6562", label: "6562:NAD83(2011) / Pennsylvania North"},
  {coordinate_system:"EPSG:6563", label: "6563:NAD83(2011) / Pennsylvania North (ftUS)"},
  {coordinate_system:"EPSG:6564", label: "6564:NAD83(2011) / Pennsylvania South"},
  {coordinate_system:"EPSG:6565", label: "6565:NAD83(2011) / Pennsylvania South (ftUS)"},
  {coordinate_system:"EPSG:6566", label: "6566:NAD83(2011) / Puerto Rico and Virgin Is."},
  {coordinate_system:"EPSG:6567", label: "6567:NAD83(2011) / Rhode Island"},
  {coordinate_system:"EPSG:6568", label: "6568:NAD83(2011) / Rhode Island (ftUS)"},
  {coordinate_system:"EPSG:6569", label: "6569:NAD83(2011) / South Carolina"},
  {coordinate_system:"EPSG:6570", label: "6570:NAD83(2011) / South Carolina (ft)"},
  {coordinate_system:"EPSG:6571", label: "6571:NAD83(2011) / South Dakota North"},
  {coordinate_system:"EPSG:6572", label: "6572:NAD83(2011) / South Dakota North (ftUS)"},
  {coordinate_system:"EPSG:6573", label: "6573:NAD83(2011) / South Dakota South"},
  {coordinate_system:"EPSG:6574", label: "6574:NAD83(2011) / South Dakota South (ftUS)"},
  {coordinate_system:"EPSG:6575", label: "6575:NAD83(2011) / Tennessee"},
  {coordinate_system:"EPSG:6576", label: "6576:NAD83(2011) / Tennessee (ftUS)"},
  {coordinate_system:"EPSG:6577", label: "6577:NAD83(2011) / Texas Central"},
  {coordinate_system:"EPSG:6578", label: "6578:NAD83(2011) / Texas Central (ftUS)"},
  {coordinate_system:"EPSG:6579", label: "6579:NAD83(2011) / Texas Centric Albers Equal Area"},
  {coordinate_system:"EPSG:6580", label: "6580:NAD83(2011) / Texas Centric Lambert Conformal"},
  {coordinate_system:"EPSG:6581", label: "6581:NAD83(2011) / Texas North"},
  {coordinate_system:"EPSG:6582", label: "6582:NAD83(2011) / Texas North (ftUS)"},
  {coordinate_system:"EPSG:6583", label: "6583:NAD83(2011) / Texas North Central"},
  {coordinate_system:"EPSG:6584", label: "6584:NAD83(2011) / Texas North Central (ftUS)"},
  {coordinate_system:"EPSG:6585", label: "6585:NAD83(2011) / Texas South"},
  {coordinate_system:"EPSG:6586", label: "6586:NAD83(2011) / Texas South (ftUS)"},
  {coordinate_system:"EPSG:6587", label: "6587:NAD83(2011) / Texas South Central"},
  {coordinate_system:"EPSG:6588", label: "6588:NAD83(2011) / Texas South Central (ftUS)"},
  {coordinate_system:"EPSG:6589", label: "6589:NAD83(2011) / Vermont"},
  {coordinate_system:"EPSG:6590", label: "6590:NAD83(2011) / Vermont (ftUS)"},
  {coordinate_system:"EPSG:6591", label: "6591:NAD83(2011) / Virginia Lambert"},
  {coordinate_system:"EPSG:6592", label: "6592:NAD83(2011) / Virginia North"},
  {coordinate_system:"EPSG:6593", label: "6593:NAD83(2011) / Virginia North (ftUS)"},
  {coordinate_system:"EPSG:6594", label: "6594:NAD83(2011) / Virginia South"},
  {coordinate_system:"EPSG:6595", label: "6595:NAD83(2011) / Virginia South (ftUS)"},
  {coordinate_system:"EPSG:6596", label: "6596:NAD83(2011) / Washington North"},
  {coordinate_system:"EPSG:6597", label: "6597:NAD83(2011) / Washington North (ftUS)"},
  {coordinate_system:"EPSG:6598", label: "6598:NAD83(2011) / Washington South"},
  {coordinate_system:"EPSG:6599", label: "6599:NAD83(2011) / Washington South (ftUS)"},
  {coordinate_system:"EPSG:6600", label: "6600:NAD83(2011) / West Virginia North"},
  {coordinate_system:"EPSG:6601", label: "6601:NAD83(2011) / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:6602", label: "6602:NAD83(2011) / West Virginia South"},
  {coordinate_system:"EPSG:6603", label: "6603:NAD83(2011) / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:6605", label: "6605:NAD83(2011) / Wisconsin Central (ftUS)"},
  {coordinate_system:"EPSG:6606", label: "6606:NAD83(2011) / Wisconsin North"},
  {coordinate_system:"EPSG:6607", label: "6607:NAD83(2011) / Wisconsin North (ftUS)"},
  {coordinate_system:"EPSG:6608", label: "6608:NAD83(2011) / Wisconsin South"},
  {coordinate_system:"EPSG:6609", label: "6609:NAD83(2011) / Wisconsin South (ftUS)"},
  {coordinate_system:"EPSG:6610", label: "6610:NAD83(2011) / Wisconsin Transverse Mercator"},
  {coordinate_system:"EPSG:6611", label: "6611:NAD83(2011) / Wyoming East"},
  {coordinate_system:"EPSG:6612", label: "6612:NAD83(2011) / Wyoming East (ftUS)"},
  {coordinate_system:"EPSG:6613", label: "6613:NAD83(2011) / Wyoming East Central"},
  {coordinate_system:"EPSG:6614", label: "6614:NAD83(2011) / Wyoming East Central (ftUS)"},
  {coordinate_system:"EPSG:6615", label: "6615:NAD83(2011) / Wyoming West"},
  {coordinate_system:"EPSG:6616", label: "6616:NAD83(2011) / Wyoming West (ftUS)"},
  {coordinate_system:"EPSG:6617", label: "6617:NAD83(2011) / Wyoming West Central"},
  {coordinate_system:"EPSG:6618", label: "6618:NAD83(2011) / Wyoming West Central (ftUS)"},
  {coordinate_system:"EPSG:6619", label: "6619:NAD83(2011) / Utah Central"},
  {coordinate_system:"EPSG:6620", label: "6620:NAD83(2011) / Utah North"},
  {coordinate_system:"EPSG:6621", label: "6621:NAD83(2011) / Utah South"},
  {coordinate_system:"EPSG:6622", label: "6622:NAD83(CSRS) / Quebec Lambert"},
  {coordinate_system:"EPSG:6623", label: "6623:NAD83 / Quebec Albers"},
  {coordinate_system:"EPSG:6624", label: "6624:NAD83(CSRS) / Quebec Albers"},
  {coordinate_system:"EPSG:6625", label: "6625:NAD83(2011) / Utah Central (ftUS)"},
  {coordinate_system:"EPSG:6626", label: "6626:NAD83(2011) / Utah North (ftUS)"},
  {coordinate_system:"EPSG:6627", label: "6627:NAD83(2011) / Utah South (ftUS)"},
  {coordinate_system:"EPSG:6628", label: "6628:NAD83(PA11) / Hawaii zone 1"},
  {coordinate_system:"EPSG:6629", label: "6629:NAD83(PA11) / Hawaii zone 2"},
  {coordinate_system:"EPSG:6630", label: "6630:NAD83(PA11) / Hawaii zone 3"},
  {coordinate_system:"EPSG:6631", label: "6631:NAD83(PA11) / Hawaii zone 4"},
  {coordinate_system:"EPSG:6632", label: "6632:NAD83(PA11) / Hawaii zone 5"},
  {coordinate_system:"EPSG:6633", label: "6633:NAD83(PA11) / Hawaii zone 3 (ftUS)"},
  {coordinate_system:"EPSG:6634", label: "6634:NAD83(PA11) / UTM zone 4N"},
  {coordinate_system:"EPSG:6635", label: "6635:NAD83(PA11) / UTM zone 5N"},
  {coordinate_system:"EPSG:6636", label: "6636:NAD83(PA11) / UTM zone 2S"},
  {coordinate_system:"EPSG:6637", label: "6637:NAD83(MA11) / Guam Map Grid"},
  {coordinate_system:"EPSG:6646", label: "6646:Karbala 1979 / Iraq National Grid"},
  {coordinate_system:"EPSG:6669", label: "6669:JGD2011 / Japan Plane Rectangular CS I"},
  {coordinate_system:"EPSG:6670", label: "6670:JGD2011 / Japan Plane Rectangular CS II"},
  {coordinate_system:"EPSG:6671", label: "6671:JGD2011 / Japan Plane Rectangular CS III"},
  {coordinate_system:"EPSG:6672", label: "6672:JGD2011 / Japan Plane Rectangular CS IV"},
  {coordinate_system:"EPSG:6673", label: "6673:JGD2011 / Japan Plane Rectangular CS V"},
  {coordinate_system:"EPSG:6674", label: "6674:JGD2011 / Japan Plane Rectangular CS VI"},
  {coordinate_system:"EPSG:6675", label: "6675:JGD2011 / Japan Plane Rectangular CS VII"},
  {coordinate_system:"EPSG:6676", label: "6676:JGD2011 / Japan Plane Rectangular CS VIII"},
  {coordinate_system:"EPSG:6677", label: "6677:JGD2011 / Japan Plane Rectangular CS IX"},
  {coordinate_system:"EPSG:6678", label: "6678:JGD2011 / Japan Plane Rectangular CS X"},
  {coordinate_system:"EPSG:6679", label: "6679:JGD2011 / Japan Plane Rectangular CS XI"},
  {coordinate_system:"EPSG:6680", label: "6680:JGD2011 / Japan Plane Rectangular CS XII"},
  {coordinate_system:"EPSG:6681", label: "6681:JGD2011 / Japan Plane Rectangular CS XIII"},
  {coordinate_system:"EPSG:6682", label: "6682:JGD2011 / Japan Plane Rectangular CS XIV"},
  {coordinate_system:"EPSG:6683", label: "6683:JGD2011 / Japan Plane Rectangular CS XV"},
  {coordinate_system:"EPSG:6684", label: "6684:JGD2011 / Japan Plane Rectangular CS XVI"},
  {coordinate_system:"EPSG:6685", label: "6685:JGD2011 / Japan Plane Rectangular CS XVII"},
  {coordinate_system:"EPSG:6686", label: "6686:JGD2011 / Japan Plane Rectangular CS XVIII"},
  {coordinate_system:"EPSG:6687", label: "6687:JGD2011 / Japan Plane Rectangular CS XIX"},
  {coordinate_system:"EPSG:6688", label: "6688:JGD2011 / UTM zone 51N"},
  {coordinate_system:"EPSG:6689", label: "6689:JGD2011 / UTM zone 52N"},
  {coordinate_system:"EPSG:6690", label: "6690:JGD2011 / UTM zone 53N"},
  {coordinate_system:"EPSG:6691", label: "6691:JGD2011 / UTM zone 54N"},
  {coordinate_system:"EPSG:6692", label: "6692:JGD2011 / UTM zone 55N"},
  {coordinate_system:"EPSG:6703", label: "6703:WGS 84 / TM 60 SW"},
  {coordinate_system:"EPSG:6707", label: "6707:RDN2008 / UTM zone 32N (N-E)"},
  {coordinate_system:"EPSG:6708", label: "6708:RDN2008 / UTM zone 33N (N-E)"},
  {coordinate_system:"EPSG:6709", label: "6709:RDN2008 / UTM zone 34N (N-E)"},
  {coordinate_system:"EPSG:6720", label: "6720:WGS 84 / CIG92"},
  {coordinate_system:"EPSG:6721", label: "6721:GDA94 / CIG94"},
  {coordinate_system:"EPSG:6722", label: "6722:WGS 84 / CKIG92"},
  {coordinate_system:"EPSG:6723", label: "6723:GDA94 / CKIG94"},
  {coordinate_system:"EPSG:6736", label: "6736:GDA94 / MGA zone 46"},
  {coordinate_system:"EPSG:6737", label: "6737:GDA94 / MGA zone 47"},
  {coordinate_system:"EPSG:6738", label: "6738:GDA94 / MGA zone 59"},
  {coordinate_system:"EPSG:6784", label: "6784:NAD83(CORS96) / Oregon Baker zone (m)"},
  {coordinate_system:"EPSG:6785", label: "6785:NAD83(CORS96) / Oregon Baker zone (ft)"},
  {coordinate_system:"EPSG:6786", label: "6786:NAD83(2011) / Oregon Baker zone (m)"},
  {coordinate_system:"EPSG:6787", label: "6787:NAD83(2011) / Oregon Baker zone (ft)"},
  {coordinate_system:"EPSG:6788", label: "6788:NAD83(CORS96) / Oregon Bend-Klamath Falls zone (m)"},
  {coordinate_system:"EPSG:6789", label: "6789:NAD83(CORS96) / Oregon Bend-Klamath Falls zone (ft)"},
  {coordinate_system:"EPSG:6790", label: "6790:NAD83(2011) / Oregon Bend-Klamath Falls zone (m)"},
  {coordinate_system:"EPSG:6791", label: "6791:NAD83(2011) / Oregon Bend-Klamath Falls zone (ft)"},
  {coordinate_system:"EPSG:6792", label: "6792:NAD83(CORS96) / Oregon Bend-Redmond-Prineville zone (m)"},
  {coordinate_system:"EPSG:6793", label: "6793:NAD83(CORS96) / Oregon Bend-Redmond-Prineville zone (ft)"},
  {coordinate_system:"EPSG:6794", label: "6794:NAD83(2011) / Oregon Bend-Redmond-Prineville zone (m)"},
  {coordinate_system:"EPSG:6795", label: "6795:NAD83(2011) / Oregon Bend-Redmond-Prineville zone (ft)"},
  {coordinate_system:"EPSG:6796", label: "6796:NAD83(CORS96) / Oregon Bend-Burns zone (m)"},
  {coordinate_system:"EPSG:6797", label: "6797:NAD83(CORS96) / Oregon Bend-Burns zone (ft)"},
  {coordinate_system:"EPSG:6798", label: "6798:NAD83(2011) / Oregon Bend-Burns zone (m)"},
  {coordinate_system:"EPSG:6799", label: "6799:NAD83(2011) / Oregon Bend-Burns zone (ft)"},
  {coordinate_system:"EPSG:6800", label: "6800:NAD83(CORS96) / Oregon Canyonville-Grants Pass zone (m)"},
  {coordinate_system:"EPSG:6801", label: "6801:NAD83(CORS96) / Oregon Canyonville-Grants Pass zone (ft)"},
  {coordinate_system:"EPSG:6802", label: "6802:NAD83(2011) / Oregon Canyonville-Grants Pass zone (m)"},
  {coordinate_system:"EPSG:6803", label: "6803:NAD83(2011) / Oregon Canyonville-Grants Pass zone (ft)"},
  {coordinate_system:"EPSG:6804", label: "6804:NAD83(CORS96) / Oregon Columbia River East zone (m)"},
  {coordinate_system:"EPSG:6805", label: "6805:NAD83(CORS96) / Oregon Columbia River East zone (ft)"},
  {coordinate_system:"EPSG:6806", label: "6806:NAD83(2011) / Oregon Columbia River East zone (m)"},
  {coordinate_system:"EPSG:6807", label: "6807:NAD83(2011) / Oregon Columbia River East zone (ft)"},
  {coordinate_system:"EPSG:6808", label: "6808:NAD83(CORS96) / Oregon Columbia River West zone (m)"},
  {coordinate_system:"EPSG:6809", label: "6809:NAD83(CORS96) / Oregon Columbia River West zone (ft)"},
  {coordinate_system:"EPSG:6810", label: "6810:NAD83(2011) / Oregon Columbia River West zone (m)"},
  {coordinate_system:"EPSG:6811", label: "6811:NAD83(2011) / Oregon Columbia River West zone (ft)"},
  {coordinate_system:"EPSG:6812", label: "6812:NAD83(CORS96) / Oregon Cottage Grove-Canyonville zone (m)"},
  {coordinate_system:"EPSG:6813", label: "6813:NAD83(CORS96) / Oregon Cottage Grove-Canyonville zone (ft)"},
  {coordinate_system:"EPSG:6814", label: "6814:NAD83(2011) / Oregon Cottage Grove-Canyonville zone (m)"},
  {coordinate_system:"EPSG:6815", label: "6815:NAD83(2011) / Oregon Cottage Grove-Canyonville zone (ft)"},
  {coordinate_system:"EPSG:6816", label: "6816:NAD83(CORS96) / Oregon Dufur-Madras zone (m)"},
  {coordinate_system:"EPSG:6817", label: "6817:NAD83(CORS96) / Oregon Dufur-Madras zone (ft)"},
  {coordinate_system:"EPSG:6818", label: "6818:NAD83(2011) / Oregon Dufur-Madras zone (m)"},
  {coordinate_system:"EPSG:6819", label: "6819:NAD83(2011) / Oregon Dufur-Madras zone (ft)"},
  {coordinate_system:"EPSG:6820", label: "6820:NAD83(CORS96) / Oregon Eugene zone (m)"},
  {coordinate_system:"EPSG:6821", label: "6821:NAD83(CORS96) / Oregon Eugene zone (ft)"},
  {coordinate_system:"EPSG:6822", label: "6822:NAD83(2011) / Oregon Eugene zone (m)"},
  {coordinate_system:"EPSG:6823", label: "6823:NAD83(2011) / Oregon Eugene zone (ft)"},
  {coordinate_system:"EPSG:6824", label: "6824:NAD83(CORS96) / Oregon Grants Pass-Ashland zone (m)"},
  {coordinate_system:"EPSG:6825", label: "6825:NAD83(CORS96) / Oregon Grants Pass-Ashland zone (ft)"},
  {coordinate_system:"EPSG:6826", label: "6826:NAD83(2011) / Oregon Grants Pass-Ashland zone (m)"},
  {coordinate_system:"EPSG:6827", label: "6827:NAD83(2011) / Oregon Grants Pass-Ashland zone (ft)"},
  {coordinate_system:"EPSG:6828", label: "6828:NAD83(CORS96) / Oregon Gresham-Warm Springs zone (m)"},
  {coordinate_system:"EPSG:6829", label: "6829:NAD83(CORS96) / Oregon Gresham-Warm Springs zone (ft)"},
  {coordinate_system:"EPSG:6830", label: "6830:NAD83(2011) / Oregon Gresham-Warm Springs zone (m)"},
  {coordinate_system:"EPSG:6831", label: "6831:NAD83(2011) / Oregon Gresham-Warm Springs zone (ft)"},
  {coordinate_system:"EPSG:6832", label: "6832:NAD83(CORS96) / Oregon La Grande zone (m)"},
  {coordinate_system:"EPSG:6833", label: "6833:NAD83(CORS96) / Oregon La Grande zone (ft)"},
  {coordinate_system:"EPSG:6834", label: "6834:NAD83(2011) / Oregon La Grande zone (m)"},
  {coordinate_system:"EPSG:6835", label: "6835:NAD83(2011) / Oregon La Grande zone (ft)"},
  {coordinate_system:"EPSG:6836", label: "6836:NAD83(CORS96) / Oregon Ontario zone (m)"},
  {coordinate_system:"EPSG:6837", label: "6837:NAD83(CORS96) / Oregon Ontario zone (ft)"},
  {coordinate_system:"EPSG:6838", label: "6838:NAD83(2011) / Oregon Ontario zone (m)"},
  {coordinate_system:"EPSG:6839", label: "6839:NAD83(2011) / Oregon Ontario zone (ft)"},
  {coordinate_system:"EPSG:6840", label: "6840:NAD83(CORS96) / Oregon Coast zone (m)"},
  {coordinate_system:"EPSG:6841", label: "6841:NAD83(CORS96) / Oregon Coast zone (ft)"},
  {coordinate_system:"EPSG:6842", label: "6842:NAD83(2011) / Oregon Coast zone (m)"},
  {coordinate_system:"EPSG:6843", label: "6843:NAD83(2011) / Oregon Coast zone (ft)"},
  {coordinate_system:"EPSG:6844", label: "6844:NAD83(CORS96) / Oregon Pendleton zone (m)"},
  {coordinate_system:"EPSG:6845", label: "6845:NAD83(CORS96) / Oregon Pendleton zone (ft)"},
  {coordinate_system:"EPSG:6846", label: "6846:NAD83(2011) / Oregon Pendleton zone (m)"},
  {coordinate_system:"EPSG:6847", label: "6847:NAD83(2011) / Oregon Pendleton zone (ft)"},
  {coordinate_system:"EPSG:6848", label: "6848:NAD83(CORS96) / Oregon Pendleton-La Grande zone (m)"},
  {coordinate_system:"EPSG:6849", label: "6849:NAD83(CORS96) / Oregon Pendleton-La Grande zone (ft)"},
  {coordinate_system:"EPSG:6850", label: "6850:NAD83(2011) / Oregon Pendleton-La Grande zone (m)"},
  {coordinate_system:"EPSG:6851", label: "6851:NAD83(2011) / Oregon Pendleton-La Grande zone (ft)"},
  {coordinate_system:"EPSG:6852", label: "6852:NAD83(CORS96) / Oregon Portland zone (m)"},
  {coordinate_system:"EPSG:6853", label: "6853:NAD83(CORS96) / Oregon Portland zone (ft)"},
  {coordinate_system:"EPSG:6854", label: "6854:NAD83(2011) / Oregon Portland zone (m)"},
  {coordinate_system:"EPSG:6855", label: "6855:NAD83(2011) / Oregon Portland zone (ft)"},
  {coordinate_system:"EPSG:6856", label: "6856:NAD83(CORS96) / Oregon Salem zone (m)"},
  {coordinate_system:"EPSG:6857", label: "6857:NAD83(CORS96) / Oregon Salem zone (ft)"},
  {coordinate_system:"EPSG:6858", label: "6858:NAD83(2011) / Oregon Salem zone (m)"},
  {coordinate_system:"EPSG:6859", label: "6859:NAD83(2011) / Oregon Salem zone (ft)"},
  {coordinate_system:"EPSG:6860", label: "6860:NAD83(CORS96) / Oregon Santiam Pass zone (m)"},
  {coordinate_system:"EPSG:6861", label: "6861:NAD83(CORS96) / Oregon Santiam Pass zone (ft)"},
  {coordinate_system:"EPSG:6862", label: "6862:NAD83(2011) / Oregon Santiam Pass zone (m)"},
  {coordinate_system:"EPSG:6863", label: "6863:NAD83(2011) / Oregon Santiam Pass zone (ft)"},
  {coordinate_system:"EPSG:6867", label: "6867:NAD83(CORS96) / Oregon LCC (m)"},
  {coordinate_system:"EPSG:6868", label: "6868:NAD83(CORS96) / Oregon GIC Lambert (ft)"},
  {coordinate_system:"EPSG:6870", label: "6870:ETRS89 / Albania TM 2010"},
  {coordinate_system:"EPSG:6875", label: "6875:RDN2008 / Italy zone (N-E)"},
  {coordinate_system:"EPSG:6876", label: "6876:RDN2008 / Zone 12 (N-E)"},
  {coordinate_system:"EPSG:6879", label: "6879:NAD83(2011) / Wisconsin Central"},
  {coordinate_system:"EPSG:6880", label: "6880:NAD83(2011) / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:6884", label: "6884:NAD83(CORS96) / Oregon North"},
  {coordinate_system:"EPSG:6885", label: "6885:NAD83(CORS96) / Oregon North (ft)"},
  {coordinate_system:"EPSG:6886", label: "6886:NAD83(CORS96) / Oregon South"},
  {coordinate_system:"EPSG:6887", label: "6887:NAD83(CORS96) / Oregon South (ft)"},
  {coordinate_system:"EPSG:6915", label: "6915:South East Island 1943 / UTM zone 40N"},
  {coordinate_system:"EPSG:6922", label: "6922:NAD83 / Kansas LCC"},
  {coordinate_system:"EPSG:6923", label: "6923:NAD83 / Kansas LCC (ftUS)"},
  {coordinate_system:"EPSG:6924", label: "6924:NAD83(2011) / Kansas LCC"},
  {coordinate_system:"EPSG:6925", label: "6925:NAD83(2011) / Kansas LCC (ftUS)"},
  {coordinate_system:"EPSG:6931", label: "6931:WGS 84 / NSIDC EASE-Grid 2.0 North"},
  {coordinate_system:"EPSG:6932", label: "6932:WGS 84 / NSIDC EASE-Grid 2.0 South"},
  {coordinate_system:"EPSG:6933", label: "6933:WGS 84 / NSIDC EASE-Grid 2.0 Global"},
  {coordinate_system:"EPSG:6962", label: "6962:ETRS89 / Albania LCC 2010"},
  {coordinate_system:"EPSG:6966", label: "6966:NAD27 / Michigan North"},
  {coordinate_system:"EPSG:6984", label: "6984:Israeli Grid 05"},
  {coordinate_system:"EPSG:6991", label: "6991:Israeli Grid 05/12"},
  {coordinate_system:"EPSG:7005", label: "7005:Nahrwan 1934 / UTM zone 37N"},
  {coordinate_system:"EPSG:7006", label: "7006:Nahrwan 1934 / UTM zone 38N"},
  {coordinate_system:"EPSG:7007", label: "7007:Nahrwan 1934 / UTM zone 39N"},
  {coordinate_system:"EPSG:7057", label: "7057:NAD83(2011) / IaRCS zone 1"},
  {coordinate_system:"EPSG:7058", label: "7058:NAD83(2011) / IaRCS zone 2"},
  {coordinate_system:"EPSG:7059", label: "7059:NAD83(2011) / IaRCS zone 3"},
  {coordinate_system:"EPSG:7060", label: "7060:NAD83(2011) / IaRCS zone 4"},
  {coordinate_system:"EPSG:7061", label: "7061:NAD83(2011) / IaRCS zone 5"},
  {coordinate_system:"EPSG:7062", label: "7062:NAD83(2011) / IaRCS zone 6"},
  {coordinate_system:"EPSG:7063", label: "7063:NAD83(2011) / IaRCS zone 7"},
  {coordinate_system:"EPSG:7064", label: "7064:NAD83(2011) / IaRCS zone 8"},
  {coordinate_system:"EPSG:7065", label: "7065:NAD83(2011) / IaRCS zone 9"},
  {coordinate_system:"EPSG:7066", label: "7066:NAD83(2011) / IaRCS zone 10"},
  {coordinate_system:"EPSG:7067", label: "7067:NAD83(2011) / IaRCS zone 11"},
  {coordinate_system:"EPSG:7068", label: "7068:NAD83(2011) / IaRCS zone 12"},
  {coordinate_system:"EPSG:7069", label: "7069:NAD83(2011) / IaRCS zone 13"},
  {coordinate_system:"EPSG:7070", label: "7070:NAD83(2011) / IaRCS zone 14"},
  {coordinate_system:"EPSG:7074", label: "7074:RGTAAF07 / UTM zone 37S"},
  {coordinate_system:"EPSG:7075", label: "7075:RGTAAF07 / UTM zone 38S"},
  {coordinate_system:"EPSG:7076", label: "7076:RGTAAF07 / UTM zone 39S"},
  {coordinate_system:"EPSG:7077", label: "7077:RGTAAF07 / UTM zone 40S"},
  {coordinate_system:"EPSG:7078", label: "7078:RGTAAF07 / UTM zone 41S"},
  {coordinate_system:"EPSG:7079", label: "7079:RGTAAF07 / UTM zone 42S"},
  {coordinate_system:"EPSG:7080", label: "7080:RGTAAF07 / UTM zone 43S"},
  {coordinate_system:"EPSG:7081", label: "7081:RGTAAF07 / UTM zone 44S"},
  {coordinate_system:"EPSG:7109", label: "7109:NAD83(2011) / RMTCRS St Mary (m)"},
  {coordinate_system:"EPSG:7110", label: "7110:NAD83(2011) / RMTCRS Blackfeet (m)"},
  {coordinate_system:"EPSG:7111", label: "7111:NAD83(2011) / RMTCRS Milk River (m)"},
  {coordinate_system:"EPSG:7112", label: "7112:NAD83(2011) / RMTCRS Fort Belknap (m)"},
  {coordinate_system:"EPSG:7113", label: "7113:NAD83(2011) / RMTCRS Fort Peck Assiniboine (m)"},
  {coordinate_system:"EPSG:7114", label: "7114:NAD83(2011) / RMTCRS Fort Peck Sioux (m)"},
  {coordinate_system:"EPSG:7115", label: "7115:NAD83(2011) / RMTCRS Crow (m)"},
  {coordinate_system:"EPSG:7116", label: "7116:NAD83(2011) / RMTCRS Bobcat (m)"},
  {coordinate_system:"EPSG:7117", label: "7117:NAD83(2011) / RMTCRS Billings (m)"},
  {coordinate_system:"EPSG:7118", label: "7118:NAD83(2011) / RMTCRS Wind River (m)"},
  {coordinate_system:"EPSG:7119", label: "7119:NAD83(2011) / RMTCRS St Mary (ft)"},
  {coordinate_system:"EPSG:7120", label: "7120:NAD83(2011) / RMTCRS Blackfeet (ft)"},
  {coordinate_system:"EPSG:7121", label: "7121:NAD83(2011) / RMTCRS Milk River (ft)"},
  {coordinate_system:"EPSG:7122", label: "7122:NAD83(2011) / RMTCRS Fort Belknap (ft)"},
  {coordinate_system:"EPSG:7123", label: "7123:NAD83(2011) / RMTCRS Fort Peck Assiniboine (ft)"},
  {coordinate_system:"EPSG:7124", label: "7124:NAD83(2011) / RMTCRS Fort Peck Sioux (ft)"},
  {coordinate_system:"EPSG:7125", label: "7125:NAD83(2011) / RMTCRS Crow (ft)"},
  {coordinate_system:"EPSG:7126", label: "7126:NAD83(2011) / RMTCRS Bobcat (ft)"},
  {coordinate_system:"EPSG:7127", label: "7127:NAD83(2011) / RMTCRS Billings (ft)"},
  {coordinate_system:"EPSG:7128", label: "7128:NAD83(2011) / RMTCRS Wind River (ftUS)"},
  {coordinate_system:"EPSG:7131", label: "7131:NAD83(2011) / San Francisco CS13"},
  {coordinate_system:"EPSG:7132", label: "7132:NAD83(2011) / San Francisco CS13 (ftUS)"},
  {coordinate_system:"EPSG:7142", label: "7142:Palestine 1923 / Palestine Grid modified"},
  {coordinate_system:"EPSG:7257", label: "7257:NAD83(2011) / InGCS Adams (m)"},
  {coordinate_system:"EPSG:7258", label: "7258:NAD83(2011) / InGCS Adams (ftUS)"},
  {coordinate_system:"EPSG:7259", label: "7259:NAD83(2011) / InGCS Allen (m)"},
  {coordinate_system:"EPSG:7260", label: "7260:NAD83(2011) / InGCS Allen (ftUS)"},
  {coordinate_system:"EPSG:7261", label: "7261:NAD83(2011) / InGCS Bartholomew (m)"},
  {coordinate_system:"EPSG:7262", label: "7262:NAD83(2011) / InGCS Bartholomew (ftUS)"},
  {coordinate_system:"EPSG:7263", label: "7263:NAD83(2011) / InGCS Benton (m)"},
  {coordinate_system:"EPSG:7264", label: "7264:NAD83(2011) / InGCS Benton (ftUS)"},
  {coordinate_system:"EPSG:7265", label: "7265:NAD83(2011) / InGCS Blackford-Delaware (m)"},
  {coordinate_system:"EPSG:7266", label: "7266:NAD83(2011) / InGCS Blackford-Delaware (ftUS)"},
  {coordinate_system:"EPSG:7267", label: "7267:NAD83(2011) / InGCS Boone-Hendricks (m)"},
  {coordinate_system:"EPSG:7268", label: "7268:NAD83(2011) / InGCS Boone-Hendricks (ftUS)"},
  {coordinate_system:"EPSG:7269", label: "7269:NAD83(2011) / InGCS Brown (m)"},
  {coordinate_system:"EPSG:7270", label: "7270:NAD83(2011) / InGCS Brown (ftUS)"},
  {coordinate_system:"EPSG:7271", label: "7271:NAD83(2011) / InGCS Carroll (m)"},
  {coordinate_system:"EPSG:7272", label: "7272:NAD83(2011) / InGCS Carroll (ftUS)"},
  {coordinate_system:"EPSG:7273", label: "7273:NAD83(2011) / InGCS Cass (m)"},
  {coordinate_system:"EPSG:7274", label: "7274:NAD83(2011) / InGCS Cass (ftUS)"},
  {coordinate_system:"EPSG:7275", label: "7275:NAD83(2011) / InGCS Clark-Floyd-Scott (m)"},
  {coordinate_system:"EPSG:7276", label: "7276:NAD83(2011) / InGCS Clark-Floyd-Scott (ftUS)"},
  {coordinate_system:"EPSG:7277", label: "7277:NAD83(2011) / InGCS Clay (m)"},
  {coordinate_system:"EPSG:7278", label: "7278:NAD83(2011) / InGCS Clay (ftUS)"},
  {coordinate_system:"EPSG:7279", label: "7279:NAD83(2011) / InGCS Clinton (m)"},
  {coordinate_system:"EPSG:7280", label: "7280:NAD83(2011) / InGCS Clinton (ftUS)"},
  {coordinate_system:"EPSG:7281", label: "7281:NAD83(2011) / InGCS Crawford-Lawrence-Orange (m)"},
  {coordinate_system:"EPSG:7282", label: "7282:NAD83(2011) / InGCS Crawford-Lawrence-Orange (ftUS)"},
  {coordinate_system:"EPSG:7283", label: "7283:NAD83(2011) / InGCS Daviess-Greene (m)"},
  {coordinate_system:"EPSG:7284", label: "7284:NAD83(2011) / InGCS Daviess-Greene (ftUS)"},
  {coordinate_system:"EPSG:7285", label: "7285:NAD83(2011) / InGCS Dearborn-Ohio-Switzerland (m)"},
  {coordinate_system:"EPSG:7286", label: "7286:NAD83(2011) / InGCS Dearborn-Ohio-Switzerland (ftUS)"},
  {coordinate_system:"EPSG:7287", label: "7287:NAD83(2011) / InGCS Decatur-Rush (m)"},
  {coordinate_system:"EPSG:7288", label: "7288:NAD83(2011) / InGCS Decatur-Rush (ftUS)"},
  {coordinate_system:"EPSG:7289", label: "7289:NAD83(2011) / InGCS DeKalb (m)"},
  {coordinate_system:"EPSG:7290", label: "7290:NAD83(2011) / InGCS DeKalb (ftUS)"},
  {coordinate_system:"EPSG:7291", label: "7291:NAD83(2011) / InGCS Dubois-Martin (m)"},
  {coordinate_system:"EPSG:7292", label: "7292:NAD83(2011) / InGCS Dubois-Martin (ftUS)"},
  {coordinate_system:"EPSG:7293", label: "7293:NAD83(2011) / InGCS Elkhart-Kosciusko-Wabash (m)"},
  {coordinate_system:"EPSG:7294", label: "7294:NAD83(2011) / InGCS Elkhart-Kosciusko-Wabash (ftUS)"},
  {coordinate_system:"EPSG:7295", label: "7295:NAD83(2011) / InGCS Fayette-Franklin-Union (m)"},
  {coordinate_system:"EPSG:7296", label: "7296:NAD83(2011) / InGCS Fayette-Franklin-Union (ftUS)"},
  {coordinate_system:"EPSG:7297", label: "7297:NAD83(2011) / InGCS Fountain-Warren (m)"},
  {coordinate_system:"EPSG:7298", label: "7298:NAD83(2011) / InGCS Fountain-Warren (ftUS)"},
  {coordinate_system:"EPSG:7299", label: "7299:NAD83(2011) / InGCS Fulton-Marshall-St. Joseph (m)"},
  {coordinate_system:"EPSG:7300", label: "7300:NAD83(2011) / InGCS Fulton-Marshall-St. Joseph (ftUS)"},
  {coordinate_system:"EPSG:7301", label: "7301:NAD83(2011) / InGCS Gibson (m)"},
  {coordinate_system:"EPSG:7302", label: "7302:NAD83(2011) / InGCS Gibson (ftUS)"},
  {coordinate_system:"EPSG:7303", label: "7303:NAD83(2011) / InGCS Grant (m)"},
  {coordinate_system:"EPSG:7304", label: "7304:NAD83(2011) / InGCS Grant (ftUS)"},
  {coordinate_system:"EPSG:7305", label: "7305:NAD83(2011) / InGCS Hamilton-Tipton (m)"},
  {coordinate_system:"EPSG:7306", label: "7306:NAD83(2011) / InGCS Hamilton-Tipton (ftUS)"},
  {coordinate_system:"EPSG:7307", label: "7307:NAD83(2011) / InGCS Hancock-Madison (m)"},
  {coordinate_system:"EPSG:7308", label: "7308:NAD83(2011) / InGCS Hancock-Madison (ftUS)"},
  {coordinate_system:"EPSG:7309", label: "7309:NAD83(2011) / InGCS Harrison-Washington (m)"},
  {coordinate_system:"EPSG:7310", label: "7310:NAD83(2011) / InGCS Harrison-Washington (ftUS)"},
  {coordinate_system:"EPSG:7311", label: "7311:NAD83(2011) / InGCS Henry (m)"},
  {coordinate_system:"EPSG:7312", label: "7312:NAD83(2011) / InGCS Henry (ftUS)"},
  {coordinate_system:"EPSG:7313", label: "7313:NAD83(2011) / InGCS Howard-Miami (m)"},
  {coordinate_system:"EPSG:7314", label: "7314:NAD83(2011) / InGCS Howard-Miami (ftUS)"},
  {coordinate_system:"EPSG:7315", label: "7315:NAD83(2011) / InGCS Huntington-Whitley (m)"},
  {coordinate_system:"EPSG:7316", label: "7316:NAD83(2011) / InGCS Huntington-Whitley (ftUS)"},
  {coordinate_system:"EPSG:7317", label: "7317:NAD83(2011) / InGCS Jackson (m)"},
  {coordinate_system:"EPSG:7318", label: "7318:NAD83(2011) / InGCS Jackson (ftUS)"},
  {coordinate_system:"EPSG:7319", label: "7319:NAD83(2011) / InGCS Jasper-Porter (m)"},
  {coordinate_system:"EPSG:7320", label: "7320:NAD83(2011) / InGCS Jasper-Porter (ftUS)"},
  {coordinate_system:"EPSG:7321", label: "7321:NAD83(2011) / InGCS Jay (m)"},
  {coordinate_system:"EPSG:7322", label: "7322:NAD83(2011) / InGCS Jay (ftUS)"},
  {coordinate_system:"EPSG:7323", label: "7323:NAD83(2011) / InGCS Jefferson (m)"},
  {coordinate_system:"EPSG:7324", label: "7324:NAD83(2011) / InGCS Jefferson (ftUS)"},
  {coordinate_system:"EPSG:7325", label: "7325:NAD83(2011) / InGCS Jennings (m)"},
  {coordinate_system:"EPSG:7326", label: "7326:NAD83(2011) / InGCS Jennings (ftUS)"},
  {coordinate_system:"EPSG:7327", label: "7327:NAD83(2011) / InGCS Johnson-Marion (m)"},
  {coordinate_system:"EPSG:7328", label: "7328:NAD83(2011) / InGCS Johnson-Marion (ftUS)"},
  {coordinate_system:"EPSG:7329", label: "7329:NAD83(2011) / InGCS Knox (m)"},
  {coordinate_system:"EPSG:7330", label: "7330:NAD83(2011) / InGCS Knox (ftUS)"},
  {coordinate_system:"EPSG:7331", label: "7331:NAD83(2011) / InGCS LaGrange-Noble (m)"},
  {coordinate_system:"EPSG:7332", label: "7332:NAD83(2011) / InGCS LaGrange-Noble (ftUS)"},
  {coordinate_system:"EPSG:7333", label: "7333:NAD83(2011) / InGCS Lake-Newton (m)"},
  {coordinate_system:"EPSG:7334", label: "7334:NAD83(2011) / InGCS Lake-Newton (ftUS)"},
  {coordinate_system:"EPSG:7335", label: "7335:NAD83(2011) / InGCS LaPorte-Pulaski-Starke (m)"},
  {coordinate_system:"EPSG:7336", label: "7336:NAD83(2011) / InGCS LaPorte-Pulaski-Starke (ftUS)"},
  {coordinate_system:"EPSG:7337", label: "7337:NAD83(2011) / InGCS Monroe-Morgan (m)"},
  {coordinate_system:"EPSG:7338", label: "7338:NAD83(2011) / InGCS Monroe-Morgan (ftUS)"},
  {coordinate_system:"EPSG:7339", label: "7339:NAD83(2011) / InGCS Montgomery-Putnam (m)"},
  {coordinate_system:"EPSG:7340", label: "7340:NAD83(2011) / InGCS Montgomery-Putnam (ftUS)"},
  {coordinate_system:"EPSG:7341", label: "7341:NAD83(2011) / InGCS Owen (m)"},
  {coordinate_system:"EPSG:7342", label: "7342:NAD83(2011) / InGCS Owen (ftUS)"},
  {coordinate_system:"EPSG:7343", label: "7343:NAD83(2011) / InGCS Parke-Vermillion (m)"},
  {coordinate_system:"EPSG:7344", label: "7344:NAD83(2011) / InGCS Parke-Vermillion (ftUS)"},
  {coordinate_system:"EPSG:7345", label: "7345:NAD83(2011) / InGCS Perry (m)"},
  {coordinate_system:"EPSG:7346", label: "7346:NAD83(2011) / InGCS Perry (ftUS)"},
  {coordinate_system:"EPSG:7347", label: "7347:NAD83(2011) / InGCS Pike-Warrick (m)"},
  {coordinate_system:"EPSG:7348", label: "7348:NAD83(2011) / InGCS Pike-Warrick (ftUS)"},
  {coordinate_system:"EPSG:7349", label: "7349:NAD83(2011) / InGCS Posey (m)"},
  {coordinate_system:"EPSG:7350", label: "7350:NAD83(2011) / InGCS Posey (ftUS)"},
  {coordinate_system:"EPSG:7351", label: "7351:NAD83(2011) / InGCS Randolph-Wayne (m)"},
  {coordinate_system:"EPSG:7352", label: "7352:NAD83(2011) / InGCS Randolph-Wayne (ftUS)"},
  {coordinate_system:"EPSG:7353", label: "7353:NAD83(2011) / InGCS Ripley (m)"},
  {coordinate_system:"EPSG:7354", label: "7354:NAD83(2011) / InGCS Ripley (ftUS)"},
  {coordinate_system:"EPSG:7355", label: "7355:NAD83(2011) / InGCS Shelby (m)"},
  {coordinate_system:"EPSG:7356", label: "7356:NAD83(2011) / InGCS Shelby (ftUS)"},
  {coordinate_system:"EPSG:7357", label: "7357:NAD83(2011) / InGCS Spencer (m)"},
  {coordinate_system:"EPSG:7358", label: "7358:NAD83(2011) / InGCS Spencer (ftUS)"},
  {coordinate_system:"EPSG:7359", label: "7359:NAD83(2011) / InGCS Steuben (m)"},
  {coordinate_system:"EPSG:7360", label: "7360:NAD83(2011) / InGCS Steuben (ftUS)"},
  {coordinate_system:"EPSG:7361", label: "7361:NAD83(2011) / InGCS Sullivan (m)"},
  {coordinate_system:"EPSG:7362", label: "7362:NAD83(2011) / InGCS Sullivan (ftUS)"},
  {coordinate_system:"EPSG:7363", label: "7363:NAD83(2011) / InGCS Tippecanoe-White (m)"},
  {coordinate_system:"EPSG:7364", label: "7364:NAD83(2011) / InGCS Tippecanoe-White (ftUS)"},
  {coordinate_system:"EPSG:7365", label: "7365:NAD83(2011) / InGCS Vanderburgh (m)"},
  {coordinate_system:"EPSG:7366", label: "7366:NAD83(2011) / InGCS Vanderburgh (ftUS)"},
  {coordinate_system:"EPSG:7367", label: "7367:NAD83(2011) / InGCS Vigo (m)"},
  {coordinate_system:"EPSG:7368", label: "7368:NAD83(2011) / InGCS Vigo (ftUS)"},
  {coordinate_system:"EPSG:7369", label: "7369:NAD83(2011) / InGCS Wells (m)"},
  {coordinate_system:"EPSG:7370", label: "7370:NAD83(2011) / InGCS Wells (ftUS)"},
  {coordinate_system:"EPSG:7374", label: "7374:ONGD14 / UTM zone 39N"},
  {coordinate_system:"EPSG:7375", label: "7375:ONGD14 / UTM zone 40N"},
  {coordinate_system:"EPSG:7376", label: "7376:ONGD14 / UTM zone 41N"},
  {coordinate_system:"EPSG:7528", label: "7528:NAD83(2011) / WISCRS Adams and Juneau (m)"},
  {coordinate_system:"EPSG:7529", label: "7529:NAD83(2011) / WISCRS Ashland (m)"},
  {coordinate_system:"EPSG:7530", label: "7530:NAD83(2011) / WISCRS Barron (m)"},
  {coordinate_system:"EPSG:7531", label: "7531:NAD83(2011) / WISCRS Bayfield (m)"},
  {coordinate_system:"EPSG:7532", label: "7532:NAD83(2011) / WISCRS Brown (m)"},
  {coordinate_system:"EPSG:7533", label: "7533:NAD83(2011) / WISCRS Buffalo (m)"},
  {coordinate_system:"EPSG:7534", label: "7534:NAD83(2011) / WISCRS Burnett (m)"},
  {coordinate_system:"EPSG:7535", label: "7535:NAD83(2011) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (m)"},
  {coordinate_system:"EPSG:7536", label: "7536:NAD83(2011) / WISCRS Chippewa (m)"},
  {coordinate_system:"EPSG:7537", label: "7537:NAD83(2011) / WISCRS Clark (m)"},
  {coordinate_system:"EPSG:7538", label: "7538:NAD83(2011) / WISCRS Columbia (m)"},
  {coordinate_system:"EPSG:7539", label: "7539:NAD83(2011) / WISCRS Crawford (m)"},
  {coordinate_system:"EPSG:7540", label: "7540:NAD83(2011) / WISCRS Dane (m)"},
  {coordinate_system:"EPSG:7541", label: "7541:NAD83(2011) / WISCRS Dodge and Jefferson (m)"},
  {coordinate_system:"EPSG:7542", label: "7542:NAD83(2011) / WISCRS Door (m)"},
  {coordinate_system:"EPSG:7543", label: "7543:NAD83(2011) / WISCRS Douglas (m)"},
  {coordinate_system:"EPSG:7544", label: "7544:NAD83(2011) / WISCRS Dunn (m)"},
  {coordinate_system:"EPSG:7545", label: "7545:NAD83(2011) / WISCRS Eau Claire (m)"},
  {coordinate_system:"EPSG:7546", label: "7546:NAD83(2011) / WISCRS Florence (m)"},
  {coordinate_system:"EPSG:7547", label: "7547:NAD83(2011) / WISCRS Forest (m)"},
  {coordinate_system:"EPSG:7548", label: "7548:NAD83(2011) / WISCRS Grant (m)"},
  {coordinate_system:"EPSG:7549", label: "7549:NAD83(2011) / WISCRS Green and Lafayette (m)"},
  {coordinate_system:"EPSG:7550", label: "7550:NAD83(2011) / WISCRS Green Lake and Marquette (m)"},
  {coordinate_system:"EPSG:7551", label: "7551:NAD83(2011) / WISCRS Iowa (m)"},
  {coordinate_system:"EPSG:7552", label: "7552:NAD83(2011) / WISCRS Iron (m)"},
  {coordinate_system:"EPSG:7553", label: "7553:NAD83(2011) / WISCRS Jackson (m)"},
  {coordinate_system:"EPSG:7554", label: "7554:NAD83(2011) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (m)"},
  {coordinate_system:"EPSG:7555", label: "7555:NAD83(2011) / WISCRS Kewaunee, Manitowoc and Sheboygan (m)"},
  {coordinate_system:"EPSG:7556", label: "7556:NAD83(2011) / WISCRS La Crosse (m)"},
  {coordinate_system:"EPSG:7557", label: "7557:NAD83(2011) / WISCRS Langlade (m)"},
  {coordinate_system:"EPSG:7558", label: "7558:NAD83(2011) / WISCRS Lincoln (m)"},
  {coordinate_system:"EPSG:7559", label: "7559:NAD83(2011) / WISCRS Marathon (m)"},
  {coordinate_system:"EPSG:7560", label: "7560:NAD83(2011) / WISCRS Marinette (m)"},
  {coordinate_system:"EPSG:7561", label: "7561:NAD83(2011) / WISCRS Menominee (m)"},
  {coordinate_system:"EPSG:7562", label: "7562:NAD83(2011) / WISCRS Monroe (m)"},
  {coordinate_system:"EPSG:7563", label: "7563:NAD83(2011) / WISCRS Oconto (m)"},
  {coordinate_system:"EPSG:7564", label: "7564:NAD83(2011) / WISCRS Oneida (m)"},
  {coordinate_system:"EPSG:7565", label: "7565:NAD83(2011) / WISCRS Pepin and Pierce (m)"},
  {coordinate_system:"EPSG:7566", label: "7566:NAD83(2011) / WISCRS Polk (m)"},
  {coordinate_system:"EPSG:7567", label: "7567:NAD83(2011) / WISCRS Portage (m)"},
  {coordinate_system:"EPSG:7568", label: "7568:NAD83(2011) / WISCRS Price (m)"},
  {coordinate_system:"EPSG:7569", label: "7569:NAD83(2011) / WISCRS Richland (m)"},
  {coordinate_system:"EPSG:7570", label: "7570:NAD83(2011) / WISCRS Rock (m)"},
  {coordinate_system:"EPSG:7571", label: "7571:NAD83(2011) / WISCRS Rusk (m)"},
  {coordinate_system:"EPSG:7572", label: "7572:NAD83(2011) / WISCRS Sauk (m)"},
  {coordinate_system:"EPSG:7573", label: "7573:NAD83(2011) / WISCRS Sawyer (m)"},
  {coordinate_system:"EPSG:7574", label: "7574:NAD83(2011) / WISCRS Shawano (m)"},
  {coordinate_system:"EPSG:7575", label: "7575:NAD83(2011) / WISCRS St. Croix (m)"},
  {coordinate_system:"EPSG:7576", label: "7576:NAD83(2011) / WISCRS Taylor (m)"},
  {coordinate_system:"EPSG:7577", label: "7577:NAD83(2011) / WISCRS Trempealeau (m)"},
  {coordinate_system:"EPSG:7578", label: "7578:NAD83(2011) / WISCRS Vernon (m)"},
  {coordinate_system:"EPSG:7579", label: "7579:NAD83(2011) / WISCRS Vilas (m)"},
  {coordinate_system:"EPSG:7580", label: "7580:NAD83(2011) / WISCRS Walworth (m)"},
  {coordinate_system:"EPSG:7581", label: "7581:NAD83(2011) / WISCRS Washburn (m)"},
  {coordinate_system:"EPSG:7582", label: "7582:NAD83(2011) / WISCRS Washington (m)"},
  {coordinate_system:"EPSG:7583", label: "7583:NAD83(2011) / WISCRS Waukesha (m)"},
  {coordinate_system:"EPSG:7584", label: "7584:NAD83(2011) / WISCRS Waupaca (m)"},
  {coordinate_system:"EPSG:7585", label: "7585:NAD83(2011) / WISCRS Waushara (m)"},
  {coordinate_system:"EPSG:7586", label: "7586:NAD83(2011) / WISCRS Wood (m)"},
  {coordinate_system:"EPSG:7587", label: "7587:NAD83(2011) / WISCRS Adams and Juneau (ftUS)"},
  {coordinate_system:"EPSG:7588", label: "7588:NAD83(2011) / WISCRS Ashland (ftUS)"},
  {coordinate_system:"EPSG:7589", label: "7589:NAD83(2011) / WISCRS Barron (ftUS)"},
  {coordinate_system:"EPSG:7590", label: "7590:NAD83(2011) / WISCRS Bayfield (ftUS)"},
  {coordinate_system:"EPSG:7591", label: "7591:NAD83(2011) / WISCRS Brown (ftUS)"},
  {coordinate_system:"EPSG:7592", label: "7592:NAD83(2011) / WISCRS Buffalo (ftUS)"},
  {coordinate_system:"EPSG:7593", label: "7593:NAD83(2011) / WISCRS Burnett (ftUS)"},
  {coordinate_system:"EPSG:7594", label: "7594:NAD83(2011) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (ftUS)"},
  {coordinate_system:"EPSG:7595", label: "7595:NAD83(2011) / WISCRS Chippewa (ftUS)"},
  {coordinate_system:"EPSG:7596", label: "7596:NAD83(2011) / WISCRS Clark (ftUS)"},
  {coordinate_system:"EPSG:7597", label: "7597:NAD83(2011) / WISCRS Columbia (ftUS)"},
  {coordinate_system:"EPSG:7598", label: "7598:NAD83(2011) / WISCRS Crawford (ftUS)"},
  {coordinate_system:"EPSG:7599", label: "7599:NAD83(2011) / WISCRS Dane (ftUS)"},
  {coordinate_system:"EPSG:7600", label: "7600:NAD83(2011) / WISCRS Dodge and Jefferson (ftUS)"},
  {coordinate_system:"EPSG:7601", label: "7601:NAD83(2011) / WISCRS Door (ftUS)"},
  {coordinate_system:"EPSG:7602", label: "7602:NAD83(2011) / WISCRS Douglas (ftUS)"},
  {coordinate_system:"EPSG:7603", label: "7603:NAD83(2011) / WISCRS Dunn (ftUS)"},
  {coordinate_system:"EPSG:7604", label: "7604:NAD83(2011) / WISCRS Eau Claire (ftUS)"},
  {coordinate_system:"EPSG:7605", label: "7605:NAD83(2011) / WISCRS Florence (ftUS)"},
  {coordinate_system:"EPSG:7606", label: "7606:NAD83(2011) / WISCRS Forest (ftUS)"},
  {coordinate_system:"EPSG:7607", label: "7607:NAD83(2011) / WISCRS Grant (ftUS)"},
  {coordinate_system:"EPSG:7608", label: "7608:NAD83(2011) / WISCRS Green and Lafayette (ftUS)"},
  {coordinate_system:"EPSG:7609", label: "7609:NAD83(2011) / WISCRS Green Lake and Marquette (ftUS)"},
  {coordinate_system:"EPSG:7610", label: "7610:NAD83(2011) / WISCRS Iowa (ftUS)"},
  {coordinate_system:"EPSG:7611", label: "7611:NAD83(2011) / WISCRS Iron (ftUS)"},
  {coordinate_system:"EPSG:7612", label: "7612:NAD83(2011) / WISCRS Jackson (ftUS)"},
  {coordinate_system:"EPSG:7613", label: "7613:NAD83(2011) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (ftUS)"},
  {coordinate_system:"EPSG:7614", label: "7614:NAD83(2011) / WISCRS Kewaunee, Manitowoc and Sheboygan (ftUS)"},
  {coordinate_system:"EPSG:7615", label: "7615:NAD83(2011) / WISCRS La Crosse (ftUS)"},
  {coordinate_system:"EPSG:7616", label: "7616:NAD83(2011) / WISCRS Langlade (ftUS)"},
  {coordinate_system:"EPSG:7617", label: "7617:NAD83(2011) / WISCRS Lincoln (ftUS)"},
  {coordinate_system:"EPSG:7618", label: "7618:NAD83(2011) / WISCRS Marathon (ftUS)"},
  {coordinate_system:"EPSG:7619", label: "7619:NAD83(2011) / WISCRS Marinette (ftUS)"},
  {coordinate_system:"EPSG:7620", label: "7620:NAD83(2011) / WISCRS Menominee (ftUS)"},
  {coordinate_system:"EPSG:7621", label: "7621:NAD83(2011) / WISCRS Monroe (ftUS)"},
  {coordinate_system:"EPSG:7622", label: "7622:NAD83(2011) / WISCRS Oconto (ftUS)"},
  {coordinate_system:"EPSG:7623", label: "7623:NAD83(2011) / WISCRS Oneida (ftUS)"},
  {coordinate_system:"EPSG:7624", label: "7624:NAD83(2011) / WISCRS Pepin and Pierce (ftUS)"},
  {coordinate_system:"EPSG:7625", label: "7625:NAD83(2011) / WISCRS Polk (ftUS)"},
  {coordinate_system:"EPSG:7626", label: "7626:NAD83(2011) / WISCRS Portage (ftUS)"},
  {coordinate_system:"EPSG:7627", label: "7627:NAD83(2011) / WISCRS Price (ftUS)"},
  {coordinate_system:"EPSG:7628", label: "7628:NAD83(2011) / WISCRS Richland (ftUS)"},
  {coordinate_system:"EPSG:7629", label: "7629:NAD83(2011) / WISCRS Rock (ftUS)"},
  {coordinate_system:"EPSG:7630", label: "7630:NAD83(2011) / WISCRS Rusk (ftUS)"},
  {coordinate_system:"EPSG:7631", label: "7631:NAD83(2011) / WISCRS Sauk (ftUS)"},
  {coordinate_system:"EPSG:7632", label: "7632:NAD83(2011) / WISCRS Sawyer (ftUS)"},
  {coordinate_system:"EPSG:7633", label: "7633:NAD83(2011) / WISCRS Shawano (ftUS)"},
  {coordinate_system:"EPSG:7634", label: "7634:NAD83(2011) / WISCRS St. Croix (ftUS)"},
  {coordinate_system:"EPSG:7635", label: "7635:NAD83(2011) / WISCRS Taylor (ftUS)"},
  {coordinate_system:"EPSG:7636", label: "7636:NAD83(2011) / WISCRS Trempealeau (ftUS)"},
  {coordinate_system:"EPSG:7637", label: "7637:NAD83(2011) / WISCRS Vernon (ftUS)"},
  {coordinate_system:"EPSG:7638", label: "7638:NAD83(2011) / WISCRS Vilas (ftUS)"},
  {coordinate_system:"EPSG:7639", label: "7639:NAD83(2011) / WISCRS Walworth (ftUS)"},
  {coordinate_system:"EPSG:7640", label: "7640:NAD83(2011) / WISCRS Washburn (ftUS)"},
  {coordinate_system:"EPSG:7641", label: "7641:NAD83(2011) / WISCRS Washington (ftUS)"},
  {coordinate_system:"EPSG:7642", label: "7642:NAD83(2011) / WISCRS Waukesha (ftUS)"},
  {coordinate_system:"EPSG:7643", label: "7643:NAD83(2011) / WISCRS Waupaca (ftUS)"},
  {coordinate_system:"EPSG:7644", label: "7644:NAD83(2011) / WISCRS Waushara (ftUS)"},
  {coordinate_system:"EPSG:7645", label: "7645:NAD83(2011) / WISCRS Wood (ftUS)"},
  {coordinate_system:"EPSG:7692", label: "7692:Kyrg-06 / zone 1"},
  {coordinate_system:"EPSG:7693", label: "7693:Kyrg-06 / zone 2"},
  {coordinate_system:"EPSG:7694", label: "7694:Kyrg-06 / zone 3"},
  {coordinate_system:"EPSG:7695", label: "7695:Kyrg-06 / zone 4"},
  {coordinate_system:"EPSG:7696", label: "7696:Kyrg-06 / zone 5"},
  {coordinate_system:"EPSG:7755", label: "7755:WGS 84 / India NSF LCC"},
  {coordinate_system:"EPSG:7756", label: "7756:WGS 84 / Andhra Pradesh"},
  {coordinate_system:"EPSG:7757", label: "7757:WGS 84 / Arunachal Pradesh"},
  {coordinate_system:"EPSG:7758", label: "7758:WGS 84 / Assam"},
  {coordinate_system:"EPSG:7759", label: "7759:WGS 84 / Bihar"},
  {coordinate_system:"EPSG:7760", label: "7760:WGS 84 / Delhi"},
  {coordinate_system:"EPSG:7761", label: "7761:WGS 84 / Gujarat"},
  {coordinate_system:"EPSG:7762", label: "7762:WGS 84 / Haryana"},
  {coordinate_system:"EPSG:7763", label: "7763:WGS 84 / Himachal Pradesh"},
  {coordinate_system:"EPSG:7764", label: "7764:WGS 84 / Jammu and Kashmir"},
  {coordinate_system:"EPSG:7765", label: "7765:WGS 84 / Jharkhand"},
  {coordinate_system:"EPSG:7766", label: "7766:WGS 84 / Madhya Pradesh"},
  {coordinate_system:"EPSG:7767", label: "7767:WGS 84 / Maharashtra"},
  {coordinate_system:"EPSG:7768", label: "7768:WGS 84 / Manipur"},
  {coordinate_system:"EPSG:7769", label: "7769:WGS 84 / Meghalaya"},
  {coordinate_system:"EPSG:7770", label: "7770:WGS 84 / Nagaland"},
  {coordinate_system:"EPSG:7771", label: "7771:WGS 84 / India Northeast"},
  {coordinate_system:"EPSG:7772", label: "7772:WGS 84 / Orissa"},
  {coordinate_system:"EPSG:7773", label: "7773:WGS 84 / Punjab"},
  {coordinate_system:"EPSG:7774", label: "7774:WGS 84 / Rajasthan"},
  {coordinate_system:"EPSG:7775", label: "7775:WGS 84 / Uttar Pradesh"},
  {coordinate_system:"EPSG:7776", label: "7776:WGS 84 / Uttaranchal"},
  {coordinate_system:"EPSG:7777", label: "7777:WGS 84 / Andaman and Nicobar"},
  {coordinate_system:"EPSG:7778", label: "7778:WGS 84 / Chhattisgarh"},
  {coordinate_system:"EPSG:7779", label: "7779:WGS 84 / Goa"},
  {coordinate_system:"EPSG:7780", label: "7780:WGS 84 / Karnataka"},
  {coordinate_system:"EPSG:7781", label: "7781:WGS 84 / Kerala"},
  {coordinate_system:"EPSG:7782", label: "7782:WGS 84 / Lakshadweep"},
  {coordinate_system:"EPSG:7783", label: "7783:WGS 84 / Mizoram"},
  {coordinate_system:"EPSG:7784", label: "7784:WGS 84 / Sikkim"},
  {coordinate_system:"EPSG:7785", label: "7785:WGS 84 / Tamil Nadu"},
  {coordinate_system:"EPSG:7786", label: "7786:WGS 84 / Tripura"},
  {coordinate_system:"EPSG:7787", label: "7787:WGS 84 / West Bengal"},
  {coordinate_system:"EPSG:7791", label: "7791:RDN2008 / UTM zone 32N"},
  {coordinate_system:"EPSG:7792", label: "7792:RDN2008 / UTM zone 33N"},
  {coordinate_system:"EPSG:7793", label: "7793:RDN2008 / UTM zone 34N"},
  {coordinate_system:"EPSG:7794", label: "7794:RDN2008 / Italy zone (E-N)"},
  {coordinate_system:"EPSG:7795", label: "7795:RDN2008 / Zone 12 (E-N)"},
  {coordinate_system:"EPSG:7799", label: "7799:BGS2005 / UTM zone 34N (N-E)"},
  {coordinate_system:"EPSG:7800", label: "7800:BGS2005 / UTM zone 35N (N-E)"},
  {coordinate_system:"EPSG:7801", label: "7801:BGS2005 / CCS2005"},
  {coordinate_system:"EPSG:7803", label: "7803:BGS2005 / UTM zone 34N"},
  {coordinate_system:"EPSG:7805", label: "7805:BGS2005 / UTM zone 36N"},
  {coordinate_system:"EPSG:7825", label: "7825:Pulkovo 1942 / CS63 zone X1"},
  {coordinate_system:"EPSG:7826", label: "7826:Pulkovo 1942 / CS63 zone X2"},
  {coordinate_system:"EPSG:7827", label: "7827:Pulkovo 1942 / CS63 zone X3"},
  {coordinate_system:"EPSG:7828", label: "7828:Pulkovo 1942 / CS63 zone X4"},
  {coordinate_system:"EPSG:7829", label: "7829:Pulkovo 1942 / CS63 zone X5"},
  {coordinate_system:"EPSG:7830", label: "7830:Pulkovo 1942 / CS63 zone X6"},
  {coordinate_system:"EPSG:7831", label: "7831:Pulkovo 1942 / CS63 zone X7"},
  {coordinate_system:"EPSG:7845", label: "7845:GDA2020 / GA LCC"},
  {coordinate_system:"EPSG:7846", label: "7846:GDA2020 / MGA zone 46"},
  {coordinate_system:"EPSG:7847", label: "7847:GDA2020 / MGA zone 47"},
  {coordinate_system:"EPSG:7848", label: "7848:GDA2020 / MGA zone 48"},
  {coordinate_system:"EPSG:7849", label: "7849:GDA2020 / MGA zone 49"},
  {coordinate_system:"EPSG:7850", label: "7850:GDA2020 / MGA zone 50"},
  {coordinate_system:"EPSG:7851", label: "7851:GDA2020 / MGA zone 51"},
  {coordinate_system:"EPSG:7852", label: "7852:GDA2020 / MGA zone 52"},
  {coordinate_system:"EPSG:7853", label: "7853:GDA2020 / MGA zone 53"},
  {coordinate_system:"EPSG:7854", label: "7854:GDA2020 / MGA zone 54"},
  {coordinate_system:"EPSG:7855", label: "7855:GDA2020 / MGA zone 55"},
  {coordinate_system:"EPSG:7856", label: "7856:GDA2020 / MGA zone 56"},
  {coordinate_system:"EPSG:7857", label: "7857:GDA2020 / MGA zone 57"},
  {coordinate_system:"EPSG:7858", label: "7858:GDA2020 / MGA zone 58"},
  {coordinate_system:"EPSG:7859", label: "7859:GDA2020 / MGA zone 59"},
  {coordinate_system:"EPSG:7877", label: "7877:Astro DOS 71 / SHLG71"},
  {coordinate_system:"EPSG:7878", label: "7878:Astro DOS 71 / UTM zone 30S"},
  {coordinate_system:"EPSG:7882", label: "7882:St. Helena Tritan / SHLG(Tritan)"},
  {coordinate_system:"EPSG:7883", label: "7883:St. Helena Tritan / UTM zone 30S"},
  {coordinate_system:"EPSG:7887", label: "7887:SHMG2015"},
  {coordinate_system:"EPSG:7899", label: "7899:GDA2020 / Vicgrid"},
  {coordinate_system:"EPSG:7991", label: "7991:NAD27 / MTM zone 10"},
  {coordinate_system:"EPSG:7992", label: "7992:Malongo 1987 / UTM zone 33S"},
  {coordinate_system:"EPSG:8013", label: "8013:GDA2020 / ALB2020"},
  {coordinate_system:"EPSG:8014", label: "8014:GDA2020 / BIO2020"},
  {coordinate_system:"EPSG:8015", label: "8015:GDA2020 / BRO2020"},
  {coordinate_system:"EPSG:8016", label: "8016:GDA2020 / BCG2020"},
  {coordinate_system:"EPSG:8017", label: "8017:GDA2020 / CARN2020"},
  {coordinate_system:"EPSG:8018", label: "8018:GDA2020 / CIG2020"},
  {coordinate_system:"EPSG:8019", label: "8019:GDA2020 / CKIG2020"},
  {coordinate_system:"EPSG:8020", label: "8020:GDA2020 / COL2020"},
  {coordinate_system:"EPSG:8021", label: "8021:GDA2020 / ESP2020"},
  {coordinate_system:"EPSG:8022", label: "8022:GDA2020 / EXM2020"},
  {coordinate_system:"EPSG:8023", label: "8023:GDA2020 / GCG2020"},
  {coordinate_system:"EPSG:8024", label: "8024:GDA2020 / GOLD2020"},
  {coordinate_system:"EPSG:8025", label: "8025:GDA2020 / JCG2020"},
  {coordinate_system:"EPSG:8026", label: "8026:GDA2020 / KALB2020"},
  {coordinate_system:"EPSG:8027", label: "8027:GDA2020 / KAR2020"},
  {coordinate_system:"EPSG:8028", label: "8028:GDA2020 / KUN2020"},
  {coordinate_system:"EPSG:8029", label: "8029:GDA2020 / LCG2020"},
  {coordinate_system:"EPSG:8030", label: "8030:GDA2020 / MRCG2020"},
  {coordinate_system:"EPSG:8031", label: "8031:GDA2020 / PCG2020"},
  {coordinate_system:"EPSG:8032", label: "8032:GDA2020 / PHG2020"},
  {coordinate_system:"EPSG:8035", label: "8035:WGS 84 / TM Zone 20N (ftUS)"},
  {coordinate_system:"EPSG:8036", label: "8036:WGS 84 / TM Zone 21N (ftUS)"},
  {coordinate_system:"EPSG:8044", label: "8044:Gusterberg Grid (Ferro)"},
  {coordinate_system:"EPSG:8045", label: "8045:St. Stephen Grid (Ferro)"},
  {coordinate_system:"EPSG:8058", label: "8058:GDA2020 / NSW Lambert"},
  {coordinate_system:"EPSG:8059", label: "8059:GDA2020 / SA Lambert"},
  {coordinate_system:"EPSG:8065", label: "8065:NAD83(2011) / PCCS zone 1 (ft)"},
  {coordinate_system:"EPSG:8066", label: "8066:NAD83(2011) / PCCS zone 2 (ft)"},
  {coordinate_system:"EPSG:8067", label: "8067:NAD83(2011) / PCCS zone 3 (ft)"},
  {coordinate_system:"EPSG:8068", label: "8068:NAD83(2011) / PCCS zone 4 (ft)"},
  {coordinate_system:"EPSG:8082", label: "8082:NAD83(CSRS)v6 / MTM NS 2010 zone 4"},
  {coordinate_system:"EPSG:8083", label: "8083:NAD83(CSRS)v6 / MTM NS 2010 zone 5"},
  {coordinate_system:"EPSG:8088", label: "8088:ISN2016 / Lambert 2016"},
  {coordinate_system:"EPSG:8090", label: "8090:NAD83(HARN) / WISCRS Florence (m)"},
  {coordinate_system:"EPSG:8091", label: "8091:NAD83(HARN) / WISCRS Florence (ftUS)"},
  {coordinate_system:"EPSG:8092", label: "8092:NAD83(HARN) / WISCRS Eau Claire (m)"},
  {coordinate_system:"EPSG:8093", label: "8093:NAD83(HARN) / WISCRS Eau Claire (ftUS)"},
  {coordinate_system:"EPSG:8095", label: "8095:NAD83(HARN) / WISCRS Wood (m)"},
  {coordinate_system:"EPSG:8096", label: "8096:NAD83(HARN) / WISCRS Wood (ftUS)"},
  {coordinate_system:"EPSG:8097", label: "8097:NAD83(HARN) / WISCRS Waushara (m)"},
  {coordinate_system:"EPSG:8098", label: "8098:NAD83(HARN) / WISCRS Waushara (ftUS)"},
  {coordinate_system:"EPSG:8099", label: "8099:NAD83(HARN) / WISCRS Waupaca (m)"},
  {coordinate_system:"EPSG:8100", label: "8100:NAD83(HARN) / WISCRS Waupaca (ftUS)"},
  {coordinate_system:"EPSG:8101", label: "8101:NAD83(HARN) / WISCRS Waukesha (m)"},
  {coordinate_system:"EPSG:8102", label: "8102:NAD83(HARN) / WISCRS Waukesha (ftUS)"},
  {coordinate_system:"EPSG:8103", label: "8103:NAD83(HARN) / WISCRS Washington (m)"},
  {coordinate_system:"EPSG:8104", label: "8104:NAD83(HARN) / WISCRS Washington (ftUS)"},
  {coordinate_system:"EPSG:8105", label: "8105:NAD83(HARN) / WISCRS Washburn (m)"},
  {coordinate_system:"EPSG:8106", label: "8106:NAD83(HARN) / WISCRS Washburn (ftUS)"},
  {coordinate_system:"EPSG:8107", label: "8107:NAD83(HARN) / WISCRS Walworth (m)"},
  {coordinate_system:"EPSG:8108", label: "8108:NAD83(HARN) / WISCRS Walworth (ftUS)"},
  {coordinate_system:"EPSG:8109", label: "8109:NAD83(HARN) / WISCRS Vilas (m)"},
  {coordinate_system:"EPSG:8110", label: "8110:NAD83(HARN) / WISCRS Vilas (ftUS)"},
  {coordinate_system:"EPSG:8111", label: "8111:NAD83(HARN) / WISCRS Vernon (m)"},
  {coordinate_system:"EPSG:8112", label: "8112:NAD83(HARN) / WISCRS Vernon (ftUS)"},
  {coordinate_system:"EPSG:8113", label: "8113:NAD83(HARN) / WISCRS Trempealeau (m)"},
  {coordinate_system:"EPSG:8114", label: "8114:NAD83(HARN) / WISCRS Trempealeau (ftUS)"},
  {coordinate_system:"EPSG:8115", label: "8115:NAD83(HARN) / WISCRS Taylor (m)"},
  {coordinate_system:"EPSG:8116", label: "8116:NAD83(HARN) / WISCRS Taylor (ftUS)"},
  {coordinate_system:"EPSG:8117", label: "8117:NAD83(HARN) / WISCRS St. Croix (m)"},
  {coordinate_system:"EPSG:8118", label: "8118:NAD83(HARN) / WISCRS St. Croix (ftUS)"},
  {coordinate_system:"EPSG:8119", label: "8119:NAD83(HARN) / WISCRS Shawano (m)"},
  {coordinate_system:"EPSG:8120", label: "8120:NAD83(HARN) / WISCRS Shawano (ftUS)"},
  {coordinate_system:"EPSG:8121", label: "8121:NAD83(HARN) / WISCRS Sawyer (m)"},
  {coordinate_system:"EPSG:8122", label: "8122:NAD83(HARN) / WISCRS Sawyer (ftUS)"},
  {coordinate_system:"EPSG:8123", label: "8123:NAD83(HARN) / WISCRS Sauk (m)"},
  {coordinate_system:"EPSG:8124", label: "8124:NAD83(HARN) / WISCRS Sauk (ftUS)"},
  {coordinate_system:"EPSG:8125", label: "8125:NAD83(HARN) / WISCRS Rusk (m)"},
  {coordinate_system:"EPSG:8126", label: "8126:NAD83(HARN) / WISCRS Rusk (ftUS)"},
  {coordinate_system:"EPSG:8127", label: "8127:NAD83(HARN) / WISCRS Rock (m)"},
  {coordinate_system:"EPSG:8128", label: "8128:NAD83(HARN) / WISCRS Rock (ftUS)"},
  {coordinate_system:"EPSG:8129", label: "8129:NAD83(HARN) / WISCRS Richland (m)"},
  {coordinate_system:"EPSG:8130", label: "8130:NAD83(HARN) / WISCRS Richland (ftUS)"},
  {coordinate_system:"EPSG:8131", label: "8131:NAD83(HARN) / WISCRS Price (m)"},
  {coordinate_system:"EPSG:8132", label: "8132:NAD83(HARN) / WISCRS Price (ftUS)"},
  {coordinate_system:"EPSG:8133", label: "8133:NAD83(HARN) / WISCRS Portage (m)"},
  {coordinate_system:"EPSG:8134", label: "8134:NAD83(HARN) / WISCRS Portage (ftUS)"},
  {coordinate_system:"EPSG:8135", label: "8135:NAD83(HARN) / WISCRS Polk (m)"},
  {coordinate_system:"EPSG:8136", label: "8136:NAD83(HARN) / WISCRS Polk (ftUS)"},
  {coordinate_system:"EPSG:8137", label: "8137:NAD83(HARN) / WISCRS Pepin and Pierce (m)"},
  {coordinate_system:"EPSG:8138", label: "8138:NAD83(HARN) / WISCRS Pepin and Pierce (ftUS)"},
  {coordinate_system:"EPSG:8139", label: "8139:NAD83(HARN) / WISCRS Oneida (m)"},
  {coordinate_system:"EPSG:8140", label: "8140:NAD83(HARN) / WISCRS Oneida (ftUS)"},
  {coordinate_system:"EPSG:8141", label: "8141:NAD83(HARN) / WISCRS Oconto (m)"},
  {coordinate_system:"EPSG:8142", label: "8142:NAD83(HARN) / WISCRS Oconto (ftUS)"},
  {coordinate_system:"EPSG:8143", label: "8143:NAD83(HARN) / WISCRS Monroe (m)"},
  {coordinate_system:"EPSG:8144", label: "8144:NAD83(HARN) / WISCRS Monroe (ftUS)"},
  {coordinate_system:"EPSG:8145", label: "8145:NAD83(HARN) / WISCRS Menominee (m)"},
  {coordinate_system:"EPSG:8146", label: "8146:NAD83(HARN) / WISCRS Menominee (ftUS)"},
  {coordinate_system:"EPSG:8147", label: "8147:NAD83(HARN) / WISCRS Marinette (m)"},
  {coordinate_system:"EPSG:8148", label: "8148:NAD83(HARN) / WISCRS Marinette (ftUS)"},
  {coordinate_system:"EPSG:8149", label: "8149:NAD83(HARN) / WISCRS Marathon (m)"},
  {coordinate_system:"EPSG:8150", label: "8150:NAD83(HARN) / WISCRS Marathon (ftUS)"},
  {coordinate_system:"EPSG:8151", label: "8151:NAD83(HARN) / WISCRS Lincoln (m)"},
  {coordinate_system:"EPSG:8152", label: "8152:NAD83(HARN) / WISCRS Lincoln (ftUS)"},
  {coordinate_system:"EPSG:8153", label: "8153:NAD83(HARN) / WISCRS Langlade (m)"},
  {coordinate_system:"EPSG:8154", label: "8154:NAD83(HARN) / WISCRS Langlade (ftUS)"},
  {coordinate_system:"EPSG:8155", label: "8155:NAD83(HARN) / WISCRS La Crosse (m)"},
  {coordinate_system:"EPSG:8156", label: "8156:NAD83(HARN) / WISCRS La Crosse (ftUS)"},
  {coordinate_system:"EPSG:8157", label: "8157:NAD83(HARN) / WISCRS Kewaunee, Manitowoc and Sheboygan (m)"},
  {coordinate_system:"EPSG:8158", label: "8158:NAD83(HARN) / WISCRS Kewaunee, Manitowoc and Sheboygan (ftUS)"},
  {coordinate_system:"EPSG:8159", label: "8159:NAD83(HARN) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (m)"},
  {coordinate_system:"EPSG:8160", label: "8160:NAD83(HARN) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (ftUS)"},
  {coordinate_system:"EPSG:8161", label: "8161:NAD83(HARN) / WISCRS Jackson (m)"},
  {coordinate_system:"EPSG:8162", label: "8162:NAD83(HARN) / WISCRS Jackson (ftUS)"},
  {coordinate_system:"EPSG:8163", label: "8163:NAD83(HARN) / WISCRS Iron (m)"},
  {coordinate_system:"EPSG:8164", label: "8164:NAD83(HARN) / WISCRS Iron (ftUS)"},
  {coordinate_system:"EPSG:8165", label: "8165:NAD83(HARN) / WISCRS Iowa (m)"},
  {coordinate_system:"EPSG:8166", label: "8166:NAD83(HARN) / WISCRS Iowa (ftUS)"},
  {coordinate_system:"EPSG:8167", label: "8167:NAD83(HARN) / WISCRS Green Lake and Marquette (m)"},
  {coordinate_system:"EPSG:8168", label: "8168:NAD83(HARN) / WISCRS Green Lake and Marquette (ftUS)"},
  {coordinate_system:"EPSG:8169", label: "8169:NAD83(HARN) / WISCRS Green and Lafayette (m)"},
  {coordinate_system:"EPSG:8170", label: "8170:NAD83(HARN) / WISCRS Green and Lafayette (ftUS)"},
  {coordinate_system:"EPSG:8171", label: "8171:NAD83(HARN) / WISCRS Grant (m)"},
  {coordinate_system:"EPSG:8172", label: "8172:NAD83(HARN) / WISCRS Grant (ftUS)"},
  {coordinate_system:"EPSG:8173", label: "8173:NAD83(HARN) / WISCRS Forest (m)"},
  {coordinate_system:"EPSG:8177", label: "8177:NAD83(HARN) / WISCRS Forest (ftUS)"},
  {coordinate_system:"EPSG:8179", label: "8179:NAD83(HARN) / WISCRS Dunn (m)"},
  {coordinate_system:"EPSG:8180", label: "8180:NAD83(HARN) / WISCRS Dunn (ftUS)"},
  {coordinate_system:"EPSG:8181", label: "8181:NAD83(HARN) / WISCRS Douglas (m)"},
  {coordinate_system:"EPSG:8182", label: "8182:NAD83(HARN) / WISCRS Douglas (ftUS)"},
  {coordinate_system:"EPSG:8184", label: "8184:NAD83(HARN) / WISCRS Door (m)"},
  {coordinate_system:"EPSG:8185", label: "8185:NAD83(HARN) / WISCRS Door (ftUS)"},
  {coordinate_system:"EPSG:8187", label: "8187:NAD83(HARN) / WISCRS Dodge and Jefferson (m)"},
  {coordinate_system:"EPSG:8189", label: "8189:NAD83(HARN) / WISCRS Dodge and Jefferson (ftUS)"},
  {coordinate_system:"EPSG:8191", label: "8191:NAD83(HARN) / WISCRS Dane (m)"},
  {coordinate_system:"EPSG:8193", label: "8193:NAD83(HARN) / WISCRS Dane (ftUS)"},
  {coordinate_system:"EPSG:8196", label: "8196:NAD83(HARN) / WISCRS Crawford (m)"},
  {coordinate_system:"EPSG:8197", label: "8197:NAD83(HARN) / WISCRS Crawford (ftUS)"},
  {coordinate_system:"EPSG:8198", label: "8198:NAD83(HARN) / WISCRS Columbia (m)"},
  {coordinate_system:"EPSG:8200", label: "8200:NAD83(HARN) / WISCRS Columbia (ftUS)"},
  {coordinate_system:"EPSG:8201", label: "8201:NAD83(HARN) / WISCRS Clark (m)"},
  {coordinate_system:"EPSG:8202", label: "8202:NAD83(HARN) / WISCRS Clark (ftUS)"},
  {coordinate_system:"EPSG:8203", label: "8203:NAD83(HARN) / WISCRS Chippewa (m)"},
  {coordinate_system:"EPSG:8204", label: "8204:NAD83(HARN) / WISCRS Chippewa (ftUS)"},
  {coordinate_system:"EPSG:8205", label: "8205:NAD83(HARN) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (m)"},
  {coordinate_system:"EPSG:8206", label: "8206:NAD83(HARN) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (ftUS)"},
  {coordinate_system:"EPSG:8207", label: "8207:NAD83(HARN) / WISCRS Burnett (m)"},
  {coordinate_system:"EPSG:8208", label: "8208:NAD83(HARN) / WISCRS Burnett (ftUS)"},
  {coordinate_system:"EPSG:8209", label: "8209:NAD83(HARN) / WISCRS Buffalo (m)"},
  {coordinate_system:"EPSG:8210", label: "8210:NAD83(HARN) / WISCRS Buffalo (ftUS)"},
  {coordinate_system:"EPSG:8212", label: "8212:NAD83(HARN) / WISCRS Brown (m)"},
  {coordinate_system:"EPSG:8213", label: "8213:NAD83(HARN) / WISCRS Brown (ftUS)"},
  {coordinate_system:"EPSG:8214", label: "8214:NAD83(HARN) / WISCRS Bayfield (m)"},
  {coordinate_system:"EPSG:8216", label: "8216:NAD83(HARN) / WISCRS Bayfield (ftUS)"},
  {coordinate_system:"EPSG:8218", label: "8218:NAD83(HARN) / WISCRS Barron (m)"},
  {coordinate_system:"EPSG:8220", label: "8220:NAD83(HARN) / WISCRS Barron (ftUS)"},
  {coordinate_system:"EPSG:8222", label: "8222:NAD83(HARN) / WISCRS Ashland (m)"},
  {coordinate_system:"EPSG:8224", label: "8224:NAD83(HARN) / WISCRS Ashland (ftUS)"},
  {coordinate_system:"EPSG:8225", label: "8225:NAD83(HARN) / WISCRS Adams and Juneau (m)"},
  {coordinate_system:"EPSG:8226", label: "8226:NAD83(HARN) / WISCRS Adams and Juneau (ftUS)"},
  {coordinate_system:"EPSG:8311", label: "8311:NAD83(2011) / Oregon Burns-Harper zone (m)"},
  {coordinate_system:"EPSG:8312", label: "8312:NAD83(2011) / Oregon Burns-Harper zone (ft)"},
  {coordinate_system:"EPSG:8313", label: "8313:NAD83(2011) / Oregon Canyon City-Burns zone (m)"},
  {coordinate_system:"EPSG:8314", label: "8314:NAD83(2011) / Oregon Canyon City-Burns zone (ft)"},
  {coordinate_system:"EPSG:8315", label: "8315:NAD83(2011) / Oregon Coast Range North zone (m)"},
  {coordinate_system:"EPSG:8316", label: "8316:NAD83(2011) / Oregon Coast Range North zone (ft)"},
  {coordinate_system:"EPSG:8317", label: "8317:NAD83(2011) / Oregon Dayville-Prairie City zone (m)"},
  {coordinate_system:"EPSG:8318", label: "8318:NAD83(2011) / Oregon Dayville-Prairie City zone (ft)"},
  {coordinate_system:"EPSG:8319", label: "8319:NAD83(2011) / Oregon Denio-Burns zone (m)"},
  {coordinate_system:"EPSG:8320", label: "8320:NAD83(2011) / Oregon Denio-Burns zone (ft)"},
  {coordinate_system:"EPSG:8321", label: "8321:NAD83(2011) / Oregon Halfway zone (m)"},
  {coordinate_system:"EPSG:8322", label: "8322:NAD83(2011) / Oregon Halfway zone (ft)"},
  {coordinate_system:"EPSG:8323", label: "8323:NAD83(2011) / Oregon Medford-Diamond Lake zone (m)"},
  {coordinate_system:"EPSG:8324", label: "8324:NAD83(2011) / Oregon Medford-Diamond Lake zone (ft)"},
  {coordinate_system:"EPSG:8325", label: "8325:NAD83(2011) / Oregon Mitchell zone (m)"},
  {coordinate_system:"EPSG:8326", label: "8326:NAD83(2011) / Oregon Mitchell zone (ft)"},
  {coordinate_system:"EPSG:8327", label: "8327:NAD83(2011) / Oregon North Central zone (m)"},
  {coordinate_system:"EPSG:8328", label: "8328:NAD83(2011) / Oregon North Central zone (ft)"},
  {coordinate_system:"EPSG:8329", label: "8329:NAD83(2011) / Oregon Ochoco Summit zone (m)"},
  {coordinate_system:"EPSG:8330", label: "8330:NAD83(2011) / Oregon Ochoco Summit zone (ft)"},
  {coordinate_system:"EPSG:8331", label: "8331:NAD83(2011) / Oregon Owyhee zone (m)"},
  {coordinate_system:"EPSG:8332", label: "8332:NAD83(2011) / Oregon Owyhee zone (ft)"},
  {coordinate_system:"EPSG:8333", label: "8333:NAD83(2011) / Oregon Pilot Rock-Ukiah zone (m)"},
  {coordinate_system:"EPSG:8334", label: "8334:NAD83(2011) / Oregon Pilot Rock-Ukiah zone (ft)"},
  {coordinate_system:"EPSG:8335", label: "8335:NAD83(2011) / Oregon Prairie City-Brogan zone (m)"},
  {coordinate_system:"EPSG:8336", label: "8336:NAD83(2011) / Oregon Prairie City-Brogan zone (ft)"},
  {coordinate_system:"EPSG:8337", label: "8337:NAD83(2011) / Oregon Riley-Lakeview zone (m)"},
  {coordinate_system:"EPSG:8338", label: "8338:NAD83(2011) / Oregon Riley-Lakeview zone (ft)"},
  {coordinate_system:"EPSG:8339", label: "8339:NAD83(2011) / Oregon Siskiyou Pass zone (m)"},
  {coordinate_system:"EPSG:8340", label: "8340:NAD83(2011) / Oregon Siskiyou Pass zone (ft)"},
  {coordinate_system:"EPSG:8341", label: "8341:NAD83(2011) / Oregon Ukiah-Fox zone (m)"},
  {coordinate_system:"EPSG:8342", label: "8342:NAD83(2011) / Oregon Ukiah-Fox zone (ft)"},
  {coordinate_system:"EPSG:8343", label: "8343:NAD83(2011) / Oregon Wallowa zone (m)"},
  {coordinate_system:"EPSG:8344", label: "8344:NAD83(2011) / Oregon Wallowa zone (ft)"},
  {coordinate_system:"EPSG:8345", label: "8345:NAD83(2011) / Oregon Warner Highway zone (m)"},
  {coordinate_system:"EPSG:8346", label: "8346:NAD83(2011) / Oregon Warner Highway zone (ft)"},
  {coordinate_system:"EPSG:8347", label: "8347:NAD83(2011) / Oregon Willamette Pass zone (m)"},
  {coordinate_system:"EPSG:8348", label: "8348:NAD83(2011) / Oregon Willamette Pass zone (ft)"},
  {coordinate_system:"EPSG:8352", label: "8352:S-JTSK [JTSK03] / Krovak"},
  {coordinate_system:"EPSG:8353", label: "8353:S-JTSK [JTSK03] / Krovak East North"},
  {coordinate_system:"EPSG:8379", label: "8379:NAD83 / NCRS Las Vegas (m)"},
  {coordinate_system:"EPSG:8380", label: "8380:NAD83 / NCRS Las Vegas (ftUS)"},
  {coordinate_system:"EPSG:8381", label: "8381:NAD83 / NCRS Las Vegas high (m)"},
  {coordinate_system:"EPSG:8382", label: "8382:NAD83 / NCRS Las Vegas high (ftUS)"},
  {coordinate_system:"EPSG:8383", label: "8383:NAD83(2011) / NCRS Las Vegas (m)"},
  {coordinate_system:"EPSG:8384", label: "8384:NAD83(2011) / NCRS Las Vegas (ftUS)"},
  {coordinate_system:"EPSG:8385", label: "8385:NAD83(2011) / NCRS Las Vegas high (m)"},
  {coordinate_system:"EPSG:8387", label: "8387:NAD83(2011) / NCRS Las Vegas high (ftUS)"},
  {coordinate_system:"EPSG:8391", label: "8391:GDA94 / WEIPA94"},
  {coordinate_system:"EPSG:8395", label: "8395:ETRS89 / Gauss-Kruger CM 9E"},
  {coordinate_system:"EPSG:8433", label: "8433:Macao 1920 / Macao Grid"},
  {coordinate_system:"EPSG:8441", label: "8441:Tananarive / Laborde Grid"},
  {coordinate_system:"EPSG:8455", label: "8455:RGTAAF07 / UTM zone 53S"},
  {coordinate_system:"EPSG:8456", label: "8456:RGTAAF07 / UTM zone 54S"},
  {coordinate_system:"EPSG:8518", label: "8518:NAD83(2011) / KS RCS zone 1"},
  {coordinate_system:"EPSG:8519", label: "8519:NAD83(2011) / KS RCS zone 2"},
  {coordinate_system:"EPSG:8520", label: "8520:NAD83(2011) / KS RCS zone 3"},
  {coordinate_system:"EPSG:8521", label: "8521:NAD83(2011) / KS RCS zone 4"},
  {coordinate_system:"EPSG:8522", label: "8522:NAD83(2011) / KS RCS zone 5"},
  {coordinate_system:"EPSG:8523", label: "8523:NAD83(2011) / KS RCS zone 6"},
  {coordinate_system:"EPSG:8524", label: "8524:NAD83(2011) / KS RCS zone 7"},
  {coordinate_system:"EPSG:8525", label: "8525:NAD83(2011) / KS RCS zone 8"},
  {coordinate_system:"EPSG:8526", label: "8526:NAD83(2011) / KS RCS zone 9"},
  {coordinate_system:"EPSG:8527", label: "8527:NAD83(2011) / KS RCS zone 10"},
  {coordinate_system:"EPSG:8528", label: "8528:NAD83(2011) / KS RCS zone 11"},
  {coordinate_system:"EPSG:8529", label: "8529:NAD83(2011) / KS RCS zone 12"},
  {coordinate_system:"EPSG:8531", label: "8531:NAD83(2011) / KS RCS zone 13"},
  {coordinate_system:"EPSG:8533", label: "8533:NAD83(2011) / KS RCS zone 14"},
  {coordinate_system:"EPSG:8534", label: "8534:NAD83(2011) / KS RCS zone 15"},
  {coordinate_system:"EPSG:8535", label: "8535:NAD83(2011) / KS RCS zone 16"},
  {coordinate_system:"EPSG:8536", label: "8536:NAD83(2011) / KS RCS zone 17"},
  {coordinate_system:"EPSG:8538", label: "8538:NAD83(2011) / KS RCS zone 18"},
  {coordinate_system:"EPSG:8539", label: "8539:NAD83(2011) / KS RCS zone 19"},
  {coordinate_system:"EPSG:8540", label: "8540:NAD83(2011) / KS RCS zone 20"},
  {coordinate_system:"EPSG:8677", label: "8677:MGI 1901 / Balkans zone 5"},
  {coordinate_system:"EPSG:8678", label: "8678:MGI 1901 / Balkans zone 6"},
  {coordinate_system:"EPSG:8679", label: "8679:MGI 1901 / Balkans zone 8"},
  {coordinate_system:"EPSG:8682", label: "8682:SRB_ETRS89 / UTM zone 34N"},
  {coordinate_system:"EPSG:8686", label: "8686:MGI 1901 / Slovenia Grid"},
  {coordinate_system:"EPSG:8687", label: "8687:Slovenia 1996 / UTM zone 33N"},
  {coordinate_system:"EPSG:8692", label: "8692:NAD83(MA11) / UTM zone 54N"},
  {coordinate_system:"EPSG:8693", label: "8693:NAD83(MA11) / UTM zone 55N"},
  {coordinate_system:"EPSG:8826", label: "8826:NAD83 / Idaho Transverse Mercator"},
  {coordinate_system:"EPSG:8836", label: "8836:MTRF-2000 / UTM zone 36N"},
  {coordinate_system:"EPSG:8837", label: "8837:MTRF-2000 / UTM zone 37N"},
  {coordinate_system:"EPSG:8838", label: "8838:MTRF-2000 / UTM zone 38N"},
  {coordinate_system:"EPSG:8839", label: "8839:MTRF-2000 / UTM zone 39N"},
  {coordinate_system:"EPSG:8840", label: "8840:MTRF-2000 / UTM zone 40N"},
  {coordinate_system:"EPSG:8857", label: "8857:WGS 84 / Equal Earth Greenwich"},
  {coordinate_system:"EPSG:8858", label: "8858:WGS 84 / Equal Earth Americas"},
  {coordinate_system:"EPSG:8859", label: "8859:WGS 84 / Equal Earth Asia-Pacific"},
  {coordinate_system:"EPSG:8903", label: "8903:RGWF96 / UTM zone 1S"},
  {coordinate_system:"EPSG:8908", label: "8908:CR-SIRGAS / CRTM05"},
  {coordinate_system:"EPSG:8909", label: "8909:CR-SIRGAS / UTM zone 16N"},
  {coordinate_system:"EPSG:8910", label: "8910:CR-SIRGAS / UTM zone 17N"},
  {coordinate_system:"EPSG:8950", label: "8950:SIRGAS-Chile 2010 / UTM zone 18S"},
  {coordinate_system:"EPSG:8951", label: "8951:SIRGAS-Chile 2010 / UTM zone 19S"},
  {coordinate_system:"EPSG:9039", label: "9039:ISN2016 / LAEA Europe"},
  {coordinate_system:"EPSG:9040", label: "9040:ISN2016 / LCC Europe"},
  {coordinate_system:"EPSG:9141", label: "9141:KOSOVAREF01 / Balkans zone 7"},
  {coordinate_system:"EPSG:9149", label: "9149:SIRGAS-Chile 2013 / UTM zone 18S"},
  {coordinate_system:"EPSG:9150", label: "9150:SIRGAS-Chile 2013 / UTM zone 19S"},
  {coordinate_system:"EPSG:9154", label: "9154:SIRGAS-Chile 2016 / UTM zone 18S"},
  {coordinate_system:"EPSG:9155", label: "9155:SIRGAS-Chile 2016 / UTM zone 19S"},
  {coordinate_system:"EPSG:9156", label: "9156:RSAO13 / UTM zone 32S"},
  {coordinate_system:"EPSG:9157", label: "9157:RSAO13 / UTM zone 33S"},
  {coordinate_system:"EPSG:9158", label: "9158:RSAO13 / UTM zone 34S"},
  {coordinate_system:"EPSG:9159", label: "9159:RSAO13 / TM 12 SE"},
  {coordinate_system:"EPSG:9191", label: "9191:WGS 84 / NIWA Albers"},
  {coordinate_system:"EPSG:9205", label: "9205:VN-2000 / TM-3 103-00"},
  {coordinate_system:"EPSG:9206", label: "9206:VN-2000 / TM-3 104-00"},
  {coordinate_system:"EPSG:9207", label: "9207:VN-2000 / TM-3 104-30"},
  {coordinate_system:"EPSG:9208", label: "9208:VN-2000 / TM-3 104-45"},
  {coordinate_system:"EPSG:9209", label: "9209:VN-2000 / TM-3 105-30"},
  {coordinate_system:"EPSG:9210", label: "9210:VN-2000 / TM-3 105-45"},
  {coordinate_system:"EPSG:9211", label: "9211:VN-2000 / TM-3 106-00"},
  {coordinate_system:"EPSG:9212", label: "9212:VN-2000 / TM-3 106-15"},
  {coordinate_system:"EPSG:9213", label: "9213:VN-2000 / TM-3 106-30"},
  {coordinate_system:"EPSG:9214", label: "9214:VN-2000 / TM-3 107-00"},
  {coordinate_system:"EPSG:9215", label: "9215:VN-2000 / TM-3 107-15"},
  {coordinate_system:"EPSG:9216", label: "9216:VN-2000 / TM-3 107-30"},
  {coordinate_system:"EPSG:9217", label: "9217:VN-2000 / TM-3 108-15"},
  {coordinate_system:"EPSG:9218", label: "9218:VN-2000 / TM-3 108-30"},
  {coordinate_system:"EPSG:9221", label: "9221:Hartebeesthoek94 / ZAF BSU Albers 25E"},
  {coordinate_system:"EPSG:9222", label: "9222:Hartebeesthoek94 / ZAF BSU Albers 44E"},
  {coordinate_system:"EPSG:9249", label: "9249:Tapi Aike / Argentina 1"},
  {coordinate_system:"EPSG:9250", label: "9250:Tapi Aike / Argentina 2"},
  {coordinate_system:"EPSG:9252", label: "9252:MMN / Argentina 2"},
  {coordinate_system:"EPSG:9254", label: "9254:MMS / Argentina 2"},
  {coordinate_system:"EPSG:9265", label: "9265:POSGAR 2007 / UTM zone 19S"},
  {coordinate_system:"EPSG:9271", label: "9271:MGI / Austria West"},
  {coordinate_system:"EPSG:9272", label: "9272:MGI / Austria Central"},
  {coordinate_system:"EPSG:9273", label: "9273:MGI / Austria East"},
  {coordinate_system:"EPSG:9284", label: "9284:Pampa del Castillo / Argentina 1"},
  {coordinate_system:"EPSG:9285", label: "9285:Pampa del Castillo / Argentina 3"},
  {coordinate_system:"EPSG:9295", label: "9295:ONGD17 / UTM zone 39N"},
  {coordinate_system:"EPSG:9296", label: "9296:ONGD17 / UTM zone 40N"},
  {coordinate_system:"EPSG:9297", label: "9297:ONGD17 / UTM zone 41N"},
  {coordinate_system:"EPSG:9300", label: "9300:HS2 Survey Grid"},
  {coordinate_system:"EPSG:9311", label: "9311:NAD27 / US National Atlas Equal Area"},
  {coordinate_system:"EPSG:9354", label: "9354:WGS 84 / IBCSO Polar Stereographic"},
  {coordinate_system:"EPSG:9356", label: "9356:KSA-GRF17 / UTM zone 36N"},
  {coordinate_system:"EPSG:9357", label: "9357:KSA-GRF17 / UTM zone 37N"},
  {coordinate_system:"EPSG:9358", label: "9358:KSA-GRF17 / UTM zone 38N"},
  {coordinate_system:"EPSG:9359", label: "9359:KSA-GRF17 / UTM zone 39N"},
  {coordinate_system:"EPSG:9360", label: "9360:KSA-GRF17 / UTM zone 40N"},
  {coordinate_system:"EPSG:9367", label: "9367:TPEN11 Grid"},
  {coordinate_system:"EPSG:9373", label: "9373:MML07 Grid"},
  {coordinate_system:"EPSG:9377", label: "9377:MAGNA-SIRGAS / Origen-Nacional"},
  {coordinate_system:"EPSG:9387", label: "9387:AbInvA96_2020 Grid"},
  {coordinate_system:"EPSG:9391", label: "9391:BGS2005 / UTM zone 35N"},
  {coordinate_system:"EPSG:9404", label: "9404:PN68 / UTM zone 27N"},
  {coordinate_system:"EPSG:9405", label: "9405:PN68 / UTM zone 28N"},
  {coordinate_system:"EPSG:9406", label: "9406:PN84 / UTM zone 27N"},
  {coordinate_system:"EPSG:9407", label: "9407:PN84 / UTM zone 28N"},
  {coordinate_system:"EPSG:9456", label: "9456:GBK19 Grid"},
  {coordinate_system:"EPSG:9473", label: "9473:GDA2020 / Australian Albers"},
  {coordinate_system:"EPSG:9476", label: "9476:SRGI2013 / UTM zone 46N"},
  {coordinate_system:"EPSG:9477", label: "9477:SRGI2013 / UTM zone 47N"},
  {coordinate_system:"EPSG:9478", label: "9478:SRGI2013 / UTM zone 48N"},
  {coordinate_system:"EPSG:9479", label: "9479:SRGI2013 / UTM zone 49N"},
  {coordinate_system:"EPSG:9480", label: "9480:SRGI2013 / UTM zone 50N"},
  {coordinate_system:"EPSG:9481", label: "9481:SRGI2013 / UTM zone 51N"},
  {coordinate_system:"EPSG:9482", label: "9482:SRGI2013 / UTM zone 52N"},
  {coordinate_system:"EPSG:9487", label: "9487:SRGI2013 / UTM zone 47S"},
  {coordinate_system:"EPSG:9488", label: "9488:SRGI2013 / UTM zone 48S"},
  {coordinate_system:"EPSG:9489", label: "9489:SRGI2013 / UTM zone 49S"},
  {coordinate_system:"EPSG:9490", label: "9490:SRGI2013 / UTM zone 50S"},
  {coordinate_system:"EPSG:9491", label: "9491:SRGI2013 / UTM zone 51S"},
  {coordinate_system:"EPSG:9492", label: "9492:SRGI2013 / UTM zone 52S"},
  {coordinate_system:"EPSG:9493", label: "9493:SRGI2013 / UTM zone 53S"},
  {coordinate_system:"EPSG:9494", label: "9494:SRGI2013 / UTM zone 54S"},
  {coordinate_system:"EPSG:9498", label: "9498:POSGAR 2007 / CABA 2019"},
  {coordinate_system:"EPSG:9674", label: "9674:NAD83 / USFS R6 Albers"},
  {coordinate_system:"EPSG:9678", label: "9678:Gulshan 303 / Bangladesh Transverse Mercator"},
  {coordinate_system:"EPSG:9680", label: "9680:WGS 84 / TM 90 NE"},
  {coordinate_system:"EPSG:9697", label: "9697:REDGEOMIN / UTM zone 12S"},
  {coordinate_system:"EPSG:9698", label: "9698:REDGEOMIN / UTM zone 18S"},
  {coordinate_system:"EPSG:9699", label: "9699:REDGEOMIN / UTM zone 19S"},
  {coordinate_system:"EPSG:9709", label: "9709:NAD83(CSRS) / UTM zone 23N"},
  {coordinate_system:"EPSG:9712", label: "9712:NAD83 / UTM zone 24N"},
  {coordinate_system:"EPSG:9713", label: "9713:NAD83(CSRS) / UTM zone 24N"},
  {coordinate_system:"EPSG:9716", label: "9716:IGM95 / UTM zone 34N"},
  {coordinate_system:"EPSG:9741", label: "9741:EOS21 Grid"},
  {coordinate_system:"EPSG:9748", label: "9748:NAD83(2011) / Alabama East (ftUS)"},
  {coordinate_system:"EPSG:9749", label: "9749:NAD83(2011) / Alabama West (ftUS)"},
  {coordinate_system:"EPSG:9761", label: "9761:ECML14_NB Grid"},
  {coordinate_system:"EPSG:9766", label: "9766:EWR2 Grid"},
  {coordinate_system:"EPSG:9793", label: "9793:RGF93 v2 / Lambert-93"},
  {coordinate_system:"EPSG:9794", label: "9794:RGF93 v2b / Lambert-93"},
  {coordinate_system:"EPSG:9821", label: "9821:UCS-2000 / LCS-32 Kyiv region"},
  {coordinate_system:"EPSG:9822", label: "9822:RGF93 v2 / CC42"},
  {coordinate_system:"EPSG:9823", label: "9823:RGF93 v2 / CC43"},
  {coordinate_system:"EPSG:9824", label: "9824:RGF93 v2 / CC44"},
  {coordinate_system:"EPSG:9825", label: "9825:RGF93 v2 / CC45"},
  {coordinate_system:"EPSG:9826", label: "9826:RGF93 v2 / CC46"},
  {coordinate_system:"EPSG:9827", label: "9827:RGF93 v2 / CC47"},
  {coordinate_system:"EPSG:9828", label: "9828:RGF93 v2 / CC48"},
  {coordinate_system:"EPSG:9829", label: "9829:RGF93 v2 / CC49"},
  {coordinate_system:"EPSG:9830", label: "9830:RGF93 v2 / CC50"},
  {coordinate_system:"EPSG:9831", label: "9831:UCS-2000 / LCS-01 Crimea"},
  {coordinate_system:"EPSG:9832", label: "9832:UCS-2000 / LCS-05 Vinnytsia"},
  {coordinate_system:"EPSG:9833", label: "9833:UCS-2000 / LCS-07 Volyn"},
  {coordinate_system:"EPSG:9834", label: "9834:UCS-2000 / LCS-12 Dnipropetrovsk"},
  {coordinate_system:"EPSG:9835", label: "9835:UCS-2000 / LCS-14 Donetsk"},
  {coordinate_system:"EPSG:9836", label: "9836:UCS-2000 / LCS-18 Zhytomyr"},
  {coordinate_system:"EPSG:9837", label: "9837:UCS-2000 / LCS-21 Zakarpattia"},
  {coordinate_system:"EPSG:9838", label: "9838:UCS-2000 / LCS-23 Zaporizhzhia"},
  {coordinate_system:"EPSG:9839", label: "9839:UCS-2000 / LCS-26 Ivano-Frankivsk"},
  {coordinate_system:"EPSG:9840", label: "9840:UCS-2000 / LCS-35 Kirovohrad"},
  {coordinate_system:"EPSG:9841", label: "9841:UCS-2000 / LCS-44 Luhansk"},
  {coordinate_system:"EPSG:9842", label: "9842:RGF93 v2b / CC42"},
  {coordinate_system:"EPSG:9843", label: "9843:RGF93 v2b / CC43"},
  {coordinate_system:"EPSG:9844", label: "9844:RGF93 v2b / CC44"},
  {coordinate_system:"EPSG:9845", label: "9845:RGF93 v2b / CC45"},
  {coordinate_system:"EPSG:9846", label: "9846:RGF93 v2b / CC46"},
  {coordinate_system:"EPSG:9847", label: "9847:RGF93 v2b / CC47"},
  {coordinate_system:"EPSG:9848", label: "9848:RGF93 v2b / CC48"},
  {coordinate_system:"EPSG:9849", label: "9849:RGF93 v2b / CC49"},
  {coordinate_system:"EPSG:9850", label: "9850:RGF93 v2b / CC50"},
  {coordinate_system:"EPSG:9851", label: "9851:UCS-2000 / LCS-46 Lviv"},
  {coordinate_system:"EPSG:9852", label: "9852:UCS-2000 / LCS-48 Mykolaiv"},
  {coordinate_system:"EPSG:9853", label: "9853:UCS-2000 / LCS-51 Odessa"},
  {coordinate_system:"EPSG:9854", label: "9854:UCS-2000 / LCS-53 Poltava"},
  {coordinate_system:"EPSG:9855", label: "9855:UCS-2000 / LCS-56 Rivne"},
  {coordinate_system:"EPSG:9856", label: "9856:UCS-2000 / LCS-59 Sumy"},
  {coordinate_system:"EPSG:9857", label: "9857:UCS-2000 / LCS-61 Ternopil"},
  {coordinate_system:"EPSG:9858", label: "9858:UCS-2000 / LCS-63 Kharkiv"},
  {coordinate_system:"EPSG:9859", label: "9859:UCS-2000 / LCS-65 Kherson"},
  {coordinate_system:"EPSG:9860", label: "9860:UCS-2000 / LCS-68 Khmelnytsky"},
  {coordinate_system:"EPSG:9861", label: "9861:UCS-2000 / LCS-71 Cherkasy"},
  {coordinate_system:"EPSG:9862", label: "9862:UCS-2000 / LCS-73 Chernivtsi"},
  {coordinate_system:"EPSG:9863", label: "9863:UCS-2000 / LCS-74 Chernihiv"},
  {coordinate_system:"EPSG:9864", label: "9864:UCS-2000 / LCS-80 Kyiv city"},
  {coordinate_system:"EPSG:9865", label: "9865:UCS-2000 / LCS-85 Sevastopol"},
  {coordinate_system:"EPSG:9869", label: "9869:MRH21 Grid"},
  {coordinate_system:"EPSG:9874", label: "9874:PNG94 / PNGMG94 zone 57"},
  {coordinate_system:"EPSG:9875", label: "9875:PNG94 / PNGMG94 zone 58"},
  {coordinate_system:"EPSG:9880", label: "9880:MOLDOR11 Grid"},
  {coordinate_system:"EPSG:9895", label: "9895:LUREF / Luxembourg TM (3D)"},
  {coordinate_system:"EPSG:9943", label: "9943:EBBWV14 Grid"},
  {coordinate_system:"EPSG:9945", label: "9945:Macedonia State Coordinate System truncated"},
  {coordinate_system:"EPSG:9947", label: "9947:ISN2004 / LAEA Iceland"},
  {coordinate_system:"EPSG:9967", label: "9967:HULLEE13 Grid"},
  {coordinate_system:"EPSG:9972", label: "9972:SCM22 Grid"},
  {coordinate_system:"EPSG:9977", label: "9977:FNL22 Grid"},
  {coordinate_system:"EPSG:20002", label: "20002:MWC18 Grid"},
  {coordinate_system:"EPSG:20004", label: "20004:Pulkovo 1995 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:20005", label: "20005:Pulkovo 1995 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:20006", label: "20006:Pulkovo 1995 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:20007", label: "20007:Pulkovo 1995 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:20008", label: "20008:Pulkovo 1995 / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:20009", label: "20009:Pulkovo 1995 / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:20010", label: "20010:Pulkovo 1995 / Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:20011", label: "20011:Pulkovo 1995 / Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:20012", label: "20012:Pulkovo 1995 / Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:20013", label: "20013:Pulkovo 1995 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:20014", label: "20014:Pulkovo 1995 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:20015", label: "20015:Pulkovo 1995 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:20016", label: "20016:Pulkovo 1995 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:20017", label: "20017:Pulkovo 1995 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:20018", label: "20018:Pulkovo 1995 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:20019", label: "20019:Pulkovo 1995 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:20020", label: "20020:Pulkovo 1995 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:20021", label: "20021:Pulkovo 1995 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:20022", label: "20022:Pulkovo 1995 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:20023", label: "20023:Pulkovo 1995 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:20024", label: "20024:Pulkovo 1995 / Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:20025", label: "20025:Pulkovo 1995 / Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:20026", label: "20026:Pulkovo 1995 / Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:20027", label: "20027:Pulkovo 1995 / Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:20028", label: "20028:Pulkovo 1995 / Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:20029", label: "20029:Pulkovo 1995 / Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:20030", label: "20030:Pulkovo 1995 / Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:20031", label: "20031:Pulkovo 1995 / Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:20032", label: "20032:Pulkovo 1995 / Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:20042", label: "20042:SIRGAS-Chile 2021 / UTM zone 12S"},
  {coordinate_system:"EPSG:20047", label: "20047:GDA2020 / BCSG2020"},
  {coordinate_system:"EPSG:20048", label: "20048:SIRGAS-Chile 2021 / UTM zone 18S"},
  {coordinate_system:"EPSG:20049", label: "20049:SIRGAS-Chile 2021 / UTM zone 19S"},
  {coordinate_system:"EPSG:20050", label: "20050:NAD83(2011) / Amtrak NECCS21 (ft)"},
  {coordinate_system:"EPSG:20135", label: "20135:Adindan / UTM zone 35N"},
  {coordinate_system:"EPSG:20136", label: "20136:Adindan / UTM zone 36N"},
  {coordinate_system:"EPSG:20137", label: "20137:Adindan / UTM zone 37N"},
  {coordinate_system:"EPSG:20138", label: "20138:Adindan / UTM zone 38N"},
  {coordinate_system:"EPSG:20249", label: "20249:AGD66 / AMG zone 49"},
  {coordinate_system:"EPSG:20250", label: "20250:AGD66 / AMG zone 50"},
  {coordinate_system:"EPSG:20251", label: "20251:AGD66 / AMG zone 51"},
  {coordinate_system:"EPSG:20252", label: "20252:AGD66 / AMG zone 52"},
  {coordinate_system:"EPSG:20253", label: "20253:AGD66 / AMG zone 53"},
  {coordinate_system:"EPSG:20254", label: "20254:AGD66 / AMG zone 54"},
  {coordinate_system:"EPSG:20255", label: "20255:AGD66 / AMG zone 55"},
  {coordinate_system:"EPSG:20256", label: "20256:AGD66 / AMG zone 56"},
  {coordinate_system:"EPSG:20257", label: "20257:AGD66 / AMG zone 57"},
  {coordinate_system:"EPSG:20258", label: "20258:AGD66 / AMG zone 58"},
  {coordinate_system:"EPSG:20349", label: "20349:AGD84 / AMG zone 49"},
  {coordinate_system:"EPSG:20350", label: "20350:AGD84 / AMG zone 50"},
  {coordinate_system:"EPSG:20351", label: "20351:AGD84 / AMG zone 51"},
  {coordinate_system:"EPSG:20352", label: "20352:AGD84 / AMG zone 52"},
  {coordinate_system:"EPSG:20353", label: "20353:AGD84 / AMG zone 53"},
  {coordinate_system:"EPSG:20354", label: "20354:AGD84 / AMG zone 54"},
  {coordinate_system:"EPSG:20355", label: "20355:AGD84 / AMG zone 55"},
  {coordinate_system:"EPSG:20356", label: "20356:AGD84 / AMG zone 56"},
  {coordinate_system:"EPSG:20436", label: "20436:Ain el Abd / UTM zone 36N"},
  {coordinate_system:"EPSG:20437", label: "20437:Ain el Abd / UTM zone 37N"},
  {coordinate_system:"EPSG:20438", label: "20438:Ain el Abd / UTM zone 38N"},
  {coordinate_system:"EPSG:20439", label: "20439:Ain el Abd / UTM zone 39N"},
  {coordinate_system:"EPSG:20440", label: "20440:Ain el Abd / UTM zone 40N"},
  {coordinate_system:"EPSG:20499", label: "20499:Ain el Abd / Bahrain Grid"},
  {coordinate_system:"EPSG:20538", label: "20538:Afgooye / UTM zone 38N"},
  {coordinate_system:"EPSG:20539", label: "20539:Afgooye / UTM zone 39N"},
  {coordinate_system:"EPSG:20790", label: "20790:Lisbon (Lisbon) / Portuguese National Grid"},
  {coordinate_system:"EPSG:20791", label: "20791:Lisbon (Lisbon) / Portuguese Grid"},
  {coordinate_system:"EPSG:20822", label: "20822:Aratu / UTM zone 22S"},
  {coordinate_system:"EPSG:20823", label: "20823:Aratu / UTM zone 23S"},
  {coordinate_system:"EPSG:20824", label: "20824:Aratu / UTM zone 24S"},
  {coordinate_system:"EPSG:20904", label: "20904:GSK-2011 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:20905", label: "20905:GSK-2011 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:20906", label: "20906:GSK-2011 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:20907", label: "20907:GSK-2011 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:20908", label: "20908:GSK-2011 / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:20909", label: "20909:GSK-2011 / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:20910", label: "20910:GSK-2011 / Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:20911", label: "20911:GSK-2011 / Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:20912", label: "20912:GSK-2011 / Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:20913", label: "20913:GSK-2011 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:20914", label: "20914:GSK-2011 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:20915", label: "20915:GSK-2011 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:20916", label: "20916:GSK-2011 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:20917", label: "20917:GSK-2011 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:20918", label: "20918:GSK-2011 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:20919", label: "20919:GSK-2011 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:20920", label: "20920:GSK-2011 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:20921", label: "20921:GSK-2011 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:20922", label: "20922:GSK-2011 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:20923", label: "20923:GSK-2011 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:20924", label: "20924:GSK-2011 / Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:20925", label: "20925:GSK-2011 / Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:20926", label: "20926:GSK-2011 / Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:20927", label: "20927:GSK-2011 / Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:20928", label: "20928:GSK-2011 / Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:20929", label: "20929:GSK-2011 / Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:20930", label: "20930:GSK-2011 / Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:20931", label: "20931:GSK-2011 / Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:20932", label: "20932:GSK-2011 / Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:20934", label: "20934:Arc 1950 / UTM zone 34S"},
  {coordinate_system:"EPSG:20935", label: "20935:Arc 1950 / UTM zone 35S"},
  {coordinate_system:"EPSG:20936", label: "20936:Arc 1950 / UTM zone 36S"},
  {coordinate_system:"EPSG:21004", label: "21004:GSK-2011 / Gauss-Kruger CM 21E"},
  {coordinate_system:"EPSG:21005", label: "21005:GSK-2011 / Gauss-Kruger CM 27E"},
  {coordinate_system:"EPSG:21006", label: "21006:GSK-2011 / Gauss-Kruger CM 33E"},
  {coordinate_system:"EPSG:21007", label: "21007:GSK-2011 / Gauss-Kruger CM 39E"},
  {coordinate_system:"EPSG:21008", label: "21008:GSK-2011 / Gauss-Kruger CM 45E"},
  {coordinate_system:"EPSG:21009", label: "21009:GSK-2011 / Gauss-Kruger CM 51E"},
  {coordinate_system:"EPSG:21010", label: "21010:GSK-2011 / Gauss-Kruger CM 57E"},
  {coordinate_system:"EPSG:21011", label: "21011:GSK-2011 / Gauss-Kruger CM 63E"},
  {coordinate_system:"EPSG:21012", label: "21012:GSK-2011 / Gauss-Kruger CM 69E"},
  {coordinate_system:"EPSG:21013", label: "21013:GSK-2011 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:21014", label: "21014:GSK-2011 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:21015", label: "21015:GSK-2011 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:21016", label: "21016:GSK-2011 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:21017", label: "21017:GSK-2011 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:21018", label: "21018:GSK-2011 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:21019", label: "21019:GSK-2011 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:21020", label: "21020:GSK-2011 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:21021", label: "21021:GSK-2011 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:21022", label: "21022:GSK-2011 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:21023", label: "21023:GSK-2011 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:21024", label: "21024:GSK-2011 / Gauss-Kruger CM 141E"},
  {coordinate_system:"EPSG:21025", label: "21025:GSK-2011 / Gauss-Kruger CM 147E"},
  {coordinate_system:"EPSG:21026", label: "21026:GSK-2011 / Gauss-Kruger CM 153E"},
  {coordinate_system:"EPSG:21027", label: "21027:GSK-2011 / Gauss-Kruger CM 159E"},
  {coordinate_system:"EPSG:21028", label: "21028:GSK-2011 / Gauss-Kruger CM 165E"},
  {coordinate_system:"EPSG:21029", label: "21029:GSK-2011 / Gauss-Kruger CM 171E"},
  {coordinate_system:"EPSG:21030", label: "21030:GSK-2011 / Gauss-Kruger CM 177E"},
  {coordinate_system:"EPSG:21031", label: "21031:GSK-2011 / Gauss-Kruger CM 177W"},
  {coordinate_system:"EPSG:21032", label: "21032:GSK-2011 / Gauss-Kruger CM 171W"},
  {coordinate_system:"EPSG:21035", label: "21035:Arc 1960 / UTM zone 35S"},
  {coordinate_system:"EPSG:21036", label: "21036:Arc 1960 / UTM zone 36S"},
  {coordinate_system:"EPSG:21037", label: "21037:Arc 1960 / UTM zone 37S"},
  {coordinate_system:"EPSG:21095", label: "21095:Arc 1960 / UTM zone 35N"},
  {coordinate_system:"EPSG:21096", label: "21096:Arc 1960 / UTM zone 36N"},
  {coordinate_system:"EPSG:21097", label: "21097:Arc 1960 / UTM zone 37N"},
  {coordinate_system:"EPSG:21148", label: "21148:Batavia / UTM zone 48S"},
  {coordinate_system:"EPSG:21149", label: "21149:Batavia / UTM zone 49S"},
  {coordinate_system:"EPSG:21150", label: "21150:Batavia / UTM zone 50S"},
  {coordinate_system:"EPSG:21207", label: "21207:GSK-2011 / GSK 3GK zone 7"},
  {coordinate_system:"EPSG:21208", label: "21208:GSK-2011 / GSK 3GK zone 8"},
  {coordinate_system:"EPSG:21209", label: "21209:GSK-2011 / GSK 3GK zone 9"},
  {coordinate_system:"EPSG:21210", label: "21210:GSK-2011 / GSK 3GK zone 10"},
  {coordinate_system:"EPSG:21211", label: "21211:GSK-2011 / GSK 3GK zone 11"},
  {coordinate_system:"EPSG:21212", label: "21212:GSK-2011 / GSK 3GK zone 12"},
  {coordinate_system:"EPSG:21213", label: "21213:GSK-2011 / GSK 3GK zone 13"},
  {coordinate_system:"EPSG:21214", label: "21214:GSK-2011 / GSK 3GK zone 14"},
  {coordinate_system:"EPSG:21215", label: "21215:GSK-2011 / GSK 3GK zone 15"},
  {coordinate_system:"EPSG:21216", label: "21216:GSK-2011 / GSK 3GK zone 16"},
  {coordinate_system:"EPSG:21217", label: "21217:GSK-2011 / GSK 3GK zone 17"},
  {coordinate_system:"EPSG:21218", label: "21218:GSK-2011 / GSK 3GK zone 18"},
  {coordinate_system:"EPSG:21219", label: "21219:GSK-2011 / GSK 3GK zone 19"},
  {coordinate_system:"EPSG:21220", label: "21220:GSK-2011 / GSK 3GK zone 20"},
  {coordinate_system:"EPSG:21221", label: "21221:GSK-2011 / GSK 3GK zone 21"},
  {coordinate_system:"EPSG:21222", label: "21222:GSK-2011 / GSK 3GK zone 22"},
  {coordinate_system:"EPSG:21223", label: "21223:GSK-2011 / GSK 3GK zone 23"},
  {coordinate_system:"EPSG:21224", label: "21224:GSK-2011 / GSK 3GK zone 24"},
  {coordinate_system:"EPSG:21225", label: "21225:GSK-2011 / GSK 3GK zone 25"},
  {coordinate_system:"EPSG:21226", label: "21226:GSK-2011 / GSK 3GK zone 26"},
  {coordinate_system:"EPSG:21227", label: "21227:GSK-2011 / GSK 3GK zone 27"},
  {coordinate_system:"EPSG:21228", label: "21228:GSK-2011 / GSK 3GK zone 28"},
  {coordinate_system:"EPSG:21229", label: "21229:GSK-2011 / GSK 3GK zone 29"},
  {coordinate_system:"EPSG:21230", label: "21230:GSK-2011 / GSK 3GK zone 30"},
  {coordinate_system:"EPSG:21231", label: "21231:GSK-2011 / GSK 3GK zone 31"},
  {coordinate_system:"EPSG:21232", label: "21232:GSK-2011 / GSK 3GK zone 32"},
  {coordinate_system:"EPSG:21233", label: "21233:GSK-2011 / GSK 3GK zone 33"},
  {coordinate_system:"EPSG:21234", label: "21234:GSK-2011 / GSK 3GK zone 34"},
  {coordinate_system:"EPSG:21235", label: "21235:GSK-2011 / GSK 3GK zone 35"},
  {coordinate_system:"EPSG:21236", label: "21236:GSK-2011 / GSK 3GK zone 36"},
  {coordinate_system:"EPSG:21237", label: "21237:GSK-2011 / GSK 3GK zone 37"},
  {coordinate_system:"EPSG:21238", label: "21238:GSK-2011 / GSK 3GK zone 38"},
  {coordinate_system:"EPSG:21239", label: "21239:GSK-2011 / GSK 3GK zone 39"},
  {coordinate_system:"EPSG:21240", label: "21240:GSK-2011 / GSK 3GK zone 40"},
  {coordinate_system:"EPSG:21241", label: "21241:GSK-2011 / GSK 3GK zone 41"},
  {coordinate_system:"EPSG:21242", label: "21242:GSK-2011 / GSK 3GK zone 42"},
  {coordinate_system:"EPSG:21243", label: "21243:GSK-2011 / GSK 3GK zone 43"},
  {coordinate_system:"EPSG:21244", label: "21244:GSK-2011 / GSK 3GK zone 44"},
  {coordinate_system:"EPSG:21245", label: "21245:GSK-2011 / GSK 3GK zone 45"},
  {coordinate_system:"EPSG:21246", label: "21246:GSK-2011 / GSK 3GK zone 46"},
  {coordinate_system:"EPSG:21247", label: "21247:GSK-2011 / GSK 3GK zone 47"},
  {coordinate_system:"EPSG:21248", label: "21248:GSK-2011 / GSK 3GK zone 48"},
  {coordinate_system:"EPSG:21249", label: "21249:GSK-2011 / GSK 3GK zone 49"},
  {coordinate_system:"EPSG:21250", label: "21250:GSK-2011 / GSK 3GK zone 50"},
  {coordinate_system:"EPSG:21251", label: "21251:GSK-2011 / GSK 3GK zone 51"},
  {coordinate_system:"EPSG:21252", label: "21252:GSK-2011 / GSK 3GK zone 52"},
  {coordinate_system:"EPSG:21253", label: "21253:GSK-2011 / GSK 3GK zone 53"},
  {coordinate_system:"EPSG:21254", label: "21254:GSK-2011 / GSK 3GK zone 54"},
  {coordinate_system:"EPSG:21255", label: "21255:GSK-2011 / GSK 3GK zone 55"},
  {coordinate_system:"EPSG:21256", label: "21256:GSK-2011 / GSK 3GK zone 56"},
  {coordinate_system:"EPSG:21257", label: "21257:GSK-2011 / GSK 3GK zone 57"},
  {coordinate_system:"EPSG:21258", label: "21258:GSK-2011 / GSK 3GK zone 58"},
  {coordinate_system:"EPSG:21259", label: "21259:GSK-2011 / GSK 3GK zone 59"},
  {coordinate_system:"EPSG:21260", label: "21260:GSK-2011 / GSK 3GK zone 60"},
  {coordinate_system:"EPSG:21261", label: "21261:GSK-2011 / GSK 3GK zone 61"},
  {coordinate_system:"EPSG:21262", label: "21262:GSK-2011 / GSK 3GK zone 62"},
  {coordinate_system:"EPSG:21263", label: "21263:GSK-2011 / GSK 3GK zone 63"},
  {coordinate_system:"EPSG:21264", label: "21264:GSK-2011 / GSK 3GK zone 64"},
  {coordinate_system:"EPSG:21291", label: "21291:Barbados 1938 / British West Indies Grid"},
  {coordinate_system:"EPSG:21292", label: "21292:Barbados 1938 / Barbados National Grid"},
  {coordinate_system:"EPSG:21307", label: "21307:GSK-2011 / GSK 3GK CM 21E"},
  {coordinate_system:"EPSG:21308", label: "21308:GSK-2011 / GSK 3GK CM 24E"},
  {coordinate_system:"EPSG:21309", label: "21309:GSK-2011 / GSK 3GK CM 27E"},
  {coordinate_system:"EPSG:21310", label: "21310:GSK-2011 / GSK 3GK CM 30E"},
  {coordinate_system:"EPSG:21311", label: "21311:GSK-2011 / GSK 3GK CM 33E"},
  {coordinate_system:"EPSG:21312", label: "21312:GSK-2011 / GSK 3GK CM 36E"},
  {coordinate_system:"EPSG:21313", label: "21313:GSK-2011 / GSK 3GK CM 39E"},
  {coordinate_system:"EPSG:21314", label: "21314:GSK-2011 / GSK 3GK CM 42E"},
  {coordinate_system:"EPSG:21315", label: "21315:GSK-2011 / GSK 3GK CM 45E"},
  {coordinate_system:"EPSG:21316", label: "21316:GSK-2011 / GSK 3GK CM 48E"},
  {coordinate_system:"EPSG:21317", label: "21317:GSK-2011 / GSK 3GK CM 51E"},
  {coordinate_system:"EPSG:21318", label: "21318:GSK-2011 / GSK 3GK CM 54E"},
  {coordinate_system:"EPSG:21319", label: "21319:GSK-2011 / GSK 3GK CM 57E"},
  {coordinate_system:"EPSG:21320", label: "21320:GSK-2011 / GSK 3GK CM 60E"},
  {coordinate_system:"EPSG:21321", label: "21321:GSK-2011 / GSK 3GK CM 63E"},
  {coordinate_system:"EPSG:21322", label: "21322:GSK-2011 / GSK 3GK CM 66E"},
  {coordinate_system:"EPSG:21323", label: "21323:GSK-2011 / GSK 3GK CM 69E"},
  {coordinate_system:"EPSG:21324", label: "21324:GSK-2011 / GSK 3GK CM 72E"},
  {coordinate_system:"EPSG:21325", label: "21325:GSK-2011 / GSK 3GK CM 75E"},
  {coordinate_system:"EPSG:21326", label: "21326:GSK-2011 / GSK 3GK CM 78E"},
  {coordinate_system:"EPSG:21327", label: "21327:GSK-2011 / GSK 3GK CM 81E"},
  {coordinate_system:"EPSG:21328", label: "21328:GSK-2011 / GSK 3GK CM 84E"},
  {coordinate_system:"EPSG:21329", label: "21329:GSK-2011 / GSK 3GK CM 87E"},
  {coordinate_system:"EPSG:21330", label: "21330:GSK-2011 / GSK 3GK CM 90E"},
  {coordinate_system:"EPSG:21331", label: "21331:GSK-2011 / GSK 3GK CM 93E"},
  {coordinate_system:"EPSG:21332", label: "21332:GSK-2011 / GSK 3GK CM 96E"},
  {coordinate_system:"EPSG:21333", label: "21333:GSK-2011 / GSK 3GK CM 99E"},
  {coordinate_system:"EPSG:21334", label: "21334:GSK-2011 / GSK 3GK CM 102E"},
  {coordinate_system:"EPSG:21335", label: "21335:GSK-2011 / GSK 3GK CM 105E"},
  {coordinate_system:"EPSG:21336", label: "21336:GSK-2011 / GSK 3GK CM 108E"},
  {coordinate_system:"EPSG:21337", label: "21337:GSK-2011 / GSK 3GK CM 111E"},
  {coordinate_system:"EPSG:21338", label: "21338:GSK-2011 / GSK 3GK CM 114E"},
  {coordinate_system:"EPSG:21339", label: "21339:GSK-2011 / GSK 3GK CM 117E"},
  {coordinate_system:"EPSG:21340", label: "21340:GSK-2011 / GSK 3GK CM 120E"},
  {coordinate_system:"EPSG:21341", label: "21341:GSK-2011 / GSK 3GK CM 123E"},
  {coordinate_system:"EPSG:21342", label: "21342:GSK-2011 / GSK 3GK CM 126E"},
  {coordinate_system:"EPSG:21343", label: "21343:GSK-2011 / GSK 3GK CM 129E"},
  {coordinate_system:"EPSG:21344", label: "21344:GSK-2011 / GSK 3GK CM 132E"},
  {coordinate_system:"EPSG:21345", label: "21345:GSK-2011 / GSK 3GK CM 135E"},
  {coordinate_system:"EPSG:21346", label: "21346:GSK-2011 / GSK 3GK CM 138E"},
  {coordinate_system:"EPSG:21347", label: "21347:GSK-2011 / GSK 3GK CM 141E"},
  {coordinate_system:"EPSG:21348", label: "21348:GSK-2011 / GSK 3GK CM 144E"},
  {coordinate_system:"EPSG:21349", label: "21349:GSK-2011 / GSK 3GK CM 147E"},
  {coordinate_system:"EPSG:21350", label: "21350:GSK-2011 / GSK 3GK CM 150E"},
  {coordinate_system:"EPSG:21351", label: "21351:GSK-2011 / GSK 3GK CM 153E"},
  {coordinate_system:"EPSG:21352", label: "21352:GSK-2011 / GSK 3GK CM 156E"},
  {coordinate_system:"EPSG:21353", label: "21353:GSK-2011 / GSK 3GK CM 159E"},
  {coordinate_system:"EPSG:21354", label: "21354:GSK-2011 / GSK 3GK CM 162E"},
  {coordinate_system:"EPSG:21355", label: "21355:GSK-2011 / GSK 3GK CM 165E"},
  {coordinate_system:"EPSG:21356", label: "21356:GSK-2011 / GSK 3GK CM 168E"},
  {coordinate_system:"EPSG:21357", label: "21357:GSK-2011 / GSK 3GK CM 171E"},
  {coordinate_system:"EPSG:21358", label: "21358:GSK-2011 / GSK 3GK CM 174E"},
  {coordinate_system:"EPSG:21359", label: "21359:GSK-2011 / GSK 3GK CM 177E"},
  {coordinate_system:"EPSG:21360", label: "21360:GSK-2011 / GSK 3GK CM 180E"},
  {coordinate_system:"EPSG:21361", label: "21361:GSK-2011 / GSK 3GK CM 177W"},
  {coordinate_system:"EPSG:21362", label: "21362:GSK-2011 / GSK 3GK CM 174W"},
  {coordinate_system:"EPSG:21363", label: "21363:GSK-2011 / GSK 3GK CM 171W"},
  {coordinate_system:"EPSG:21364", label: "21364:GSK-2011 / GSK 3GK CM 168W"},
  {coordinate_system:"EPSG:21413", label: "21413:Beijing 1954 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:21414", label: "21414:Beijing 1954 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:21415", label: "21415:Beijing 1954 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:21416", label: "21416:Beijing 1954 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:21417", label: "21417:Beijing 1954 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:21418", label: "21418:Beijing 1954 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:21419", label: "21419:Beijing 1954 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:21420", label: "21420:Beijing 1954 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:21421", label: "21421:Beijing 1954 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:21422", label: "21422:Beijing 1954 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:21423", label: "21423:Beijing 1954 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:21453", label: "21453:Beijing 1954 / Gauss-Kruger CM 75E"},
  {coordinate_system:"EPSG:21454", label: "21454:Beijing 1954 / Gauss-Kruger CM 81E"},
  {coordinate_system:"EPSG:21455", label: "21455:Beijing 1954 / Gauss-Kruger CM 87E"},
  {coordinate_system:"EPSG:21456", label: "21456:Beijing 1954 / Gauss-Kruger CM 93E"},
  {coordinate_system:"EPSG:21457", label: "21457:Beijing 1954 / Gauss-Kruger CM 99E"},
  {coordinate_system:"EPSG:21458", label: "21458:Beijing 1954 / Gauss-Kruger CM 105E"},
  {coordinate_system:"EPSG:21459", label: "21459:Beijing 1954 / Gauss-Kruger CM 111E"},
  {coordinate_system:"EPSG:21460", label: "21460:Beijing 1954 / Gauss-Kruger CM 117E"},
  {coordinate_system:"EPSG:21461", label: "21461:Beijing 1954 / Gauss-Kruger CM 123E"},
  {coordinate_system:"EPSG:21462", label: "21462:Beijing 1954 / Gauss-Kruger CM 129E"},
  {coordinate_system:"EPSG:21463", label: "21463:Beijing 1954 / Gauss-Kruger CM 135E"},
  {coordinate_system:"EPSG:21500", label: "21500:BD50 (Brussels) / Belge Lambert 50"},
  {coordinate_system:"EPSG:21780", label: "21780:Bern 1898 (Bern) / LV03C"},
  {coordinate_system:"EPSG:21781", label: "21781:CH1903 / LV03"},
  {coordinate_system:"EPSG:21782", label: "21782:CH1903 / LV03C-G"},
  {coordinate_system:"EPSG:21818", label: "21818:Bogota 1975 / UTM zone 18N"},
  {coordinate_system:"EPSG:21896", label: "21896:Bogota 1975 / Colombia West zone"},
  {coordinate_system:"EPSG:21897", label: "21897:Bogota 1975 / Colombia Bogota zone"},
  {coordinate_system:"EPSG:21898", label: "21898:Bogota 1975 / Colombia East Central zone"},
  {coordinate_system:"EPSG:21899", label: "21899:Bogota 1975 / Colombia East zone"},
  {coordinate_system:"EPSG:22032", label: "22032:Camacupa 1948 / UTM zone 32S"},
  {coordinate_system:"EPSG:22033", label: "22033:Camacupa 1948 / UTM zone 33S"},
  {coordinate_system:"EPSG:22091", label: "22091:Camacupa 1948 / TM 11.30 SE"},
  {coordinate_system:"EPSG:22092", label: "22092:Camacupa 1948 / TM 12 SE"},
  {coordinate_system:"EPSG:22171", label: "22171:POSGAR 98 / Argentina 1"},
  {coordinate_system:"EPSG:22172", label: "22172:POSGAR 98 / Argentina 2"},
  {coordinate_system:"EPSG:22173", label: "22173:POSGAR 98 / Argentina 3"},
  {coordinate_system:"EPSG:22174", label: "22174:POSGAR 98 / Argentina 4"},
  {coordinate_system:"EPSG:22175", label: "22175:POSGAR 98 / Argentina 5"},
  {coordinate_system:"EPSG:22176", label: "22176:POSGAR 98 / Argentina 6"},
  {coordinate_system:"EPSG:22177", label: "22177:POSGAR 98 / Argentina 7"},
  {coordinate_system:"EPSG:22181", label: "22181:POSGAR 94 / Argentina 1"},
  {coordinate_system:"EPSG:22182", label: "22182:POSGAR 94 / Argentina 2"},
  {coordinate_system:"EPSG:22183", label: "22183:POSGAR 94 / Argentina 3"},
  {coordinate_system:"EPSG:22184", label: "22184:POSGAR 94 / Argentina 4"},
  {coordinate_system:"EPSG:22185", label: "22185:POSGAR 94 / Argentina 5"},
  {coordinate_system:"EPSG:22186", label: "22186:POSGAR 94 / Argentina 6"},
  {coordinate_system:"EPSG:22187", label: "22187:POSGAR 94 / Argentina 7"},
  {coordinate_system:"EPSG:22191", label: "22191:Campo Inchauspe / Argentina 1"},
  {coordinate_system:"EPSG:22192", label: "22192:Campo Inchauspe / Argentina 2"},
  {coordinate_system:"EPSG:22193", label: "22193:Campo Inchauspe / Argentina 3"},
  {coordinate_system:"EPSG:22194", label: "22194:Campo Inchauspe / Argentina 4"},
  {coordinate_system:"EPSG:22195", label: "22195:Campo Inchauspe / Argentina 5"},
  {coordinate_system:"EPSG:22196", label: "22196:Campo Inchauspe / Argentina 6"},
  {coordinate_system:"EPSG:22197", label: "22197:Campo Inchauspe / Argentina 7"},
  {coordinate_system:"EPSG:22207", label: "22207:NAD83(CSRS)v2 / UTM zone 7N"},
  {coordinate_system:"EPSG:22208", label: "22208:NAD83(CSRS)v2 / UTM zone 8N"},
  {coordinate_system:"EPSG:22209", label: "22209:NAD83(CSRS)v2 / UTM zone 9N"},
  {coordinate_system:"EPSG:22210", label: "22210:NAD83(CSRS)v2 / UTM zone 10N"},
  {coordinate_system:"EPSG:22211", label: "22211:NAD83(CSRS)v2 / UTM zone 11N"},
  {coordinate_system:"EPSG:22212", label: "22212:NAD83(CSRS)v2 / UTM zone 12N"},
  {coordinate_system:"EPSG:22213", label: "22213:NAD83(CSRS)v2 / UTM zone 13N"},
  {coordinate_system:"EPSG:22213", label: "22213:NAD83(CSRS)v2 / UTM zone 13N"},
  {coordinate_system:"EPSG:22214", label: "22214:NAD83(CSRS)v2 / UTM zone 14N"},
  {coordinate_system:"EPSG:22215", label: "22215:NAD83(CSRS)v2 / UTM zone 15N"},
  {coordinate_system:"EPSG:22216", label: "22216:NAD83(CSRS)v2 / UTM zone 16N"},
  {coordinate_system:"EPSG:22217", label: "22217:NAD83(CSRS)v2 / UTM zone 17N"},
  {coordinate_system:"EPSG:22218", label: "22218:NAD83(CSRS)v2 / UTM zone 18N"},
  {coordinate_system:"EPSG:22219", label: "22219:NAD83(CSRS)v2 / UTM zone 19N"},
  {coordinate_system:"EPSG:22220", label: "22220:NAD83(CSRS)v2 / UTM zone 20N"},
  {coordinate_system:"EPSG:22221", label: "22221:NAD83(CSRS)v2 / UTM zone 21N"},
  {coordinate_system:"EPSG:22222", label: "22222:NAD83(CSRS)v2 / UTM zone 22N"},
  {coordinate_system:"EPSG:22234", label: "22234:Cape / UTM zone 34S"},
  {coordinate_system:"EPSG:22235", label: "22235:Cape / UTM zone 35S"},
  {coordinate_system:"EPSG:22239", label: "22239:NAD83(CSRS)v2 / PEI Stereographic"},
  {coordinate_system:"EPSG:22240", label: "22240:NAD83(CSRS)v2 / NB Stereographic"},
  {coordinate_system:"EPSG:22243", label: "22243:NAD83(CSRS)v2 / SCoPQ zone 3"},
  {coordinate_system:"EPSG:22244", label: "22244:NAD83(CSRS)v2 / SCoPQ zone 4"},
  {coordinate_system:"EPSG:22245", label: "22245:NAD83(CSRS)v2 / SCoPQ zone 5"},
  {coordinate_system:"EPSG:22246", label: "22246:NAD83(CSRS)v2 / SCoPQ zone 6"},
  {coordinate_system:"EPSG:22247", label: "22247:NAD83(CSRS)v2 / SCoPQ zone 7"},
  {coordinate_system:"EPSG:22248", label: "22248:NAD83(CSRS)v2 / SCoPQ zone 8"},
  {coordinate_system:"EPSG:22249", label: "22249:NAD83(CSRS)v2 / SCoPQ zone 9"},
  {coordinate_system:"EPSG:22250", label: "22250:NAD83(CSRS)v2 / SCoPQ zone 10"},
  {coordinate_system:"EPSG:22262", label: "22262:NAD83(CSRS)v2 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:22263", label: "22263:NAD83(CSRS)v2 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:22264", label: "22264:NAD83(CSRS)v2 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:22265", label: "22265:NAD83(CSRS)v2 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:22275", label: "22275:Cape / Lo15"},
  {coordinate_system:"EPSG:22277", label: "22277:Cape / Lo17"},
  {coordinate_system:"EPSG:22279", label: "22279:Cape / Lo19"},
  {coordinate_system:"EPSG:22281", label: "22281:Cape / Lo21"},
  {coordinate_system:"EPSG:22283", label: "22283:Cape / Lo23"},
  {coordinate_system:"EPSG:22285", label: "22285:Cape / Lo25"},
  {coordinate_system:"EPSG:22287", label: "22287:Cape / Lo27"},
  {coordinate_system:"EPSG:22289", label: "22289:Cape / Lo29"},
  {coordinate_system:"EPSG:22291", label: "22291:Cape / Lo31"},
  {coordinate_system:"EPSG:22293", label: "22293:Cape / Lo33"},
  {coordinate_system:"EPSG:22300", label: "22300:Carthage (Paris) / Tunisia Mining Grid"},
  {coordinate_system:"EPSG:22307", label: "22307:NAD83(CSRS)v3 / UTM zone 7N"},
  {coordinate_system:"EPSG:22308", label: "22308:NAD83(CSRS)v3 / UTM zone 8N"},
  {coordinate_system:"EPSG:22309", label: "22309:NAD83(CSRS)v3 / UTM zone 9N"},
  {coordinate_system:"EPSG:22310", label: "22310:NAD83(CSRS)v3 / UTM zone 10N"},
  {coordinate_system:"EPSG:22311", label: "22311:NAD83(CSRS)v3 / UTM zone 11N"},
  {coordinate_system:"EPSG:22312", label: "22312:NAD83(CSRS)v3 / UTM zone 12N"},
  {coordinate_system:"EPSG:22313", label: "22313:NAD83(CSRS)v3 / UTM zone 13N"},
  {coordinate_system:"EPSG:22314", label: "22314:NAD83(CSRS)v3 / UTM zone 14N"},
  {coordinate_system:"EPSG:22315", label: "22315:NAD83(CSRS)v3 / UTM zone 15N"},
  {coordinate_system:"EPSG:22316", label: "22316:NAD83(CSRS)v3 / UTM zone 16N"},
  {coordinate_system:"EPSG:22317", label: "22317:NAD83(CSRS)v3 / UTM zone 17N"},
  {coordinate_system:"EPSG:22318", label: "22318:NAD83(CSRS)v3 / UTM zone 18N"},
  {coordinate_system:"EPSG:22319", label: "22319:NAD83(CSRS)v3 / UTM zone 19N"},
  {coordinate_system:"EPSG:22320", label: "22320:NAD83(CSRS)v3 / UTM zone 20N"},
  {coordinate_system:"EPSG:22321", label: "22321:NAD83(CSRS)v3 / UTM zone 21N"},
  {coordinate_system:"EPSG:22322", label: "22322:NAD83(CSRS)v3 / UTM zone 22N"},
  {coordinate_system:"EPSG:22332", label: "22332:Carthage / UTM zone 32N"},
  {coordinate_system:"EPSG:22337", label: "22337:NAD83(CSRS)v3 / MTM NS 1997 zone 5"},
  {coordinate_system:"EPSG:22338", label: "22338:NAD83(CSRS)v3 / MTM NS 1997 zone 4"},
  {coordinate_system:"EPSG:22348", label: "22348:NAD83(CSRS)v3 / MTM zone 8"},
  {coordinate_system:"EPSG:22349", label: "22349:NAD83(CSRS)v3 / MTM zone 9"},
  {coordinate_system:"EPSG:22350", label: "22350:NAD83(CSRS)v3 / MTM zone 10"},
  {coordinate_system:"EPSG:22351", label: "22351:NAD83(CSRS)v3 / MTM zone 11"},
  {coordinate_system:"EPSG:22352", label: "22352:NAD83(CSRS)v3 / MTM zone 12"},
  {coordinate_system:"EPSG:22353", label: "22353:NAD83(CSRS)v3 / MTM zone 13"},
  {coordinate_system:"EPSG:22354", label: "22354:NAD83(CSRS)v3 / MTM zone 14"},
  {coordinate_system:"EPSG:22355", label: "22355:NAD83(CSRS)v3 / MTM zone 15"},
  {coordinate_system:"EPSG:22356", label: "22356:NAD83(CSRS)v3 / MTM zone 16"},
  {coordinate_system:"EPSG:22357", label: "22357:NAD83(CSRS)v3 / MTM zone 17"},
  {coordinate_system:"EPSG:22391", label: "22391:Carthage / Nord Tunisie"},
  {coordinate_system:"EPSG:22392", label: "22392:Carthage / Sud Tunisie"},
  {coordinate_system:"EPSG:22407", label: "22407:NAD83(CSRS)v4 / UTM zone 7N"},
  {coordinate_system:"EPSG:22408", label: "22408:NAD83(CSRS)v4 / UTM zone 8N"},
  {coordinate_system:"EPSG:22409", label: "22409:NAD83(CSRS)v4 / UTM zone 9N"},
  {coordinate_system:"EPSG:22410", label: "22410:NAD83(CSRS)v4 / UTM zone 10N"},
  {coordinate_system:"EPSG:22411", label: "22411:NAD83(CSRS)v4 / UTM zone 11N"},
  {coordinate_system:"EPSG:22412", label: "22412:NAD83(CSRS)v4 / UTM zone 12N"},
  {coordinate_system:"EPSG:22413", label: "22413:NAD83(CSRS)v4 / UTM zone 13N"},
  {coordinate_system:"EPSG:22414", label: "22414:NAD83(CSRS)v4 / UTM zone 14N"},
  {coordinate_system:"EPSG:22415", label: "22415:NAD83(CSRS)v4 / UTM zone 15N"},
  {coordinate_system:"EPSG:22416", label: "22416:NAD83(CSRS)v4 / UTM zone 16N"},
  {coordinate_system:"EPSG:22417", label: "22417:NAD83(CSRS)v4 / UTM zone 17N"},
  {coordinate_system:"EPSG:22418", label: "22418:NAD83(CSRS)v4 / UTM zone 18N"},
  {coordinate_system:"EPSG:22419", label: "22419:NAD83(CSRS)v4 / UTM zone 19N"},
  {coordinate_system:"EPSG:22420", label: "22420:NAD83(CSRS)v4 / UTM zone 20N"},
  {coordinate_system:"EPSG:22421", label: "22421:NAD83(CSRS)v4 / UTM zone 21N"},
  {coordinate_system:"EPSG:22422", label: "22422:NAD83(CSRS)v4 / UTM zone 22N"},
  {coordinate_system:"EPSG:22462", label: "22462:NAD83(CSRS)v4 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:22463", label: "22463:NAD83(CSRS)v4 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:22464", label: "22464:NAD83(CSRS)v4 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:22465", label: "22465:NAD83(CSRS)v4 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:22521", label: "22521:Corrego Alegre 1970-72 / UTM zone 21S"},
  {coordinate_system:"EPSG:22522", label: "22522:Corrego Alegre 1970-72 / UTM zone 22S"},
  {coordinate_system:"EPSG:22523", label: "22523:Corrego Alegre 1970-72 / UTM zone 23S"},
  {coordinate_system:"EPSG:22524", label: "22524:Corrego Alegre 1970-72 / UTM zone 24S"},
  {coordinate_system:"EPSG:22525", label: "22525:Corrego Alegre 1970-72 / UTM zone 25S"},
  {coordinate_system:"EPSG:22607", label: "22607:NAD83(CSRS)v6 / UTM zone 7N"},
  {coordinate_system:"EPSG:22608", label: "22608:NAD83(CSRS)v6 / UTM zone 8N"},
  {coordinate_system:"EPSG:22609", label: "22609:NAD83(CSRS)v6 / UTM zone 9N"},
  {coordinate_system:"EPSG:22610", label: "22610:NAD83(CSRS)v6 / UTM zone 10N"},
  {coordinate_system:"EPSG:22611", label: "22611:NAD83(CSRS)v6 / UTM zone 11N"},
  {coordinate_system:"EPSG:22612", label: "22612:NAD83(CSRS)v6 / UTM zone 12N"},
  {coordinate_system:"EPSG:22613", label: "22613:NAD83(CSRS)v6 / UTM zone 13N"},
  {coordinate_system:"EPSG:22614", label: "22614:NAD83(CSRS)v6 / UTM zone 14N"},
  {coordinate_system:"EPSG:22615", label: "22615:NAD83(CSRS)v6 / UTM zone 15N"},
  {coordinate_system:"EPSG:22616", label: "22616:NAD83(CSRS)v6 / UTM zone 16N"},
  {coordinate_system:"EPSG:22617", label: "22617:NAD83(CSRS)v6 / UTM zone 17N"},
  {coordinate_system:"EPSG:22618", label: "22618:NAD83(CSRS)v6 / UTM zone 18N"},
  {coordinate_system:"EPSG:22619", label: "22619:NAD83(CSRS)v6 / UTM zone 19N"},
  {coordinate_system:"EPSG:22620", label: "22620:NAD83(CSRS)v6 / UTM zone 20N"},
  {coordinate_system:"EPSG:22621", label: "22621:NAD83(CSRS)v6 / UTM zone 21N"},
  {coordinate_system:"EPSG:22622", label: "22622:NAD83(CSRS)v6 / UTM zone 22N"},
  {coordinate_system:"EPSG:22639", label: "22639:NAD83(CSRS)v6 / PEI Stereographic"},
  {coordinate_system:"EPSG:22641", label: "22641:NAD83(CSRS)v6 / MTM zone 1"},
  {coordinate_system:"EPSG:22642", label: "22642:NAD83(CSRS)v6 / MTM zone 2"},
  {coordinate_system:"EPSG:22643", label: "22643:NAD83(CSRS)v6 / MTM zone 3"},
  {coordinate_system:"EPSG:22644", label: "22644:NAD83(CSRS)v6 / MTM zone 4"},
  {coordinate_system:"EPSG:22645", label: "22645:NAD83(CSRS)v6 / MTM zone 5"},
  {coordinate_system:"EPSG:22646", label: "22646:NAD83(CSRS)v6 / MTM zone 6"},
  {coordinate_system:"EPSG:22648", label: "22648:NAD83(CSRS)v6 / MTM zone 8"},
  {coordinate_system:"EPSG:22649", label: "22649:NAD83(CSRS)v6 / MTM zone 9"},
  {coordinate_system:"EPSG:22650", label: "22650:NAD83(CSRS)v6 / MTM zone 10"},
  {coordinate_system:"EPSG:22651", label: "22651:NAD83(CSRS)v6 / MTM zone 11"},
  {coordinate_system:"EPSG:22652", label: "22652:NAD83(CSRS)v6 / MTM zone 12"},
  {coordinate_system:"EPSG:22653", label: "22653:NAD83(CSRS)v6 / MTM zone 13"},
  {coordinate_system:"EPSG:22654", label: "22654:NAD83(CSRS)v6 / MTM zone 14"},
  {coordinate_system:"EPSG:22655", label: "22655:NAD83(CSRS)v6 / MTM zone 15"},
  {coordinate_system:"EPSG:22656", label: "22656:NAD83(CSRS)v6 / MTM zone 16"},
  {coordinate_system:"EPSG:22657", label: "22657:NAD83(CSRS)v6 / MTM zone 17"},
  {coordinate_system:"EPSG:22700", label: "22700:Deir ez Zor / Levant Zone"},
  {coordinate_system:"EPSG:22707", label: "22707:NAD83(CSRS)v7 / UTM zone 7N"},
  {coordinate_system:"EPSG:22708", label: "22708:NAD83(CSRS)v7 / UTM zone 8N"},
  {coordinate_system:"EPSG:22709", label: "22709:NAD83(CSRS)v7 / UTM zone 9N"},
  {coordinate_system:"EPSG:22710", label: "22710:NAD83(CSRS)v7 / UTM zone 10N"},
  {coordinate_system:"EPSG:22711", label: "22711:NAD83(CSRS)v7 / UTM zone 11N"},
  {coordinate_system:"EPSG:22712", label: "22712:NAD83(CSRS)v7 / UTM zone 12N"},
  {coordinate_system:"EPSG:22713", label: "22713:NAD83(CSRS)v7 / UTM zone 13N"},
  {coordinate_system:"EPSG:22714", label: "22714:NAD83(CSRS)v7 / UTM zone 14N"},
  {coordinate_system:"EPSG:22715", label: "22715:NAD83(CSRS)v7 / UTM zone 15N"},
  {coordinate_system:"EPSG:22716", label: "22716:NAD83(CSRS)v7 / UTM zone 16N"},
  {coordinate_system:"EPSG:22717", label: "22717:NAD83(CSRS)v7 / UTM zone 17N"},
  {coordinate_system:"EPSG:22718", label: "22718:NAD83(CSRS)v7 / UTM zone 18N"},
  {coordinate_system:"EPSG:22719", label: "22719:NAD83(CSRS)v7 / UTM zone 19N"},
  {coordinate_system:"EPSG:22720", label: "22720:NAD83(CSRS)v7 / UTM zone 20N"},
  {coordinate_system:"EPSG:22721", label: "22721:NAD83(CSRS)v7 / UTM zone 21N"},
  {coordinate_system:"EPSG:22722", label: "22722:NAD83(CSRS)v7 / UTM zone 22N"},
  {coordinate_system:"EPSG:22739", label: "22739:NAD83(CSRS)v7 / PEI Stereographic"},
  {coordinate_system:"EPSG:22762", label: "22762:NAD83(CSRS)v7 / Alberta 3TM ref merid 111 W"},
  {coordinate_system:"EPSG:22763", label: "22763:NAD83(CSRS)v7 / Alberta 3TM ref merid 114 W"},
  {coordinate_system:"EPSG:22764", label: "22764:NAD83(CSRS)v7 / Alberta 3TM ref merid 117 W"},
  {coordinate_system:"EPSG:22765", label: "22765:NAD83(CSRS)v7 / Alberta 3TM ref merid 120 W"},
  {coordinate_system:"EPSG:22770", label: "22770:Deir ez Zor / Syria Lambert"},
  {coordinate_system:"EPSG:22780", label: "22780:Deir ez Zor / Levant Stereographic"},
  {coordinate_system:"EPSG:22991", label: "22991:Egypt 1907 / Blue Belt"},
  {coordinate_system:"EPSG:22992", label: "22992:Egypt 1907 / Red Belt"},
  {coordinate_system:"EPSG:22993", label: "22993:Egypt 1907 / Purple Belt"},
  {coordinate_system:"EPSG:22994", label: "22994:Egypt 1907 / Extended Purple Belt"},
  {coordinate_system:"EPSG:23028", label: "23028:ED50 / UTM zone 28N"},
  {coordinate_system:"EPSG:23029", label: "23029:ED50 / UTM zone 29N"},
  {coordinate_system:"EPSG:23030", label: "23030:ED50 / UTM zone 30N"},
  {coordinate_system:"EPSG:23031", label: "23031:ED50 / UTM zone 31N"},
  {coordinate_system:"EPSG:23032", label: "23032:ED50 / UTM zone 32N"},
  {coordinate_system:"EPSG:23033", label: "23033:ED50 / UTM zone 33N"},
  {coordinate_system:"EPSG:23034", label: "23034:ED50 / UTM zone 34N"},
  {coordinate_system:"EPSG:23035", label: "23035:ED50 / UTM zone 35N"},
  {coordinate_system:"EPSG:23036", label: "23036:ED50 / UTM zone 36N"},
  {coordinate_system:"EPSG:23037", label: "23037:ED50 / UTM zone 37N"},
  {coordinate_system:"EPSG:23038", label: "23038:ED50 / UTM zone 38N"},
  {coordinate_system:"EPSG:23090", label: "23090:ED50 / TM 0 N"},
  {coordinate_system:"EPSG:23095", label: "23095:ED50 / TM 5 NE"},
  {coordinate_system:"EPSG:23239", label: "23239:Fahud / UTM zone 39N"},
  {coordinate_system:"EPSG:23240", label: "23240:Fahud / UTM zone 40N"},
  {coordinate_system:"EPSG:23700", label: "23700:HD72 / EOV"},
  {coordinate_system:"EPSG:23830", label: "23830:DGN95 / Indonesia TM-3 zone 46.2"},
  {coordinate_system:"EPSG:23831", label: "23831:DGN95 / Indonesia TM-3 zone 47.1"},
  {coordinate_system:"EPSG:23832", label: "23832:DGN95 / Indonesia TM-3 zone 47.2"},
  {coordinate_system:"EPSG:23833", label: "23833:DGN95 / Indonesia TM-3 zone 48.1"},
  {coordinate_system:"EPSG:23834", label: "23834:DGN95 / Indonesia TM-3 zone 48.2"},
  {coordinate_system:"EPSG:23835", label: "23835:DGN95 / Indonesia TM-3 zone 49.1"},
  {coordinate_system:"EPSG:23836", label: "23836:DGN95 / Indonesia TM-3 zone 49.2"},
  {coordinate_system:"EPSG:23837", label: "23837:DGN95 / Indonesia TM-3 zone 50.1"},
  {coordinate_system:"EPSG:23838", label: "23838:DGN95 / Indonesia TM-3 zone 50.2"},
  {coordinate_system:"EPSG:23839", label: "23839:DGN95 / Indonesia TM-3 zone 51.1"},
  {coordinate_system:"EPSG:23840", label: "23840:DGN95 / Indonesia TM-3 zone 51.2"},
  {coordinate_system:"EPSG:23841", label: "23841:DGN95 / Indonesia TM-3 zone 52.1"},
  {coordinate_system:"EPSG:23842", label: "23842:DGN95 / Indonesia TM-3 zone 52.2"},
  {coordinate_system:"EPSG:23843", label: "23843:DGN95 / Indonesia TM-3 zone 53.1"},
  {coordinate_system:"EPSG:23844", label: "23844:DGN95 / Indonesia TM-3 zone 53.2"},
  {coordinate_system:"EPSG:23845", label: "23845:DGN95 / Indonesia TM-3 zone 54.1"},
  {coordinate_system:"EPSG:23846", label: "23846:ID74 / UTM zone 46N"},
  {coordinate_system:"EPSG:23847", label: "23847:ID74 / UTM zone 47N"},
  {coordinate_system:"EPSG:23848", label: "23848:ID74 / UTM zone 48N"},
  {coordinate_system:"EPSG:23849", label: "23849:ID74 / UTM zone 49N"},
  {coordinate_system:"EPSG:23850", label: "23850:ID74 / UTM zone 50N"},
  {coordinate_system:"EPSG:23851", label: "23851:ID74 / UTM zone 51N"},
  {coordinate_system:"EPSG:23852", label: "23852:ID74 / UTM zone 52N"},
  {coordinate_system:"EPSG:23866", label: "23866:DGN95 / UTM zone 46N"},
  {coordinate_system:"EPSG:23867", label: "23867:DGN95 / UTM zone 47N"},
  {coordinate_system:"EPSG:23868", label: "23868:DGN95 / UTM zone 48N"},
  {coordinate_system:"EPSG:23869", label: "23869:DGN95 / UTM zone 49N"},
  {coordinate_system:"EPSG:23870", label: "23870:DGN95 / UTM zone 50N"},
  {coordinate_system:"EPSG:23871", label: "23871:DGN95 / UTM zone 51N"},
  {coordinate_system:"EPSG:23872", label: "23872:DGN95 / UTM zone 52N"},
  {coordinate_system:"EPSG:23877", label: "23877:DGN95 / UTM zone 47S"},
  {coordinate_system:"EPSG:23878", label: "23878:DGN95 / UTM zone 48S"},
  {coordinate_system:"EPSG:23879", label: "23879:DGN95 / UTM zone 49S"},
  {coordinate_system:"EPSG:23880", label: "23880:DGN95 / UTM zone 50S"},
  {coordinate_system:"EPSG:23881", label: "23881:DGN95 / UTM zone 51S"},
  {coordinate_system:"EPSG:23882", label: "23882:DGN95 / UTM zone 52S"},
  {coordinate_system:"EPSG:23883", label: "23883:DGN95 / UTM zone 53S"},
  {coordinate_system:"EPSG:23884", label: "23884:DGN95 / UTM zone 54S"},
  {coordinate_system:"EPSG:23887", label: "23887:ID74 / UTM zone 47S"},
  {coordinate_system:"EPSG:23888", label: "23888:ID74 / UTM zone 48S"},
  {coordinate_system:"EPSG:23889", label: "23889:ID74 / UTM zone 49S"},
  {coordinate_system:"EPSG:23890", label: "23890:ID74 / UTM zone 50S"},
  {coordinate_system:"EPSG:23891", label: "23891:ID74 / UTM zone 51S"},
  {coordinate_system:"EPSG:23892", label: "23892:ID74 / UTM zone 52S"},
  {coordinate_system:"EPSG:23893", label: "23893:ID74 / UTM zone 53S"},
  {coordinate_system:"EPSG:23894", label: "23894:ID74 / UTM zone 54S"},
  {coordinate_system:"EPSG:23946", label: "23946:Indian 1954 / UTM zone 46N"},
  {coordinate_system:"EPSG:23947", label: "23947:Indian 1954 / UTM zone 47N"},
  {coordinate_system:"EPSG:23948", label: "23948:Indian 1954 / UTM zone 48N"},
  {coordinate_system:"EPSG:24047", label: "24047:Indian 1975 / UTM zone 47N"},
  {coordinate_system:"EPSG:24048", label: "24048:Indian 1975 / UTM zone 48N"},
  {coordinate_system:"EPSG:24100", label: "24100:Jamaica 1875 / Jamaica (Old Grid)"},
  {coordinate_system:"EPSG:24200", label: "24200:JAD69 / Jamaica National Grid"},
  {coordinate_system:"EPSG:24305", label: "24305:Kalianpur 1937 / UTM zone 45N"},
  {coordinate_system:"EPSG:24306", label: "24306:Kalianpur 1937 / UTM zone 46N"},
  {coordinate_system:"EPSG:24311", label: "24311:Kalianpur 1962 / UTM zone 41N"},
  {coordinate_system:"EPSG:24312", label: "24312:Kalianpur 1962 / UTM zone 42N"},
  {coordinate_system:"EPSG:24313", label: "24313:Kalianpur 1962 / UTM zone 43N"},
  {coordinate_system:"EPSG:24342", label: "24342:Kalianpur 1975 / UTM zone 42N"},
  {coordinate_system:"EPSG:24343", label: "24343:Kalianpur 1975 / UTM zone 43N"},
  {coordinate_system:"EPSG:24344", label: "24344:Kalianpur 1975 / UTM zone 44N"},
  {coordinate_system:"EPSG:24345", label: "24345:Kalianpur 1975 / UTM zone 45N"},
  {coordinate_system:"EPSG:24346", label: "24346:Kalianpur 1975 / UTM zone 46N"},
  {coordinate_system:"EPSG:24347", label: "24347:Kalianpur 1975 / UTM zone 47N"},
  {coordinate_system:"EPSG:24370", label: "24370:Kalianpur 1880 / India zone 0"},
  {coordinate_system:"EPSG:24371", label: "24371:Kalianpur 1880 / India zone I"},
  {coordinate_system:"EPSG:24372", label: "24372:Kalianpur 1880 / India zone IIa"},
  {coordinate_system:"EPSG:24373", label: "24373:Kalianpur 1880 / India zone IIIa"},
  {coordinate_system:"EPSG:24374", label: "24374:Kalianpur 1880 / India zone IVa"},
  {coordinate_system:"EPSG:24375", label: "24375:Kalianpur 1937 / India zone IIb"},
  {coordinate_system:"EPSG:24376", label: "24376:Kalianpur 1962 / India zone I"},
  {coordinate_system:"EPSG:24377", label: "24377:Kalianpur 1962 / India zone IIa"},
  {coordinate_system:"EPSG:24378", label: "24378:Kalianpur 1975 / India zone I"},
  {coordinate_system:"EPSG:24379", label: "24379:Kalianpur 1975 / India zone IIa"},
  {coordinate_system:"EPSG:24380", label: "24380:Kalianpur 1975 / India zone IIb"},
  {coordinate_system:"EPSG:24381", label: "24381:Kalianpur 1975 / India zone IIIa"},
  {coordinate_system:"EPSG:24382", label: "24382:Kalianpur 1880 / India zone IIb"},
  {coordinate_system:"EPSG:24383", label: "24383:Kalianpur 1975 / India zone IVa"},
  {coordinate_system:"EPSG:24500", label: "24500:Kertau 1968 / Singapore Grid"},
  {coordinate_system:"EPSG:24547", label: "24547:Kertau 1968 / UTM zone 47N"},
  {coordinate_system:"EPSG:24548", label: "24548:Kertau 1968 / UTM zone 48N"},
  {coordinate_system:"EPSG:24600", label: "24600:KOC Lambert"},
  {coordinate_system:"EPSG:24718", label: "24718:La Canoa / UTM zone 18N"},
  {coordinate_system:"EPSG:24719", label: "24719:La Canoa / UTM zone 19N"},
  {coordinate_system:"EPSG:24720", label: "24720:La Canoa / UTM zone 20N"},
  {coordinate_system:"EPSG:24817", label: "24817:PSAD56 / UTM zone 17N"},
  {coordinate_system:"EPSG:24818", label: "24818:PSAD56 / UTM zone 18N"},
  {coordinate_system:"EPSG:24819", label: "24819:PSAD56 / UTM zone 19N"},
  {coordinate_system:"EPSG:24820", label: "24820:PSAD56 / UTM zone 20N"},
  {coordinate_system:"EPSG:24821", label: "24821:PSAD56 / UTM zone 21N"},
  {coordinate_system:"EPSG:24877", label: "24877:PSAD56 / UTM zone 17S"},
  {coordinate_system:"EPSG:24878", label: "24878:PSAD56 / UTM zone 18S"},
  {coordinate_system:"EPSG:24879", label: "24879:PSAD56 / UTM zone 19S"},
  {coordinate_system:"EPSG:24880", label: "24880:PSAD56 / UTM zone 20S"},
  {coordinate_system:"EPSG:24881", label: "24881:PSAD56 / UTM zone 21S"},
  {coordinate_system:"EPSG:24882", label: "24882:PSAD56 / UTM zone 22S"},
  {coordinate_system:"EPSG:24891", label: "24891:PSAD56 / Peru west zone"},
  {coordinate_system:"EPSG:24892", label: "24892:PSAD56 / Peru central zone"},
  {coordinate_system:"EPSG:24893", label: "24893:PSAD56 / Peru east zone"},
  {coordinate_system:"EPSG:25000", label: "25000:Leigon / Ghana Metre Grid"},
  {coordinate_system:"EPSG:25231", label: "25231:Lome / UTM zone 31N"},
  {coordinate_system:"EPSG:25391", label: "25391:Luzon 1911 / Philippines zone I"},
  {coordinate_system:"EPSG:25392", label: "25392:Luzon 1911 / Philippines zone II"},
  {coordinate_system:"EPSG:25393", label: "25393:Luzon 1911 / Philippines zone III"},
  {coordinate_system:"EPSG:25394", label: "25394:Luzon 1911 / Philippines zone IV"},
  {coordinate_system:"EPSG:25395", label: "25395:Luzon 1911 / Philippines zone V"},
  {coordinate_system:"EPSG:25828", label: "25828:ETRS89 / UTM zone 28N"},
  {coordinate_system:"EPSG:25829", label: "25829:ETRS89 / UTM zone 29N"},
  {coordinate_system:"EPSG:25830", label: "25830:ETRS89 / UTM zone 30N"},
  {coordinate_system:"EPSG:25831", label: "25831:ETRS89 / UTM zone 31N"},
  {coordinate_system:"EPSG:25832", label: "25832:ETRS89 / UTM zone 32N"},
  {coordinate_system:"EPSG:25833", label: "25833:ETRS89 / UTM zone 33N"},
  {coordinate_system:"EPSG:25834", label: "25834:ETRS89 / UTM zone 34N"},
  {coordinate_system:"EPSG:25835", label: "25835:ETRS89 / UTM zone 35N"},
  {coordinate_system:"EPSG:25836", label: "25836:ETRS89 / UTM zone 36N"},
  {coordinate_system:"EPSG:25837", label: "25837:ETRS89 / UTM zone 37N"},
  {coordinate_system:"EPSG:25884", label: "25884:ETRS89 / TM Baltic93"},
  {coordinate_system:"EPSG:25932", label: "25932:Malongo 1987 / UTM zone 32S"},
  {coordinate_system:"EPSG:26191", label: "26191:Merchich / Nord Maroc"},
  {coordinate_system:"EPSG:26192", label: "26192:Merchich / Sud Maroc"},
  {coordinate_system:"EPSG:26194", label: "26194:Merchich / Sahara Nord"},
  {coordinate_system:"EPSG:26195", label: "26195:Merchich / Sahara Sud"},
  {coordinate_system:"EPSG:26237", label: "26237:Massawa / UTM zone 37N"},
  {coordinate_system:"EPSG:26331", label: "26331:Minna / UTM zone 31N"},
  {coordinate_system:"EPSG:26332", label: "26332:Minna / UTM zone 32N"},
  {coordinate_system:"EPSG:26391", label: "26391:Minna / Nigeria West Belt"},
  {coordinate_system:"EPSG:26392", label: "26392:Minna / Nigeria Mid Belt"},
  {coordinate_system:"EPSG:26393", label: "26393:Minna / Nigeria East Belt"},
  {coordinate_system:"EPSG:26632", label: "26632:M'poraloko / UTM zone 32N"},
  {coordinate_system:"EPSG:26692", label: "26692:M'poraloko / UTM zone 32S"},
  {coordinate_system:"EPSG:26701", label: "26701:NAD27 / UTM zone 1N"},
  {coordinate_system:"EPSG:26702", label: "26702:NAD27 / UTM zone 2N"},
  {coordinate_system:"EPSG:26703", label: "26703:NAD27 / UTM zone 3N"},
  {coordinate_system:"EPSG:26704", label: "26704:NAD27 / UTM zone 4N"},
  {coordinate_system:"EPSG:26705", label: "26705:NAD27 / UTM zone 5N"},
  {coordinate_system:"EPSG:26706", label: "26706:NAD27 / UTM zone 6N"},
  {coordinate_system:"EPSG:26707", label: "26707:NAD27 / UTM zone 7N"},
  {coordinate_system:"EPSG:26708", label: "26708:NAD27 / UTM zone 8N"},
  {coordinate_system:"EPSG:26709", label: "26709:NAD27 / UTM zone 9N"},
  {coordinate_system:"EPSG:26710", label: "26710:NAD27 / UTM zone 10N"},
  {coordinate_system:"EPSG:26711", label: "26711:NAD27 / UTM zone 11N"},
  {coordinate_system:"EPSG:26712", label: "26712:NAD27 / UTM zone 12N"},
  {coordinate_system:"EPSG:26713", label: "26713:NAD27 / UTM zone 13N"},
  {coordinate_system:"EPSG:26714", label: "26714:NAD27 / UTM zone 14N"},
  {coordinate_system:"EPSG:26715", label: "26715:NAD27 / UTM zone 15N"},
  {coordinate_system:"EPSG:26716", label: "26716:NAD27 / UTM zone 16N"},
  {coordinate_system:"EPSG:26717", label: "26717:NAD27 / UTM zone 17N"},
  {coordinate_system:"EPSG:26718", label: "26718:NAD27 / UTM zone 18N"},
  {coordinate_system:"EPSG:26719", label: "26719:NAD27 / UTM zone 19N"},
  {coordinate_system:"EPSG:26720", label: "26720:NAD27 / UTM zone 20N"},
  {coordinate_system:"EPSG:26721", label: "26721:NAD27 / UTM zone 21N"},
  {coordinate_system:"EPSG:26722", label: "26722:NAD27 / UTM zone 22N"},
  {coordinate_system:"EPSG:26729", label: "26729:NAD27 / Alabama East"},
  {coordinate_system:"EPSG:26730", label: "26730:NAD27 / Alabama West"},
  {coordinate_system:"EPSG:26731", label: "26731:NAD27 / Alaska zone 1"},
  {coordinate_system:"EPSG:26732", label: "26732:NAD27 / Alaska zone 2"},
  {coordinate_system:"EPSG:26733", label: "26733:NAD27 / Alaska zone 3"},
  {coordinate_system:"EPSG:26734", label: "26734:NAD27 / Alaska zone 4"},
  {coordinate_system:"EPSG:26735", label: "26735:NAD27 / Alaska zone 5"},
  {coordinate_system:"EPSG:26736", label: "26736:NAD27 / Alaska zone 6"},
  {coordinate_system:"EPSG:26737", label: "26737:NAD27 / Alaska zone 7"},
  {coordinate_system:"EPSG:26738", label: "26738:NAD27 / Alaska zone 8"},
  {coordinate_system:"EPSG:26739", label: "26739:NAD27 / Alaska zone 9"},
  {coordinate_system:"EPSG:26740", label: "26740:NAD27 / Alaska zone 10"},
  {coordinate_system:"EPSG:26741", label: "26741:NAD27 / California zone I"},
  {coordinate_system:"EPSG:26742", label: "26742:NAD27 / California zone II"},
  {coordinate_system:"EPSG:26743", label: "26743:NAD27 / California zone III"},
  {coordinate_system:"EPSG:26744", label: "26744:NAD27 / California zone IV"},
  {coordinate_system:"EPSG:26745", label: "26745:NAD27 / California zone V"},
  {coordinate_system:"EPSG:26746", label: "26746:NAD27 / California zone VI"},
  {coordinate_system:"EPSG:26748", label: "26748:NAD27 / Arizona East"},
  {coordinate_system:"EPSG:26749", label: "26749:NAD27 / Arizona Central"},
  {coordinate_system:"EPSG:26750", label: "26750:NAD27 / Arizona West"},
  {coordinate_system:"EPSG:26751", label: "26751:NAD27 / Arkansas North"},
  {coordinate_system:"EPSG:26752", label: "26752:NAD27 / Arkansas South"},
  {coordinate_system:"EPSG:26753", label: "26753:NAD27 / Colorado North"},
  {coordinate_system:"EPSG:26754", label: "26754:NAD27 / Colorado Central"},
  {coordinate_system:"EPSG:26755", label: "26755:NAD27 / Colorado South"},
  {coordinate_system:"EPSG:26756", label: "26756:NAD27 / Connecticut"},
  {coordinate_system:"EPSG:26757", label: "26757:NAD27 / Delaware"},
  {coordinate_system:"EPSG:26758", label: "26758:NAD27 / Florida East"},
  {coordinate_system:"EPSG:26759", label: "26759:NAD27 / Florida West"},
  {coordinate_system:"EPSG:26760", label: "26760:NAD27 / Florida North"},
  {coordinate_system:"EPSG:26766", label: "26766:NAD27 / Georgia East"},
  {coordinate_system:"EPSG:26767", label: "26767:NAD27 / Georgia West"},
  {coordinate_system:"EPSG:26768", label: "26768:NAD27 / Idaho East"},
  {coordinate_system:"EPSG:26769", label: "26769:NAD27 / Idaho Central"},
  {coordinate_system:"EPSG:26770", label: "26770:NAD27 / Idaho West"},
  {coordinate_system:"EPSG:26771", label: "26771:NAD27 / Illinois East"},
  {coordinate_system:"EPSG:26772", label: "26772:NAD27 / Illinois West"},
  {coordinate_system:"EPSG:26773", label: "26773:NAD27 / Indiana East"},
  {coordinate_system:"EPSG:26774", label: "26774:NAD27 / Indiana West"},
  {coordinate_system:"EPSG:26775", label: "26775:NAD27 / Iowa North"},
  {coordinate_system:"EPSG:26776", label: "26776:NAD27 / Iowa South"},
  {coordinate_system:"EPSG:26777", label: "26777:NAD27 / Kansas North"},
  {coordinate_system:"EPSG:26778", label: "26778:NAD27 / Kansas South"},
  {coordinate_system:"EPSG:26779", label: "26779:NAD27 / Kentucky North"},
  {coordinate_system:"EPSG:26780", label: "26780:NAD27 / Kentucky South"},
  {coordinate_system:"EPSG:26781", label: "26781:NAD27 / Louisiana North"},
  {coordinate_system:"EPSG:26782", label: "26782:NAD27 / Louisiana South"},
  {coordinate_system:"EPSG:26783", label: "26783:NAD27 / Maine East"},
  {coordinate_system:"EPSG:26784", label: "26784:NAD27 / Maine West"},
  {coordinate_system:"EPSG:26785", label: "26785:NAD27 / Maryland"},
  {coordinate_system:"EPSG:26786", label: "26786:NAD27 / Massachusetts Mainland"},
  {coordinate_system:"EPSG:26787", label: "26787:NAD27 / Massachusetts Island"},
  {coordinate_system:"EPSG:26791", label: "26791:NAD27 / Minnesota North"},
  {coordinate_system:"EPSG:26792", label: "26792:NAD27 / Minnesota Central"},
  {coordinate_system:"EPSG:26793", label: "26793:NAD27 / Minnesota South"},
  {coordinate_system:"EPSG:26794", label: "26794:NAD27 / Mississippi East"},
  {coordinate_system:"EPSG:26795", label: "26795:NAD27 / Mississippi West"},
  {coordinate_system:"EPSG:26796", label: "26796:NAD27 / Missouri East"},
  {coordinate_system:"EPSG:26797", label: "26797:NAD27 / Missouri Central"},
  {coordinate_system:"EPSG:26798", label: "26798:NAD27 / Missouri West"},
  {coordinate_system:"EPSG:26799", label: "26799:NAD27 / California zone VII"},
  {coordinate_system:"EPSG:26847", label: "26847:NAD83 / Maine East (ftUS)"},
  {coordinate_system:"EPSG:26848", label: "26848:NAD83 / Maine West (ftUS)"},
  {coordinate_system:"EPSG:26849", label: "26849:NAD83 / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:26850", label: "26850:NAD83 / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:26851", label: "26851:NAD83 / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:26852", label: "26852:NAD83 / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:26853", label: "26853:NAD83 / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:26854", label: "26854:NAD83 / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:26855", label: "26855:NAD83(HARN) / Maine East (ftUS)"},
  {coordinate_system:"EPSG:26856", label: "26856:NAD83(HARN) / Maine West (ftUS)"},
  {coordinate_system:"EPSG:26857", label: "26857:NAD83(HARN) / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:26858", label: "26858:NAD83(HARN) / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:26859", label: "26859:NAD83(HARN) / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:26860", label: "26860:NAD83(HARN) / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:26861", label: "26861:NAD83(HARN) / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:26862", label: "26862:NAD83(HARN) / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:26863", label: "26863:NAD83(NSRS2007) / Maine East (ftUS)"},
  {coordinate_system:"EPSG:26864", label: "26864:NAD83(NSRS2007) / Maine West (ftUS)"},
  {coordinate_system:"EPSG:26865", label: "26865:NAD83(NSRS2007) / Minnesota North (ftUS)"},
  {coordinate_system:"EPSG:26866", label: "26866:NAD83(NSRS2007) / Minnesota Central (ftUS)"},
  {coordinate_system:"EPSG:26867", label: "26867:NAD83(NSRS2007) / Minnesota South (ftUS)"},
  {coordinate_system:"EPSG:26868", label: "26868:NAD83(NSRS2007) / Nebraska (ftUS)"},
  {coordinate_system:"EPSG:26869", label: "26869:NAD83(NSRS2007) / West Virginia North (ftUS)"},
  {coordinate_system:"EPSG:26870", label: "26870:NAD83(NSRS2007) / West Virginia South (ftUS)"},
  {coordinate_system:"EPSG:26891", label: "26891:NAD83(CSRS) / MTM zone 11"},
  {coordinate_system:"EPSG:26892", label: "26892:NAD83(CSRS) / MTM zone 12"},
  {coordinate_system:"EPSG:26893", label: "26893:NAD83(CSRS) / MTM zone 13"},
  {coordinate_system:"EPSG:26894", label: "26894:NAD83(CSRS) / MTM zone 14"},
  {coordinate_system:"EPSG:26895", label: "26895:NAD83(CSRS) / MTM zone 15"},
  {coordinate_system:"EPSG:26896", label: "26896:NAD83(CSRS) / MTM zone 16"},
  {coordinate_system:"EPSG:26897", label: "26897:NAD83(CSRS) / MTM zone 17"},
  {coordinate_system:"EPSG:26898", label: "26898:NAD83(CSRS) / MTM zone 1"},
  {coordinate_system:"EPSG:26899", label: "26899:NAD83(CSRS) / MTM zone 2"},
  {coordinate_system:"EPSG:26901", label: "26901:NAD83 / UTM zone 1N"},
  {coordinate_system:"EPSG:26902", label: "26902:NAD83 / UTM zone 2N"},
  {coordinate_system:"EPSG:26903", label: "26903:NAD83 / UTM zone 3N"},
  {coordinate_system:"EPSG:26904", label: "26904:NAD83 / UTM zone 4N"},
  {coordinate_system:"EPSG:26905", label: "26905:NAD83 / UTM zone 5N"},
  {coordinate_system:"EPSG:26906", label: "26906:NAD83 / UTM zone 6N"},
  {coordinate_system:"EPSG:26907", label: "26907:NAD83 / UTM zone 7N"},
  {coordinate_system:"EPSG:26908", label: "26908:NAD83 / UTM zone 8N"},
  {coordinate_system:"EPSG:26909", label: "26909:NAD83 / UTM zone 9N"},
  {coordinate_system:"EPSG:26910", label: "26910:NAD83 / UTM zone 10N"},
  {coordinate_system:"EPSG:26911", label: "26911:NAD83 / UTM zone 11N"},
  {coordinate_system:"EPSG:26912", label: "26912:NAD83 / UTM zone 12N"},
  {coordinate_system:"EPSG:26913", label: "26913:NAD83 / UTM zone 13N"},
  {coordinate_system:"EPSG:26914", label: "26914:NAD83 / UTM zone 14N"},
  {coordinate_system:"EPSG:26915", label: "26915:NAD83 / UTM zone 15N"},
  {coordinate_system:"EPSG:26916", label: "26916:NAD83 / UTM zone 16N"},
  {coordinate_system:"EPSG:26917", label: "26917:NAD83 / UTM zone 17N"},
  {coordinate_system:"EPSG:26918", label: "26918:NAD83 / UTM zone 18N"},
  {coordinate_system:"EPSG:26919", label: "26919:NAD83 / UTM zone 19N"},
  {coordinate_system:"EPSG:26920", label: "26920:NAD83 / UTM zone 20N"},
  {coordinate_system:"EPSG:26921", label: "26921:NAD83 / UTM zone 21N"},
  {coordinate_system:"EPSG:26922", label: "26922:NAD83 / UTM zone 22N"},
  {coordinate_system:"EPSG:26923", label: "26923:NAD83 / UTM zone 23N"},
  {coordinate_system:"EPSG:26929", label: "26929:NAD83 / Alabama East"},
  {coordinate_system:"EPSG:26930", label: "26930:NAD83 / Alabama West"},
  {coordinate_system:"EPSG:26931", label: "26931:NAD83 / Alaska zone 1"},
  {coordinate_system:"EPSG:26932", label: "26932:NAD83 / Alaska zone 2"},
  {coordinate_system:"EPSG:26933", label: "26933:NAD83 / Alaska zone 3"},
  {coordinate_system:"EPSG:26934", label: "26934:NAD83 / Alaska zone 4"},
  {coordinate_system:"EPSG:26935", label: "26935:NAD83 / Alaska zone 5"},
  {coordinate_system:"EPSG:26936", label: "26936:NAD83 / Alaska zone 6"},
  {coordinate_system:"EPSG:26937", label: "26937:NAD83 / Alaska zone 7"},
  {coordinate_system:"EPSG:26938", label: "26938:NAD83 / Alaska zone 8"},
  {coordinate_system:"EPSG:26939", label: "26939:NAD83 / Alaska zone 9"},
  {coordinate_system:"EPSG:26940", label: "26940:NAD83 / Alaska zone 10"},
  {coordinate_system:"EPSG:26941", label: "26941:NAD83 / California zone 1"},
  {coordinate_system:"EPSG:26942", label: "26942:NAD83 / California zone 2"},
  {coordinate_system:"EPSG:26943", label: "26943:NAD83 / California zone 3"},
  {coordinate_system:"EPSG:26944", label: "26944:NAD83 / California zone 4"},
  {coordinate_system:"EPSG:26945", label: "26945:NAD83 / California zone 5"},
  {coordinate_system:"EPSG:26946", label: "26946:NAD83 / California zone 6"},
  {coordinate_system:"EPSG:26948", label: "26948:NAD83 / Arizona East"},
  {coordinate_system:"EPSG:26949", label: "26949:NAD83 / Arizona Central"},
  {coordinate_system:"EPSG:26950", label: "26950:NAD83 / Arizona West"},
  {coordinate_system:"EPSG:26951", label: "26951:NAD83 / Arkansas North"},
  {coordinate_system:"EPSG:26952", label: "26952:NAD83 / Arkansas South"},
  {coordinate_system:"EPSG:26953", label: "26953:NAD83 / Colorado North"},
  {coordinate_system:"EPSG:26954", label: "26954:NAD83 / Colorado Central"},
  {coordinate_system:"EPSG:26955", label: "26955:NAD83 / Colorado South"},
  {coordinate_system:"EPSG:26956", label: "26956:NAD83 / Connecticut"},
  {coordinate_system:"EPSG:26957", label: "26957:NAD83 / Delaware"},
  {coordinate_system:"EPSG:26958", label: "26958:NAD83 / Florida East"},
  {coordinate_system:"EPSG:26959", label: "26959:NAD83 / Florida West"},
  {coordinate_system:"EPSG:26960", label: "26960:NAD83 / Florida North"},
  {coordinate_system:"EPSG:26961", label: "26961:NAD83 / Hawaii zone 1"},
  {coordinate_system:"EPSG:26962", label: "26962:NAD83 / Hawaii zone 2"},
  {coordinate_system:"EPSG:26963", label: "26963:NAD83 / Hawaii zone 3"},
  {coordinate_system:"EPSG:26964", label: "26964:NAD83 / Hawaii zone 4"},
  {coordinate_system:"EPSG:26965", label: "26965:NAD83 / Hawaii zone 5"},
  {coordinate_system:"EPSG:26966", label: "26966:NAD83 / Georgia East"},
  {coordinate_system:"EPSG:26967", label: "26967:NAD83 / Georgia West"},
  {coordinate_system:"EPSG:26968", label: "26968:NAD83 / Idaho East"},
  {coordinate_system:"EPSG:26969", label: "26969:NAD83 / Idaho Central"},
  {coordinate_system:"EPSG:26970", label: "26970:NAD83 / Idaho West"},
  {coordinate_system:"EPSG:26971", label: "26971:NAD83 / Illinois East"},
  {coordinate_system:"EPSG:26972", label: "26972:NAD83 / Illinois West"},
  {coordinate_system:"EPSG:26973", label: "26973:NAD83 / Indiana East"},
  {coordinate_system:"EPSG:26974", label: "26974:NAD83 / Indiana West"},
  {coordinate_system:"EPSG:26975", label: "26975:NAD83 / Iowa North"},
  {coordinate_system:"EPSG:26976", label: "26976:NAD83 / Iowa South"},
  {coordinate_system:"EPSG:26977", label: "26977:NAD83 / Kansas North"},
  {coordinate_system:"EPSG:26978", label: "26978:NAD83 / Kansas South"},
  {coordinate_system:"EPSG:26980", label: "26980:NAD83 / Kentucky South"},
  {coordinate_system:"EPSG:26981", label: "26981:NAD83 / Louisiana North"},
  {coordinate_system:"EPSG:26982", label: "26982:NAD83 / Louisiana South"},
  {coordinate_system:"EPSG:26983", label: "26983:NAD83 / Maine East"},
  {coordinate_system:"EPSG:26984", label: "26984:NAD83 / Maine West"},
  {coordinate_system:"EPSG:26985", label: "26985:NAD83 / Maryland"},
  {coordinate_system:"EPSG:26986", label: "26986:NAD83 / Massachusetts Mainland"},
  {coordinate_system:"EPSG:26987", label: "26987:NAD83 / Massachusetts Island"},
  {coordinate_system:"EPSG:26988", label: "26988:NAD83 / Michigan North"},
  {coordinate_system:"EPSG:26989", label: "26989:NAD83 / Michigan Central"},
  {coordinate_system:"EPSG:26990", label: "26990:NAD83 / Michigan South"},
  {coordinate_system:"EPSG:26991", label: "26991:NAD83 / Minnesota North"},
  {coordinate_system:"EPSG:26992", label: "26992:NAD83 / Minnesota Central"},
  {coordinate_system:"EPSG:26993", label: "26993:NAD83 / Minnesota South"},
  {coordinate_system:"EPSG:26994", label: "26994:NAD83 / Mississippi East"},
  {coordinate_system:"EPSG:26995", label: "26995:NAD83 / Mississippi West"},
  {coordinate_system:"EPSG:26996", label: "26996:NAD83 / Missouri East"},
  {coordinate_system:"EPSG:26997", label: "26997:NAD83 / Missouri Central"},
  {coordinate_system:"EPSG:26998", label: "26998:NAD83 / Missouri West"},
  {coordinate_system:"EPSG:27039", label: "27039:Nahrwan 1967 / UTM zone 39N"},
  {coordinate_system:"EPSG:27040", label: "27040:Nahrwan 1967 / UTM zone 40N"},
  {coordinate_system:"EPSG:27120", label: "27120:Naparima 1972 / UTM zone 20N"},
  {coordinate_system:"EPSG:27200", label: "27200:NZGD49 / New Zealand Map Grid"},
  {coordinate_system:"EPSG:27205", label: "27205:NZGD49 / Mount Eden Circuit"},
  {coordinate_system:"EPSG:27206", label: "27206:NZGD49 / Bay of Plenty Circuit"},
  {coordinate_system:"EPSG:27207", label: "27207:NZGD49 / Poverty Bay Circuit"},
  {coordinate_system:"EPSG:27208", label: "27208:NZGD49 / Hawkes Bay Circuit"},
  {coordinate_system:"EPSG:27209", label: "27209:NZGD49 / Taranaki Circuit"},
  {coordinate_system:"EPSG:27210", label: "27210:NZGD49 / Tuhirangi Circuit"},
  {coordinate_system:"EPSG:27211", label: "27211:NZGD49 / Wanganui Circuit"},
  {coordinate_system:"EPSG:27212", label: "27212:NZGD49 / Wairarapa Circuit"},
  {coordinate_system:"EPSG:27213", label: "27213:NZGD49 / Wellington Circuit"},
  {coordinate_system:"EPSG:27214", label: "27214:NZGD49 / Collingwood Circuit"},
  {coordinate_system:"EPSG:27215", label: "27215:NZGD49 / Nelson Circuit"},
  {coordinate_system:"EPSG:27216", label: "27216:NZGD49 / Karamea Circuit"},
  {coordinate_system:"EPSG:27217", label: "27217:NZGD49 / Buller Circuit"},
  {coordinate_system:"EPSG:27218", label: "27218:NZGD49 / Grey Circuit"},
  {coordinate_system:"EPSG:27219", label: "27219:NZGD49 / Amuri Circuit"},
  {coordinate_system:"EPSG:27220", label: "27220:NZGD49 / Marlborough Circuit"},
  {coordinate_system:"EPSG:27221", label: "27221:NZGD49 / Hokitika Circuit"},
  {coordinate_system:"EPSG:27222", label: "27222:NZGD49 / Okarito Circuit"},
  {coordinate_system:"EPSG:27223", label: "27223:NZGD49 / Jacksons Bay Circuit"},
  {coordinate_system:"EPSG:27224", label: "27224:NZGD49 / Mount Pleasant Circuit"},
  {coordinate_system:"EPSG:27225", label: "27225:NZGD49 / Gawler Circuit"},
  {coordinate_system:"EPSG:27226", label: "27226:NZGD49 / Timaru Circuit"},
  {coordinate_system:"EPSG:27227", label: "27227:NZGD49 / Lindis Peak Circuit"},
  {coordinate_system:"EPSG:27228", label: "27228:NZGD49 / Mount Nicholas Circuit"},
  {coordinate_system:"EPSG:27229", label: "27229:NZGD49 / Mount York Circuit"},
  {coordinate_system:"EPSG:27230", label: "27230:NZGD49 / Observation Point Circuit"},
  {coordinate_system:"EPSG:27231", label: "27231:NZGD49 / North Taieri Circuit"},
  {coordinate_system:"EPSG:27232", label: "27232:NZGD49 / Bluff Circuit"},
  {coordinate_system:"EPSG:27258", label: "27258:NZGD49 / UTM zone 58S"},
  {coordinate_system:"EPSG:27259", label: "27259:NZGD49 / UTM zone 59S"},
  {coordinate_system:"EPSG:27260", label: "27260:NZGD49 / UTM zone 60S"},
  {coordinate_system:"EPSG:27291", label: "27291:NZGD49 / North Island Grid"},
  {coordinate_system:"EPSG:27292", label: "27292:NZGD49 / South Island Grid"},
  {coordinate_system:"EPSG:27391", label: "27391:NGO 1948 (Oslo) / NGO zone I"},
  {coordinate_system:"EPSG:27392", label: "27392:NGO 1948 (Oslo) / NGO zone II"},
  {coordinate_system:"EPSG:27393", label: "27393:NGO 1948 (Oslo) / NGO zone III"},
  {coordinate_system:"EPSG:27394", label: "27394:NGO 1948 (Oslo) / NGO zone IV"},
  {coordinate_system:"EPSG:27395", label: "27395:NGO 1948 (Oslo) / NGO zone V"},
  {coordinate_system:"EPSG:27396", label: "27396:NGO 1948 (Oslo) / NGO zone VI"},
  {coordinate_system:"EPSG:27397", label: "27397:NGO 1948 (Oslo) / NGO zone VII"},
  {coordinate_system:"EPSG:27398", label: "27398:NGO 1948 (Oslo) / NGO zone VIII"},
  {coordinate_system:"EPSG:27429", label: "27429:Datum 73 / UTM zone 29N"},
  {coordinate_system:"EPSG:27493", label: "27493:Datum 73 / Modified Portuguese Grid"},
  {coordinate_system:"EPSG:27500", label: "27500:ATF (Paris) / Nord de Guerre"},
  {coordinate_system:"EPSG:27561", label: "27561:NTF (Paris) / Lambert Nord France"},
  {coordinate_system:"EPSG:27562", label: "27562:NTF (Paris) / Lambert Centre France"},
  {coordinate_system:"EPSG:27563", label: "27563:NTF (Paris) / Lambert Sud France"},
  {coordinate_system:"EPSG:27564", label: "27564:NTF (Paris) / Lambert Corse"},
  {coordinate_system:"EPSG:27571", label: "27571:NTF (Paris) / Lambert zone I"},
  {coordinate_system:"EPSG:27572", label: "27572:NTF (Paris) / Lambert zone II"},
  {coordinate_system:"EPSG:27573", label: "27573:NTF (Paris) / Lambert zone III"},
  {coordinate_system:"EPSG:27574", label: "27574:NTF (Paris) / Lambert zone IV"},
  {coordinate_system:"EPSG:27700", label: "27700:OSGB36 / British National Grid"},
  {coordinate_system:"EPSG:28191", label: "28191:Palestine 1923 / Palestine Grid"},
  {coordinate_system:"EPSG:28192", label: "28192:Palestine 1923 / Palestine Belt"},
  {coordinate_system:"EPSG:28193", label: "28193:Palestine 1923 / Israeli CS Grid"},
  {coordinate_system:"EPSG:28232", label: "28232:Pointe Noire / UTM zone 32S"},
  {coordinate_system:"EPSG:28348", label: "28348:GDA94 / MGA zone 48"},
  {coordinate_system:"EPSG:28349", label: "28349:GDA94 / MGA zone 49"},
  {coordinate_system:"EPSG:28350", label: "28350:GDA94 / MGA zone 50"},
  {coordinate_system:"EPSG:28351", label: "28351:GDA94 / MGA zone 51"},
  {coordinate_system:"EPSG:28352", label: "28352:GDA94 / MGA zone 52"},
  {coordinate_system:"EPSG:28353", label: "28353:GDA94 / MGA zone 53"},
  {coordinate_system:"EPSG:28354", label: "28354:GDA94 / MGA zone 54"},
  {coordinate_system:"EPSG:28355", label: "28355:GDA94 / MGA zone 55"},
  {coordinate_system:"EPSG:28356", label: "28356:GDA94 / MGA zone 56"},
  {coordinate_system:"EPSG:28357", label: "28357:GDA94 / MGA zone 57"},
  {coordinate_system:"EPSG:28358", label: "28358:GDA94 / MGA zone 58"},
  {coordinate_system:"EPSG:28404", label: "28404:Pulkovo 1942 / Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:28405", label: "28405:Pulkovo 1942 / Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:28406", label: "28406:Pulkovo 1942 / Gauss-Kruger zone 6"},
  {coordinate_system:"EPSG:28407", label: "28407:Pulkovo 1942 / Gauss-Kruger zone 7"},
  {coordinate_system:"EPSG:28408", label: "28408:Pulkovo 1942 / Gauss-Kruger zone 8"},
  {coordinate_system:"EPSG:28409", label: "28409:Pulkovo 1942 / Gauss-Kruger zone 9"},
  {coordinate_system:"EPSG:28410", label: "28410:Pulkovo 1942 / Gauss-Kruger zone 10"},
  {coordinate_system:"EPSG:28411", label: "28411:Pulkovo 1942 / Gauss-Kruger zone 11"},
  {coordinate_system:"EPSG:28412", label: "28412:Pulkovo 1942 / Gauss-Kruger zone 12"},
  {coordinate_system:"EPSG:28413", label: "28413:Pulkovo 1942 / Gauss-Kruger zone 13"},
  {coordinate_system:"EPSG:28414", label: "28414:Pulkovo 1942 / Gauss-Kruger zone 14"},
  {coordinate_system:"EPSG:28415", label: "28415:Pulkovo 1942 / Gauss-Kruger zone 15"},
  {coordinate_system:"EPSG:28416", label: "28416:Pulkovo 1942 / Gauss-Kruger zone 16"},
  {coordinate_system:"EPSG:28417", label: "28417:Pulkovo 1942 / Gauss-Kruger zone 17"},
  {coordinate_system:"EPSG:28418", label: "28418:Pulkovo 1942 / Gauss-Kruger zone 18"},
  {coordinate_system:"EPSG:28419", label: "28419:Pulkovo 1942 / Gauss-Kruger zone 19"},
  {coordinate_system:"EPSG:28420", label: "28420:Pulkovo 1942 / Gauss-Kruger zone 20"},
  {coordinate_system:"EPSG:28421", label: "28421:Pulkovo 1942 / Gauss-Kruger zone 21"},
  {coordinate_system:"EPSG:28422", label: "28422:Pulkovo 1942 / Gauss-Kruger zone 22"},
  {coordinate_system:"EPSG:28423", label: "28423:Pulkovo 1942 / Gauss-Kruger zone 23"},
  {coordinate_system:"EPSG:28424", label: "28424:Pulkovo 1942 / Gauss-Kruger zone 24"},
  {coordinate_system:"EPSG:28425", label: "28425:Pulkovo 1942 / Gauss-Kruger zone 25"},
  {coordinate_system:"EPSG:28426", label: "28426:Pulkovo 1942 / Gauss-Kruger zone 26"},
  {coordinate_system:"EPSG:28427", label: "28427:Pulkovo 1942 / Gauss-Kruger zone 27"},
  {coordinate_system:"EPSG:28428", label: "28428:Pulkovo 1942 / Gauss-Kruger zone 28"},
  {coordinate_system:"EPSG:28429", label: "28429:Pulkovo 1942 / Gauss-Kruger zone 29"},
  {coordinate_system:"EPSG:28430", label: "28430:Pulkovo 1942 / Gauss-Kruger zone 30"},
  {coordinate_system:"EPSG:28431", label: "28431:Pulkovo 1942 / Gauss-Kruger zone 31"},
  {coordinate_system:"EPSG:28432", label: "28432:Pulkovo 1942 / Gauss-Kruger zone 32"},
  {coordinate_system:"EPSG:28600", label: "28600:Qatar 1974 / Qatar National Grid"},
  {coordinate_system:"EPSG:28991", label: "28991:Amersfoort / RD Old"},
  {coordinate_system:"EPSG:28992", label: "28992:Amersfoort / RD New"},
  {coordinate_system:"EPSG:29101", label: "29101:SAD69 / Brazil Polyconic"},
  {coordinate_system:"EPSG:29168", label: "29168:SAD69 / UTM zone 18N"},
  {coordinate_system:"EPSG:29169", label: "29169:SAD69 / UTM zone 19N"},
  {coordinate_system:"EPSG:29170", label: "29170:SAD69 / UTM zone 20N"},
  {coordinate_system:"EPSG:29171", label: "29171:SAD69 / UTM zone 21N"},
  {coordinate_system:"EPSG:29172", label: "29172:SAD69 / UTM zone 22N"},
  {coordinate_system:"EPSG:29187", label: "29187:SAD69 / UTM zone 17S"},
  {coordinate_system:"EPSG:29188", label: "29188:SAD69 / UTM zone 18S"},
  {coordinate_system:"EPSG:29189", label: "29189:SAD69 / UTM zone 19S"},
  {coordinate_system:"EPSG:29190", label: "29190:SAD69 / UTM zone 20S"},
  {coordinate_system:"EPSG:29191", label: "29191:SAD69 / UTM zone 21S"},
  {coordinate_system:"EPSG:29192", label: "29192:SAD69 / UTM zone 22S"},
  {coordinate_system:"EPSG:29193", label: "29193:SAD69 / UTM zone 23S"},
  {coordinate_system:"EPSG:29194", label: "29194:SAD69 / UTM zone 24S"},
  {coordinate_system:"EPSG:29195", label: "29195:SAD69 / UTM zone 25S"},
  {coordinate_system:"EPSG:29220", label: "29220:Sapper Hill 1943 / UTM zone 20S"},
  {coordinate_system:"EPSG:29221", label: "29221:Sapper Hill 1943 / UTM zone 21S"},
  {coordinate_system:"EPSG:29333", label: "29333:Schwarzeck / UTM zone 33S"},
  {coordinate_system:"EPSG:29371", label: "29371:Schwarzeck / Lo22/11"},
  {coordinate_system:"EPSG:29373", label: "29373:Schwarzeck / Lo22/13"},
  {coordinate_system:"EPSG:29375", label: "29375:Schwarzeck / Lo22/15"},
  {coordinate_system:"EPSG:29377", label: "29377:Schwarzeck / Lo22/17"},
  {coordinate_system:"EPSG:29379", label: "29379:Schwarzeck / Lo22/19"},
  {coordinate_system:"EPSG:29381", label: "29381:Schwarzeck / Lo22/21"},
  {coordinate_system:"EPSG:29383", label: "29383:Schwarzeck / Lo22/23"},
  {coordinate_system:"EPSG:29385", label: "29385:Schwarzeck / Lo22/25"},
  {coordinate_system:"EPSG:29701", label: "29701:Tananarive (Paris) / Laborde Grid"},
  {coordinate_system:"EPSG:29702", label: "29702:Tananarive (Paris) / Laborde Grid approximation"},
  {coordinate_system:"EPSG:29738", label: "29738:Tananarive / UTM zone 38S"},
  {coordinate_system:"EPSG:29739", label: "29739:Tananarive / UTM zone 39S"},
  {coordinate_system:"EPSG:29849", label: "29849:Timbalai 1948 / UTM zone 49N"},
  {coordinate_system:"EPSG:29850", label: "29850:Timbalai 1948 / UTM zone 50N"},
  {coordinate_system:"EPSG:29871", label: "29871:Timbalai 1948 / RSO Borneo (ch)"},
  {coordinate_system:"EPSG:29872", label: "29872:Timbalai 1948 / RSO Borneo (ftSe)"},
  {coordinate_system:"EPSG:29873", label: "29873:Timbalai 1948 / RSO Borneo (m)"},
  {coordinate_system:"EPSG:29874", label: "29874:Timbalai 1948 / RSO Sarawak LSD (m)"},
  {coordinate_system:"EPSG:29901", label: "29901:OSNI 1952 / Irish National Grid"},
  {coordinate_system:"EPSG:29902", label: "29902:TM65 / Irish Grid"},
  {coordinate_system:"EPSG:29903", label: "29903:TM75 / Irish Grid"},
  {coordinate_system:"EPSG:30161", label: "30161:Tokyo / Japan Plane Rectangular CS I"},
  {coordinate_system:"EPSG:30162", label: "30162:Tokyo / Japan Plane Rectangular CS II"},
  {coordinate_system:"EPSG:30163", label: "30163:Tokyo / Japan Plane Rectangular CS III"},
  {coordinate_system:"EPSG:30164", label: "30164:Tokyo / Japan Plane Rectangular CS IV"},
  {coordinate_system:"EPSG:30165", label: "30165:Tokyo / Japan Plane Rectangular CS V"},
  {coordinate_system:"EPSG:30166", label: "30166:Tokyo / Japan Plane Rectangular CS VI"},
  {coordinate_system:"EPSG:30167", label: "30167:Tokyo / Japan Plane Rectangular CS VII"},
  {coordinate_system:"EPSG:30168", label: "30168:Tokyo / Japan Plane Rectangular CS VIII"},
  {coordinate_system:"EPSG:30169", label: "30169:Tokyo / Japan Plane Rectangular CS IX"},
  {coordinate_system:"EPSG:30170", label: "30170:Tokyo / Japan Plane Rectangular CS X"},
  {coordinate_system:"EPSG:30171", label: "30171:Tokyo / Japan Plane Rectangular CS XI"},
  {coordinate_system:"EPSG:30172", label: "30172:Tokyo / Japan Plane Rectangular CS XII"},
  {coordinate_system:"EPSG:30173", label: "30173:Tokyo / Japan Plane Rectangular CS XIII"},
  {coordinate_system:"EPSG:30174", label: "30174:Tokyo / Japan Plane Rectangular CS XIV"},
  {coordinate_system:"EPSG:30175", label: "30175:Tokyo / Japan Plane Rectangular CS XV"},
  {coordinate_system:"EPSG:30176", label: "30176:Tokyo / Japan Plane Rectangular CS XVI"},
  {coordinate_system:"EPSG:30177", label: "30177:Tokyo / Japan Plane Rectangular CS XVII"},
  {coordinate_system:"EPSG:30178", label: "30178:Tokyo / Japan Plane Rectangular CS XVIII"},
  {coordinate_system:"EPSG:30179", label: "30179:Tokyo / Japan Plane Rectangular CS XIX"},
  {coordinate_system:"EPSG:30200", label: "30200:Trinidad 1903 / Trinidad Grid"},
  {coordinate_system:"EPSG:30339", label: "30339:TC(1948) / UTM zone 39N"},
  {coordinate_system:"EPSG:30340", label: "30340:TC(1948) / UTM zone 40N"},
  {coordinate_system:"EPSG:30491", label: "30491:Voirol 1875 / Nord Algerie (ancienne)"},
  {coordinate_system:"EPSG:30492", label: "30492:Voirol 1875 / Sud Algerie (ancienne)"},
  {coordinate_system:"EPSG:30493", label: "30493:Voirol 1879 / Nord Algerie (ancienne)"},
  {coordinate_system:"EPSG:30494", label: "30494:Voirol 1879 / Sud Algerie (ancienne)"},
  {coordinate_system:"EPSG:30729", label: "30729:Nord Sahara 1959 / UTM zone 29N"},
  {coordinate_system:"EPSG:30730", label: "30730:Nord Sahara 1959 / UTM zone 30N"},
  {coordinate_system:"EPSG:30731", label: "30731:Nord Sahara 1959 / UTM zone 31N"},
  {coordinate_system:"EPSG:30732", label: "30732:Nord Sahara 1959 / UTM zone 32N"},
  {coordinate_system:"EPSG:30791", label: "30791:Nord Sahara 1959 / Nord Algerie"},
  {coordinate_system:"EPSG:30792", label: "30792:Nord Sahara 1959 / Sud Algerie"},
  {coordinate_system:"EPSG:31028", label: "31028:Yoff / UTM zone 28N"},
  {coordinate_system:"EPSG:31121", label: "31121:Zanderij / UTM zone 21N"},
  {coordinate_system:"EPSG:31154", label: "31154:Zanderij / TM 54 NW"},
  {coordinate_system:"EPSG:31170", label: "31170:Zanderij / Suriname Old TM"},
  {coordinate_system:"EPSG:31171", label: "31171:Zanderij / Suriname TM"},
  {coordinate_system:"EPSG:31251", label: "31251:MGI (Ferro) / Austria GK West Zone"},
  {coordinate_system:"EPSG:31252", label: "31252:MGI (Ferro) / Austria GK Central Zone"},
  {coordinate_system:"EPSG:31253", label: "31253:MGI (Ferro) / Austria GK East Zone"},
  {coordinate_system:"EPSG:31254", label: "31254:MGI / Austria GK West"},
  {coordinate_system:"EPSG:31255", label: "31255:MGI / Austria GK Central"},
  {coordinate_system:"EPSG:31256", label: "31256:MGI / Austria GK East"},
  {coordinate_system:"EPSG:31257", label: "31257:MGI / Austria GK M28"},
  {coordinate_system:"EPSG:31258", label: "31258:MGI / Austria GK M31"},
  {coordinate_system:"EPSG:31259", label: "31259:MGI / Austria GK M34"},
  {coordinate_system:"EPSG:31281", label: "31281:MGI (Ferro) / Austria West Zone"},
  {coordinate_system:"EPSG:31282", label: "31282:MGI (Ferro) / Austria Central Zone"},
  {coordinate_system:"EPSG:31283", label: "31283:MGI (Ferro) / Austria East Zone"},
  {coordinate_system:"EPSG:31284", label: "31284:MGI / Austria M28"},
  {coordinate_system:"EPSG:31285", label: "31285:MGI / Austria M31"},
  {coordinate_system:"EPSG:31286", label: "31286:MGI / Austria M34"},
  {coordinate_system:"EPSG:31287", label: "31287:MGI / Austria Lambert"},
  {coordinate_system:"EPSG:31288", label: "31288:MGI (Ferro) / Austria zone M28"},
  {coordinate_system:"EPSG:31289", label: "31289:MGI (Ferro) / Austria zone M31"},
  {coordinate_system:"EPSG:31290", label: "31290:MGI (Ferro) / Austria zone M34"},
  {coordinate_system:"EPSG:31300", label: "31300:BD72 / Belge Lambert 72"},
  {coordinate_system:"EPSG:31370", label: "31370:BD72 / Belgian Lambert 72"},
  {coordinate_system:"EPSG:31466", label: "31466:DHDN / 3-degree Gauss-Kruger zone 2"},
  {coordinate_system:"EPSG:31467", label: "31467:DHDN / 3-degree Gauss-Kruger zone 3"},
  {coordinate_system:"EPSG:31468", label: "31468:DHDN / 3-degree Gauss-Kruger zone 4"},
  {coordinate_system:"EPSG:31469", label: "31469:DHDN / 3-degree Gauss-Kruger zone 5"},
  {coordinate_system:"EPSG:31528", label: "31528:Conakry 1905 / UTM zone 28N"},
  {coordinate_system:"EPSG:31529", label: "31529:Conakry 1905 / UTM zone 29N"},
  {coordinate_system:"EPSG:31600", label: "31600:Dealul Piscului 1930 / Stereo 33"},
  {coordinate_system:"EPSG:31838", label: "31838:NGN / UTM zone 38N"},
  {coordinate_system:"EPSG:31839", label: "31839:NGN / UTM zone 39N"},
  {coordinate_system:"EPSG:31901", label: "31901:KUDAMS / KTM"},
  {coordinate_system:"EPSG:31965", label: "31965:SIRGAS 2000 / UTM zone 11N"},
  {coordinate_system:"EPSG:31966", label: "31966:SIRGAS 2000 / UTM zone 12N"},
  {coordinate_system:"EPSG:31967", label: "31967:SIRGAS 2000 / UTM zone 13N"},
  {coordinate_system:"EPSG:31968", label: "31968:SIRGAS 2000 / UTM zone 14N"},
  {coordinate_system:"EPSG:31969", label: "31969:SIRGAS 2000 / UTM zone 15N"},
  {coordinate_system:"EPSG:31970", label: "31970:SIRGAS 2000 / UTM zone 16N"},
  {coordinate_system:"EPSG:31971", label: "31971:SIRGAS 2000 / UTM zone 17N"},
  {coordinate_system:"EPSG:31972", label: "31972:SIRGAS 2000 / UTM zone 18N"},
  {coordinate_system:"EPSG:31973", label: "31973:SIRGAS 2000 / UTM zone 19N"},
  {coordinate_system:"EPSG:31974", label: "31974:SIRGAS 2000 / UTM zone 20N"},
  {coordinate_system:"EPSG:31975", label: "31975:SIRGAS 2000 / UTM zone 21N"},
  {coordinate_system:"EPSG:31976", label: "31976:SIRGAS 2000 / UTM zone 22N"},
  {coordinate_system:"EPSG:31977", label: "31977:SIRGAS 2000 / UTM zone 17S"},
  {coordinate_system:"EPSG:31978", label: "31978:SIRGAS 2000 / UTM zone 18S"},
  {coordinate_system:"EPSG:31979", label: "31979:SIRGAS 2000 / UTM zone 19S"},
  {coordinate_system:"EPSG:31980", label: "31980:SIRGAS 2000 / UTM zone 20S"},
  {coordinate_system:"EPSG:31981", label: "31981:SIRGAS 2000 / UTM zone 21S"},
  {coordinate_system:"EPSG:31982", label: "31982:SIRGAS 2000 / UTM zone 22S"},
  {coordinate_system:"EPSG:31983", label: "31983:SIRGAS 2000 / UTM zone 23S"},
  {coordinate_system:"EPSG:31984", label: "31984:SIRGAS 2000 / UTM zone 24S"},
  {coordinate_system:"EPSG:31985", label: "31985:SIRGAS 2000 / UTM zone 25S"},
  {coordinate_system:"EPSG:31986", label: "31986:SIRGAS 1995 / UTM zone 17N"},
  {coordinate_system:"EPSG:31987", label: "31987:SIRGAS 1995 / UTM zone 18N"},
  {coordinate_system:"EPSG:31988", label: "31988:SIRGAS 1995 / UTM zone 19N"},
  {coordinate_system:"EPSG:31989", label: "31989:SIRGAS 1995 / UTM zone 20N"},
  {coordinate_system:"EPSG:31990", label: "31990:SIRGAS 1995 / UTM zone 21N"},
  {coordinate_system:"EPSG:31991", label: "31991:SIRGAS 1995 / UTM zone 22N"},
  {coordinate_system:"EPSG:31992", label: "31992:SIRGAS 1995 / UTM zone 17S"},
  {coordinate_system:"EPSG:31993", label: "31993:SIRGAS 1995 / UTM zone 18S"},
  {coordinate_system:"EPSG:31994", label: "31994:SIRGAS 1995 / UTM zone 19S"},
  {coordinate_system:"EPSG:31995", label: "31995:SIRGAS 1995 / UTM zone 20S"},
  {coordinate_system:"EPSG:31996", label: "31996:SIRGAS 1995 / UTM zone 21S"},
  {coordinate_system:"EPSG:31997", label: "31997:SIRGAS 1995 / UTM zone 22S"},
  {coordinate_system:"EPSG:31998", label: "31998:SIRGAS 1995 / UTM zone 23S"},
  {coordinate_system:"EPSG:31999", label: "31999:SIRGAS 1995 / UTM zone 24S"},
  {coordinate_system:"EPSG:32000", label: "32000:SIRGAS 1995 / UTM zone 25S"},
  {coordinate_system:"EPSG:32001", label: "32001:NAD27 / Montana North"},
  {coordinate_system:"EPSG:32002", label: "32002:NAD27 / Montana Central"},
  {coordinate_system:"EPSG:32003", label: "32003:NAD27 / Montana South"},
  {coordinate_system:"EPSG:32005", label: "32005:NAD27 / Nebraska North"},
  {coordinate_system:"EPSG:32006", label: "32006:NAD27 / Nebraska South"},
  {coordinate_system:"EPSG:32007", label: "32007:NAD27 / Nevada East"},
  {coordinate_system:"EPSG:32008", label: "32008:NAD27 / Nevada Central"},
  {coordinate_system:"EPSG:32009", label: "32009:NAD27 / Nevada West"},
  {coordinate_system:"EPSG:32010", label: "32010:NAD27 / New Hampshire"},
  {coordinate_system:"EPSG:32011", label: "32011:NAD27 / New Jersey"},
  {coordinate_system:"EPSG:32012", label: "32012:NAD27 / New Mexico East"},
  {coordinate_system:"EPSG:32013", label: "32013:NAD27 / New Mexico Central"},
  {coordinate_system:"EPSG:32014", label: "32014:NAD27 / New Mexico West"},
  {coordinate_system:"EPSG:32015", label: "32015:NAD27 / New York East"},
  {coordinate_system:"EPSG:32016", label: "32016:NAD27 / New York Central"},
  {coordinate_system:"EPSG:32017", label: "32017:NAD27 / New York West"},
  {coordinate_system:"EPSG:32019", label: "32019:NAD27 / North Carolina"},
  {coordinate_system:"EPSG:32020", label: "32020:NAD27 / North Dakota North"},
  {coordinate_system:"EPSG:32021", label: "32021:NAD27 / North Dakota South"},
  {coordinate_system:"EPSG:32022", label: "32022:NAD27 / Ohio North"},
  {coordinate_system:"EPSG:32023", label: "32023:NAD27 / Ohio South"},
  {coordinate_system:"EPSG:32024", label: "32024:NAD27 / Oklahoma North"},
  {coordinate_system:"EPSG:32025", label: "32025:NAD27 / Oklahoma South"},
  {coordinate_system:"EPSG:32026", label: "32026:NAD27 / Oregon North"},
  {coordinate_system:"EPSG:32027", label: "32027:NAD27 / Oregon South"},
  {coordinate_system:"EPSG:32028", label: "32028:NAD27 / Pennsylvania North"},
  {coordinate_system:"EPSG:32030", label: "32030:NAD27 / Rhode Island"},
  {coordinate_system:"EPSG:32031", label: "32031:NAD27 / South Carolina North"},
  {coordinate_system:"EPSG:32033", label: "32033:NAD27 / South Carolina South"},
  {coordinate_system:"EPSG:32034", label: "32034:NAD27 / South Dakota North"},
  {coordinate_system:"EPSG:32035", label: "32035:NAD27 / South Dakota South"},
  {coordinate_system:"EPSG:32037", label: "32037:NAD27 / Texas North"},
  {coordinate_system:"EPSG:32038", label: "32038:NAD27 / Texas North Central"},
  {coordinate_system:"EPSG:32039", label: "32039:NAD27 / Texas Central"},
  {coordinate_system:"EPSG:32040", label: "32040:NAD27 / Texas South Central"},
  {coordinate_system:"EPSG:32041", label: "32041:NAD27 / Texas South"},
  {coordinate_system:"EPSG:32042", label: "32042:NAD27 / Utah North"},
  {coordinate_system:"EPSG:32043", label: "32043:NAD27 / Utah Central"},
  {coordinate_system:"EPSG:32044", label: "32044:NAD27 / Utah South"},
  {coordinate_system:"EPSG:32045", label: "32045:NAD27 / Vermont"},
  {coordinate_system:"EPSG:32046", label: "32046:NAD27 / Virginia North"},
  {coordinate_system:"EPSG:32047", label: "32047:NAD27 / Virginia South"},
  {coordinate_system:"EPSG:32048", label: "32048:NAD27 / Washington North"},
  {coordinate_system:"EPSG:32049", label: "32049:NAD27 / Washington South"},
  {coordinate_system:"EPSG:32050", label: "32050:NAD27 / West Virginia North"},
  {coordinate_system:"EPSG:32051", label: "32051:NAD27 / West Virginia South"},
  {coordinate_system:"EPSG:32052", label: "32052:NAD27 / Wisconsin North"},
  {coordinate_system:"EPSG:32053", label: "32053:NAD27 / Wisconsin Central"},
  {coordinate_system:"EPSG:32054", label: "32054:NAD27 / Wisconsin South"},
  {coordinate_system:"EPSG:32055", label: "32055:NAD27 / Wyoming East"},
  {coordinate_system:"EPSG:32056", label: "32056:NAD27 / Wyoming East Central"},
  {coordinate_system:"EPSG:32057", label: "32057:NAD27 / Wyoming West Central"},
  {coordinate_system:"EPSG:32058", label: "32058:NAD27 / Wyoming West"},
  {coordinate_system:"EPSG:32064", label: "32064:NAD27 / BLM 14N (ftUS)"},
  {coordinate_system:"EPSG:32065", label: "32065:NAD27 / BLM 15N (ftUS)"},
  {coordinate_system:"EPSG:32066", label: "32066:NAD27 / BLM 16N (ftUS)"},
  {coordinate_system:"EPSG:32067", label: "32067:NAD27 / BLM 17N (ftUS)"},
  {coordinate_system:"EPSG:32081", label: "32081:NAD27 / MTM zone 1"},
  {coordinate_system:"EPSG:32082", label: "32082:NAD27 / MTM zone 2"},
  {coordinate_system:"EPSG:32083", label: "32083:NAD27 / MTM zone 3"},
  {coordinate_system:"EPSG:32084", label: "32084:NAD27 / MTM zone 4"},
  {coordinate_system:"EPSG:32085", label: "32085:NAD27 / MTM zone 5"},
  {coordinate_system:"EPSG:32086", label: "32086:NAD27 / MTM zone 6"},
  {coordinate_system:"EPSG:32098", label: "32098:NAD27 / Quebec Lambert"},
  {coordinate_system:"EPSG:32099", label: "32099:NAD27 / Louisiana Offshore"},
  {coordinate_system:"EPSG:32100", label: "32100:NAD83 / Montana"},
  {coordinate_system:"EPSG:32104", label: "32104:NAD83 / Nebraska"},
  {coordinate_system:"EPSG:32107", label: "32107:NAD83 / Nevada East"},
  {coordinate_system:"EPSG:32108", label: "32108:NAD83 / Nevada Central"},
  {coordinate_system:"EPSG:32109", label: "32109:NAD83 / Nevada West"},
  {coordinate_system:"EPSG:32110", label: "32110:NAD83 / New Hampshire"},
  {coordinate_system:"EPSG:32111", label: "32111:NAD83 / New Jersey"},
  {coordinate_system:"EPSG:32112", label: "32112:NAD83 / New Mexico East"},
  {coordinate_system:"EPSG:32113", label: "32113:NAD83 / New Mexico Central"},
  {coordinate_system:"EPSG:32114", label: "32114:NAD83 / New Mexico West"},
  {coordinate_system:"EPSG:32115", label: "32115:NAD83 / New York East"},
  {coordinate_system:"EPSG:32116", label: "32116:NAD83 / New York Central"},
  {coordinate_system:"EPSG:32117", label: "32117:NAD83 / New York West"},
  {coordinate_system:"EPSG:32118", label: "32118:NAD83 / New York Long Island"},
  {coordinate_system:"EPSG:32119", label: "32119:NAD83 / North Carolina"},
  {coordinate_system:"EPSG:32120", label: "32120:NAD83 / North Dakota North"},
  {coordinate_system:"EPSG:32121", label: "32121:NAD83 / North Dakota South"},
  {coordinate_system:"EPSG:32122", label: "32122:NAD83 / Ohio North"},
  {coordinate_system:"EPSG:32123", label: "32123:NAD83 / Ohio South"},
  {coordinate_system:"EPSG:32124", label: "32124:NAD83 / Oklahoma North"},
  {coordinate_system:"EPSG:32125", label: "32125:NAD83 / Oklahoma South"},
  {coordinate_system:"EPSG:32126", label: "32126:NAD83 / Oregon North"},
  {coordinate_system:"EPSG:32127", label: "32127:NAD83 / Oregon South"},
  {coordinate_system:"EPSG:32128", label: "32128:NAD83 / Pennsylvania North"},
  {coordinate_system:"EPSG:32129", label: "32129:NAD83 / Pennsylvania South"},
  {coordinate_system:"EPSG:32130", label: "32130:NAD83 / Rhode Island"},
  {coordinate_system:"EPSG:32133", label: "32133:NAD83 / South Carolina"},
  {coordinate_system:"EPSG:32134", label: "32134:NAD83 / South Dakota North"},
  {coordinate_system:"EPSG:32135", label: "32135:NAD83 / South Dakota South"},
  {coordinate_system:"EPSG:32136", label: "32136:NAD83 / Tennessee"},
  {coordinate_system:"EPSG:32137", label: "32137:NAD83 / Texas North"},
  {coordinate_system:"EPSG:32138", label: "32138:NAD83 / Texas North Central"},
  {coordinate_system:"EPSG:32139", label: "32139:NAD83 / Texas Central"},
  {coordinate_system:"EPSG:32140", label: "32140:NAD83 / Texas South Central"},
  {coordinate_system:"EPSG:32141", label: "32141:NAD83 / Texas South"},
  {coordinate_system:"EPSG:32142", label: "32142:NAD83 / Utah North"},
  {coordinate_system:"EPSG:32143", label: "32143:NAD83 / Utah Central"},
  {coordinate_system:"EPSG:32144", label: "32144:NAD83 / Utah South"},
  {coordinate_system:"EPSG:32145", label: "32145:NAD83 / Vermont"},
  {coordinate_system:"EPSG:32146", label: "32146:NAD83 / Virginia North"},
  {coordinate_system:"EPSG:32147", label: "32147:NAD83 / Virginia South"},
  {coordinate_system:"EPSG:32148", label: "32148:NAD83 / Washington North"},
  {coordinate_system:"EPSG:32149", label: "32149:NAD83 / Washington South"},
  {coordinate_system:"EPSG:32150", label: "32150:NAD83 / West Virginia North"},
  {coordinate_system:"EPSG:32151", label: "32151:NAD83 / West Virginia South"},
  {coordinate_system:"EPSG:32152", label: "32152:NAD83 / Wisconsin North"},
  {coordinate_system:"EPSG:32153", label: "32153:NAD83 / Wisconsin Central"},
  {coordinate_system:"EPSG:32154", label: "32154:NAD83 / Wisconsin South"},
  {coordinate_system:"EPSG:32155", label: "32155:NAD83 / Wyoming East"},
  {coordinate_system:"EPSG:32156", label: "32156:NAD83 / Wyoming East Central"},
  {coordinate_system:"EPSG:32157", label: "32157:NAD83 / Wyoming West Central"},
  {coordinate_system:"EPSG:32158", label: "32158:NAD83 / Wyoming West"},
  {coordinate_system:"EPSG:32159", label: "32159:NAD83 / Wyoming Lambert"},
  {coordinate_system:"EPSG:32161", label: "32161:NAD83 / Puerto Rico & Virgin Is."},
  {coordinate_system:"EPSG:32164", label: "32164:NAD83 / BLM 14N (ftUS)"},
  {coordinate_system:"EPSG:32165", label: "32165:NAD83 / BLM 15N (ftUS)"},
  {coordinate_system:"EPSG:32166", label: "32166:NAD83 / BLM 16N (ftUS)"},
  {coordinate_system:"EPSG:32167", label: "32167:NAD83 / BLM 17N (ftUS)"},
  {coordinate_system:"EPSG:32181", label: "32181:NAD83 / MTM zone 1"},
  {coordinate_system:"EPSG:32182", label: "32182:NAD83 / MTM zone 2"},
  {coordinate_system:"EPSG:32183", label: "32183:NAD83 / MTM zone 3"},
  {coordinate_system:"EPSG:32184", label: "32184:NAD83 / MTM zone 4"},
  {coordinate_system:"EPSG:32185", label: "32185:NAD83 / MTM zone 5"},
  {coordinate_system:"EPSG:32186", label: "32186:NAD83 / MTM zone 6"},
  {coordinate_system:"EPSG:32187", label: "32187:NAD83 / MTM zone 7"},
  {coordinate_system:"EPSG:32188", label: "32188:NAD83 / MTM zone 8"},
  {coordinate_system:"EPSG:32189", label: "32189:NAD83 / MTM zone 9"},
  {coordinate_system:"EPSG:32190", label: "32190:NAD83 / MTM zone 10"},
  {coordinate_system:"EPSG:32191", label: "32191:NAD83 / MTM zone 11"},
  {coordinate_system:"EPSG:32192", label: "32192:NAD83 / MTM zone 12"},
  {coordinate_system:"EPSG:32193", label: "32193:NAD83 / MTM zone 13"},
  {coordinate_system:"EPSG:32194", label: "32194:NAD83 / MTM zone 14"},
  {coordinate_system:"EPSG:32195", label: "32195:NAD83 / MTM zone 15"},
  {coordinate_system:"EPSG:32196", label: "32196:NAD83 / MTM zone 16"},
  {coordinate_system:"EPSG:32197", label: "32197:NAD83 / MTM zone 17"},
  {coordinate_system:"EPSG:32198", label: "32198:NAD83 / Quebec Lambert"},
  {coordinate_system:"EPSG:32199", label: "32199:NAD83 / Louisiana Offshore"},
  {coordinate_system:"EPSG:32201", label: "32201:WGS 72 / UTM zone 1N"},
  {coordinate_system:"EPSG:32202", label: "32202:WGS 72 / UTM zone 2N"},
  {coordinate_system:"EPSG:32203", label: "32203:WGS 72 / UTM zone 3N"},
  {coordinate_system:"EPSG:32204", label: "32204:WGS 72 / UTM zone 4N"},
  {coordinate_system:"EPSG:32205", label: "32205:WGS 72 / UTM zone 5N"},
  {coordinate_system:"EPSG:32206", label: "32206:WGS 72 / UTM zone 6N"},
  {coordinate_system:"EPSG:32207", label: "32207:WGS 72 / UTM zone 7N"},
  {coordinate_system:"EPSG:32208", label: "32208:WGS 72 / UTM zone 8N"},
  {coordinate_system:"EPSG:32209", label: "32209:WGS 72 / UTM zone 9N"},
  {coordinate_system:"EPSG:32210", label: "32210:WGS 72 / UTM zone 10N"},
  {coordinate_system:"EPSG:32211", label: "32211:WGS 72 / UTM zone 11N"},
  {coordinate_system:"EPSG:32212", label: "32212:WGS 72 / UTM zone 12N"},
  {coordinate_system:"EPSG:32213", label: "32213:WGS 72 / UTM zone 13N"},
  {coordinate_system:"EPSG:32214", label: "32214:WGS 72 / UTM zone 14N"},
  {coordinate_system:"EPSG:32215", label: "32215:WGS 72 / UTM zone 15N"},
  {coordinate_system:"EPSG:32216", label: "32216:WGS 72 / UTM zone 16N"},
  {coordinate_system:"EPSG:32217", label: "32217:WGS 72 / UTM zone 17N"},
  {coordinate_system:"EPSG:32218", label: "32218:WGS 72 / UTM zone 18N"},
  {coordinate_system:"EPSG:32219", label: "32219:WGS 72 / UTM zone 19N"},
  {coordinate_system:"EPSG:32220", label: "32220:WGS 72 / UTM zone 20N"},
  {coordinate_system:"EPSG:32221", label: "32221:WGS 72 / UTM zone 21N"},
  {coordinate_system:"EPSG:32222", label: "32222:WGS 72 / UTM zone 22N"},
  {coordinate_system:"EPSG:32223", label: "32223:WGS 72 / UTM zone 23N"},
  {coordinate_system:"EPSG:32224", label: "32224:WGS 72 / UTM zone 24N"},
  {coordinate_system:"EPSG:32225", label: "32225:WGS 72 / UTM zone 25N"},
  {coordinate_system:"EPSG:32226", label: "32226:WGS 72 / UTM zone 26N"},
  {coordinate_system:"EPSG:32227", label: "32227:WGS 72 / UTM zone 27N"},
  {coordinate_system:"EPSG:32228", label: "32228:WGS 72 / UTM zone 28N"},
  {coordinate_system:"EPSG:32229", label: "32229:WGS 72 / UTM zone 29N"},
  {coordinate_system:"EPSG:32230", label: "32230:WGS 72 / UTM zone 30N"},
  {coordinate_system:"EPSG:32231", label: "32231:WGS 72 / UTM zone 31N"},
  {coordinate_system:"EPSG:32232", label: "32232:WGS 72 / UTM zone 32N"},
  {coordinate_system:"EPSG:32233", label: "32233:WGS 72 / UTM zone 33N"},
  {coordinate_system:"EPSG:32234", label: "32234:WGS 72 / UTM zone 34N"},
  {coordinate_system:"EPSG:32235", label: "32235:WGS 72 / UTM zone 35N"},
  {coordinate_system:"EPSG:32236", label: "32236:WGS 72 / UTM zone 36N"},
  {coordinate_system:"EPSG:32237", label: "32237:WGS 72 / UTM zone 37N"},
  {coordinate_system:"EPSG:32238", label: "32238:WGS 72 / UTM zone 38N"},
  {coordinate_system:"EPSG:32239", label: "32239:WGS 72 / UTM zone 39N"},
  {coordinate_system:"EPSG:32240", label: "32240:WGS 72 / UTM zone 40N"},
  {coordinate_system:"EPSG:32241", label: "32241:WGS 72 / UTM zone 41N"},
  {coordinate_system:"EPSG:32242", label: "32242:WGS 72 / UTM zone 42N"},
  {coordinate_system:"EPSG:32243", label: "32243:WGS 72 / UTM zone 43N"},
  {coordinate_system:"EPSG:32244", label: "32244:WGS 72 / UTM zone 44N"},
  {coordinate_system:"EPSG:32245", label: "32245:WGS 72 / UTM zone 45N"},
  {coordinate_system:"EPSG:32246", label: "32246:WGS 72 / UTM zone 46N"},
  {coordinate_system:"EPSG:32247", label: "32247:WGS 72 / UTM zone 47N"},
  {coordinate_system:"EPSG:32248", label: "32248:WGS 72 / UTM zone 48N"},
  {coordinate_system:"EPSG:32249", label: "32249:WGS 72 / UTM zone 49N"},
  {coordinate_system:"EPSG:32250", label: "32250:WGS 72 / UTM zone 50N"},
  {coordinate_system:"EPSG:32251", label: "32251:WGS 72 / UTM zone 51N"},
  {coordinate_system:"EPSG:32252", label: "32252:WGS 72 / UTM zone 52N"},
  {coordinate_system:"EPSG:32253", label: "32253:WGS 72 / UTM zone 53N"},
  {coordinate_system:"EPSG:32254", label: "32254:WGS 72 / UTM zone 54N"},
  {coordinate_system:"EPSG:32255", label: "32255:WGS 72 / UTM zone 55N"},
  {coordinate_system:"EPSG:32256", label: "32256:WGS 72 / UTM zone 56N"},
  {coordinate_system:"EPSG:32257", label: "32257:WGS 72 / UTM zone 57N"},
  {coordinate_system:"EPSG:32258", label: "32258:WGS 72 / UTM zone 58N"},
  {coordinate_system:"EPSG:32259", label: "32259:WGS 72 / UTM zone 59N"},
  {coordinate_system:"EPSG:32260", label: "32260:WGS 72 / UTM zone 60N"},
  {coordinate_system:"EPSG:32301", label: "32301:WGS 72 / UTM zone 1S"},
  {coordinate_system:"EPSG:32302", label: "32302:WGS 72 / UTM zone 2S"},
  {coordinate_system:"EPSG:32303", label: "32303:WGS 72 / UTM zone 3S"},
  {coordinate_system:"EPSG:32304", label: "32304:WGS 72 / UTM zone 4S"},
  {coordinate_system:"EPSG:32305", label: "32305:WGS 72 / UTM zone 5S"},
  {coordinate_system:"EPSG:32306", label: "32306:WGS 72 / UTM zone 6S"},
  {coordinate_system:"EPSG:32307", label: "32307:WGS 72 / UTM zone 7S"},
  {coordinate_system:"EPSG:32308", label: "32308:WGS 72 / UTM zone 8S"},
  {coordinate_system:"EPSG:32309", label: "32309:WGS 72 / UTM zone 9S"},
  {coordinate_system:"EPSG:32310", label: "32310:WGS 72 / UTM zone 10S"},
  {coordinate_system:"EPSG:32311", label: "32311:WGS 72 / UTM zone 11S"},
  {coordinate_system:"EPSG:32312", label: "32312:WGS 72 / UTM zone 12S"},
  {coordinate_system:"EPSG:32313", label: "32313:WGS 72 / UTM zone 13S"},
  {coordinate_system:"EPSG:32314", label: "32314:WGS 72 / UTM zone 14S"},
  {coordinate_system:"EPSG:32315", label: "32315:WGS 72 / UTM zone 15S"},
  {coordinate_system:"EPSG:32316", label: "32316:WGS 72 / UTM zone 16S"},
  {coordinate_system:"EPSG:32317", label: "32317:WGS 72 / UTM zone 17S"},
  {coordinate_system:"EPSG:32318", label: "32318:WGS 72 / UTM zone 18S"},
  {coordinate_system:"EPSG:32319", label: "32319:WGS 72 / UTM zone 19S"},
  {coordinate_system:"EPSG:32320", label: "32320:WGS 72 / UTM zone 20S"},
  {coordinate_system:"EPSG:32321", label: "32321:WGS 72 / UTM zone 21S"},
  {coordinate_system:"EPSG:32322", label: "32322:WGS 72 / UTM zone 22S"},
  {coordinate_system:"EPSG:32323", label: "32323:WGS 72 / UTM zone 23S"},
  {coordinate_system:"EPSG:32324", label: "32324:WGS 72 / UTM zone 24S"},
  {coordinate_system:"EPSG:32325", label: "32325:WGS 72 / UTM zone 25S"},
  {coordinate_system:"EPSG:32326", label: "32326:WGS 72 / UTM zone 26S"},
  {coordinate_system:"EPSG:32327", label: "32327:WGS 72 / UTM zone 27S"},
  {coordinate_system:"EPSG:32328", label: "32328:WGS 72 / UTM zone 28S"},
  {coordinate_system:"EPSG:32329", label: "32329:WGS 72 / UTM zone 29S"},
  {coordinate_system:"EPSG:32330", label: "32330:WGS 72 / UTM zone 30S"},
  {coordinate_system:"EPSG:32331", label: "32331:WGS 72 / UTM zone 31S"},
  {coordinate_system:"EPSG:32332", label: "32332:WGS 72 / UTM zone 32S"},
  {coordinate_system:"EPSG:32333", label: "32333:WGS 72 / UTM zone 33S"},
  {coordinate_system:"EPSG:32334", label: "32334:WGS 72 / UTM zone 34S"},
  {coordinate_system:"EPSG:32335", label: "32335:WGS 72 / UTM zone 35S"},
  {coordinate_system:"EPSG:32336", label: "32336:WGS 72 / UTM zone 36S"},
  {coordinate_system:"EPSG:32337", label: "32337:WGS 72 / UTM zone 37S"},
  {coordinate_system:"EPSG:32338", label: "32338:WGS 72 / UTM zone 38S"},
  {coordinate_system:"EPSG:32339", label: "32339:WGS 72 / UTM zone 39S"},
  {coordinate_system:"EPSG:32340", label: "32340:WGS 72 / UTM zone 40S"},
  {coordinate_system:"EPSG:32341", label: "32341:WGS 72 / UTM zone 41S"},
  {coordinate_system:"EPSG:32342", label: "32342:WGS 72 / UTM zone 42S"},
  {coordinate_system:"EPSG:32343", label: "32343:WGS 72 / UTM zone 43S"},
  {coordinate_system:"EPSG:32344", label: "32344:WGS 72 / UTM zone 44S"},
  {coordinate_system:"EPSG:32345", label: "32345:WGS 72 / UTM zone 45S"},
  {coordinate_system:"EPSG:32346", label: "32346:WGS 72 / UTM zone 46S"},
  {coordinate_system:"EPSG:32347", label: "32347:WGS 72 / UTM zone 47S"},
  {coordinate_system:"EPSG:32348", label: "32348:WGS 72 / UTM zone 48S"},
  {coordinate_system:"EPSG:32349", label: "32349:WGS 72 / UTM zone 49S"},
  {coordinate_system:"EPSG:32350", label: "32350:WGS 72 / UTM zone 50S"},
  {coordinate_system:"EPSG:32351", label: "32351:WGS 72 / UTM zone 51S"},
  {coordinate_system:"EPSG:32352", label: "32352:WGS 72 / UTM zone 52S"},
  {coordinate_system:"EPSG:32353", label: "32353:WGS 72 / UTM zone 53S"},
  {coordinate_system:"EPSG:32354", label: "32354:WGS 72 / UTM zone 54S"},
  {coordinate_system:"EPSG:32355", label: "32355:WGS 72 / UTM zone 55S"},
  {coordinate_system:"EPSG:32356", label: "32356:WGS 72 / UTM zone 56S"},
  {coordinate_system:"EPSG:32357", label: "32357:WGS 72 / UTM zone 57S"},
  {coordinate_system:"EPSG:32358", label: "32358:WGS 72 / UTM zone 58S"},
  {coordinate_system:"EPSG:32359", label: "32359:WGS 72 / UTM zone 59S"},
  {coordinate_system:"EPSG:32360", label: "32360:WGS 72 / UTM zone 60S"},
  {coordinate_system:"EPSG:32401", label: "32401:WGS 72BE / UTM zone 1N"},
  {coordinate_system:"EPSG:32402", label: "32402:WGS 72BE / UTM zone 2N"},
  {coordinate_system:"EPSG:32403", label: "32403:WGS 72BE / UTM zone 3N"},
  {coordinate_system:"EPSG:32404", label: "32404:WGS 72BE / UTM zone 4N"},
  {coordinate_system:"EPSG:32405", label: "32405:WGS 72BE / UTM zone 5N"},
  {coordinate_system:"EPSG:32406", label: "32406:WGS 72BE / UTM zone 6N"},
  {coordinate_system:"EPSG:32407", label: "32407:WGS 72BE / UTM zone 7N"},
  {coordinate_system:"EPSG:32408", label: "32408:WGS 72BE / UTM zone 8N"},
  {coordinate_system:"EPSG:32409", label: "32409:WGS 72BE / UTM zone 9N"},
  {coordinate_system:"EPSG:32410", label: "32410:WGS 72BE / UTM zone 10N"},
  {coordinate_system:"EPSG:32411", label: "32411:WGS 72BE / UTM zone 11N"},
  {coordinate_system:"EPSG:32412", label: "32412:WGS 72BE / UTM zone 12N"},
  {coordinate_system:"EPSG:32413", label: "32413:WGS 72BE / UTM zone 13N"},
  {coordinate_system:"EPSG:32414", label: "32414:WGS 72BE / UTM zone 14N"},
  {coordinate_system:"EPSG:32415", label: "32415:WGS 72BE / UTM zone 15N"},
  {coordinate_system:"EPSG:32416", label: "32416:WGS 72BE / UTM zone 16N"},
  {coordinate_system:"EPSG:32417", label: "32417:WGS 72BE / UTM zone 17N"},
  {coordinate_system:"EPSG:32418", label: "32418:WGS 72BE / UTM zone 18N"},
  {coordinate_system:"EPSG:32419", label: "32419:WGS 72BE / UTM zone 19N"},
  {coordinate_system:"EPSG:32420", label: "32420:WGS 72BE / UTM zone 20N"},
  {coordinate_system:"EPSG:32421", label: "32421:WGS 72BE / UTM zone 21N"},
  {coordinate_system:"EPSG:32422", label: "32422:WGS 72BE / UTM zone 22N"},
  {coordinate_system:"EPSG:32423", label: "32423:WGS 72BE / UTM zone 23N"},
  {coordinate_system:"EPSG:32424", label: "32424:WGS 72BE / UTM zone 24N"},
  {coordinate_system:"EPSG:32425", label: "32425:WGS 72BE / UTM zone 25N"},
  {coordinate_system:"EPSG:32426", label: "32426:WGS 72BE / UTM zone 26N"},
  {coordinate_system:"EPSG:32427", label: "32427:WGS 72BE / UTM zone 27N"},
  {coordinate_system:"EPSG:32428", label: "32428:WGS 72BE / UTM zone 28N"},
  {coordinate_system:"EPSG:32429", label: "32429:WGS 72BE / UTM zone 29N"},
  {coordinate_system:"EPSG:32430", label: "32430:WGS 72BE / UTM zone 30N"},
  {coordinate_system:"EPSG:32431", label: "32431:WGS 72BE / UTM zone 31N"},
  {coordinate_system:"EPSG:32432", label: "32432:WGS 72BE / UTM zone 32N"},
  {coordinate_system:"EPSG:32433", label: "32433:WGS 72BE / UTM zone 33N"},
  {coordinate_system:"EPSG:32434", label: "32434:WGS 72BE / UTM zone 34N"},
  {coordinate_system:"EPSG:32435", label: "32435:WGS 72BE / UTM zone 35N"},
  {coordinate_system:"EPSG:32436", label: "32436:WGS 72BE / UTM zone 36N"},
  {coordinate_system:"EPSG:32437", label: "32437:WGS 72BE / UTM zone 37N"},
  {coordinate_system:"EPSG:32438", label: "32438:WGS 72BE / UTM zone 38N"},
  {coordinate_system:"EPSG:32439", label: "32439:WGS 72BE / UTM zone 39N"},
  {coordinate_system:"EPSG:32440", label: "32440:WGS 72BE / UTM zone 40N"},
  {coordinate_system:"EPSG:32441", label: "32441:WGS 72BE / UTM zone 41N"},
  {coordinate_system:"EPSG:32442", label: "32442:WGS 72BE / UTM zone 42N"},
  {coordinate_system:"EPSG:32443", label: "32443:WGS 72BE / UTM zone 43N"},
  {coordinate_system:"EPSG:32444", label: "32444:WGS 72BE / UTM zone 44N"},
  {coordinate_system:"EPSG:32445", label: "32445:WGS 72BE / UTM zone 45N"},
  {coordinate_system:"EPSG:32446", label: "32446:WGS 72BE / UTM zone 46N"},
  {coordinate_system:"EPSG:32447", label: "32447:WGS 72BE / UTM zone 47N"},
  {coordinate_system:"EPSG:32448", label: "32448:WGS 72BE / UTM zone 48N"},
  {coordinate_system:"EPSG:32449", label: "32449:WGS 72BE / UTM zone 49N"},
  {coordinate_system:"EPSG:32450", label: "32450:WGS 72BE / UTM zone 50N"},
  {coordinate_system:"EPSG:32451", label: "32451:WGS 72BE / UTM zone 51N"},
  {coordinate_system:"EPSG:32452", label: "32452:WGS 72BE / UTM zone 52N"},
  {coordinate_system:"EPSG:32453", label: "32453:WGS 72BE / UTM zone 53N"},
  {coordinate_system:"EPSG:32454", label: "32454:WGS 72BE / UTM zone 54N"},
  {coordinate_system:"EPSG:32455", label: "32455:WGS 72BE / UTM zone 55N"},
  {coordinate_system:"EPSG:32456", label: "32456:WGS 72BE / UTM zone 56N"},
  {coordinate_system:"EPSG:32457", label: "32457:WGS 72BE / UTM zone 57N"},
  {coordinate_system:"EPSG:32458", label: "32458:WGS 72BE / UTM zone 58N"},
  {coordinate_system:"EPSG:32459", label: "32459:WGS 72BE / UTM zone 59N"},
  {coordinate_system:"EPSG:32460", label: "32460:WGS 72BE / UTM zone 60N"},
  {coordinate_system:"EPSG:32501", label: "32501:WGS 72BE / UTM zone 1S"},
  {coordinate_system:"EPSG:32502", label: "32502:WGS 72BE / UTM zone 2S"},
  {coordinate_system:"EPSG:32503", label: "32503:WGS 72BE / UTM zone 3S"},
  {coordinate_system:"EPSG:32504", label: "32504:WGS 72BE / UTM zone 4S"},
  {coordinate_system:"EPSG:32505", label: "32505:WGS 72BE / UTM zone 5S"},
  {coordinate_system:"EPSG:32506", label: "32506:WGS 72BE / UTM zone 6S"},
  {coordinate_system:"EPSG:32507", label: "32507:WGS 72BE / UTM zone 7S"},
  {coordinate_system:"EPSG:32508", label: "32508:WGS 72BE / UTM zone 8S"},
  {coordinate_system:"EPSG:32509", label: "32509:WGS 72BE / UTM zone 9S"},
  {coordinate_system:"EPSG:32510", label: "32510:WGS 72BE / UTM zone 10S"},
  {coordinate_system:"EPSG:32511", label: "32511:WGS 72BE / UTM zone 11S"},
  {coordinate_system:"EPSG:32512", label: "32512:WGS 72BE / UTM zone 12S"},
  {coordinate_system:"EPSG:32513", label: "32513:WGS 72BE / UTM zone 13S"},
  {coordinate_system:"EPSG:32514", label: "32514:WGS 72BE / UTM zone 14S"},
  {coordinate_system:"EPSG:32515", label: "32515:WGS 72BE / UTM zone 15S"},
  {coordinate_system:"EPSG:32516", label: "32516:WGS 72BE / UTM zone 16S"},
  {coordinate_system:"EPSG:32517", label: "32517:WGS 72BE / UTM zone 17S"},
  {coordinate_system:"EPSG:32518", label: "32518:WGS 72BE / UTM zone 18S"},
  {coordinate_system:"EPSG:32519", label: "32519:WGS 72BE / UTM zone 19S"},
  {coordinate_system:"EPSG:32520", label: "32520:WGS 72BE / UTM zone 20S"},
  {coordinate_system:"EPSG:32521", label: "32521:WGS 72BE / UTM zone 21S"},
  {coordinate_system:"EPSG:32522", label: "32522:WGS 72BE / UTM zone 22S"},
  {coordinate_system:"EPSG:32523", label: "32523:WGS 72BE / UTM zone 23S"},
  {coordinate_system:"EPSG:32524", label: "32524:WGS 72BE / UTM zone 24S"},
  {coordinate_system:"EPSG:32525", label: "32525:WGS 72BE / UTM zone 25S"},
  {coordinate_system:"EPSG:32526", label: "32526:WGS 72BE / UTM zone 26S"},
  {coordinate_system:"EPSG:32527", label: "32527:WGS 72BE / UTM zone 27S"},
  {coordinate_system:"EPSG:32528", label: "32528:WGS 72BE / UTM zone 28S"},
  {coordinate_system:"EPSG:32529", label: "32529:WGS 72BE / UTM zone 29S"},
  {coordinate_system:"EPSG:32530", label: "32530:WGS 72BE / UTM zone 30S"},
  {coordinate_system:"EPSG:32531", label: "32531:WGS 72BE / UTM zone 31S"},
  {coordinate_system:"EPSG:32532", label: "32532:WGS 72BE / UTM zone 32S"},
  {coordinate_system:"EPSG:32533", label: "32533:WGS 72BE / UTM zone 33S"},
  {coordinate_system:"EPSG:32534", label: "32534:WGS 72BE / UTM zone 34S"},
  {coordinate_system:"EPSG:32535", label: "32535:WGS 72BE / UTM zone 35S"},
  {coordinate_system:"EPSG:32536", label: "32536:WGS 72BE / UTM zone 36S"},
  {coordinate_system:"EPSG:32537", label: "32537:WGS 72BE / UTM zone 37S"},
  {coordinate_system:"EPSG:32538", label: "32538:WGS 72BE / UTM zone 38S"},
  {coordinate_system:"EPSG:32539", label: "32539:WGS 72BE / UTM zone 39S"},
  {coordinate_system:"EPSG:32540", label: "32540:WGS 72BE / UTM zone 40S"},
  {coordinate_system:"EPSG:32541", label: "32541:WGS 72BE / UTM zone 41S"},
  {coordinate_system:"EPSG:32542", label: "32542:WGS 72BE / UTM zone 42S"},
  {coordinate_system:"EPSG:32543", label: "32543:WGS 72BE / UTM zone 43S"},
  {coordinate_system:"EPSG:32544", label: "32544:WGS 72BE / UTM zone 44S"},
  {coordinate_system:"EPSG:32545", label: "32545:WGS 72BE / UTM zone 45S"},
  {coordinate_system:"EPSG:32546", label: "32546:WGS 72BE / UTM zone 46S"},
  {coordinate_system:"EPSG:32547", label: "32547:WGS 72BE / UTM zone 47S"},
  {coordinate_system:"EPSG:32548", label: "32548:WGS 72BE / UTM zone 48S"},
  {coordinate_system:"EPSG:32549", label: "32549:WGS 72BE / UTM zone 49S"},
  {coordinate_system:"EPSG:32550", label: "32550:WGS 72BE / UTM zone 50S"},
  {coordinate_system:"EPSG:32551", label: "32551:WGS 72BE / UTM zone 51S"},
  {coordinate_system:"EPSG:32552", label: "32552:WGS 72BE / UTM zone 52S"},
  {coordinate_system:"EPSG:32553", label: "32553:WGS 72BE / UTM zone 53S"},
  {coordinate_system:"EPSG:32554", label: "32554:WGS 72BE / UTM zone 54S"},
  {coordinate_system:"EPSG:32555", label: "32555:WGS 72BE / UTM zone 55S"},
  {coordinate_system:"EPSG:32556", label: "32556:WGS 72BE / UTM zone 56S"},
  {coordinate_system:"EPSG:32557", label: "32557:WGS 72BE / UTM zone 57S"},
  {coordinate_system:"EPSG:32558", label: "32558:WGS 72BE / UTM zone 58S"},
  {coordinate_system:"EPSG:32559", label: "32559:WGS 72BE / UTM zone 59S"},
  {coordinate_system:"EPSG:32560", label: "32560:WGS 72BE / UTM zone 60S"},
  {coordinate_system:"EPSG:32600", label: "32600:WGS 84 / UTM grid system (northern hemisphere)"},
  {coordinate_system:"EPSG:32601", label: "32601:WGS 84 / UTM zone 1N"},
  {coordinate_system:"EPSG:32602", label: "32602:WGS 84 / UTM zone 2N"},
  {coordinate_system:"EPSG:32603", label: "32603:WGS 84 / UTM zone 3N"},
  {coordinate_system:"EPSG:32604", label: "32604:WGS 84 / UTM zone 4N"},
  {coordinate_system:"EPSG:32605", label: "32605:WGS 84 / UTM zone 5N"},
  {coordinate_system:"EPSG:32606", label: "32606:WGS 84 / UTM zone 6N"},
  {coordinate_system:"EPSG:32607", label: "32607:WGS 84 / UTM zone 7N"},
  {coordinate_system:"EPSG:32608", label: "32608:WGS 84 / UTM zone 8N"},
  {coordinate_system:"EPSG:32609", label: "32609:WGS 84 / UTM zone 9N"},
  {coordinate_system:"EPSG:32610", label: "32610:WGS 84 / UTM zone 10N"},
  {coordinate_system:"EPSG:32611", label: "32611:WGS 84 / UTM zone 11N"},
  {coordinate_system:"EPSG:32612", label: "32612:WGS 84 / UTM zone 12N"},
  {coordinate_system:"EPSG:32613", label: "32613:WGS 84 / UTM zone 13N"},
  {coordinate_system:"EPSG:32614", label: "32614:WGS 84 / UTM zone 14N"},
  {coordinate_system:"EPSG:32615", label: "32615:WGS 84 / UTM zone 15N"},
  {coordinate_system:"EPSG:32616", label: "32616:WGS 84 / UTM zone 16N"},
  {coordinate_system:"EPSG:32617", label: "32617:WGS 84 / UTM zone 17N"},
  {coordinate_system:"EPSG:32618", label: "32618:WGS 84 / UTM zone 18N"},
  {coordinate_system:"EPSG:32619", label: "32619:WGS 84 / UTM zone 19N"},
  {coordinate_system:"EPSG:32620", label: "32620:WGS 84 / UTM zone 20N"},
  {coordinate_system:"EPSG:32621", label: "32621:WGS 84 / UTM zone 21N"},
  {coordinate_system:"EPSG:32622", label: "32622:WGS 84 / UTM zone 22N"},
  {coordinate_system:"EPSG:32623", label: "32623:WGS 84 / UTM zone 23N"},
  {coordinate_system:"EPSG:32624", label: "32624:WGS 84 / UTM zone 24N"},
  {coordinate_system:"EPSG:32625", label: "32625:WGS 84 / UTM zone 25N"},
  {coordinate_system:"EPSG:32626", label: "32626:WGS 84 / UTM zone 26N"},
  {coordinate_system:"EPSG:32627", label: "32627:WGS 84 / UTM zone 27N"},
  {coordinate_system:"EPSG:32628", label: "32628:WGS 84 / UTM zone 28N"},
  {coordinate_system:"EPSG:32629", label: "32629:WGS 84 / UTM zone 29N"},
  {coordinate_system:"EPSG:32630", label: "32630:WGS 84 / UTM zone 30N"},
  {coordinate_system:"EPSG:32631", label: "32631:WGS 84 / UTM zone 31N"},
  {coordinate_system:"EPSG:32632", label: "32632:WGS 84 / UTM zone 32N"},
  {coordinate_system:"EPSG:32633", label: "32633:WGS 84 / UTM zone 33N"},
  {coordinate_system:"EPSG:32634", label: "32634:WGS 84 / UTM zone 34N"},
  {coordinate_system:"EPSG:32635", label: "32635:WGS 84 / UTM zone 35N"},
  {coordinate_system:"EPSG:32636", label: "32636:WGS 84 / UTM zone 36N"},
  {coordinate_system:"EPSG:32637", label: "32637:WGS 84 / UTM zone 37N"},
  {coordinate_system:"EPSG:32638", label: "32638:WGS 84 / UTM zone 38N"},
  {coordinate_system:"EPSG:32639", label: "32639:WGS 84 / UTM zone 39N"},
  {coordinate_system:"EPSG:32640", label: "32640:WGS 84 / UTM zone 40N"},
  {coordinate_system:"EPSG:32641", label: "32641:WGS 84 / UTM zone 41N"},
  {coordinate_system:"EPSG:32642", label: "32642:WGS 84 / UTM zone 42N"},
  {coordinate_system:"EPSG:32643", label: "32643:WGS 84 / UTM zone 43N"},
  {coordinate_system:"EPSG:32644", label: "32644:WGS 84 / UTM zone 44N"},
  {coordinate_system:"EPSG:32645", label: "32645:WGS 84 / UTM zone 45N"},
  {coordinate_system:"EPSG:32646", label: "32646:WGS 84 / UTM zone 46N"},
  {coordinate_system:"EPSG:32647", label: "32647:WGS 84 / UTM zone 47N"},
  {coordinate_system:"EPSG:32648", label: "32648:WGS 84 / UTM zone 48N"},
  {coordinate_system:"EPSG:32649", label: "32649:WGS 84 / UTM zone 49N"},
  {coordinate_system:"EPSG:32650", label: "32650:WGS 84 / UTM zone 50N"},
  {coordinate_system:"EPSG:32651", label: "32651:WGS 84 / UTM zone 51N"},
  {coordinate_system:"EPSG:32652", label: "32652:WGS 84 / UTM zone 52N"},
  {coordinate_system:"EPSG:32653", label: "32653:WGS 84 / UTM zone 53N"},
  {coordinate_system:"EPSG:32654", label: "32654:WGS 84 / UTM zone 54N"},
  {coordinate_system:"EPSG:32655", label: "32655:WGS 84 / UTM zone 55N"},
  {coordinate_system:"EPSG:32656", label: "32656:WGS 84 / UTM zone 56N"},
  {coordinate_system:"EPSG:32657", label: "32657:WGS 84 / UTM zone 57N"},
  {coordinate_system:"EPSG:32658", label: "32658:WGS 84 / UTM zone 58N"},
  {coordinate_system:"EPSG:32659", label: "32659:WGS 84 / UTM zone 59N"},
  {coordinate_system:"EPSG:32660", label: "32660:WGS 84 / UTM zone 60N"},
  {coordinate_system:"EPSG:32661", label: "32661:WGS 84 / UPS North (N,E)"},
  {coordinate_system:"EPSG:32664", label: "32664:WGS 84 / BLM 14N (ftUS)"},
  {coordinate_system:"EPSG:32665", label: "32665:WGS 84 / BLM 15N (ftUS)"},
  {coordinate_system:"EPSG:32666", label: "32666:WGS 84 / BLM 16N (ftUS)"},
  {coordinate_system:"EPSG:32667", label: "32667:WGS 84 / BLM 17N (ftUS)"},
  {coordinate_system:"EPSG:32700", label: "32700:WGS 84 / UTM grid system (southern hemisphere)"},
  {coordinate_system:"EPSG:32701", label: "32701:WGS 84 / UTM zone 1S"},
  {coordinate_system:"EPSG:32702", label: "32702:WGS 84 / UTM zone 2S"},
  {coordinate_system:"EPSG:32703", label: "32703:WGS 84 / UTM zone 3S"},
  {coordinate_system:"EPSG:32704", label: "32704:WGS 84 / UTM zone 4S"},
  {coordinate_system:"EPSG:32705", label: "32705:WGS 84 / UTM zone 5S"},
  {coordinate_system:"EPSG:32706", label: "32706:WGS 84 / UTM zone 6S"},
  {coordinate_system:"EPSG:32707", label: "32707:WGS 84 / UTM zone 7S"},
  {coordinate_system:"EPSG:32708", label: "32708:WGS 84 / UTM zone 8S"},
  {coordinate_system:"EPSG:32709", label: "32709:WGS 84 / UTM zone 9S"},
  {coordinate_system:"EPSG:32710", label: "32710:WGS 84 / UTM zone 10S"},
  {coordinate_system:"EPSG:32711", label: "32711:WGS 84 / UTM zone 11S"},
  {coordinate_system:"EPSG:32712", label: "32712:WGS 84 / UTM zone 12S"},
  {coordinate_system:"EPSG:32713", label: "32713:WGS 84 / UTM zone 13S"},
  {coordinate_system:"EPSG:32714", label: "32714:WGS 84 / UTM zone 14S"},
  {coordinate_system:"EPSG:32715", label: "32715:WGS 84 / UTM zone 15S"},
  {coordinate_system:"EPSG:32716", label: "32716:WGS 84 / UTM zone 16S"},
  {coordinate_system:"EPSG:32717", label: "32717:WGS 84 / UTM zone 17S"},
  {coordinate_system:"EPSG:32718", label: "32718:WGS 84 / UTM zone 18S"},
  {coordinate_system:"EPSG:32719", label: "32719:WGS 84 / UTM zone 19S"},
  {coordinate_system:"EPSG:32720", label: "32720:WGS 84 / UTM zone 20S"},
  {coordinate_system:"EPSG:32721", label: "32721:WGS 84 / UTM zone 21S"},
  {coordinate_system:"EPSG:32722", label: "32722:WGS 84 / UTM zone 22S"},
  {coordinate_system:"EPSG:32723", label: "32723:WGS 84 / UTM zone 23S"},
  {coordinate_system:"EPSG:32724", label: "32724:WGS 84 / UTM zone 24S"},
  {coordinate_system:"EPSG:32725", label: "32725:WGS 84 / UTM zone 25S"},
  {coordinate_system:"EPSG:32726", label: "32726:WGS 84 / UTM zone 26S"},
  {coordinate_system:"EPSG:32727", label: "32727:WGS 84 / UTM zone 27S"},
  {coordinate_system:"EPSG:32728", label: "32728:WGS 84 / UTM zone 28S"},
  {coordinate_system:"EPSG:32729", label: "32729:WGS 84 / UTM zone 29S"},
  {coordinate_system:"EPSG:32730", label: "32730:WGS 84 / UTM zone 30S"},
  {coordinate_system:"EPSG:32731", label: "32731:WGS 84 / UTM zone 31S"},
  {coordinate_system:"EPSG:32732", label: "32732:WGS 84 / UTM zone 32S"},
  {coordinate_system:"EPSG:32733", label: "32733:WGS 84 / UTM zone 33S"},
  {coordinate_system:"EPSG:32734", label: "32734:WGS 84 / UTM zone 34S"},
  {coordinate_system:"EPSG:32735", label: "32735:WGS 84 / UTM zone 35S"},
  {coordinate_system:"EPSG:32736", label: "32736:WGS 84 / UTM zone 36S"},
  {coordinate_system:"EPSG:32737", label: "32737:WGS 84 / UTM zone 37S"},
  {coordinate_system:"EPSG:32738", label: "32738:WGS 84 / UTM zone 38S"},
  {coordinate_system:"EPSG:32739", label: "32739:WGS 84 / UTM zone 39S"},
  {coordinate_system:"EPSG:32740", label: "32740:WGS 84 / UTM zone 40S"},
  {coordinate_system:"EPSG:32741", label: "32741:WGS 84 / UTM zone 41S"},
  {coordinate_system:"EPSG:32742", label: "32742:WGS 84 / UTM zone 42S"},
  {coordinate_system:"EPSG:32743", label: "32743:WGS 84 / UTM zone 43S"},
  {coordinate_system:"EPSG:32744", label: "32744:WGS 84 / UTM zone 44S"},
  {coordinate_system:"EPSG:32745", label: "32745:WGS 84 / UTM zone 45S"},
  {coordinate_system:"EPSG:32746", label: "32746:WGS 84 / UTM zone 46S"},
  {coordinate_system:"EPSG:32747", label: "32747:WGS 84 / UTM zone 47S"},
  {coordinate_system:"EPSG:32748", label: "32748:WGS 84 / UTM zone 48S"},
  {coordinate_system:"EPSG:32749", label: "32749:WGS 84 / UTM zone 49S"},
  {coordinate_system:"EPSG:32750", label: "32750:WGS 84 / UTM zone 50S"},
  {coordinate_system:"EPSG:32751", label: "32751:WGS 84 / UTM zone 51S"},
  {coordinate_system:"EPSG:32752", label: "32752:WGS 84 / UTM zone 52S"},
  {coordinate_system:"EPSG:32753", label: "32753:WGS 84 / UTM zone 53S"},
  {coordinate_system:"EPSG:32754", label: "32754:WGS 84 / UTM zone 54S"},
  {coordinate_system:"EPSG:32755", label: "32755:WGS 84 / UTM zone 55S"},
  {coordinate_system:"EPSG:32756", label: "32756:WGS 84 / UTM zone 56S"},
  {coordinate_system:"EPSG:32757", label: "32757:WGS 84 / UTM zone 57S"},
  {coordinate_system:"EPSG:32758", label: "32758:WGS 84 / UTM zone 58S"},
  {coordinate_system:"EPSG:32759", label: "32759:WGS 84 / UTM zone 59S"},
  {coordinate_system:"EPSG:32760", label: "32760:WGS 84 / UTM zone 60S"},
  {coordinate_system:"EPSG:32761", label: "32761:WGS 84 / UPS South (N,E)"},
  {coordinate_system:"EPSG:32766", label: "32766:WGS 84 / TM 36 SE"},
  {coordinate_system:"ESRI:9709", label: "9709:NAD_1983_CSRS_UTM_Zone_23N"},
  {coordinate_system:"ESRI:9712", label: "9712:NAD_1983_UTM_Zone_24N"},
  {coordinate_system:"ESRI:9713", label: "9713:NAD_1983_CSRS_UTM_Zone_24N"},
  {coordinate_system:"ESRI:9716", label: "9716:IGM95_UTM_Zone_34N"},
  {coordinate_system:"ESRI:9741", label: "9741:EOS21_Grid"},
  {coordinate_system:"ESRI:9748", label: "9748:NAD_1983_2011_StatePlane_Alabama_East_(ftUS)"},
  {coordinate_system:"ESRI:9749", label: "9749:NAD_1983_2011_StatePlane_Alabama_West_(ftUS)"},
  {coordinate_system:"ESRI:9761", label: "9761:ECML14_NB_Grid"},
  {coordinate_system:"ESRI:9766", label: "9766:EWR2_Grid"},
  {coordinate_system:"ESRI:20002", label: "20002:Pulkovo_1995_GK_Zone_2"},
  {coordinate_system:"ESRI:20003", label: "20003:Pulkovo_1995_GK_Zone_3"},
  {coordinate_system:"ESRI:20062", label: "20062:Pulkovo_1995_GK_Zone_2N"},
  {coordinate_system:"ESRI:20063", label: "20063:Pulkovo_1995_GK_Zone_3N"},
  {coordinate_system:"ESRI:20904", label: "20904:GSK-2011_Gauss-Kruger_zone_4"},
  {coordinate_system:"ESRI:20905", label: "20905:GSK-2011_Gauss-Kruger_zone_5"},
  {coordinate_system:"ESRI:20906", label: "20906:GSK-2011_Gauss-Kruger_zone_6"},
  {coordinate_system:"ESRI:20907", label: "20907:GSK-2011_Gauss-Kruger_zone_7"},
  {coordinate_system:"ESRI:20908", label: "20908:GSK-2011_Gauss-Kruger_zone_8"},
  {coordinate_system:"ESRI:20909", label: "20909:GSK-2011_Gauss-Kruger_zone_9"},
  {coordinate_system:"ESRI:20910", label: "20910:GSK-2011_Gauss-Kruger_zone_10"},
  {coordinate_system:"ESRI:20911", label: "20911:GSK-2011_Gauss-Kruger_zone_11"},
  {coordinate_system:"ESRI:20912", label: "20912:GSK-2011_Gauss-Kruger_zone_12"},
  {coordinate_system:"ESRI:20913", label: "20913:GSK-2011_Gauss-Kruger_zone_13"},
  {coordinate_system:"ESRI:20914", label: "20914:GSK-2011_Gauss-Kruger_zone_14"},
  {coordinate_system:"ESRI:20915", label: "20915:GSK-2011_Gauss-Kruger_zone_15"},
  {coordinate_system:"ESRI:20916", label: "20916:GSK-2011_Gauss-Kruger_zone_16"},
  {coordinate_system:"ESRI:20917", label: "20917:GSK-2011_Gauss-Kruger_zone_17"},
  {coordinate_system:"ESRI:20918", label: "20918:GSK-2011_Gauss-Kruger_zone_18"},
  {coordinate_system:"ESRI:20919", label: "20919:GSK-2011_Gauss-Kruger_zone_19"},
  {coordinate_system:"ESRI:20920", label: "20920:GSK-2011_Gauss-Kruger_zone_20"},
  {coordinate_system:"ESRI:20921", label: "20921:GSK-2011_Gauss-Kruger_zone_21"},
  {coordinate_system:"ESRI:20922", label: "20922:GSK-2011_Gauss-Kruger_zone_22"},
  {coordinate_system:"ESRI:20923", label: "20923:GSK-2011_Gauss-Kruger_zone_23"},
  {coordinate_system:"ESRI:20924", label: "20924:GSK-2011_Gauss-Kruger_zone_24"},
  {coordinate_system:"ESRI:20925", label: "20925:GSK-2011_Gauss-Kruger_zone_25"},
  {coordinate_system:"ESRI:20926", label: "20926:GSK-2011_Gauss-Kruger_zone_26"},
  {coordinate_system:"ESRI:20927", label: "20927:GSK-2011_Gauss-Kruger_zone_27"},
  {coordinate_system:"ESRI:20928", label: "20928:GSK-2011_Gauss-Kruger_zone_28"},
  {coordinate_system:"ESRI:20929", label: "20929:GSK-2011_Gauss-Kruger_zone_29"},
  {coordinate_system:"ESRI:20930", label: "20930:GSK-2011_Gauss-Kruger_zone_30"},
  {coordinate_system:"ESRI:20931", label: "20931:GSK-2011_Gauss-Kruger_zone_31"},
  {coordinate_system:"ESRI:20932", label: "20932:GSK-2011_Gauss-Kruger_zone_32"},
  {coordinate_system:"ESRI:21004", label: "21004:GSK-2011_Gauss-Kruger_CM_21E"},
  {coordinate_system:"ESRI:21005", label: "21005:GSK-2011_Gauss-Kruger_CM_27E"},
  {coordinate_system:"ESRI:21006", label: "21006:GSK-2011_Gauss-Kruger_CM_33E"},
  {coordinate_system:"ESRI:21007", label: "21007:GSK-2011_Gauss-Kruger_CM_39E"},
  {coordinate_system:"ESRI:21008", label: "21008:GSK-2011_Gauss-Kruger_CM_45E"},
  {coordinate_system:"ESRI:21009", label: "21009:GSK-2011_Gauss-Kruger_CM_51E"},
  {coordinate_system:"ESRI:21010", label: "21010:GSK-2011_Gauss-Kruger_CM_57E"},
  {coordinate_system:"ESRI:21011", label: "21011:GSK-2011_Gauss-Kruger_CM_63E"},
  {coordinate_system:"ESRI:21012", label: "21012:GSK-2011_Gauss-Kruger_CM_69E"},
  {coordinate_system:"ESRI:21013", label: "21013:GSK-2011_Gauss-Kruger_CM_75E"},
  {coordinate_system:"ESRI:21014", label: "21014:GSK-2011_Gauss-Kruger_CM_81E"},
  {coordinate_system:"ESRI:21015", label: "21015:GSK-2011_Gauss-Kruger_CM_87E"},
  {coordinate_system:"ESRI:21016", label: "21016:GSK-2011_Gauss-Kruger_CM_93E"},
  {coordinate_system:"ESRI:21017", label: "21017:GSK-2011_Gauss-Kruger_CM_99E"},
  {coordinate_system:"ESRI:21018", label: "21018:GSK-2011_Gauss-Kruger_CM_105E"},
  {coordinate_system:"ESRI:21019", label: "21019:GSK-2011_Gauss-Kruger_CM_111E"},
  {coordinate_system:"ESRI:21020", label: "21020:GSK-2011_Gauss-Kruger_CM_117E"},
  {coordinate_system:"ESRI:21021", label: "21021:GSK-2011_Gauss-Kruger_CM_123E"},
  {coordinate_system:"ESRI:21022", label: "21022:GSK-2011_Gauss-Kruger_CM_129E"},
  {coordinate_system:"ESRI:21023", label: "21023:GSK-2011_Gauss-Kruger_CM_135E"},
  {coordinate_system:"ESRI:21024", label: "21024:GSK-2011_Gauss-Kruger_CM_141E"},
  {coordinate_system:"ESRI:21025", label: "21025:GSK-2011_Gauss-Kruger_CM_147E"},
  {coordinate_system:"ESRI:21026", label: "21026:GSK-2011_Gauss-Kruger_CM_153E"},
  {coordinate_system:"ESRI:21027", label: "21027:GSK-2011_Gauss-Kruger_CM_159E"},
  {coordinate_system:"ESRI:21028", label: "21028:GSK-2011_Gauss-Kruger_CM_165E"},
  {coordinate_system:"ESRI:21029", label: "21029:GSK-2011_Gauss-Kruger_CM_171E"},
  {coordinate_system:"ESRI:21030", label: "21030:GSK-2011_Gauss-Kruger_CM_177E"},
  {coordinate_system:"ESRI:21031", label: "21031:GSK-2011_Gauss-Kruger_CM_177W"},
  {coordinate_system:"ESRI:21032", label: "21032:GSK-2011_Gauss-Kruger_CM_171W"},
  {coordinate_system:"ESRI:21207", label: "21207:GSK-2011_GSK_3GK_zone_7"},
  {coordinate_system:"ESRI:21208", label: "21208:GSK-2011_GSK_3GK_zone_8"},
  {coordinate_system:"ESRI:21209", label: "21209:GSK-2011_GSK_3GK_zone_9"},
  {coordinate_system:"ESRI:21210", label: "21210:GSK-2011_GSK_3GK_zone_10"},
  {coordinate_system:"ESRI:21211", label: "21211:GSK-2011_GSK_3GK_zone_11"},
  {coordinate_system:"ESRI:21212", label: "21212:GSK-2011_GSK_3GK_zone_12"},
  {coordinate_system:"ESRI:21213", label: "21213:GSK-2011_GSK_3GK_zone_13"},
  {coordinate_system:"ESRI:21214", label: "21214:GSK-2011_GSK_3GK_zone_14"},
  {coordinate_system:"ESRI:21215", label: "21215:GSK-2011_GSK_3GK_zone_15"},
  {coordinate_system:"ESRI:21216", label: "21216:GSK-2011_GSK_3GK_zone_16"},
  {coordinate_system:"ESRI:21217", label: "21217:GSK-2011_GSK_3GK_zone_17"},
  {coordinate_system:"ESRI:21218", label: "21218:GSK-2011_GSK_3GK_zone_18"},
  {coordinate_system:"ESRI:21219", label: "21219:GSK-2011_GSK_3GK_zone_19"},
  {coordinate_system:"ESRI:21220", label: "21220:GSK-2011_GSK_3GK_zone_20"},
  {coordinate_system:"ESRI:21221", label: "21221:GSK-2011_GSK_3GK_zone_21"},
  {coordinate_system:"ESRI:21222", label: "21222:GSK-2011_GSK_3GK_zone_22"},
  {coordinate_system:"ESRI:21223", label: "21223:GSK-2011_GSK_3GK_zone_23"},
  {coordinate_system:"ESRI:21224", label: "21224:GSK-2011_GSK_3GK_zone_24"},
  {coordinate_system:"ESRI:21225", label: "21225:GSK-2011_GSK_3GK_zone_25"},
  {coordinate_system:"ESRI:21226", label: "21226:GSK-2011_GSK_3GK_zone_26"},
  {coordinate_system:"ESRI:21227", label: "21227:GSK-2011_GSK_3GK_zone_27"},
  {coordinate_system:"ESRI:21228", label: "21228:GSK-2011_GSK_3GK_zone_28"},
  {coordinate_system:"ESRI:21229", label: "21229:GSK-2011_GSK_3GK_zone_29"},
  {coordinate_system:"ESRI:21230", label: "21230:GSK-2011_GSK_3GK_zone_30"},
  {coordinate_system:"ESRI:21231", label: "21231:GSK-2011_GSK_3GK_zone_31"},
  {coordinate_system:"ESRI:21232", label: "21232:GSK-2011_GSK_3GK_zone_32"},
  {coordinate_system:"ESRI:21233", label: "21233:GSK-2011_GSK_3GK_zone_33"},
  {coordinate_system:"ESRI:21234", label: "21234:GSK-2011_GSK_3GK_zone_34"},
  {coordinate_system:"ESRI:21235", label: "21235:GSK-2011_GSK_3GK_zone_35"},
  {coordinate_system:"ESRI:21236", label: "21236:GSK-2011_GSK_3GK_zone_36"},
  {coordinate_system:"ESRI:21237", label: "21237:GSK-2011_GSK_3GK_zone_37"},
  {coordinate_system:"ESRI:21238", label: "21238:GSK-2011_GSK_3GK_zone_38"},
  {coordinate_system:"ESRI:21239", label: "21239:GSK-2011_GSK_3GK_zone_39"},
  {coordinate_system:"ESRI:21240", label: "21240:GSK-2011_GSK_3GK_zone_40"},
  {coordinate_system:"ESRI:21241", label: "21241:GSK-2011_GSK_3GK_zone_41"},
  {coordinate_system:"ESRI:21242", label: "21242:GSK-2011_GSK_3GK_zone_42"},
  {coordinate_system:"ESRI:21243", label: "21243:GSK-2011_GSK_3GK_zone_43"},
  {coordinate_system:"ESRI:21244", label: "21244:GSK-2011_GSK_3GK_zone_44"},
  {coordinate_system:"ESRI:21245", label: "21245:GSK-2011_GSK_3GK_zone_45"},
  {coordinate_system:"ESRI:21246", label: "21246:GSK-2011_GSK_3GK_zone_46"},
  {coordinate_system:"ESRI:21247", label: "21247:GSK-2011_GSK_3GK_zone_47"},
  {coordinate_system:"ESRI:21248", label: "21248:GSK-2011_GSK_3GK_zone_48"},
  {coordinate_system:"ESRI:21249", label: "21249:GSK-2011_GSK_3GK_zone_49"},
  {coordinate_system:"ESRI:21250", label: "21250:GSK-2011_GSK_3GK_zone_50"},
  {coordinate_system:"ESRI:21251", label: "21251:GSK-2011_GSK_3GK_zone_51"},
  {coordinate_system:"ESRI:21252", label: "21252:GSK-2011_GSK_3GK_zone_52"},
  {coordinate_system:"ESRI:21253", label: "21253:GSK-2011_GSK_3GK_zone_53"},
  {coordinate_system:"ESRI:21254", label: "21254:GSK-2011_GSK_3GK_zone_54"},
  {coordinate_system:"ESRI:21255", label: "21255:GSK-2011_GSK_3GK_zone_55"},
  {coordinate_system:"ESRI:21256", label: "21256:GSK-2011_GSK_3GK_zone_56"},
  {coordinate_system:"ESRI:21257", label: "21257:GSK-2011_GSK_3GK_zone_57"},
  {coordinate_system:"ESRI:21258", label: "21258:GSK-2011_GSK_3GK_zone_58"},
  {coordinate_system:"ESRI:21259", label: "21259:GSK-2011_GSK_3GK_zone_59"},
  {coordinate_system:"ESRI:21260", label: "21260:GSK-2011_GSK_3GK_zone_60"},
  {coordinate_system:"ESRI:21261", label: "21261:GSK-2011_GSK_3GK_zone_61"},
  {coordinate_system:"ESRI:21262", label: "21262:GSK-2011_GSK_3GK_zone_62"},
  {coordinate_system:"ESRI:21263", label: "21263:GSK-2011_GSK_3GK_zone_63"},
  {coordinate_system:"ESRI:21264", label: "21264:GSK-2011_GSK_3GK_zone_64"},
  {coordinate_system:"ESRI:21307", label: "21307:GSK-2011_GSK_3GK_CM_21E"},
  {coordinate_system:"ESRI:21308", label: "21308:GSK-2011_GSK_3GK_CM_24E"},
  {coordinate_system:"ESRI:21309", label: "21309:GSK-2011_GSK_3GK_CM_27E"},
  {coordinate_system:"ESRI:21310", label: "21310:GSK-2011_GSK_3GK_CM_30E"},
  {coordinate_system:"ESRI:21311", label: "21311:GSK-2011_GSK_3GK_CM_33E"},
  {coordinate_system:"ESRI:21312", label: "21312:GSK-2011_GSK_3GK_CM_36E"},
  {coordinate_system:"ESRI:21313", label: "21313:GSK-2011_GSK_3GK_CM_39E"},
  {coordinate_system:"ESRI:21314", label: "21314:GSK-2011_GSK_3GK_CM_42E"},
  {coordinate_system:"ESRI:21315", label: "21315:GSK-2011_GSK_3GK_CM_45E"},
  {coordinate_system:"ESRI:21316", label: "21316:GSK-2011_GSK_3GK_CM_48E"},
  {coordinate_system:"ESRI:21317", label: "21317:GSK-2011_GSK_3GK_CM_51E"},
  {coordinate_system:"ESRI:21318", label: "21318:GSK-2011_GSK_3GK_CM_54E"},
  {coordinate_system:"ESRI:21319", label: "21319:GSK-2011_GSK_3GK_CM_57E"},
  {coordinate_system:"ESRI:21320", label: "21320:GSK-2011_GSK_3GK_CM_60E"},
  {coordinate_system:"ESRI:21321", label: "21321:GSK-2011_GSK_3GK_CM_63E"},
  {coordinate_system:"ESRI:21322", label: "21322:GSK-2011_GSK_3GK_CM_66E"},
  {coordinate_system:"ESRI:21323", label: "21323:GSK-2011_GSK_3GK_CM_69E"},
  {coordinate_system:"ESRI:21324", label: "21324:GSK-2011_GSK_3GK_CM_72E"},
  {coordinate_system:"ESRI:21325", label: "21325:GSK-2011_GSK_3GK_CM_75E"},
  {coordinate_system:"ESRI:21326", label: "21326:GSK-2011_GSK_3GK_CM_78E"},
  {coordinate_system:"ESRI:21327", label: "21327:GSK-2011_GSK_3GK_CM_81E"},
  {coordinate_system:"ESRI:21328", label: "21328:GSK-2011_GSK_3GK_CM_84E"},
  {coordinate_system:"ESRI:21329", label: "21329:GSK-2011_GSK_3GK_CM_87E"},
  {coordinate_system:"ESRI:21330", label: "21330:GSK-2011_GSK_3GK_CM_90E"},
  {coordinate_system:"ESRI:21331", label: "21331:GSK-2011_GSK_3GK_CM_93E"},
  {coordinate_system:"ESRI:21332", label: "21332:GSK-2011_GSK_3GK_CM_96E"},
  {coordinate_system:"ESRI:21333", label: "21333:GSK-2011_GSK_3GK_CM_99E"},
  {coordinate_system:"ESRI:21334", label: "21334:GSK-2011_GSK_3GK_CM_102E"},
  {coordinate_system:"ESRI:21335", label: "21335:GSK-2011_GSK_3GK_CM_105E"},
  {coordinate_system:"ESRI:21336", label: "21336:GSK-2011_GSK_3GK_CM_108E"},
  {coordinate_system:"ESRI:21337", label: "21337:GSK-2011_GSK_3GK_CM_111E"},
  {coordinate_system:"ESRI:21338", label: "21338:GSK-2011_GSK_3GK_CM_114E"},
  {coordinate_system:"ESRI:21339", label: "21339:GSK-2011_GSK_3GK_CM_117E"},
  {coordinate_system:"ESRI:21340", label: "21340:GSK-2011_GSK_3GK_CM_120E"},
  {coordinate_system:"ESRI:21341", label: "21341:GSK-2011_GSK_3GK_CM_123E"},
  {coordinate_system:"ESRI:21342", label: "21342:GSK-2011_GSK_3GK_CM_126E"},
  {coordinate_system:"ESRI:21343", label: "21343:GSK-2011_GSK_3GK_CM_129E"},
  {coordinate_system:"ESRI:21344", label: "21344:GSK-2011_GSK_3GK_CM_132E"},
  {coordinate_system:"ESRI:21345", label: "21345:GSK-2011_GSK_3GK_CM_135E"},
  {coordinate_system:"ESRI:21346", label: "21346:GSK-2011_GSK_3GK_CM_138E"},
  {coordinate_system:"ESRI:21347", label: "21347:GSK-2011_GSK_3GK_CM_141E"},
  {coordinate_system:"ESRI:21348", label: "21348:GSK-2011_GSK_3GK_CM_144E"},
  {coordinate_system:"ESRI:21349", label: "21349:GSK-2011_GSK_3GK_CM_147E"},
  {coordinate_system:"ESRI:21350", label: "21350:GSK-2011_GSK_3GK_CM_150E"},
  {coordinate_system:"ESRI:21351", label: "21351:GSK-2011_GSK_3GK_CM_153E"},
  {coordinate_system:"ESRI:21352", label: "21352:GSK-2011_GSK_3GK_CM_156E"},
  {coordinate_system:"ESRI:21353", label: "21353:GSK-2011_GSK_3GK_CM_159E"},
  {coordinate_system:"ESRI:21354", label: "21354:GSK-2011_GSK_3GK_CM_162E"},
  {coordinate_system:"ESRI:21355", label: "21355:GSK-2011_GSK_3GK_CM_165E"},
  {coordinate_system:"ESRI:21356", label: "21356:GSK-2011_GSK_3GK_CM_168E"},
  {coordinate_system:"ESRI:21357", label: "21357:GSK-2011_GSK_3GK_CM_171E"},
  {coordinate_system:"ESRI:21358", label: "21358:GSK-2011_GSK_3GK_CM_174E"},
  {coordinate_system:"ESRI:21359", label: "21359:GSK-2011_GSK_3GK_CM_177E"},
  {coordinate_system:"ESRI:21360", label: "21360:GSK-2011_GSK_3GK_CM_180E"},
  {coordinate_system:"ESRI:21361", label: "21361:GSK-2011_GSK_3GK_CM_177W"},
  {coordinate_system:"ESRI:21362", label: "21362:GSK-2011_GSK_3GK_CM_174W"},
  {coordinate_system:"ESRI:21363", label: "21363:GSK-2011_GSK_3GK_CM_171W"},
  {coordinate_system:"ESRI:21364", label: "21364:GSK-2011_GSK_3GK_CM_168W"},
  {coordinate_system:"ESRI:24721", label: "24721:La_Canoa_UTM_Zone_21N"},
  {coordinate_system:"ESRI:26788", label: "26788:NAD_1927_StatePlane_Michigan_North_FIPS_2111"},
  {coordinate_system:"ESRI:26789", label: "26789:NAD_1927_StatePlane_Michigan_Central_FIPS_2112"},
  {coordinate_system:"ESRI:26790", label: "26790:NAD_1927_StatePlane_Michigan_South_FIPS_2113"},
  {coordinate_system:"ESRI:31491", label: "31491:Germany_Zone_1"},
  {coordinate_system:"ESRI:31492", label: "31492:Germany_Zone_2"},
  {coordinate_system:"ESRI:31493", label: "31493:Germany_Zone_3"},
  {coordinate_system:"ESRI:31494", label: "31494:Germany_Zone_4"},
  {coordinate_system:"ESRI:31495", label: "31495:Germany_Zone_5"},
  {coordinate_system:"ESRI:32059", label: "32059:NAD_1927_StatePlane_Puerto_Rico_FIPS_5201"},
  {coordinate_system:"ESRI:32060", label: "32060:NAD_1927_StatePlane_Virgin_Islands_St_Croix_FIPS_5202"},
  {coordinate_system:"ESRI:53001", label: "53001:Sphere_Plate_Carree"},
  {coordinate_system:"ESRI:53002", label: "53002:Sphere_Equidistant_Cylindrical"},
  {coordinate_system:"ESRI:53003", label: "53003:Sphere_Miller_Cylindrical"},
  {coordinate_system:"ESRI:53004", label: "53004:Sphere_Mercator"},
  {coordinate_system:"ESRI:53008", label: "53008:Sphere_Sinusoidal"},
  {coordinate_system:"ESRI:53009", label: "53009:Sphere_Mollweide"},
  {coordinate_system:"ESRI:53010", label: "53010:Sphere_Eckert_VI"},
  {coordinate_system:"ESRI:53011", label: "53011:Sphere_Eckert_V"},
  {coordinate_system:"ESRI:53012", label: "53012:Sphere_Eckert_IV"},
  {coordinate_system:"ESRI:53013", label: "53013:Sphere_Eckert_III"},
  {coordinate_system:"ESRI:53014", label: "53014:Sphere_Eckert_II"},
  {coordinate_system:"ESRI:53015", label: "53015:Sphere_Eckert_I"},
  {coordinate_system:"ESRI:53016", label: "53016:Sphere_Gall_Stereographic"},
  {coordinate_system:"ESRI:53017", label: "53017:Sphere_Behrmann"},
  {coordinate_system:"ESRI:53018", label: "53018:Sphere_Winkel_I"},
  {coordinate_system:"ESRI:53019", label: "53019:Sphere_Winkel_II"},
  {coordinate_system:"ESRI:53021", label: "53021:Sphere_Polyconic"},
  {coordinate_system:"ESRI:53022", label: "53022:Sphere_Quartic_Authalic"},
  {coordinate_system:"ESRI:53023", label: "53023:Sphere_Loximuthal"},
  {coordinate_system:"ESRI:53024", label: "53024:Sphere_Bonne"},
  {coordinate_system:"ESRI:53025", label: "53025:Sphere_Hotine"},
  {coordinate_system:"ESRI:53026", label: "53026:Sphere_Stereographic"},
  {coordinate_system:"ESRI:53027", label: "53027:Sphere_Equidistant_Conic"},
  {coordinate_system:"ESRI:53028", label: "53028:Sphere_Cassini"},
  {coordinate_system:"ESRI:53029", label: "53029:Sphere_Van_der_Grinten_I"},
  {coordinate_system:"ESRI:53030", label: "53030:Sphere_Robinson"},
  {coordinate_system:"ESRI:53031", label: "53031:Sphere_Two_Point_Equidistant"},
  {coordinate_system:"ESRI:53032", label: "53032:Sphere_Azimuthal_Equidistant"},
  {coordinate_system:"ESRI:53034", label: "53034:Sphere_Cylindrical_Equal_Area"},
  {coordinate_system:"ESRI:53035", label: "53035:Sphere_Equal_Earth_Greenwich"},
  {coordinate_system:"ESRI:53036", label: "53036:Sphere_Equal_Earth_Americas"},
  {coordinate_system:"ESRI:53037", label: "53037:Sphere_Equal_Earth_Asia_Pacific"},
  {coordinate_system:"ESRI:53042", label: "53042:Sphere_Winkel_Tripel_NGS"},
  {coordinate_system:"ESRI:53043", label: "53043:Sphere_Aitoff"},
  {coordinate_system:"ESRI:53044", label: "53044:Sphere_Hammer_Aitoff"},
  {coordinate_system:"ESRI:53045", label: "53045:Sphere_Flat_Polar_Quartic"},
  {coordinate_system:"ESRI:53046", label: "53046:Sphere_Craster_Parabolic"},
  {coordinate_system:"ESRI:53048", label: "53048:Sphere_Times"},
  {coordinate_system:"ESRI:53049", label: "53049:Sphere_Vertical_Perspective"},
  {coordinate_system:"ESRI:53074", label: "53074:Sphere_Wagner_IV"},
  {coordinate_system:"ESRI:53075", label: "53075:Sphere_Wagner_V"},
  {coordinate_system:"ESRI:53076", label: "53076:Sphere_Wagner_VII"},
  {coordinate_system:"ESRI:53077", label: "53077:Sphere_Natural_Earth"},
  {coordinate_system:"ESRI:53078", label: "53078:Sphere_Natural_Earth_II"},
  {coordinate_system:"ESRI:53079", label: "53079:Sphere_Patterson"},
  {coordinate_system:"ESRI:53080", label: "53080:Sphere_Compact_Miller"},
  {coordinate_system:"ESRI:54001", label: "54001:World_Plate_Carree"},
  {coordinate_system:"ESRI:54002", label: "54002:World_Equidistant_Cylindrical"},
  {coordinate_system:"ESRI:54003", label: "54003:World_Miller_Cylindrical"},
  {coordinate_system:"ESRI:54004", label: "54004:World_Mercator"},
  {coordinate_system:"ESRI:54008", label: "54008:World_Sinusoidal"},
  {coordinate_system:"ESRI:54009", label: "54009:World_Mollweide"},
  {coordinate_system:"ESRI:54010", label: "54010:World_Eckert_VI"},
  {coordinate_system:"ESRI:54011", label: "54011:World_Eckert_V"},
  {coordinate_system:"ESRI:54012", label: "54012:World_Eckert_IV"},
  {coordinate_system:"ESRI:54013", label: "54013:World_Eckert_III"},
  {coordinate_system:"ESRI:54014", label: "54014:World_Eckert_II"},
  {coordinate_system:"ESRI:54015", label: "54015:World_Eckert_I"},
  {coordinate_system:"ESRI:54016", label: "54016:World_Gall_Stereographic"},
  {coordinate_system:"ESRI:54017", label: "54017:World_Behrmann"},
  {coordinate_system:"ESRI:54018", label: "54018:World_Winkel_I"},
  {coordinate_system:"ESRI:54019", label: "54019:World_Winkel_II"},
  {coordinate_system:"ESRI:54021", label: "54021:World_Polyconic"},
  {coordinate_system:"ESRI:54022", label: "54022:World_Quartic_Authalic"},
  {coordinate_system:"ESRI:54023", label: "54023:World_Loximuthal"},
  {coordinate_system:"ESRI:54024", label: "54024:World_Bonne"},
  {coordinate_system:"ESRI:54025", label: "54025:World_Hotine"},
  {coordinate_system:"ESRI:54026", label: "54026:World_Stereographic"},
  {coordinate_system:"ESRI:54027", label: "54027:World_Equidistant_Conic"},
  {coordinate_system:"ESRI:54028", label: "54028:World_Cassini"},
  {coordinate_system:"ESRI:54029", label: "54029:World_Van_der_Grinten_I"},
  {coordinate_system:"ESRI:54030", label: "54030:World_Robinson"},
  {coordinate_system:"ESRI:54031", label: "54031:World_Two_Point_Equidistant"},
  {coordinate_system:"ESRI:54032", label: "54032:World_Azimuthal_Equidistant"},
  {coordinate_system:"ESRI:54034", label: "54034:World_Cylindrical_Equal_Area"},
  {coordinate_system:"ESRI:54042", label: "54042:World_Winkel_Tripel_NGS"},
  {coordinate_system:"ESRI:54043", label: "54043:World_Aitoff"},
  {coordinate_system:"ESRI:54044", label: "54044:World_Hammer_Aitoff"},
  {coordinate_system:"ESRI:54045", label: "54045:World_Flat_Polar_Quartic"},
  {coordinate_system:"ESRI:54046", label: "54046:World_Craster_Parabolic"},
  {coordinate_system:"ESRI:54048", label: "54048:World_Times"},
  {coordinate_system:"ESRI:54049", label: "54049:World_Vertical_Perspective"},
  {coordinate_system:"ESRI:54050", label: "54050:World_Fuller"},
  {coordinate_system:"ESRI:54051", label: "54051:World_Cube"},
  {coordinate_system:"ESRI:54052", label: "54052:World_Goode_Homolosine_Land"},
  {coordinate_system:"ESRI:54053", label: "54053:World_Goode_Homolosine_Ocean"},
  {coordinate_system:"ESRI:54074", label: "54074:World_Wagner_IV"},
  {coordinate_system:"ESRI:54075", label: "54075:World_Wagner_V"},
  {coordinate_system:"ESRI:54076", label: "54076:World_Wagner_VII"},
  {coordinate_system:"ESRI:54077", label: "54077:World_Natural_Earth"},
  {coordinate_system:"ESRI:54078", label: "54078:World_Natural_Earth_II"},
  {coordinate_system:"ESRI:54079", label: "54079:World_Patterson"},
  {coordinate_system:"ESRI:54080", label: "54080:World_Compact_Miller"},
  {coordinate_system:"ESRI:54090", label: "54090:WGS_1984_Peirce_quincuncial_North_Pole_square"},
  {coordinate_system:"ESRI:54091", label: "54091:WGS_1984_Peirce_quincuncial_North_Pole_diamond"},
  {coordinate_system:"ESRI:54098", label: "54098:WGS_1984_Adams_Square_II"},
  {coordinate_system:"ESRI:54099", label: "54099:WGS_1984_Spilhaus_Ocean_Map_in_Square"},
  {coordinate_system:"ESRI:54100", label: "54100:WGS_1984_Tobler_Cylindrical_I"},
  {coordinate_system:"ESRI:54101", label: "54101:WGS_1984_Tobler_Cylindrical_II"},
  {coordinate_system:"ESRI:65061", label: "65061:NAD_1927_StatePlane_Guam_FIPS_5400"},
  {coordinate_system:"ESRI:65062", label: "65062:American_Samoa_1962_StatePlane_American_Samoa_FIPS_5300"},
  {coordinate_system:"ESRI:65161", label: "65161:NAD_1983_StatePlane_Guam_FIPS_5400"},
  {coordinate_system:"ESRI:102001", label: "102001:Canada_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102002", label: "102002:Canada_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102003", label: "102003:USA_Contiguous_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102004", label: "102004:USA_Contiguous_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102005", label: "102005:USA_Contiguous_Equidistant_Conic"},
  {coordinate_system:"ESRI:102007", label: "102007:Hawaii_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102008", label: "102008:North_America_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102009", label: "102009:North_America_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102010", label: "102010:North_America_Equidistant_Conic"},
  {coordinate_system:"ESRI:102011", label: "102011:Africa_Sinusoidal"},
  {coordinate_system:"ESRI:102012", label: "102012:Asia_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102013", label: "102013:Europe_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102014", label: "102014:Europe_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102015", label: "102015:South_America_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102016", label: "102016:North_Pole_Azimuthal_Equidistant"},
  {coordinate_system:"ESRI:102017", label: "102017:North_Pole_Lambert_Azimuthal_Equal_Area"},
  {coordinate_system:"ESRI:102018", label: "102018:North_Pole_Stereographic"},
  {coordinate_system:"ESRI:102019", label: "102019:South_Pole_Azimuthal_Equidistant"},
  {coordinate_system:"ESRI:102020", label: "102020:South_Pole_Lambert_Azimuthal_Equal_Area"},
  {coordinate_system:"ESRI:102021", label: "102021:South_Pole_Stereographic"},
  {coordinate_system:"ESRI:102022", label: "102022:Africa_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102023", label: "102023:Africa_Equidistant_Conic"},
  {coordinate_system:"ESRI:102024", label: "102024:Africa_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102025", label: "102025:Asia_North_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102026", label: "102026:Asia_North_Equidistant_Conic"},
  {coordinate_system:"ESRI:102027", label: "102027:Asia_North_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102028", label: "102028:Asia_South_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102029", label: "102029:Asia_South_Equidistant_Conic"},
  {coordinate_system:"ESRI:102030", label: "102030:Asia_South_Lambert_Conformal_Conic"},
  {coordinate_system:"ESRI:102031", label: "102031:Europe_Equidistant_Conic"},
  {coordinate_system:"ESRI:102032", label: "102032:South_America_Equidistant_Conic"},
  {coordinate_system:"ESRI:102033", label: "102033:South_America_Albers_Equal_Area_Conic"},
  {coordinate_system:"ESRI:102034", label: "102034:North_Pole_Gnomonic"},
  {coordinate_system:"ESRI:102035", label: "102035:North_Pole_Orthographic"},
  {coordinate_system:"ESRI:102036", label: "102036:South_Pole_Gnomonic"},
  {coordinate_system:"ESRI:102037", label: "102037:South_Pole_Orthographic"},
  {coordinate_system:"ESRI:102038", label: "102038:The_World_From_Space"},
  {coordinate_system:"ESRI:102039", label: "102039:USA_Contiguous_Albers_Equal_Area_Conic_USGS_version"},
  {coordinate_system:"ESRI:102041", label: "102041:COB_NAD83_2007"},
  {coordinate_system:"ESRI:102042", label: "102042:NAD_1983_USFS_R9_Albers"},
  {coordinate_system:"ESRI:102043", label: "102043:NAD_1983_CORS96_UTM_Zone_20N"},
  {coordinate_system:"ESRI:102044", label: "102044:NAD_1983_NSRS2007_UTM_Zone_20N"},
  {coordinate_system:"ESRI:102045", label: "102045:NAD_1983_2011_UTM_Zone_20N"},
  {coordinate_system:"ESRI:102060", label: "102060:D48_Slovenia_TM"},
  {coordinate_system:"ESRI:102061", label: "102061:Everest_Modified_1969_RSO_Malaya_Meters"},
  {coordinate_system:"ESRI:102062", label: "102062:Kertau_RSO_Malaya_Meters"},
  {coordinate_system:"ESRI:102063", label: "102063:Kandawala_Ceylon_Belt_Meters"},
  {coordinate_system:"ESRI:102064", label: "102064:Kandawala_Ceylon_Belt_Indian_Yards_1937"},
  {coordinate_system:"ESRI:102068", label: "102068:EMEP_50_Kilometer_Grid"},
  {coordinate_system:"ESRI:102069", label: "102069:EMEP_150_Kilometer_Grid"},
  {coordinate_system:"ESRI:102070", label: "102070:Guernsey_Grid"},
  {coordinate_system:"ESRI:102071", label: "102071:AGD_1966_ACT_Grid_AGC_Zone"},
  {coordinate_system:"ESRI:102072", label: "102072:AGD_1966_ISG_54_2"},
  {coordinate_system:"ESRI:102073", label: "102073:AGD_1966_ISG_54_3"},
  {coordinate_system:"ESRI:102074", label: "102074:AGD_1966_ISG_55_1"},
  {coordinate_system:"ESRI:102075", label: "102075:AGD_1966_ISG_55_2"},
  {coordinate_system:"ESRI:102076", label: "102076:AGD_1966_ISG_55_3"},
  {coordinate_system:"ESRI:102077", label: "102077:AGD_1966_ISG_56_1"},
  {coordinate_system:"ESRI:102078", label: "102078:AGD_1966_ISG_56_2"},
  {coordinate_system:"ESRI:102079", label: "102079:AGD_1966_ISG_56_3"},
  {coordinate_system:"ESRI:102093", label: "102093:Roma_1940_Gauss_Boaga_Est"},
  {coordinate_system:"ESRI:102094", label: "102094:Roma_1940_Gauss_Boaga_Ovest"},
  {coordinate_system:"ESRI:102096", label: "102096:Bab_South_Palau_Azimuthal_Equidistant"},
  {coordinate_system:"ESRI:102097", label: "102097:ETRS_1989_UTM_Zone_26N"},
  {coordinate_system:"ESRI:102098", label: "102098:ETRS_1989_UTM_Zone_27N"},
  {coordinate_system:"ESRI:102099", label: "102099:ETRS_1989_UTM_Zone_39N"},
  {coordinate_system:"ESRI:102101", label: "102101:NGO_1948_Norway_Zone_1"},
  {coordinate_system:"ESRI:102102", label: "102102:NGO_1948_Norway_Zone_2"},
  {coordinate_system:"ESRI:102103", label: "102103:NGO_1948_Norway_Zone_3"},
  {coordinate_system:"ESRI:102104", label: "102104:NGO_1948_Norway_Zone_4"},
  {coordinate_system:"ESRI:102105", label: "102105:NGO_1948_Norway_Zone_5"},
  {coordinate_system:"ESRI:102106", label: "102106:NGO_1948_Norway_Zone_6"},
  {coordinate_system:"ESRI:102107", label: "102107:NGO_1948_Norway_Zone_7"},
  {coordinate_system:"ESRI:102108", label: "102108:NGO_1948_Norway_Zone_8"},
  {coordinate_system:"ESRI:102109", label: "102109:ETRS_1989_Slovenia_TM"},
  {coordinate_system:"ESRI:102114", label: "102114:Old_Hawaiian_UTM_Zone_4N"},
  {coordinate_system:"ESRI:102115", label: "102115:Old_Hawaiian_UTM_Zone_5N"},
  {coordinate_system:"ESRI:102116", label: "102116:American_Samoa_1962_UTM_Zone_2S"},
  {coordinate_system:"ESRI:102117", label: "102117:NAD_1927_Alaska_Albers_Meters"},
  {coordinate_system:"ESRI:102118", label: "102118:NAD_1927_Georgia_Statewide_Albers"},
  {coordinate_system:"ESRI:102120", label: "102120:NAD_1927_Michigan_GeoRef_Feet_US"},
  {coordinate_system:"ESRI:102121", label: "102121:NAD_1983_Michigan_GeoRef_Feet_US"},
  {coordinate_system:"ESRI:102122", label: "102122:NAD_1927_Michigan_GeoRef_Meters"},
  {coordinate_system:"ESRI:102132", label: "102132:NGO_1948_UTM_Zone_32N"},
  {coordinate_system:"ESRI:102133", label: "102133:NGO_1948_UTM_Zone_33N"},
  {coordinate_system:"ESRI:102134", label: "102134:NGO_1948_UTM_Zone_34N"},
  {coordinate_system:"ESRI:102135", label: "102135:NGO_1948_UTM_Zone_35N"},
  {coordinate_system:"ESRI:102136", label: "102136:NGO_1948_Baerum_Kommune"},
  {coordinate_system:"ESRI:102137", label: "102137:NGO_1948_Bergenhalvoen"},
  {coordinate_system:"ESRI:102138", label: "102138:NGO_1948_Oslo_Kommune"},
  {coordinate_system:"ESRI:102141", label: "102141:Hong_Kong_1980_UTM_Zone_49N"},
  {coordinate_system:"ESRI:102142", label: "102142:Hong_Kong_1980_UTM_Zone_50N"},
  {coordinate_system:"ESRI:102143", label: "102143:QND_1995_UTM_39N"},
  {coordinate_system:"ESRI:102144", label: "102144:Merchich_Degree_UTM_Zone_28N"},
  {coordinate_system:"ESRI:102150", label: "102150:JGD_2000_UTM_Zone_56N"},
  {coordinate_system:"ESRI:102156", label: "102156:Tokyo_UTM_Zone_56N"},
  {coordinate_system:"ESRI:102157", label: "102157:ETRS_1989_Kosovo_Grid"},
  {coordinate_system:"ESRI:102158", label: "102158:Jordan_JTM"},
  {coordinate_system:"ESRI:102159", label: "102159:Observatorio_Meteorologico_1965_Macau_Grid"},
  {coordinate_system:"ESRI:102160", label: "102160:Datum_73_Hayford_Gauss_IGeoE"},
  {coordinate_system:"ESRI:102161", label: "102161:Datum_73_Hayford_Gauss_IPCC"},
  {coordinate_system:"ESRI:102162", label: "102162:Graciosa_Base_SW_1948_UTM_Zone_26N"},
  {coordinate_system:"ESRI:102163", label: "102163:Lisboa_Bessel_Bonne"},
  {coordinate_system:"ESRI:102164", label: "102164:Lisboa_Hayford_Gauss_IGeoE"},
  {coordinate_system:"ESRI:102165", label: "102165:Lisboa_Hayford_Gauss_IPCC"},
  {coordinate_system:"ESRI:102166", label: "102166:Observatorio_Meteorologico_1939_UTM_Zone_25N"},
  {coordinate_system:"ESRI:102168", label: "102168:Sao_Braz_UTM_Zone_26N"},
  {coordinate_system:"ESRI:102173", label: "102173:ETRS_1989_UWPP_1992"},
  {coordinate_system:"ESRI:102174", label: "102174:ETRS_1989_UWPP_2000_PAS_5"},
  {coordinate_system:"ESRI:102175", label: "102175:ETRS_1989_UWPP_2000_PAS_6"},
  {coordinate_system:"ESRI:102176", label: "102176:ETRS_1989_UWPP_2000_PAS_7"},
  {coordinate_system:"ESRI:102177", label: "102177:ETRS_1989_UWPP_2000_PAS_8"},
  {coordinate_system:"ESRI:102178", label: "102178:NAD_1927_10TM_AEP_Forest"},
  {coordinate_system:"ESRI:102179", label: "102179:NAD_1927_10TM_AEP_Resource"},
  {coordinate_system:"ESRI:102191", label: "102191:Nord_Maroc_Degree"},
  {coordinate_system:"ESRI:102192", label: "102192:Sud_Maroc_Degree"},
  {coordinate_system:"ESRI:102193", label: "102193:Sahara_Degree"},
  {coordinate_system:"ESRI:102194", label: "102194:UWPP_1992"},
  {coordinate_system:"ESRI:102195", label: "102195:UWPP_2000_PAS_5"},
  {coordinate_system:"ESRI:102196", label: "102196:UWPP_2000_PAS_6"},
  {coordinate_system:"ESRI:102197", label: "102197:UWPP_2000_PAS_7"},
  {coordinate_system:"ESRI:102198", label: "102198:UWPP_2000_PAS_8"},
  {coordinate_system:"ESRI:102204", label: "102204:Ain_el_Abd_1970_Aramco_Lambert_2"},
  {coordinate_system:"ESRI:102212", label: "102212:NAD_1983_WyLAM"},
  {coordinate_system:"ESRI:102213", label: "102213:NAD_1983_UTM_Zone_58N"},
  {coordinate_system:"ESRI:102215", label: "102215:WGS_1984_Canada_Atlas_LCC"},
  {coordinate_system:"ESRI:102216", label: "102216:GDA_1994_Perth_Coastal_Grid_1994"},
  {coordinate_system:"ESRI:102217", label: "102217:NAD_1983_NSRS2007_Wisconsin_TM_US_Ft"},
  {coordinate_system:"ESRI:102219", label: "102219:NAD_1983_Wisconsin_TM_US_Ft"},
  {coordinate_system:"ESRI:102220", label: "102220:NAD_1983_HARN_Wisconsin_TM_US_Ft"},
  {coordinate_system:"ESRI:102221", label: "102221:Ocotepeque_1935_Costa_Rica_Lambert_Norte"},
  {coordinate_system:"ESRI:102222", label: "102222:Ocotepeque_1935_Costa_Rica_Lambert_Sur"},
  {coordinate_system:"ESRI:102223", label: "102223:WGS_1984_Costa_Rica_TM_90"},
  {coordinate_system:"ESRI:102224", label: "102224:MONREF_1997_UTM_Zone_46N"},
  {coordinate_system:"ESRI:102225", label: "102225:MONREF_1997_UTM_Zone_47N"},
  {coordinate_system:"ESRI:102226", label: "102226:MONREF_1997_UTM_Zone_48N"},
  {coordinate_system:"ESRI:102227", label: "102227:MONREF_1997_UTM_Zone_49N"},
  {coordinate_system:"ESRI:102228", label: "102228:MONREF_1997_UTM_Zone_50N"},
  {coordinate_system:"ESRI:102231", label: "102231:Colombia_West_West_Zone"},
  {coordinate_system:"ESRI:102232", label: "102232:Bogota_Ciudad_Bogota"},
  {coordinate_system:"ESRI:102233", label: "102233:MAGNA_Ciudad_Bogota"},
  {coordinate_system:"ESRI:102237", label: "102237:Pohnpei_Az_Eq_1971"},
  {coordinate_system:"ESRI:102238", label: "102238:Saipan_Az_Eq_1969"},
  {coordinate_system:"ESRI:102239", label: "102239:Guam_Geodetic_Triangulation_Network_1963"},
  {coordinate_system:"ESRI:102240", label: "102240:Guam_Geodetic_Network_1993"},
  {coordinate_system:"ESRI:102247", label: "102247:NAD_1983_CORS96_Alaska_Albers"},
  {coordinate_system:"ESRI:102299", label: "102299:Berghaus_Star_AAG"},
  {coordinate_system:"ESRI:102306", label: "102306:Nepal_Nagarkot_TM"},
  {coordinate_system:"ESRI:102319", label: "102319:CGRS_1993_LTM"},
  {coordinate_system:"ESRI:102328", label: "102328:ETRS_1989_UTM_Zone_32N_7stellen"},
  {coordinate_system:"ESRI:102329", label: "102329:ETRS_1989_UTM_Zone_32N_8stellen"},
  {coordinate_system:"ESRI:102359", label: "102359:ETRS_1989_UTM_Zone_33N_7stellen"},
  {coordinate_system:"ESRI:102360", label: "102360:ETRS_1989_UTM_Zone_33N_8stellen"},
  {coordinate_system:"ESRI:102364", label: "102364:NAD_1983_CORS96_UTM_Zone_59N"},
  {coordinate_system:"ESRI:102365", label: "102365:NAD_1983_CORS96_UTM_Zone_60N"},
  {coordinate_system:"ESRI:102366", label: "102366:NAD_1983_CORS96_StatePlane_Alaska_1_FIPS_5001"},
  {coordinate_system:"ESRI:102367", label: "102367:NAD_1983_CORS96_StatePlane_Alaska_2_FIPS_5002"},
  {coordinate_system:"ESRI:102368", label: "102368:NAD_1983_CORS96_StatePlane_Alaska_3_FIPS_5003"},
  {coordinate_system:"ESRI:102369", label: "102369:NAD_1983_CORS96_StatePlane_Alaska_4_FIPS_5004"},
  {coordinate_system:"ESRI:102370", label: "102370:NAD_1983_CORS96_StatePlane_Alaska_5_FIPS_5005"},
  {coordinate_system:"ESRI:102371", label: "102371:NAD_1983_CORS96_StatePlane_Alaska_6_FIPS_5006"},
  {coordinate_system:"ESRI:102372", label: "102372:NAD_1983_CORS96_StatePlane_Alaska_7_FIPS_5007"},
  {coordinate_system:"ESRI:102373", label: "102373:NAD_1983_CORS96_StatePlane_Alaska_8_FIPS_5008"},
  {coordinate_system:"ESRI:102374", label: "102374:NAD_1983_CORS96_StatePlane_Alaska_9_FIPS_5009"},
  {coordinate_system:"ESRI:102375", label: "102375:NAD_1983_CORS96_StatePlane_Alaska_10_FIPS_5010"},
  {coordinate_system:"ESRI:102389", label: "102389:NAD_1983_Fargo_Ground_Coordinate_System"},
  {coordinate_system:"ESRI:102390", label: "102390:NAD_1983_HARN_Fargo_Ground_Coordinate_System"},
  {coordinate_system:"ESRI:102391", label: "102391:NAD_1983_2011_Fargo_Ground_Coordinate_System"},
  {coordinate_system:"ESRI:102392", label: "102392:NAD_1983_2011_StatePlane_Alaska_4_FIPS_5004_Feet"},
  {coordinate_system:"ESRI:102393", label: "102393:NAD_1983_2011_StatePlane_Alaska_5_FIPS_5005_Feet"},
  {coordinate_system:"ESRI:102394", label: "102394:NAD_1983_2011_StatePlane_Alaska_6_FIPS_5006_Feet"},
  {coordinate_system:"ESRI:102395", label: "102395:NAD_1983_2011_StatePlane_Alaska_7_FIPS_5007_Feet"},
  {coordinate_system:"ESRI:102396", label: "102396:NAD_1983_2011_StatePlane_Alaska_8_FIPS_5008_Feet"},
  {coordinate_system:"ESRI:102397", label: "102397:NAD_1983_2011_StatePlane_Alaska_9_FIPS_5009_Feet"},
  {coordinate_system:"ESRI:102398", label: "102398:NAD_1983_2011_StatePlane_Alaska_10_FIPS_5010_Feet"},
  {coordinate_system:"ESRI:102399", label: "102399:NAD_1983_Albers_BLM_MT_ND_SD"},
  {coordinate_system:"ESRI:102400", label: "102400:London_Survey_Grid"},
  {coordinate_system:"ESRI:102401", label: "102401:NAD_1983_CORS96_UTM_Zone_1N"},
  {coordinate_system:"ESRI:102402", label: "102402:NAD_1983_CORS96_UTM_Zone_2N"},
  {coordinate_system:"ESRI:102403", label: "102403:NAD_1983_CORS96_UTM_Zone_3N"},
  {coordinate_system:"ESRI:102404", label: "102404:NAD_1983_CORS96_UTM_Zone_4N"},
  {coordinate_system:"ESRI:102405", label: "102405:NAD_1983_CORS96_UTM_Zone_5N"},
  {coordinate_system:"ESRI:102406", label: "102406:NAD_1983_CORS96_UTM_Zone_6N"},
  {coordinate_system:"ESRI:102407", label: "102407:NAD_1983_CORS96_UTM_Zone_7N"},
  {coordinate_system:"ESRI:102408", label: "102408:NAD_1983_CORS96_UTM_Zone_8N"},
  {coordinate_system:"ESRI:102409", label: "102409:NAD_1983_CORS96_UTM_Zone_9N"},
  {coordinate_system:"ESRI:102410", label: "102410:NAD_1983_CORS96_UTM_Zone_10N"},
  {coordinate_system:"ESRI:102411", label: "102411:NAD_1983_CORS96_UTM_Zone_11N"},
  {coordinate_system:"ESRI:102412", label: "102412:NAD_1983_CORS96_UTM_Zone_12N"},
  {coordinate_system:"ESRI:102413", label: "102413:NAD_1983_CORS96_UTM_Zone_13N"},
  {coordinate_system:"ESRI:102414", label: "102414:NAD_1983_CORS96_UTM_Zone_14N"},
  {coordinate_system:"ESRI:102415", label: "102415:NAD_1983_CORS96_UTM_Zone_15N"},
  {coordinate_system:"ESRI:102416", label: "102416:NAD_1983_CORS96_UTM_Zone_16N"},
  {coordinate_system:"ESRI:102417", label: "102417:NAD_1983_CORS96_UTM_Zone_17N"},
  {coordinate_system:"ESRI:102418", label: "102418:NAD_1983_CORS96_UTM_Zone_18N"},
  {coordinate_system:"ESRI:102419", label: "102419:NAD_1983_CORS96_UTM_Zone_19N"},
  {coordinate_system:"ESRI:102421", label: "102421:WGS_1984_ARC_System_Zone_01"},
  {coordinate_system:"ESRI:102422", label: "102422:WGS_1984_ARC_System_Zone_02"},
  {coordinate_system:"ESRI:102423", label: "102423:WGS_1984_ARC_System_Zone_03"},
  {coordinate_system:"ESRI:102424", label: "102424:WGS_1984_ARC_System_Zone_04"},
  {coordinate_system:"ESRI:102425", label: "102425:WGS_1984_ARC_System_Zone_05"},
  {coordinate_system:"ESRI:102426", label: "102426:WGS_1984_ARC_System_Zone_06"},
  {coordinate_system:"ESRI:102427", label: "102427:WGS_1984_ARC_System_Zone_07"},
  {coordinate_system:"ESRI:102428", label: "102428:WGS_1984_ARC_System_Zone_08"},
  {coordinate_system:"ESRI:102429", label: "102429:WGS_1984_ARC_System_Zone_09"},
  {coordinate_system:"ESRI:102430", label: "102430:WGS_1984_ARC_System_Zone_10"},
  {coordinate_system:"ESRI:102431", label: "102431:WGS_1984_ARC_System_Zone_11"},
  {coordinate_system:"ESRI:102432", label: "102432:WGS_1984_ARC_System_Zone_12"},
  {coordinate_system:"ESRI:102433", label: "102433:WGS_1984_ARC_System_Zone_13"},
  {coordinate_system:"ESRI:102434", label: "102434:WGS_1984_ARC_System_Zone_14"},
  {coordinate_system:"ESRI:102435", label: "102435:WGS_1984_ARC_System_Zone_15"},
  {coordinate_system:"ESRI:102436", label: "102436:WGS_1984_ARC_System_Zone_16"},
  {coordinate_system:"ESRI:102437", label: "102437:WGS_1984_ARC_System_Zone_17"},
  {coordinate_system:"ESRI:102438", label: "102438:WGS_1984_ARC_System_Zone_18"},
  {coordinate_system:"ESRI:102440", label: "102440:LKS_1992_Latvia_TM_0"},
  {coordinate_system:"ESRI:102445", label: "102445:NAD_1983_2011_StatePlane_Alaska_1_FIPS_5001_Feet"},
  {coordinate_system:"ESRI:102446", label: "102446:NAD_1983_2011_StatePlane_Alaska_2_FIPS_5002_Feet"},
  {coordinate_system:"ESRI:102447", label: "102447:NAD_1983_2011_StatePlane_Alaska_3_FIPS_5003_Feet"},
  {coordinate_system:"ESRI:102448", label: "102448:Macao_2008_Macao_Grid"},
  {coordinate_system:"ESRI:102450", label: "102450:NGO_1948_Oslo_Baerum_Kommune"},
  {coordinate_system:"ESRI:102451", label: "102451:NGO_1948_Oslo_Bergenhalvoen"},
  {coordinate_system:"ESRI:102452", label: "102452:NGO_1948_Oslo_Oslo_Kommune"},
  {coordinate_system:"ESRI:102453", label: "102453:Luzon_1911_UTM_Zone_50N"},
  {coordinate_system:"ESRI:102454", label: "102454:Luzon_1911_UTM_Zone_51N"},
  {coordinate_system:"ESRI:102455", label: "102455:Luzon_1911_UTM_Zone_52N"},
  {coordinate_system:"ESRI:102456", label: "102456:PRS_1992_UTM_Zone_50N"},
  {coordinate_system:"ESRI:102457", label: "102457:PRS_1992_UTM_Zone_51N"},
  {coordinate_system:"ESRI:102458", label: "102458:PRS_1992_UTM_Zone_52N"},
  {coordinate_system:"ESRI:102459", label: "102459:NAD_1983_Idaho-Ada_County"},
  {coordinate_system:"ESRI:102460", label: "102460:HJAIA_AirportGrid_2Mar10"},
  {coordinate_system:"ESRI:102461", label: "102461:NAD_1983_HARN_StatePlane_Hawaii_1_FIPS_5101_Feet"},
  {coordinate_system:"ESRI:102462", label: "102462:NAD_1983_HARN_StatePlane_Hawaii_2_FIPS_5102_Feet"},
  {coordinate_system:"ESRI:102464", label: "102464:NAD_1983_HARN_StatePlane_Hawaii_4_FIPS_5104_Feet"},
  {coordinate_system:"ESRI:102465", label: "102465:NAD_1983_HARN_StatePlane_Hawaii_5_FIPS_5105_Feet"},
  {coordinate_system:"ESRI:102470", label: "102470:Cape_Lo15"},
  {coordinate_system:"ESRI:102471", label: "102471:Cape_Lo17"},
  {coordinate_system:"ESRI:102472", label: "102472:Cape_Lo19"},
  {coordinate_system:"ESRI:102473", label: "102473:Cape_Lo21"},
  {coordinate_system:"ESRI:102474", label: "102474:Cape_Lo23"},
  {coordinate_system:"ESRI:102475", label: "102475:Cape_Lo25"},
  {coordinate_system:"ESRI:102476", label: "102476:Cape_Lo27"},
  {coordinate_system:"ESRI:102477", label: "102477:Cape_Lo29"},
  {coordinate_system:"ESRI:102478", label: "102478:Cape_Lo31"},
  {coordinate_system:"ESRI:102479", label: "102479:Cape_Lo33"},
  {coordinate_system:"ESRI:102480", label: "102480:Hartebeesthoek94_Lo15"},
  {coordinate_system:"ESRI:102481", label: "102481:Hartebeesthoek94_Lo17"},
  {coordinate_system:"ESRI:102482", label: "102482:Hartebeesthoek94_Lo19"},
  {coordinate_system:"ESRI:102483", label: "102483:Hartebeesthoek94_Lo21"},
  {coordinate_system:"ESRI:102484", label: "102484:Hartebeesthoek94_Lo23"},
  {coordinate_system:"ESRI:102485", label: "102485:Hartebeesthoek94_Lo25"},
  {coordinate_system:"ESRI:102486", label: "102486:Hartebeesthoek94_Lo27"},
  {coordinate_system:"ESRI:102487", label: "102487:Hartebeesthoek94_Lo29"},
  {coordinate_system:"ESRI:102488", label: "102488:Hartebeesthoek94_Lo31"},
  {coordinate_system:"ESRI:102489", label: "102489:Hartebeesthoek94_Lo33"},
  {coordinate_system:"ESRI:102491", label: "102491:Nord_Algerie_Ancienne_Degree"},
  {coordinate_system:"ESRI:102492", label: "102492:Sud_Algerie_Ancienne_Degree"},
  {coordinate_system:"ESRI:102498", label: "102498:GOES-16_East_ABI_Fixed_Grid_ITRF2008"},
  {coordinate_system:"ESRI:102499", label: "102499:Belge_Lambert_1972_bad_FE_FN"},
  {coordinate_system:"ESRI:102525", label: "102525:NAD_1983_PA11_StatePlane_Hawaii_1_FIPS_5101_Feet"},
  {coordinate_system:"ESRI:102526", label: "102526:NAD_1983_PA11_StatePlane_Hawaii_2_FIPS_5102_Feet"},
  {coordinate_system:"ESRI:102528", label: "102528:NAD_1983_PA11_StatePlane_Hawaii_4_FIPS_5104_Feet"},
  {coordinate_system:"ESRI:102529", label: "102529:NAD_1983_PA11_StatePlane_Hawaii_5_FIPS_5105_Feet"},
  {coordinate_system:"ESRI:102550", label: "102550:ED_1950_Turkey_9"},
  {coordinate_system:"ESRI:102551", label: "102551:ED_1950_Turkey_10"},
  {coordinate_system:"ESRI:102552", label: "102552:ED_1950_Turkey_11"},
  {coordinate_system:"ESRI:102553", label: "102553:ED_1950_Turkey_12"},
  {coordinate_system:"ESRI:102554", label: "102554:ED_1950_Turkey_13"},
  {coordinate_system:"ESRI:102555", label: "102555:ED_1950_Turkey_14"},
  {coordinate_system:"ESRI:102556", label: "102556:ED_1950_Turkey_15"},
  {coordinate_system:"ESRI:102562", label: "102562:Hartebeesthoek94_Lo19_(E-N)"},
  {coordinate_system:"ESRI:102563", label: "102563:Hartebeesthoek94_Lo21_(E-N)"},
  {coordinate_system:"ESRI:102564", label: "102564:Hartebeesthoek94_Lo23_(E-N)"},
  {coordinate_system:"ESRI:102565", label: "102565:Hartebeesthoek94_Lo25_(E-N)"},
  {coordinate_system:"ESRI:102566", label: "102566:Hartebeesthoek94_Lo27_(E-N)"},
  {coordinate_system:"ESRI:102567", label: "102567:Hartebeesthoek94_Lo29_(E-N)"},
  {coordinate_system:"ESRI:102568", label: "102568:Hartebeesthoek94_Lo31_(E-N)"},
  {coordinate_system:"ESRI:102569", label: "102569:New_Beijing_3_Degree_Gauss_Kruger_CM_132E"},
  {coordinate_system:"ESRI:102570", label: "102570:WGS_1984_Complex_UTM_Zone_20N"},
  {coordinate_system:"ESRI:102571", label: "102571:WGS_1984_Complex_UTM_Zone_21N"},
  {coordinate_system:"ESRI:102572", label: "102572:WGS_1984_Complex_UTM_Zone_22N"},
  {coordinate_system:"ESRI:102573", label: "102573:WGS_1984_Complex_UTM_Zone_23N"},
  {coordinate_system:"ESRI:102574", label: "102574:WGS_1984_Complex_UTM_Zone_24N"},
  {coordinate_system:"ESRI:102575", label: "102575:WGS_1984_Complex_UTM_Zone_25N"},
  {coordinate_system:"ESRI:102576", label: "102576:WGS_1984_Complex_UTM_Zone_26N"},
  {coordinate_system:"ESRI:102577", label: "102577:WGS_1984_Complex_UTM_Zone_27N"},
  {coordinate_system:"ESRI:102578", label: "102578:WGS_1984_Complex_UTM_Zone_28N"},
  {coordinate_system:"ESRI:102579", label: "102579:WGS_1984_Complex_UTM_Zone_29N"},
  {coordinate_system:"ESRI:102580", label: "102580:WGS_1984_Complex_UTM_Zone_30N"},
  {coordinate_system:"ESRI:102581", label: "102581:NTF_France_I_degrees"},
  {coordinate_system:"ESRI:102582", label: "102582:NTF_France_II_degrees"},
  {coordinate_system:"ESRI:102583", label: "102583:NTF_France_III_degrees"},
  {coordinate_system:"ESRI:102584", label: "102584:NTF_France_IV_degrees"},
  {coordinate_system:"ESRI:102585", label: "102585:NTF_Lambert_Zone_I"},
  {coordinate_system:"ESRI:102586", label: "102586:NTF_Lambert_Zone_II"},
  {coordinate_system:"ESRI:102587", label: "102587:NTF_Lambert_Zone_III"},
  {coordinate_system:"ESRI:102588", label: "102588:NTF_Lambert_Zone_IV"},
  {coordinate_system:"ESRI:102589", label: "102589:Panhandle_Energy_Albers"},
  {coordinate_system:"ESRI:102598", label: "102598:JGD_2011_UTM_Zone_56N"},
  {coordinate_system:"ESRI:102599", label: "102599:WGS_1984_California_Teale_Albers_FtUS"},
  {coordinate_system:"ESRI:102600", label: "102600:NAD_1983_California_Teale_Albers_FtUS"},
  {coordinate_system:"ESRI:102604", label: "102604:NAD_1983_Georgia_Statewide_Lambert"},
  {coordinate_system:"ESRI:102629", label: "102629:NAD_1983_StatePlane_Alabama_East_FIPS_0101_Feet"},
  {coordinate_system:"ESRI:102630", label: "102630:NAD_1983_StatePlane_Alabama_West_FIPS_0102_Feet"},
  {coordinate_system:"ESRI:102631", label: "102631:NAD_1983_StatePlane_Alaska_1_FIPS_5001_Feet"},
  {coordinate_system:"ESRI:102632", label: "102632:NAD_1983_StatePlane_Alaska_2_FIPS_5002_Feet"},
  {coordinate_system:"ESRI:102633", label: "102633:NAD_1983_StatePlane_Alaska_3_FIPS_5003_Feet"},
  {coordinate_system:"ESRI:102634", label: "102634:NAD_1983_StatePlane_Alaska_4_FIPS_5004_Feet"},
  {coordinate_system:"ESRI:102635", label: "102635:NAD_1983_StatePlane_Alaska_5_FIPS_5005_Feet"},
  {coordinate_system:"ESRI:102636", label: "102636:NAD_1983_StatePlane_Alaska_6_FIPS_5006_Feet"},
  {coordinate_system:"ESRI:102637", label: "102637:NAD_1983_StatePlane_Alaska_7_FIPS_5007_Feet"},
  {coordinate_system:"ESRI:102638", label: "102638:NAD_1983_StatePlane_Alaska_8_FIPS_5008_Feet"},
  {coordinate_system:"ESRI:102639", label: "102639:NAD_1983_StatePlane_Alaska_9_FIPS_5009_Feet"},
  {coordinate_system:"ESRI:102640", label: "102640:NAD_1983_StatePlane_Alaska_10_FIPS_5010_Feet"},
  {coordinate_system:"ESRI:102648", label: "102648:NAD_1983_StatePlane_Arizona_East_FIPS_0201_Feet"},
  {coordinate_system:"ESRI:102649", label: "102649:NAD_1983_StatePlane_Arizona_Central_FIPS_0202_Feet"},
  {coordinate_system:"ESRI:102650", label: "102650:NAD_1983_StatePlane_Arizona_West_FIPS_0203_Feet"},
  {coordinate_system:"ESRI:102661", label: "102661:NAD_1983_StatePlane_Hawaii_1_FIPS_5101_Feet"},
  {coordinate_system:"ESRI:102662", label: "102662:NAD_1983_StatePlane_Hawaii_2_FIPS_5102_Feet"},
  {coordinate_system:"ESRI:102664", label: "102664:NAD_1983_StatePlane_Hawaii_4_FIPS_5104_Feet"},
  {coordinate_system:"ESRI:102665", label: "102665:NAD_1983_StatePlane_Hawaii_5_FIPS_5105_Feet"},
  {coordinate_system:"ESRI:102688", label: "102688:NAD_1983_StatePlane_Michigan_North_FIPS_2111_Feet"},
  {coordinate_system:"ESRI:102689", label: "102689:NAD_1983_StatePlane_Michigan_Central_FIPS_2112_Feet"},
  {coordinate_system:"ESRI:102690", label: "102690:NAD_1983_StatePlane_Michigan_South_FIPS_2113_Feet"},
  {coordinate_system:"ESRI:102696", label: "102696:NAD_1983_StatePlane_Missouri_East_FIPS_2401_Feet"},
  {coordinate_system:"ESRI:102697", label: "102697:NAD_1983_StatePlane_Missouri_Central_FIPS_2402_Feet"},
  {coordinate_system:"ESRI:102698", label: "102698:NAD_1983_StatePlane_Missouri_West_FIPS_2403_Feet"},
  {coordinate_system:"ESRI:102699", label: "102699:NAD_1927_StatePlane_California_V_Ventura"},
  {coordinate_system:"ESRI:102700", label: "102700:NAD_1983_StatePlane_Montana_FIPS_2500_Feet"},
  {coordinate_system:"ESRI:102701", label: "102701:NAD_1983_PACP00_UTM_Zone_4N"},
  {coordinate_system:"ESRI:102702", label: "102702:NAD_1983_PACP00_UTM_Zone_5N"},
  {coordinate_system:"ESRI:102703", label: "102703:NAD_1983_PACP00_UTM_Zone_2S"},
  {coordinate_system:"ESRI:102705", label: "102705:NAD_1983_Nebraska_Lancaster_County_FtUS"},
  {coordinate_system:"ESRI:102720", label: "102720:NAD_1983_StatePlane_North_Dakota_North_FIPS_3301_Feet"},
  {coordinate_system:"ESRI:102721", label: "102721:NAD_1983_StatePlane_North_Dakota_South_FIPS_3302_Feet"},
  {coordinate_system:"ESRI:102726", label: "102726:NAD_1983_StatePlane_Oregon_North_FIPS_3601_Feet"},
  {coordinate_system:"ESRI:102727", label: "102727:NAD_1983_StatePlane_Oregon_South_FIPS_3602_Feet"},
  {coordinate_system:"ESRI:102733", label: "102733:NAD_1983_StatePlane_South_Carolina_FIPS_3900_Feet"},
  {coordinate_system:"ESRI:102759", label: "102759:WGS_1984_California_Teale_Albers_Ft_Intl"},
  {coordinate_system:"ESRI:102760", label: "102760:NAD_1983_California_Teale_Albers_Ft_Intl"},
  {coordinate_system:"ESRI:102761", label: "102761:NAD_1983_StatePlane_Puerto_Rico_Virgin_Islands_FIPS_5200_Feet"},
  {coordinate_system:"ESRI:102766", label: "102766:NAD_1983_StatePlane_Guam_FIPS_5400_Feet"},
  {coordinate_system:"ESRI:102799", label: "102799:OSGB36_Highways_England_A1H1"},
  {coordinate_system:"ESRI:102800", label: "102800:OSGB36_Highways_England_A2H1"},
  {coordinate_system:"ESRI:102801", label: "102801:OSGB36_Highways_England_A3H1"},
  {coordinate_system:"ESRI:102802", label: "102802:OSGB36_Highways_England_A3H2"},
  {coordinate_system:"ESRI:102803", label: "102803:OSGB36_Highways_England_A4H1"},
  {coordinate_system:"ESRI:102804", label: "102804:OSGB36_Highways_England_A5H1"},
  {coordinate_system:"ESRI:102805", label: "102805:OSGB36_Highways_England_A5H2"},
  {coordinate_system:"ESRI:102806", label: "102806:OSGB36_Highways_England_A6H1"},
  {coordinate_system:"ESRI:102807", label: "102807:OSGB36_Highways_England_A6H2"},
  {coordinate_system:"ESRI:102808", label: "102808:OSGB36_Highways_England_A7H1"},
  {coordinate_system:"ESRI:102809", label: "102809:OSGB36_Highways_England_A7H2"},
  {coordinate_system:"ESRI:102810", label: "102810:OSGB36_Highways_England_A8H1"},
  {coordinate_system:"ESRI:102811", label: "102811:OSGB36_Highways_England_A8H2"},
  {coordinate_system:"ESRI:102812", label: "102812:OSGB36_Highways_England_A9H1"},
  {coordinate_system:"ESRI:102813", label: "102813:OSGB36_Highways_England_A9H2"},
  {coordinate_system:"ESRI:102814", label: "102814:OSGB36_Highways_England_A10H1"},
  {coordinate_system:"ESRI:102815", label: "102815:OSGB36_Highways_England_A10H2"},
  {coordinate_system:"ESRI:102816", label: "102816:OSGB36_Highways_England_A11H1"},
  {coordinate_system:"ESRI:102817", label: "102817:OSGB36_Highways_England_A11H2"},
  {coordinate_system:"ESRI:102818", label: "102818:OSGB36_Highways_England_A11H3"},
  {coordinate_system:"ESRI:102819", label: "102819:OSGB36_Highways_England_A12H1"},
  {coordinate_system:"ESRI:102820", label: "102820:OSGB36_Highways_England_A12H2"},
  {coordinate_system:"ESRI:102821", label: "102821:OSGB36_Highways_England_A12H3"},
  {coordinate_system:"ESRI:102822", label: "102822:OSGB36_Highways_England_A13H1"},
  {coordinate_system:"ESRI:102823", label: "102823:OSGB36_Highways_England_A13H2"},
  {coordinate_system:"ESRI:102824", label: "102824:OSGB36_Highways_England_A14H1"},
  {coordinate_system:"ESRI:102825", label: "102825:OSGB36_Highways_England_A14H2"},
  {coordinate_system:"ESRI:102826", label: "102826:OSGB36_Highways_England_A15H1"},
  {coordinate_system:"ESRI:102827", label: "102827:OSGB36_Highways_England_A15H2"},
  {coordinate_system:"ESRI:102828", label: "102828:OSGB36_Highways_England_A16H1"},
  {coordinate_system:"ESRI:102829", label: "102829:OSGB36_Highways_England_A16H2"},
  {coordinate_system:"ESRI:102830", label: "102830:OSGB36_Highways_England_A17H1"},
  {coordinate_system:"ESRI:102831", label: "102831:OSGB36_Highways_England_A17H2"},
  {coordinate_system:"ESRI:102832", label: "102832:OSGB36_Highways_England_A18H1"},
  {coordinate_system:"ESRI:102833", label: "102833:OSGB36_Highways_England_A18H2"},
  {coordinate_system:"ESRI:102834", label: "102834:OSGB36_Highways_England_A19H1"},
  {coordinate_system:"ESRI:102835", label: "102835:OSGB36_Highways_England_A19H2"},
  {coordinate_system:"ESRI:102836", label: "102836:OSGB36_Highways_England_A20H1"},
  {coordinate_system:"ESRI:102837", label: "102837:OSGB36_Highways_England_A20H2"},
  {coordinate_system:"ESRI:102838", label: "102838:OSGB36_Highways_England_A21H1"},
  {coordinate_system:"ESRI:102839", label: "102839:OSGB36_Highways_England_A21H2"},
  {coordinate_system:"ESRI:102840", label: "102840:OSGB36_Highways_England_A22H1"},
  {coordinate_system:"ESRI:102841", label: "102841:OSGB36_Highways_England_A22H2"},
  {coordinate_system:"ESRI:102842", label: "102842:OSGB36_Highways_England_A23H1"},
  {coordinate_system:"ESRI:102843", label: "102843:OSGB36_Highways_England_A23H2"},
  {coordinate_system:"ESRI:102844", label: "102844:OSGB36_Highways_England_A24H1"},
  {coordinate_system:"ESRI:102845", label: "102845:OSGB36_Highways_England_A24H2"},
  {coordinate_system:"ESRI:102846", label: "102846:OSGB36_Highways_England_A25H1"},
  {coordinate_system:"ESRI:102847", label: "102847:OSGB36_Highways_England_A25H2"},
  {coordinate_system:"ESRI:102848", label: "102848:OSGB36_Highways_England_A26H1"},
  {coordinate_system:"ESRI:102849", label: "102849:OSGB36_Highways_England_A26H2"},
  {coordinate_system:"ESRI:102850", label: "102850:OSGB36_Highways_England_A27H1"},
  {coordinate_system:"ESRI:102851", label: "102851:OSGB36_Highways_England_A27H2"},
  {coordinate_system:"ESRI:102852", label: "102852:OSGB36_Highways_England_A28H1"},
  {coordinate_system:"ESRI:102853", label: "102853:OSGB36_Highways_England_A28H2"},
  {coordinate_system:"ESRI:102854", label: "102854:OSGB36_Highways_England_A29H1"},
  {coordinate_system:"ESRI:102855", label: "102855:OSGB36_Highways_England_A30H1"},
  {coordinate_system:"ESRI:102856", label: "102856:OSGB36_Highways_England_B15H1"},
  {coordinate_system:"ESRI:102857", label: "102857:OSGB36_Highways_England_B15H2"},
  {coordinate_system:"ESRI:102858", label: "102858:OSGB36_Highways_England_B15H3"},
  {coordinate_system:"ESRI:102859", label: "102859:OSGB36_Highways_England_B16H1"},
  {coordinate_system:"ESRI:102860", label: "102860:OSGB36_Highways_England_B16H2"},
  {coordinate_system:"ESRI:102861", label: "102861:OSGB36_Highways_England_B16H3"},
  {coordinate_system:"ESRI:102862", label: "102862:OSGB36_Highways_England_B16H4"},
  {coordinate_system:"ESRI:102863", label: "102863:OSGB36_Highways_England_B17H1"},
  {coordinate_system:"ESRI:102864", label: "102864:OSGB36_Highways_England_B17H2"},
  {coordinate_system:"ESRI:102865", label: "102865:OSGB36_Highways_England_B18H1"},
  {coordinate_system:"ESRI:102866", label: "102866:OSGB36_Highways_England_B18H2"},
  {coordinate_system:"ESRI:102867", label: "102867:OSGB36_Highways_England_B19H1"},
  {coordinate_system:"ESRI:102868", label: "102868:OSGB36_Highways_England_B20H1"},
  {coordinate_system:"ESRI:102869", label: "102869:OSGB36_Highways_England_B21H1"},
  {coordinate_system:"ESRI:102870", label: "102870:OSGB36_Highways_England_B22H1"},
  {coordinate_system:"ESRI:102871", label: "102871:OSGB36_Highways_England_B23H1"},
  {coordinate_system:"ESRI:102872", label: "102872:OSGB36_Highways_England_B24H1"},
  {coordinate_system:"ESRI:102873", label: "102873:OSGB36_Highways_England_B25H1"},
  {coordinate_system:"ESRI:102874", label: "102874:OSGB36_Highways_England_B26H1"},
  {coordinate_system:"ESRI:102875", label: "102875:OSGB36_Highways_England_B27H1"},
  {coordinate_system:"ESRI:102876", label: "102876:OSGB36_Highways_England_B28H1"},
  {coordinate_system:"ESRI:102877", label: "102877:OSGB36_Highways_England_B29H1"},
  {coordinate_system:"ESRI:102878", label: "102878:OSGB36_Highways_England_B30H1"},
  {coordinate_system:"ESRI:102879", label: "102879:OSGB36_Highways_England_B31H1"},
  {coordinate_system:"ESRI:102880", label: "102880:OSGB36_Highways_England_B32H1"},
  {coordinate_system:"ESRI:102881", label: "102881:OSGB36_Highways_England_C13H1"},
  {coordinate_system:"ESRI:102882", label: "102882:OSGB36_Highways_England_C14H1"},
  {coordinate_system:"ESRI:102883", label: "102883:OSGB36_Highways_England_C14H2"},
  {coordinate_system:"ESRI:102884", label: "102884:OSGB36_Highways_England_C14H3"},
  {coordinate_system:"ESRI:102885", label: "102885:OSGB36_Highways_England_C14H4"},
  {coordinate_system:"ESRI:102886", label: "102886:OSGB36_Highways_England_C15H1"},
  {coordinate_system:"ESRI:102887", label: "102887:OSGB36_Highways_England_C15H2"},
  {coordinate_system:"ESRI:102888", label: "102888:OSGB36_Highways_England_C15H3"},
  {coordinate_system:"ESRI:102889", label: "102889:OSGB36_Highways_England_C15H4"},
  {coordinate_system:"ESRI:102890", label: "102890:OSGB36_Highways_England_C15H5"},
  {coordinate_system:"ESRI:102891", label: "102891:OSGB36_Highways_England_C16H1"},
  {coordinate_system:"ESRI:102892", label: "102892:OSGB36_Highways_England_C16H2"},
  {coordinate_system:"ESRI:102893", label: "102893:OSGB36_Highways_England_C16H3"},
  {coordinate_system:"ESRI:102894", label: "102894:OSGB36_Highways_England_C16H4"},
  {coordinate_system:"ESRI:102895", label: "102895:OSGB36_Highways_England_C17H1"},
  {coordinate_system:"ESRI:102896", label: "102896:OSGB36_Highways_England_C17H2"},
  {coordinate_system:"ESRI:102897", label: "102897:OSGB36_Highways_England_C18H1"},
  {coordinate_system:"ESRI:102898", label: "102898:OSGB36_Highways_England_C18H2"},
  {coordinate_system:"ESRI:102899", label: "102899:OSGB36_Highways_England_C19H1"},
  {coordinate_system:"ESRI:102900", label: "102900:OSGB36_Highways_England_C19H2"},
  {coordinate_system:"ESRI:102901", label: "102901:NAD_1983_(2011)_ICS_Aurora_(US_Feet)"},
  {coordinate_system:"ESRI:102902", label: "102902:NAD_1983_(2011)_ICS_Freeport_(US_Feet)"},
  {coordinate_system:"ESRI:102903", label: "102903:NAD_1983_(2011)_ICS_Chicago_(US_Feet)"},
  {coordinate_system:"ESRI:102904", label: "102904:NAD_1983_(2011)_ICS_Rockford_(US_Feet)"},
  {coordinate_system:"ESRI:102905", label: "102905:NAD_1983_(2011)_ICS_Moline_(US_Feet)"},
  {coordinate_system:"ESRI:102906", label: "102906:NAD_1983_(2011)_ICS_Ottawa_(US_Feet)"},
  {coordinate_system:"ESRI:102907", label: "102907:NAD_1983_(2011)_ICS_Sterling_(US_Feet)"},
  {coordinate_system:"ESRI:102908", label: "102908:NAD_1983_(2011)_ICS_Joliet_(US_Feet)"},
  {coordinate_system:"ESRI:102909", label: "102909:NAD_1983_(2011)_ICS_Eureka_(US_Feet)"},
  {coordinate_system:"ESRI:102910", label: "102910:NAD_1983_(2011)_ICS_Pontiac_(US_Feet)"},
  {coordinate_system:"ESRI:102911", label: "102911:NAD_1983_(2011)_ICS_Monmouth_(US_Feet)"},
  {coordinate_system:"ESRI:102912", label: "102912:NAD_1983_(2011)_ICS_Watseka_(US_Feet)"},
  {coordinate_system:"ESRI:102913", label: "102913:NAD_1983_(2011)_ICS_Peoria_(US_Feet)"},
  {coordinate_system:"ESRI:102914", label: "102914:NAD_1983_(2011)_ICS_Bloomington_(US_Feet)"},
  {coordinate_system:"ESRI:102915", label: "102915:NAD_1983_(2011)_ICS_Galesburg_(US_Feet)"},
  {coordinate_system:"ESRI:102916", label: "102916:NAD_1983_(2011)_ICS_Champaign_(US_Feet)"},
  {coordinate_system:"ESRI:102917", label: "102917:NAD_1983_(2011)_ICS_Decatur_(US_Feet)"},
  {coordinate_system:"ESRI:102918", label: "102918:NAD_1983_(2011)_ICS_Quincy_(US_Feet)"},
  {coordinate_system:"ESRI:102919", label: "102919:NAD_1983_(2011)_ICS_Lincoln_(US_Feet)"},
  {coordinate_system:"ESRI:102920", label: "102920:NAD_1983_(2011)_ICS_Macomb_(US_Feet)"},
  {coordinate_system:"ESRI:102921", label: "102921:NAD_1983_(2011)_ICS_Springfield_(US_Feet)"},
  {coordinate_system:"ESRI:102922", label: "102922:NAD_1983_(2011)_ICS_Jacksonville_(US_Feet)"},
  {coordinate_system:"ESRI:102923", label: "102923:NAD_1983_(2011)_ICS_Taylorville_(US_Feet)"},
  {coordinate_system:"ESRI:102924", label: "102924:NAD_1983_(2011)_ICS_Charleston_(US_Feet)"},
  {coordinate_system:"ESRI:102925", label: "102925:NAD_1983_(2011)_ICS_Carlinville_(US_Feet)"},
  {coordinate_system:"ESRI:102926", label: "102926:NAD_1983_(2011)_ICS_Robinson_(US_Feet)"},
  {coordinate_system:"ESRI:102927", label: "102927:NAD_1983_(2011)_ICS_Jerseyville_(US_Feet)"},
  {coordinate_system:"ESRI:102928", label: "102928:NAD_1983_(2011)_ICS_Effingham_(US_Feet)"},
  {coordinate_system:"ESRI:102929", label: "102929:NAD_1983_(2011)_ICS_Belleville_(US_Feet)"},
  {coordinate_system:"ESRI:102930", label: "102930:NAD_1983_(2011)_ICS_Olney_(US_Feet)"},
  {coordinate_system:"ESRI:102931", label: "102931:NAD_1983_(2011)_ICS_Mount_Vernon_(US_Feet)"},
  {coordinate_system:"ESRI:102932", label: "102932:NAD_1983_(2011)_ICS_Carbondale_(US_Feet)"},
  {coordinate_system:"ESRI:102933", label: "102933:NAD_1983_(2011)_ICS_Metropolis_(US_Feet)"},
  {coordinate_system:"ESRI:102934", label: "102934:Cassini_Bangladesh_Zone_01_Dinajpur"},
  {coordinate_system:"ESRI:102935", label: "102935:Cassini_Bangladesh_Zone_02_Rangpur"},
  {coordinate_system:"ESRI:102936", label: "102936:Cassini_Bangladesh_Zone_03_Rajshahi"},
  {coordinate_system:"ESRI:102937", label: "102937:Cassini_Bangladesh_Zone_04_Bogura"},
  {coordinate_system:"ESRI:102938", label: "102938:Cassini_Bangladesh_Zone_05_Pabna"},
  {coordinate_system:"ESRI:102939", label: "102939:Cassini_Bangladesh_Zone_06_Mymensingh"},
  {coordinate_system:"ESRI:102940", label: "102940:Cassini_Bangladesh_Zone_07_Dhaka"},
  {coordinate_system:"ESRI:102941", label: "102941:Cassini_Bangladesh_Zone_08_Faridpur"},
  {coordinate_system:"ESRI:102942", label: "102942:Cassini_Bangladesh_Zone_09_Sylhet"},
  {coordinate_system:"ESRI:102943", label: "102943:Cassini_Bangladesh_Zone_10_Kushtia"},
  {coordinate_system:"ESRI:102944", label: "102944:Cassini_Bangladesh_Zone_11_Joshore"},
  {coordinate_system:"ESRI:102945", label: "102945:Cassini_Bangladesh_Zone_12_Khulna"},
  {coordinate_system:"ESRI:102946", label: "102946:Cassini_Bangladesh_Zone_13_Barishal"},
  {coordinate_system:"ESRI:102947", label: "102947:Cassini_Bangladesh_Zone_14_Cumilla"},
  {coordinate_system:"ESRI:102948", label: "102948:Cassini_Bangladesh_Zone_15_Noakhali"},
  {coordinate_system:"ESRI:102949", label: "102949:Cassini_Bangladesh_Zone_16_Chottogram"},
  {coordinate_system:"ESRI:102950", label: "102950:Cassini_Bangladesh_Zone_17_CHT"},
  {coordinate_system:"ESRI:102951", label: "102951:LCC_Bangladesh"},
  {coordinate_system:"ESRI:102952", label: "102952:LCC_Gulshan"},
  {coordinate_system:"ESRI:102953", label: "102953:UTM_Bangladesh_(BTM)"},
  {coordinate_system:"ESRI:102954", label: "102954:BUTM2010"},
  {coordinate_system:"ESRI:102955", label: "102955:UTM_Gulshan"},
  {coordinate_system:"ESRI:102961", label: "102961:AGD_1966_ISG_57_2"},
  {coordinate_system:"ESRI:102974", label: "102974:NAD_1983_2011_Wisconsin_TM_US_Ft"},
  {coordinate_system:"ESRI:103166", label: "103166:NAD_1983_2011_StatePlane_Utah_North_FIPS_4301_Ft_Intl"},
  {coordinate_system:"ESRI:103167", label: "103167:NAD_1983_2011_StatePlane_Utah_Central_FIPS_4302_Ft_Intl"},
  {coordinate_system:"ESRI:103168", label: "103168:NAD_1983_2011_StatePlane_Utah_South_FIPS_4303_Ft_Intl"},
  {coordinate_system:"ESRI:103220", label: "103220:NAD_1983_CORS96_StatePlane_Alabama_East_FIPS_0101"},
  {coordinate_system:"ESRI:103221", label: "103221:NAD_1983_CORS96_StatePlane_Alabama_West_FIPS_0102"},
  {coordinate_system:"ESRI:103222", label: "103222:NAD_1983_CORS96_StatePlane_Arizona_East_FIPS_0201"},
  {coordinate_system:"ESRI:103223", label: "103223:NAD_1983_CORS96_StatePlane_Arizona_Central_FIPS_0202"},
  {coordinate_system:"ESRI:103224", label: "103224:NAD_1983_CORS96_StatePlane_Arizona_West_FIPS_0203"},
  {coordinate_system:"ESRI:103225", label: "103225:NAD_1983_CORS96_StatePlane_Arizona_East_FIPS_0201_Ft_Intl"},
  {coordinate_system:"ESRI:103226", label: "103226:NAD_1983_CORS96_StatePlane_Arizona_Central_FIPS_0202_Ft_Intl"},
  {coordinate_system:"ESRI:103227", label: "103227:NAD_1983_CORS96_StatePlane_Arizona_West_FIPS_0203_Ft_Intl"},
  {coordinate_system:"ESRI:103228", label: "103228:NAD_1983_CORS96_StatePlane_Arkansas_North_FIPS_0301"},
  {coordinate_system:"ESRI:103229", label: "103229:NAD_1983_CORS96_StatePlane_Arkansas_South_FIPS_0302"},
  {coordinate_system:"ESRI:103230", label: "103230:NAD_1983_CORS96_StatePlane_Arkansas_North_FIPS_0301_Ft_US"},
  {coordinate_system:"ESRI:103231", label: "103231:NAD_1983_CORS96_StatePlane_Arkansas_South_FIPS_0302_Ft_US"},
  {coordinate_system:"ESRI:103232", label: "103232:NAD_1983_CORS96_StatePlane_California_I_FIPS_0401"},
  {coordinate_system:"ESRI:103233", label: "103233:NAD_1983_CORS96_StatePlane_California_II_FIPS_0402"},
  {coordinate_system:"ESRI:103234", label: "103234:NAD_1983_CORS96_StatePlane_California_III_FIPS_0403"},
  {coordinate_system:"ESRI:103235", label: "103235:NAD_1983_CORS96_StatePlane_California_IV_FIPS_0404"},
  {coordinate_system:"ESRI:103236", label: "103236:NAD_1983_CORS96_StatePlane_California_V_FIPS_0405"},
  {coordinate_system:"ESRI:103237", label: "103237:NAD_1983_CORS96_StatePlane_California_VI_FIPS_0406"},
  {coordinate_system:"ESRI:103238", label: "103238:NAD_1983_CORS96_StatePlane_California_I_FIPS_0401_Ft_US"},
  {coordinate_system:"ESRI:103239", label: "103239:NAD_1983_CORS96_StatePlane_California_II_FIPS_0402_Ft_US"},
  {coordinate_system:"ESRI:103240", label: "103240:NAD_1983_CORS96_StatePlane_California_III_FIPS_0403_Ft_US"},
  {coordinate_system:"ESRI:103241", label: "103241:NAD_1983_CORS96_StatePlane_California_IV_FIPS_0404_Ft_US"},
  {coordinate_system:"ESRI:103242", label: "103242:NAD_1983_CORS96_StatePlane_California_V_FIPS_0405_Ft_US"},
  {coordinate_system:"ESRI:103243", label: "103243:NAD_1983_CORS96_StatePlane_California_VI_FIPS_0406_Ft_US"},
  {coordinate_system:"ESRI:103244", label: "103244:NAD_1983_CORS96_StatePlane_Colorado_North_FIPS_0501"},
  {coordinate_system:"ESRI:103245", label: "103245:NAD_1983_CORS96_StatePlane_Colorado_Central_FIPS_0502"},
  {coordinate_system:"ESRI:103246", label: "103246:NAD_1983_CORS96_StatePlane_Colorado_South_FIPS_0503"},
  {coordinate_system:"ESRI:103247", label: "103247:NAD_1983_CORS96_StatePlane_Colorado_North_FIPS_0501_Ft_US"},
  {coordinate_system:"ESRI:103248", label: "103248:NAD_1983_CORS96_StatePlane_Colorado_Central_FIPS_0502_Ft_US"},
  {coordinate_system:"ESRI:103249", label: "103249:NAD_1983_CORS96_StatePlane_Colorado_South_FIPS_0503_Ft_US"},
  {coordinate_system:"ESRI:103250", label: "103250:NAD_1983_CORS96_StatePlane_Connecticut_FIPS_0600"},
  {coordinate_system:"ESRI:103251", label: "103251:NAD_1983_CORS96_StatePlane_Connecticut_FIPS_0600_Ft_US"},
  {coordinate_system:"ESRI:103252", label: "103252:NAD_1983_CORS96_StatePlane_Delaware_FIPS_0700"},
  {coordinate_system:"ESRI:103253", label: "103253:NAD_1983_CORS96_StatePlane_Delaware_FIPS_0700_Ft_US"},
  {coordinate_system:"ESRI:103254", label: "103254:NAD_1983_CORS96_StatePlane_Florida_East_FIPS_0901"},
  {coordinate_system:"ESRI:103255", label: "103255:NAD_1983_CORS96_StatePlane_Florida_West_FIPS_0902"},
  {coordinate_system:"ESRI:103256", label: "103256:NAD_1983_CORS96_StatePlane_Florida_North_FIPS_0903"},
  {coordinate_system:"ESRI:103257", label: "103257:NAD_1983_CORS96_StatePlane_Florida_East_FIPS_0901_Ft_US"},
  {coordinate_system:"ESRI:103258", label: "103258:NAD_1983_CORS96_StatePlane_Florida_West_FIPS_0902_Ft_US"},
  {coordinate_system:"ESRI:103259", label: "103259:NAD_1983_CORS96_StatePlane_Florida_North_FIPS_0903_Ft_US"},
  {coordinate_system:"ESRI:103260", label: "103260:NAD_1983_CORS96_StatePlane_Georgia_East_FIPS_1001"},
  {coordinate_system:"ESRI:103261", label: "103261:NAD_1983_CORS96_StatePlane_Georgia_West_FIPS_1002"},
  {coordinate_system:"ESRI:103262", label: "103262:NAD_1983_CORS96_StatePlane_Georgia_East_FIPS_1001_Ft_US"},
  {coordinate_system:"ESRI:103263", label: "103263:NAD_1983_CORS96_StatePlane_Georgia_West_FIPS_1002_Ft_US"},
  {coordinate_system:"ESRI:103264", label: "103264:NAD_1983_CORS96_StatePlane_Idaho_East_FIPS_1101"},
  {coordinate_system:"ESRI:103265", label: "103265:NAD_1983_CORS96_StatePlane_Idaho_Central_FIPS_1102"},
  {coordinate_system:"ESRI:103266", label: "103266:NAD_1983_CORS96_StatePlane_Idaho_West_FIPS_1103"},
  {coordinate_system:"ESRI:103267", label: "103267:NAD_1983_CORS96_StatePlane_Idaho_East_FIPS_1101_Ft_US"},
  {coordinate_system:"ESRI:103268", label: "103268:NAD_1983_CORS96_StatePlane_Idaho_Central_FIPS_1102_Ft_US"},
  {coordinate_system:"ESRI:103269", label: "103269:NAD_1983_CORS96_StatePlane_Idaho_West_FIPS_1103_Ft_US"},
  {coordinate_system:"ESRI:103270", label: "103270:NAD_1983_CORS96_StatePlane_Illinois_East_FIPS_1201"},
  {coordinate_system:"ESRI:103271", label: "103271:NAD_1983_CORS96_StatePlane_Illinois_West_FIPS_1202"},
  {coordinate_system:"ESRI:103272", label: "103272:NAD_1983_CORS96_StatePlane_Illinois_East_FIPS_1201_Ft_US"},
  {coordinate_system:"ESRI:103273", label: "103273:NAD_1983_CORS96_StatePlane_Illinois_West_FIPS_1202_Ft_US"},
  {coordinate_system:"ESRI:103274", label: "103274:NAD_1983_CORS96_StatePlane_Indiana_East_FIPS_1301"},
  {coordinate_system:"ESRI:103275", label: "103275:NAD_1983_CORS96_StatePlane_Indiana_West_FIPS_1302"},
  {coordinate_system:"ESRI:103276", label: "103276:NAD_1983_CORS96_StatePlane_Indiana_East_FIPS_1301_Ft_US"},
  {coordinate_system:"ESRI:103277", label: "103277:NAD_1983_CORS96_StatePlane_Indiana_West_FIPS_1302_Ft_US"},
  {coordinate_system:"ESRI:103278", label: "103278:NAD_1983_CORS96_StatePlane_Iowa_North_FIPS_1401"},
  {coordinate_system:"ESRI:103279", label: "103279:NAD_1983_CORS96_StatePlane_Iowa_South_FIPS_1402"},
  {coordinate_system:"ESRI:103280", label: "103280:NAD_1983_CORS96_StatePlane_Iowa_North_FIPS_1401_Ft_US"},
  {coordinate_system:"ESRI:103281", label: "103281:NAD_1983_CORS96_StatePlane_Iowa_South_FIPS_1402_Ft_US"},
  {coordinate_system:"ESRI:103282", label: "103282:NAD_1983_CORS96_StatePlane_Kansas_North_FIPS_1501"},
  {coordinate_system:"ESRI:103283", label: "103283:NAD_1983_CORS96_StatePlane_Kansas_South_FIPS_1502"},
  {coordinate_system:"ESRI:103284", label: "103284:NAD_1983_CORS96_StatePlane_Kansas_North_FIPS_1501_Ft_US"},
  {coordinate_system:"ESRI:103285", label: "103285:NAD_1983_CORS96_StatePlane_Kansas_South_FIPS_1502_Ft_US"},
  {coordinate_system:"ESRI:103286", label: "103286:NAD_1983_CORS96_StatePlane_Kentucky_North_FIPS_1601"},
  {coordinate_system:"ESRI:103287", label: "103287:NAD_1983_CORS96_StatePlane_Kentucky_North_FIPS_1601_Ft_US"},
  {coordinate_system:"ESRI:103288", label: "103288:NAD_1983_CORS96_StatePlane_Kentucky_FIPS_1600"},
  {coordinate_system:"ESRI:103289", label: "103289:NAD_1983_CORS96_StatePlane_Kentucky_FIPS_1600_Ft_US"},
  {coordinate_system:"ESRI:103290", label: "103290:NAD_1983_CORS96_StatePlane_Kentucky_South_FIPS_1602"},
  {coordinate_system:"ESRI:103291", label: "103291:NAD_1983_CORS96_StatePlane_Kentucky_South_FIPS_1602_Ft_US"},
  {coordinate_system:"ESRI:103292", label: "103292:NAD_1983_CORS96_StatePlane_Louisiana_North_FIPS_1701"},
  {coordinate_system:"ESRI:103293", label: "103293:NAD_1983_CORS96_StatePlane_Louisiana_South_FIPS_1702"},
  {coordinate_system:"ESRI:103294", label: "103294:NAD_1983_CORS96_StatePlane_Louisiana_North_FIPS_1701_Ft_US"},
  {coordinate_system:"ESRI:103295", label: "103295:NAD_1983_CORS96_StatePlane_Louisiana_South_FIPS_1702_Ft_US"},
  {coordinate_system:"ESRI:103296", label: "103296:NAD_1983_CORS96_StatePlane_Maine_East_FIPS_1801"},
  {coordinate_system:"ESRI:103297", label: "103297:NAD_1983_CORS96_StatePlane_Maine_West_FIPS_1802"},
  {coordinate_system:"ESRI:103298", label: "103298:NAD_1983_CORS96_StatePlane_Maine_East_FIPS_1801_Ft_US"},
  {coordinate_system:"ESRI:103299", label: "103299:NAD_1983_CORS96_StatePlane_Maine_West_FIPS_1802_Ft_US"},
  {coordinate_system:"ESRI:103300", label: "103300:NAD_1983_HARN_WISCRS_Adams_County_Meters"},
  {coordinate_system:"ESRI:103307", label: "103307:NAD_1983_HARN_WISCRS_Calumet_County_Meters"},
  {coordinate_system:"ESRI:103313", label: "103313:NAD_1983_HARN_WISCRS_Dodge_County_Meters"},
  {coordinate_system:"ESRI:103319", label: "103319:NAD_1983_HARN_WISCRS_Fond_du_Lac_County_Meters"},
  {coordinate_system:"ESRI:103322", label: "103322:NAD_1983_HARN_WISCRS_Green_County_Meters"},
  {coordinate_system:"ESRI:103323", label: "103323:NAD_1983_HARN_WISCRS_GreenLake_County_Meters"},
  {coordinate_system:"ESRI:103327", label: "103327:NAD_1983_HARN_WISCRS_Jefferson_County_Meters"},
  {coordinate_system:"ESRI:103328", label: "103328:NAD_1983_HARN_WISCRS_Juneau_County_Meters"},
  {coordinate_system:"ESRI:103329", label: "103329:NAD_1983_HARN_WISCRS_Kenosha_County_Meters"},
  {coordinate_system:"ESRI:103330", label: "103330:NAD_1983_HARN_WISCRS_Kewaunee_County_Meters"},
  {coordinate_system:"ESRI:103332", label: "103332:NAD_1983_HARN_WISCRS_Lafayette_County_Meters"},
  {coordinate_system:"ESRI:103335", label: "103335:NAD_1983_HARN_WISCRS_Manitowoc_County_Meters"},
  {coordinate_system:"ESRI:103338", label: "103338:NAD_1983_HARN_WISCRS_Marquette_County_Meters"},
  {coordinate_system:"ESRI:103340", label: "103340:NAD_1983_HARN_WISCRS_Milwaukee_County_Meters"},
  {coordinate_system:"ESRI:103344", label: "103344:NAD_1983_HARN_WISCRS_Outagamie_County_Meters"},
  {coordinate_system:"ESRI:103345", label: "103345:NAD_1983_HARN_WISCRS_Ozaukee_County_Meters"},
  {coordinate_system:"ESRI:103346", label: "103346:NAD_1983_HARN_WISCRS_Pepin_County_Meters"},
  {coordinate_system:"ESRI:103347", label: "103347:NAD_1983_HARN_WISCRS_Pierce_County_Meters"},
  {coordinate_system:"ESRI:103351", label: "103351:NAD_1983_HARN_WISCRS_Racine_County_Meters"},
  {coordinate_system:"ESRI:103358", label: "103358:NAD_1983_HARN_WISCRS_Sheboygan_County_Meters"},
  {coordinate_system:"ESRI:103370", label: "103370:NAD_1983_HARN_WISCRS_Winnebago_County_Meters"},
  {coordinate_system:"ESRI:103372", label: "103372:NAD_1983_CORS96_Maine_2000_East_Zone"},
  {coordinate_system:"ESRI:103373", label: "103373:NAD_1983_CORS96_Maine_2000_Central_Zone"},
  {coordinate_system:"ESRI:103374", label: "103374:NAD_1983_CORS96_Maine_2000_West_Zone"},
  {coordinate_system:"ESRI:103375", label: "103375:NAD_1983_CORS96_StatePlane_Maryland_FIPS_1900"},
  {coordinate_system:"ESRI:103376", label: "103376:NAD_1983_CORS96_StatePlane_Maryland_FIPS_1900_Ft_US"},
  {coordinate_system:"ESRI:103377", label: "103377:NAD_1983_CORS96_StatePlane_Massachusetts_Mainland_FIPS_2001"},
  {coordinate_system:"ESRI:103378", label: "103378:NAD_1983_CORS96_StatePlane_Massachusetts_Island_FIPS_2002"},
  {coordinate_system:"ESRI:103379", label: "103379:NAD_1983_CORS96_StatePlane_Massachusetts_Mnld_FIPS_2001_FtUS"},
  {coordinate_system:"ESRI:103380", label: "103380:NAD_1983_CORS96_StatePlane_Massachusetts_Isl_FIPS_2002_FtUS"},
  {coordinate_system:"ESRI:103381", label: "103381:NAD_1983_CORS96_StatePlane_Michigan_North_FIPS_2111"},
  {coordinate_system:"ESRI:103382", label: "103382:NAD_1983_CORS96_StatePlane_Michigan_Central_FIPS_2112"},
  {coordinate_system:"ESRI:103383", label: "103383:NAD_1983_CORS96_StatePlane_Michigan_South_FIPS_2113"},
  {coordinate_system:"ESRI:103384", label: "103384:NAD_1983_CORS96_StatePlane_Michigan_North_FIPS_2111_Ft_Intl"},
  {coordinate_system:"ESRI:103385", label: "103385:NAD_1983_CORS96_StatePlane_Michigan_Central_FIPS_2112_Ft_Intl"},
  {coordinate_system:"ESRI:103386", label: "103386:NAD_1983_CORS96_StatePlane_Michigan_South_FIPS_2113_Ft_Intl"},
  {coordinate_system:"ESRI:103387", label: "103387:NAD_1983_CORS96_StatePlane_Minnesota_North_FIPS_2201"},
  {coordinate_system:"ESRI:103388", label: "103388:NAD_1983_CORS96_StatePlane_Minnesota_Central_FIPS_2202"},
  {coordinate_system:"ESRI:103389", label: "103389:NAD_1983_CORS96_StatePlane_Minnesota_South_FIPS_2203"},
  {coordinate_system:"ESRI:103390", label: "103390:NAD_1983_CORS96_StatePlane_Minnesota_North_FIPS_2201_Ft_US"},
  {coordinate_system:"ESRI:103391", label: "103391:NAD_1983_CORS96_StatePlane_Minnesota_Central_FIPS_2202_Ft_US"},
  {coordinate_system:"ESRI:103392", label: "103392:NAD_1983_CORS96_StatePlane_Minnesota_South_FIPS_2203_Ft_US"},
  {coordinate_system:"ESRI:103393", label: "103393:NAD_1983_CORS96_StatePlane_Mississippi_East_FIPS_2301"},
  {coordinate_system:"ESRI:103394", label: "103394:NAD_1983_CORS96_StatePlane_Mississippi_West_FIPS_2302"},
  {coordinate_system:"ESRI:103395", label: "103395:NAD_1983_CORS96_StatePlane_Mississippi_East_FIPS_2301_Ft_US"},
  {coordinate_system:"ESRI:103396", label: "103396:NAD_1983_CORS96_StatePlane_Mississippi_West_FIPS_2302_Ft_US"},
  {coordinate_system:"ESRI:103397", label: "103397:NAD_1983_CORS96_StatePlane_Missouri_East_FIPS_2401"},
  {coordinate_system:"ESRI:103398", label: "103398:NAD_1983_CORS96_StatePlane_Missouri_Central_FIPS_2402"},
  {coordinate_system:"ESRI:103399", label: "103399:NAD_1983_CORS96_StatePlane_Missouri_West_FIPS_2403"},
  {coordinate_system:"ESRI:103400", label: "103400:NAD_1983_HARN_WISCRS_Adams_County_Feet"},
  {coordinate_system:"ESRI:103407", label: "103407:NAD_1983_HARN_WISCRS_Calumet_County_Feet"},
  {coordinate_system:"ESRI:103413", label: "103413:NAD_1983_HARN_WISCRS_Dodge_County_Feet"},
  {coordinate_system:"ESRI:103419", label: "103419:NAD_1983_HARN_WISCRS_Fond_du_Lac_County_Feet"},
  {coordinate_system:"ESRI:103422", label: "103422:NAD_1983_HARN_WISCRS_Green_County_Feet"},
  {coordinate_system:"ESRI:103423", label: "103423:NAD_1983_HARN_WISCRS_GreenLake_County_Feet"},
  {coordinate_system:"ESRI:103427", label: "103427:NAD_1983_HARN_WISCRS_Jefferson_County_Feet"},
  {coordinate_system:"ESRI:103428", label: "103428:NAD_1983_HARN_WISCRS_Juneau_County_Feet"},
  {coordinate_system:"ESRI:103429", label: "103429:NAD_1983_HARN_WISCRS_Kenosha_County_Feet"},
  {coordinate_system:"ESRI:103430", label: "103430:NAD_1983_HARN_WISCRS_Kewaunee_County_Feet"},
  {coordinate_system:"ESRI:103432", label: "103432:NAD_1983_HARN_WISCRS_Lafayette_County_Feet"},
  {coordinate_system:"ESRI:103435", label: "103435:NAD_1983_HARN_WISCRS_Manitowoc_County_Feet"},
  {coordinate_system:"ESRI:103438", label: "103438:NAD_1983_HARN_WISCRS_Marquette_County_Feet"},
  {coordinate_system:"ESRI:103440", label: "103440:NAD_1983_HARN_WISCRS_Milwaukee_County_Feet"},
  {coordinate_system:"ESRI:103444", label: "103444:NAD_1983_HARN_WISCRS_Outagamie_County_Feet"},
  {coordinate_system:"ESRI:103445", label: "103445:NAD_1983_HARN_WISCRS_Ozaukee_County_Feet"},
  {coordinate_system:"ESRI:103446", label: "103446:NAD_1983_HARN_WISCRS_Pepin_County_Feet"},
  {coordinate_system:"ESRI:103447", label: "103447:NAD_1983_HARN_WISCRS_Pierce_County_Feet"},
  {coordinate_system:"ESRI:103451", label: "103451:NAD_1983_HARN_WISCRS_Racine_County_Feet"},
  {coordinate_system:"ESRI:103458", label: "103458:NAD_1983_HARN_WISCRS_Sheboygan_County_Feet"},
  {coordinate_system:"ESRI:103470", label: "103470:NAD_1983_HARN_WISCRS_Winnebago_County_Feet"},
  {coordinate_system:"ESRI:103472", label: "103472:NAD_1983_CORS96_StatePlane_Montana_FIPS_2500"},
  {coordinate_system:"ESRI:103473", label: "103473:NAD_1983_CORS96_StatePlane_Montana_FIPS_2500_Ft_Intl"},
  {coordinate_system:"ESRI:103474", label: "103474:NAD_1983_CORS96_StatePlane_Nebraska_FIPS_2600"},
  {coordinate_system:"ESRI:103475", label: "103475:NAD_1983_CORS96_StatePlane_Nebraska_FIPS_2600_Ft_US"},
  {coordinate_system:"ESRI:103476", label: "103476:NAD_1983_CORS96_StatePlane_Nevada_East_FIPS_2701"},
  {coordinate_system:"ESRI:103477", label: "103477:NAD_1983_CORS96_StatePlane_Nevada_Central_FIPS_2702"},
  {coordinate_system:"ESRI:103478", label: "103478:NAD_1983_CORS96_StatePlane_Nevada_West_FIPS_2703"},
  {coordinate_system:"ESRI:103479", label: "103479:NAD_1983_CORS96_StatePlane_Nevada_East_FIPS_2701_Ft_US"},
  {coordinate_system:"ESRI:103480", label: "103480:NAD_1983_CORS96_StatePlane_Nevada_Central_FIPS_2702_Ft_US"},
  {coordinate_system:"ESRI:103481", label: "103481:NAD_1983_CORS96_StatePlane_Nevada_West_FIPS_2703_Ft_US"},
  {coordinate_system:"ESRI:103482", label: "103482:NAD_1983_CORS96_StatePlane_New_Hampshire_FIPS_2800"},
  {coordinate_system:"ESRI:103483", label: "103483:NAD_1983_CORS96_StatePlane_New_Hampshire_FIPS_2800_Ft_US"},
  {coordinate_system:"ESRI:103484", label: "103484:NAD_1983_CORS96_StatePlane_New_Jersey_FIPS_2900"},
  {coordinate_system:"ESRI:103485", label: "103485:NAD_1983_CORS96_StatePlane_New_Jersey_FIPS_2900_Ft_US"},
  {coordinate_system:"ESRI:103486", label: "103486:NAD_1983_CORS96_StatePlane_New_Mexico_East_FIPS_3001"},
  {coordinate_system:"ESRI:103487", label: "103487:NAD_1983_CORS96_StatePlane_New_Mexico_Central_FIPS_3002"},
  {coordinate_system:"ESRI:103488", label: "103488:NAD_1983_CORS96_StatePlane_New_Mexico_West_FIPS_3003"},
  {coordinate_system:"ESRI:103489", label: "103489:NAD_1983_CORS96_StatePlane_New_Mexico_East_FIPS_3001_Ft_US"},
  {coordinate_system:"ESRI:103490", label: "103490:NAD_1983_CORS96_StatePlane_New_Mexico_Central_FIPS_3002_Ft_US"},
  {coordinate_system:"ESRI:103491", label: "103491:NAD_1983_CORS96_StatePlane_New_Mexico_West_FIPS_3003_Ft_US"},
  {coordinate_system:"ESRI:103492", label: "103492:NAD_1983_CORS96_StatePlane_New_York_East_FIPS_3101"},
  {coordinate_system:"ESRI:103493", label: "103493:NAD_1983_CORS96_StatePlane_New_York_Central_FIPS_3102"},
  {coordinate_system:"ESRI:103494", label: "103494:NAD_1983_CORS96_StatePlane_New_York_West_FIPS_3103"},
  {coordinate_system:"ESRI:103495", label: "103495:NAD_1983_CORS96_StatePlane_New_York_Long_Island_FIPS_3104"},
  {coordinate_system:"ESRI:103496", label: "103496:NAD_1983_CORS96_StatePlane_New_York_East_FIPS_3101_Ft_US"},
  {coordinate_system:"ESRI:103497", label: "103497:NAD_1983_CORS96_StatePlane_New_York_Central_FIPS_3102_Ft_US"},
  {coordinate_system:"ESRI:103498", label: "103498:NAD_1983_CORS96_StatePlane_New_York_West_FIPS_3103_Ft_US"},
  {coordinate_system:"ESRI:103499", label: "103499:NAD_1983_CORS96_StatePlane_New_York_Long_Isl_FIPS_3104_Ft_US"},
  {coordinate_system:"ESRI:103500", label: "103500:NAD_1983_CORS96_StatePlane_North_Carolina_FIPS_3200"},
  {coordinate_system:"ESRI:103501", label: "103501:NAD_1983_CORS96_StatePlane_North_Carolina_FIPS_3200_Ft_US"},
  {coordinate_system:"ESRI:103502", label: "103502:NAD_1983_CORS96_StatePlane_North_Dakota_North_FIPS_3301"},
  {coordinate_system:"ESRI:103503", label: "103503:NAD_1983_CORS96_StatePlane_North_Dakota_South_FIPS_3302"},
  {coordinate_system:"ESRI:103504", label: "103504:NAD_1983_CORS96_StatePlane_North_Dakota_North_FIPS_3301_FtI"},
  {coordinate_system:"ESRI:103505", label: "103505:NAD_1983_CORS96_StatePlane_North_Dakota_South_FIPS_3302_FtI"},
  {coordinate_system:"ESRI:103506", label: "103506:NAD_1983_CORS96_StatePlane_Ohio_North_FIPS_3401"},
  {coordinate_system:"ESRI:103507", label: "103507:NAD_1983_CORS96_StatePlane_Ohio_South_FIPS_3402"},
  {coordinate_system:"ESRI:103508", label: "103508:NAD_1983_CORS96_StatePlane_Ohio_North_FIPS_3401_Ft_US"},
  {coordinate_system:"ESRI:103509", label: "103509:NAD_1983_CORS96_StatePlane_Ohio_South_FIPS_3402_Ft_US"},
  {coordinate_system:"ESRI:103510", label: "103510:NAD_1983_CORS96_StatePlane_Oklahoma_North_FIPS_3501"},
  {coordinate_system:"ESRI:103511", label: "103511:NAD_1983_CORS96_StatePlane_Oklahoma_South_FIPS_3502"},
  {coordinate_system:"ESRI:103512", label: "103512:NAD_1983_CORS96_StatePlane_Oklahoma_North_FIPS_3501_Ft_US"},
  {coordinate_system:"ESRI:103513", label: "103513:NAD_1983_CORS96_StatePlane_Oklahoma_South_FIPS_3502_Ft_US"},
  {coordinate_system:"ESRI:103514", label: "103514:NAD_1983_CORS96_StatePlane_Pennsylvania_North_FIPS_3701"},
  {coordinate_system:"ESRI:103515", label: "103515:NAD_1983_CORS96_StatePlane_Pennsylvania_North_FIPS_3701_Ft_US"},
  {coordinate_system:"ESRI:103516", label: "103516:NAD_1983_CORS96_StatePlane_Pennsylvania_South_FIPS_3702"},
  {coordinate_system:"ESRI:103517", label: "103517:NAD_1983_CORS96_StatePlane_Pennsylvania_South_FIPS_3702_Ft_US"},
  {coordinate_system:"ESRI:103518", label: "103518:NAD_1983_CORS96_StatePlane_Rhode_Island_FIPS_3800"},
  {coordinate_system:"ESRI:103519", label: "103519:NAD_1983_CORS96_StatePlane_Rhode_Island_FIPS_3800_Ft_US"},
  {coordinate_system:"ESRI:103520", label: "103520:NAD_1983_CORS96_StatePlane_South_Carolina_FIPS_3900"},
  {coordinate_system:"ESRI:103521", label: "103521:NAD_1983_CORS96_StatePlane_South_Carolina_FIPS_3900_Ft_Intl"},
  {coordinate_system:"ESRI:103522", label: "103522:NAD_1983_CORS96_StatePlane_South_Dakota_North_FIPS_4001"},
  {coordinate_system:"ESRI:103523", label: "103523:NAD_1983_CORS96_StatePlane_South_Dakota_South_FIPS_4002"},
  {coordinate_system:"ESRI:103524", label: "103524:NAD_1983_CORS96_StatePlane_South_Dakota_North_FIPS_4001_Ft_US"},
  {coordinate_system:"ESRI:103525", label: "103525:NAD_1983_CORS96_StatePlane_South_Dakota_South_FIPS_4002_Ft_US"},
  {coordinate_system:"ESRI:103526", label: "103526:NAD_1983_CORS96_StatePlane_Tennessee_FIPS_4100"},
  {coordinate_system:"ESRI:103527", label: "103527:NAD_1983_CORS96_StatePlane_Tennessee_FIPS_4100_Ft_US"},
  {coordinate_system:"ESRI:103539", label: "103539:NAD_1983_CORS96_StatePlane_Texas_North_FIPS_4201"},
  {coordinate_system:"ESRI:103540", label: "103540:NAD_1983_CORS96_StatePlane_Texas_North_Central_FIPS_4202"},
  {coordinate_system:"ESRI:103541", label: "103541:NAD_1983_CORS96_StatePlane_Texas_Central_FIPS_4203"},
  {coordinate_system:"ESRI:103542", label: "103542:NAD_1983_CORS96_StatePlane_Texas_South_Central_FIPS_4204"},
  {coordinate_system:"ESRI:103543", label: "103543:NAD_1983_CORS96_StatePlane_Texas_South_FIPS_4205"},
  {coordinate_system:"ESRI:103544", label: "103544:NAD_1983_CORS96_StatePlane_Texas_North_FIPS_4201_Ft_US"},
  {coordinate_system:"ESRI:103545", label: "103545:NAD_1983_CORS96_StatePlane_Texas_North_Central_FIPS_4202_FtUS"},
  {coordinate_system:"ESRI:103546", label: "103546:NAD_1983_CORS96_StatePlane_Texas_Central_FIPS_4203_Ft_US"},
  {coordinate_system:"ESRI:103547", label: "103547:NAD_1983_CORS96_StatePlane_Texas_South_Central_FIPS_4204_FtUS"},
  {coordinate_system:"ESRI:103548", label: "103548:NAD_1983_CORS96_StatePlane_Texas_South_FIPS_4205_Ft_US"},
  {coordinate_system:"ESRI:103549", label: "103549:NAD_1983_CORS96_StatePlane_Utah_North_FIPS_4301"},
  {coordinate_system:"ESRI:103550", label: "103550:NAD_1983_CORS96_StatePlane_Utah_Central_FIPS_4302"},
  {coordinate_system:"ESRI:103551", label: "103551:NAD_1983_CORS96_StatePlane_Utah_South_FIPS_4303"},
  {coordinate_system:"ESRI:103552", label: "103552:NAD_1983_CORS96_StatePlane_Utah_North_FIPS_4301_Ft_Intl"},
  {coordinate_system:"ESRI:103553", label: "103553:NAD_1983_CORS96_StatePlane_Utah_Central_FIPS_4302_Ft_Intl"},
  {coordinate_system:"ESRI:103554", label: "103554:NAD_1983_CORS96_StatePlane_Utah_South_FIPS_4303_Ft_Intl"},
  {coordinate_system:"ESRI:103555", label: "103555:NAD_1983_CORS96_StatePlane_Utah_North_FIPS_4301_Ft_US"},
  {coordinate_system:"ESRI:103556", label: "103556:NAD_1983_CORS96_StatePlane_Utah_Central_FIPS_4302_Ft_US"},
  {coordinate_system:"ESRI:103557", label: "103557:NAD_1983_CORS96_StatePlane_Utah_South_FIPS_4303_Ft_US"},
  {coordinate_system:"ESRI:103558", label: "103558:NAD_1983_CORS96_StatePlane_Vermont_FIPS_4400"},
  {coordinate_system:"ESRI:103559", label: "103559:NAD_1983_CORS96_StatePlane_Virginia_North_FIPS_4501"},
  {coordinate_system:"ESRI:103560", label: "103560:NAD_1983_CORS96_StatePlane_Virginia_South_FIPS_4502"},
  {coordinate_system:"ESRI:103561", label: "103561:NAD_1983_CORS96_StatePlane_Virginia_North_FIPS_4501_Ft_US"},
  {coordinate_system:"ESRI:103562", label: "103562:NAD_1983_CORS96_StatePlane_Virginia_South_FIPS_4502_Ft_US"},
  {coordinate_system:"ESRI:103563", label: "103563:NAD_1983_CORS96_StatePlane_Washington_North_FIPS_4601"},
  {coordinate_system:"ESRI:103564", label: "103564:NAD_1983_CORS96_StatePlane_Washington_South_FIPS_4602"},
  {coordinate_system:"ESRI:103565", label: "103565:NAD_1983_CORS96_StatePlane_Washington_North_FIPS_4601_Ft_US"},
  {coordinate_system:"ESRI:103566", label: "103566:NAD_1983_CORS96_StatePlane_Washington_South_FIPS_4602_Ft_US"},
  {coordinate_system:"ESRI:103567", label: "103567:NAD_1983_CORS96_StatePlane_West_Virginia_North_FIPS_4701"},
  {coordinate_system:"ESRI:103568", label: "103568:NAD_1983_CORS96_StatePlane_West_Virginia_South_FIPS_4702"},
  {coordinate_system:"ESRI:103569", label: "103569:NAD_1983_CORS96_StatePlane_West_Virginia_North_FIPS_4701_FtUS"},
  {coordinate_system:"ESRI:103570", label: "103570:NAD_1983_CORS96_StatePlane_West_Virginia_South_FIPS_4702_FtUS"},
  {coordinate_system:"ESRI:103571", label: "103571:NAD_1983_CORS96_StatePlane_Wisconsin_North_FIPS_4801"},
  {coordinate_system:"ESRI:103572", label: "103572:NAD_1983_CORS96_StatePlane_Wisconsin_Central_FIPS_4802"},
  {coordinate_system:"ESRI:103573", label: "103573:NAD_1983_CORS96_StatePlane_Wisconsin_South_FIPS_4803"},
  {coordinate_system:"ESRI:103574", label: "103574:NAD_1983_CORS96_StatePlane_Wisconsin_North_FIPS_4801_Ft_US"},
  {coordinate_system:"ESRI:103575", label: "103575:NAD_1983_CORS96_StatePlane_Wisconsin_Central_FIPS_4802_Ft_US"},
  {coordinate_system:"ESRI:103576", label: "103576:NAD_1983_CORS96_StatePlane_Wisconsin_South_FIPS_4803_Ft_US"},
  {coordinate_system:"ESRI:103577", label: "103577:NAD_1983_CORS96_StatePlane_Wyoming_East_FIPS_4901"},
  {coordinate_system:"ESRI:103578", label: "103578:NAD_1983_CORS96_StatePlane_Wyoming_East_Central_FIPS_4902"},
  {coordinate_system:"ESRI:103579", label: "103579:NAD_1983_CORS96_StatePlane_Wyoming_West_Central_FIPS_4903"},
  {coordinate_system:"ESRI:103580", label: "103580:NAD_1983_CORS96_StatePlane_Wyoming_West_FIPS_4904"},
  {coordinate_system:"ESRI:103581", label: "103581:NAD_1983_CORS96_StatePlane_Wyoming_East_FIPS_4901_Ft_US"},
  {coordinate_system:"ESRI:103582", label: "103582:NAD_1983_CORS96_StatePlane_Wyoming_E_Central_FIPS_4902_Ft_US"},
  {coordinate_system:"ESRI:103583", label: "103583:NAD_1983_CORS96_StatePlane_Wyoming_W_Central_FIPS_4903_Ft_US"},
  {coordinate_system:"ESRI:103585", label: "103585:NAD_1983_CORS96_StatePlane_Wyoming_West_FIPS_4904_Ft_US"},
  {coordinate_system:"ESRI:103586", label: "103586:NAD_1983_HARN_Navajo_Nation_Coordinate_System_Meters"},
  {coordinate_system:"ESRI:103587", label: "103587:NAD_1983_HARN_Navajo_Nation_Coordinate_System_US_Feet"},
  {coordinate_system:"ESRI:103588", label: "103588:NAD_1983_HARN_Navajo_Nation_Coordinate_System_Intl_Feet"},
  {coordinate_system:"ESRI:103589", label: "103589:NAD_1983_NSRS2007_Navajo_Nation_Coordinate_System_Meters"},
  {coordinate_system:"ESRI:103590", label: "103590:NAD_1983_NSRS2007_Navajo_Nation_Coordinate_System_US_Feet"},
  {coordinate_system:"ESRI:103591", label: "103591:NAD_1983_NSRS2007_Navajo_Nation_Coordinate_System_Intl_Feet"},
  {coordinate_system:"ESRI:103592", label: "103592:NAD_1983_(2011)_Navajo_Nation_Coordinate_System_Meters"},
  {coordinate_system:"ESRI:103593", label: "103593:NAD_1983_(2011)_Navajo_Nation_Coordinate_System_US_Feet"},
  {coordinate_system:"ESRI:103594", label: "103594:NAD_1983_(2011)_Navajo_Nation_Coordinate_System_Intl_Feet"},
  {coordinate_system:"ESRI:103598", label: "103598:GTM"},
  {coordinate_system:"ESRI:103599", label: "103599:MAGNA-SIRGAS_CMT12"},
  {coordinate_system:"ESRI:103600", label: "103600:NAD_1983_HARN_Adj_MN_Aitkin_Meters"},
  {coordinate_system:"ESRI:103601", label: "103601:NAD_1983_HARN_Adj_MN_Clay_Meters"},
  {coordinate_system:"ESRI:103602", label: "103602:NAD_1983_HARN_Adj_MN_Clearwater_Meters"},
  {coordinate_system:"ESRI:103603", label: "103603:NAD_1983_HARN_Adj_MN_Hubbard_Meters"},
  {coordinate_system:"ESRI:103604", label: "103604:NAD_1983_HARN_Adj_MN_Lake_Meters"},
  {coordinate_system:"ESRI:103605", label: "103605:NAD_1983_HARN_Adj_MN_Mille_Lacs_Meters"},
  {coordinate_system:"ESRI:103606", label: "103606:NAD_1983_HARN_Adj_MN_Washington_Meters"},
  {coordinate_system:"ESRI:103607", label: "103607:NAD_1983_HARN_Adj_MN_Wilkin_Meters"},
  {coordinate_system:"ESRI:103608", label: "103608:NAD_1983_HARN_Adj_MN_Anoka_Meters"},
  {coordinate_system:"ESRI:103609", label: "103609:NAD_1983_HARN_Adj_MN_Becker_Meters"},
  {coordinate_system:"ESRI:103610", label: "103610:NAD_1983_HARN_Adj_MN_Beltrami_North_Meters"},
  {coordinate_system:"ESRI:103611", label: "103611:NAD_1983_HARN_Adj_MN_Beltrami_South_Meters"},
  {coordinate_system:"ESRI:103612", label: "103612:NAD_1983_HARN_Adj_MN_Benton_Meters"},
  {coordinate_system:"ESRI:103613", label: "103613:NAD_1983_HARN_Adj_MN_Big_Stone_Meters"},
  {coordinate_system:"ESRI:103614", label: "103614:NAD_1983_HARN_Adj_MN_Blue_Earth_Meters"},
  {coordinate_system:"ESRI:103615", label: "103615:NAD_1983_HARN_Adj_MN_Brown_Meters"},
  {coordinate_system:"ESRI:103616", label: "103616:NAD_1983_HARN_Adj_MN_Carlton_Meters"},
  {coordinate_system:"ESRI:103617", label: "103617:NAD_1983_HARN_Adj_MN_Carver_Meters"},
  {coordinate_system:"ESRI:103618", label: "103618:NAD_1983_HARN_Adj_MN_Cass_North_Meters"},
  {coordinate_system:"ESRI:103619", label: "103619:NAD_1983_HARN_Adj_MN_Cass_South_Meters"},
  {coordinate_system:"ESRI:103620", label: "103620:NAD_1983_HARN_Adj_MN_Chippewa_Meters"},
  {coordinate_system:"ESRI:103621", label: "103621:NAD_1983_HARN_Adj_MN_Chisago_Meters"},
  {coordinate_system:"ESRI:103622", label: "103622:NAD_1983_HARN_Adj_MN_Cook_North_Meters"},
  {coordinate_system:"ESRI:103623", label: "103623:NAD_1983_HARN_Adj_MN_Cook_South_Meters"},
  {coordinate_system:"ESRI:103624", label: "103624:NAD_1983_HARN_Adj_MN_Cottonwood_Meters"},
  {coordinate_system:"ESRI:103625", label: "103625:NAD_1983_HARN_Adj_MN_Crow_Wing_Meters"},
  {coordinate_system:"ESRI:103626", label: "103626:NAD_1983_HARN_Adj_MN_Dakota_Meters"},
  {coordinate_system:"ESRI:103627", label: "103627:NAD_1983_HARN_Adj_MN_Dodge_Meters"},
  {coordinate_system:"ESRI:103628", label: "103628:NAD_1983_HARN_Adj_MN_Douglas_Meters"},
  {coordinate_system:"ESRI:103629", label: "103629:NAD_1983_HARN_Adj_MN_Faribault_Meters"},
  {coordinate_system:"ESRI:103630", label: "103630:NAD_1983_HARN_Adj_MN_Fillmore_Meters"},
  {coordinate_system:"ESRI:103631", label: "103631:NAD_1983_HARN_Adj_MN_Freeborn_Meters"},
  {coordinate_system:"ESRI:103632", label: "103632:NAD_1983_HARN_Adj_MN_Goodhue_Meters"},
  {coordinate_system:"ESRI:103633", label: "103633:NAD_1983_HARN_Adj_MN_Grant_Meters"},
  {coordinate_system:"ESRI:103634", label: "103634:NAD_1983_HARN_Adj_MN_Hennepin_Meters"},
  {coordinate_system:"ESRI:103635", label: "103635:NAD_1983_HARN_Adj_MN_Houston_Meters"},
  {coordinate_system:"ESRI:103636", label: "103636:NAD_1983_HARN_Adj_MN_Isanti_Meters"},
  {coordinate_system:"ESRI:103637", label: "103637:NAD_1983_HARN_Adj_MN_Itasca_North_Meters"},
  {coordinate_system:"ESRI:103638", label: "103638:NAD_1983_HARN_Adj_MN_Itasca_South_Meters"},
  {coordinate_system:"ESRI:103639", label: "103639:NAD_1983_HARN_Adj_MN_Jackson_Meters"},
  {coordinate_system:"ESRI:103640", label: "103640:NAD_1983_HARN_Adj_MN_Kanabec_Meters"},
  {coordinate_system:"ESRI:103641", label: "103641:NAD_1983_HARN_Adj_MN_Kandiyohi_Meters"},
  {coordinate_system:"ESRI:103642", label: "103642:NAD_1983_HARN_Adj_MN_Kittson_Meters"},
  {coordinate_system:"ESRI:103643", label: "103643:NAD_1983_HARN_Adj_MN_Koochiching_Meters"},
  {coordinate_system:"ESRI:103644", label: "103644:NAD_1983_HARN_Adj_MN_Lac_Qui_Parle_Meters"},
  {coordinate_system:"ESRI:103645", label: "103645:NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North_Meters"},
  {coordinate_system:"ESRI:103646", label: "103646:NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South_Meters"},
  {coordinate_system:"ESRI:103647", label: "103647:NAD_1983_HARN_Adj_MN_Le_Sueur_Meters"},
  {coordinate_system:"ESRI:103648", label: "103648:NAD_1983_HARN_Adj_MN_Lincoln_Meters"},
  {coordinate_system:"ESRI:103649", label: "103649:NAD_1983_HARN_Adj_MN_Lyon_Meters"},
  {coordinate_system:"ESRI:103650", label: "103650:NAD_1983_HARN_Adj_MN_McLeod_Meters"},
  {coordinate_system:"ESRI:103651", label: "103651:NAD_1983_HARN_Adj_MN_Mahnomen_Meters"},
  {coordinate_system:"ESRI:103652", label: "103652:NAD_1983_HARN_Adj_MN_Marshall_Meters"},
  {coordinate_system:"ESRI:103653", label: "103653:NAD_1983_HARN_Adj_MN_Martin_Meters"},
  {coordinate_system:"ESRI:103654", label: "103654:NAD_1983_HARN_Adj_MN_Meeker_Meters"},
  {coordinate_system:"ESRI:103655", label: "103655:NAD_1983_HARN_Adj_MN_Morrison_Meters"},
  {coordinate_system:"ESRI:103656", label: "103656:NAD_1983_HARN_Adj_MN_Mower_Meters"},
  {coordinate_system:"ESRI:103657", label: "103657:NAD_1983_HARN_Adj_MN_Murray_Meters"},
  {coordinate_system:"ESRI:103658", label: "103658:NAD_1983_HARN_Adj_MN_Nicollet_Meters"},
  {coordinate_system:"ESRI:103659", label: "103659:NAD_1983_HARN_Adj_MN_Nobles_Meters"},
  {coordinate_system:"ESRI:103660", label: "103660:NAD_1983_HARN_Adj_MN_Norman_Meters"},
  {coordinate_system:"ESRI:103661", label: "103661:NAD_1983_HARN_Adj_MN_Olmsted_Meters"},
  {coordinate_system:"ESRI:103662", label: "103662:NAD_1983_HARN_Adj_MN_Ottertail_Meters"},
  {coordinate_system:"ESRI:103663", label: "103663:NAD_1983_HARN_Adj_MN_Pennington_Meters"},
  {coordinate_system:"ESRI:103664", label: "103664:NAD_1983_HARN_Adj_MN_Pine_Meters"},
  {coordinate_system:"ESRI:103665", label: "103665:NAD_1983_HARN_Adj_MN_Pipestone_Meters"},
  {coordinate_system:"ESRI:103666", label: "103666:NAD_1983_HARN_Adj_MN_Polk_Meters"},
  {coordinate_system:"ESRI:103667", label: "103667:NAD_1983_HARN_Adj_MN_Pope_Meters"},
  {coordinate_system:"ESRI:103668", label: "103668:NAD_1983_HARN_Adj_MN_Ramsey_Meters"},
  {coordinate_system:"ESRI:103669", label: "103669:NAD_1983_HARN_Adj_MN_Red_Lake_Meters"},
  {coordinate_system:"ESRI:103670", label: "103670:NAD_1983_HARN_Adj_MN_Redwood_Meters"},
  {coordinate_system:"ESRI:103671", label: "103671:NAD_1983_HARN_Adj_MN_Renville_Meters"},
  {coordinate_system:"ESRI:103672", label: "103672:NAD_1983_HARN_Adj_MN_Rice_Meters"},
  {coordinate_system:"ESRI:103673", label: "103673:NAD_1983_HARN_Adj_MN_Rock_Meters"},
  {coordinate_system:"ESRI:103674", label: "103674:NAD_1983_HARN_Adj_MN_Roseau_Meters"},
  {coordinate_system:"ESRI:103675", label: "103675:NAD_1983_HARN_Adj_MN_St_Louis_North_Meters"},
  {coordinate_system:"ESRI:103676", label: "103676:NAD_1983_HARN_Adj_MN_St_Louis_Central_Meters"},
  {coordinate_system:"ESRI:103677", label: "103677:NAD_1983_HARN_Adj_MN_St_Louis_South_Meters"},
  {coordinate_system:"ESRI:103678", label: "103678:NAD_1983_HARN_Adj_MN_Scott_Meters"},
  {coordinate_system:"ESRI:103679", label: "103679:NAD_1983_HARN_Adj_MN_Sherburne_Meters"},
  {coordinate_system:"ESRI:103680", label: "103680:NAD_1983_HARN_Adj_MN_Sibley_Meters"},
  {coordinate_system:"ESRI:103681", label: "103681:NAD_1983_HARN_Adj_MN_Stearns_Meters"},
  {coordinate_system:"ESRI:103682", label: "103682:NAD_1983_HARN_Adj_MN_Steele_Meters"},
  {coordinate_system:"ESRI:103683", label: "103683:NAD_1983_HARN_Adj_MN_Stevens_Meters"},
  {coordinate_system:"ESRI:103684", label: "103684:NAD_1983_HARN_Adj_MN_Swift_Meters"},
  {coordinate_system:"ESRI:103685", label: "103685:NAD_1983_HARN_Adj_MN_Todd_Meters"},
  {coordinate_system:"ESRI:103686", label: "103686:NAD_1983_HARN_Adj_MN_Traverse_Meters"},
  {coordinate_system:"ESRI:103687", label: "103687:NAD_1983_HARN_Adj_MN_Wabasha_Meters"},
  {coordinate_system:"ESRI:103688", label: "103688:NAD_1983_HARN_Adj_MN_Wadena_Meters"},
  {coordinate_system:"ESRI:103689", label: "103689:NAD_1983_HARN_Adj_MN_Waseca_Meters"},
  {coordinate_system:"ESRI:103690", label: "103690:NAD_1983_HARN_Adj_MN_Watonwan_Meters"},
  {coordinate_system:"ESRI:103691", label: "103691:NAD_1983_HARN_Adj_MN_Winona_Meters"},
  {coordinate_system:"ESRI:103692", label: "103692:NAD_1983_HARN_Adj_MN_Wright_Meters"},
  {coordinate_system:"ESRI:103693", label: "103693:NAD_1983_HARN_Adj_MN_Yellow_Medicine_Meters"},
  {coordinate_system:"ESRI:103694", label: "103694:NAD_1983_HARN_Adj_MN_St_Louis_CS96_Meters"},
  {coordinate_system:"ESRI:103695", label: "103695:NAD_1983_HARN_Adj_MN_St_Louis_CS96_Feet"},
  {coordinate_system:"ESRI:103696", label: "103696:NAD_1983_(CSRS)_v6_UTM_Zone_19N"},
  {coordinate_system:"ESRI:103697", label: "103697:NAD_1983_(CSRS)_v6_UTM_Zone_20N"},
  {coordinate_system:"ESRI:103698", label: "103698:NAD_1983_(CSRS)_v6_UTM_Zone_21N"},
  {coordinate_system:"ESRI:103699", label: "103699:Palestine_Grid_1923_Modified_TM"},
  {coordinate_system:"ESRI:103700", label: "103700:NAD_1983_HARN_Adj_MN_Aitkin_Feet"},
  {coordinate_system:"ESRI:103701", label: "103701:NAD_1983_HARN_Adj_MN_Clay_Feet"},
  {coordinate_system:"ESRI:103702", label: "103702:NAD_1983_HARN_Adj_MN_Clearwater_Feet"},
  {coordinate_system:"ESRI:103703", label: "103703:NAD_1983_HARN_Adj_MN_Hubbard_Feet"},
  {coordinate_system:"ESRI:103704", label: "103704:NAD_1983_HARN_Adj_MN_Lake_Feet"},
  {coordinate_system:"ESRI:103705", label: "103705:NAD_1983_HARN_Adj_MN_Mille_Lacs_Feet"},
  {coordinate_system:"ESRI:103706", label: "103706:NAD_1983_HARN_Adj_MN_Washington_Feet"},
  {coordinate_system:"ESRI:103707", label: "103707:NAD_1983_HARN_Adj_MN_Wilkin_Feet"},
  {coordinate_system:"ESRI:103708", label: "103708:NAD_1983_HARN_Adj_MN_Anoka_Feet"},
  {coordinate_system:"ESRI:103709", label: "103709:NAD_1983_HARN_Adj_MN_Becker_Feet"},
  {coordinate_system:"ESRI:103710", label: "103710:NAD_1983_HARN_Adj_MN_Beltrami_North_Feet"},
  {coordinate_system:"ESRI:103711", label: "103711:NAD_1983_HARN_Adj_MN_Beltrami_South_Feet"},
  {coordinate_system:"ESRI:103712", label: "103712:NAD_1983_HARN_Adj_MN_Benton_Feet"},
  {coordinate_system:"ESRI:103713", label: "103713:NAD_1983_HARN_Adj_MN_Big_Stone_Feet"},
  {coordinate_system:"ESRI:103714", label: "103714:NAD_1983_HARN_Adj_MN_Blue_Earth_Feet"},
  {coordinate_system:"ESRI:103715", label: "103715:NAD_1983_HARN_Adj_MN_Brown_Feet"},
  {coordinate_system:"ESRI:103716", label: "103716:NAD_1983_HARN_Adj_MN_Carlton_Feet"},
  {coordinate_system:"ESRI:103717", label: "103717:NAD_1983_HARN_Adj_MN_Carver_Feet"},
  {coordinate_system:"ESRI:103718", label: "103718:NAD_1983_HARN_Adj_MN_Cass_North_Feet"},
  {coordinate_system:"ESRI:103719", label: "103719:NAD_1983_HARN_Adj_MN_Cass_South_Feet"},
  {coordinate_system:"ESRI:103720", label: "103720:NAD_1983_HARN_Adj_MN_Chippewa_Feet"},
  {coordinate_system:"ESRI:103721", label: "103721:NAD_1983_HARN_Adj_MN_Chisago_Feet"},
  {coordinate_system:"ESRI:103722", label: "103722:NAD_1983_HARN_Adj_MN_Cook_North_Feet"},
  {coordinate_system:"ESRI:103723", label: "103723:NAD_1983_HARN_Adj_MN_Cook_South_Feet"},
  {coordinate_system:"ESRI:103724", label: "103724:NAD_1983_HARN_Adj_MN_Cottonwood_Feet"},
  {coordinate_system:"ESRI:103725", label: "103725:NAD_1983_HARN_Adj_MN_Crow_Wing_Feet"},
  {coordinate_system:"ESRI:103726", label: "103726:NAD_1983_HARN_Adj_MN_Dakota_Feet"},
  {coordinate_system:"ESRI:103727", label: "103727:NAD_1983_HARN_Adj_MN_Dodge_Feet"},
  {coordinate_system:"ESRI:103728", label: "103728:NAD_1983_HARN_Adj_MN_Douglas_Feet"},
  {coordinate_system:"ESRI:103729", label: "103729:NAD_1983_HARN_Adj_MN_Faribault_Feet"},
  {coordinate_system:"ESRI:103730", label: "103730:NAD_1983_HARN_Adj_MN_Fillmore_Feet"},
  {coordinate_system:"ESRI:103731", label: "103731:NAD_1983_HARN_Adj_MN_Freeborn_Feet"},
  {coordinate_system:"ESRI:103732", label: "103732:NAD_1983_HARN_Adj_MN_Goodhue_Feet"},
  {coordinate_system:"ESRI:103733", label: "103733:NAD_1983_HARN_Adj_MN_Grant_Feet"},
  {coordinate_system:"ESRI:103734", label: "103734:NAD_1983_HARN_Adj_MN_Hennepin_Feet"},
  {coordinate_system:"ESRI:103735", label: "103735:NAD_1983_HARN_Adj_MN_Houston_Feet"},
  {coordinate_system:"ESRI:103736", label: "103736:NAD_1983_HARN_Adj_MN_Isanti_Feet"},
  {coordinate_system:"ESRI:103737", label: "103737:NAD_1983_HARN_Adj_MN_Itasca_North_Feet"},
  {coordinate_system:"ESRI:103738", label: "103738:NAD_1983_HARN_Adj_MN_Itasca_South_Feet"},
  {coordinate_system:"ESRI:103739", label: "103739:NAD_1983_HARN_Adj_MN_Jackson_Feet"},
  {coordinate_system:"ESRI:103740", label: "103740:NAD_1983_HARN_Adj_MN_Kanabec_Feet"},
  {coordinate_system:"ESRI:103741", label: "103741:NAD_1983_HARN_Adj_MN_Kandiyohi_Feet"},
  {coordinate_system:"ESRI:103742", label: "103742:NAD_1983_HARN_Adj_MN_Kittson_Feet"},
  {coordinate_system:"ESRI:103743", label: "103743:NAD_1983_HARN_Adj_MN_Koochiching_Feet"},
  {coordinate_system:"ESRI:103744", label: "103744:NAD_1983_HARN_Adj_MN_Lac_Qui_Parle_Feet"},
  {coordinate_system:"ESRI:103745", label: "103745:NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North_Feet"},
  {coordinate_system:"ESRI:103746", label: "103746:NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South_Feet"},
  {coordinate_system:"ESRI:103747", label: "103747:NAD_1983_HARN_Adj_MN_Le_Sueur_Feet"},
  {coordinate_system:"ESRI:103748", label: "103748:NAD_1983_HARN_Adj_MN_Lincoln_Feet"},
  {coordinate_system:"ESRI:103749", label: "103749:NAD_1983_HARN_Adj_MN_Lyon_Feet"},
  {coordinate_system:"ESRI:103750", label: "103750:NAD_1983_HARN_Adj_MN_McLeod_Feet"},
  {coordinate_system:"ESRI:103751", label: "103751:NAD_1983_HARN_Adj_MN_Mahnomen_Feet"},
  {coordinate_system:"ESRI:103752", label: "103752:NAD_1983_HARN_Adj_MN_Marshall_Feet"},
  {coordinate_system:"ESRI:103753", label: "103753:NAD_1983_HARN_Adj_MN_Martin_Feet"},
  {coordinate_system:"ESRI:103754", label: "103754:NAD_1983_HARN_Adj_MN_Meeker_Feet"},
  {coordinate_system:"ESRI:103755", label: "103755:NAD_1983_HARN_Adj_MN_Morrison_Feet"},
  {coordinate_system:"ESRI:103756", label: "103756:NAD_1983_HARN_Adj_MN_Mower_Feet"},
  {coordinate_system:"ESRI:103757", label: "103757:NAD_1983_HARN_Adj_MN_Murray_Feet"},
  {coordinate_system:"ESRI:103758", label: "103758:NAD_1983_HARN_Adj_MN_Nicollet_Feet"},
  {coordinate_system:"ESRI:103759", label: "103759:NAD_1983_HARN_Adj_MN_Nobles_Feet"},
  {coordinate_system:"ESRI:103760", label: "103760:NAD_1983_HARN_Adj_MN_Norman_Feet"},
  {coordinate_system:"ESRI:103761", label: "103761:NAD_1983_HARN_Adj_MN_Olmsted_Feet"},
  {coordinate_system:"ESRI:103762", label: "103762:NAD_1983_HARN_Adj_MN_Ottertail_Feet"},
  {coordinate_system:"ESRI:103763", label: "103763:NAD_1983_HARN_Adj_MN_Pennington_Feet"},
  {coordinate_system:"ESRI:103764", label: "103764:NAD_1983_HARN_Adj_MN_Pine_Feet"},
  {coordinate_system:"ESRI:103765", label: "103765:NAD_1983_HARN_Adj_MN_Pipestone_Feet"},
  {coordinate_system:"ESRI:103766", label: "103766:NAD_1983_HARN_Adj_MN_Polk_Feet"},
  {coordinate_system:"ESRI:103767", label: "103767:NAD_1983_HARN_Adj_MN_Pope_Feet"},
  {coordinate_system:"ESRI:103768", label: "103768:NAD_1983_HARN_Adj_MN_Ramsey_Feet"},
  {coordinate_system:"ESRI:103769", label: "103769:NAD_1983_HARN_Adj_MN_Red_Lake_Feet"},
  {coordinate_system:"ESRI:103770", label: "103770:NAD_1983_HARN_Adj_MN_Redwood_Feet"},
  {coordinate_system:"ESRI:103771", label: "103771:NAD_1983_HARN_Adj_MN_Renville_Feet"},
  {coordinate_system:"ESRI:103772", label: "103772:NAD_1983_HARN_Adj_MN_Rice_Feet"},
  {coordinate_system:"ESRI:103773", label: "103773:NAD_1983_HARN_Adj_MN_Rock_Feet"},
  {coordinate_system:"ESRI:103774", label: "103774:NAD_1983_HARN_Adj_MN_Roseau_Feet"},
  {coordinate_system:"ESRI:103775", label: "103775:NAD_1983_HARN_Adj_MN_St_Louis_North_Feet"},
  {coordinate_system:"ESRI:103776", label: "103776:NAD_1983_HARN_Adj_MN_St_Louis_Central_Feet"},
  {coordinate_system:"ESRI:103777", label: "103777:NAD_1983_HARN_Adj_MN_St_Louis_South_Feet"},
  {coordinate_system:"ESRI:103778", label: "103778:NAD_1983_HARN_Adj_MN_Scott_Feet"},
  {coordinate_system:"ESRI:103779", label: "103779:NAD_1983_HARN_Adj_MN_Sherburne_Feet"},
  {coordinate_system:"ESRI:103780", label: "103780:NAD_1983_HARN_Adj_MN_Sibley_Feet"},
  {coordinate_system:"ESRI:103781", label: "103781:NAD_1983_HARN_Adj_MN_Stearns_Feet"},
  {coordinate_system:"ESRI:103782", label: "103782:NAD_1983_HARN_Adj_MN_Steele_Feet"},
  {coordinate_system:"ESRI:103783", label: "103783:NAD_1983_HARN_Adj_MN_Stevens_Feet"},
  {coordinate_system:"ESRI:103784", label: "103784:NAD_1983_HARN_Adj_MN_Swift_Feet"},
  {coordinate_system:"ESRI:103785", label: "103785:NAD_1983_HARN_Adj_MN_Todd_Feet"},
  {coordinate_system:"ESRI:103786", label: "103786:NAD_1983_HARN_Adj_MN_Traverse_Feet"},
  {coordinate_system:"ESRI:103787", label: "103787:NAD_1983_HARN_Adj_MN_Wabasha_Feet"},
  {coordinate_system:"ESRI:103788", label: "103788:NAD_1983_HARN_Adj_MN_Wadena_Feet"},
  {coordinate_system:"ESRI:103789", label: "103789:NAD_1983_HARN_Adj_MN_Waseca_Feet"},
  {coordinate_system:"ESRI:103790", label: "103790:NAD_1983_HARN_Adj_MN_Watonwan_Feet"},
  {coordinate_system:"ESRI:103791", label: "103791:NAD_1983_HARN_Adj_MN_Winona_Feet"},
  {coordinate_system:"ESRI:103792", label: "103792:NAD_1983_HARN_Adj_MN_Wright_Feet"},
  {coordinate_system:"ESRI:103793", label: "103793:NAD_1983_HARN_Adj_MN_Yellow_Medicine_Feet"},
  {coordinate_system:"ESRI:103846", label: "103846:NAD_1983_HARN_Adj_WI_Chippewa_Meters"},
  {coordinate_system:"ESRI:103872", label: "103872:GDM2008_LGM2012"},
  {coordinate_system:"ESRI:103873", label: "103873:NAD_1983_(CSRS)_v6_UTM_Zone_15N"},
  {coordinate_system:"ESRI:103874", label: "103874:NAD_1983_(CSRS)_v6_UTM_Zone_16N"},
  {coordinate_system:"ESRI:103875", label: "103875:NAD_1983_(CSRS)_v6_UTM_Zone_17N"},
  {coordinate_system:"ESRI:103876", label: "103876:NAD_1983_(CSRS)_v6_UTM_Zone_18N"},
  {coordinate_system:"ESRI:103877", label: "103877:Moon_2000_North_Pole_Stereographic"},
  {coordinate_system:"ESRI:103878", label: "103878:Moon_2000_South_Pole_Stereographic"},
  {coordinate_system:"ESRI:103879", label: "103879:Moon_2000_Far_Side_Lambert_Azimuthal_Equal_Area"},
  {coordinate_system:"ESRI:103880", label: "103880:Moon_2000_Near_Side_Lambert_Azimuthal_Equal_Area"},
  {coordinate_system:"ESRI:103881", label: "103881:Moon_2000_Equidistant_Cylindrical"},
  {coordinate_system:"ESRI:103882", label: "103882:Moon_2000_Sinusoidal"},
  {coordinate_system:"ESRI:103883", label: "103883:Mars_2000_North_Pole_Stereographic_sphere"},
  {coordinate_system:"ESRI:103884", label: "103884:Mars_2000_South_Pole_Stereographic_sphere"},
  {coordinate_system:"ESRI:103885", label: "103885:Mars_2000_Equidistant_Cylindrical_sphere"},
  {coordinate_system:"ESRI:103886", label: "103886:Mars_2000_Sinusoidal_sphere"},
  {coordinate_system:"ESRI:103887", label: "103887:Mars_2000_Winkel_Tripel_sphere"},
  {coordinate_system:"ESRI:103946", label: "103946:NAD_1983_HARN_Adj_WI_Chippewa_Feet"},
  {coordinate_system:"IAU_2015:1010", label: "1010:Sun (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:1015", label: "1015:Sun (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:1020", label: "1020:Sun (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:1025", label: "1025:Sun (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:1030", label: "1030:Sun (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:1035", label: "1035:Sun (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:1040", label: "1040:Sun (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:1045", label: "1045:Sun (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:1050", label: "1050:Sun (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:1055", label: "1055:Sun (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:1060", label: "1060:Sun (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:1065", label: "1065:Sun (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:1070", label: "1070:Sun (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:1075", label: "1075:Sun (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:1080", label: "1080:Sun (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:1085", label: "1085:Sun (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:19910", label: "19910:Mercury (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:19911", label: "19911:Mercury (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:19912", label: "19912:Mercury (2015) / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:19915", label: "19915:Mercury (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:19916", label: "19916:Mercury (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:19917", label: "19917:Mercury (2015) / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:19920", label: "19920:Mercury (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:19921", label: "19921:Mercury (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:19922", label: "19922:Mercury (2015) / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:19925", label: "19925:Mercury (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:19926", label: "19926:Mercury (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:19927", label: "19927:Mercury (2015) / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:19930", label: "19930:Mercury (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:19931", label: "19931:Mercury (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:19932", label: "19932:Mercury (2015) / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:19935", label: "19935:Mercury (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:19936", label: "19936:Mercury (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:19937", label: "19937:Mercury (2015) / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:19940", label: "19940:Mercury (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:19941", label: "19941:Mercury (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:19942", label: "19942:Mercury (2015) / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:19945", label: "19945:Mercury (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:19946", label: "19946:Mercury (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:19947", label: "19947:Mercury (2015) / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:19950", label: "19950:Mercury (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:19951", label: "19951:Mercury (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:19952", label: "19952:Mercury (2015) / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:19955", label: "19955:Mercury (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:19956", label: "19956:Mercury (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:19957", label: "19957:Mercury (2015) / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:19960", label: "19960:Mercury (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:19961", label: "19961:Mercury (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:19962", label: "19962:Mercury (2015) / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:19965", label: "19965:Mercury (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:19966", label: "19966:Mercury (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:19967", label: "19967:Mercury (2015) / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:19970", label: "19970:Mercury (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:19971", label: "19971:Mercury (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:19972", label: "19972:Mercury (2015) / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:19975", label: "19975:Mercury (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:19976", label: "19976:Mercury (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:19977", label: "19977:Mercury (2015) / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:19980", label: "19980:Mercury (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:19981", label: "19981:Mercury (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:19982", label: "19982:Mercury (2015) / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:19985", label: "19985:Mercury (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:19986", label: "19986:Mercury (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:19987", label: "19987:Mercury (2015) / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:29910", label: "29910:Venus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:29915", label: "29915:Venus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:29920", label: "29920:Venus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:29925", label: "29925:Venus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:29930", label: "29930:Venus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:29935", label: "29935:Venus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:29940", label: "29940:Venus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:29945", label: "29945:Venus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:29950", label: "29950:Venus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:29955", label: "29955:Venus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:29960", label: "29960:Venus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:29965", label: "29965:Venus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:29970", label: "29970:Venus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:29975", label: "29975:Venus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:29980", label: "29980:Venus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:29985", label: "29985:Venus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:30110", label: "30110:Moon (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:30115", label: "30115:Moon (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:30120", label: "30120:Moon (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:30125", label: "30125:Moon (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:30130", label: "30130:Moon (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:30135", label: "30135:Moon (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:30140", label: "30140:Moon (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:30145", label: "30145:Moon (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:30150", label: "30150:Moon (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:30155", label: "30155:Moon (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:30160", label: "30160:Moon (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:30165", label: "30165:Moon (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:30170", label: "30170:Moon (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:30175", label: "30175:Moon (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:30180", label: "30180:Moon (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:30185", label: "30185:Moon (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:39910", label: "39910:Earth (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:39911", label: "39911:Earth (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:39915", label: "39915:Earth (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:39916", label: "39916:Earth (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:39920", label: "39920:Earth (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:39921", label: "39921:Earth (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:39925", label: "39925:Earth (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:39926", label: "39926:Earth (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:39930", label: "39930:Earth (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:39931", label: "39931:Earth (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:39935", label: "39935:Earth (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:39936", label: "39936:Earth (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:39940", label: "39940:Earth (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:39941", label: "39941:Earth (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:39945", label: "39945:Earth (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:39946", label: "39946:Earth (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:39950", label: "39950:Earth (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:39951", label: "39951:Earth (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:39955", label: "39955:Earth (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:39956", label: "39956:Earth (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:39960", label: "39960:Earth (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:39961", label: "39961:Earth (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:39965", label: "39965:Earth (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:39966", label: "39966:Earth (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:39970", label: "39970:Earth (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:39971", label: "39971:Earth (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:39975", label: "39975:Earth (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:39976", label: "39976:Earth (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:39980", label: "39980:Earth (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:39981", label: "39981:Earth (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:39985", label: "39985:Earth (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:39986", label: "39986:Earth (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:40110", label: "40110:Phobos (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:40115", label: "40115:Phobos (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:40120", label: "40120:Phobos (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:40125", label: "40125:Phobos (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:40130", label: "40130:Phobos (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:40135", label: "40135:Phobos (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:40140", label: "40140:Phobos (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:40145", label: "40145:Phobos (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:40150", label: "40150:Phobos (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:40155", label: "40155:Phobos (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:40160", label: "40160:Phobos (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:40165", label: "40165:Phobos (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:40170", label: "40170:Phobos (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:40175", label: "40175:Phobos (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:40180", label: "40180:Phobos (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:40185", label: "40185:Phobos (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:40210", label: "40210:Deimos (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:40215", label: "40215:Deimos (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:40220", label: "40220:Deimos (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:40225", label: "40225:Deimos (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:40230", label: "40230:Deimos (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:40235", label: "40235:Deimos (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:40240", label: "40240:Deimos (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:40245", label: "40245:Deimos (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:40250", label: "40250:Deimos (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:40255", label: "40255:Deimos (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:40260", label: "40260:Deimos (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:40265", label: "40265:Deimos (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:40270", label: "40270:Deimos (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:40275", label: "40275:Deimos (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:40280", label: "40280:Deimos (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:40285", label: "40285:Deimos (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:49910", label: "49910:Mars (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:49911", label: "49911:Mars (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:49912", label: "49912:Mars (2015) / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:49915", label: "49915:Mars (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:49916", label: "49916:Mars (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:49917", label: "49917:Mars (2015) / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:49920", label: "49920:Mars (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:49921", label: "49921:Mars (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:49922", label: "49922:Mars (2015) / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:49925", label: "49925:Mars (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:49926", label: "49926:Mars (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:49927", label: "49927:Mars (2015) / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:49930", label: "49930:Mars (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:49931", label: "49931:Mars (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:49932", label: "49932:Mars (2015) / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:49935", label: "49935:Mars (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:49936", label: "49936:Mars (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:49937", label: "49937:Mars (2015) / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:49940", label: "49940:Mars (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:49941", label: "49941:Mars (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:49942", label: "49942:Mars (2015) / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:49945", label: "49945:Mars (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:49946", label: "49946:Mars (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:49947", label: "49947:Mars (2015) / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:49950", label: "49950:Mars (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:49951", label: "49951:Mars (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:49952", label: "49952:Mars (2015) / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:49955", label: "49955:Mars (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:49956", label: "49956:Mars (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:49957", label: "49957:Mars (2015) / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:49960", label: "49960:Mars (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:49961", label: "49961:Mars (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:49962", label: "49962:Mars (2015) / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:49965", label: "49965:Mars (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:49966", label: "49966:Mars (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:49967", label: "49967:Mars (2015) / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:49970", label: "49970:Mars (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:49971", label: "49971:Mars (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:49972", label: "49972:Mars (2015) / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:49975", label: "49975:Mars (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:49976", label: "49976:Mars (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:49977", label: "49977:Mars (2015) / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:49980", label: "49980:Mars (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:49981", label: "49981:Mars (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:49982", label: "49982:Mars (2015) / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:49985", label: "49985:Mars (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:49986", label: "49986:Mars (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:49987", label: "49987:Mars (2015) / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50110", label: "50110:Io (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50115", label: "50115:Io (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50120", label: "50120:Io (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50125", label: "50125:Io (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50130", label: "50130:Io (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50135", label: "50135:Io (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50140", label: "50140:Io (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50145", label: "50145:Io (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50150", label: "50150:Io (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50155", label: "50155:Io (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50160", label: "50160:Io (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50165", label: "50165:Io (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50170", label: "50170:Io (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50175", label: "50175:Io (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50180", label: "50180:Io (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50185", label: "50185:Io (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50210", label: "50210:Europa (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50215", label: "50215:Europa (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50220", label: "50220:Europa (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50225", label: "50225:Europa (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50230", label: "50230:Europa (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50235", label: "50235:Europa (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50240", label: "50240:Europa (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50245", label: "50245:Europa (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50250", label: "50250:Europa (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50255", label: "50255:Europa (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50260", label: "50260:Europa (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50265", label: "50265:Europa (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50270", label: "50270:Europa (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50275", label: "50275:Europa (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50280", label: "50280:Europa (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50285", label: "50285:Europa (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50310", label: "50310:Ganymede (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50311", label: "50311:Ganymede (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50315", label: "50315:Ganymede (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50316", label: "50316:Ganymede (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50320", label: "50320:Ganymede (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50321", label: "50321:Ganymede (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50325", label: "50325:Ganymede (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50326", label: "50326:Ganymede (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50330", label: "50330:Ganymede (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50331", label: "50331:Ganymede (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:50335", label: "50335:Ganymede (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50336", label: "50336:Ganymede (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:50340", label: "50340:Ganymede (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50341", label: "50341:Ganymede (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50345", label: "50345:Ganymede (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50346", label: "50346:Ganymede (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50350", label: "50350:Ganymede (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50351", label: "50351:Ganymede (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50355", label: "50355:Ganymede (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50356", label: "50356:Ganymede (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50360", label: "50360:Ganymede (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50361", label: "50361:Ganymede (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50365", label: "50365:Ganymede (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50366", label: "50366:Ganymede (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50370", label: "50370:Ganymede (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50371", label: "50371:Ganymede (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50375", label: "50375:Ganymede (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50376", label: "50376:Ganymede (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50380", label: "50380:Ganymede (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50381", label: "50381:Ganymede (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50385", label: "50385:Ganymede (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50386", label: "50386:Ganymede (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50410", label: "50410:Callisto (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50411", label: "50411:Callisto (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50415", label: "50415:Callisto (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50416", label: "50416:Callisto (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50420", label: "50420:Callisto (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50421", label: "50421:Callisto (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50425", label: "50425:Callisto (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50426", label: "50426:Callisto (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50430", label: "50430:Callisto (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50431", label: "50431:Callisto (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:50435", label: "50435:Callisto (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50436", label: "50436:Callisto (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:50440", label: "50440:Callisto (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50441", label: "50441:Callisto (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50445", label: "50445:Callisto (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50446", label: "50446:Callisto (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50450", label: "50450:Callisto (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50451", label: "50451:Callisto (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50455", label: "50455:Callisto (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50456", label: "50456:Callisto (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50460", label: "50460:Callisto (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50461", label: "50461:Callisto (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50465", label: "50465:Callisto (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50466", label: "50466:Callisto (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50470", label: "50470:Callisto (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50471", label: "50471:Callisto (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50475", label: "50475:Callisto (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50476", label: "50476:Callisto (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50480", label: "50480:Callisto (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50481", label: "50481:Callisto (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50485", label: "50485:Callisto (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50486", label: "50486:Callisto (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50510", label: "50510:Amalthea (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50515", label: "50515:Amalthea (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50520", label: "50520:Amalthea (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50525", label: "50525:Amalthea (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50530", label: "50530:Amalthea (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50535", label: "50535:Amalthea (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50540", label: "50540:Amalthea (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50545", label: "50545:Amalthea (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50550", label: "50550:Amalthea (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50555", label: "50555:Amalthea (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50560", label: "50560:Amalthea (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50565", label: "50565:Amalthea (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50570", label: "50570:Amalthea (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50575", label: "50575:Amalthea (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50580", label: "50580:Amalthea (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50585", label: "50585:Amalthea (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50610", label: "50610:Himalia (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50615", label: "50615:Himalia (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50620", label: "50620:Himalia (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50625", label: "50625:Himalia (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50630", label: "50630:Himalia (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50635", label: "50635:Himalia (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50640", label: "50640:Himalia (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50645", label: "50645:Himalia (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50650", label: "50650:Himalia (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50655", label: "50655:Himalia (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50660", label: "50660:Himalia (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50665", label: "50665:Himalia (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50670", label: "50670:Himalia (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50675", label: "50675:Himalia (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50680", label: "50680:Himalia (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50685", label: "50685:Himalia (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50710", label: "50710:Elara (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50715", label: "50715:Elara (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50720", label: "50720:Elara (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50725", label: "50725:Elara (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50730", label: "50730:Elara (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50735", label: "50735:Elara (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50740", label: "50740:Elara (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50745", label: "50745:Elara (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50750", label: "50750:Elara (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50755", label: "50755:Elara (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50760", label: "50760:Elara (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50765", label: "50765:Elara (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50770", label: "50770:Elara (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50775", label: "50775:Elara (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50780", label: "50780:Elara (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50785", label: "50785:Elara (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50810", label: "50810:Pasiphae (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50815", label: "50815:Pasiphae (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50820", label: "50820:Pasiphae (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50825", label: "50825:Pasiphae (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50830", label: "50830:Pasiphae (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50835", label: "50835:Pasiphae (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50840", label: "50840:Pasiphae (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50845", label: "50845:Pasiphae (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50850", label: "50850:Pasiphae (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50855", label: "50855:Pasiphae (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50860", label: "50860:Pasiphae (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50865", label: "50865:Pasiphae (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50870", label: "50870:Pasiphae (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50875", label: "50875:Pasiphae (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50880", label: "50880:Pasiphae (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50885", label: "50885:Pasiphae (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:50910", label: "50910:Sinope (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:50915", label: "50915:Sinope (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:50920", label: "50920:Sinope (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:50925", label: "50925:Sinope (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:50930", label: "50930:Sinope (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:50935", label: "50935:Sinope (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:50940", label: "50940:Sinope (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:50945", label: "50945:Sinope (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:50950", label: "50950:Sinope (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:50955", label: "50955:Sinope (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:50960", label: "50960:Sinope (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:50965", label: "50965:Sinope (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:50970", label: "50970:Sinope (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:50975", label: "50975:Sinope (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:50980", label: "50980:Sinope (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:50985", label: "50985:Sinope (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:51010", label: "51010:Lysithea (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:51015", label: "51015:Lysithea (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:51020", label: "51020:Lysithea (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:51025", label: "51025:Lysithea (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:51030", label: "51030:Lysithea (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:51035", label: "51035:Lysithea (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:51040", label: "51040:Lysithea (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:51045", label: "51045:Lysithea (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:51050", label: "51050:Lysithea (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:51055", label: "51055:Lysithea (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:51060", label: "51060:Lysithea (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:51065", label: "51065:Lysithea (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:51070", label: "51070:Lysithea (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:51075", label: "51075:Lysithea (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:51080", label: "51080:Lysithea (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:51085", label: "51085:Lysithea (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:51110", label: "51110:Carme (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:51115", label: "51115:Carme (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:51120", label: "51120:Carme (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:51125", label: "51125:Carme (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:51130", label: "51130:Carme (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:51135", label: "51135:Carme (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:51140", label: "51140:Carme (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:51145", label: "51145:Carme (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:51150", label: "51150:Carme (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:51155", label: "51155:Carme (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:51160", label: "51160:Carme (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:51165", label: "51165:Carme (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:51170", label: "51170:Carme (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:51175", label: "51175:Carme (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:51180", label: "51180:Carme (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:51185", label: "51185:Carme (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:51210", label: "51210:Ananke (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:51215", label: "51215:Ananke (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:51220", label: "51220:Ananke (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:51225", label: "51225:Ananke (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:51230", label: "51230:Ananke (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:51235", label: "51235:Ananke (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:51240", label: "51240:Ananke (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:51245", label: "51245:Ananke (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:51250", label: "51250:Ananke (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:51255", label: "51255:Ananke (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:51260", label: "51260:Ananke (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:51265", label: "51265:Ananke (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:51270", label: "51270:Ananke (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:51275", label: "51275:Ananke (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:51280", label: "51280:Ananke (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:51285", label: "51285:Ananke (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:51310", label: "51310:Leda (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:51315", label: "51315:Leda (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:51320", label: "51320:Leda (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:51325", label: "51325:Leda (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:51330", label: "51330:Leda (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:51335", label: "51335:Leda (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:51340", label: "51340:Leda (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:51345", label: "51345:Leda (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:51350", label: "51350:Leda (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:51355", label: "51355:Leda (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:51360", label: "51360:Leda (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:51365", label: "51365:Leda (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:51370", label: "51370:Leda (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:51375", label: "51375:Leda (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:51380", label: "51380:Leda (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:51385", label: "51385:Leda (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:51410", label: "51410:Thebe (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:51415", label: "51415:Thebe (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:51420", label: "51420:Thebe (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:51425", label: "51425:Thebe (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:51430", label: "51430:Thebe (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:51435", label: "51435:Thebe (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:51440", label: "51440:Thebe (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:51445", label: "51445:Thebe (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:51450", label: "51450:Thebe (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:51455", label: "51455:Thebe (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:51460", label: "51460:Thebe (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:51465", label: "51465:Thebe (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:51470", label: "51470:Thebe (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:51475", label: "51475:Thebe (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:51480", label: "51480:Thebe (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:51485", label: "51485:Thebe (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:51510", label: "51510:Adrastea (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:51515", label: "51515:Adrastea (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:51520", label: "51520:Adrastea (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:51525", label: "51525:Adrastea (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:51530", label: "51530:Adrastea (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:51535", label: "51535:Adrastea (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:51540", label: "51540:Adrastea (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:51545", label: "51545:Adrastea (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:51550", label: "51550:Adrastea (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:51555", label: "51555:Adrastea (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:51560", label: "51560:Adrastea (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:51565", label: "51565:Adrastea (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:51570", label: "51570:Adrastea (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:51575", label: "51575:Adrastea (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:51580", label: "51580:Adrastea (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:51585", label: "51585:Adrastea (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:51610", label: "51610:Metis (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:51615", label: "51615:Metis (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:51620", label: "51620:Metis (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:51625", label: "51625:Metis (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:51630", label: "51630:Metis (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:51635", label: "51635:Metis (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:51640", label: "51640:Metis (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:51645", label: "51645:Metis (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:51650", label: "51650:Metis (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:51655", label: "51655:Metis (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:51660", label: "51660:Metis (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:51665", label: "51665:Metis (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:51670", label: "51670:Metis (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:51675", label: "51675:Metis (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:51680", label: "51680:Metis (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:51685", label: "51685:Metis (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:59910", label: "59910:Jupiter (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:59911", label: "59911:Jupiter (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:59912", label: "59912:Jupiter (2015) / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:59915", label: "59915:Jupiter (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:59916", label: "59916:Jupiter (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:59917", label: "59917:Jupiter (2015) / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:59920", label: "59920:Jupiter (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:59921", label: "59921:Jupiter (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:59922", label: "59922:Jupiter (2015) / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:59925", label: "59925:Jupiter (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:59926", label: "59926:Jupiter (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:59927", label: "59927:Jupiter (2015) / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:59930", label: "59930:Jupiter (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:59931", label: "59931:Jupiter (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:59932", label: "59932:Jupiter (2015) / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:59935", label: "59935:Jupiter (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:59936", label: "59936:Jupiter (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:59937", label: "59937:Jupiter (2015) / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:59940", label: "59940:Jupiter (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:59941", label: "59941:Jupiter (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:59942", label: "59942:Jupiter (2015) / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:59945", label: "59945:Jupiter (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:59946", label: "59946:Jupiter (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:59947", label: "59947:Jupiter (2015) / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:59950", label: "59950:Jupiter (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:59951", label: "59951:Jupiter (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:59952", label: "59952:Jupiter (2015) / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:59955", label: "59955:Jupiter (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:59956", label: "59956:Jupiter (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:59957", label: "59957:Jupiter (2015) / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:59960", label: "59960:Jupiter (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:59961", label: "59961:Jupiter (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:59962", label: "59962:Jupiter (2015) / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:59965", label: "59965:Jupiter (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:59966", label: "59966:Jupiter (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:59967", label: "59967:Jupiter (2015) / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:59970", label: "59970:Jupiter (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:59971", label: "59971:Jupiter (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:59972", label: "59972:Jupiter (2015) / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:59975", label: "59975:Jupiter (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:59976", label: "59976:Jupiter (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:59977", label: "59977:Jupiter (2015) / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:59980", label: "59980:Jupiter (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:59981", label: "59981:Jupiter (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:59982", label: "59982:Jupiter (2015) / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:59985", label: "59985:Jupiter (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:59986", label: "59986:Jupiter (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:59987", label: "59987:Jupiter (2015) / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60110", label: "60110:Mimas (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60115", label: "60115:Mimas (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60120", label: "60120:Mimas (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60125", label: "60125:Mimas (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60130", label: "60130:Mimas (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60135", label: "60135:Mimas (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60140", label: "60140:Mimas (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60145", label: "60145:Mimas (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60150", label: "60150:Mimas (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60155", label: "60155:Mimas (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60160", label: "60160:Mimas (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60165", label: "60165:Mimas (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60170", label: "60170:Mimas (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60175", label: "60175:Mimas (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60180", label: "60180:Mimas (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60185", label: "60185:Mimas (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60210", label: "60210:Enceladus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60215", label: "60215:Enceladus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60220", label: "60220:Enceladus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60225", label: "60225:Enceladus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60230", label: "60230:Enceladus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60235", label: "60235:Enceladus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60240", label: "60240:Enceladus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60245", label: "60245:Enceladus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60250", label: "60250:Enceladus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60255", label: "60255:Enceladus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60260", label: "60260:Enceladus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60265", label: "60265:Enceladus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60270", label: "60270:Enceladus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60275", label: "60275:Enceladus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60280", label: "60280:Enceladus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60285", label: "60285:Enceladus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60310", label: "60310:Tethys (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60315", label: "60315:Tethys (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60320", label: "60320:Tethys (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60325", label: "60325:Tethys (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60330", label: "60330:Tethys (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60335", label: "60335:Tethys (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60340", label: "60340:Tethys (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60345", label: "60345:Tethys (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60350", label: "60350:Tethys (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60355", label: "60355:Tethys (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60360", label: "60360:Tethys (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60365", label: "60365:Tethys (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60370", label: "60370:Tethys (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60375", label: "60375:Tethys (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60380", label: "60380:Tethys (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60385", label: "60385:Tethys (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60410", label: "60410:Dione (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60415", label: "60415:Dione (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60420", label: "60420:Dione (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60425", label: "60425:Dione (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60430", label: "60430:Dione (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60435", label: "60435:Dione (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60440", label: "60440:Dione (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60445", label: "60445:Dione (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60450", label: "60450:Dione (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60455", label: "60455:Dione (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60460", label: "60460:Dione (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60465", label: "60465:Dione (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60470", label: "60470:Dione (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60475", label: "60475:Dione (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60480", label: "60480:Dione (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60485", label: "60485:Dione (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60510", label: "60510:Rhea (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60515", label: "60515:Rhea (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60520", label: "60520:Rhea (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60525", label: "60525:Rhea (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60530", label: "60530:Rhea (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60535", label: "60535:Rhea (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60540", label: "60540:Rhea (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60545", label: "60545:Rhea (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60550", label: "60550:Rhea (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60555", label: "60555:Rhea (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60560", label: "60560:Rhea (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60565", label: "60565:Rhea (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60570", label: "60570:Rhea (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60575", label: "60575:Rhea (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60580", label: "60580:Rhea (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60585", label: "60585:Rhea (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60610", label: "60610:Titan (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60615", label: "60615:Titan (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60620", label: "60620:Titan (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60625", label: "60625:Titan (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60630", label: "60630:Titan (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60635", label: "60635:Titan (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60640", label: "60640:Titan (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60645", label: "60645:Titan (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60650", label: "60650:Titan (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60655", label: "60655:Titan (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60660", label: "60660:Titan (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60665", label: "60665:Titan (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60670", label: "60670:Titan (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60675", label: "60675:Titan (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60680", label: "60680:Titan (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60685", label: "60685:Titan (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60710", label: "60710:Hyperion (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60715", label: "60715:Hyperion (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60720", label: "60720:Hyperion (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60725", label: "60725:Hyperion (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60730", label: "60730:Hyperion (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60735", label: "60735:Hyperion (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60740", label: "60740:Hyperion (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60745", label: "60745:Hyperion (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60750", label: "60750:Hyperion (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60755", label: "60755:Hyperion (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60760", label: "60760:Hyperion (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60765", label: "60765:Hyperion (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60770", label: "60770:Hyperion (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60775", label: "60775:Hyperion (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60780", label: "60780:Hyperion (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60785", label: "60785:Hyperion (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60810", label: "60810:Iapetus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60811", label: "60811:Iapetus (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60812", label: "60812:Iapetus (2015) / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60815", label: "60815:Iapetus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60816", label: "60816:Iapetus (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60817", label: "60817:Iapetus (2015) / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60820", label: "60820:Iapetus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60821", label: "60821:Iapetus (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60822", label: "60822:Iapetus (2015) / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60825", label: "60825:Iapetus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60826", label: "60826:Iapetus (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60827", label: "60827:Iapetus (2015) / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60830", label: "60830:Iapetus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60831", label: "60831:Iapetus (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:60832", label: "60832:Iapetus (2015) / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60835", label: "60835:Iapetus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60836", label: "60836:Iapetus (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:60837", label: "60837:Iapetus (2015) / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60840", label: "60840:Iapetus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60841", label: "60841:Iapetus (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60842", label: "60842:Iapetus (2015) / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60845", label: "60845:Iapetus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60846", label: "60846:Iapetus (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60847", label: "60847:Iapetus (2015) / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60850", label: "60850:Iapetus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60851", label: "60851:Iapetus (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60852", label: "60852:Iapetus (2015) / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60855", label: "60855:Iapetus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60856", label: "60856:Iapetus (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60857", label: "60857:Iapetus (2015) / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60860", label: "60860:Iapetus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60861", label: "60861:Iapetus (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60862", label: "60862:Iapetus (2015) / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60865", label: "60865:Iapetus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60866", label: "60866:Iapetus (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60867", label: "60867:Iapetus (2015) / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60870", label: "60870:Iapetus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60871", label: "60871:Iapetus (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60872", label: "60872:Iapetus (2015) / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60875", label: "60875:Iapetus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60876", label: "60876:Iapetus (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60877", label: "60877:Iapetus (2015) / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60880", label: "60880:Iapetus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60881", label: "60881:Iapetus (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60882", label: "60882:Iapetus (2015) / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60885", label: "60885:Iapetus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60886", label: "60886:Iapetus (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60887", label: "60887:Iapetus (2015) / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:60910", label: "60910:Phoebe (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:60915", label: "60915:Phoebe (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:60920", label: "60920:Phoebe (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:60925", label: "60925:Phoebe (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:60930", label: "60930:Phoebe (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:60935", label: "60935:Phoebe (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:60940", label: "60940:Phoebe (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:60945", label: "60945:Phoebe (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:60950", label: "60950:Phoebe (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:60955", label: "60955:Phoebe (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:60960", label: "60960:Phoebe (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:60965", label: "60965:Phoebe (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:60970", label: "60970:Phoebe (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:60975", label: "60975:Phoebe (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:60980", label: "60980:Phoebe (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:60985", label: "60985:Phoebe (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61010", label: "61010:Janus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61015", label: "61015:Janus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61020", label: "61020:Janus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61025", label: "61025:Janus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61030", label: "61030:Janus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61035", label: "61035:Janus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61040", label: "61040:Janus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61045", label: "61045:Janus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61050", label: "61050:Janus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61055", label: "61055:Janus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61060", label: "61060:Janus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61065", label: "61065:Janus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61070", label: "61070:Janus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61075", label: "61075:Janus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61080", label: "61080:Janus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61085", label: "61085:Janus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61110", label: "61110:Epimetheus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61115", label: "61115:Epimetheus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61120", label: "61120:Epimetheus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61125", label: "61125:Epimetheus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61130", label: "61130:Epimetheus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61135", label: "61135:Epimetheus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61140", label: "61140:Epimetheus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61145", label: "61145:Epimetheus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61150", label: "61150:Epimetheus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61155", label: "61155:Epimetheus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61160", label: "61160:Epimetheus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61165", label: "61165:Epimetheus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61170", label: "61170:Epimetheus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61175", label: "61175:Epimetheus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61180", label: "61180:Epimetheus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61185", label: "61185:Epimetheus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61210", label: "61210:Helene (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61215", label: "61215:Helene (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61220", label: "61220:Helene (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61225", label: "61225:Helene (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61230", label: "61230:Helene (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61235", label: "61235:Helene (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61240", label: "61240:Helene (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61245", label: "61245:Helene (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61250", label: "61250:Helene (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61255", label: "61255:Helene (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61260", label: "61260:Helene (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61265", label: "61265:Helene (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61270", label: "61270:Helene (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61275", label: "61275:Helene (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61280", label: "61280:Helene (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61285", label: "61285:Helene (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61310", label: "61310:Telesto (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61315", label: "61315:Telesto (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61320", label: "61320:Telesto (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61325", label: "61325:Telesto (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61330", label: "61330:Telesto (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61335", label: "61335:Telesto (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61340", label: "61340:Telesto (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61345", label: "61345:Telesto (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61350", label: "61350:Telesto (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61355", label: "61355:Telesto (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61360", label: "61360:Telesto (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61365", label: "61365:Telesto (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61370", label: "61370:Telesto (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61375", label: "61375:Telesto (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61380", label: "61380:Telesto (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61385", label: "61385:Telesto (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61410", label: "61410:Calypso (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61415", label: "61415:Calypso (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61420", label: "61420:Calypso (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61425", label: "61425:Calypso (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61430", label: "61430:Calypso (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61435", label: "61435:Calypso (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61440", label: "61440:Calypso (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61445", label: "61445:Calypso (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61450", label: "61450:Calypso (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61455", label: "61455:Calypso (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61460", label: "61460:Calypso (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61465", label: "61465:Calypso (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61470", label: "61470:Calypso (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61475", label: "61475:Calypso (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61480", label: "61480:Calypso (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61485", label: "61485:Calypso (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61510", label: "61510:Atlas (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61515", label: "61515:Atlas (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61520", label: "61520:Atlas (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61525", label: "61525:Atlas (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61530", label: "61530:Atlas (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61535", label: "61535:Atlas (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61540", label: "61540:Atlas (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61545", label: "61545:Atlas (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61550", label: "61550:Atlas (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61555", label: "61555:Atlas (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61560", label: "61560:Atlas (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61565", label: "61565:Atlas (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61570", label: "61570:Atlas (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61575", label: "61575:Atlas (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61580", label: "61580:Atlas (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61585", label: "61585:Atlas (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61610", label: "61610:Prometheus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61615", label: "61615:Prometheus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61620", label: "61620:Prometheus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61625", label: "61625:Prometheus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61630", label: "61630:Prometheus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61635", label: "61635:Prometheus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61640", label: "61640:Prometheus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61645", label: "61645:Prometheus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61650", label: "61650:Prometheus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61655", label: "61655:Prometheus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61660", label: "61660:Prometheus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61665", label: "61665:Prometheus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61670", label: "61670:Prometheus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61675", label: "61675:Prometheus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61680", label: "61680:Prometheus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61685", label: "61685:Prometheus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61710", label: "61710:Pandora (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61715", label: "61715:Pandora (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61720", label: "61720:Pandora (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61725", label: "61725:Pandora (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61730", label: "61730:Pandora (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61735", label: "61735:Pandora (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61740", label: "61740:Pandora (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61745", label: "61745:Pandora (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61750", label: "61750:Pandora (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61755", label: "61755:Pandora (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61760", label: "61760:Pandora (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61765", label: "61765:Pandora (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61770", label: "61770:Pandora (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61775", label: "61775:Pandora (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61780", label: "61780:Pandora (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61785", label: "61785:Pandora (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:61810", label: "61810:Pan (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:61815", label: "61815:Pan (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:61820", label: "61820:Pan (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:61825", label: "61825:Pan (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:61830", label: "61830:Pan (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:61835", label: "61835:Pan (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:61840", label: "61840:Pan (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:61845", label: "61845:Pan (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:61850", label: "61850:Pan (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:61855", label: "61855:Pan (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:61860", label: "61860:Pan (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:61865", label: "61865:Pan (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:61870", label: "61870:Pan (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:61875", label: "61875:Pan (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:61880", label: "61880:Pan (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:61885", label: "61885:Pan (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:63210", label: "63210:Methone (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:63215", label: "63215:Methone (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:63220", label: "63220:Methone (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:63225", label: "63225:Methone (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:63230", label: "63230:Methone (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:63235", label: "63235:Methone (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:63240", label: "63240:Methone (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:63245", label: "63245:Methone (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:63250", label: "63250:Methone (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:63255", label: "63255:Methone (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:63260", label: "63260:Methone (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:63265", label: "63265:Methone (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:63270", label: "63270:Methone (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:63275", label: "63275:Methone (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:63280", label: "63280:Methone (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:63285", label: "63285:Methone (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:63310", label: "63310:Pallene (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:63315", label: "63315:Pallene (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:63320", label: "63320:Pallene (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:63325", label: "63325:Pallene (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:63330", label: "63330:Pallene (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:63335", label: "63335:Pallene (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:63340", label: "63340:Pallene (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:63345", label: "63345:Pallene (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:63350", label: "63350:Pallene (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:63355", label: "63355:Pallene (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:63360", label: "63360:Pallene (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:63365", label: "63365:Pallene (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:63370", label: "63370:Pallene (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:63375", label: "63375:Pallene (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:63380", label: "63380:Pallene (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:63385", label: "63385:Pallene (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:63410", label: "63410:Polydeuces (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:63415", label: "63415:Polydeuces (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:63420", label: "63420:Polydeuces (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:63425", label: "63425:Polydeuces (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:63430", label: "63430:Polydeuces (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:63435", label: "63435:Polydeuces (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:63440", label: "63440:Polydeuces (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:63445", label: "63445:Polydeuces (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:63450", label: "63450:Polydeuces (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:63455", label: "63455:Polydeuces (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:63460", label: "63460:Polydeuces (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:63465", label: "63465:Polydeuces (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:63470", label: "63470:Polydeuces (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:63475", label: "63475:Polydeuces (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:63480", label: "63480:Polydeuces (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:63485", label: "63485:Polydeuces (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:63510", label: "63510:Daphnis (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:63515", label: "63515:Daphnis (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:63520", label: "63520:Daphnis (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:63525", label: "63525:Daphnis (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:63530", label: "63530:Daphnis (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:63535", label: "63535:Daphnis (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:63540", label: "63540:Daphnis (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:63545", label: "63545:Daphnis (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:63550", label: "63550:Daphnis (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:63555", label: "63555:Daphnis (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:63560", label: "63560:Daphnis (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:63565", label: "63565:Daphnis (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:63570", label: "63570:Daphnis (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:63575", label: "63575:Daphnis (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:63580", label: "63580:Daphnis (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:63585", label: "63585:Daphnis (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:64910", label: "64910:Anthe (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:64915", label: "64915:Anthe (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:64920", label: "64920:Anthe (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:64925", label: "64925:Anthe (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:64930", label: "64930:Anthe (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:64935", label: "64935:Anthe (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:64940", label: "64940:Anthe (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:64945", label: "64945:Anthe (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:64950", label: "64950:Anthe (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:64955", label: "64955:Anthe (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:64960", label: "64960:Anthe (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:64965", label: "64965:Anthe (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:64970", label: "64970:Anthe (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:64975", label: "64975:Anthe (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:64980", label: "64980:Anthe (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:64985", label: "64985:Anthe (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:65310", label: "65310:Aegaeon (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:65315", label: "65315:Aegaeon (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:65320", label: "65320:Aegaeon (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:65325", label: "65325:Aegaeon (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:65330", label: "65330:Aegaeon (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:65335", label: "65335:Aegaeon (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:65340", label: "65340:Aegaeon (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:65345", label: "65345:Aegaeon (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:65350", label: "65350:Aegaeon (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:65355", label: "65355:Aegaeon (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:65360", label: "65360:Aegaeon (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:65365", label: "65365:Aegaeon (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:65370", label: "65370:Aegaeon (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:65375", label: "65375:Aegaeon (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:65380", label: "65380:Aegaeon (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:65385", label: "65385:Aegaeon (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:69910", label: "69910:Saturn (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:69911", label: "69911:Saturn (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:69912", label: "69912:Saturn (2015) / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:69915", label: "69915:Saturn (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:69916", label: "69916:Saturn (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:69917", label: "69917:Saturn (2015) / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:69920", label: "69920:Saturn (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:69921", label: "69921:Saturn (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:69922", label: "69922:Saturn (2015) / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:69925", label: "69925:Saturn (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:69926", label: "69926:Saturn (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:69927", label: "69927:Saturn (2015) / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:69930", label: "69930:Saturn (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:69931", label: "69931:Saturn (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:69932", label: "69932:Saturn (2015) / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:69935", label: "69935:Saturn (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:69936", label: "69936:Saturn (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:69937", label: "69937:Saturn (2015) / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:69940", label: "69940:Saturn (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:69941", label: "69941:Saturn (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:69942", label: "69942:Saturn (2015) / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:69945", label: "69945:Saturn (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:69946", label: "69946:Saturn (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:69947", label: "69947:Saturn (2015) / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:69950", label: "69950:Saturn (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:69951", label: "69951:Saturn (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:69952", label: "69952:Saturn (2015) / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:69955", label: "69955:Saturn (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:69956", label: "69956:Saturn (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:69957", label: "69957:Saturn (2015) / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:69960", label: "69960:Saturn (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:69961", label: "69961:Saturn (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:69962", label: "69962:Saturn (2015) / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:69965", label: "69965:Saturn (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:69966", label: "69966:Saturn (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:69967", label: "69967:Saturn (2015) / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:69970", label: "69970:Saturn (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:69971", label: "69971:Saturn (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:69972", label: "69972:Saturn (2015) / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:69975", label: "69975:Saturn (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:69976", label: "69976:Saturn (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:69977", label: "69977:Saturn (2015) / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:69980", label: "69980:Saturn (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:69981", label: "69981:Saturn (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:69982", label: "69982:Saturn (2015) / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:69985", label: "69985:Saturn (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:69986", label: "69986:Saturn (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:69987", label: "69987:Saturn (2015) / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70110", label: "70110:Ariel (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70115", label: "70115:Ariel (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70120", label: "70120:Ariel (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70125", label: "70125:Ariel (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70130", label: "70130:Ariel (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70135", label: "70135:Ariel (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70140", label: "70140:Ariel (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70145", label: "70145:Ariel (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70150", label: "70150:Ariel (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70155", label: "70155:Ariel (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70160", label: "70160:Ariel (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70165", label: "70165:Ariel (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70170", label: "70170:Ariel (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70175", label: "70175:Ariel (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70180", label: "70180:Ariel (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70185", label: "70185:Ariel (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70210", label: "70210:Umbriel (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70215", label: "70215:Umbriel (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70220", label: "70220:Umbriel (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70225", label: "70225:Umbriel (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70230", label: "70230:Umbriel (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70235", label: "70235:Umbriel (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70240", label: "70240:Umbriel (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70245", label: "70245:Umbriel (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70250", label: "70250:Umbriel (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70255", label: "70255:Umbriel (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70260", label: "70260:Umbriel (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70265", label: "70265:Umbriel (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70270", label: "70270:Umbriel (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70275", label: "70275:Umbriel (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70280", label: "70280:Umbriel (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70285", label: "70285:Umbriel (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70310", label: "70310:Titania (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70315", label: "70315:Titania (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70320", label: "70320:Titania (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70325", label: "70325:Titania (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70330", label: "70330:Titania (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70335", label: "70335:Titania (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70340", label: "70340:Titania (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70345", label: "70345:Titania (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70350", label: "70350:Titania (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70355", label: "70355:Titania (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70360", label: "70360:Titania (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70365", label: "70365:Titania (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70370", label: "70370:Titania (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70375", label: "70375:Titania (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70380", label: "70380:Titania (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70385", label: "70385:Titania (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70410", label: "70410:Oberon (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70415", label: "70415:Oberon (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70420", label: "70420:Oberon (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70425", label: "70425:Oberon (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70430", label: "70430:Oberon (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70435", label: "70435:Oberon (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70440", label: "70440:Oberon (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70445", label: "70445:Oberon (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70450", label: "70450:Oberon (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70455", label: "70455:Oberon (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70460", label: "70460:Oberon (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70465", label: "70465:Oberon (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70470", label: "70470:Oberon (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70475", label: "70475:Oberon (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70480", label: "70480:Oberon (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70485", label: "70485:Oberon (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70510", label: "70510:Miranda (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70515", label: "70515:Miranda (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70520", label: "70520:Miranda (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70525", label: "70525:Miranda (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70530", label: "70530:Miranda (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70535", label: "70535:Miranda (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70540", label: "70540:Miranda (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70545", label: "70545:Miranda (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70550", label: "70550:Miranda (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70555", label: "70555:Miranda (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70560", label: "70560:Miranda (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70565", label: "70565:Miranda (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70570", label: "70570:Miranda (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70575", label: "70575:Miranda (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70580", label: "70580:Miranda (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70585", label: "70585:Miranda (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70610", label: "70610:Cordelia (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70615", label: "70615:Cordelia (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70620", label: "70620:Cordelia (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70625", label: "70625:Cordelia (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70630", label: "70630:Cordelia (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70635", label: "70635:Cordelia (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70640", label: "70640:Cordelia (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70645", label: "70645:Cordelia (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70650", label: "70650:Cordelia (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70655", label: "70655:Cordelia (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70660", label: "70660:Cordelia (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70665", label: "70665:Cordelia (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70670", label: "70670:Cordelia (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70675", label: "70675:Cordelia (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70680", label: "70680:Cordelia (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70685", label: "70685:Cordelia (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70710", label: "70710:Ophelia (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70715", label: "70715:Ophelia (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70720", label: "70720:Ophelia (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70725", label: "70725:Ophelia (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70730", label: "70730:Ophelia (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70735", label: "70735:Ophelia (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70740", label: "70740:Ophelia (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70745", label: "70745:Ophelia (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70750", label: "70750:Ophelia (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70755", label: "70755:Ophelia (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70760", label: "70760:Ophelia (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70765", label: "70765:Ophelia (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70770", label: "70770:Ophelia (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70775", label: "70775:Ophelia (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70780", label: "70780:Ophelia (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70785", label: "70785:Ophelia (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70810", label: "70810:Bianca (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70815", label: "70815:Bianca (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70820", label: "70820:Bianca (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70825", label: "70825:Bianca (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70830", label: "70830:Bianca (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70835", label: "70835:Bianca (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70840", label: "70840:Bianca (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70845", label: "70845:Bianca (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70850", label: "70850:Bianca (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70855", label: "70855:Bianca (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70860", label: "70860:Bianca (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70865", label: "70865:Bianca (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70870", label: "70870:Bianca (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70875", label: "70875:Bianca (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70880", label: "70880:Bianca (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70885", label: "70885:Bianca (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:70910", label: "70910:Cressida (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:70915", label: "70915:Cressida (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:70920", label: "70920:Cressida (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:70925", label: "70925:Cressida (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:70930", label: "70930:Cressida (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:70935", label: "70935:Cressida (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:70940", label: "70940:Cressida (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:70945", label: "70945:Cressida (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:70950", label: "70950:Cressida (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:70955", label: "70955:Cressida (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:70960", label: "70960:Cressida (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:70965", label: "70965:Cressida (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:70970", label: "70970:Cressida (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:70975", label: "70975:Cressida (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:70980", label: "70980:Cressida (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:70985", label: "70985:Cressida (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:71010", label: "71010:Desdemona (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:71015", label: "71015:Desdemona (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:71020", label: "71020:Desdemona (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:71025", label: "71025:Desdemona (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:71030", label: "71030:Desdemona (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:71035", label: "71035:Desdemona (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:71040", label: "71040:Desdemona (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:71045", label: "71045:Desdemona (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:71050", label: "71050:Desdemona (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:71055", label: "71055:Desdemona (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:71060", label: "71060:Desdemona (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:71065", label: "71065:Desdemona (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:71070", label: "71070:Desdemona (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:71075", label: "71075:Desdemona (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:71080", label: "71080:Desdemona (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:71085", label: "71085:Desdemona (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:71110", label: "71110:Juliet (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:71115", label: "71115:Juliet (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:71120", label: "71120:Juliet (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:71125", label: "71125:Juliet (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:71130", label: "71130:Juliet (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:71135", label: "71135:Juliet (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:71140", label: "71140:Juliet (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:71145", label: "71145:Juliet (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:71150", label: "71150:Juliet (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:71155", label: "71155:Juliet (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:71160", label: "71160:Juliet (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:71165", label: "71165:Juliet (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:71170", label: "71170:Juliet (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:71175", label: "71175:Juliet (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:71180", label: "71180:Juliet (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:71185", label: "71185:Juliet (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:71210", label: "71210:Portia (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:71215", label: "71215:Portia (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:71220", label: "71220:Portia (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:71225", label: "71225:Portia (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:71230", label: "71230:Portia (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:71235", label: "71235:Portia (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:71240", label: "71240:Portia (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:71245", label: "71245:Portia (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:71250", label: "71250:Portia (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:71255", label: "71255:Portia (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:71260", label: "71260:Portia (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:71265", label: "71265:Portia (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:71270", label: "71270:Portia (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:71275", label: "71275:Portia (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:71280", label: "71280:Portia (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:71285", label: "71285:Portia (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:71310", label: "71310:Rosalind (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:71315", label: "71315:Rosalind (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:71320", label: "71320:Rosalind (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:71325", label: "71325:Rosalind (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:71330", label: "71330:Rosalind (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:71335", label: "71335:Rosalind (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:71340", label: "71340:Rosalind (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:71345", label: "71345:Rosalind (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:71350", label: "71350:Rosalind (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:71355", label: "71355:Rosalind (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:71360", label: "71360:Rosalind (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:71365", label: "71365:Rosalind (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:71370", label: "71370:Rosalind (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:71375", label: "71375:Rosalind (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:71380", label: "71380:Rosalind (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:71385", label: "71385:Rosalind (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:71410", label: "71410:Belinda (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:71415", label: "71415:Belinda (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:71420", label: "71420:Belinda (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:71425", label: "71425:Belinda (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:71430", label: "71430:Belinda (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:71435", label: "71435:Belinda (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:71440", label: "71440:Belinda (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:71445", label: "71445:Belinda (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:71450", label: "71450:Belinda (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:71455", label: "71455:Belinda (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:71460", label: "71460:Belinda (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:71465", label: "71465:Belinda (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:71470", label: "71470:Belinda (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:71475", label: "71475:Belinda (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:71480", label: "71480:Belinda (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:71485", label: "71485:Belinda (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:71510", label: "71510:Puck (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:71515", label: "71515:Puck (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:71520", label: "71520:Puck (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:71525", label: "71525:Puck (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:71530", label: "71530:Puck (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:71535", label: "71535:Puck (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:71540", label: "71540:Puck (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:71545", label: "71545:Puck (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:71550", label: "71550:Puck (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:71555", label: "71555:Puck (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:71560", label: "71560:Puck (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:71565", label: "71565:Puck (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:71570", label: "71570:Puck (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:71575", label: "71575:Puck (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:71580", label: "71580:Puck (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:71585", label: "71585:Puck (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:79910", label: "79910:Uranus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:79911", label: "79911:Uranus (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:79915", label: "79915:Uranus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:79916", label: "79916:Uranus (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:79920", label: "79920:Uranus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:79921", label: "79921:Uranus (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:79925", label: "79925:Uranus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:79926", label: "79926:Uranus (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:79930", label: "79930:Uranus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:79931", label: "79931:Uranus (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:79935", label: "79935:Uranus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:79936", label: "79936:Uranus (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:79940", label: "79940:Uranus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:79941", label: "79941:Uranus (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:79945", label: "79945:Uranus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:79946", label: "79946:Uranus (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:79950", label: "79950:Uranus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:79951", label: "79951:Uranus (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:79955", label: "79955:Uranus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:79956", label: "79956:Uranus (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:79960", label: "79960:Uranus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:79961", label: "79961:Uranus (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:79965", label: "79965:Uranus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:79966", label: "79966:Uranus (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:79970", label: "79970:Uranus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:79971", label: "79971:Uranus (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:79975", label: "79975:Uranus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:79976", label: "79976:Uranus (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:79980", label: "79980:Uranus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:79981", label: "79981:Uranus (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:79985", label: "79985:Uranus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:79986", label: "79986:Uranus (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80110", label: "80110:Triton (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80115", label: "80115:Triton (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80120", label: "80120:Triton (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80125", label: "80125:Triton (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80130", label: "80130:Triton (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80135", label: "80135:Triton (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80140", label: "80140:Triton (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80145", label: "80145:Triton (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80150", label: "80150:Triton (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80155", label: "80155:Triton (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80160", label: "80160:Triton (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80165", label: "80165:Triton (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80170", label: "80170:Triton (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80175", label: "80175:Triton (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80180", label: "80180:Triton (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80185", label: "80185:Triton (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80210", label: "80210:Nereid (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80215", label: "80215:Nereid (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80220", label: "80220:Nereid (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80225", label: "80225:Nereid (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80230", label: "80230:Nereid (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80235", label: "80235:Nereid (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80240", label: "80240:Nereid (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80245", label: "80245:Nereid (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80250", label: "80250:Nereid (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80255", label: "80255:Nereid (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80260", label: "80260:Nereid (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80265", label: "80265:Nereid (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80270", label: "80270:Nereid (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80275", label: "80275:Nereid (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80280", label: "80280:Nereid (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80285", label: "80285:Nereid (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80310", label: "80310:Naiad (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80311", label: "80311:Naiad (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80315", label: "80315:Naiad (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80316", label: "80316:Naiad (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80320", label: "80320:Naiad (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80321", label: "80321:Naiad (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80325", label: "80325:Naiad (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80326", label: "80326:Naiad (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80330", label: "80330:Naiad (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80331", label: "80331:Naiad (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:80335", label: "80335:Naiad (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80336", label: "80336:Naiad (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:80340", label: "80340:Naiad (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80341", label: "80341:Naiad (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80345", label: "80345:Naiad (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80346", label: "80346:Naiad (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80350", label: "80350:Naiad (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80351", label: "80351:Naiad (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80355", label: "80355:Naiad (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80356", label: "80356:Naiad (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80360", label: "80360:Naiad (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80361", label: "80361:Naiad (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80365", label: "80365:Naiad (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80366", label: "80366:Naiad (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80370", label: "80370:Naiad (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80371", label: "80371:Naiad (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80375", label: "80375:Naiad (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80376", label: "80376:Naiad (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80380", label: "80380:Naiad (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80381", label: "80381:Naiad (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80385", label: "80385:Naiad (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80386", label: "80386:Naiad (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80410", label: "80410:Thalassa (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80411", label: "80411:Thalassa (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80415", label: "80415:Thalassa (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80416", label: "80416:Thalassa (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80420", label: "80420:Thalassa (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80421", label: "80421:Thalassa (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80425", label: "80425:Thalassa (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80426", label: "80426:Thalassa (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80430", label: "80430:Thalassa (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80431", label: "80431:Thalassa (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:80435", label: "80435:Thalassa (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80436", label: "80436:Thalassa (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:80440", label: "80440:Thalassa (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80441", label: "80441:Thalassa (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80445", label: "80445:Thalassa (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80446", label: "80446:Thalassa (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80450", label: "80450:Thalassa (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80451", label: "80451:Thalassa (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80455", label: "80455:Thalassa (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80456", label: "80456:Thalassa (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80460", label: "80460:Thalassa (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80461", label: "80461:Thalassa (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80465", label: "80465:Thalassa (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80466", label: "80466:Thalassa (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80470", label: "80470:Thalassa (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80471", label: "80471:Thalassa (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80475", label: "80475:Thalassa (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80476", label: "80476:Thalassa (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80480", label: "80480:Thalassa (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80481", label: "80481:Thalassa (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80485", label: "80485:Thalassa (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80486", label: "80486:Thalassa (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80510", label: "80510:Despina (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80511", label: "80511:Despina (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80515", label: "80515:Despina (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80516", label: "80516:Despina (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80520", label: "80520:Despina (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80521", label: "80521:Despina (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80525", label: "80525:Despina (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80526", label: "80526:Despina (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80530", label: "80530:Despina (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80531", label: "80531:Despina (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:80535", label: "80535:Despina (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80536", label: "80536:Despina (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:80540", label: "80540:Despina (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80541", label: "80541:Despina (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80545", label: "80545:Despina (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80546", label: "80546:Despina (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80550", label: "80550:Despina (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80551", label: "80551:Despina (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80555", label: "80555:Despina (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80556", label: "80556:Despina (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80560", label: "80560:Despina (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80561", label: "80561:Despina (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80565", label: "80565:Despina (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80566", label: "80566:Despina (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80570", label: "80570:Despina (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80571", label: "80571:Despina (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80575", label: "80575:Despina (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80576", label: "80576:Despina (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80580", label: "80580:Despina (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80581", label: "80581:Despina (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80585", label: "80585:Despina (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80586", label: "80586:Despina (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80610", label: "80610:Galatea (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80611", label: "80611:Galatea (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80615", label: "80615:Galatea (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80616", label: "80616:Galatea (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80620", label: "80620:Galatea (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80621", label: "80621:Galatea (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80625", label: "80625:Galatea (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80626", label: "80626:Galatea (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80630", label: "80630:Galatea (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80631", label: "80631:Galatea (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:80635", label: "80635:Galatea (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80636", label: "80636:Galatea (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:80640", label: "80640:Galatea (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80641", label: "80641:Galatea (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80645", label: "80645:Galatea (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80646", label: "80646:Galatea (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80650", label: "80650:Galatea (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80651", label: "80651:Galatea (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80655", label: "80655:Galatea (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80656", label: "80656:Galatea (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80660", label: "80660:Galatea (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80661", label: "80661:Galatea (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80665", label: "80665:Galatea (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80666", label: "80666:Galatea (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80670", label: "80670:Galatea (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80671", label: "80671:Galatea (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80675", label: "80675:Galatea (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80676", label: "80676:Galatea (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80680", label: "80680:Galatea (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80681", label: "80681:Galatea (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80685", label: "80685:Galatea (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80686", label: "80686:Galatea (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80710", label: "80710:Larissa (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80711", label: "80711:Larissa (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80712", label: "80712:Larissa (2015) / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80715", label: "80715:Larissa (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80716", label: "80716:Larissa (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80717", label: "80717:Larissa (2015) / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80720", label: "80720:Larissa (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80721", label: "80721:Larissa (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80722", label: "80722:Larissa (2015) / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80725", label: "80725:Larissa (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80726", label: "80726:Larissa (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80727", label: "80727:Larissa (2015) / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80730", label: "80730:Larissa (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80731", label: "80731:Larissa (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:80732", label: "80732:Larissa (2015) / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80735", label: "80735:Larissa (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80736", label: "80736:Larissa (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:80737", label: "80737:Larissa (2015) / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80740", label: "80740:Larissa (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80741", label: "80741:Larissa (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80742", label: "80742:Larissa (2015) / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80745", label: "80745:Larissa (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80746", label: "80746:Larissa (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80747", label: "80747:Larissa (2015) / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80750", label: "80750:Larissa (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80751", label: "80751:Larissa (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80752", label: "80752:Larissa (2015) / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80755", label: "80755:Larissa (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80756", label: "80756:Larissa (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80757", label: "80757:Larissa (2015) / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80760", label: "80760:Larissa (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80761", label: "80761:Larissa (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80762", label: "80762:Larissa (2015) / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80765", label: "80765:Larissa (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80766", label: "80766:Larissa (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80767", label: "80767:Larissa (2015) / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80770", label: "80770:Larissa (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80771", label: "80771:Larissa (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80772", label: "80772:Larissa (2015) / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80775", label: "80775:Larissa (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80776", label: "80776:Larissa (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80777", label: "80777:Larissa (2015) / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80780", label: "80780:Larissa (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80781", label: "80781:Larissa (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80782", label: "80782:Larissa (2015) / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80785", label: "80785:Larissa (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80786", label: "80786:Larissa (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80787", label: "80787:Larissa (2015) / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:80810", label: "80810:Proteus (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:80815", label: "80815:Proteus (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:80820", label: "80820:Proteus (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:80825", label: "80825:Proteus (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:80830", label: "80830:Proteus (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:80835", label: "80835:Proteus (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:80840", label: "80840:Proteus (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:80845", label: "80845:Proteus (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:80850", label: "80850:Proteus (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:80855", label: "80855:Proteus (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:80860", label: "80860:Proteus (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:80865", label: "80865:Proteus (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:80870", label: "80870:Proteus (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:80875", label: "80875:Proteus (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:80880", label: "80880:Proteus (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:80885", label: "80885:Proteus (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:89910", label: "89910:Neptune (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:89911", label: "89911:Neptune (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:89912", label: "89912:Neptune (2015) / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:89915", label: "89915:Neptune (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:89916", label: "89916:Neptune (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:89917", label: "89917:Neptune (2015) / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:89920", label: "89920:Neptune (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:89921", label: "89921:Neptune (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:89922", label: "89922:Neptune (2015) / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:89925", label: "89925:Neptune (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:89926", label: "89926:Neptune (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:89927", label: "89927:Neptune (2015) / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:89930", label: "89930:Neptune (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:89931", label: "89931:Neptune (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:89932", label: "89932:Neptune (2015) / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:89935", label: "89935:Neptune (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:89936", label: "89936:Neptune (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:89937", label: "89937:Neptune (2015) / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:89940", label: "89940:Neptune (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:89941", label: "89941:Neptune (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:89942", label: "89942:Neptune (2015) / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:89945", label: "89945:Neptune (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:89946", label: "89946:Neptune (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:89947", label: "89947:Neptune (2015) / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:89950", label: "89950:Neptune (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:89951", label: "89951:Neptune (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:89952", label: "89952:Neptune (2015) / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:89955", label: "89955:Neptune (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:89956", label: "89956:Neptune (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:89957", label: "89957:Neptune (2015) / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:89960", label: "89960:Neptune (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:89961", label: "89961:Neptune (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:89962", label: "89962:Neptune (2015) / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:89965", label: "89965:Neptune (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:89966", label: "89966:Neptune (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:89967", label: "89967:Neptune (2015) / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:89970", label: "89970:Neptune (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:89971", label: "89971:Neptune (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:89972", label: "89972:Neptune (2015) / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:89975", label: "89975:Neptune (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:89976", label: "89976:Neptune (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:89977", label: "89977:Neptune (2015) / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:89980", label: "89980:Neptune (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:89981", label: "89981:Neptune (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:89982", label: "89982:Neptune (2015) / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:89985", label: "89985:Neptune (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:89986", label: "89986:Neptune (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:89987", label: "89987:Neptune (2015) / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:90110", label: "90110:Charon (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:90115", label: "90115:Charon (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:90120", label: "90120:Charon (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:90125", label: "90125:Charon (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:90130", label: "90130:Charon (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:90135", label: "90135:Charon (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:90140", label: "90140:Charon (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:90145", label: "90145:Charon (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:90150", label: "90150:Charon (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:90155", label: "90155:Charon (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:90160", label: "90160:Charon (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:90165", label: "90165:Charon (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:90170", label: "90170:Charon (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:90175", label: "90175:Charon (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:90180", label: "90180:Charon (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:90185", label: "90185:Charon (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:99910", label: "99910:Pluto (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:99915", label: "99915:Pluto (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:99920", label: "99920:Pluto (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:99925", label: "99925:Pluto (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:99930", label: "99930:Pluto (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:99935", label: "99935:Pluto (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:99940", label: "99940:Pluto (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:99945", label: "99945:Pluto (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:99950", label: "99950:Pluto (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:99955", label: "99955:Pluto (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:99960", label: "99960:Pluto (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:99965", label: "99965:Pluto (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:99970", label: "99970:Pluto (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:99975", label: "99975:Pluto (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:99980", label: "99980:Pluto (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:99985", label: "99985:Pluto (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:100000510", label: "100000510:Borrelly (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:100000515", label: "100000515:Borrelly (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:100000520", label: "100000520:Borrelly (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:100000525", label: "100000525:Borrelly (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:100000530", label: "100000530:Borrelly (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:100000535", label: "100000535:Borrelly (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:100000540", label: "100000540:Borrelly (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:100000545", label: "100000545:Borrelly (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:100000550", label: "100000550:Borrelly (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:100000555", label: "100000555:Borrelly (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:100000560", label: "100000560:Borrelly (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:100000565", label: "100000565:Borrelly (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:100000570", label: "100000570:Borrelly (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:100000575", label: "100000575:Borrelly (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:100000580", label: "100000580:Borrelly (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:100000585", label: "100000585:Borrelly (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:100001210", label: "100001210:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:100001215", label: "100001215:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:100001220", label: "100001220:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:100001225", label: "100001225:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:100001230", label: "100001230:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:100001235", label: "100001235:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:100001240", label: "100001240:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:100001245", label: "100001245:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:100001250", label: "100001250:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:100001255", label: "100001255:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:100001260", label: "100001260:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:100001265", label: "100001265:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:100001270", label: "100001270:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:100001275", label: "100001275:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:100001280", label: "100001280:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:100001285", label: "100001285:Churyumov-Gerasimenko (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:100003610", label: "100003610:Halley (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:100003611", label: "100003611:Halley (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:100003615", label: "100003615:Halley (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:100003616", label: "100003616:Halley (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:100003620", label: "100003620:Halley (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:100003621", label: "100003621:Halley (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:100003625", label: "100003625:Halley (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:100003626", label: "100003626:Halley (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:100003630", label: "100003630:Halley (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:100003631", label: "100003631:Halley (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:100003635", label: "100003635:Halley (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:100003636", label: "100003636:Halley (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:100003640", label: "100003640:Halley (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:100003641", label: "100003641:Halley (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:100003645", label: "100003645:Halley (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:100003646", label: "100003646:Halley (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:100003650", label: "100003650:Halley (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:100003651", label: "100003651:Halley (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:100003655", label: "100003655:Halley (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:100003656", label: "100003656:Halley (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:100003660", label: "100003660:Halley (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:100003661", label: "100003661:Halley (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:100003665", label: "100003665:Halley (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:100003666", label: "100003666:Halley (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:100003670", label: "100003670:Halley (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:100003671", label: "100003671:Halley (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:100003675", label: "100003675:Halley (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:100003676", label: "100003676:Halley (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:100003680", label: "100003680:Halley (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:100003681", label: "100003681:Halley (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:100003685", label: "100003685:Halley (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:100003686", label: "100003686:Halley (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:100004110", label: "100004110:Hartley 2 (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:100004115", label: "100004115:Hartley 2 (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:100004120", label: "100004120:Hartley 2 (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:100004125", label: "100004125:Hartley 2 (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:100004130", label: "100004130:Hartley 2 (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:100004135", label: "100004135:Hartley 2 (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:100004140", label: "100004140:Hartley 2 (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:100004145", label: "100004145:Hartley 2 (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:100004150", label: "100004150:Hartley 2 (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:100004155", label: "100004155:Hartley 2 (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:100004160", label: "100004160:Hartley 2 (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:100004165", label: "100004165:Hartley 2 (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:100004170", label: "100004170:Hartley 2 (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:100004175", label: "100004175:Hartley 2 (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:100004180", label: "100004180:Hartley 2 (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:100004185", label: "100004185:Hartley 2 (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:100009310", label: "100009310:Tempel 1 (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:100009315", label: "100009315:Tempel 1 (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:100009320", label: "100009320:Tempel 1 (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:100009325", label: "100009325:Tempel 1 (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:100009330", label: "100009330:Tempel 1 (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:100009335", label: "100009335:Tempel 1 (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:100009340", label: "100009340:Tempel 1 (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:100009345", label: "100009345:Tempel 1 (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:100009350", label: "100009350:Tempel 1 (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:100009355", label: "100009355:Tempel 1 (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:100009360", label: "100009360:Tempel 1 (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:100009365", label: "100009365:Tempel 1 (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:100009370", label: "100009370:Tempel 1 (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:100009375", label: "100009375:Tempel 1 (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:100009380", label: "100009380:Tempel 1 (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:100009385", label: "100009385:Tempel 1 (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:100010710", label: "100010710:Wild 2 (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:100010715", label: "100010715:Wild 2 (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:100010720", label: "100010720:Wild 2 (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:100010725", label: "100010725:Wild 2 (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:100010730", label: "100010730:Wild 2 (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:100010735", label: "100010735:Wild 2 (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:100010740", label: "100010740:Wild 2 (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:100010745", label: "100010745:Wild 2 (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:100010750", label: "100010750:Wild 2 (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:100010755", label: "100010755:Wild 2 (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:100010760", label: "100010760:Wild 2 (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:100010765", label: "100010765:Wild 2 (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:100010770", label: "100010770:Wild 2 (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:100010775", label: "100010775:Wild 2 (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:100010780", label: "100010780:Wild 2 (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:100010785", label: "100010785:Wild 2 (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200000110", label: "200000110:Ceres (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200000111", label: "200000111:Ceres (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200000115", label: "200000115:Ceres (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200000116", label: "200000116:Ceres (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200000120", label: "200000120:Ceres (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200000121", label: "200000121:Ceres (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200000125", label: "200000125:Ceres (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200000126", label: "200000126:Ceres (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200000130", label: "200000130:Ceres (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200000131", label: "200000131:Ceres (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:200000135", label: "200000135:Ceres (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200000136", label: "200000136:Ceres (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:200000140", label: "200000140:Ceres (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200000141", label: "200000141:Ceres (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200000145", label: "200000145:Ceres (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200000146", label: "200000146:Ceres (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200000150", label: "200000150:Ceres (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200000151", label: "200000151:Ceres (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200000155", label: "200000155:Ceres (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200000156", label: "200000156:Ceres (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200000160", label: "200000160:Ceres (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200000161", label: "200000161:Ceres (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200000165", label: "200000165:Ceres (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200000166", label: "200000166:Ceres (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200000170", label: "200000170:Ceres (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200000171", label: "200000171:Ceres (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200000175", label: "200000175:Ceres (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200000176", label: "200000176:Ceres (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200000180", label: "200000180:Ceres (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200000181", label: "200000181:Ceres (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200000185", label: "200000185:Ceres (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200000186", label: "200000186:Ceres (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200000410", label: "200000410:Vesta (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200000415", label: "200000415:Vesta (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200000420", label: "200000420:Vesta (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200000425", label: "200000425:Vesta (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200000430", label: "200000430:Vesta (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200000435", label: "200000435:Vesta (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200000440", label: "200000440:Vesta (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200000445", label: "200000445:Vesta (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200000450", label: "200000450:Vesta (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200000455", label: "200000455:Vesta (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200000460", label: "200000460:Vesta (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200000465", label: "200000465:Vesta (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200000470", label: "200000470:Vesta (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200000475", label: "200000475:Vesta (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200000480", label: "200000480:Vesta (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200000485", label: "200000485:Vesta (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200001610", label: "200001610:Psyche (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200001615", label: "200001615:Psyche (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200001620", label: "200001620:Psyche (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200001625", label: "200001625:Psyche (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200001630", label: "200001630:Psyche (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200001635", label: "200001635:Psyche (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200001640", label: "200001640:Psyche (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200001645", label: "200001645:Psyche (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200001650", label: "200001650:Psyche (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200001655", label: "200001655:Psyche (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200001660", label: "200001660:Psyche (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200001665", label: "200001665:Psyche (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200001670", label: "200001670:Psyche (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200001675", label: "200001675:Psyche (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200001680", label: "200001680:Psyche (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200001685", label: "200001685:Psyche (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200002110", label: "200002110:Lutetia (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200002115", label: "200002115:Lutetia (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200002120", label: "200002120:Lutetia (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200002125", label: "200002125:Lutetia (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200002130", label: "200002130:Lutetia (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200002135", label: "200002135:Lutetia (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200002140", label: "200002140:Lutetia (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200002145", label: "200002145:Lutetia (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200002150", label: "200002150:Lutetia (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200002155", label: "200002155:Lutetia (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200002160", label: "200002160:Lutetia (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200002165", label: "200002165:Lutetia (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200002170", label: "200002170:Lutetia (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200002175", label: "200002175:Lutetia (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200002180", label: "200002180:Lutetia (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200002185", label: "200002185:Lutetia (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200005210", label: "200005210:52 Europa (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200005215", label: "200005215:52 Europa (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200005220", label: "200005220:52 Europa (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200005225", label: "200005225:52 Europa (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200005230", label: "200005230:52 Europa (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200005235", label: "200005235:52 Europa (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200005240", label: "200005240:52 Europa (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200005245", label: "200005245:52 Europa (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200005250", label: "200005250:52 Europa (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200005255", label: "200005255:52 Europa (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200005260", label: "200005260:52 Europa (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200005265", label: "200005265:52 Europa (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200005270", label: "200005270:52 Europa (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200005275", label: "200005275:52 Europa (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200005280", label: "200005280:52 Europa (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200005285", label: "200005285:52 Europa (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200021610", label: "200021610:Kleopatra (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200021615", label: "200021615:Kleopatra (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200021620", label: "200021620:Kleopatra (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200021625", label: "200021625:Kleopatra (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200021630", label: "200021630:Kleopatra (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200021635", label: "200021635:Kleopatra (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200021640", label: "200021640:Kleopatra (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200021645", label: "200021645:Kleopatra (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200021650", label: "200021650:Kleopatra (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200021655", label: "200021655:Kleopatra (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200021660", label: "200021660:Kleopatra (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200021665", label: "200021665:Kleopatra (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200021670", label: "200021670:Kleopatra (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200021675", label: "200021675:Kleopatra (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200021680", label: "200021680:Kleopatra (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200021685", label: "200021685:Kleopatra (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200025310", label: "200025310:Mathilde (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200025315", label: "200025315:Mathilde (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200025320", label: "200025320:Mathilde (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200025325", label: "200025325:Mathilde (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200025330", label: "200025330:Mathilde (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200025335", label: "200025335:Mathilde (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200025340", label: "200025340:Mathilde (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200025345", label: "200025345:Mathilde (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200025350", label: "200025350:Mathilde (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200025355", label: "200025355:Mathilde (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200025360", label: "200025360:Mathilde (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200025365", label: "200025365:Mathilde (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200025370", label: "200025370:Mathilde (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200025375", label: "200025375:Mathilde (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200025380", label: "200025380:Mathilde (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200025385", label: "200025385:Mathilde (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200043310", label: "200043310:Eros (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200043311", label: "200043311:Eros (2015) / Ographic / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200043315", label: "200043315:Eros (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200043316", label: "200043316:Eros (2015) / Ographic / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200043320", label: "200043320:Eros (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200043321", label: "200043321:Eros (2015) / Ographic / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200043325", label: "200043325:Eros (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200043326", label: "200043326:Eros (2015) / Ographic / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200043330", label: "200043330:Eros (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200043331", label: "200043331:Eros (2015) / Ographic / North Polar"},
  {coordinate_system:"IAU_2015:200043335", label: "200043335:Eros (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200043336", label: "200043336:Eros (2015) / Ographic / South Polar"},
  {coordinate_system:"IAU_2015:200043340", label: "200043340:Eros (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200043341", label: "200043341:Eros (2015) / Ographic / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200043345", label: "200043345:Eros (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200043346", label: "200043346:Eros (2015) / Ographic / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200043350", label: "200043350:Eros (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200043351", label: "200043351:Eros (2015) / Ographic / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200043355", label: "200043355:Eros (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200043356", label: "200043356:Eros (2015) / Ographic / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200043360", label: "200043360:Eros (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200043361", label: "200043361:Eros (2015) / Ographic / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200043365", label: "200043365:Eros (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200043366", label: "200043366:Eros (2015) / Ographic / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200043370", label: "200043370:Eros (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200043371", label: "200043371:Eros (2015) / Ographic / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200043375", label: "200043375:Eros (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200043376", label: "200043376:Eros (2015) / Ographic / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200043380", label: "200043380:Eros (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200043381", label: "200043381:Eros (2015) / Ographic / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200043385", label: "200043385:Eros (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200043386", label: "200043386:Eros (2015) / Ographic / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200051110", label: "200051110:Davida (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200051115", label: "200051115:Davida (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200051120", label: "200051120:Davida (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200051125", label: "200051125:Davida (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200051130", label: "200051130:Davida (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200051135", label: "200051135:Davida (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200051140", label: "200051140:Davida (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200051145", label: "200051145:Davida (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200051150", label: "200051150:Davida (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200051155", label: "200051155:Davida (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200051160", label: "200051160:Davida (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200051165", label: "200051165:Davida (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200051170", label: "200051170:Davida (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200051175", label: "200051175:Davida (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200051180", label: "200051180:Davida (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200051185", label: "200051185:Davida (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200286710", label: "200286710:Steins (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200286715", label: "200286715:Steins (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200286720", label: "200286720:Steins (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200286725", label: "200286725:Steins (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200286730", label: "200286730:Steins (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200286735", label: "200286735:Steins (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200286740", label: "200286740:Steins (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200286745", label: "200286745:Steins (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200286750", label: "200286750:Steins (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200286755", label: "200286755:Steins (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200286760", label: "200286760:Steins (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200286765", label: "200286765:Steins (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200286770", label: "200286770:Steins (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200286775", label: "200286775:Steins (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200286780", label: "200286780:Steins (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200286785", label: "200286785:Steins (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:200417910", label: "200417910:Toutatis (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:200417915", label: "200417915:Toutatis (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:200417920", label: "200417920:Toutatis (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:200417925", label: "200417925:Toutatis (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:200417930", label: "200417930:Toutatis (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:200417935", label: "200417935:Toutatis (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:200417940", label: "200417940:Toutatis (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:200417945", label: "200417945:Toutatis (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:200417950", label: "200417950:Toutatis (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:200417955", label: "200417955:Toutatis (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:200417960", label: "200417960:Toutatis (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:200417965", label: "200417965:Toutatis (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:200417970", label: "200417970:Toutatis (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:200417975", label: "200417975:Toutatis (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:200417980", label: "200417980:Toutatis (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:200417985", label: "200417985:Toutatis (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:202514310", label: "202514310:Itokawa (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:202514315", label: "202514315:Itokawa (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:202514320", label: "202514320:Itokawa (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:202514325", label: "202514325:Itokawa (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:202514330", label: "202514330:Itokawa (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:202514335", label: "202514335:Itokawa (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:202514340", label: "202514340:Itokawa (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:202514345", label: "202514345:Itokawa (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:202514350", label: "202514350:Itokawa (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:202514355", label: "202514355:Itokawa (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:202514360", label: "202514360:Itokawa (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:202514365", label: "202514365:Itokawa (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:202514370", label: "202514370:Itokawa (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:202514375", label: "202514375:Itokawa (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:202514380", label: "202514380:Itokawa (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:202514385", label: "202514385:Itokawa (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:243101010", label: "243101010:Ida (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:243101015", label: "243101015:Ida (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:243101020", label: "243101020:Ida (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:243101025", label: "243101025:Ida (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:243101030", label: "243101030:Ida (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:243101035", label: "243101035:Ida (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:243101040", label: "243101040:Ida (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:243101045", label: "243101045:Ida (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:243101050", label: "243101050:Ida (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:243101055", label: "243101055:Ida (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:243101060", label: "243101060:Ida (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:243101065", label: "243101065:Ida (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:243101070", label: "243101070:Ida (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:243101075", label: "243101075:Ida (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:243101080", label: "243101080:Ida (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:243101085", label: "243101085:Ida (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IAU_2015:951101010", label: "951101010:Gaspra (2015) - Sphere / Ocentric / Equirectangular, clon = 0"},
  {coordinate_system:"IAU_2015:951101015", label: "951101015:Gaspra (2015) - Sphere / Ocentric / Equirectangular, clon = 180"},
  {coordinate_system:"IAU_2015:951101020", label: "951101020:Gaspra (2015) - Sphere / Ocentric / Sinusoidal, clon = 0"},
  {coordinate_system:"IAU_2015:951101025", label: "951101025:Gaspra (2015) - Sphere / Ocentric / Sinusoidal, clon = 180"},
  {coordinate_system:"IAU_2015:951101030", label: "951101030:Gaspra (2015) - Sphere / Ocentric / North Polar"},
  {coordinate_system:"IAU_2015:951101035", label: "951101035:Gaspra (2015) - Sphere / Ocentric / South Polar"},
  {coordinate_system:"IAU_2015:951101040", label: "951101040:Gaspra (2015) - Sphere / Ocentric / Mollweide, clon = 0"},
  {coordinate_system:"IAU_2015:951101045", label: "951101045:Gaspra (2015) - Sphere / Ocentric / Mollweide, clon = 180"},
  {coordinate_system:"IAU_2015:951101050", label: "951101050:Gaspra (2015) - Sphere / Ocentric / Robinson, clon = 0"},
  {coordinate_system:"IAU_2015:951101055", label: "951101055:Gaspra (2015) - Sphere / Ocentric / Robinson, clon = 180"},
  {coordinate_system:"IAU_2015:951101060", label: "951101060:Gaspra (2015) - Sphere / Ocentric / Tranverse Mercator"},
  {coordinate_system:"IAU_2015:951101065", label: "951101065:Gaspra (2015) - Sphere / Ocentric / Orthographic, clon = 0"},
  {coordinate_system:"IAU_2015:951101070", label: "951101070:Gaspra (2015) - Sphere / Ocentric / Orthographic, clon = 180"},
  {coordinate_system:"IAU_2015:951101075", label: "951101075:Gaspra (2015) - Sphere / Ocentric / Lambert Conic Conformal"},
  {coordinate_system:"IAU_2015:951101080", label: "951101080:Gaspra (2015) - Sphere / Ocentric / Lambert Azimuthal Equal Area"},
  {coordinate_system:"IAU_2015:951101085", label: "951101085:Gaspra (2015) - Sphere / Ocentric / Albers Equal Area"},
  {coordinate_system:"IGNF:AMANU63UTM7S", label: "AMANU63UTM7S:Amanu (MHPF 1963) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:AMST63UTM43S", label: "AMST63UTM43S:Amsterdam 1963 UTM Sud fuseau 43"},
  {coordinate_system:"IGNF:ANAA47UTM6S", label: "ANAA47UTM6S:Anaa (SHM 1947) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:ANAA92UTM6S", label: "ANAA92UTM6S:Anaa (MOP92) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:APAT80UTM6S", label: "APAT80UTM6S:Apataki (Cadastre 1980) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:APAT86UTM6S", label: "APAT86UTM6S:Tuamotu (MOP86) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:APAT86UTM7S", label: "APAT86UTM7S:Tuamotu (MOP86) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:ATIGBONNE", label: "ATIGBONNE:ATIG Bonne France Etat Major"},
  {coordinate_system:"IGNF:ATUO63UTM7S", label: "ATUO63UTM7S:IGN 1963 UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:C67IG95UTM21", label: "C67IG95UTM21:CSG67(IGN 1995) UTM Nord fuseau 21"},
  {coordinate_system:"IGNF:C67IG95UTM22", label: "C67IG95UTM22:CSG67 (IGN 1995) UTM Nord fuseau 22"},
  {coordinate_system:"IGNF:CAD97UTM38S", label: "CAD97UTM38S:Cadastre 1997 UTM Sud fuseau 38"},
  {coordinate_system:"IGNF:CLIP57UTM12", label: "CLIP57UTM12:Clipperton 1967 UTM Nord fuseau 12"},
  {coordinate_system:"IGNF:CLIP67UTM12", label: "CLIP67UTM12:Clipperton 1967 UTM Nord fuseau 12"},
  {coordinate_system:"IGNF:CROZ63UTM39S", label: "CROZ63UTM39S:CROZET POSSESSION 1963 UTM SUD FUSEAU 39"},
  {coordinate_system:"IGNF:CSG67UTM21", label: "CSG67UTM21:CSG67 UTM Nord fuseau 21"},
  {coordinate_system:"IGNF:CSG67UTM22", label: "CSG67UTM22:CSG67 UTM Nord fuseau 22"},
  {coordinate_system:"IGNF:ED50UTM30", label: "ED50UTM30:ED50 UTM fuseau 30"},
  {coordinate_system:"IGNF:ED50UTM31", label: "ED50UTM31:ED50 UTM NORD FUSEAU 31"},
  {coordinate_system:"IGNF:ED50UTM32", label: "ED50UTM32:ED50 UTM fuseau 32"},
  {coordinate_system:"IGNF:EFAT57UTM59S", label: "EFAT57UTM59S:Efate UTM Sud fuseau 59"},
  {coordinate_system:"IGNF:EFATE57UT59S", label: "EFATE57UT59S:Efate UTM Sud fuseau 59"},
  {coordinate_system:"IGNF:ETRS89LAEA", label: "ETRS89LAEA:ETRS89 Lambert Azimutal Equal Area"},
  {coordinate_system:"IGNF:ETRS89LCC", label: "ETRS89LCC:ETRS89 Lambert Conformal Conic"},
  {coordinate_system:"IGNF:ETRS89UTM26", label: "ETRS89UTM26:ETRS89 UTM Nord fuseau 26"},
  {coordinate_system:"IGNF:ETRS89UTM27", label: "ETRS89UTM27:ETRS89 UTM Nord fuseau 27"},
  {coordinate_system:"IGNF:ETRS89UTM28", label: "ETRS89UTM28:ETRS89 UTM Nord fuseau 28"},
  {coordinate_system:"IGNF:ETRS89UTM29", label: "ETRS89UTM29:ETRS89 UTM Nord fuseau 29"},
  {coordinate_system:"IGNF:ETRS89UTM30", label: "ETRS89UTM30:ETRS89 UTM Nord fuseau 30"},
  {coordinate_system:"IGNF:ETRS89UTM31", label: "ETRS89UTM31:ETRS89 UTM Nord fuseau 31"},
  {coordinate_system:"IGNF:ETRS89UTM32", label: "ETRS89UTM32:ETRS89 UTM Nord fuseau 32"},
  {coordinate_system:"IGNF:ETRS89UTM33", label: "ETRS89UTM33:ETRS89 UTM Nord fuseau 33"},
  {coordinate_system:"IGNF:ETRS89UTM34", label: "ETRS89UTM34:ETRS89 UTM Nord fuseau 34"},
  {coordinate_system:"IGNF:ETRS89UTM35", label: "ETRS89UTM35:ETRS89 UTM Nord fuseau 35"},
  {coordinate_system:"IGNF:ETRS89UTM36", label: "ETRS89UTM36:ETRS89 UTM Nord fuseau 36"},
  {coordinate_system:"IGNF:ETRS89UTM37", label: "ETRS89UTM37:ETRS89 UTM Nord fuseau 37"},
  {coordinate_system:"IGNF:ETRS89UTM38", label: "ETRS89UTM38:ETRS89 UTM Nord fuseau 38"},
  {coordinate_system:"IGNF:EURO54UTM37S", label: "EURO54UTM37S:EUROPA MHM 1954 UTM SUD FUSEAU 37"},
  {coordinate_system:"IGNF:EUROPA54MD", label: "EUROPA54MD:Europa Mercator directe"},
  {coordinate_system:"IGNF:FAKA50UTM6S", label: "FAKA50UTM6S:Fakarava (SHM 1947-1950) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:FANG651UTM7S", label: "FANG651UTM7S:Fangataufa (MHPF 1965-1) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:FANG652UTM7S", label: "FANG652UTM7S:Fangataufa (MHPF 1965-2) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:FANGA64UTM7S", label: "FANGA64UTM7S:Fangataufa (MHPF 1964) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:FANGA651U7S", label: "FANGA651U7S:Fangataufa (MHPF 1965-1) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:FANGA652U7S", label: "FANGA652U7S:Fangataufa (MHPF 1965-2) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:FANGA66UTM7S", label: "FANGA66UTM7S:Fangataufa (MHPF 1966) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:FANGA84UTM7S", label: "FANGA84UTM7S:Fangataufa (MOP84) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:GEOPORTALANF", label: "GEOPORTALANF:ANTILLES FRANCAISES PROJECTION GEOPORTAIL"},
  {coordinate_system:"IGNF:GEOPORTALASP", label: "GEOPORTALASP:Amsterdam Saint-Paul projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALCRZ", label: "GEOPORTALCRZ:Crozet projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALFXX", label: "GEOPORTALFXX:France Metropolitaine projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALGUF", label: "GEOPORTALGUF:Guyane Francaise projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALKER", label: "GEOPORTALKER:Kerguelen projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALMYT", label: "GEOPORTALMYT:Mayotte projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALNCL", label: "GEOPORTALNCL:Nouvelle Caledonie projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALPYF", label: "GEOPORTALPYF:Polynesie Francaise projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALREU", label: "GEOPORTALREU:Reunion projection Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALSPM", label: "GEOPORTALSPM:Saint-Pierre-et-Miquelon Geoportail"},
  {coordinate_system:"IGNF:GEOPORTALWLF", label: "GEOPORTALWLF:Wallis et Futuna projection Geoportail"},
  {coordinate_system:"IGNF:GLOR77MD", label: "GLOR77MD:Glorieuses Mercator directe"},
  {coordinate_system:"IGNF:GLOR77UTM38S", label: "GLOR77UTM38S:GLORIEUSES MHG 1977 UTM SUD FUSEAU 38"},
  {coordinate_system:"IGNF:GTN51UTM58S", label: "GTN51UTM58S:GOMEN TERME NORD 1951 UTM SUD FUSEAU 58"},
  {coordinate_system:"IGNF:GUAD48UTM20", label: "GUAD48UTM20:Guadeloupe Ste Anne UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:GUADANNUTM20", label: "GUADANNUTM20:Guadeloupe Ste Anne UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:GUADFM49U20", label: "GUADFM49U20:Guadeloupe Fort-Marigot UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:GUADFMUTM20", label: "GUADFMUTM20:Guadeloupe Fort-Marigot UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:HAO58UTM7S", label: "HAO58UTM7S:Hao (MHPF 1958) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:HAO67UTM7S", label: "HAO67UTM7S:Hao Amanu (MHPF 1967) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:HAOAM67UTM7S", label: "HAOAM67UTM7S:Hao Amanu (MHPF 1967) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:HARA49UTM7S", label: "HARA49UTM7S:Haraiki (SHM 1949) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:HIKU50UTM7S", label: "HIKU50UTM7S:Hikueru (SHM 1947-1950) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:HIVA60UTM7S", label: "HIVA60UTM7S:Hiva Oa (MHPF 1960) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:HIVA67UTM7S", label: "HIVA67UTM7S:Hiva Oa (MHPF 1967) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:IGN63UTM7S", label: "IGN63UTM7S:IGN 1963 UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:IGN72LAM", label: "IGN72LAM:IGN72 Lambert Nouvelle-Caledonie"},
  {coordinate_system:"IGNF:IGN72LAMBNC", label: "IGN72LAMBNC:IGN72 Lambert Nouvelle-Caledonie"},
  {coordinate_system:"IGNF:IGN72UTM58S", label: "IGN72UTM58S:IGN72 UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:KAUE70UTM6S", label: "KAUE70UTM6S:Kauehi (MHPF70) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:KERG62UTM42S", label: "KERG62UTM42S:KERGUELEN K0 IGN 1962 UTM SUD FUSEAU 42"},
  {coordinate_system:"IGNF:LAMB1", label: "LAMB1:NTF Lambert I"},
  {coordinate_system:"IGNF:LAMB1C", label: "LAMB1C:NTF Lambert I carto"},
  {coordinate_system:"IGNF:LAMB2", label: "LAMB2:NTF Lambert II"},
  {coordinate_system:"IGNF:LAMB2C", label: "LAMB2C:NTF Lambert II carto"},
  {coordinate_system:"IGNF:LAMB3", label: "LAMB3:NTF Lambert III"},
  {coordinate_system:"IGNF:LAMB3C", label: "LAMB3C:NTF Lambert III carto"},
  {coordinate_system:"IGNF:LAMB4", label: "LAMB4:NTF Lambert IV"},
  {coordinate_system:"IGNF:LAMB4C", label: "LAMB4C:NTF Lambert IV carto"},
  {coordinate_system:"IGNF:LAMB93", label: "LAMB93:RGF93 Lambert 93"},
  {coordinate_system:"IGNF:LAMBE", label: "LAMBE:NTF Lambert II etendu"},
  {coordinate_system:"IGNF:LAMBGC", label: "LAMBGC:NTF Lambert Grand Champ"},
  {coordinate_system:"IGNF:LIFOU56UT58S", label: "LIFOU56UT58S:Lifou IGN 56 UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:LNGLNG", label: "LNGLNG:Lambert Nord de Guerre Lambert Nord de Guerre"},
  {coordinate_system:"IGNF:LURESGKL", label: "LURESGKL:NOUVELLE TRIANGULATION DU DUCHE DU LUXEMBOURG GAUSS KRUGER LUXEMBOURG"},
  {coordinate_system:"IGNF:LUXGAUSSK", label: "LUXGAUSSK:NOUVELLE TRIANGULATION DU DUCHE DU LUXEMBOURG GAUSS KRUGER LUXEMBOURG"},
  {coordinate_system:"IGNF:MAKE50UTM7S", label: "MAKE50UTM7S:Makemo (SHM 1947-1950) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:MANGA51U8S", label: "MANGA51U8S:Mangareva 1951 UTM Sud fuseau 8"},
  {coordinate_system:"IGNF:MANGA51UTM8S", label: "MANGA51UTM8S:Mangareva 1951 UTM Sud fuseau 8"},
  {coordinate_system:"IGNF:MARE53UTM58S", label: "MARE53UTM58S:Mare IGN53 UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:MART38UTM20", label: "MART38UTM20:Martinique Fort-Desaix UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:MARTFDUTM20", label: "MARTFDUTM20:Martinique Fort-Desaix UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:MAUPITIUTM5S", label: "MAUPITIUTM5S:Maupiti (MOP 1983) UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:MAYO50UTM38S", label: "MAYO50UTM38S:Combani UTM Sud fuseau 38"},
  {coordinate_system:"IGNF:MHPF67UTM8S", label: "MHPF67UTM8S:MHPF 1967 UTM SUD FUSEAU 8"},
  {coordinate_system:"IGNF:MILLER", label: "MILLER:WGS84 PROJECTION MILLER GEOPORTAIL"},
  {coordinate_system:"IGNF:MOORE87UTM6S", label: "MOORE87UTM6S:Moorea 1987 UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:MOOREA87U6S", label: "MOOREA87U6S:Moorea 1987 UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:MOP90UTM6S", label: "MOP90UTM6S:Tetiaroa (MOP90) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:MURU59UTM7S", label: "MURU59UTM7S:Mururoa (MHPF 1959) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:MURU62UTM7S", label: "MURU62UTM7S:Mururoa (MHOI 1962) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:MURU78UTM7S", label: "MURU78UTM7S:Mururoa (IGN 1978) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:NC51UTM58S", label: "NC51UTM58S:GOMEN TERME NORD 1951 UTM SUD FUSEAU 58"},
  {coordinate_system:"IGNF:NEA74LBTNM2", label: "NEA74LBTNM2:NEA74 NOUMEA Lambert Noumea 2"},
  {coordinate_system:"IGNF:NOVA53UTM38S", label: "NOVA53UTM38S:JUAN DE NOVA MHM 1953 UTM SUD FUSEAU 38"},
  {coordinate_system:"IGNF:NTFLAMB1", label: "NTFLAMB1:NTF Lambert I"},
  {coordinate_system:"IGNF:NTFLAMB1C", label: "NTFLAMB1C:NTF Lambert I carto"},
  {coordinate_system:"IGNF:NTFLAMB2", label: "NTFLAMB2:NTF Lambert II"},
  {coordinate_system:"IGNF:NTFLAMB2C", label: "NTFLAMB2C:NTF Lambert II carto"},
  {coordinate_system:"IGNF:NTFLAMB2E", label: "NTFLAMB2E:NTF Lambert II etendu"},
  {coordinate_system:"IGNF:NTFLAMB3", label: "NTFLAMB3:NTF Lambert III"},
  {coordinate_system:"IGNF:NTFLAMB3C", label: "NTFLAMB3C:NTF Lambert III carto"},
  {coordinate_system:"IGNF:NTFLAMB4", label: "NTFLAMB4:NTF Lambert IV"},
  {coordinate_system:"IGNF:NTFLAMB4C", label: "NTFLAMB4C:NTF Lambert IV carto"},
  {coordinate_system:"IGNF:NTFLAMBGC", label: "NTFLAMBGC:NTF Lambert Grand Champ"},
  {coordinate_system:"IGNF:NUKU72U7S", label: "NUKU72U7S:Nuku Hiva UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:NUKU72UTM7S", label: "NUKU72UTM7S:Nuku Hiva UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:OUVE72UTM58S", label: "OUVE72UTM58S:Ouvea MHNC 1972 UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:OUVEA72U58S", label: "OUVEA72U58S:Ouvea MHNC 1972 UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:PDN08GAUSSL", label: "PDN08GAUSSL:Piton des Neiges (2008) Gauss Laborde Reunion"},
  {coordinate_system:"IGNF:PDN92GAUSSL", label: "PDN92GAUSSL:Piton des Neiges (1992) Gauss Laborde Reunion"},
  {coordinate_system:"IGNF:PGP50STEREPS", label: "PGP50STEREPS:TERRE ADELIE POINTE GEOLOGIE PERROUD STEREO POLAIRE SUD (TANGENTE)"},
  {coordinate_system:"IGNF:RAIA53UTM5S", label: "RAIA53UTM5S:IGN53 Societe UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:RAIV54UTM6S", label: "RAIV54UTM6S:Raivavae (Cadastre) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RAIV66UTM6S", label: "RAIV66UTM6S:Raivavae (MHPF 1966) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RANGI47U6S", label: "RANGI47U6S:Rangiroa (MGT 1947) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RANGI47UTM6S", label: "RANGI47UTM6S:Rangiroa (MGT 1947) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RANGI59U6S", label: "RANGI59U6S:Rangiroa (MHPF 1959) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RANGI59UTM6S", label: "RANGI59UTM6S:Rangiroa (MHPF 1959) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RANGI68U6S", label: "RANGI68U6S:Rangiroa (MHPF 1966-68) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RANGI68UTM6S", label: "RANGI68UTM6S:Rangiroa (MHPF 1966-68) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:REUN47GAUSSL", label: "REUN47GAUSSL:Reunion Piton des Neiges Gauss Laborde"},
  {coordinate_system:"IGNF:REUN49GAUSSL", label: "REUN49GAUSSL:Reunion Piton des Neiges Gauss Laborde"},
  {coordinate_system:"IGNF:RGAF09UTM20", label: "RGAF09UTM20:RGAF09 UTM Nord Fuseau 20"},
  {coordinate_system:"IGNF:RGF93CC42", label: "RGF93CC42:RGF93 CC42 zone 1"},
  {coordinate_system:"IGNF:RGF93CC43", label: "RGF93CC43:RGF93 CC43 zone 2"},
  {coordinate_system:"IGNF:RGF93CC44", label: "RGF93CC44:RGF93 CC44 zone 3"},
  {coordinate_system:"IGNF:RGF93CC45", label: "RGF93CC45:RGF93 CC45 zone 4"},
  {coordinate_system:"IGNF:RGF93CC46", label: "RGF93CC46:RGF93 CC46 zone 5"},
  {coordinate_system:"IGNF:RGF93CC47", label: "RGF93CC47:RGF93 CC47 zone 6"},
  {coordinate_system:"IGNF:RGF93CC48", label: "RGF93CC48:RGF93 CC48 zone 7"},
  {coordinate_system:"IGNF:RGF93CC49", label: "RGF93CC49:RGF93 CC49 zone 8"},
  {coordinate_system:"IGNF:RGF93CC50", label: "RGF93CC50:RGF93 CC50 zone 9"},
  {coordinate_system:"IGNF:RGF93EQGPFR", label: "RGF93EQGPFR:France Metropolitaine projection Geoportail"},
  {coordinate_system:"IGNF:RGF93LAMB93", label: "RGF93LAMB93:RGF93 Lambert 93"},
  {coordinate_system:"IGNF:RGF93UTM30", label: "RGF93UTM30:RGF93 UTM NORD FUSEAU 30"},
  {coordinate_system:"IGNF:RGF93UTM31", label: "RGF93UTM31:RGF93 UTM fuseau 31"},
  {coordinate_system:"IGNF:RGF93UTM32", label: "RGF93UTM32:RGF93 UTM fuseau 32"},
  {coordinate_system:"IGNF:RGFG95EQGPGU", label: "RGFG95EQGPGU:Guyane Francaise projection Geoportail"},
  {coordinate_system:"IGNF:RGFG95UTM21", label: "RGFG95UTM21:RGFG95 UTM Nord f.21"},
  {coordinate_system:"IGNF:RGFG95UTM22", label: "RGFG95UTM22:RGFG95 UTM Nord f.22"},
  {coordinate_system:"IGNF:RGM04EQGPMYT", label: "RGM04EQGPMYT:Mayotte projection Geoportail"},
  {coordinate_system:"IGNF:RGM04UTM38S", label: "RGM04UTM38S:RGM04 UTM Sud fuseau 38"},
  {coordinate_system:"IGNF:RGNC91EQGPNC", label: "RGNC91EQGPNC:Nouvelle Caledonie projection Geoportail"},
  {coordinate_system:"IGNF:RGNCLAM", label: "RGNCLAM:RGNC Lambert Nouvelle-Caledonie"},
  {coordinate_system:"IGNF:RGNCLAMBNC", label: "RGNCLAMBNC:RGNC Lambert Nouvelle-Caledonie"},
  {coordinate_system:"IGNF:RGNCUTM57S", label: "RGNCUTM57S:RGNC UTM Sud fuseau 57"},
  {coordinate_system:"IGNF:RGNCUTM58S", label: "RGNCUTM58S:RGNC UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:RGNCUTM59S", label: "RGNCUTM59S:RGNC UTM Sud fuseau 59"},
  {coordinate_system:"IGNF:RGPFEQGPPF", label: "RGPFEQGPPF:Polynesie Francaise projection Geoportail"},
  {coordinate_system:"IGNF:RGPFUTM5S", label: "RGPFUTM5S:RGPF UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:RGPFUTM6S", label: "RGPFUTM6S:RGPF UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:RGPFUTM7S", label: "RGPFUTM7S:RGPF UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:RGPFUTM8S", label: "RGPFUTM8S:RGPF UTM SUD FUSEAU 8"},
  {coordinate_system:"IGNF:RGR92EQGPREU", label: "RGR92EQGPREU:Reunion projection Geoportail"},
  {coordinate_system:"IGNF:RGR92UTM40S", label: "RGR92UTM40S:RGR92 UTM 40 Sud"},
  {coordinate_system:"IGNF:RGSPM06EQGP", label: "RGSPM06EQGP:Saint-Pierre-et-Miquelon Geoportail"},
  {coordinate_system:"IGNF:RGSPM06U21", label: "RGSPM06U21:RGSPM06 UTM Nord fuseau 21"},
  {coordinate_system:"IGNF:RGTAAFUTM37S", label: "RGTAAFUTM37S:RGTAAF07 UTM Sud fuseau 37"},
  {coordinate_system:"IGNF:RGTAAFUTM39S", label: "RGTAAFUTM39S:RGTAAF07 UTM Sud fuseau 39"},
  {coordinate_system:"IGNF:RGTAAFUTM42S", label: "RGTAAFUTM42S:RGTAAF07 UTM Sud fuseau 42"},
  {coordinate_system:"IGNF:RGTAAFUTM43S", label: "RGTAAFUTM43S:RGTAAF07 UTM Sud fuseau 43"},
  {coordinate_system:"IGNF:RGTAAFUTM53S", label: "RGTAAFUTM53S:RGTAAF07 UTM SUD FUSEAU 53"},
  {coordinate_system:"IGNF:RGWF96UTM1S", label: "RGWF96UTM1S:RESEAU GEODESIQUE DE WALLIS ET FUTUNA 1996 UTM SUD FUSEAU 1"},
  {coordinate_system:"IGNF:RRAFUTM20", label: "RRAFUTM20:RRAF UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:RUSAT84UTM5S", label: "RUSAT84UTM5S:Rurutu (SAT84) UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:SAT84UTM5S", label: "SAT84UTM5S:Rurutu (SAT84) UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:ST84UTM58S", label: "ST84UTM58S:ST 84 ILE DES PINS UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:ST87UTM58S", label: "ST87UTM58S:ST 87 OUVEA UTM Sud fuseau 58"},
  {coordinate_system:"IGNF:STPL69UTM43S", label: "STPL69UTM43S:Saint-Paul UTM Sud fuseau 43"},
  {coordinate_system:"IGNF:STPM50UTM21", label: "STPM50UTM21:St-Pierre-et-Miquelon UTM Nord f.21"},
  {coordinate_system:"IGNF:SYSLN", label: "SYSLN:Lambert Nord de Guerre Lambert Nord de Guerre"},
  {coordinate_system:"IGNF:TAHAA53UTM5S", label: "TAHAA53UTM5S:IGN53 Societe UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:TAHAAUTM05S", label: "TAHAAUTM05S:Tahaa UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:TAHAAUTM5S", label: "TAHAAUTM5S:Tahaa UTM Sud fuseau 5"},
  {coordinate_system:"IGNF:TAHI51UTM06S", label: "TAHI51UTM06S:TAHITI TERME NORD UTM SUD FUSEAU 6"},
  {coordinate_system:"IGNF:TAHI51UTM6S", label: "TAHI51UTM6S:TAHITI TERME NORD UTM SUD FUSEAU 6"},
  {coordinate_system:"IGNF:TAHI79UTM6S", label: "TAHI79UTM6S:Tahiti (IGN79) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:TAKA69UTM6S", label: "TAKA69UTM6S:Takaroa Takapoto (SHM 1969) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:TANNAUTM59S", label: "TANNAUTM59S:Tanna UTM Sud fuseau 59"},
  {coordinate_system:"IGNF:TERA50STEREO", label: "TERA50STEREO:TERRE ADELIE POINTE GEOLOGIE PERROUD STEREO POLAIRE SUD (TANGENTE)"},
  {coordinate_system:"IGNF:TETIA90UTM6S", label: "TETIA90UTM6S:Tetiaroa (MOP90) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:TIKE50UTM6S", label: "TIKE50UTM6S:Tikehau (SHM 1947-1950) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:TIKE60UTM6S", label: "TIKE60UTM6S:Tikehau (MHPF 1960) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:TIKE88UTM6S", label: "TIKE88UTM6S:TIKEHAU (MOP88) UTM SUD FUSEAU 6"},
  {coordinate_system:"IGNF:TROM56MD", label: "TROM56MD:TROMELIN SGM 1956 MERCATOR DIRECTE"},
  {coordinate_system:"IGNF:TUAM86UTM6S", label: "TUAM86UTM6S:Tuamotu (MOP86) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:TUAM86UTM7S", label: "TUAM86UTM7S:Tuamotu (MOP86) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:TUBU69UTM6S", label: "TUBU69UTM6S:Tubuai (MHPF 1969) UTM Sud fuseau 6"},
  {coordinate_system:"IGNF:TURI69UTM7S", label: "TURI69UTM7S:Tureia (SHM 1969) UTM Sud fuseau 7"},
  {coordinate_system:"IGNF:UTM01SW84", label: "UTM01SW84:WGS84 UTM Sud Fuseau 1"},
  {coordinate_system:"IGNF:UTM20W84MART", label: "UTM20W84MART:RRAF UTM Nord fuseau 20"},
  {coordinate_system:"IGNF:UTM21RGFG95", label: "UTM21RGFG95:RGFG95 UTM Nord f.21"},
  {coordinate_system:"IGNF:UTM22RGFG95", label: "UTM22RGFG95:RGFG95 UTM Nord f.22"},
  {coordinate_system:"IGNF:UTM26ETRS89", label: "UTM26ETRS89:ETRS89 UTM Nord fuseau 26"},
  {coordinate_system:"IGNF:UTM27ETRS89", label: "UTM27ETRS89:ETRS89 UTM Nord fuseau 27"},
  {coordinate_system:"IGNF:UTM28ETRS89", label: "UTM28ETRS89:ETRS89 UTM Nord fuseau 28"},
  {coordinate_system:"IGNF:UTM29ETRS89", label: "UTM29ETRS89:ETRS89 UTM Nord fuseau 29"},
  {coordinate_system:"IGNF:UTM30", label: "UTM30:ED50 UTM fuseau 30"},
  {coordinate_system:"IGNF:UTM30ETRS89", label: "UTM30ETRS89:ETRS89 UTM Nord fuseau 30"},
  {coordinate_system:"IGNF:UTM30RGF93", label: "UTM30RGF93:RGF93 UTM NORD FUSEAU 30"},
  {coordinate_system:"IGNF:UTM30W72", label: "UTM30W72:WGS72 UTM fuseau 30"},
  {coordinate_system:"IGNF:UTM30W84", label: "UTM30W84:WGS84 UTM fuseau 30"},
  {coordinate_system:"IGNF:UTM31", label: "UTM31:ED50 UTM NORD FUSEAU 31"},
  {coordinate_system:"IGNF:UTM31ETRS89", label: "UTM31ETRS89:ETRS89 UTM Nord fuseau 31"},
  {coordinate_system:"IGNF:UTM31RGF93", label: "UTM31RGF93:RGF93 UTM fuseau 31"},
  {coordinate_system:"IGNF:UTM31W72", label: "UTM31W72:WGS72 UTM fuseau 31"},
  {coordinate_system:"IGNF:UTM31W84", label: "UTM31W84:WGS84 UTM fuseau 31"},
  {coordinate_system:"IGNF:UTM32", label: "UTM32:ED50 UTM fuseau 32"},
  {coordinate_system:"IGNF:UTM32ETRS89", label: "UTM32ETRS89:ETRS89 UTM Nord fuseau 32"},
  {coordinate_system:"IGNF:UTM32RGF93", label: "UTM32RGF93:RGF93 UTM fuseau 32"},
  {coordinate_system:"IGNF:UTM32W72", label: "UTM32W72:WGS72 UTM fuseau 32"},
  {coordinate_system:"IGNF:UTM32W84", label: "UTM32W84:WGS84 UTM NORD FUSEAU 32"},
  {coordinate_system:"IGNF:UTM33ETRS89", label: "UTM33ETRS89:ETRS89 UTM Nord fuseau 33"},
  {coordinate_system:"IGNF:UTM34ETRS89", label: "UTM34ETRS89:ETRS89 UTM Nord fuseau 34"},
  {coordinate_system:"IGNF:UTM35ETRS89", label: "UTM35ETRS89:ETRS89 UTM Nord fuseau 35"},
  {coordinate_system:"IGNF:UTM36ETRS89", label: "UTM36ETRS89:ETRS89 UTM Nord fuseau 36"},
  {coordinate_system:"IGNF:UTM37ETRS89", label: "UTM37ETRS89:ETRS89 UTM Nord fuseau 37"},
  {coordinate_system:"IGNF:UTM38ETRS89", label: "UTM38ETRS89:ETRS89 UTM Nord fuseau 38"},
  {coordinate_system:"IGNF:UTM39SW84", label: "UTM39SW84:WGS84 UTM SUD FUSEAU 39"},
  {coordinate_system:"IGNF:UTM42SW84", label: "UTM42SW84:WGS84 UTM SUD FUSEAU 42"},
  {coordinate_system:"IGNF:UTM43SW84", label: "UTM43SW84:WGS84 UTM SUD FUSEAU 43"},
  {coordinate_system:"IGNF:WALL76UTM1S", label: "WALL76UTM1S:Wallis (MOP 1976) UTM Sud fuseau 1"},
  {coordinate_system:"IGNF:WALL78UTM1S", label: "WALL78UTM1S:Wallis (MOP 1978) UTM Sud fuseau 1"},
  {coordinate_system:"IGNF:WGS72UTM1S", label: "WGS72UTM1S:WGS72 UTM Sud Fuseau 1"},
  {coordinate_system:"IGNF:WGS72UTM30", label: "WGS72UTM30:WGS72 UTM fuseau 30"},
  {coordinate_system:"IGNF:WGS72UTM31", label: "WGS72UTM31:WGS72 UTM fuseau 31"},
  {coordinate_system:"IGNF:WGS72UTM32", label: "WGS72UTM32:WGS72 UTM fuseau 32"},
  {coordinate_system:"IGNF:WGS84EQGPASP", label: "WGS84EQGPASP:Amsterdam Saint-Paul projection Geoportail"},
  {coordinate_system:"IGNF:WGS84EQGPCRZ", label: "WGS84EQGPCRZ:Crozet projection Geoportail"},
  {coordinate_system:"IGNF:WGS84EQGPKER", label: "WGS84EQGPKER:Kerguelen projection Geoportail"},
  {coordinate_system:"IGNF:WGS84EQGPWF", label: "WGS84EQGPWF:Wallis et Futuna projection Geoportail"},
  {coordinate_system:"IGNF:WGS84GPAF", label: "WGS84GPAF:ANTILLES FRANCAISES PROJECTION GEOPORTAIL"},
  {coordinate_system:"IGNF:WGS84MILLGP", label: "WGS84MILLGP:WGS84 PROJECTION MILLER GEOPORTAIL"},
  {coordinate_system:"IGNF:WGS84UTM1S", label: "WGS84UTM1S:WGS84 UTM Sud Fuseau 1"},
  {coordinate_system:"IGNF:WGS84UTM30", label: "WGS84UTM30:WGS84 UTM fuseau 30"},
  {coordinate_system:"IGNF:WGS84UTM31", label: "WGS84UTM31:WGS84 UTM fuseau 31"},
  {coordinate_system:"IGNF:WGS84UTM32", label: "WGS84UTM32:WGS84 UTM NORD FUSEAU 32"},
  {coordinate_system:"IGNF:WGS84UTM39S", label: "WGS84UTM39S:WGS84 UTM SUD FUSEAU 39"},
  {coordinate_system:"IGNF:WGS84UTM42S", label: "WGS84UTM42S:WGS84 UTM SUD FUSEAU 42"},
  {coordinate_system:"IGNF:WGS84UTM43S", label: "WGS84UTM43S:WGS84 UTM SUD FUSEAU 43"},
  {coordinate_system:"IGNF:WGS84WMSV", label: "WGS84WMSV:WGS84 WEB MERCATOR SPHERIQUE (VISUALISATION)"},
  {coordinate_system:"EPSG:3819", label: "3819:HD1909"},
  {coordinate_system:"EPSG:3821", label: "3821:TWD67"},
  {coordinate_system:"EPSG:3823", label: "3823:TWD97"},
  {coordinate_system:"EPSG:3824", label: "3824:TWD97"},
  {coordinate_system:"EPSG:3888", label: "3888:IGRS"},
  {coordinate_system:"EPSG:3889", label: "3889:IGRS"},
  {coordinate_system:"EPSG:3906", label: "3906:MGI 1901"},
  {coordinate_system:"EPSG:4017", label: "4017:MOLDREF99"},
  {coordinate_system:"EPSG:4023", label: "4023:MOLDREF99"},
  {coordinate_system:"EPSG:4040", label: "4040:RGRDC 2005"},
  {coordinate_system:"EPSG:4046", label: "4046:RGRDC 2005"},
  {coordinate_system:"EPSG:4074", label: "4074:SREF98"},
  {coordinate_system:"EPSG:4075", label: "4075:SREF98"},
  {coordinate_system:"EPSG:4080", label: "4080:REGCAN95"},
  {coordinate_system:"EPSG:4081", label: "4081:REGCAN95"},
  {coordinate_system:"EPSG:4120", label: "4120:Greek"},
  {coordinate_system:"EPSG:4121", label: "4121:GGRS87"},
  {coordinate_system:"EPSG:4122", label: "4122:ATS77"},
  {coordinate_system:"EPSG:4123", label: "4123:KKJ"},
  {coordinate_system:"EPSG:4124", label: "4124:RT90"},
  {coordinate_system:"EPSG:4127", label: "4127:Tete"},
  {coordinate_system:"EPSG:4128", label: "4128:Madzansua"},
  {coordinate_system:"EPSG:4129", label: "4129:Observatario"},
  {coordinate_system:"EPSG:4130", label: "4130:Moznet"},
  {coordinate_system:"EPSG:4131", label: "4131:Indian 1960"},
  {coordinate_system:"EPSG:4132", label: "4132:FD58"},
  {coordinate_system:"EPSG:4133", label: "4133:EST92"},
  {coordinate_system:"EPSG:4134", label: "4134:PSD93"},
  {coordinate_system:"EPSG:4135", label: "4135:Old Hawaiian"},
  {coordinate_system:"EPSG:4136", label: "4136:St. Lawrence Island"},
  {coordinate_system:"EPSG:4137", label: "4137:St. Paul Island"},
  {coordinate_system:"EPSG:4138", label: "4138:St. George Island"},
  {coordinate_system:"EPSG:4139", label: "4139:Puerto Rico"},
  {coordinate_system:"EPSG:4141", label: "4141:Israel 1993"},
  {coordinate_system:"EPSG:4142", label: "4142:Locodjo 1965"},
  {coordinate_system:"EPSG:4143", label: "4143:Abidjan 1987"},
  {coordinate_system:"EPSG:4144", label: "4144:Kalianpur 1937"},
  {coordinate_system:"EPSG:4145", label: "4145:Kalianpur 1962"},
  {coordinate_system:"EPSG:4146", label: "4146:Kalianpur 1975"},
  {coordinate_system:"EPSG:4147", label: "4147:Hanoi 1972"},
  {coordinate_system:"EPSG:4148", label: "4148:Hartebeesthoek94"},
  {coordinate_system:"EPSG:4149", label: "4149:CH1903"},
  {coordinate_system:"EPSG:4150", label: "4150:CH1903+"},
  {coordinate_system:"EPSG:4151", label: "4151:CHTRS95"},
  {coordinate_system:"EPSG:4152", label: "4152:NAD83(HARN)"},
  {coordinate_system:"EPSG:4153", label: "4153:Rassadiran"},
  {coordinate_system:"EPSG:4154", label: "4154:ED50(ED77)"},
  {coordinate_system:"EPSG:4155", label: "4155:Dabola 1981"},
  {coordinate_system:"EPSG:4156", label: "4156:S-JTSK"},
  {coordinate_system:"EPSG:4157", label: "4157:Mount Dillon"},
  {coordinate_system:"EPSG:4158", label: "4158:Naparima 1955"},
  {coordinate_system:"EPSG:4159", label: "4159:ELD79"},
  {coordinate_system:"EPSG:4160", label: "4160:Chos Malal 1914"},
  {coordinate_system:"EPSG:4161", label: "4161:Pampa del Castillo"},
  {coordinate_system:"EPSG:4162", label: "4162:Korean 1985"},
  {coordinate_system:"EPSG:4163", label: "4163:Yemen NGN96"},
  {coordinate_system:"EPSG:4164", label: "4164:South Yemen"},
  {coordinate_system:"EPSG:4165", label: "4165:Bissau"},
  {coordinate_system:"EPSG:4166", label: "4166:Korean 1995"},
  {coordinate_system:"EPSG:4167", label: "4167:NZGD2000"},
  {coordinate_system:"EPSG:4168", label: "4168:Accra"},
  {coordinate_system:"EPSG:4169", label: "4169:American Samoa 1962"},
  {coordinate_system:"EPSG:4170", label: "4170:SIRGAS 1995"},
  {coordinate_system:"EPSG:4171", label: "4171:RGF93 v1"},
  {coordinate_system:"EPSG:4173", label: "4173:IRENET95"},
  {coordinate_system:"EPSG:4174", label: "4174:Sierra Leone 1924"},
  {coordinate_system:"EPSG:4175", label: "4175:Sierra Leone 1968"},
  {coordinate_system:"EPSG:4176", label: "4176:Australian Antarctic"},
  {coordinate_system:"EPSG:4178", label: "4178:Pulkovo 1942(83)"},
  {coordinate_system:"EPSG:4179", label: "4179:Pulkovo 1942(58)"},
  {coordinate_system:"EPSG:4180", label: "4180:EST97"},
  {coordinate_system:"EPSG:4181", label: "4181:LUREF"},
  {coordinate_system:"EPSG:4182", label: "4182:Azores Occidental 1939"},
  {coordinate_system:"EPSG:4183", label: "4183:Azores Central 1948"},
  {coordinate_system:"EPSG:4184", label: "4184:Azores Oriental 1940"},
  {coordinate_system:"EPSG:4188", label: "4188:OSNI 1952"},
  {coordinate_system:"EPSG:4189", label: "4189:REGVEN"},
  {coordinate_system:"EPSG:4190", label: "4190:POSGAR 98"},
  {coordinate_system:"EPSG:4191", label: "4191:Albanian 1987"},
  {coordinate_system:"EPSG:4192", label: "4192:Douala 1948"},
  {coordinate_system:"EPSG:4193", label: "4193:Manoca 1962"},
  {coordinate_system:"EPSG:4194", label: "4194:Qornoq 1927"},
  {coordinate_system:"EPSG:4195", label: "4195:Scoresbysund 1952"},
  {coordinate_system:"EPSG:4196", label: "4196:Ammassalik 1958"},
  {coordinate_system:"EPSG:4197", label: "4197:Garoua"},
  {coordinate_system:"EPSG:4198", label: "4198:Kousseri"},
  {coordinate_system:"EPSG:4199", label: "4199:Egypt 1930"},
  {coordinate_system:"EPSG:4200", label: "4200:Pulkovo 1995"},
  {coordinate_system:"EPSG:4201", label: "4201:Adindan"},
  {coordinate_system:"EPSG:4202", label: "4202:AGD66"},
  {coordinate_system:"EPSG:4203", label: "4203:AGD84"},
  {coordinate_system:"EPSG:4204", label: "4204:Ain el Abd"},
  {coordinate_system:"EPSG:4205", label: "4205:Afgooye"},
  {coordinate_system:"EPSG:4206", label: "4206:Agadez"},
  {coordinate_system:"EPSG:4207", label: "4207:Lisbon"},
  {coordinate_system:"EPSG:4208", label: "4208:Aratu"},
  {coordinate_system:"EPSG:4209", label: "4209:Arc 1950"},
  {coordinate_system:"EPSG:4210", label: "4210:Arc 1960"},
  {coordinate_system:"EPSG:4211", label: "4211:Batavia"},
  {coordinate_system:"EPSG:4212", label: "4212:Barbados 1938"},
  {coordinate_system:"EPSG:4213", label: "4213:Beduaram"},
  {coordinate_system:"EPSG:4214", label: "4214:Beijing 1954"},
  {coordinate_system:"EPSG:4215", label: "4215:BD50"},
  {coordinate_system:"EPSG:4216", label: "4216:Bermuda 1957"},
  {coordinate_system:"EPSG:4218", label: "4218:Bogota 1975"},
  {coordinate_system:"EPSG:4219", label: "4219:Bukit Rimpah"},
  {coordinate_system:"EPSG:4220", label: "4220:Camacupa 1948"},
  {coordinate_system:"EPSG:4221", label: "4221:Campo Inchauspe"},
  {coordinate_system:"EPSG:4222", label: "4222:Cape"},
  {coordinate_system:"EPSG:4223", label: "4223:Carthage"},
  {coordinate_system:"EPSG:4224", label: "4224:Chua"},
  {coordinate_system:"EPSG:4225", label: "4225:Corrego Alegre 1970-72"},
  {coordinate_system:"EPSG:4227", label: "4227:Deir ez Zor"},
  {coordinate_system:"EPSG:4229", label: "4229:Egypt 1907"},
  {coordinate_system:"EPSG:4230", label: "4230:ED50"},
  {coordinate_system:"EPSG:4231", label: "4231:ED87"},
  {coordinate_system:"EPSG:4232", label: "4232:Fahud"},
  {coordinate_system:"EPSG:4236", label: "4236:Hu Tzu Shan 1950"},
  {coordinate_system:"EPSG:4237", label: "4237:HD72"},
  {coordinate_system:"EPSG:4238", label: "4238:ID74"},
  {coordinate_system:"EPSG:4239", label: "4239:Indian 1954"},
  {coordinate_system:"EPSG:4240", label: "4240:Indian 1975"},
  {coordinate_system:"EPSG:4241", label: "4241:Jamaica 1875"},
  {coordinate_system:"EPSG:4242", label: "4242:JAD69"},
  {coordinate_system:"EPSG:4243", label: "4243:Kalianpur 1880"},
  {coordinate_system:"EPSG:4244", label: "4244:Kandawala"},
  {coordinate_system:"EPSG:4245", label: "4245:Kertau 1968"},
  {coordinate_system:"EPSG:4246", label: "4246:KOC"},
  {coordinate_system:"EPSG:4247", label: "4247:La Canoa"},
  {coordinate_system:"EPSG:4248", label: "4248:PSAD56"},
  {coordinate_system:"EPSG:4249", label: "4249:Lake"},
  {coordinate_system:"EPSG:4250", label: "4250:Leigon"},
  {coordinate_system:"EPSG:4251", label: "4251:Liberia 1964"},
  {coordinate_system:"EPSG:4252", label: "4252:Lome"},
  {coordinate_system:"EPSG:4253", label: "4253:Luzon 1911"},
  {coordinate_system:"EPSG:4254", label: "4254:Hito XVIII 1963"},
  {coordinate_system:"EPSG:4255", label: "4255:Herat North"},
  {coordinate_system:"EPSG:4256", label: "4256:Mahe 1971"},
  {coordinate_system:"EPSG:4257", label: "4257:Makassar"},
  {coordinate_system:"EPSG:4258", label: "4258:ETRS89"},
  {coordinate_system:"EPSG:4259", label: "4259:Malongo 1987"},
  {coordinate_system:"EPSG:4261", label: "4261:Merchich"},
  {coordinate_system:"EPSG:4262", label: "4262:Massawa"},
  {coordinate_system:"EPSG:4263", label: "4263:Minna"},
  {coordinate_system:"EPSG:4265", label: "4265:Monte Mario"},
  {coordinate_system:"EPSG:4266", label: "4266:M'poraloko"},
  {coordinate_system:"EPSG:4267", label: "4267:NAD27"},
  {coordinate_system:"EPSG:4269", label: "4269:NAD83"},
  {coordinate_system:"EPSG:4270", label: "4270:Nahrwan 1967"},
  {coordinate_system:"EPSG:4271", label: "4271:Naparima 1972"},
  {coordinate_system:"EPSG:4272", label: "4272:NZGD49"},
  {coordinate_system:"EPSG:4273", label: "4273:NGO 1948"},
  {coordinate_system:"EPSG:4274", label: "4274:Datum 73"},
  {coordinate_system:"EPSG:4275", label: "4275:NTF"},
  {coordinate_system:"EPSG:4276", label: "4276:NSWC 9Z-2"},
  {coordinate_system:"EPSG:4277", label: "4277:OSGB36"},
  {coordinate_system:"EPSG:4278", label: "4278:OSGB70"},
  {coordinate_system:"EPSG:4279", label: "4279:OS(SN)80"},
  {coordinate_system:"EPSG:4281", label: "4281:Palestine 1923"},
  {coordinate_system:"EPSG:4282", label: "4282:Pointe Noire"},
  {coordinate_system:"EPSG:4283", label: "4283:GDA94"},
  {coordinate_system:"EPSG:4284", label: "4284:Pulkovo 1942"},
  {coordinate_system:"EPSG:4285", label: "4285:Qatar 1974"},
  {coordinate_system:"EPSG:4286", label: "4286:Qatar 1948"},
  {coordinate_system:"EPSG:4288", label: "4288:Loma Quintana"},
  {coordinate_system:"EPSG:4289", label: "4289:Amersfoort"},
  {coordinate_system:"EPSG:4292", label: "4292:Sapper Hill 1943"},
  {coordinate_system:"EPSG:4293", label: "4293:Schwarzeck"},
  {coordinate_system:"EPSG:4295", label: "4295:Serindung"},
  {coordinate_system:"EPSG:4297", label: "4297:Tananarive"},
  {coordinate_system:"EPSG:4298", label: "4298:Timbalai 1948"},
  {coordinate_system:"EPSG:4299", label: "4299:TM65"},
  {coordinate_system:"EPSG:4300", label: "4300:TM75"},
  {coordinate_system:"EPSG:4301", label: "4301:Tokyo"},
  {coordinate_system:"EPSG:4302", label: "4302:Trinidad 1903"},
  {coordinate_system:"EPSG:4303", label: "4303:TC(1948)"},
  {coordinate_system:"EPSG:4304", label: "4304:Voirol 1875"},
  {coordinate_system:"EPSG:4306", label: "4306:Bern 1938"},
  {coordinate_system:"EPSG:4307", label: "4307:Nord Sahara 1959"},
  {coordinate_system:"EPSG:4308", label: "4308:RT38"},
  {coordinate_system:"EPSG:4309", label: "4309:Yacare"},
  {coordinate_system:"EPSG:4310", label: "4310:Yoff"},
  {coordinate_system:"EPSG:4311", label: "4311:Zanderij"},
  {coordinate_system:"EPSG:4312", label: "4312:MGI"},
  {coordinate_system:"EPSG:4313", label: "4313:BD72"},
  {coordinate_system:"EPSG:4314", label: "4314:DHDN"},
  {coordinate_system:"EPSG:4315", label: "4315:Conakry 1905"},
  {coordinate_system:"EPSG:4316", label: "4316:Dealul Piscului 1930"},
  {coordinate_system:"EPSG:4318", label: "4318:NGN"},
  {coordinate_system:"EPSG:4319", label: "4319:KUDAMS"},
  {coordinate_system:"EPSG:4322", label: "4322:WGS 72"},
  {coordinate_system:"EPSG:4324", label: "4324:WGS 72BE"},
  {coordinate_system:"EPSG:4326", label: "4326:WGS 84"},
  {coordinate_system:"EPSG:4463", label: "4463:RGSPM06"},
  {coordinate_system:"EPSG:4466", label: "4466:RGSPM06"},
  {coordinate_system:"EPSG:4469", label: "4469:RGM04"},
  {coordinate_system:"EPSG:4470", label: "4470:RGM04"},
  {coordinate_system:"EPSG:4472", label: "4472:Cadastre 1997"},
  {coordinate_system:"EPSG:4475", label: "4475:Cadastre 1997"},
  {coordinate_system:"EPSG:4480", label: "4480:China Geodetic Coordinate System 2000"},
  {coordinate_system:"EPSG:4482", label: "4482:Mexico ITRF92"},
  {coordinate_system:"EPSG:4483", label: "4483:Mexico ITRF92"},
  {coordinate_system:"EPSG:4490", label: "4490:China Geodetic Coordinate System 2000"},
  {coordinate_system:"EPSG:4555", label: "4555:New Beijing"},
  {coordinate_system:"EPSG:4557", label: "4557:RRAF 1991"},
  {coordinate_system:"EPSG:4558", label: "4558:RRAF 1991"},
  {coordinate_system:"EPSG:4600", label: "4600:Anguilla 1957"},
  {coordinate_system:"EPSG:4601", label: "4601:Antigua 1943"},
  {coordinate_system:"EPSG:4602", label: "4602:Dominica 1945"},
  {coordinate_system:"EPSG:4603", label: "4603:Grenada 1953"},
  {coordinate_system:"EPSG:4604", label: "4604:Montserrat 1958"},
  {coordinate_system:"EPSG:4605", label: "4605:St. Kitts 1955"},
  {coordinate_system:"EPSG:4606", label: "4606:St. Lucia 1955"},
  {coordinate_system:"EPSG:4607", label: "4607:St. Vincent 1945"},
  {coordinate_system:"EPSG:4608", label: "4608:NAD27(76)"},
  {coordinate_system:"EPSG:4609", label: "4609:NAD27(CGQ77)"},
  {coordinate_system:"EPSG:4610", label: "4610:Xian 1980"},
  {coordinate_system:"EPSG:4611", label: "4611:Hong Kong 1980"},
  {coordinate_system:"EPSG:4612", label: "4612:JGD2000"},
  {coordinate_system:"EPSG:4613", label: "4613:Segara"},
  {coordinate_system:"EPSG:4614", label: "4614:QND95"},
  {coordinate_system:"EPSG:4615", label: "4615:Porto Santo"},
  {coordinate_system:"EPSG:4616", label: "4616:Selvagem Grande"},
  {coordinate_system:"EPSG:4617", label: "4617:NAD83(CSRS)"},
  {coordinate_system:"EPSG:4618", label: "4618:SAD69"},
  {coordinate_system:"EPSG:4619", label: "4619:SWEREF99"},
  {coordinate_system:"EPSG:4620", label: "4620:Point 58"},
  {coordinate_system:"EPSG:4621", label: "4621:Fort Marigot"},
  {coordinate_system:"EPSG:4622", label: "4622:Guadeloupe 1948"},
  {coordinate_system:"EPSG:4623", label: "4623:CSG67"},
  {coordinate_system:"EPSG:4624", label: "4624:RGFG95"},
  {coordinate_system:"EPSG:4625", label: "4625:Martinique 1938"},
  {coordinate_system:"EPSG:4626", label: "4626:Reunion 1947"},
  {coordinate_system:"EPSG:4627", label: "4627:RGR92"},
  {coordinate_system:"EPSG:4628", label: "4628:Tahiti 52"},
  {coordinate_system:"EPSG:4629", label: "4629:Tahaa 54"},
  {coordinate_system:"EPSG:4630", label: "4630:IGN72 Nuku Hiva"},
  {coordinate_system:"EPSG:4632", label: "4632:Combani 1950"},
  {coordinate_system:"EPSG:4633", label: "4633:IGN56 Lifou"},
  {coordinate_system:"EPSG:4636", label: "4636:Petrels 1972"},
  {coordinate_system:"EPSG:4637", label: "4637:Perroud 1950"},
  {coordinate_system:"EPSG:4638", label: "4638:Saint Pierre et Miquelon 1950"},
  {coordinate_system:"EPSG:4639", label: "4639:MOP78"},
  {coordinate_system:"EPSG:4641", label: "4641:IGN53 Mare"},
  {coordinate_system:"EPSG:4642", label: "4642:ST84 Ile des Pins"},
  {coordinate_system:"EPSG:4643", label: "4643:ST71 Belep"},
  {coordinate_system:"EPSG:4644", label: "4644:NEA74 Noumea"},
  {coordinate_system:"EPSG:4646", label: "4646:Grand Comoros"},
  {coordinate_system:"EPSG:4657", label: "4657:Reykjavik 1900"},
  {coordinate_system:"EPSG:4658", label: "4658:Hjorsey 1955"},
  {coordinate_system:"EPSG:4659", label: "4659:ISN93"},
  {coordinate_system:"EPSG:4660", label: "4660:Helle 1954"},
  {coordinate_system:"EPSG:4661", label: "4661:LKS92"},
  {coordinate_system:"EPSG:4662", label: "4662:IGN72 Grande Terre"},
  {coordinate_system:"EPSG:4663", label: "4663:Porto Santo 1995"},
  {coordinate_system:"EPSG:4664", label: "4664:Azores Oriental 1995"},
  {coordinate_system:"EPSG:4665", label: "4665:Azores Central 1995"},
  {coordinate_system:"EPSG:4666", label: "4666:Lisbon 1890"},
  {coordinate_system:"EPSG:4667", label: "4667:IKBD-92"},
  {coordinate_system:"EPSG:4668", label: "4668:ED79"},
  {coordinate_system:"EPSG:4669", label: "4669:LKS94"},
  {coordinate_system:"EPSG:4670", label: "4670:IGM95"},
  {coordinate_system:"EPSG:4671", label: "4671:Voirol 1879"},
  {coordinate_system:"EPSG:4672", label: "4672:Chatham Islands 1971"},
  {coordinate_system:"EPSG:4673", label: "4673:Chatham Islands 1979"},
  {coordinate_system:"EPSG:4674", label: "4674:SIRGAS 2000"},
  {coordinate_system:"EPSG:4675", label: "4675:Guam 1963"},
  {coordinate_system:"EPSG:4676", label: "4676:Vientiane 1982"},
  {coordinate_system:"EPSG:4677", label: "4677:Lao 1993"},
  {coordinate_system:"EPSG:4678", label: "4678:Lao 1997"},
  {coordinate_system:"EPSG:4679", label: "4679:Jouik 1961"},
  {coordinate_system:"EPSG:4680", label: "4680:Nouakchott 1965"},
  {coordinate_system:"EPSG:4682", label: "4682:Gulshan 303"},
  {coordinate_system:"EPSG:4683", label: "4683:PRS92"},
  {coordinate_system:"EPSG:4684", label: "4684:Gan 1970"},
  {coordinate_system:"EPSG:4686", label: "4686:MAGNA-SIRGAS"},
  {coordinate_system:"EPSG:4687", label: "4687:RGPF"},
  {coordinate_system:"EPSG:4688", label: "4688:Fatu Iva 72"},
  {coordinate_system:"EPSG:4689", label: "4689:IGN63 Hiva Oa"},
  {coordinate_system:"EPSG:4690", label: "4690:Tahiti 79"},
  {coordinate_system:"EPSG:4691", label: "4691:Moorea 87"},
  {coordinate_system:"EPSG:4692", label: "4692:Maupiti 83"},
  {coordinate_system:"EPSG:4693", label: "4693:Nakhl-e Ghanem"},
  {coordinate_system:"EPSG:4694", label: "4694:POSGAR 94"},
  {coordinate_system:"EPSG:4695", label: "4695:Katanga 1955"},
  {coordinate_system:"EPSG:4696", label: "4696:Kasai 1953"},
  {coordinate_system:"EPSG:4697", label: "4697:IGC 1962 6th Parallel South"},
  {coordinate_system:"EPSG:4698", label: "4698:IGN 1962 Kerguelen"},
  {coordinate_system:"EPSG:4699", label: "4699:Le Pouce 1934"},
  {coordinate_system:"EPSG:4700", label: "4700:IGN Astro 1960"},
  {coordinate_system:"EPSG:4701", label: "4701:IGCB 1955"},
  {coordinate_system:"EPSG:4702", label: "4702:Mauritania 1999"},
  {coordinate_system:"EPSG:4703", label: "4703:Mhast 1951"},
  {coordinate_system:"EPSG:4704", label: "4704:Mhast (onshore)"},
  {coordinate_system:"EPSG:4705", label: "4705:Mhast (offshore)"},
  {coordinate_system:"EPSG:4706", label: "4706:Egypt Gulf of Suez S-650 TL"},
  {coordinate_system:"EPSG:4707", label: "4707:Tern Island 1961"},
  {coordinate_system:"EPSG:4708", label: "4708:Cocos Islands 1965"},
  {coordinate_system:"EPSG:4709", label: "4709:Iwo Jima 1945"},
  {coordinate_system:"EPSG:4710", label: "4710:Astro DOS 71"},
  {coordinate_system:"EPSG:4711", label: "4711:Marcus Island 1952"},
  {coordinate_system:"EPSG:4712", label: "4712:Ascension Island 1958"},
  {coordinate_system:"EPSG:4713", label: "4713:Ayabelle Lighthouse"},
  {coordinate_system:"EPSG:4714", label: "4714:Bellevue"},
  {coordinate_system:"EPSG:4715", label: "4715:Camp Area Astro"},
  {coordinate_system:"EPSG:4716", label: "4716:Phoenix Islands 1966"},
  {coordinate_system:"EPSG:4717", label: "4717:Cape Canaveral"},
  {coordinate_system:"EPSG:4718", label: "4718:Solomon 1968"},
  {coordinate_system:"EPSG:4719", label: "4719:Easter Island 1967"},
  {coordinate_system:"EPSG:4720", label: "4720:Fiji 1986"},
  {coordinate_system:"EPSG:4721", label: "4721:Fiji 1956"},
  {coordinate_system:"EPSG:4722", label: "4722:South Georgia 1968"},
  {coordinate_system:"EPSG:4723", label: "4723:GCGD59"},
  {coordinate_system:"EPSG:4724", label: "4724:Diego Garcia 1969"},
  {coordinate_system:"EPSG:4725", label: "4725:Johnston Island 1961"},
  {coordinate_system:"EPSG:4726", label: "4726:SIGD61"},
  {coordinate_system:"EPSG:4727", label: "4727:Midway 1961"},
  {coordinate_system:"EPSG:4728", label: "4728:PN84"},
  {coordinate_system:"EPSG:4729", label: "4729:Pitcairn 1967"},
  {coordinate_system:"EPSG:4730", label: "4730:Santo 1965"},
  {coordinate_system:"EPSG:4732", label: "4732:Marshall Islands 1960"},
  {coordinate_system:"EPSG:4733", label: "4733:Wake Island 1952"},
  {coordinate_system:"EPSG:4734", label: "4734:Tristan 1968"},
  {coordinate_system:"EPSG:4735", label: "4735:Kusaie 1951"},
  {coordinate_system:"EPSG:4736", label: "4736:Deception Island"},
  {coordinate_system:"EPSG:4737", label: "4737:Korea 2000"},
  {coordinate_system:"EPSG:4738", label: "4738:Hong Kong 1963"},
  {coordinate_system:"EPSG:4739", label: "4739:Hong Kong 1963(67)"},
  {coordinate_system:"EPSG:4740", label: "4740:PZ-90"},
  {coordinate_system:"EPSG:4741", label: "4741:FD54"},
  {coordinate_system:"EPSG:4742", label: "4742:GDM2000"},
  {coordinate_system:"EPSG:4743", label: "4743:Karbala 1979"},
  {coordinate_system:"EPSG:4744", label: "4744:Nahrwan 1934"},
  {coordinate_system:"EPSG:4745", label: "4745:RD/83"},
  {coordinate_system:"EPSG:4746", label: "4746:PD/83"},
  {coordinate_system:"EPSG:4747", label: "4747:GR96"},
  {coordinate_system:"EPSG:4748", label: "4748:Vanua Levu 1915"},
  {coordinate_system:"EPSG:4749", label: "4749:RGNC91-93"},
  {coordinate_system:"EPSG:4750", label: "4750:ST87 Ouvea"},
  {coordinate_system:"EPSG:4751", label: "4751:Kertau (RSO)"},
  {coordinate_system:"EPSG:4752", label: "4752:Viti Levu 1912"},
  {coordinate_system:"EPSG:4753", label: "4753:fk89"},
  {coordinate_system:"EPSG:4754", label: "4754:LGD2006"},
  {coordinate_system:"EPSG:4755", label: "4755:DGN95"},
  {coordinate_system:"EPSG:4756", label: "4756:VN-2000"},
  {coordinate_system:"EPSG:4757", label: "4757:SVY21"},
  {coordinate_system:"EPSG:4758", label: "4758:JAD2001"},
  {coordinate_system:"EPSG:4759", label: "4759:NAD83(NSRS2007)"},
  {coordinate_system:"EPSG:4760", label: "4760:WGS 66"},
  {coordinate_system:"EPSG:4761", label: "4761:HTRS96"},
  {coordinate_system:"EPSG:4762", label: "4762:BDA2000"},
  {coordinate_system:"EPSG:4763", label: "4763:Pitcairn 2006"},
  {coordinate_system:"EPSG:4764", label: "4764:RSRGD2000"},
  {coordinate_system:"EPSG:4765", label: "4765:Slovenia 1996"},
  {coordinate_system:"EPSG:4801", label: "4801:Bern 1898 (Bern)"},
  {coordinate_system:"EPSG:4802", label: "4802:Bogota 1975 (Bogota)"},
  {coordinate_system:"EPSG:4803", label: "4803:Lisbon (Lisbon)"},
  {coordinate_system:"EPSG:4804", label: "4804:Makassar (Jakarta)"},
  {coordinate_system:"EPSG:4805", label: "4805:MGI (Ferro)"},
  {coordinate_system:"EPSG:4806", label: "4806:Monte Mario (Rome)"},
  {coordinate_system:"EPSG:4807", label: "4807:NTF (Paris)"},
  {coordinate_system:"EPSG:4809", label: "4809:BD50 (Brussels)"},
  {coordinate_system:"EPSG:4810", label: "4810:Tananarive (Paris)"},
  {coordinate_system:"EPSG:4811", label: "4811:Voirol 1875 (Paris)"},
  {coordinate_system:"EPSG:4813", label: "4813:Batavia (Jakarta)"},
  {coordinate_system:"EPSG:4814", label: "4814:RT38 (Stockholm)"},
  {coordinate_system:"EPSG:4815", label: "4815:Greek (Athens)"},
  {coordinate_system:"EPSG:4816", label: "4816:Carthage (Paris)"},
  {coordinate_system:"EPSG:4817", label: "4817:NGO 1948 (Oslo)"},
  {coordinate_system:"EPSG:4818", label: "4818:S-JTSK (Ferro)"},
  {coordinate_system:"EPSG:4820", label: "4820:Segara (Jakarta)"},
  {coordinate_system:"EPSG:4821", label: "4821:Voirol 1879 (Paris)"},
  {coordinate_system:"EPSG:4823", label: "4823:Sao Tome"},
  {coordinate_system:"EPSG:4824", label: "4824:Principe"},
  {coordinate_system:"EPSG:4883", label: "4883:Slovenia 1996"},
  {coordinate_system:"EPSG:4885", label: "4885:RSRGD2000"},
  {coordinate_system:"EPSG:4887", label: "4887:BDA2000"},
  {coordinate_system:"EPSG:4889", label: "4889:HTRS96"},
  {coordinate_system:"EPSG:4891", label: "4891:WGS 66"},
  {coordinate_system:"EPSG:4893", label: "4893:NAD83(NSRS2007)"},
  {coordinate_system:"EPSG:4895", label: "4895:JAD2001"},
  {coordinate_system:"EPSG:4898", label: "4898:DGN95"},
  {coordinate_system:"EPSG:4900", label: "4900:LGD2006"},
  {coordinate_system:"EPSG:4901", label: "4901:ATF (Paris)"},
  {coordinate_system:"EPSG:4903", label: "4903:Madrid 1870 (Madrid)"},
  {coordinate_system:"EPSG:4904", label: "4904:Lisbon 1890 (Lisbon)"},
  {coordinate_system:"EPSG:4907", label: "4907:RGNC91-93"},
  {coordinate_system:"EPSG:4909", label: "4909:GR96"},
  {coordinate_system:"EPSG:4921", label: "4921:GDM2000"},
  {coordinate_system:"EPSG:4923", label: "4923:PZ-90"},
  {coordinate_system:"EPSG:4925", label: "4925:Mauritania 1999"},
  {coordinate_system:"EPSG:4927", label: "4927:Korea 2000"},
  {coordinate_system:"EPSG:4929", label: "4929:POSGAR 94"},
  {coordinate_system:"EPSG:4931", label: "4931:Australian Antarctic"},
  {coordinate_system:"EPSG:4933", label: "4933:CHTRS95"},
  {coordinate_system:"EPSG:4935", label: "4935:EST97"},
  {coordinate_system:"EPSG:4937", label: "4937:ETRS89"},
  {coordinate_system:"EPSG:4939", label: "4939:GDA94"},
  {coordinate_system:"EPSG:4941", label: "4941:Hartebeesthoek94"},
  {coordinate_system:"EPSG:4943", label: "4943:IRENET95"},
  {coordinate_system:"EPSG:4945", label: "4945:ISN93"},
  {coordinate_system:"EPSG:4947", label: "4947:JGD2000"},
  {coordinate_system:"EPSG:4949", label: "4949:LKS92"},
  {coordinate_system:"EPSG:4951", label: "4951:LKS94"},
  {coordinate_system:"EPSG:4953", label: "4953:Moznet"},
  {coordinate_system:"EPSG:4955", label: "4955:NAD83(CSRS)"},
  {coordinate_system:"EPSG:4957", label: "4957:NAD83(HARN)"},
  {coordinate_system:"EPSG:4959", label: "4959:NZGD2000"},
  {coordinate_system:"EPSG:4961", label: "4961:POSGAR 98"},
  {coordinate_system:"EPSG:4963", label: "4963:REGVEN"},
  {coordinate_system:"EPSG:4965", label: "4965:RGF93 v1"},
  {coordinate_system:"EPSG:4967", label: "4967:RGFG95"},
  {coordinate_system:"EPSG:4971", label: "4971:RGR92"},
  {coordinate_system:"EPSG:4975", label: "4975:SIRGAS 1995"},
  {coordinate_system:"EPSG:4977", label: "4977:SWEREF99"},
  {coordinate_system:"EPSG:4979", label: "4979:WGS 84"},
  {coordinate_system:"EPSG:4981", label: "4981:Yemen NGN96"},
  {coordinate_system:"EPSG:4983", label: "4983:IGM95"},
  {coordinate_system:"EPSG:4985", label: "4985:WGS 72"},
  {coordinate_system:"EPSG:4987", label: "4987:WGS 72BE"},
  {coordinate_system:"EPSG:4989", label: "4989:SIRGAS 2000"},
  {coordinate_system:"EPSG:4991", label: "4991:Lao 1993"},
  {coordinate_system:"EPSG:4993", label: "4993:Lao 1997"},
  {coordinate_system:"EPSG:4995", label: "4995:PRS92"},
  {coordinate_system:"EPSG:4997", label: "4997:MAGNA-SIRGAS"},
  {coordinate_system:"EPSG:4999", label: "4999:RGPF"},
  {coordinate_system:"EPSG:5012", label: "5012:PTRA08"},
  {coordinate_system:"EPSG:5013", label: "5013:PTRA08"},
  {coordinate_system:"EPSG:5132", label: "5132:Tokyo 1892"},
  {coordinate_system:"EPSG:5228", label: "5228:S-JTSK/05"},
  {coordinate_system:"EPSG:5229", label: "5229:S-JTSK/05 (Ferro)"},
  {coordinate_system:"EPSG:5233", label: "5233:SLD99"},
  {coordinate_system:"EPSG:5245", label: "5245:GDBD2009"},
  {coordinate_system:"EPSG:5246", label: "5246:GDBD2009"},
  {coordinate_system:"EPSG:5251", label: "5251:TUREF"},
  {coordinate_system:"EPSG:5252", label: "5252:TUREF"},
  {coordinate_system:"EPSG:5263", label: "5263:DRUKREF 03"},
  {coordinate_system:"EPSG:5264", label: "5264:DRUKREF 03"},
  {coordinate_system:"EPSG:5323", label: "5323:ISN2004"},
  {coordinate_system:"EPSG:5324", label: "5324:ISN2004"},
  {coordinate_system:"EPSG:5340", label: "5340:POSGAR 2007"},
  {coordinate_system:"EPSG:5342", label: "5342:POSGAR 2007"},
  {coordinate_system:"EPSG:5353", label: "5353:MARGEN"},
  {coordinate_system:"EPSG:5354", label: "5354:MARGEN"},
  {coordinate_system:"EPSG:5359", label: "5359:SIRGAS-Chile 2002"},
  {coordinate_system:"EPSG:5360", label: "5360:SIRGAS-Chile 2002"},
  {coordinate_system:"EPSG:5364", label: "5364:CR05"},
  {coordinate_system:"EPSG:5365", label: "5365:CR05"},
  {coordinate_system:"EPSG:5370", label: "5370:MACARIO SOLIS"},
  {coordinate_system:"EPSG:5371", label: "5371:MACARIO SOLIS"},
  {coordinate_system:"EPSG:5372", label: "5372:Peru96"},
  {coordinate_system:"EPSG:5373", label: "5373:Peru96"},
  {coordinate_system:"EPSG:5380", label: "5380:SIRGAS-ROU98"},
  {coordinate_system:"EPSG:5381", label: "5381:SIRGAS-ROU98"},
  {coordinate_system:"EPSG:5392", label: "5392:SIRGAS_ES2007.8"},
  {coordinate_system:"EPSG:5393", label: "5393:SIRGAS_ES2007.8"},
  {coordinate_system:"EPSG:5451", label: "5451:Ocotepeque 1935"},
  {coordinate_system:"EPSG:5464", label: "5464:Sibun Gorge 1922"},
  {coordinate_system:"EPSG:5467", label: "5467:Panama-Colon 1911"},
  {coordinate_system:"EPSG:5488", label: "5488:RGAF09"},
  {coordinate_system:"EPSG:5489", label: "5489:RGAF09"},
  {coordinate_system:"EPSG:5524", label: "5524:Corrego Alegre 1961"},
  {coordinate_system:"EPSG:5527", label: "5527:SAD69(96)"},
  {coordinate_system:"EPSG:5545", label: "5545:PNG94"},
  {coordinate_system:"EPSG:5546", label: "5546:PNG94"},
  {coordinate_system:"EPSG:5560", label: "5560:UCS-2000"},
  {coordinate_system:"EPSG:5561", label: "5561:UCS-2000"},
  {coordinate_system:"EPSG:5592", label: "5592:FEH2010"},
  {coordinate_system:"EPSG:5593", label: "5593:FEH2010"},
  {coordinate_system:"EPSG:5681", label: "5681:DB_REF"},
  {coordinate_system:"EPSG:5830", label: "5830:DB_REF"},
  {coordinate_system:"EPSG:5885", label: "5885:TGD2005"},
  {coordinate_system:"EPSG:5886", label: "5886:TGD2005"},
  {coordinate_system:"EPSG:6134", label: "6134:CIGD11"},
  {coordinate_system:"EPSG:6135", label: "6135:CIGD11"},
  {coordinate_system:"EPSG:6207", label: "6207:Nepal 1981"},
  {coordinate_system:"EPSG:6310", label: "6310:CGRS93"},
  {coordinate_system:"EPSG:6311", label: "6311:CGRS93"},
  {coordinate_system:"EPSG:6318", label: "6318:NAD83(2011)"},
  {coordinate_system:"EPSG:6319", label: "6319:NAD83(2011)"},
  {coordinate_system:"EPSG:6321", label: "6321:NAD83(PA11)"},
  {coordinate_system:"EPSG:6322", label: "6322:NAD83(PA11)"},
  {coordinate_system:"EPSG:6324", label: "6324:NAD83(MA11)"},
  {coordinate_system:"EPSG:6325", label: "6325:NAD83(MA11)"},
  {coordinate_system:"EPSG:6364", label: "6364:Mexico ITRF2008"},
  {coordinate_system:"EPSG:6365", label: "6365:Mexico ITRF2008"},
  {coordinate_system:"EPSG:6667", label: "6667:JGD2011"},
  {coordinate_system:"EPSG:6668", label: "6668:JGD2011"},
  {coordinate_system:"EPSG:6705", label: "6705:RDN2008"},
  {coordinate_system:"EPSG:6706", label: "6706:RDN2008"},
  {coordinate_system:"EPSG:6782", label: "6782:NAD83(CORS96)"},
  {coordinate_system:"EPSG:6783", label: "6783:NAD83(CORS96)"},
  {coordinate_system:"EPSG:6881", label: "6881:Aden 1925"},
  {coordinate_system:"EPSG:6882", label: "6882:Bekaa Valley 1920"},
  {coordinate_system:"EPSG:6883", label: "6883:Bioko"},
  {coordinate_system:"EPSG:6892", label: "6892:South East Island 1943"},
  {coordinate_system:"EPSG:6894", label: "6894:Gambia"},
  {coordinate_system:"EPSG:6982", label: "6982:IG05 Intermediate CRS"},
  {coordinate_system:"EPSG:6983", label: "6983:IG05 Intermediate CRS"},
  {coordinate_system:"EPSG:6989", label: "6989:IG05/12 Intermediate CRS"},
  {coordinate_system:"EPSG:6990", label: "6990:IG05/12 Intermediate CRS"},
  {coordinate_system:"EPSG:7034", label: "7034:RGSPM06 (lon-lat)"},
  {coordinate_system:"EPSG:7035", label: "7035:RGSPM06 (lon-lat)"},
  {coordinate_system:"EPSG:7036", label: "7036:RGR92 (lon-lat)"},
  {coordinate_system:"EPSG:7037", label: "7037:RGR92 (lon-lat)"},
  {coordinate_system:"EPSG:7038", label: "7038:RGM04 (lon-lat)"},
  {coordinate_system:"EPSG:7039", label: "7039:RGM04 (lon-lat)"},
  {coordinate_system:"EPSG:7040", label: "7040:RGFG95 (lon-lat)"},
  {coordinate_system:"EPSG:7041", label: "7041:RGFG95 (lon-lat)"},
  {coordinate_system:"EPSG:7042", label: "7042:RGF93 v1 (lon-lat)"},
  {coordinate_system:"EPSG:7072", label: "7072:RGTAAF07"},
  {coordinate_system:"EPSG:7073", label: "7073:RGTAAF07"},
  {coordinate_system:"EPSG:7084", label: "7084:RGF93 v1 (lon-lat)"},
  {coordinate_system:"EPSG:7085", label: "7085:RGAF09 (lon-lat)"},
  {coordinate_system:"EPSG:7086", label: "7086:RGAF09 (lon-lat)"},
  {coordinate_system:"EPSG:7087", label: "7087:RGTAAF07 (lon-lat)"},
  {coordinate_system:"EPSG:7133", label: "7133:RGTAAF07 (lon-lat)"},
  {coordinate_system:"EPSG:7135", label: "7135:IGD05"},
  {coordinate_system:"EPSG:7136", label: "7136:IGD05"},
  {coordinate_system:"EPSG:7138", label: "7138:IGD05/12"},
  {coordinate_system:"EPSG:7139", label: "7139:IGD05/12"},
  {coordinate_system:"EPSG:7372", label: "7372:ONGD14"},
  {coordinate_system:"EPSG:7373", label: "7373:ONGD14"},
  {coordinate_system:"EPSG:7657", label: "7657:WGS 84 (G730)"},
  {coordinate_system:"EPSG:7659", label: "7659:WGS 84 (G873)"},
  {coordinate_system:"EPSG:7661", label: "7661:WGS 84 (G1150)"},
  {coordinate_system:"EPSG:7663", label: "7663:WGS 84 (G1674)"},
  {coordinate_system:"EPSG:7665", label: "7665:WGS 84 (G1762)"},
  {coordinate_system:"EPSG:7678", label: "7678:PZ-90.02"},
  {coordinate_system:"EPSG:7680", label: "7680:PZ-90.11"},
  {coordinate_system:"EPSG:7682", label: "7682:GSK-2011"},
  {coordinate_system:"EPSG:7683", label: "7683:GSK-2011"},
  {coordinate_system:"EPSG:7685", label: "7685:Kyrg-06"},
  {coordinate_system:"EPSG:7686", label: "7686:Kyrg-06"},
  {coordinate_system:"EPSG:7797", label: "7797:BGS2005"},
  {coordinate_system:"EPSG:7798", label: "7798:BGS2005"},
  {coordinate_system:"EPSG:7816", label: "7816:WGS 84 (Transit)"},
  {coordinate_system:"EPSG:7843", label: "7843:GDA2020"},
  {coordinate_system:"EPSG:7844", label: "7844:GDA2020"},
  {coordinate_system:"EPSG:7880", label: "7880:St. Helena Tritan"},
  {coordinate_system:"EPSG:7881", label: "7881:St. Helena Tritan"},
  {coordinate_system:"EPSG:7885", label: "7885:SHGD2015"},
  {coordinate_system:"EPSG:7886", label: "7886:SHGD2015"},
  {coordinate_system:"EPSG:7900", label: "7900:ITRF88"},
  {coordinate_system:"EPSG:7901", label: "7901:ITRF89"},
  {coordinate_system:"EPSG:7902", label: "7902:ITRF90"},
  {coordinate_system:"EPSG:7903", label: "7903:ITRF91"},
  {coordinate_system:"EPSG:7904", label: "7904:ITRF92"},
  {coordinate_system:"EPSG:7905", label: "7905:ITRF93"},
  {coordinate_system:"EPSG:7906", label: "7906:ITRF94"},
  {coordinate_system:"EPSG:7907", label: "7907:ITRF96"},
  {coordinate_system:"EPSG:7908", label: "7908:ITRF97"},
  {coordinate_system:"EPSG:7909", label: "7909:ITRF2000"},
  {coordinate_system:"EPSG:7910", label: "7910:ITRF2005"},
  {coordinate_system:"EPSG:7911", label: "7911:ITRF2008"},
  {coordinate_system:"EPSG:7912", label: "7912:ITRF2014"},
  {coordinate_system:"EPSG:7915", label: "7915:ETRF89"},
  {coordinate_system:"EPSG:7917", label: "7917:ETRF90"},
  {coordinate_system:"EPSG:7919", label: "7919:ETRF91"},
  {coordinate_system:"EPSG:7921", label: "7921:ETRF92"},
  {coordinate_system:"EPSG:7923", label: "7923:ETRF93"},
  {coordinate_system:"EPSG:7925", label: "7925:ETRF94"},
  {coordinate_system:"EPSG:7927", label: "7927:ETRF96"},
  {coordinate_system:"EPSG:7929", label: "7929:ETRF97"},
  {coordinate_system:"EPSG:7931", label: "7931:ETRF2000"},
  {coordinate_system:"EPSG:8042", label: "8042:Gusterberg (Ferro)"},
  {coordinate_system:"EPSG:8043", label: "8043:St. Stephen (Ferro)"},
  {coordinate_system:"EPSG:8085", label: "8085:ISN2016"},
  {coordinate_system:"EPSG:8086", label: "8086:ISN2016"},
  {coordinate_system:"EPSG:8231", label: "8231:NAD83(CSRS96)"},
  {coordinate_system:"EPSG:8232", label: "8232:NAD83(CSRS96)"},
  {coordinate_system:"EPSG:8235", label: "8235:NAD83(CSRS)v2"},
  {coordinate_system:"EPSG:8237", label: "8237:NAD83(CSRS)v2"},
  {coordinate_system:"EPSG:8239", label: "8239:NAD83(CSRS)v3"},
  {coordinate_system:"EPSG:8240", label: "8240:NAD83(CSRS)v3"},
  {coordinate_system:"EPSG:8244", label: "8244:NAD83(CSRS)v4"},
  {coordinate_system:"EPSG:8246", label: "8246:NAD83(CSRS)v4"},
  {coordinate_system:"EPSG:8248", label: "8248:NAD83(CSRS)v5"},
  {coordinate_system:"EPSG:8249", label: "8249:NAD83(CSRS)v5"},
  {coordinate_system:"EPSG:8251", label: "8251:NAD83(CSRS)v6"},
  {coordinate_system:"EPSG:8252", label: "8252:NAD83(CSRS)v6"},
  {coordinate_system:"EPSG:8254", label: "8254:NAD83(CSRS)v7"},
  {coordinate_system:"EPSG:8255", label: "8255:NAD83(CSRS)v7"},
  {coordinate_system:"EPSG:8351", label: "8351:S-JTSK [JTSK03]"},
  {coordinate_system:"EPSG:8399", label: "8399:ETRF2005"},
  {coordinate_system:"EPSG:8403", label: "8403:ETRF2014"},
  {coordinate_system:"EPSG:8426", label: "8426:Hong Kong Geodetic CS"},
  {coordinate_system:"EPSG:8427", label: "8427:Hong Kong Geodetic CS"},
  {coordinate_system:"EPSG:8428", label: "8428:Macao 1920"},
  {coordinate_system:"EPSG:8430", label: "8430:Macao 2008"},
  {coordinate_system:"EPSG:8431", label: "8431:Macao 2008"},
  {coordinate_system:"EPSG:8542", label: "8542:NAD83(FBN)"},
  {coordinate_system:"EPSG:8544", label: "8544:NAD83(HARN Corrected)"},
  {coordinate_system:"EPSG:8545", label: "8545:NAD83(HARN Corrected)"},
  {coordinate_system:"EPSG:8684", label: "8684:SRB_ETRS89"},
  {coordinate_system:"EPSG:8685", label: "8685:SRB_ETRS89"},
  {coordinate_system:"EPSG:8694", label: "8694:Camacupa 2015"},
  {coordinate_system:"EPSG:8698", label: "8698:RSAO13"},
  {coordinate_system:"EPSG:8699", label: "8699:RSAO13"},
  {coordinate_system:"EPSG:8817", label: "8817:MTRF-2000"},
  {coordinate_system:"EPSG:8818", label: "8818:MTRF-2000"},
  {coordinate_system:"EPSG:8860", label: "8860:NAD83(FBN)"},
  {coordinate_system:"EPSG:8888", label: "8888:WGS 84 (Transit)"},
  {coordinate_system:"EPSG:8899", label: "8899:RGWF96"},
  {coordinate_system:"EPSG:8900", label: "8900:RGWF96"},
  {coordinate_system:"EPSG:8901", label: "8901:RGWF96 (lon-lat)"},
  {coordinate_system:"EPSG:8902", label: "8902:RGWF96 (lon-lat)"},
  {coordinate_system:"EPSG:8906", label: "8906:CR-SIRGAS"},
  {coordinate_system:"EPSG:8907", label: "8907:CR-SIRGAS"},
  {coordinate_system:"EPSG:8916", label: "8916:SIRGAS-CON DGF00P01"},
  {coordinate_system:"EPSG:8918", label: "8918:SIRGAS-CON DGF01P01"},
  {coordinate_system:"EPSG:8920", label: "8920:SIRGAS-CON DGF01P02"},
  {coordinate_system:"EPSG:8922", label: "8922:SIRGAS-CON DGF02P01"},
  {coordinate_system:"EPSG:8924", label: "8924:SIRGAS-CON DGF04P01"},
  {coordinate_system:"EPSG:8926", label: "8926:SIRGAS-CON DGF05P01"},
  {coordinate_system:"EPSG:8928", label: "8928:SIRGAS-CON DGF06P01"},
  {coordinate_system:"EPSG:8930", label: "8930:SIRGAS-CON DGF07P01"},
  {coordinate_system:"EPSG:8932", label: "8932:SIRGAS-CON DGF08P01"},
  {coordinate_system:"EPSG:8934", label: "8934:SIRGAS-CON SIR09P01"},
  {coordinate_system:"EPSG:8936", label: "8936:SIRGAS-CON SIR10P01"},
  {coordinate_system:"EPSG:8938", label: "8938:SIRGAS-CON SIR11P01"},
  {coordinate_system:"EPSG:8940", label: "8940:SIRGAS-CON SIR13P01"},
  {coordinate_system:"EPSG:8942", label: "8942:SIRGAS-CON SIR14P01"},
  {coordinate_system:"EPSG:8944", label: "8944:SIRGAS-CON SIR15P01"},
  {coordinate_system:"EPSG:8946", label: "8946:SIRGAS-CON SIR17P01"},
  {coordinate_system:"EPSG:8948", label: "8948:SIRGAS-Chile 2010"},
  {coordinate_system:"EPSG:8949", label: "8949:SIRGAS-Chile 2010"},
  {coordinate_system:"EPSG:8972", label: "8972:SIRGAS-CON DGF00P01"},
  {coordinate_system:"EPSG:8973", label: "8973:SIRGAS-CON DGF01P01"},
  {coordinate_system:"EPSG:8974", label: "8974:SIRGAS-CON DGF01P02"},
  {coordinate_system:"EPSG:8975", label: "8975:SIRGAS-CON DGF02P01"},
  {coordinate_system:"EPSG:8976", label: "8976:SIRGAS-CON DGF04P01"},
  {coordinate_system:"EPSG:8977", label: "8977:SIRGAS-CON DGF05P01"},
  {coordinate_system:"EPSG:8978", label: "8978:SIRGAS-CON DGF06P01"},
  {coordinate_system:"EPSG:8979", label: "8979:SIRGAS-CON DGF07P01"},
  {coordinate_system:"EPSG:8980", label: "8980:SIRGAS-CON DGF08P01"},
  {coordinate_system:"EPSG:8981", label: "8981:SIRGAS-CON SIR09P01"},
  {coordinate_system:"EPSG:8982", label: "8982:SIRGAS-CON SIR10P01"},
  {coordinate_system:"EPSG:8983", label: "8983:SIRGAS-CON SIR11P01"},
  {coordinate_system:"EPSG:8984", label: "8984:SIRGAS-CON SIR13P01"},
  {coordinate_system:"EPSG:8985", label: "8985:SIRGAS-CON SIR14P01"},
  {coordinate_system:"EPSG:8986", label: "8986:SIRGAS-CON SIR15P01"},
  {coordinate_system:"EPSG:8987", label: "8987:SIRGAS-CON SIR17P01"},
  {coordinate_system:"EPSG:8988", label: "8988:ITRF88"},
  {coordinate_system:"EPSG:8989", label: "8989:ITRF89"},
  {coordinate_system:"EPSG:8990", label: "8990:ITRF90"},
  {coordinate_system:"EPSG:8991", label: "8991:ITRF91"},
  {coordinate_system:"EPSG:8992", label: "8992:ITRF92"},
  {coordinate_system:"EPSG:8993", label: "8993:ITRF93"},
  {coordinate_system:"EPSG:8994", label: "8994:ITRF94"},
  {coordinate_system:"EPSG:8995", label: "8995:ITRF96"},
  {coordinate_system:"EPSG:8996", label: "8996:ITRF97"},
  {coordinate_system:"EPSG:8997", label: "8997:ITRF2000"},
  {coordinate_system:"EPSG:8998", label: "8998:ITRF2005"},
  {coordinate_system:"EPSG:8999", label: "8999:ITRF2008"},
  {coordinate_system:"EPSG:9000", label: "9000:ITRF2014"},
  {coordinate_system:"EPSG:9002", label: "9002:IGS97"},
  {coordinate_system:"EPSG:9003", label: "9003:IGS97"},
  {coordinate_system:"EPSG:9005", label: "9005:IGS00"},
  {coordinate_system:"EPSG:9006", label: "9006:IGS00"},
  {coordinate_system:"EPSG:9008", label: "9008:IGb00"},
  {coordinate_system:"EPSG:9009", label: "9009:IGb00"},
  {coordinate_system:"EPSG:9011", label: "9011:IGS05"},
  {coordinate_system:"EPSG:9012", label: "9012:IGS05"},
  {coordinate_system:"EPSG:9013", label: "9013:IGS08"},
  {coordinate_system:"EPSG:9014", label: "9014:IGS08"},
  {coordinate_system:"EPSG:9016", label: "9016:IGb08"},
  {coordinate_system:"EPSG:9017", label: "9017:IGb08"},
  {coordinate_system:"EPSG:9018", label: "9018:IGS14"},
  {coordinate_system:"EPSG:9019", label: "9019:IGS14"},
  {coordinate_system:"EPSG:9053", label: "9053:WGS 84 (G730)"},
  {coordinate_system:"EPSG:9054", label: "9054:WGS 84 (G873)"},
  {coordinate_system:"EPSG:9055", label: "9055:WGS 84 (G1150)"},
  {coordinate_system:"EPSG:9056", label: "9056:WGS 84 (G1674)"},
  {coordinate_system:"EPSG:9057", label: "9057:WGS 84 (G1762)"},
  {coordinate_system:"EPSG:9059", label: "9059:ETRF89"},
  {coordinate_system:"EPSG:9060", label: "9060:ETRF90"},
  {coordinate_system:"EPSG:9061", label: "9061:ETRF91"},
  {coordinate_system:"EPSG:9062", label: "9062:ETRF92"},
  {coordinate_system:"EPSG:9063", label: "9063:ETRF93"},
  {coordinate_system:"EPSG:9064", label: "9064:ETRF94"},
  {coordinate_system:"EPSG:9065", label: "9065:ETRF96"},
  {coordinate_system:"EPSG:9066", label: "9066:ETRF97"},
  {coordinate_system:"EPSG:9067", label: "9067:ETRF2000"},
  {coordinate_system:"EPSG:9068", label: "9068:ETRF2005"},
  {coordinate_system:"EPSG:9069", label: "9069:ETRF2014"},
  {coordinate_system:"EPSG:9071", label: "9071:NAD83(MARP00)"},
  {coordinate_system:"EPSG:9072", label: "9072:NAD83(MARP00)"},
  {coordinate_system:"EPSG:9074", label: "9074:NAD83(PACP00)"},
  {coordinate_system:"EPSG:9075", label: "9075:NAD83(PACP00)"},
  {coordinate_system:"EPSG:9139", label: "9139:KOSOVAREF01"},
  {coordinate_system:"EPSG:9140", label: "9140:KOSOVAREF01"},
  {coordinate_system:"EPSG:9147", label: "9147:SIRGAS-Chile 2013"},
  {coordinate_system:"EPSG:9148", label: "9148:SIRGAS-Chile 2013"},
  {coordinate_system:"EPSG:9152", label: "9152:SIRGAS-Chile 2016"},
  {coordinate_system:"EPSG:9153", label: "9153:SIRGAS-Chile 2016"},
  {coordinate_system:"EPSG:9248", label: "9248:Tapi Aike"},
  {coordinate_system:"EPSG:9251", label: "9251:MMN"},
  {coordinate_system:"EPSG:9253", label: "9253:MMS"},
  {coordinate_system:"EPSG:9267", label: "9267:MGI"},
  {coordinate_system:"EPSG:9293", label: "9293:ONGD17"},
  {coordinate_system:"EPSG:9294", label: "9294:ONGD17"},
  {coordinate_system:"EPSG:9299", label: "9299:HS2-IRF"},
  {coordinate_system:"EPSG:9308", label: "9308:ATRF2014"},
  {coordinate_system:"EPSG:9309", label: "9309:ATRF2014"},
  {coordinate_system:"EPSG:9332", label: "9332:KSA-GRF17"},
  {coordinate_system:"EPSG:9333", label: "9333:KSA-GRF17"},
  {coordinate_system:"EPSG:9364", label: "9364:TPEN11-IRF"},
  {coordinate_system:"EPSG:9372", label: "9372:MML07-IRF"},
  {coordinate_system:"EPSG:9379", label: "9379:IGb14"},
  {coordinate_system:"EPSG:9380", label: "9380:IGb14"},
  {coordinate_system:"EPSG:9384", label: "9384:AbInvA96_2020-IRF"},
  {coordinate_system:"EPSG:9403", label: "9403:PN68"},
  {coordinate_system:"EPSG:9453", label: "9453:GBK19-IRF"},
  {coordinate_system:"EPSG:9469", label: "9469:SRGI2013"},
  {coordinate_system:"EPSG:9470", label: "9470:SRGI2013"},
  {coordinate_system:"EPSG:9474", label: "9474:PZ-90.02"},
  {coordinate_system:"EPSG:9475", label: "9475:PZ-90.11"},
  {coordinate_system:"EPSG:9546", label: "9546:LTF2004(G)"},
  {coordinate_system:"EPSG:9547", label: "9547:LTF2004(G)"},
  {coordinate_system:"EPSG:9695", label: "9695:REDGEOMIN"},
  {coordinate_system:"EPSG:9696", label: "9696:REDGEOMIN"},
  {coordinate_system:"EPSG:9701", label: "9701:ETRF2000-PL"},
  {coordinate_system:"EPSG:9702", label: "9702:ETRF2000-PL"},
  {coordinate_system:"EPSG:9739", label: "9739:EOS21-IRF"},
  {coordinate_system:"EPSG:9754", label: "9754:WGS 84 (G2139)"},
  {coordinate_system:"EPSG:9755", label: "9755:WGS 84 (G2139)"},
  {coordinate_system:"EPSG:9758", label: "9758:ECML14_NB-IRF"},
  {coordinate_system:"EPSG:9763", label: "9763:EWR2-IRF"},
  {coordinate_system:"EPSG:9776", label: "9776:RGF93 v2"},
  {coordinate_system:"EPSG:9777", label: "9777:RGF93 v2"},
  {coordinate_system:"EPSG:9778", label: "9778:RGF93 v2 (lon-lat)"},
  {coordinate_system:"EPSG:9779", label: "9779:RGF93 v2 (lon-lat)"},
  {coordinate_system:"EPSG:9781", label: "9781:RGF93 v2b"},
  {coordinate_system:"EPSG:9782", label: "9782:RGF93 v2b"},
  {coordinate_system:"EPSG:9783", label: "9783:RGF93 v2b (lon-lat)"},
  {coordinate_system:"EPSG:9784", label: "9784:RGF93 v2b (lon-lat)"},
  {coordinate_system:"EPSG:9866", label: "9866:MRH21-IRF"},
  {coordinate_system:"EPSG:9871", label: "9871:MOLDOR11-IRF"},
  {coordinate_system:"EPSG:9893", label: "9893:LUREF"},
  {coordinate_system:"EPSG:9939", label: "9939:EBBWV14-IRF"},
  {coordinate_system:"EPSG:9964", label: "9964:HULLEE13-IRF"},
  {coordinate_system:"EPSG:9969", label: "9969:SCM22-IRF"},
  {coordinate_system:"EPSG:9974", label: "9974:FNL22-IRF"},
  {coordinate_system:"EPSG:9989", label: "9989:ITRF2020"},
  {coordinate_system:"EPSG:9990", label: "9990:ITRF2020"},
  {coordinate_system:"EPSG:20033", label: "20033:MWC18-IRF"},
  {coordinate_system:"EPSG:20040", label: "20040:SIRGAS-Chile 2021"},
  {coordinate_system:"EPSG:20041", label: "20041:SIRGAS-Chile 2021"},
  {coordinate_system:"ESRI:9701", label: "9701:ETRF2000-PL_(3D)"},
  {coordinate_system:"ESRI:9702", label: "9702:ETRF2000-PL"},
  {coordinate_system:"ESRI:9739", label: "9739:EOS21-IRF"},
  {coordinate_system:"ESRI:9758", label: "9758:ECML14_NB-IRF"},
  {coordinate_system:"ESRI:9763", label: "9763:EWR2-IRF"},
  {coordinate_system:"ESRI:37002", label: "37002:GCS_Fischer_1960"},
  {coordinate_system:"ESRI:37003", label: "37003:GCS_Fischer_1968"},
  {coordinate_system:"ESRI:37004", label: "37004:GCS_Fischer_Modified"},
  {coordinate_system:"ESRI:37005", label: "37005:GCS_Hough_1960"},
  {coordinate_system:"ESRI:37006", label: "37006:GCS_Everest_Modified_1969"},
  {coordinate_system:"ESRI:37007", label: "37007:GCS_Walbeck"},
  {coordinate_system:"ESRI:37008", label: "37008:GCS_Sphere_ARC_INFO"},
  {coordinate_system:"ESRI:37202", label: "37202:GCS_Everest_Bangladesh"},
  {coordinate_system:"ESRI:37203", label: "37203:GCS_Everest_India_Nepal"},
  {coordinate_system:"ESRI:37206", label: "37206:GCS_Oman"},
  {coordinate_system:"ESRI:37207", label: "37207:GCS_South_Asia_Singapore"},
  {coordinate_system:"ESRI:37218", label: "37218:GCS_DOS_1968"},
  {coordinate_system:"ESRI:37221", label: "37221:GCS_GUX_1"},
  {coordinate_system:"ESRI:37225", label: "37225:GCS_Carthage_Grad"},
  {coordinate_system:"ESRI:37240", label: "37240:GCS_Fort_Thomas_1955"},
  {coordinate_system:"ESRI:37241", label: "37241:GCS_Graciosa_Base_SW_1948"},
  {coordinate_system:"ESRI:37243", label: "37243:GCS_LC5_1961"},
  {coordinate_system:"ESRI:37245", label: "37245:GCS_Observatorio_Meteorologico_1939"},
  {coordinate_system:"ESRI:37249", label: "37249:GCS_Sao_Braz"},
  {coordinate_system:"ESRI:37257", label: "37257:GCS_S42_Hungary"},
  {coordinate_system:"ESRI:37260", label: "37260:GCS_Alaskan_Islands"},
  {coordinate_system:"ESRI:104009", label: "104009:GCS_Kyrg-06"},
  {coordinate_system:"ESRI:104017", label: "104017:PZ-90.02"},
  {coordinate_system:"ESRI:104018", label: "104018:PZ-90.11"},
  {coordinate_system:"ESRI:104022", label: "104022:GGD"},
  {coordinate_system:"ESRI:104024", label: "104024:California_SRS_Epoch_2017.50_(NAD83)"},
  {coordinate_system:"ESRI:104028", label: "104028:GDM2008"},
  {coordinate_system:"ESRI:104047", label: "104047:GCS_Sphere_GRS_1980_Mean_Radius"},
  {coordinate_system:"ESRI:104050", label: "104050:GCS_Xrail84"},
  {coordinate_system:"ESRI:104101", label: "104101:GCS_Estonia_1937"},
  {coordinate_system:"ESRI:104102", label: "104102:GCS_Hermannskogel"},
  {coordinate_system:"ESRI:104103", label: "104103:GCS_Sierra_Leone_1960"},
  {coordinate_system:"ESRI:104105", label: "104105:GCS_Datum_Lisboa_Bessel"},
  {coordinate_system:"ESRI:104106", label: "104106:GCS_Datum_Lisboa_Hayford"},
  {coordinate_system:"ESRI:104109", label: "104109:GCS_Pohnpei"},
  {coordinate_system:"ESRI:104112", label: "104112:GCS_Bab_South"},
  {coordinate_system:"ESRI:104113", label: "104113:GCS_Majuro"},
  {coordinate_system:"ESRI:104126", label: "104126:GCS_Observatorio_Meteorologico_1965"},
  {coordinate_system:"ESRI:104127", label: "104127:GCS_Roma_1940"},
  {coordinate_system:"ESRI:104128", label: "104128:GCS_Sphere_EMEP"},
  {coordinate_system:"ESRI:104129", label: "104129:GCS_EUREF_FIN"},
  {coordinate_system:"ESRI:104130", label: "104130:GCS_Jordan"},
  {coordinate_system:"ESRI:104131", label: "104131:GCS_D48"},
  {coordinate_system:"ESRI:104134", label: "104134:GCS_MONREF_1997"},
  {coordinate_system:"ESRI:104135", label: "104135:GCS_MSK_1942"},
  {coordinate_system:"ESRI:104138", label: "104138:GCS_Old_Hawaiian_Intl_1924"},
  {coordinate_system:"ESRI:104139", label: "104139:GCS_Voirol_1875_Grad"},
  {coordinate_system:"ESRI:104140", label: "104140:GCS_Voirol_1879_Grad"},
  {coordinate_system:"ESRI:104261", label: "104261:GCS_Merchich_Degree"},
  {coordinate_system:"ESRI:104602", label: "104602:North_American_1983_3D"},
  {coordinate_system:"ESRI:104613", label: "104613:EUREF_FIN_3D"},
  {coordinate_system:"ESRI:104644", label: "104644:California_SRS_Epoch_2017.50_(NAD83)_3D"},
  {coordinate_system:"ESRI:104645", label: "104645:GGD_3D"},
  {coordinate_system:"ESRI:104648", label: "104648:S-JTSK_[JTSK03]_3D"},
  {coordinate_system:"ESRI:104653", label: "104653:MONREF_1997_3D"},
  {coordinate_system:"ESRI:104664", label: "104664:Nepal_Nagarkot_3D"},
  {coordinate_system:"ESRI:104693", label: "104693:SLD99_3D"},
  {coordinate_system:"ESRI:104696", label: "104696:S_JTSK/05_3D"},
  {coordinate_system:"ESRI:104697", label: "104697:S_JTSK/05_Ferro_3D"},
  {coordinate_system:"ESRI:104700", label: "104700:GCS_NAD_1983_HARN_Adj_MN_Anoka"},
  {coordinate_system:"ESRI:104701", label: "104701:GCS_NAD_1983_HARN_Adj_MN_Becker"},
  {coordinate_system:"ESRI:104702", label: "104702:GCS_NAD_1983_HARN_Adj_MN_Beltrami_North"},
  {coordinate_system:"ESRI:104703", label: "104703:GCS_NAD_1983_HARN_Adj_MN_Beltrami_South"},
  {coordinate_system:"ESRI:104704", label: "104704:GCS_NAD_1983_HARN_Adj_MN_Benton"},
  {coordinate_system:"ESRI:104705", label: "104705:GCS_NAD_1983_HARN_Adj_MN_Big_Stone"},
  {coordinate_system:"ESRI:104706", label: "104706:GCS_NAD_1983_HARN_Adj_MN_Blue_Earth"},
  {coordinate_system:"ESRI:104707", label: "104707:GCS_NAD_1983_HARN_Adj_MN_Brown"},
  {coordinate_system:"ESRI:104708", label: "104708:GCS_NAD_1983_HARN_Adj_MN_Carlton"},
  {coordinate_system:"ESRI:104709", label: "104709:GCS_NAD_1983_HARN_Adj_MN_Carver"},
  {coordinate_system:"ESRI:104710", label: "104710:GCS_NAD_1983_HARN_Adj_MN_Cass_North"},
  {coordinate_system:"ESRI:104711", label: "104711:GCS_NAD_1983_HARN_Adj_MN_Cass_South"},
  {coordinate_system:"ESRI:104712", label: "104712:GCS_NAD_1983_HARN_Adj_MN_Chippewa"},
  {coordinate_system:"ESRI:104713", label: "104713:GCS_NAD_1983_HARN_Adj_MN_Chisago"},
  {coordinate_system:"ESRI:104714", label: "104714:GCS_NAD_1983_HARN_Adj_MN_Cook_North"},
  {coordinate_system:"ESRI:104715", label: "104715:GCS_NAD_1983_HARN_Adj_MN_Cook_South"},
  {coordinate_system:"ESRI:104716", label: "104716:GCS_NAD_1983_HARN_Adj_MN_Cottonwood"},
  {coordinate_system:"ESRI:104717", label: "104717:GCS_NAD_1983_HARN_Adj_MN_Crow_Wing"},
  {coordinate_system:"ESRI:104718", label: "104718:GCS_NAD_1983_HARN_Adj_MN_Dakota"},
  {coordinate_system:"ESRI:104719", label: "104719:GCS_NAD_1983_HARN_Adj_MN_Dodge"},
  {coordinate_system:"ESRI:104720", label: "104720:GCS_NAD_1983_HARN_Adj_MN_Douglas"},
  {coordinate_system:"ESRI:104721", label: "104721:GCS_NAD_1983_HARN_Adj_MN_Faribault"},
  {coordinate_system:"ESRI:104722", label: "104722:GCS_NAD_1983_HARN_Adj_MN_Fillmore"},
  {coordinate_system:"ESRI:104723", label: "104723:GCS_NAD_1983_HARN_Adj_MN_Freeborn"},
  {coordinate_system:"ESRI:104724", label: "104724:GCS_NAD_1983_HARN_Adj_MN_Goodhue"},
  {coordinate_system:"ESRI:104725", label: "104725:GCS_NAD_1983_HARN_Adj_MN_Grant"},
  {coordinate_system:"ESRI:104726", label: "104726:GCS_NAD_1983_HARN_Adj_MN_Hennepin"},
  {coordinate_system:"ESRI:104727", label: "104727:GCS_NAD_1983_HARN_Adj_MN_Houston"},
  {coordinate_system:"ESRI:104728", label: "104728:GCS_NAD_1983_HARN_Adj_MN_Isanti"},
  {coordinate_system:"ESRI:104729", label: "104729:GCS_NAD_1983_HARN_Adj_MN_Itasca_North"},
  {coordinate_system:"ESRI:104730", label: "104730:GCS_NAD_1983_HARN_Adj_MN_Itasca_South"},
  {coordinate_system:"ESRI:104731", label: "104731:GCS_NAD_1983_HARN_Adj_MN_Jackson"},
  {coordinate_system:"ESRI:104732", label: "104732:GCS_NAD_1983_HARN_Adj_MN_Kanabec"},
  {coordinate_system:"ESRI:104733", label: "104733:GCS_NAD_1983_HARN_Adj_MN_Kandiyohi"},
  {coordinate_system:"ESRI:104734", label: "104734:GCS_NAD_1983_HARN_Adj_MN_Kittson"},
  {coordinate_system:"ESRI:104735", label: "104735:GCS_NAD_1983_HARN_Adj_MN_Koochiching"},
  {coordinate_system:"ESRI:104736", label: "104736:GCS_NAD_1983_HARN_Adj_MN_Lac_Qui_Parle"},
  {coordinate_system:"ESRI:104737", label: "104737:GCS_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North"},
  {coordinate_system:"ESRI:104738", label: "104738:GCS_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South"},
  {coordinate_system:"ESRI:104739", label: "104739:GCS_NAD_1983_HARN_Adj_MN_Le_Sueur"},
  {coordinate_system:"ESRI:104740", label: "104740:GCS_NAD_1983_HARN_Adj_MN_Lincoln"},
  {coordinate_system:"ESRI:104741", label: "104741:GCS_NAD_1983_HARN_Adj_MN_Lyon"},
  {coordinate_system:"ESRI:104742", label: "104742:GCS_NAD_1983_HARN_Adj_MN_McLeod"},
  {coordinate_system:"ESRI:104743", label: "104743:GCS_NAD_1983_HARN_Adj_MN_Mahnomen"},
  {coordinate_system:"ESRI:104744", label: "104744:GCS_NAD_1983_HARN_Adj_MN_Marshall"},
  {coordinate_system:"ESRI:104745", label: "104745:GCS_NAD_1983_HARN_Adj_MN_Martin"},
  {coordinate_system:"ESRI:104746", label: "104746:GCS_NAD_1983_HARN_Adj_MN_Meeker"},
  {coordinate_system:"ESRI:104747", label: "104747:GCS_NAD_1983_HARN_Adj_MN_Morrison"},
  {coordinate_system:"ESRI:104748", label: "104748:GCS_NAD_1983_HARN_Adj_MN_Mower"},
  {coordinate_system:"ESRI:104749", label: "104749:GCS_NAD_1983_HARN_Adj_MN_Murray"},
  {coordinate_system:"ESRI:104750", label: "104750:GCS_NAD_1983_HARN_Adj_MN_Nicollet"},
  {coordinate_system:"ESRI:104751", label: "104751:GCS_NAD_1983_HARN_Adj_MN_Nobles"},
  {coordinate_system:"ESRI:104752", label: "104752:GCS_NAD_1983_HARN_Adj_MN_Norman"},
  {coordinate_system:"ESRI:104753", label: "104753:GCS_NAD_1983_HARN_Adj_MN_Olmsted"},
  {coordinate_system:"ESRI:104754", label: "104754:GCS_NAD_1983_HARN_Adj_MN_Ottertail"},
  {coordinate_system:"ESRI:104755", label: "104755:GCS_NAD_1983_HARN_Adj_MN_Pennington"},
  {coordinate_system:"ESRI:104756", label: "104756:GCS_NAD_1983_HARN_Adj_MN_Pine"},
  {coordinate_system:"ESRI:104757", label: "104757:GCS_NAD_1983_HARN_Adj_MN_Pipestone"},
  {coordinate_system:"ESRI:104758", label: "104758:GCS_NAD_1983_HARN_Adj_MN_Polk"},
  {coordinate_system:"ESRI:104759", label: "104759:GCS_NAD_1983_HARN_Adj_MN_Pope"},
  {coordinate_system:"ESRI:104760", label: "104760:GCS_NAD_1983_HARN_Adj_MN_Ramsey"},
  {coordinate_system:"ESRI:104761", label: "104761:GCS_NAD_1983_HARN_Adj_MN_Red_Lake"},
  {coordinate_system:"ESRI:104762", label: "104762:GCS_NAD_1983_HARN_Adj_MN_Redwood"},
  {coordinate_system:"ESRI:104763", label: "104763:GCS_NAD_1983_HARN_Adj_MN_Renville"},
  {coordinate_system:"ESRI:104764", label: "104764:GCS_NAD_1983_HARN_Adj_MN_Rice"},
  {coordinate_system:"ESRI:104765", label: "104765:GCS_NAD_1983_HARN_Adj_MN_Rock"},
  {coordinate_system:"ESRI:104766", label: "104766:GCS_NAD_1983_HARN_Adj_MN_Roseau"},
  {coordinate_system:"ESRI:104767", label: "104767:GCS_NAD_1983_HARN_Adj_MN_St_Louis_North"},
  {coordinate_system:"ESRI:104768", label: "104768:GCS_NAD_1983_HARN_Adj_MN_St_Louis_Central"},
  {coordinate_system:"ESRI:104769", label: "104769:GCS_NAD_1983_HARN_Adj_MN_St_Louis_South"},
  {coordinate_system:"ESRI:104770", label: "104770:GCS_NAD_1983_HARN_Adj_MN_Scott"},
  {coordinate_system:"ESRI:104771", label: "104771:GCS_NAD_1983_HARN_Adj_MN_Sherburne"},
  {coordinate_system:"ESRI:104772", label: "104772:GCS_NAD_1983_HARN_Adj_MN_Sibley"},
  {coordinate_system:"ESRI:104773", label: "104773:GCS_NAD_1983_HARN_Adj_MN_Stearns"},
  {coordinate_system:"ESRI:104774", label: "104774:GCS_NAD_1983_HARN_Adj_MN_Steele"},
  {coordinate_system:"ESRI:104775", label: "104775:GCS_NAD_1983_HARN_Adj_MN_Stevens"},
  {coordinate_system:"ESRI:104776", label: "104776:GCS_NAD_1983_HARN_Adj_MN_Swift"},
  {coordinate_system:"ESRI:104777", label: "104777:GCS_NAD_1983_HARN_Adj_MN_Todd"},
  {coordinate_system:"ESRI:104778", label: "104778:GCS_NAD_1983_HARN_Adj_MN_Traverse"},
  {coordinate_system:"ESRI:104779", label: "104779:GCS_NAD_1983_HARN_Adj_MN_Wabasha"},
  {coordinate_system:"ESRI:104780", label: "104780:GCS_NAD_1983_HARN_Adj_MN_Wadena"},
  {coordinate_system:"ESRI:104781", label: "104781:GCS_NAD_1983_HARN_Adj_MN_Waseca"},
  {coordinate_system:"ESRI:104782", label: "104782:GCS_NAD_1983_HARN_Adj_MN_Watonwan"},
  {coordinate_system:"ESRI:104783", label: "104783:GCS_NAD_1983_HARN_Adj_MN_Winona"},
  {coordinate_system:"ESRI:104784", label: "104784:GCS_NAD_1983_HARN_Adj_MN_Wright"},
  {coordinate_system:"ESRI:104785", label: "104785:GCS_NAD_1983_HARN_Adj_MN_Yellow_Medicine"},
  {coordinate_system:"ESRI:104786", label: "104786:GCS_NAD_1983_HARN_Adj_MN_St_Louis"},
  {coordinate_system:"ESRI:104808", label: "104808:GCS_NAD_1983_HARN_Adj_WI_Chippewa"},
  {coordinate_system:"ESRI:104872", label: "104872:Phobos_2015"},
  {coordinate_system:"ESRI:104873", label: "104873:Callisto_2015"},
  {coordinate_system:"ESRI:104874", label: "104874:Europa_2015"},
  {coordinate_system:"ESRI:104875", label: "104875:Ganymede_2015"},
  {coordinate_system:"ESRI:104876", label: "104876:Io_2015"},
  {coordinate_system:"ESRI:104877", label: "104877:Metis_2015"},
  {coordinate_system:"ESRI:104878", label: "104878:Aegaeon_2015"},
  {coordinate_system:"ESRI:104879", label: "104879:Anthe_2015"},
  {coordinate_system:"ESRI:104897", label: "104897:Pandora_2015"},
  {coordinate_system:"ESRI:104898", label: "104898:Phoebe_2015"},
  {coordinate_system:"ESRI:104899", label: "104899:Polydeuces_2015"},
  {coordinate_system:"ESRI:104900", label: "104900:GCS_Mercury_2000"},
  {coordinate_system:"ESRI:104901", label: "104901:GCS_Venus_1985"},
  {coordinate_system:"ESRI:104902", label: "104902:GCS_Venus_2000"},
  {coordinate_system:"ESRI:104903", label: "104903:GCS_Moon_2000"},
  {coordinate_system:"ESRI:104904", label: "104904:GCS_Mars_1979"},
  {coordinate_system:"ESRI:104905", label: "104905:GCS_Mars_2000"},
  {coordinate_system:"ESRI:104906", label: "104906:GCS_Deimos_2000"},
  {coordinate_system:"ESRI:104907", label: "104907:GCS_Phobos_2000"},
  {coordinate_system:"ESRI:104908", label: "104908:GCS_Jupiter_2000"},
  {coordinate_system:"ESRI:104909", label: "104909:GCS_Adrastea_2000"},
  {coordinate_system:"ESRI:104910", label: "104910:GCS_Amalthea_2000"},
  {coordinate_system:"ESRI:104911", label: "104911:GCS_Ananke_2000"},
  {coordinate_system:"ESRI:104912", label: "104912:GCS_Callisto_2000"},
  {coordinate_system:"ESRI:104913", label: "104913:GCS_Carme_2000"},
  {coordinate_system:"ESRI:104914", label: "104914:GCS_Elara_2000"},
  {coordinate_system:"ESRI:104915", label: "104915:GCS_Europa_2000"},
  {coordinate_system:"ESRI:104916", label: "104916:GCS_Ganymede_2000"},
  {coordinate_system:"ESRI:104917", label: "104917:GCS_Himalia_2000"},
  {coordinate_system:"ESRI:104918", label: "104918:GCS_Io_2000"},
  {coordinate_system:"ESRI:104919", label: "104919:GCS_Leda_2000"},
  {coordinate_system:"ESRI:104920", label: "104920:GCS_Lysithea_2000"},
  {coordinate_system:"ESRI:104921", label: "104921:GCS_Metis_2000"},
  {coordinate_system:"ESRI:104922", label: "104922:GCS_Pasiphae_2000"},
  {coordinate_system:"ESRI:104923", label: "104923:GCS_Sinope_2000"},
  {coordinate_system:"ESRI:104924", label: "104924:GCS_Thebe_2000"},
  {coordinate_system:"ESRI:104925", label: "104925:GCS_Saturn_2000"},
  {coordinate_system:"ESRI:104926", label: "104926:GCS_Atlas_2000"},
  {coordinate_system:"ESRI:104927", label: "104927:GCS_Calypso_2000"},
  {coordinate_system:"ESRI:104928", label: "104928:GCS_Dione_2000"},
  {coordinate_system:"ESRI:104929", label: "104929:GCS_Enceladus_2000"},
  {coordinate_system:"ESRI:104930", label: "104930:GCS_Epimetheus_2000"},
  {coordinate_system:"ESRI:104931", label: "104931:GCS_Helene_2000"},
  {coordinate_system:"ESRI:104932", label: "104932:GCS_Hyperion_2000"},
  {coordinate_system:"ESRI:104933", label: "104933:GCS_Iapetus_2000"},
  {coordinate_system:"ESRI:104934", label: "104934:GCS_Janus_2000"},
  {coordinate_system:"ESRI:104935", label: "104935:GCS_Mimas_2000"},
  {coordinate_system:"ESRI:104936", label: "104936:GCS_Pan_2000"},
  {coordinate_system:"ESRI:104937", label: "104937:GCS_Pandora_2000"},
  {coordinate_system:"ESRI:104938", label: "104938:GCS_Phoebe_2000"},
  {coordinate_system:"ESRI:104939", label: "104939:GCS_Prometheus_2000"},
  {coordinate_system:"ESRI:104940", label: "104940:GCS_Rhea_2000"},
  {coordinate_system:"ESRI:104941", label: "104941:GCS_Telesto_2000"},
  {coordinate_system:"ESRI:104942", label: "104942:GCS_Tethys_2000"},
  {coordinate_system:"ESRI:104943", label: "104943:GCS_Titan_2000"},
  {coordinate_system:"ESRI:104944", label: "104944:GCS_Uranus_2000"},
  {coordinate_system:"ESRI:104945", label: "104945:GCS_Ariel_2000"},
  {coordinate_system:"ESRI:104946", label: "104946:GCS_Belinda_2000"},
  {coordinate_system:"ESRI:104947", label: "104947:GCS_Bianca_2000"},
  {coordinate_system:"ESRI:104948", label: "104948:GCS_Cordelia_2000"},
  {coordinate_system:"ESRI:104949", label: "104949:GCS_Cressida_2000"},
  {coordinate_system:"ESRI:104950", label: "104950:GCS_Desdemona_2000"},
  {coordinate_system:"ESRI:104951", label: "104951:GCS_Juliet_2000"},
  {coordinate_system:"ESRI:104952", label: "104952:GCS_Miranda_2000"},
  {coordinate_system:"ESRI:104953", label: "104953:GCS_Oberon_2000"},
  {coordinate_system:"ESRI:104954", label: "104954:GCS_Ophelia_2000"},
  {coordinate_system:"ESRI:104955", label: "104955:GCS_Portia_2000"},
  {coordinate_system:"ESRI:104956", label: "104956:GCS_Puck_2000"},
  {coordinate_system:"ESRI:104957", label: "104957:GCS_Rosalind_2000"},
  {coordinate_system:"ESRI:104958", label: "104958:GCS_Titania_2000"},
  {coordinate_system:"ESRI:104959", label: "104959:GCS_Umbriel_2000"},
  {coordinate_system:"ESRI:104960", label: "104960:GCS_Neptune_2000"},
  {coordinate_system:"ESRI:104961", label: "104961:GCS_Despina_2000"},
  {coordinate_system:"ESRI:104962", label: "104962:GCS_Galatea_2000"},
  {coordinate_system:"ESRI:104963", label: "104963:GCS_Larissa_2000"},
  {coordinate_system:"ESRI:104964", label: "104964:GCS_Naiad_2000"},
  {coordinate_system:"ESRI:104965", label: "104965:GCS_Nereid_2000"},
  {coordinate_system:"ESRI:104966", label: "104966:GCS_Proteus_2000"},
  {coordinate_system:"ESRI:104967", label: "104967:GCS_Thalassa_2000"},
  {coordinate_system:"ESRI:104968", label: "104968:GCS_Triton_2000"},
  {coordinate_system:"ESRI:104969", label: "104969:GCS_Pluto_2000"},
  {coordinate_system:"ESRI:104970", label: "104970:GCS_Charon_2000"},
  {coordinate_system:"ESRI:104971", label: "104971:Mars_2000_(Sphere)"},
  {coordinate_system:"ESRI:104972", label: "104972:1_Ceres_2015"},
  {coordinate_system:"ESRI:104973", label: "104973:4_Vesta_2015"},
  {coordinate_system:"ESRI:104974", label: "104974:Mercury_2015"},
  {coordinate_system:"ESRI:104975", label: "104975:Sun_2015"},
  {coordinate_system:"ESRI:104976", label: "104976:Atlas_2015"},
  {coordinate_system:"ESRI:104977", label: "104977:Calypso_2015"},
  {coordinate_system:"ESRI:104978", label: "104978:Daphnis_2015"},
  {coordinate_system:"ESRI:104979", label: "104979:Dione_2015"},
  {coordinate_system:"ESRI:104980", label: "104980:Enceladus_2015"},
  {coordinate_system:"ESRI:104981", label: "104981:Epimetheus_2015"},
  {coordinate_system:"ESRI:104982", label: "104982:Helene_2015"},
  {coordinate_system:"ESRI:104983", label: "104983:Hyperion_2015"},
  {coordinate_system:"ESRI:104984", label: "104984:Iapetus_2015"},
  {coordinate_system:"ESRI:104985", label: "104985:Janus_2015"},
  {coordinate_system:"ESRI:104986", label: "104986:Methone_2015"},
  {coordinate_system:"ESRI:104987", label: "104987:Mimas_2015"},
  {coordinate_system:"ESRI:104988", label: "104988:Pallene_2015"},
  {coordinate_system:"ESRI:104989", label: "104989:Pan_2015"},
  {coordinate_system:"ESRI:104993", label: "104993:Prometheus_2015"},
  {coordinate_system:"ESRI:104994", label: "104994:Rhea_2015"},
  {coordinate_system:"ESRI:104995", label: "104995:Telesto_2015"},
  {coordinate_system:"ESRI:104996", label: "104996:Tethys_2015"},
  {coordinate_system:"ESRI:104997", label: "104997:Larissa_2015"},
  {coordinate_system:"ESRI:104998", label: "104998:Pluto_2015"},
  {coordinate_system:"ESRI:104999", label: "104999:Charon_2015"},
  {coordinate_system:"IAU_2015:1000", label: "1000:Sun (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:19900", label: "19900:Mercury (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:19901", label: "19901:Mercury (2015) / Ographic"},
  {coordinate_system:"IAU_2015:29900", label: "29900:Venus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:30100", label: "30100:Moon (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:39900", label: "39900:Earth (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:39901", label: "39901:Earth (2015) / Ographic"},
  {coordinate_system:"IAU_2015:40100", label: "40100:Phobos (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:40200", label: "40200:Deimos (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:49900", label: "49900:Mars (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:49901", label: "49901:Mars (2015) / Ographic"},
  {coordinate_system:"IAU_2015:50100", label: "50100:Io (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50200", label: "50200:Europa (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50300", label: "50300:Ganymede (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50301", label: "50301:Ganymede (2015) / Ographic"},
  {coordinate_system:"IAU_2015:50400", label: "50400:Callisto (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50401", label: "50401:Callisto (2015) / Ographic"},
  {coordinate_system:"IAU_2015:50500", label: "50500:Amalthea (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50600", label: "50600:Himalia (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50700", label: "50700:Elara (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50800", label: "50800:Pasiphae (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:50900", label: "50900:Sinope (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:51000", label: "51000:Lysithea (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:51100", label: "51100:Carme (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:51200", label: "51200:Ananke (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:51300", label: "51300:Leda (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:51400", label: "51400:Thebe (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:51500", label: "51500:Adrastea (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:51600", label: "51600:Metis (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:59900", label: "59900:Jupiter (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:59901", label: "59901:Jupiter (2015) / Ographic"},
  {coordinate_system:"IAU_2015:60100", label: "60100:Mimas (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60200", label: "60200:Enceladus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60300", label: "60300:Tethys (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60400", label: "60400:Dione (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60500", label: "60500:Rhea (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60600", label: "60600:Titan (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60700", label: "60700:Hyperion (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60800", label: "60800:Iapetus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:60801", label: "60801:Iapetus (2015) / Ographic"},
  {coordinate_system:"IAU_2015:60900", label: "60900:Phoebe (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61000", label: "61000:Janus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61100", label: "61100:Epimetheus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61200", label: "61200:Helene (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61300", label: "61300:Telesto (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61400", label: "61400:Calypso (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61500", label: "61500:Atlas (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61600", label: "61600:Prometheus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61700", label: "61700:Pandora (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:61800", label: "61800:Pan (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:63200", label: "63200:Methone (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:63300", label: "63300:Pallene (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:63400", label: "63400:Polydeuces (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:63500", label: "63500:Daphnis (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:64900", label: "64900:Anthe (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:65300", label: "65300:Aegaeon (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:69900", label: "69900:Saturn (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:69901", label: "69901:Saturn (2015) / Ographic"},
  {coordinate_system:"IAU_2015:70100", label: "70100:Ariel (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70200", label: "70200:Umbriel (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70300", label: "70300:Titania (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70400", label: "70400:Oberon (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70500", label: "70500:Miranda (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70600", label: "70600:Cordelia (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70700", label: "70700:Ophelia (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70800", label: "70800:Bianca (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:70900", label: "70900:Cressida (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:71000", label: "71000:Desdemona (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:71100", label: "71100:Juliet (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:71200", label: "71200:Portia (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:71300", label: "71300:Rosalind (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:71400", label: "71400:Belinda (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:71500", label: "71500:Puck (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:79900", label: "79900:Uranus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:79901", label: "79901:Uranus (2015) / Ographic"},
  {coordinate_system:"IAU_2015:80100", label: "80100:Triton (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:80200", label: "80200:Nereid (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:80300", label: "80300:Naiad (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:80301", label: "80301:Naiad (2015) / Ographic"},
  {coordinate_system:"IAU_2015:80400", label: "80400:Thalassa (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:80401", label: "80401:Thalassa (2015) / Ographic"},
  {coordinate_system:"IAU_2015:80500", label: "80500:Despina (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:80501", label: "80501:Despina (2015) / Ographic"},
  {coordinate_system:"IAU_2015:80600", label: "80600:Galatea (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:80601", label: "80601:Galatea (2015) / Ographic"},
  {coordinate_system:"IAU_2015:80700", label: "80700:Larissa (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:80701", label: "80701:Larissa (2015) / Ographic"},
  {coordinate_system:"IAU_2015:80800", label: "80800:Proteus (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:89900", label: "89900:Neptune (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:89901", label: "89901:Neptune (2015) / Ographic"},
  {coordinate_system:"IAU_2015:90100", label: "90100:Charon (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:99900", label: "99900:Pluto (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:100000500", label: "100000500:Borrelly (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:100001200", label: "100001200:Churyumov-Gerasimenko (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:100003600", label: "100003600:Halley (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:100003601", label: "100003601:Halley (2015) / Ographic"},
  {coordinate_system:"IAU_2015:100004100", label: "100004100:Hartley 2 (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:100009300", label: "100009300:Tempel 1 (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:100010700", label: "100010700:Wild 2 (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200000100", label: "200000100:Ceres (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200000101", label: "200000101:Ceres (2015) / Ographic"},
  {coordinate_system:"IAU_2015:200000400", label: "200000400:Vesta (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200001600", label: "200001600:Psyche (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200002100", label: "200002100:Lutetia (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200005200", label: "200005200:52 Europa (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200021600", label: "200021600:Kleopatra (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200025300", label: "200025300:Mathilde (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200043300", label: "200043300:Eros (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200043301", label: "200043301:Eros (2015) / Ographic"},
  {coordinate_system:"IAU_2015:200051100", label: "200051100:Davida (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200286700", label: "200286700:Steins (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:200417900", label: "200417900:Toutatis (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:202514300", label: "202514300:Itokawa (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:243101000", label: "243101000:Ida (2015) - Sphere / Ocentric"},
  {coordinate_system:"IAU_2015:951101000", label: "951101000:Gaspra (2015) - Sphere / Ocentric"},
  {coordinate_system:"IGNF:AMANU49G", label: "AMANU49G:Amanu (NGT 1949) geographiques (dms)"},
  {coordinate_system:"IGNF:AMANU63G", label: "AMANU63G:Amanu (MHPF 1963) geographiques (dms)"},
  {coordinate_system:"IGNF:AMST63G", label: "AMST63G:Amsterdam 1963 geographiques (dms)"},
  {coordinate_system:"IGNF:AMST63GEO", label: "AMST63GEO:Ile Amsterdam 1963 geographiques (dms)"},
  {coordinate_system:"IGNF:ANAA47G", label: "ANAA47G:Anaa (SHM 1947) geographiques (dms)"},
  {coordinate_system:"IGNF:ANAA92G", label: "ANAA92G:Anaa (MOP92) geographiques (dms)"},
  {coordinate_system:"IGNF:ANAA92GEO", label: "ANAA92GEO:Anaa (MOP92) geographiques (dms)"},
  {coordinate_system:"IGNF:APAT48G", label: "APAT48G:Apataki (MGT 1948) geographiques (dms)"},
  {coordinate_system:"IGNF:APAT49G", label: "APAT49G:Apataki (FG 1949) geographiques (dms)"},
  {coordinate_system:"IGNF:APAT80G", label: "APAT80G:Apataki (Cadastre 1980) geographiques (dms)"},
  {coordinate_system:"IGNF:APAT86G", label: "APAT86G:Tuamotu (MOP86) geographiques (dms)"},
  {coordinate_system:"IGNF:ATIGG", label: "ATIGG:ATIG geographiques grades Paris (gr)"},
  {coordinate_system:"IGNF:ATUO63G", label: "ATUO63G:IGN 1963 geographiques (dms)"},
  {coordinate_system:"IGNF:BIEN55G", label: "BIEN55G:Cap Bienvenue geographiques (dms)"},
  {coordinate_system:"IGNF:C67IG95G", label: "C67IG95G:CSG67 (IGN 1995) geographiques (dms)"},
  {coordinate_system:"IGNF:CAD97G", label: "CAD97G:Cadastre 1997 geographiques (dms)"},
  {coordinate_system:"IGNF:CAD97GEO", label: "CAD97GEO:Cadastre 1997 geographiques (dms)"},
  {coordinate_system:"IGNF:CADA80G", label: "CADA80G:Apataki (Cadastre 1980) geographiques (dms)"},
  {coordinate_system:"IGNF:CAPBP55G", label: "CAPBP55G:Cap Bienvenue geographiques (dms)"},
  {coordinate_system:"IGNF:CAPJP55G", label: "CAPJP55G:Cap Jules geographiques (dms)"},
  {coordinate_system:"IGNF:CLIP67G", label: "CLIP67G:Clipperton 1967 geographiques (dms)"},
  {coordinate_system:"IGNF:CROZ63G", label: "CROZ63G:Crozet Possession 1963 geographiques (dms)"},
  {coordinate_system:"IGNF:CROZ63GEO", label: "CROZ63GEO:Crozet - Possession 1963 geographiques (dms)"},
  {coordinate_system:"IGNF:CSG67G", label: "CSG67G:CSG67 geographiques (dms)"},
  {coordinate_system:"IGNF:CSG67GEO", label: "CSG67GEO:CSG67 geographiques (dms)"},
  {coordinate_system:"IGNF:ED50G", label: "ED50G:ED50 geographiques (dms)"},
  {coordinate_system:"IGNF:ED50GEO", label: "ED50GEO:ED50 geographiques (dms)"},
  {coordinate_system:"IGNF:EFATE57G", label: "EFATE57G:Efate geographiques (dms)"},
  {coordinate_system:"IGNF:EFATE57GEO", label: "EFATE57GEO:Efate geographiques (dms)"},
  {coordinate_system:"IGNF:ETRS89G", label: "ETRS89G:ETRS89 geographiques (dms)"},
  {coordinate_system:"IGNF:ETRS89GEO", label: "ETRS89GEO:ETRS89 geographiques (dms)"},
  {coordinate_system:"IGNF:EUROPA54G", label: "EUROPA54G:Europa geographiques (dms)"},
  {coordinate_system:"IGNF:FAKA50G", label: "FAKA50G:Fakarava (SHM 1947-1950) geographiques (dms)"},
  {coordinate_system:"IGNF:FANG651G", label: "FANG651G:Fangataufa (MHPF 1965-1) geographiques (dms)"},
  {coordinate_system:"IGNF:FANG652G", label: "FANG652G:Fangataufa (MHPF 1965-2) geographiques (dms)"},
  {coordinate_system:"IGNF:FANGA50G", label: "FANGA50G:Fangataufa (MGT 1950) geographiques (dms)"},
  {coordinate_system:"IGNF:FANGA64G", label: "FANGA64G:Fangataufa (MHPF 1964) geographiques (dms)"},
  {coordinate_system:"IGNF:FANGA651G", label: "FANGA651G:Fangataufa (MHPF 1965-1) geographiques (dms)"},
  {coordinate_system:"IGNF:FANGA652G", label: "FANGA652G:Fangataufa (MHPF 1965-2) geographiques (dms)"},
  {coordinate_system:"IGNF:FANGA66G", label: "FANGA66G:Fangataufa (MHPF 1966) geographiques (dms)"},
  {coordinate_system:"IGNF:FANGA84G", label: "FANGA84G:Fangataufa (MOP84) geographiques (dms)"},
  {coordinate_system:"IGNF:FATU55FG", label: "FATU55FG:Fatu Huku (MHEFO 1955) geographiques (dms)"},
  {coordinate_system:"IGNF:GLOR77G", label: "GLOR77G:Glorieuses geographiques (dms)"},
  {coordinate_system:"IGNF:GTN51G", label: "GTN51G:Gomen Terme Nord 1951 geographiques (dms)"},
  {coordinate_system:"IGNF:GUAD48G", label: "GUAD48G:Guadeloupe Sainte-Anne geographiques (dms)"},
  {coordinate_system:"IGNF:GUAD48GEO", label: "GUAD48GEO:Guadeloupe Sainte-Anne geographiques (dms)"},
  {coordinate_system:"IGNF:GUADANNG", label: "GUADANNG:Guadeloupe Sainte-Anne geographiques (dms)"},
  {coordinate_system:"IGNF:GUADFM49GEO", label: "GUADFM49GEO:Guadeloupe Fort-Marigot geographiques (dms)"},
  {coordinate_system:"IGNF:GUADFMG", label: "GUADFMG:Guadeloupe Fort-Marigot geographiques (dms)"},
  {coordinate_system:"IGNF:GUAFM48G", label: "GUAFM48G:Guadeloupe Fort-Marigot geographiques (dms)"},
  {coordinate_system:"IGNF:HAO49G", label: "HAO49G:Hao (MGT 1949) geographiques (dms)"},
  {coordinate_system:"IGNF:HAO58G", label: "HAO58G:Hao (MHPF 1958) geographiques (dms)"},
  {coordinate_system:"IGNF:HAOAM67G", label: "HAOAM67G:Hao Amanu (MHPF 1967) geographiques (dms)"},
  {coordinate_system:"IGNF:HAOAMA67G", label: "HAOAMA67G:Hao Amanu (MHPF 1967) geographiques (dms)"},
  {coordinate_system:"IGNF:HARA49G", label: "HARA49G:Haraiki (SHM 1949) geographiques (dms)"},
  {coordinate_system:"IGNF:HIKU50G", label: "HIKU50G:Hikueru (SHM 1947-1950) geographiques (dms)"},
  {coordinate_system:"IGNF:HIVA60G", label: "HIVA60G:Hiva Oa (MHPF 1960) geographiques (dms)"},
  {coordinate_system:"IGNF:HIVA67G", label: "HIVA67G:Hiva Oa (MHPF 1967) geographiques (dms)"},
  {coordinate_system:"IGNF:IGN63G", label: "IGN63G:IGN 1963 geographiques (dms)"},
  {coordinate_system:"IGNF:IGN63GEO", label: "IGN63GEO:IGN63 geographiques (dms)"},
  {coordinate_system:"IGNF:IGN72G", label: "IGN72G:IGN72 geographiques (dms)"},
  {coordinate_system:"IGNF:IGN72GEO", label: "IGN72GEO:IGN72 geographiques (dms)"},
  {coordinate_system:"IGNF:JULES55G", label: "JULES55G:Cap Jules geographiques (dms)"},
  {coordinate_system:"IGNF:KAUE70G", label: "KAUE70G:Kauehi (MHPF70) geographiques (dms)"},
  {coordinate_system:"IGNF:KERG62G", label: "KERG62G:Kerguelen geographiques (dms)"},
  {coordinate_system:"IGNF:KERG62GEO", label: "KERG62GEO:Kerguelen - K0 geographiques (dms)"},
  {coordinate_system:"IGNF:LIFOU56G", label: "LIFOU56G:Lifou IGN 56 geographiques (dms)"},
  {coordinate_system:"IGNF:LNGPGG", label: "LNGPGG:Lambert Nord de Guerre grades Paris"},
  {coordinate_system:"IGNF:LURESG", label: "LURESG:NOUVELLE TRIANGULATION DU DUCHE DU LUXEMBOURG GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:LUXGEO", label: "LUXGEO:NOUVELLE TRIANGULATION DU DUCHE DU LUXEMBOURG GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:MAKE50G", label: "MAKE50G:Makemo (SHM 1947-1950) geographiques (dms)"},
  {coordinate_system:"IGNF:MANGA51G", label: "MANGA51G:Mangareva 1951 geographiques (dms)"},
  {coordinate_system:"IGNF:MARE53G", label: "MARE53G:Mare IGN53 geographiques (dms)"},
  {coordinate_system:"IGNF:MARQUI72G", label: "MARQUI72G:Marquises (IGN72) geographiques (dms)"},
  {coordinate_system:"IGNF:MART38G", label: "MART38G:Martinique Fort-Desaix geographiques (dms)"},
  {coordinate_system:"IGNF:MART38GEO", label: "MART38GEO:Martinique Fort-Desaix geographiques (dms)"},
  {coordinate_system:"IGNF:MARTFDG", label: "MARTFDG:Martinique Fort-Desaix geographiques (dms)"},
  {coordinate_system:"IGNF:MAUPITIG", label: "MAUPITIG:Maupiti (MOP 1983) geographiques (dms)"},
  {coordinate_system:"IGNF:MAYO50G", label: "MAYO50G:Combani triangulation IGN 1950 geographiques (dms)"},
  {coordinate_system:"IGNF:MAYO50GEO", label: "MAYO50GEO:Combani geographiques (dms)"},
  {coordinate_system:"IGNF:MHEFO55FG", label: "MHEFO55FG:Fatu Huku (MHEFO 1955) geographiques (dms)"},
  {coordinate_system:"IGNF:MHEFO55MG", label: "MHEFO55MG:Mohotani (MHEFO 1955) geographiques (dms)"},
  {coordinate_system:"IGNF:MHPF67G", label: "MHPF67G:MHPF 1967 GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:MOHO55G", label: "MOHO55G:Mohotani (MHEFO 1955) geographiques (dms)"},
  {coordinate_system:"IGNF:MOOREA87G", label: "MOOREA87G:Moorea 1987 geographiques (dms)"},
  {coordinate_system:"IGNF:MOOREA87GEO", label: "MOOREA87GEO:Moorea 1987 geographiques (dms)"},
  {coordinate_system:"IGNF:MOP90G", label: "MOP90G:Tetiaroa (MOP90) geographiques (dms)"},
  {coordinate_system:"IGNF:MURU51G", label: "MURU51G:Mururoa (MGT 1951) geographiques (dms)"},
  {coordinate_system:"IGNF:MURU59G", label: "MURU59G:Mururoa (MHPF 1959) geographiques (dms)"},
  {coordinate_system:"IGNF:MURU62G", label: "MURU62G:Mururoa (MHOI 1962) geographiques (dms)"},
  {coordinate_system:"IGNF:MURU78G", label: "MURU78G:Mururoa (IGN 1978) geographiques (dms)"},
  {coordinate_system:"IGNF:NC51G", label: "NC51G:Gomen Terme Nord 1951 geographiques (dms)"},
  {coordinate_system:"IGNF:NEA74G", label: "NEA74G:NEA74 NOUMEA geographiques (dms)"},
  {coordinate_system:"IGNF:NOVA53G", label: "NOVA53G:Juan de Nova  geographiques (dms)"},
  {coordinate_system:"IGNF:NTFG", label: "NTFG:NTF GEOGRAPHIQUES GREENWICH (DMS)"},
  {coordinate_system:"IGNF:NTFP", label: "NTFP:NTF geographiques Paris (gr)"},
  {coordinate_system:"IGNF:NTFPGRAD", label: "NTFPGRAD:NTF geographiques Paris (gr)"},
  {coordinate_system:"IGNF:NUKU72G", label: "NUKU72G:Nuku Hiva 1972 geographiques (dms)"},
  {coordinate_system:"IGNF:NUKU72GEO", label: "NUKU72GEO:Nuku Hiva 1972 geographiques (dms)"},
  {coordinate_system:"IGNF:OUVE72G", label: "OUVE72G:Ouvea MHNC 1972 geographiques (dms)"},
  {coordinate_system:"IGNF:OUVEA72G", label: "OUVEA72G:Ouvea MHNC 1972 geographiques (dms)"},
  {coordinate_system:"IGNF:PDN08G", label: "PDN08G:PITON DES NEIGES (2008) GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:PDN92G", label: "PDN92G:PITON DES NEIGES (1992) GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:PETRELS72G", label: "PETRELS72G:Petrels geographiques (dms)"},
  {coordinate_system:"IGNF:PGP50G", label: "PGP50G:Pointe Geologie Perroud 1950 geographiques (dms)"},
  {coordinate_system:"IGNF:PMAR55G", label: "PMAR55G:Port Martin geographiques (dms)"},
  {coordinate_system:"IGNF:PMARP55G", label: "PMARP55G:Port Martin geographiques (dms)"},
  {coordinate_system:"IGNF:RAIA53G", label: "RAIA53G:IGN53 Societe geographiques (dms)"},
  {coordinate_system:"IGNF:RAIV54G", label: "RAIV54G:Raivavae (Cadastre) geographiques (dms)"},
  {coordinate_system:"IGNF:RAIV66G", label: "RAIV66G:Raivavae (MHPF 1966) geographiques (dms)"},
  {coordinate_system:"IGNF:RANGI47G", label: "RANGI47G:Rangiroa (MGT 1947) geographiques (dms)"},
  {coordinate_system:"IGNF:RANGI47GEO", label: "RANGI47GEO:MGT 1947 geographiques (dms)"},
  {coordinate_system:"IGNF:RANGI59G", label: "RANGI59G:Rangiroa (MHPF 1959) geographiques (dms)"},
  {coordinate_system:"IGNF:RANGI68G", label: "RANGI68G:Rangiroa (MHPF 1966-68) geographiques (dms)"},
  {coordinate_system:"IGNF:RAPA55G", label: "RAPA55G:Rapa (MHEFO 1955) geographiques (dms)"},
  {coordinate_system:"IGNF:RAPA80G", label: "RAPA80G:Rapa (SEQ 1980) geographiques (dms)"},
  {coordinate_system:"IGNF:REUN47G", label: "REUN47G:Reunion Piton des Neiges geographiques (dms)"},
  {coordinate_system:"IGNF:REUN47GEO", label: "REUN47GEO:Reunion Piton des Neiges geographiques (dms)"},
  {coordinate_system:"IGNF:REUN49G", label: "REUN49G:Reunion Piton des Neiges geographiques (dms)"},
  {coordinate_system:"IGNF:RGAF09G", label: "RGAF09G:RGAF09 geographiques (dms)"},
  {coordinate_system:"IGNF:RGAF09GDD", label: "RGAF09GDD:RGAF09 geographiques (dd)"},
  {coordinate_system:"IGNF:RGAF09GEO", label: "RGAF09GEO:RGAF09 geographiques (dms)"},
  {coordinate_system:"IGNF:RGAF09GEODD", label: "RGAF09GEODD:RGAF09 geographiques (dd)"},
  {coordinate_system:"IGNF:RGF93G", label: "RGF93G:RGF93 geographiques (dms)"},
  {coordinate_system:"IGNF:RGF93GDD", label: "RGF93GDD:RGF93 geographiques (dd)"},
  {coordinate_system:"IGNF:RGF93GEO", label: "RGF93GEO:RGF93 geographiques (dms)"},
  {coordinate_system:"IGNF:RGF93GEODD", label: "RGF93GEODD:RGF93 geographiques (dd)"},
  {coordinate_system:"IGNF:RGFG95G", label: "RGFG95G:RGFG95 geographiques (dms)"},
  {coordinate_system:"IGNF:RGFG95GDD", label: "RGFG95GDD:RGFG95 geographiques (dd)"},
  {coordinate_system:"IGNF:RGFG95GEO", label: "RGFG95GEO:RGFG95 geographiques (dms)"},
  {coordinate_system:"IGNF:RGFG95GEODD", label: "RGFG95GEODD:RGFG95 geographiques (dd)"},
  {coordinate_system:"IGNF:RGM04G", label: "RGM04G:RGM04 geographiques (dms)"},
  {coordinate_system:"IGNF:RGM04GDD", label: "RGM04GDD:RGM04 geographiques (dd)"},
  {coordinate_system:"IGNF:RGM04GEO", label: "RGM04GEO:RGM04 geographiques (dms)"},
  {coordinate_system:"IGNF:RGM04GEODD", label: "RGM04GEODD:RGM04 geographiques (dd)"},
  {coordinate_system:"IGNF:RGNCG", label: "RGNCG:RGNC geographiques (dms)"},
  {coordinate_system:"IGNF:RGNCGEO", label: "RGNCGEO:RGNC geographiques (dms)"},
  {coordinate_system:"IGNF:RGNCGEODD", label: "RGNCGEODD:RGNC GEOGRAPHIQUES (DD)"},
  {coordinate_system:"IGNF:RGPFG", label: "RGPFG:RGPF geographiques (dms)"},
  {coordinate_system:"IGNF:RGPFGDD", label: "RGPFGDD:RGPF geographiques (dd)"},
  {coordinate_system:"IGNF:RGPFGEO", label: "RGPFGEO:RGPF geographiques (dms)"},
  {coordinate_system:"IGNF:RGR92G", label: "RGR92G:RGR92 geographiques (dms)"},
  {coordinate_system:"IGNF:RGR92GDD", label: "RGR92GDD:RGR92 geographiques (dd)"},
  {coordinate_system:"IGNF:RGR92GEO", label: "RGR92GEO:RGR92 geographiques (dms)"},
  {coordinate_system:"IGNF:RGR92GEODD", label: "RGR92GEODD:RGR92 geographiques (dd)"},
  {coordinate_system:"IGNF:RGSPM06G", label: "RGSPM06G:RGSPM06 geographiques (dms)"},
  {coordinate_system:"IGNF:RGSPM06GDD", label: "RGSPM06GDD:RGSPM06 geographiques (dd)"},
  {coordinate_system:"IGNF:RGSPM06GEO", label: "RGSPM06GEO:RGSPM06 geographiques (dms)"},
  {coordinate_system:"IGNF:RGSPM06GEODD", label: "RGSPM06GEODD:RGSPM06 geographiques (dd)"},
  {coordinate_system:"IGNF:RGTAAF07G", label: "RGTAAF07G:RGTAAF07 geographiques (dms)"},
  {coordinate_system:"IGNF:RGTAAF07GDD", label: "RGTAAF07GDD:RGTAAF07 geographiques (dd)"},
  {coordinate_system:"IGNF:RGTAAFGEO", label: "RGTAAFGEO:RGTAAF07 geographiques (dms)"},
  {coordinate_system:"IGNF:RGTAAFGEODD", label: "RGTAAFGEODD:RGTAAF07 geographiques (dd)"},
  {coordinate_system:"IGNF:RGWF96G", label: "RGWF96G:RESEAU GEODESIQUE DE WALLIS ET FUTUNA 1996 GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:RGWF96GDD", label: "RGWF96GDD:RESEAU GEODESIQUE DE WALLIS ET FUTUNA 1996 GEOGRAPHIQUES (DD)"},
  {coordinate_system:"IGNF:RGWF96GEO", label: "RGWF96GEO:RESEAU GEODESIQUE DE WALLIS ET FUTUNA 1996 GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:RGWF96GEODD", label: "RGWF96GEODD:RESEAU GEODESIQUE DE WALLIS ET FUTUNA 1996 GEOGRAPHIQUES (DD)"},
  {coordinate_system:"IGNF:RRAFG", label: "RRAFG:RRAF geographiques (dms)"},
  {coordinate_system:"IGNF:RRAFGDD", label: "RRAFGDD:RRAF geographiques (dd)"},
  {coordinate_system:"IGNF:RRAFGEO", label: "RRAFGEO:RRAF geographiques (dms)"},
  {coordinate_system:"IGNF:RRAFGEODD", label: "RRAFGEODD:RRAF geographiques (dd)"},
  {coordinate_system:"IGNF:RUSAT84G", label: "RUSAT84G:Rurutu (SAT84) geographiques (dms)"},
  {coordinate_system:"IGNF:SAT84G", label: "SAT84G:Rurutu (SAT84) geographiques (dms)"},
  {coordinate_system:"IGNF:ST84G", label: "ST84G:ST 84 ILE DES PINS geographiques (dms)"},
  {coordinate_system:"IGNF:ST87G", label: "ST87G:ST 87 OUVEA geographiques (dms)"},
  {coordinate_system:"IGNF:ST87GEO", label: "ST87GEO:ST 87 OUVEA geographiques (dms)"},
  {coordinate_system:"IGNF:STPL69G", label: "STPL69G:Saint-Paul geographiques (dms)"},
  {coordinate_system:"IGNF:STPL69GEO", label: "STPL69GEO:Ile Saint-Paul 1969 geographiques (dms)"},
  {coordinate_system:"IGNF:STPM50G", label: "STPM50G:St Pierre Miquelon 1950 geographiques (dms)"},
  {coordinate_system:"IGNF:STPM50GEO", label: "STPM50GEO:St Pierre Miquelon 1950 geographiques (dms)"},
  {coordinate_system:"IGNF:SYSLNG", label: "SYSLNG:Lambert Nord de Guerre grades Paris"},
  {coordinate_system:"IGNF:TAHAA53G", label: "TAHAA53G:IGN53 Societe geographiques (dms)"},
  {coordinate_system:"IGNF:TAHAAG", label: "TAHAAG:Tahaa geographiques (dms)"},
  {coordinate_system:"IGNF:TAHAAGEO", label: "TAHAAGEO:Tahaa geographiques (dms)"},
  {coordinate_system:"IGNF:TAHI51G", label: "TAHI51G:TAHITI TERME NORD GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:TAHI79G", label: "TAHI79G:Tahiti (IGN79) geographiques (dms)"},
  {coordinate_system:"IGNF:TAHI79GEO", label: "TAHI79GEO:Tahiti (IGN79) geographiques (dms)"},
  {coordinate_system:"IGNF:TAKA69G", label: "TAKA69G:Takaroa Takapoto (SHM 1969) geo. (dms)"},
  {coordinate_system:"IGNF:TANNAG", label: "TANNAG:Tanna geographiques (dms)"},
  {coordinate_system:"IGNF:TERA50G", label: "TERA50G:Pointe Geologie Perroud 1950 geographiques (dms)"},
  {coordinate_system:"IGNF:TERA50GEO", label: "TERA50GEO:Pointe Geologie Perroud 1950 geographiques (dms)"},
  {coordinate_system:"IGNF:TETIA90G", label: "TETIA90G:Tetiaroa (MOP90) geographiques (dms)"},
  {coordinate_system:"IGNF:TIKE50G", label: "TIKE50G:Tikehau (SHM 1947-1950) geographiques (dms)"},
  {coordinate_system:"IGNF:TIKE60G", label: "TIKE60G:Tikehau (MHPF 1960) geographiques (dms)"},
  {coordinate_system:"IGNF:TIKE88G", label: "TIKE88G:TIKEHAU (MOP88) GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:TIKE88GEO", label: "TIKE88GEO:TIKEHAU (MOP88) GEOGRAPHIQUES (DMS)"},
  {coordinate_system:"IGNF:TROM56G", label: "TROM56G:Tromelin geographiques (dms)"},
  {coordinate_system:"IGNF:TUAM86G", label: "TUAM86G:Tuamotu (MOP86) geographiques (dms)"},
  {coordinate_system:"IGNF:TUBU55G", label: "TUBU55G:Tubuai (MGT 1955) geographiques (dms)"},
  {coordinate_system:"IGNF:TUBU69G", label: "TUBU69G:Tubuai (MHPF 1969) geographiques (dms)"},
  {coordinate_system:"IGNF:TURI69G", label: "TURI69G:Tureia (SHM 1969) geographiques (dms)"},
  {coordinate_system:"IGNF:W84MARTGEODD", label: "W84MARTGEODD:RRAF geographiques (dd)"},
  {coordinate_system:"IGNF:WALL76G", label: "WALL76G:Wallis (MOP 1976) geographiques (dms)"},
  {coordinate_system:"IGNF:WALL78G", label: "WALL78G:Wallis (MOP 1978) geographiques (dms)"},
  {coordinate_system:"IGNF:WALL78GEO", label: "WALL78GEO:MOP 1978 geographiques (dms)"},
  {coordinate_system:"IGNF:WGS72G", label: "WGS72G:WGS72 geographiques (dms)"},
  {coordinate_system:"IGNF:WGS72GEO", label: "WGS72GEO:WGS72 geographiques (dms)"},
  {coordinate_system:"IGNF:WGS84G", label: "WGS84G:WGS84 geographiques (dms)"},
  {coordinate_system:"IGNF:WGS84GDD", label: "WGS84GDD:WGS84 geographiques (dd)"},
  {coordinate_system:"IGNF:WGS84GEO", label: "WGS84GEO:WGS84 geographiques (dms)"},
  {coordinate_system:"IGNF:WGS84GEODD", label: "WGS84GEODD:WGS84 geographiques (dd)"},
  {coordinate_system:"IGNF:WGS84GUADGEO", label: "WGS84GUADGEO:RRAF geographiques (dms)"},
  {coordinate_system:"IGNF:WGS84MARTG", label: "WGS84MARTG:RRAF geographiques (dms)"},
  {coordinate_system:"IGNF:WGS84MARTGDD", label: "WGS84MARTGDD:RRAF geographiques (dd)"},
  {coordinate_system:"IGNF:WGS84RRAFGEO", label: "WGS84RRAFGEO:WGS84 geographiques (dms)"},
  {coordinate_system:"OGC:CRS27", label: "CRS27:NAD27 (CRS27)"},
  {coordinate_system:"OGC:CRS83", label: "CRS83:NAD83 (CRS83)"},
  {coordinate_system:"OGC:CRS84", label: "CRS84:WGS 84 (CRS84)"},
  {coordinate_system:"OGC:CRS84h", label: "CRS84h:WGS 84 longitude-latitude-height"},
  ]
  
  export const VERTICAL_SHIFT_SETTING_LIST = [
    {vertical_shift:"ESRI:115700", label: i18n.tc("ESRI_115700")},
    {vertical_shift:"EPSG:3855", label: "3855:EGM2008 height"},
    {vertical_shift:"EPSG:3886", label: "3886:Fao 1979 height"},
    {vertical_shift:"EPSG:3900", label: "3900:N2000 height"},
    {vertical_shift:"EPSG:4440", label: "4440:NZVD2009 height"},
    {vertical_shift:"EPSG:4458", label: "4458:Dunedin-Bluff 1960 height"},
    {vertical_shift:"EPSG:5193", label: "5193:Incheon height"},
    {vertical_shift:"EPSG:5195", label: "5195:Trieste height"},
    {vertical_shift:"EPSG:5214", label: "5214:Genoa 1942 height"},
    {vertical_shift:"EPSG:5237", label: "5237:SLVD height"},
    {vertical_shift:"EPSG:5317", label: "5317:FVR09 height"},
    {vertical_shift:"EPSG:5336", label: "5336:Black Sea depth"},
    {vertical_shift:"EPSG:5597", label: "5597:FCSVR10 height"},
    {vertical_shift:"EPSG:5600", label: "5600:NGPF height"},
    {vertical_shift:"EPSG:5601", label: "5601:IGN 1966 height"},
    {vertical_shift:"EPSG:5602", label: "5602:Moorea SAU 1981 height"},
    {vertical_shift:"EPSG:5603", label: "5603:Raiatea SAU 2001 height"},
    {vertical_shift:"EPSG:5604", label: "5604:Maupiti SAU 2001 height"},
    {vertical_shift:"EPSG:5605", label: "5605:Huahine SAU 2001 height"},
    {vertical_shift:"EPSG:5606", label: "5606:Tahaa SAU 2001 height"},
    {vertical_shift:"EPSG:5607", label: "5607:Bora Bora SAU 2001 height"},
    {vertical_shift:"EPSG:5608", label: "5608:IGLD 1955 height"},
    {vertical_shift:"EPSG:5609", label: "5609:IGLD 1985 height"},
    {vertical_shift:"EPSG:5610", label: "5610:HVRS71 height"},
    {vertical_shift:"EPSG:5611", label: "5611:Caspian height"},
    {vertical_shift:"EPSG:5612", label: "5612:Baltic 1977 depth"},
    {vertical_shift:"EPSG:5613", label: "5613:RH2000 height"},
    {vertical_shift:"EPSG:5614", label: "5614:KOC WD depth (ft)"},
    {vertical_shift:"EPSG:5615", label: "5615:RH00 height"},
    {vertical_shift:"EPSG:5616", label: "5616:IGN 1988 LS height"},
    {vertical_shift:"EPSG:5617", label: "5617:IGN 1988 MG height"},
    {vertical_shift:"EPSG:5618", label: "5618:IGN 1992 LD height"},
    {vertical_shift:"EPSG:5619", label: "5619:IGN 1988 SB height"},
    {vertical_shift:"EPSG:5620", label: "5620:IGN 1988 SM height"},
    {vertical_shift:"EPSG:5621", label: "5621:EVRF2007 height"},
    {vertical_shift:"EPSG:5701", label: "5701:ODN height"},
    {vertical_shift:"EPSG:5702", label: "5702:NGVD29 height (ftUS)"},
    {vertical_shift:"EPSG:5703", label: "5703:NAVD88 height"},
    {vertical_shift:"EPSG:5705", label: "5705:Baltic 1977 height"},
    {vertical_shift:"EPSG:5706", label: "5706:Caspian depth"},
    {vertical_shift:"EPSG:5709", label: "5709:NAP height"},
    {vertical_shift:"EPSG:5710", label: "5710:Ostend height"},
    {vertical_shift:"EPSG:5711", label: "5711:AHD height"},
    {vertical_shift:"EPSG:5712", label: "5712:AHD (Tasmania) height"},
    {vertical_shift:"EPSG:5713", label: "5713:CGVD28 height"},
    {vertical_shift:"EPSG:5714", label: "5714:MSL height"},
    {vertical_shift:"EPSG:5715", label: "5715:MSL depth"},
    {vertical_shift:"EPSG:5716", label: "5716:Piraeus height"},
    {vertical_shift:"EPSG:5717", label: "5717:N60 height"},
    {vertical_shift:"EPSG:5718", label: "5718:RH70 height"},
    {vertical_shift:"EPSG:5719", label: "5719:NGF Lallemand height"},
    {vertical_shift:"EPSG:5720", label: "5720:NGF-IGN69 height"},
    {vertical_shift:"EPSG:5721", label: "5721:NGF-IGN78 height"},
    {vertical_shift:"EPSG:5722", label: "5722:Maputo height"},
    {vertical_shift:"EPSG:5723", label: "5723:JSLD69 height"},
    {vertical_shift:"EPSG:5724", label: "5724:PHD93 height"},
    {vertical_shift:"EPSG:5725", label: "5725:Fahud HD height"},
    {vertical_shift:"EPSG:5726", label: "5726:Ha Tien 1960 height"},
    {vertical_shift:"EPSG:5727", label: "5727:Hon Dau 1992 height"},
    {vertical_shift:"EPSG:5728", label: "5728:LN02 height"},
    {vertical_shift:"EPSG:5729", label: "5729:LHN95 height"},
    {vertical_shift:"EPSG:5730", label: "5730:EVRF2000 height"},
    {vertical_shift:"EPSG:5731", label: "5731:Malin Head height"},
    {vertical_shift:"EPSG:5732", label: "5732:Belfast height"},
    {vertical_shift:"EPSG:5733", label: "5733:DNN height"},
    {vertical_shift:"EPSG:5734", label: "5734:AIOC95 depth"},
    {vertical_shift:"EPSG:5735", label: "5735:Black Sea height"},
    {vertical_shift:"EPSG:5736", label: "5736:Yellow Sea 1956 height"},
    {vertical_shift:"EPSG:5737", label: "5737:Yellow Sea 1985 height"},
    {vertical_shift:"EPSG:5738", label: "5738:HKPD height"},
    {vertical_shift:"EPSG:5739", label: "5739:HKCD depth"},
    {vertical_shift:"EPSG:5740", label: "5740:ODN Orkney height"},
    {vertical_shift:"EPSG:5741", label: "5741:Fair Isle height"},
    {vertical_shift:"EPSG:5742", label: "5742:Lerwick height"},
    {vertical_shift:"EPSG:5743", label: "5743:Foula height"},
    {vertical_shift:"EPSG:5744", label: "5744:Sule Skerry height"},
    {vertical_shift:"EPSG:5745", label: "5745:North Rona height"},
    {vertical_shift:"EPSG:5746", label: "5746:Stornoway height"},
    {vertical_shift:"EPSG:5747", label: "5747:St. Kilda height"},
    {vertical_shift:"EPSG:5748", label: "5748:Flannan Isles height"},
    {vertical_shift:"EPSG:5749", label: "5749:St. Marys height"},
    {vertical_shift:"EPSG:5750", label: "5750:Douglas height"},
    {vertical_shift:"EPSG:5751", label: "5751:Fao height"},
    {vertical_shift:"EPSG:5752", label: "5752:Bandar Abbas height"},
    {vertical_shift:"EPSG:5753", label: "5753:NGNC69 height"},
    {vertical_shift:"EPSG:5754", label: "5754:Poolbeg height (ft(Br36))"},
    {vertical_shift:"EPSG:5755", label: "5755:NGG1977 height"},
    {vertical_shift:"EPSG:5756", label: "5756:Martinique 1987 height"},
    {vertical_shift:"EPSG:5757", label: "5757:Guadeloupe 1988 height"},
    {vertical_shift:"EPSG:5758", label: "5758:Reunion 1989 height"},
    {vertical_shift:"EPSG:5759", label: "5759:Auckland 1946 height"},
    {vertical_shift:"EPSG:5760", label: "5760:Bluff 1955 height"},
    {vertical_shift:"EPSG:5761", label: "5761:Dunedin 1958 height"},
    {vertical_shift:"EPSG:5762", label: "5762:Gisborne 1926 height"},
    {vertical_shift:"EPSG:5763", label: "5763:Lyttelton 1937 height"},
    {vertical_shift:"EPSG:5764", label: "5764:Moturiki 1953 height"},
    {vertical_shift:"EPSG:5765", label: "5765:Napier 1962 height"},
    {vertical_shift:"EPSG:5766", label: "5766:Nelson 1955 height"},
    {vertical_shift:"EPSG:5767", label: "5767:One Tree Point 1964 height"},
    {vertical_shift:"EPSG:5768", label: "5768:Tararu 1952 height"},
    {vertical_shift:"EPSG:5769", label: "5769:Taranaki 1970 height"},
    {vertical_shift:"EPSG:5770", label: "5770:Wellington 1953 height"},
    {vertical_shift:"EPSG:5771", label: "5771:Chatham Island 1959 height"},
    {vertical_shift:"EPSG:5772", label: "5772:Stewart Island 1977 height"},
    {vertical_shift:"EPSG:5773", label: "5773:EGM96 height"},
    {vertical_shift:"EPSG:5774", label: "5774:NG95 height"},
    {vertical_shift:"EPSG:5775", label: "5775:Antalya height"},
    {vertical_shift:"EPSG:5776", label: "5776:NN54 height"},
    {vertical_shift:"EPSG:5777", label: "5777:Durres height"},
    {vertical_shift:"EPSG:5778", label: "5778:GHA height"},
    {vertical_shift:"EPSG:5779", label: "5779:SVS2000 height"},
    {vertical_shift:"EPSG:5780", label: "5780:Cascais height"},
    {vertical_shift:"EPSG:5781", label: "5781:Constanta height"},
    {vertical_shift:"EPSG:5782", label: "5782:Alicante height"},
    {vertical_shift:"EPSG:5783", label: "5783:DHHN92 height"},
    {vertical_shift:"EPSG:5784", label: "5784:DHHN85 height"},
    {vertical_shift:"EPSG:5785", label: "5785:SNN76 height"},
    {vertical_shift:"EPSG:5786", label: "5786:Baltic 1982 height"},
    {vertical_shift:"EPSG:5787", label: "5787:EOMA 1980 height"},
    {vertical_shift:"EPSG:5788", label: "5788:Kuwait PWD height"},
    {vertical_shift:"EPSG:5789", label: "5789:KOC WD depth"},
    {vertical_shift:"EPSG:5790", label: "5790:KOC CD height"},
    {vertical_shift:"EPSG:5791", label: "5791:NGC 1948 height"},
    {vertical_shift:"EPSG:5792", label: "5792:Danger 1950 height"},
    {vertical_shift:"EPSG:5793", label: "5793:Mayotte 1950 height"},
    {vertical_shift:"EPSG:5794", label: "5794:Martinique 1955 height"},
    {vertical_shift:"EPSG:5795", label: "5795:Guadeloupe 1951 height"},
    {vertical_shift:"EPSG:5796", label: "5796:Lagos 1955 height"},
    {vertical_shift:"EPSG:5797", label: "5797:AIOC95 height"},
    {vertical_shift:"EPSG:5798", label: "5798:EGM84 height"},
    {vertical_shift:"EPSG:5799", label: "5799:DVR90 height"},
    {vertical_shift:"EPSG:5829", label: "5829:Instantaneous Water Level height"},
    {vertical_shift:"EPSG:5831", label: "5831:Instantaneous Water Level depth"},
    {vertical_shift:"EPSG:5843", label: "5843:Ras Ghumays height"},
    {vertical_shift:"EPSG:5861", label: "5861:LAT depth"},
    {vertical_shift:"EPSG:5862", label: "5862:LLWLT depth"},
    {vertical_shift:"EPSG:5863", label: "5863:ISLW depth"},
    {vertical_shift:"EPSG:5864", label: "5864:MLLWS depth"},
    {vertical_shift:"EPSG:5865", label: "5865:MLWS depth"},
    {vertical_shift:"EPSG:5866", label: "5866:MLLW depth"},
    {vertical_shift:"EPSG:5867", label: "5867:MLW depth"},
    {vertical_shift:"EPSG:5868", label: "5868:MHW height"},
    {vertical_shift:"EPSG:5869", label: "5869:MHHW height"},
    {vertical_shift:"EPSG:5870", label: "5870:MHWS height"},
    {vertical_shift:"EPSG:5871", label: "5871:HHWLT height"},
    {vertical_shift:"EPSG:5872", label: "5872:HAT height"},
    {vertical_shift:"EPSG:5873", label: "5873:Low Water depth"},
    {vertical_shift:"EPSG:5874", label: "5874:High Water height"},
    {vertical_shift:"EPSG:5941", label: "5941:NN2000 height"},
    {vertical_shift:"EPSG:6130", label: "6130:GCVD54 height (ft)"},
    {vertical_shift:"EPSG:6131", label: "6131:LCVD61 height (ft)"},
    {vertical_shift:"EPSG:6132", label: "6132:CBVD61 height (ft)"},
    {vertical_shift:"EPSG:6178", label: "6178:Cais da Pontinha - Funchal height"},
    {vertical_shift:"EPSG:6179", label: "6179:Cais da Vila - Porto Santo height"},
    {vertical_shift:"EPSG:6180", label: "6180:Cais das Velas height"},
    {vertical_shift:"EPSG:6181", label: "6181:Horta height"},
    {vertical_shift:"EPSG:6182", label: "6182:Cais da Madalena height"},
    {vertical_shift:"EPSG:6183", label: "6183:Santa Cruz da Graciosa height"},
    {vertical_shift:"EPSG:6184", label: "6184:Cais da Figueirinha - Angra do Heroismo height"},
    {vertical_shift:"EPSG:6185", label: "6185:Santa Cruz das Flores height"},
    {vertical_shift:"EPSG:6186", label: "6186:Cais da Vila do Porto height"},
    {vertical_shift:"EPSG:6187", label: "6187:Ponta Delgada height"},
    {vertical_shift:"EPSG:6357", label: "6357:NAVD88 depth"},
    {vertical_shift:"EPSG:6358", label: "6358:NAVD88 depth (ftUS)"},
    {vertical_shift:"EPSG:6359", label: "6359:NGVD29 depth (ftUS)"},
    {vertical_shift:"EPSG:6360", label: "6360:NAVD88 height (ftUS)"},
    {vertical_shift:"EPSG:6638", label: "6638:Tutuila 1962 height"},
    {vertical_shift:"EPSG:6639", label: "6639:Guam 1963 height"},
    {vertical_shift:"EPSG:6640", label: "6640:NMVD03 height"},
    {vertical_shift:"EPSG:6641", label: "6641:PRVD02 height"},
    {vertical_shift:"EPSG:6642", label: "6642:VIVD09 height"},
    {vertical_shift:"EPSG:6643", label: "6643:ASVD02 height"},
    {vertical_shift:"EPSG:6644", label: "6644:GUVD04 height"},
    {vertical_shift:"EPSG:6647", label: "6647:CGVD2013(CGG2013) height"},
    {vertical_shift:"EPSG:6693", label: "6693:JSLD72 height"},
    {vertical_shift:"EPSG:6694", label: "6694:JGD2000 (vertical) height"},
    {vertical_shift:"EPSG:6695", label: "6695:JGD2011 (vertical) height"},
    {vertical_shift:"EPSG:6916", label: "6916:SHD height"},
    {vertical_shift:"EPSG:7446", label: "7446:Famagusta 1960 height"},
    {vertical_shift:"EPSG:7447", label: "7447:PNG08 height"},
    {vertical_shift:"EPSG:7651", label: "7651:Kumul 34 height"},
    {vertical_shift:"EPSG:7652", label: "7652:Kiunga height"},
    {vertical_shift:"EPSG:7699", label: "7699:DHHN12 height"},
    {vertical_shift:"EPSG:7700", label: "7700:Latvia 2000 height"},
    {vertical_shift:"EPSG:7707", label: "7707:ODN (Offshore) height"},
    {vertical_shift:"EPSG:7832", label: "7832:POM96 height"},
    {vertical_shift:"EPSG:7837", label: "7837:DHHN2016 height"},
    {vertical_shift:"EPSG:7839", label: "7839:NZVD2016 height"},
    {vertical_shift:"EPSG:7841", label: "7841:POM08 height"},
    {vertical_shift:"EPSG:7888", label: "7888:Jamestown 1971 height"},
    {vertical_shift:"EPSG:7889", label: "7889:St. Helena Tritan 2011 height"},
    {vertical_shift:"EPSG:7890", label: "7890:SHVD2015 height"},
    {vertical_shift:"EPSG:7962", label: "7962:Poolbeg height (m)"},
    {vertical_shift:"EPSG:7968", label: "7968:NGVD29 height (m)"},
    {vertical_shift:"EPSG:7976", label: "7976:HKPD depth"},
    {vertical_shift:"EPSG:7979", label: "7979:KOC WD height"},
    {vertical_shift:"EPSG:8050", label: "8050:MSL height (ft)"},
    {vertical_shift:"EPSG:8051", label: "8051:MSL depth (ft)"},
    {vertical_shift:"EPSG:8052", label: "8052:MSL height (ftUS)"},
    {vertical_shift:"EPSG:8053", label: "8053:MSL depth (ftUS)"},
    {vertical_shift:"EPSG:8089", label: "8089:ISH2004 height"},
    {vertical_shift:"EPSG:8228", label: "8228:NAVD88 height (ft)"},
    {vertical_shift:"EPSG:8266", label: "8266:GVR2000 height"},
    {vertical_shift:"EPSG:8267", label: "8267:GVR2016 height"},
    {vertical_shift:"EPSG:8357", label: "8357:Baltic 1957 height"},
    {vertical_shift:"EPSG:8358", label: "8358:Baltic 1957 depth"},
    {vertical_shift:"EPSG:8378", label: "8378:EPSG example wellbore local vertical CRS"},
    {vertical_shift:"EPSG:8434", label: "8434:Macao height"},
    {vertical_shift:"EPSG:8675", label: "8675:N43 height"},
    {vertical_shift:"EPSG:8690", label: "8690:SVS2010 height"},
    {vertical_shift:"EPSG:8691", label: "8691:SRB_VRS12 height"},
    {vertical_shift:"EPSG:8841", label: "8841:MVGC height"},
    {vertical_shift:"EPSG:8881", label: "8881:Vienna height"},
    {vertical_shift:"EPSG:8897", label: "8897:EPSG example wellbore local vertical CRS (ft)"},
    {vertical_shift:"EPSG:8904", label: "8904:TWVD 2001 height"},
    {vertical_shift:"EPSG:8911", label: "8911:DACR52 height"},
    {vertical_shift:"EPSG:9130", label: "9130:IGN 2008 LD height"},
    {vertical_shift:"EPSG:9245", label: "9245:CGVD2013a(2010) height"},
    {vertical_shift:"EPSG:9255", label: "9255:SRVN16 height"},
    {vertical_shift:"EPSG:9274", label: "9274:EVRF2000 Austria height"},
    {vertical_shift:"EPSG:9279", label: "9279:SA LLD height"},
    {vertical_shift:"EPSG:9287", label: "9287:LAT NL depth"},
    {vertical_shift:"EPSG:9288", label: "9288:MSL NL depth"},
    {vertical_shift:"EPSG:9303", label: "9303:HS2-VRF height"},
    {vertical_shift:"EPSG:9335", label: "9335:KSA-VRF14 height"},
    {vertical_shift:"EPSG:9351", label: "9351:NGNC08 height"},
    {vertical_shift:"EPSG:9389", label: "9389:EVRF2019 height"},
    {vertical_shift:"EPSG:9390", label: "9390:EVRF2019 mean-tide height"},
    {vertical_shift:"EPSG:9392", label: "9392:Mallorca height"},
    {vertical_shift:"EPSG:9393", label: "9393:Menorca height"},
    {vertical_shift:"EPSG:9394", label: "9394:Ibiza height"},
    {vertical_shift:"EPSG:9395", label: "9395:Lanzarote height"},
    {vertical_shift:"EPSG:9396", label: "9396:Fuerteventura height"},
    {vertical_shift:"EPSG:9397", label: "9397:Gran Canaria height"},
    {vertical_shift:"EPSG:9398", label: "9398:Tenerife height"},
    {vertical_shift:"EPSG:9399", label: "9399:La Gomera height"},
    {vertical_shift:"EPSG:9400", label: "9400:La Palma height"},
    {vertical_shift:"EPSG:9401", label: "9401:El Hierro height"},
    {vertical_shift:"EPSG:9402", label: "9402:Ceuta 2 height"},
    {vertical_shift:"EPSG:9451", label: "9451:BI height"},
    {vertical_shift:"EPSG:9458", label: "9458:AVWS height"},
    {vertical_shift:"EPSG:9471", label: "9471:INAGeoid2020 v1 height"},
    {vertical_shift:"EPSG:9650", label: "9650:Baltic 1986 height"},
    {vertical_shift:"EPSG:9651", label: "9651:EVRF2007-PL height"},
    {vertical_shift:"EPSG:9663", label: "9663:EH2000 height"},
    {vertical_shift:"EPSG:9666", label: "9666:LAS07 height"},
    {vertical_shift:"EPSG:9669", label: "9669:BGS2005 height"},
    {vertical_shift:"EPSG:9672", label: "9672:CD Norway depth"},
    {vertical_shift:"EPSG:9675", label: "9675:Pago Pago 2020 height"},
    {vertical_shift:"EPSG:9681", label: "9681:NVD 1992 height"},
    {vertical_shift:"EPSG:9721", label: "9721:Catania 1965 height"},
    {vertical_shift:"EPSG:9722", label: "9722:Cagliari 1956 height"},
    {vertical_shift:"EPSG:9923", label: "9923:GNTRANS height"},
    {vertical_shift:"EPSG:9927", label: "9927:GNTRANS2016 height"},
    {vertical_shift:"EPSG:20000", label: "20000:SVD2006 height"},
    {vertical_shift:"EPSG:20034", label: "20034:CGVD2013a(2002) height"},
    {vertical_shift:"EPSG:20035", label: "20035:CGVD2013a(1997) height"},
    {vertical_shift:"EPSG:20036", label: "20036:INAGeoid2020 v2 height"},
    {vertical_shift:"ESRI:9650", label: "9650:Baltic_1986_height"},
    {vertical_shift:"ESRI:9651", label: "9651:EVRF_2007_PL_height"},
    {vertical_shift:"ESRI:9721", label: "9721:Catania_1965_height"},
    {vertical_shift:"ESRI:9722", label: "9722:Cagliari_1956_height"},
    {vertical_shift:"ESRI:105603", label: "105603:REDNAP_height"},
    {vertical_shift:"ESRI:105700", label: "105700:WGS_1984_Geoid"},
    {vertical_shift:"ESRI:115701", label: "115701:ETRS_1989"},
    {vertical_shift:"ESRI:115702", label: "115702:NAD_1983"},
    {vertical_shift:"ESRI:115703", label: "115703:Australian_Antarctic_1998"},
    {vertical_shift:"ESRI:115704", label: "115704:Cadastre_1997"},
    {vertical_shift:"ESRI:115705", label: "115705:China_Geodetic_Coordinate_System_2000"},
    {vertical_shift:"ESRI:115706", label: "115706:Swiss_TRF_1995"},
    {vertical_shift:"ESRI:115707", label: "115707:CIGD11"},
    {vertical_shift:"ESRI:115708", label: "115708:CR05"},
    {vertical_shift:"ESRI:115709", label: "115709:DB_REF"},
    {vertical_shift:"ESRI:115710", label: "115710:DGN_1995"},
    {vertical_shift:"ESRI:115711", label: "115711:DRUKREF_03"},
    {vertical_shift:"ESRI:115712", label: "115712:Estonia_1997"},
    {vertical_shift:"ESRI:115713", label: "115713:EUREF_FIN"},
    {vertical_shift:"ESRI:115714", label: "115714:FEH2010"},
    {vertical_shift:"ESRI:115715", label: "115715:GDA_1994"},
    {vertical_shift:"ESRI:115716", label: "115716:GDBD2009"},
    {vertical_shift:"ESRI:115717", label: "115717:GDM_2000"},
    {vertical_shift:"ESRI:115718", label: "115718:Greenland_1996"},
    {vertical_shift:"ESRI:115719", label: "115719:Hartebeesthoek_1994"},
    {vertical_shift:"ESRI:115720", label: "115720:IGD05"},
    {vertical_shift:"ESRI:115721", label: "115721:IG05_Intermediate_CRS"},
    {vertical_shift:"ESRI:115722", label: "115722:IGD05(2012)"},
    {vertical_shift:"ESRI:115723", label: "115723:IG05(2012)_Intermediate_CRS"},
    {vertical_shift:"ESRI:115724", label: "115724:IGM_1995"},
    {vertical_shift:"ESRI:115725", label: "115725:IGRS"},
    {vertical_shift:"ESRI:115726", label: "115726:IRENET95"},
    {vertical_shift:"ESRI:115727", label: "115727:ISN_1993"},
    {vertical_shift:"ESRI:115728", label: "115728:ISN_2004"},
    {vertical_shift:"ESRI:115729", label: "115729:ITRF_1988"},
    {vertical_shift:"ESRI:115730", label: "115730:ITRF_1989"},
    {vertical_shift:"ESRI:115731", label: "115731:ITRF_1990"},
    {vertical_shift:"ESRI:115732", label: "115732:ITRF_1991"},
    {vertical_shift:"ESRI:115733", label: "115733:ITRF_1992"},
    {vertical_shift:"ESRI:115734", label: "115734:ITRF_1993"},
    {vertical_shift:"ESRI:115735", label: "115735:ITRF_1996"},
    {vertical_shift:"ESRI:115736", label: "115736:ITRF_1997"},
    {vertical_shift:"ESRI:115737", label: "115737:ITRF_2000"},
    {vertical_shift:"ESRI:115738", label: "115738:ITRF_2005"},
    {vertical_shift:"ESRI:115739", label: "115739:JAD_2001"},
    {vertical_shift:"ESRI:115740", label: "115740:JGD_2000"},
    {vertical_shift:"ESRI:115741", label: "115741:JGD_2011"},
    {vertical_shift:"ESRI:115742", label: "115742:Korea_2000"},
    {vertical_shift:"ESRI:115743", label: "115743:Lao_1997"},
    {vertical_shift:"ESRI:115744", label: "115744:LGD2006"},
    {vertical_shift:"ESRI:115745", label: "115745:LKS_1992"},
    {vertical_shift:"ESRI:115746", label: "115746:LKS_1994"},
    {vertical_shift:"ESRI:115747", label: "115747:MACAO_2008"},
    {vertical_shift:"ESRI:115748", label: "115748:MAGNA"},
    {vertical_shift:"ESRI:115749", label: "115749:MARCARIO_SOLIS"},
    {vertical_shift:"ESRI:115750", label: "115750:MARGEN"},
    {vertical_shift:"ESRI:115751", label: "115751:Mexico_ITRF2008"},
    {vertical_shift:"ESRI:115752", label: "115752:MOLDREF99"},
    {vertical_shift:"ESRI:115753", label: "115753:MONREF_1997"},
    {vertical_shift:"ESRI:115754", label: "115754:Moznet"},
    {vertical_shift:"ESRI:115755", label: "115755:NAD_1983_2011"},
    {vertical_shift:"ESRI:115756", label: "115756:NAD_1983_CORS96"},
    {vertical_shift:"ESRI:115757", label: "115757:North_American_1983_CSRS"},
    {vertical_shift:"ESRI:115758", label: "115758:North_American_1983_HARN"},
    {vertical_shift:"ESRI:115759", label: "115759:NAD_1983_MA11"},
    {vertical_shift:"ESRI:115760", label: "115760:NAD_1983_MARP00"},
    {vertical_shift:"ESRI:115761", label: "115761:NAD_1983_NSRS2007"},
    {vertical_shift:"ESRI:115762", label: "115762:NAD_1983_PA11"},
    {vertical_shift:"ESRI:115763", label: "115763:NAD_1983_PACP00"},
    {vertical_shift:"ESRI:115764", label: "115764:Nepal_Nagarkot"},
    {vertical_shift:"ESRI:115765", label: "115765:NZGD_2000"},
    {vertical_shift:"ESRI:115766", label: "115766:Peru96"},
    {vertical_shift:"ESRI:115767", label: "115767:PNG94"},
    {vertical_shift:"ESRI:115768", label: "115768:POSGAR"},
    {vertical_shift:"ESRI:115769", label: "115769:POSGAR_1994"},
    {vertical_shift:"ESRI:115770", label: "115770:POSGAR_1998"},
    {vertical_shift:"ESRI:115771", label: "115771:PRS_1992"},
    {vertical_shift:"ESRI:115772", label: "115772:PTRA08"},
    {vertical_shift:"ESRI:115773", label: "115773:PZ_1990"},
    {vertical_shift:"ESRI:115774", label: "115774:RDN2008"},
    {vertical_shift:"ESRI:115775", label: "115775:REGCAN95"},
    {vertical_shift:"ESRI:115776", label: "115776:REGVEN"},
    {vertical_shift:"ESRI:115777", label: "115777:RGAF09"},
    {vertical_shift:"ESRI:115778", label: "115778:RGF_1993"},
    {vertical_shift:"ESRI:115779", label: "115779:RGFG_1995"},
    {vertical_shift:"ESRI:115780", label: "115780:RGM_2004"},
    {vertical_shift:"ESRI:115781", label: "115781:RGNC_1991"},
    {vertical_shift:"ESRI:115782", label: "115782:RGNC_1991-93"},
    {vertical_shift:"ESRI:115783", label: "115783:RGPF"},
    {vertical_shift:"ESRI:115784", label: "115784:RGR_1992"},
    {vertical_shift:"ESRI:115785", label: "115785:RGRDC_2005"},
    {vertical_shift:"ESRI:115786", label: "115786:RGSPM_2006"},
    {vertical_shift:"ESRI:115787", label: "115787:RRAF_1991"},
    {vertical_shift:"ESRI:115788", label: "115788:RSRGD2000"},
    {vertical_shift:"ESRI:115789", label: "115789:SIRGAS_2000"},
    {vertical_shift:"ESRI:115790", label: "115790:SIRGAS-Chile"},
    {vertical_shift:"ESRI:115791", label: "115791:SIRGAS_ES2007.8"},
    {vertical_shift:"ESRI:115792", label: "115792:SIRGAS-ROU98"},
    {vertical_shift:"ESRI:115793", label: "115793:SLD99"},
    {vertical_shift:"ESRI:115794", label: "115794:Slovenia_1996"},
    {vertical_shift:"ESRI:115795", label: "115795:SREF98"},
    {vertical_shift:"ESRI:115796", label: "115796:S_JTSK/05"},
    {vertical_shift:"ESRI:115797", label: "115797:S_JTSK/05_Ferro"},
    {vertical_shift:"ESRI:115798", label: "115798:SWEREF99"},
    {vertical_shift:"ESRI:115799", label: "115799:TGD2005"},
    {vertical_shift:"ESRI:115800", label: "115800:TWD_1997"},
    {vertical_shift:"ESRI:115801", label: "115801:Ukraine_2000"},
    {vertical_shift:"ESRI:115802", label: "115802:Yemen_NGN_1996"},
    {vertical_shift:"ESRI:115803", label: "115803:ITRF_2008"},
    {vertical_shift:"ESRI:115804", label: "115804:TUREF"},
    {vertical_shift:"ESRI:115805", label: "115805:GDA2020"},
    {vertical_shift:"ESRI:115806", label: "115806:BGS2005"},
    {vertical_shift:"ESRI:115807", label: "115807:Unknown_height_system_(meters)"},
    {vertical_shift:"ESRI:115808", label: "115808:Unknown_height_system_(US_survey_feet)"},
    {vertical_shift:"ESRI:115809", label: "115809:Unknown_height_system_(Intl_Feet)"},
    {vertical_shift:"ESRI:115810", label: "115810:ITRF2014"},
    {vertical_shift:"ESRI:115811", label: "115811:SHGD2015"},
    {vertical_shift:"ESRI:115812", label: "115812:St_Helena_Tritan"},
    {vertical_shift:"ESRI:115813", label: "115813:GSK-2011"},
    {vertical_shift:"ESRI:115814", label: "115814:PZ-90.11"},
    {vertical_shift:"ESRI:115815", label: "115815:PZ-90.02"},
    {vertical_shift:"ESRI:115816", label: "115816:WGS_1984_(Transit)"},
    {vertical_shift:"ESRI:115817", label: "115817:WGS_1984_(G1762)"},
    {vertical_shift:"ESRI:115818", label: "115818:WGS_1984_(G1674)"},
    {vertical_shift:"ESRI:115819", label: "115819:WGS_1984_(G1150)"},
    {vertical_shift:"ESRI:115820", label: "115820:WGS_1984_(G873)"},
    {vertical_shift:"ESRI:115821", label: "115821:WGS_1984_(G730)"},
    {vertical_shift:"ESRI:115822", label: "115822:ETRF90"},
    {vertical_shift:"ESRI:115823", label: "115823:ETRF91"},
    {vertical_shift:"ESRI:115824", label: "115824:ETRF92"},
    {vertical_shift:"ESRI:115825", label: "115825:ETRF93"},
    {vertical_shift:"ESRI:115826", label: "115826:ETRF94"},
    {vertical_shift:"ESRI:115827", label: "115827:ETRF96"},
    {vertical_shift:"ESRI:115828", label: "115828:ETRF97"},
    {vertical_shift:"ESRI:115829", label: "115829:ETRF2000"},
    {vertical_shift:"ESRI:115830", label: "115830:NAD83(CSRS96)"},
    {vertical_shift:"ESRI:115831", label: "115831:NAD83(CSRS)v2"},
    {vertical_shift:"ESRI:115832", label: "115832:NAD83(CSRS)v3"},
    {vertical_shift:"ESRI:115833", label: "115833:NAD83(CSRS)v4"},
    {vertical_shift:"ESRI:115834", label: "115834:NAD83(CSRS)v5"},
    {vertical_shift:"ESRI:115835", label: "115835:NAD83(CSRS)v6"},
    {vertical_shift:"ESRI:115836", label: "115836:NAD83(CSRS)v7"},
    {vertical_shift:"ESRI:115837", label: "115837:IGS14"},
    {vertical_shift:"ESRI:115838", label: "115838:ISN2016"},
    {vertical_shift:"ESRI:115839", label: "115839:Hong_Kong_Geodetic_CS"},
    {vertical_shift:"ESRI:115840", label: "115840:NAD_1983_(FBN)"},
    {vertical_shift:"ESRI:115841", label: "115841:NAD_1983_(HARN_Corrected)"},
    {vertical_shift:"ESRI:115842", label: "115842:SRB_ETRS89"},
    {vertical_shift:"ESRI:115843", label: "115843:MTRF-2000"},
    {vertical_shift:"ESRI:115844", label: "115844:California_SRS_Epoch_2017.50_(NAD83)"},
    {vertical_shift:"ESRI:115845", label: "115845:GGD"},
    {vertical_shift:"ESRI:115846", label: "115846:ONGD14"},
    {vertical_shift:"ESRI:115847", label: "115847:ONGD17"},
    {vertical_shift:"ESRI:115848", label: "115848:S-JTSK_[JTSK03]"},
    {vertical_shift:"ESRI:115849", label: "115849:CR-SIRGAS"},
    {vertical_shift:"ESRI:115850", label: "115850:RGWF96"},
    {vertical_shift:"ESRI:115851", label: "115851:SIRGAS-CON_DGF00P01"},
    {vertical_shift:"ESRI:115852", label: "115852:SIRGAS-CON_DGF01P01"},
    {vertical_shift:"ESRI:115853", label: "115853:SIRGAS-CON_DGF01P02"},
    {vertical_shift:"ESRI:115854", label: "115854:SIRGAS-CON_DGF02P01"},
    {vertical_shift:"ESRI:115855", label: "115855:SIRGAS-CON_DGF04P01"},
    {vertical_shift:"ESRI:115856", label: "115856:SIRGAS-CON_DGF05P01"},
    {vertical_shift:"ESRI:115857", label: "115857:SIRGAS-CON_DGF06P01"},
    {vertical_shift:"ESRI:115858", label: "115858:SIRGAS-CON_DGF07P01"},
    {vertical_shift:"ESRI:115859", label: "115859:SIRGAS-CON_DGF08P01"},
    {vertical_shift:"ESRI:115860", label: "115860:SIRGAS-CON_SIR09P01"},
    {vertical_shift:"ESRI:115861", label: "115861:SIRGAS-CON_SIR10P01"},
    {vertical_shift:"ESRI:115862", label: "115862:SIRGAS-CON_SIR11P01"},
    {vertical_shift:"ESRI:115863", label: "115863:SIRGAS-CON_SIR13P01"},
    {vertical_shift:"ESRI:115864", label: "115864:SIRGAS-CON_SIR14P01"},
    {vertical_shift:"ESRI:115865", label: "115865:SIRGAS-CON_SIR15P01"},
    {vertical_shift:"ESRI:115866", label: "115866:SIRGAS-CON_SIR17P01"},
    {vertical_shift:"ESRI:115867", label: "115867:IGS97"},
    {vertical_shift:"ESRI:115868", label: "115868:IGS00"},
    {vertical_shift:"ESRI:115869", label: "115869:IGb00"},
    {vertical_shift:"ESRI:115870", label: "115870:IGS05"},
    {vertical_shift:"ESRI:115871", label: "115871:IGb08"},
    {vertical_shift:"ESRI:115872", label: "115872:KOSOVAREF01"},
    {vertical_shift:"ESRI:115873", label: "115873:ETRF2005"},
    {vertical_shift:"ESRI:115874", label: "115874:ETRF2014"},
    {vertical_shift:"ESRI:115875", label: "115875:CH1903+"},
    {vertical_shift:"ESRI:115876", label: "115876:ATRF2014"},
    {vertical_shift:"ESRI:115877", label: "115877:Australian_1984"},
    {vertical_shift:"ESRI:115878", label: "115878:Australian_1966"},
    {vertical_shift:"ESRI:115879", label: "115879:ITRF_1994"},
    {vertical_shift:"IGNF:BORA01", label: "BORA01:BORA_SAU 2001"},
    {vertical_shift:"IGNF:BOURD", label: "BOURD:NGF-BOURDALOUE"},
    {vertical_shift:"IGNF:EVRF2000", label: "EVRF2000:EVRF2000 (UELN-95/98)(EUROPEAN VERTICAL REFERENCE FRAME)"},
    {vertical_shift:"IGNF:EVRF2007", label: "EVRF2007:EVRF2007 (EUROPEAN VERTICAL REFERENCE FRAME 2007)"},
    {vertical_shift:"IGNF:FUTUNA1997", label: "FUTUNA1997:NGWF ILES HORN (FUTUNA ET ALOFI)"},
    {vertical_shift:"IGNF:GLOR77", label: "GLOR77:SHOM 1977 (ILES GLORIEUSES - CANAL DE MOZAMBIQUE)"},
    {vertical_shift:"IGNF:GUAD2008LD", label: "GUAD2008LD:IGN 2008 LD (GUADELOUPE / LA DESIRADE)"},
    {vertical_shift:"IGNF:GUAD88", label: "GUAD88:IGN 1988 (GUADELOUPE)"},
    {vertical_shift:"IGNF:GUAD88LS", label: "GUAD88LS:IGN 1988 LS (GUADELOUPE / LES SAINTES)"},
    {vertical_shift:"IGNF:GUAD88MG", label: "GUAD88MG:IGN 1988 MG (GUADELOUPE / MARIE-GALANTE)"},
    {vertical_shift:"IGNF:GUAD88SB", label: "GUAD88SB:IGN 1988 SB (GUADELOUPE / SAINT-BARTHELEMY)"},
    {vertical_shift:"IGNF:GUAD88SM", label: "GUAD88SM:IGN 1988 SM (GUADELOUPE / SAINT-MARTIN)"},
    {vertical_shift:"IGNF:GUAD92LD", label: "GUAD92LD:IGN 1992 LD (GUADELOUPE / LA DESIRADE)"},
    {vertical_shift:"IGNF:GUYA77", label: "GUYA77:NIVELLEMENT GENERAL DE GUYANE (NGG) 1977"},
    {vertical_shift:"IGNF:HUAH01", label: "HUAH01:HUAHINE_SAU 2001"},
    {vertical_shift:"IGNF:IGN69", label: "IGN69:NGF-IGN 1969"},
    {vertical_shift:"IGNF:IGN78C", label: "IGN78C:NGF-IGN 1978"},
    {vertical_shift:"IGNF:KERG62", label: "KERG62:IGN 1962 (KERGUELEN)"},
    {vertical_shift:"IGNF:LIFOU91", label: "LIFOU91:NIVELLEMENT GENERAL DE LIFOU (IGN 1991 LF)"},
    {vertical_shift:"IGNF:MARE91", label: "MARE91:NIVELLEMENT GENERAL DE MARE (IGN 1991 MR)"},
    {vertical_shift:"IGNF:MART87", label: "MART87:IGN 1987 (MARTINIQUE)"},
    {vertical_shift:"IGNF:MAUPITI01", label: "MAUPITI01:MAUPITI_SAU 2001"},
    {vertical_shift:"IGNF:MAYO53", label: "MAYO53:SHOM 1953 (MAYOTTE)"},
    {vertical_shift:"IGNF:MOOREA81", label: "MOOREA81:MOOREA 1981 (MOOREA_SAU 2001)"},
    {vertical_shift:"IGNF:NCAL69", label: "NCAL69:NIVELLEMENT GENERAL DE NOUVELLE-CALEDONIE (NGNC)"},
    {vertical_shift:"IGNF:NGC48", label: "NGC48:NIVELLEMENT GENERAL DE LA CORSE (NGC)"},
    {vertical_shift:"IGNF:NGF84", label: "NGF84:NGF-LALLEMAND"},
    {vertical_shift:"IGNF:NNLUX", label: "NNLUX:NIVELLEMENT GENERAL DU LUXEMBOURG"},
    {vertical_shift:"IGNF:PETRELS52", label: "PETRELS52:EPF 1952 (ILE DES PETRELS)"},
    {vertical_shift:"IGNF:PINS78", label: "PINS78:SHOM 1978 (ILE DES PINS)"},
    {vertical_shift:"IGNF:RAIA01", label: "RAIA01:RAIATEA_SAU 2001"},
    {vertical_shift:"IGNF:REUN89", label: "REUN89:IGN 1989 (REUNION)"},
    {vertical_shift:"IGNF:SHOM1984WF", label: "SHOM1984WF:SHOM 1984 (WALLIS ET FUTUNA)"},
    {vertical_shift:"IGNF:STPM50_V", label: "STPM50_V:DANGER 1950 (SAINT-PIERRE-ET-MIQUELON)"},
    {vertical_shift:"IGNF:TAHAA01", label: "TAHAA01:TAHAA_SAU 2001"},
    {vertical_shift:"IGNF:TAHITI66", label: "TAHITI66:IGN 1966 (TAHITI)"},
    {vertical_shift:"IGNF:UVEA84", label: "UVEA84:IGN 1984 (ILE UVEA)"},
    {vertical_shift:"IGNF:WALLIS96", label: "WALLIS96:NGWF WALLIS (MOP 1996)"},       
  ]
