<template>
    <v-list style=" background-color: #242323;">
        <v-subheader>{{ $t("IndividualSegmentSetting") }}</v-subheader>
        <v-list-item v-for="(segmentData, i) in uiProps" :key="i">
            <v-card style="width: 100%;">
                <v-card-actions style="display: flex; justify-content: space-between;">
                    <div>
                        {{ $t("SegmentRange") }} {{ segmentData.data.startIndex }} - {{ segmentData.data.endIndex }}
                    </div>
                    <div>
                        <template>
                            <v-btn icon @click="openPanel(i)">
                                <v-icon>
                                    {{
                                        segmentData.isShow
                                            ? "icon-Chevron-Up"
                                            : "icon-Chevron-Down"
                                    }}
                                </v-icon>
                            </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" class="pr-0">
                                        <v-icon> icon-Overflow-Menu-Vertical </v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="deleteSegment(i)" class="list-delayed">
                                        <v-list-item-icon><v-icon>icon-Trash-Can</v-icon></v-list-item-icon>
                                        <v-list-item-title>{{ $t("REMOVE") }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </div>
                </v-card-actions>
                <v-expand-transition>
                    <IndividualSegmentSettings v-show="segmentData.isShow" :is-editable="isEditable" :road-id="roadId" :index="i" :suffix="suffix" v-bind="segmentData.data" >
                    </IndividualSegmentSettings>
                </v-expand-transition>
            </v-card>
        </v-list-item>
    </v-list>
</template>

<script>

import IndividualSegmentSettings from './IndividualSegmentSettings.vue';
import {uploadRoadObj, updateRoadSettingsInViewer} from "../../utils/cesium-common"

export default {
    name: "IndividualSegmentSettingList",
    components: { IndividualSegmentSettings },
    props: {
        roadId: String,
        isEditable: Boolean,
        settingList: Array,
        suffix: String
    },
    watch:{
        settingList() {
            this.uiProps = this.settingList.map((s)=>({data:s, isShow: false}));
        }
    },
    mounted() {
        this.uiProps = this.settingList.map((s)=>({data:s, isShow: false}))
    },
    data: function () {
        return {
            uiProps:[]
        }
    },

    methods: {
        openPanel(i) {
            this.uiProps[i].isShow = !this.uiProps[i].isShow;
        },
        deleteSegment(i) {
            try {
                const roadUISettings = this.$store.state.objects.filter(road => road.id === this.roadId);
                roadUISettings[0].settings.commonSettings.segmentSettings.splice(i, 1);
                // const d = JSON.parse(JSON.stringify(roadUISettings[0].settings));
                if (window["viewer"].isRoadLoaded(this.roadId)) {
                    updateRoadSettingsInViewer(this.roadId,roadUISettings[0]);
                    // window["viewer"].updateRoadSettings(this.roadId, d);
                }
                
                uploadRoadObj(roadUISettings[0]);
            } catch (e) {
                console.error(e);
            } finally {
                this.$store.dispatch('get_obj_list', this.$route.query.siteId);
            }
        }
    }
};
</script>
