<template>
  <v-list dense>
    <v-list-item class="list-ChartContextMenu"
      @click="onAddOptionClick($event)">
      <v-list-item-title>
        {{ $t('Add') }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
    export default {
        props: {
            pathId: String,
            point: Array,
        },
        methods: {
            onAddOptionClick(e) {
                e.preventDefault();
                window["viewer"].addControlPointOnCenterLine(
                  this.pathId,
                  this.point
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>