<template>
  <v-list dense>
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForFlatGroundEdit" :key="index"
      @click="onCMClickForFlatGroundEdit($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { uploadFlatGroundObj } from '../../utils/cesium-common';


export default {
  props: {
    flatGroundId: String,
    pointId: String,
  },
  data: () => ({
    isroadOrFlatArea: "Road",
    itemsForFlatGroundEdit: [
      // { id: 1, title: "ADD" },
      { id: 2, title: "Remove" },
      { id: 3, title: "Switchdragmode" },
      { id: 4, title: "Update" },
      { id: 5, title: "Undo" },
      { id: 6, title: "Redo" },
      { id: 7, title: "Finishdrawing" },
    ],
  }),
  methods: {
    addControlPoint() {
      // window["viewer"].toggleFlatGroundPointVerticalDrag(
      //   false,
      //   this.flatGroundId
      // );
      // window["viewer"].toggleFlatGroundPointDrag(
      //   true,
      //   this.flatGroundId,
      //   () => { uploadFlatGroundObj(obj) }
      // );
      // window["viewer"].addMidPointsForFlatGround(
      //   this.flatGroundId
      // );
    },
    removeControlPoint() {
      window["viewer"].removePointFromFlatGround(
        this.flatGroundId,
        this.pointId
      );
    },
    switchToDragMode(obj) {
      if (this.$store.state.modes.inFlatGroundHoriDrag) {
        window["viewer"].toggleFlatGroundPointDrag(
          false,
          this.flatGroundId
        );
        window["viewer"].toggleFlatGroundPointVerticalDrag(
          true,
          this.flatGroundId,
          () => { uploadFlatGroundObj(obj); }
        );
        this.$store.state.modes.inFlatGroundHoriDrag = false;
      } else {
        window["viewer"].toggleFlatGroundPointVerticalDrag(
          false,
          this.flatGroundId
        );
        window["viewer"].toggleFlatGroundPointDrag(
          true,
          this.flatGroundId,
          () => { uploadFlatGroundObj(obj) }
        );
        this.$store.state.modes.inFlatGroundHoriDrag = true;
      }
    },
    openPropertyDialogUpdateControlPoint() {
      window.dispatchEvent(new CustomEvent("openPropertyDialog", {
        detail: { id: this.flatGroundId, type: 3, pointId: this.pointId, entityUnderEdit: "FLATGROUND", disabilityConditions: "NONE", origin: "CesiumView" },
      }));
    },
    finishDrawing() {
      // eslint-disable-next-line no-case-declarations
      const flatGround = this.$store.state.objects.filter(
        (flatGround) => flatGround.id === this.flatGroundId
      );
      if (flatGround.length > 0) {
        this.$store.state.modes.inEditMode = false;
        if (this.$store.state.modes.inFlatGroundHoriDrag) {
          window["viewer"].toggleFlatGroundPointDrag(
            false,
            this.flatGroundId
          );
          this.$store.state.modes.inFlatGroundHoriDrag = false;
        } else {
          window["viewer"].toggleFlatGroundPointVerticalDrag(
            false,
            this.flatGroundId
          );
          this.$store.state.modes.inFlatGroundHoriDrag = true;
        }
        flatGround[0].edit = false;
        // window["viewer"].toggleGlobeVisibility(true);
      }
      window["viewer"].clearCommandManager();
    },
    onCMClickForFlatGroundEdit(e, id) {
      e.preventDefault();
      const obj = this.$store.state.objects.find(
        (flatGround) => flatGround.id === this.flatGroundId
      );
      this.isroadOrFlatArea = "FlatArea";
      switch (id) {
        // case 1:
        //   this.addControlPoint(obj);
        //   break;
        case 2:
          this.removeControlPoint();
          break;
        case 3:
          this.switchToDragMode(obj);
          break;
        case 4:
          this.openPropertyDialogUpdateControlPoint();
          break;
        case 5:
          window["viewer"].undo();
          break;
        case 6:
          window["viewer"].redo();
          break;
        case 7:
          this.finishDrawing();
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
