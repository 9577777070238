<template>
  <v-list dense>
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForCreation" :key="index"
      @click="onContextMenuClickForCreation($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    pathId: String,
    pointId: String,
  },
  data: () => ({
    itemsForCreation: [
      { id: 1, title: "Undo" },
      { id: 2, title: "Redo" },
      { id: 3, title: "Finishdrawing" },
    ]
  }),
  methods: {
    onContextMenuClickForCreation(e, id) {
      e.preventDefault();
      switch (id) {
        case 1:
          window["viewer"].undo();
          break;
        case 2:
          window["viewer"].redo();
          break;
        case 3:
          window["viewer"].disableRoadCreationHandler();
          window["viewer"].clearCommandManager();
          this.$root.$emit("end-create-mode", "road");
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
