<template>
    <span>
        <span class="ml-4">{{timeStamp}}</span>
        <v-card-actions
            class="py-0"
        >
            <template>
                <!-- アップロードステータス -->
                <div v-if="entity.getAssetId() && (showUploadProgress || isUploadError)">
                  <ProgressCircle
                    class="mr-1 my-3"
                    :entity="entity"
                    :showUploadProgress="showUploadProgress"
                    :isUploadError="isUploadError"
                    @update-upload-progress="(value) => { showUploadProgress = value }"
                    @update-upload-error-status="(value) => { isUploadError = value }"
                  ></ProgressCircle>
                </div>
                <v-btn
                  v-if="draggable"
                  icon
                  small
                  class="handle"
                  style="cursor:grab"
                >
                    <v-icon>
                        mdi-drag
                    </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="toggle_visibility"
                  :disabled="isDisabledVisibilityBtn"
                  class="ml-0"
                >
                    <v-icon>
                        {{ entity.getVisibility() ? "icon-View" : "icon-View-Off" }}
                    </v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="toggle_expansion"
                  class="ml-0"
                >
                    <v-icon>
                        mdi-play  {{ entity.getExpansion() ? "mdi-rotate-90" : "" }}
                    </v-icon>
                </v-btn>
            </template>
            <span style="width: 75%">{{ entity.getName() }}</span>
            <template>
                <v-btn
                  @click="focus"
                  :disabled="isDisabledFocusBtn"
                  icon
                  right
                  small
                >
                    <v-icon small>icon-Search</v-icon>
                </v-btn>
                <v-menu 
                  offset-y 
                  transition="slide-y-transition"
                  :disabled="entity.getTilingProgress()"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="remove">
                            <v-list-item-icon class="mr-4">
                                <v-icon>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("REMOVE") }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- 処理ステータス -->
            <div 
              v-if="entity.getProcessingStatus()"
            >
              <processing-status
                :status="entity.getProcessingStatus()"
                :processingStatus="constants.pointCloudProcessingStatus"
              >
              </processing-status>
            </div>
        </v-card-actions>
        <div class="pl-7" v-show="entity.getExpansion()" >
            <v-card-actions class="py-0 px-5">
                {{ $t("OPACITY") }}
                <v-slider
                    class="ml-1"
                    v-model="transparency"
                    :disabled="isDisabledTransparency"
                    hide-details
                    max="1"
                    step="0.01"
                ></v-slider>
            </v-card-actions>
        </div>
    </span>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import * as cesiumCommon from '@/utils/cesium-common'
import ProgressCircle from './ProgressCircle';
import * as constants from '@/constant.js';
import ProcessingStatus from '@/components/common/ProcessingStatus.vue';
import axios from "axios";
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))

export default {
  name: "PointCloudListItem",
  components: {
    ProgressCircle,
    ProcessingStatus 
  },
  props: {
    entity: Object,
    draggable: Boolean,
  },
  data() {
    return {
      showUploadProgress: true,
      isUploadError: false,
      constants
    }
  },
  computed: {
    isAssetUploaded() {
      return this.entity.getIsAssetUploaded();
    },
    /**
     * 画面のクリエーションモードの状態
     */
    inCreationMode() {
      return this.$store.state.modes.inCreationMode;
    },
    /**
     * 点群の透明度
     */
    transparency: {
      get() {
        return this.entity.getTransparency();
      },
      set(value) {
        this.entity.setTransparency(value);
      }
    },
    /**
     * 可視状態切り替えボタンの活性状態を制御する
     * 以下の場合非活性
     * ・道路追加や平場追加を実行中で他の点群が活性状態じゃない
     * ・タイリング途中
     * ・アセットがアップロードされていない
     */
    isDisabledVisibilityBtn() {
      return (this.inCreationMode && !this.entity.getIsCreating())
          || this.entity.getTilingProgress()
          || this.isAssetUploaded === 'PENDING'
          || this.isAssetUploaded === 'ERROR'
    },
    /**
     * 透明度スライダーの活性状態を制御する
     */
    isDisabledTransparency() {
      // 非表示状態の場合は非活性
      if (!this.entity.getVisibility()) return true
      else return false
    },
    /**
     * フォーカスボタンの活性状態を制御する
     */
    isDisabledFocusBtn() {
      // 非表示状態の場合は非活性
      if (!this.entity.getVisibility()) return true
      else return false
    },
    timeStamp() {
      return this.entity.getTimeStamp()
    }
  },
  watch: {
    // アップロードステータスがPENDINGになれば再度ポーリング開始
    isAssetUploaded(newValue) {
      if (newValue === 'PENDING') {
        this.showUploadProgress = true;
        this.isUploadError = false;
      }
    },
    transparency() {
      let transparency_val = parseFloat(this.transparency.toFixed(2));
      try {
        window["viewer"].updateTilesetTransparency(parseInt(this.entity.getAssetId()), "pointcloud", transparency_val);
      } catch(e) {
        console.error(e)
      }
    },
    inCreationMode(newCreationMode) {
      if (newCreationMode) {
      // 画面がクリエーションモードに変更になった場合
        if (this.entity.getVisibility()) {
        // 可視状態であれば点群をクリエーションモードに変更
          this.entity.setIsCreating(true)
        }
      } else {
      // 画面のクリエーションモードが解除された場合
        if (this.entity.getIsCreating()) {
        // 点群がクリエーションモードであれば解除
          this.entity.setIsCreating(false)
        }
      }
    }
  },
  methods: {
    ...mapMutations(['set_snackbar']),
    ...mapActions(['delete_point_cloud']),
    toggle_visibility() {
      cesiumCommon.togglePointCloudVisibility(this.entity, this.$store.getters.getRoads);
    },
    toggle_expansion() {
      this.entity.setExpansion(!this.entity.getExpansion());
    },
    async remove() {
      //排他制御チェック
      const isEditByOther = await this.exclusive_check();
      if (isEditByOther) {
        this.$store.commit('set_exclusion', true)
        return false;
      } else {
        await this.delete_point_cloud(this.entity);
        this.set_snackbar({
          text: `${this.entity.getName()} ${this.$t('REMOVE')} ${this.$t('successful')}`,
          color: 'rgba(0, 153, 0, 0.72)'
        })
        await this.update_site_info();
        const updateInfo = await this.get_site_info();
        // stateをかきかえる
        this.$store.commit('set_initial_site_datas', updateInfo)
      }
    },
    focus() {
      window["viewer"].zoomToTileset(this.entity.getAssetId(), "pointcloud");
    },
    async get_site_info() {
      let ret = null;
      await axios.get(`${process.env.VUE_APP_API_BASE}/sites/${this.$route.query.siteId}`, {
        auth: this.$store.state.authObject,
        withCredentials: false      
      }).then((res) => {
        ret =  res.data;
      })
      return ret;
    },
    async exclusive_check() {
      let edit_site_data = await this.get_site_info();
      const result = dayjs(this.$store.state.initial_site_data.updated_at).isBefore(edit_site_data.updated_at);
      return result;
    },
    // サイト情報更新
    async update_site_info() {
      let res = await axios.put(`${process.env.VUE_APP_API_BASE}/sites/touch/${this.$route.query.siteId}`, {
        auth: this.$store.state.authObject,
        withCredentials: false      
      }).then((response) => {
        console.log(response.data);
      })
      console.log('udpateSiteInfo',res);
    }
  },
  beforeDestroy() {
    // 仮設道路追加や平場追加を行っている場合キャンセル
    if (this.inCreationMode) {
      this.$root.$emit("cancel-create-mode");
    }
  }
}
</script>