
/**
 * 汎用的に使用可能なフォームバリデーションルールを定義する
 */
const rules = {
  /**
   * 入力値のフォーマットが不正でなければチェックOK
   */
  numberFormat: v=> {
    if(v) return /^(?!-?0[0-9])-?[0-9]+([.]\d+)?$/.test(v) 
    return "Please enter number"
  },
  /**
   * 入力値の小数点以下の桁数が引数以下であればチェックOK
   */
  decimalLength: max => {
    if(typeof max === 'number'){
    const pattern = new RegExp(`^(?!.*[.]\\d{${max+1},}$).+$`);
    return v => pattern.test(v);}
    return "Decimal Length does not match"
  },
  /**
   * 入力値が引数の数値以上であればチェックOK
   */
  orMore: baseNumber => {
    if(typeof baseNumber === 'number'){
    return v => { 
      if (v === "") return false;
      else return Number(v) >= Number(baseNumber) 
    };}
    return "number should be greater"
  },
  /**
   * 入力値が引数の数値以下であればチェックOK
   */
  orLess: (baseNumber) => {
    if(typeof baseNumber === 'number'){
    return v => { 
      if (v === "") return false;
      else return Number(v) <= Number(baseNumber) 
    };
  }
  return "number should be smaller"
}
};

export default rules;