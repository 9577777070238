<template>
  <v-card>
    <v-card-title>{{$t("RoadadditionbyAI")}}</v-card-title>
    <v-btn
      class="closeDialogIcon"
      fab
      absolute
      top
      right
      @click="closeProcess"
    >
      <v-icon> mdi-close </v-icon>
    </v-btn>
    <v-container style="width: 60%" class="ma-4">
      <p>{{$t(("MESSAGE_CONFIRM_PREPROCESS"))}}<br />{{$t(("MESSAGE_CONFIRM_SUFFIX"))}}</p>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
          color="orange"
          class="uploadBtn can001a"
          id="upload_btn"
          rounded
          @click="closeProcess"
          >
            {{$t(("CANCEL"))}}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn
          color="orange"
          class="uploadBtn ok001a"
          id="upload_btn"
          rounded
          @click="postPreprocess()"
          >
            {{$t(("OK"))}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import axios from 'axios';

export default {
  name: "preprocessConfirm",
  props: {},
  async mounted() {
    console.log("PreprocessConfirm");
    console.log(this.$store);
  },
  data: () => ({
    file: null,
    isbtnDisabled: true,
  }),
  computed: {
    terrainItems: {
      get() {
        return this.$store.getters['addRoadTerrainByAi/terrainItems'];
      },
      set(pointValue) {
        this.$store.dispatch('addRoadTerrainByAi/update_terrain_items', pointValue);
      }
    },
  },
  watch: {
    // whenever value changes for this variables, this function will run
    file() {
      this.isbtnDisabled = this.buttonVisibilityCheck();
    },
  },
  methods: {
    closeProcess() {
      this.$emit("close", undefined);
    },
    setTerrainInfo(data) {
      // undefinedかnullになる場合は空文字を返す
      const convertToEmpty = (data, key) => {
        if (key in data) {
          if (data[key] === undefined || data[key] === null) {
            return '';
          } else {
            return data[key];
          }
        } else {
          return '';
        }
      }
      this.terrainItems[0].value = convertToEmpty(data, 'terrain_x_value_min');
      this.terrainItems[1].value = convertToEmpty(data, 'terrain_x_value_max');
      this.terrainItems[2].value = convertToEmpty(data, 'design_x_value_min');
      this.terrainItems[3].value = convertToEmpty(data, 'design_x_value_max');

      this.terrainItems[4].value = convertToEmpty(data, 'terrain_y_value_min');
      this.terrainItems[5].value = convertToEmpty(data, 'terrain_y_value_max');
      this.terrainItems[6].value = convertToEmpty(data, 'design_y_value_min');
      this.terrainItems[7].value = convertToEmpty(data, 'design_y_value_max');

      this.terrainItems[8].value = convertToEmpty(data, 'terrain_height_min');
      this.terrainItems[9].value = convertToEmpty(data, 'terrain_height_max');
      this.terrainItems[10].value = convertToEmpty(data, 'design_height_min');
      this.terrainItems[11].value = convertToEmpty(data, 'design_height_max');
    },
    /**
     * 計算ステータス確認APIのポーリングを開始する
     * @param {*} taskId 
     */
    async startPolling (taskId) { 
      let isComplete = await this.checkCalculationStatus(taskId);
      const timeId = setInterval(async()=>{
        if (isComplete) {
          clearInterval(timeId);
          return;
        }
        isComplete = await this.checkCalculationStatus(taskId);
      }, 3000)
    },
    /**
     * 計算ステータス確認APIを実行する
     * 計算が完了していない場合、3秒毎に再帰的に実行する
     * @param {*} taskId 
     * @returns isComplete 計算完了フラグ
     */
    async checkCalculationStatus(taskId) {
      let isComplete = false;
      try {
        const userId = this.$store.state.user?.id;
        const res = await axios.get(
          `${process.env.VUE_APP_API_BASE}/designRoadApp/taskResult/?user_id=${userId}&task_id=${taskId}&timestamp=${new Date().getTime()}'`, 
          { 
            headers: { 'Content-Type': 'application/json', },
          }
        )
        const body = JSON.parse(res.data);
        // console.log(body);
        // console.log(body.ready);
        // 正常応答の場合
        if (res.status == 200) {
          // 非同期処理が終了しているか確認。未終了の場合再実行
          // if(res.data.ready === true) {
          if(body.ready === 'True') {
            isComplete = true;
            // スピナーを非表示にする
            this.$store.dispatch('updateIsLoading', false);
            // if(res.data.state === 'SUCCESS') {
            if(body.state === 'FAILURE'
             || (body.state === 'SUCCESS' && !body.data)) {
              // Error process
              console.log(body.data);
              this.$store.commit("set_snackbar", {
                text: `${this.$t("MESSAGE_ERROR_PREPROCESS")}`,
                color: 'rgba(255, 215, 0, 0.72)'
              });
              // this.$store.commit("set_snackbar", {
              //   text: `${this.$t("MESSAGE_ERROR_PREPROCESS_LOCKED")}`,
              //   color: 'rgba(255, 215, 0, 0.72)'
              // });
            } else {
              // console.log(res.data.data);
              console.log(body.data);
              // 地形情報を設定
              this.setTerrainInfo(body.data);
              // this.setTerrainInfo(res.data.data)
            }
          }
        }
        return isComplete;
      } catch(error) {
        // 異常応答の場合エラー出力
        console.error(error.message);
        // スピナーを非表示にする
        this.$store.dispatch('updateIsLoading', false);
        return isComplete;
      }
    },
    async postPreprocess() {
      // スピナーを表示する
      this.$store.dispatch('updateIsLoading', true);
      const terrainItems = this.$store.getters['addRoadTerrainByAi/terrainItems'];
      console.log(terrainItems);
      const userId = this.$store.state.user?.id;
      // const userId = 1;
      // console.log(`uuid:${this.$store.state.user.id}`);
      const pointId = this.$store.getters['addRoadTerrainByAi/pointCloudItem'].point_cloud_id;
      const designId = this.$store.getters['addRoadTerrainByAi/designFilename'].design_id;
      const data = {
        pointId,
        designId,
        userId,
        terrainItems: terrainItems,
      };
      // 地形情報計算APIを実行
      try {
        const res = await axios.post(`${process.env.VUE_APP_API_BASE}/designRoadApp/`, data)
        if (res.status == 200) {
          // レスポンスのデータが文字列で返ってくるためtaskIdを取り出す
          // const taskId = res.data.split(',').find(item => item.search(/taskId:/) !== -1)
          const body = JSON.parse(res.data); 
          // console.log(res.data);
          // console.log(res.data.taskId);
          console.log(body);
          console.log(body.ready);
          console.log(body.state);

          // const taskId = res.data.taskId;
          const taskId = body.taskId;
          console.log(taskId);
          if (taskId) {
            // console.log(`taskId:${taskId}`);
            // レスポンス内にtaskIdが存在する場合はポーリング開始
            this.startPolling(taskId);
            // this.startPolling(taskId.replace('taskId:', ''))
          }
        }
      } 
      catch(error) {
        console.error(error.message);
        // スピナーを非表示にする
        this.$store.dispatch('updateIsLoading', false);
      } 
      this.closeProcess(false);
    },
  },
};
</script>
<style scoped>
.closeDialogIcon {
  margin-top: 5%;
}
.uploadBtn {
  margin-bottom: 3%;
  margin-left: 80%
}
.ok001a {
 margin-bottom: 3%;
 margin-left: 80%;
 font-size: 14px!important;
 font-weight: 600!important;
 color: #fff! important;
 height: 36px !important;
 padding: 10px 50px!important;
 background-color: #0064ba !important;
 border-color: #0064ba !important;
 border-radius: 5px!important;
}
.can001a {
 margin-bottom: 3%;
 margin-left: 80%;
 font-size: 14px!important;
 font-weight: 600!important;
 color: #fff;
 height: 36px! important;
 padding: 10px 25px !important;
 background-color: #f44336 !important;
 border-color: #f44336 !important;
 border-radius: 5px !important;
}
</style>
