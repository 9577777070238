export const UnitTypes =  [
      {
        label: "Percent",
        value: "percent",
        symbol: "%"

      },
      {
        label: "RATIO_WORD",
        value: "ratio",
        symbol: "1/n"
      },
      {
        label: "Degree",
        value: "degree",
        symbol: "°",
      },
    ];

export const pointCloudProcessingStatus = Object.freeze({
  ERROR: ["SERVER_ERROR", "SST_ERROR"],
  PROCESSING: ["SERVER", "SST"],
  FINISHED: ["SST_FINISHED"]
})

export const designProcessingStatus = Object.freeze({
  ERROR: ["error"],
  PROCESSING: ["upload"],
  FINISHED: ["complete"]
})