<template>
    <span>
        <v-card-subtitle class="py-0 pt-3">
            {{ $t("DESIGN") }}
        </v-card-subtitle>
        <div style="max-height:110px; overflow:auto; scroll-behavior: smooth;" class="mx-3 my-3" id="design_list">
            <v-card-text v-if="design_list.length == 0" class="pa-0 pl-1">
                {{ $t("NO_DATA") }}
            </v-card-text>
            <draggable handle=".handle" animation="553" v-model="design_list_draggable">
                <v-card-text
                    v-for="(entity) in design_list"
                    :key="entity.getId()"
                    class="pa-0"
                >
                    <Design-list-item 
                        :entity="entity" 
                        :draggable="true" 
                    ></Design-list-item>
                </v-card-text>
            </draggable>
        </div>
    </span>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import draggable from "vuedraggable";
    import DesignListItem from "./DesignListItem.vue";
    export default {
        components: {
            draggable,
            DesignListItem
        },
        name: "DesignList",
        computed:{
            ...mapState(['design_list']),
            design_list_draggable: {
                get() {
                    return this.design_list;
                },
                set(value) {
                    this.set_design_list(value);
                }
            }
        },
        methods: {
            ...mapMutations(['set_design_list'])
        }
    };
</script>