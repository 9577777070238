<template>
    <div>
        {{ minVal }}
        <span style="font-weight: bold;">{{ suffix }}</span> - {{ maxVal }}
        <span style="font-weight: bold;">{{ suffix }}</span>
    </div>
</template>

<script>

export default {
    name: "SlopeRangeComponent",
    props: {
        min: Number,
        max: Number,
        suffix: String
    },
    watch:{
        suffix(newVal, oldVal) {
            if (newVal != oldVal) {
                this.updateValueAsPerSuffix();
            }
        },        
    },
    data() {
        return {
            minVal: 0,
            maxVal: 0
        }
    },
    mounted(){
        //conver ratio to suffix
        this.updateValueAsPerSuffix();
    },
    methods:{
        updateValueAsPerSuffix(){
            switch (this.suffix) {
                    case ("1/n"):
                        //given ratio return 1/ratio
                        this.minVal = (1 / (this.min)).toFixed(3);
                        this.maxVal = (1 / (this.max)).toFixed(3);
                        break;
                    case ("%"):
                        //given ratio return percent
                        this.minVal =  (100 * this.min).toFixed(3);
                        this.maxVal = (100 * this.max).toFixed(3);
                        break;
                    case ("°"):
                        //given ratio return degree
                        this.minVal = (Math.atan(this.min) * 180 / Math.PI).toFixed(3);
                        this.maxVal = (Math.atan(this.max) * 180 / Math.PI).toFixed(3);
                        break;
                }
        }
    }
}  
</script>
