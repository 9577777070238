<template>
  <v-card>
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <v-list dense>
        <v-list-item class="list-ChartContextMenu" v-for="(item, index) in items" :key="index"
          @click="onContextMenuClick($event, item.id)">
          <v-list-item-title>
            {{ $t(item.title) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-model="showLongitudinalMenu" :position-x="x" :position-y="y" absolute offset-y z-index="100">
      <v-list dense>
        <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsAdd" :key="index"
          @click="onAddContextMenuClick($event, item.id)">
          <v-list-item-title>
            {{ $t(item.title) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-card-text id="profileView" tile style="width: 100%; height: 240px" class="pa-0"
      @contextmenu="show" ref="profile_view"></v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProfileViewDialog",
  props: {
    id: String,
  },
  computed: {
    ...mapState(['panelName'])
  },
  watch: {
    panelName() {
      this.showMenu = false;
      this.showLongitudinalMenu = false;
    }
  },
  data: () => ({
    showMenu: false,
    showLongitudinalMenu: false,
    x: 0,
    y: 0,
    offsetX: 0,
    offsetY: 0,
    pathInfo: { pathId: undefined, pointId: undefined },
    pointInfo: { index: undefined, height: undefined, length: undefined },
    items: [
      // { id: 1, title: "Straightline" },
      // { id: 2, title: "Curvedline" },
      { id: 3, title: "Remove" },
      // { id: 4, title: "Extendroad" },
      { id: 5, title: "Update" },
      { id: 6, title: "Undo" },
      { id: 7, title: "Redo" },
      { id: 8, title: "Finishdrawing" },
      // { id: 9, title: "create arc" },
    ],
    itemsAdd: [
      { id: 1, title: "ADD" }
    ]
  }),
  mounted() {
    this.$nextTick(function () {
      this.update();
      this.onScreenResize();
    });

  },
  methods: {
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.update();
      });
    },
    show(e) {
      this.$store.dispatch("changePanel", "ProfileView")
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX + 10;
      this.y = e.clientY;
      this.offsetX = e.offsetX;
      this.offsetY = e.offsetY;
      this.$nextTick(() => {
        const editableRoads = this.$store.state.objects.filter(
          (road) => road.edit === true
        );
        if (editableRoads.length > 0) {
          const pickResult = window["viewer"].pickPathandPoint(
            e.pageX,
            e.pageY,
            "altitudeView"
          );

          if (pickResult !== undefined) {
            this.pathInfo.pathId = pickResult.pathId;
            const slopeBreaks = window["viewer"].getSlopeBreaks(pickResult.pathId);
            const slopeBreakData = [...slopeBreaks];
            if (pickResult.pointIndex === "roadPath") {
              const offset = window["viewer"].getOffsetForAssociatedPointCloudWithRoad(pickResult.pathId);
              const slopeBreak = { percentage: pickResult.data.length, height: pickResult.data.height + offset }
              slopeBreakData.splice(pickResult.data.index, 0, slopeBreak);
              slopeBreakData.sort((a, b) => a.percentage - b.percentage);
              this.data = slopeBreakData;
              this.showLongitudinalMenu = true;
            } else {
              const slopeBreak = slopeBreakData[pickResult.pointIndex];
              this.pathInfo.pointId = pickResult.pointIndex;
              if (slopeBreak)
                this.showMenu = true;
            }
          }
        }
      });
    },
    onContextMenuClick(e, id) {
      e.preventDefault();
      switch (id) {
        case 1:
          window["viewer"].updateBendToStraight(
            this.pathInfo.pathId,
            this.pathInfo.pointId
          );
          break;
        case 2:
          window["viewer"].updateBendToBezier(
            this.pathInfo.pathId,
            this.pathInfo.pointId
          );
          break;
        case 3:
          window["viewer"].removeSelectedSlopeBreak(
            this.pathInfo.pathId,
          );
          break;
        case 4:
          window["viewer"].roadExtension(this.pathInfo.pathId);
          break;
        case 5:
          window.dispatchEvent(new CustomEvent("openPropertyDialog", {
            detail: { id: this.pathInfo.pathId, type: 3, pointId: this.pathInfo.pointId, entityUnderEdit: "ROAD", disabilityConditions: "XY", origin: "AltitudeView" },
          }));
          break;
        case 6:
          window["viewer"].undo();
          break;
        case 7:
          window["viewer"].redo();
          break;
        case 8:
          // eslint-disable-next-line no-case-declarations
          const roads = this.$store.state.objects.filter(
            (road) => road.id === this.pathInfo.pathId
          );
          if (roads.length > 0) {
            this.$store.state.modes.inEditMode = false;
            window["viewer"].enableRoadEdit(false, roads[0].id, () => { });
            roads[0].edit = false;
          }
          window["viewer"].clearCommandManager();
          break;
        case 9:
          window["viewer"].updateBendToCircle(
            this.pathInfo.pathId,
            this.pathInfo.pointId
          );
          break;
      }
    },
    onAddContextMenuClick(e, id) {
      e.preventDefault();
      switch (id) {
        case 1:
          window["viewer"].updateSlopeBreaksFromLongitudinalView(
            this.pathInfo.pathId,
            this.data
          );
          break;
      }
    },
    update() {
      const canvas =
        window["viewer"] && window["viewer"].getCanvasForView("altitudeView");
      const element = this.$refs.profile_view;
      if (canvas && element) {
        for (const node of element.children) {
          element.removeChild(node);
        }
        element.appendChild(canvas);
        canvas.height = element.getBoundingClientRect().height;
        canvas.width = element.getBoundingClientRect().width;
        window["viewer"].resize2dView(
          { width: canvas.width, height: canvas.height },
          "altitudeView"
        );
      }
    }
  }
};
</script>
