<template>
    <v-text-field
        hide-details
        dense
        type="number"
        :min="minValue"
        :step="step"
        :suffix="suffixToDisplay"
        :rules="[
          rules.required,
          rules.orLess(maxValue),
          rules.orMore(minValue),
          rules.decimalLength(decimalLength),
          rules.numberFormat
          ]"
        :disabled="!isEditable"
        @input="onChange"
        @blur="$emit('blur')"
        v-model.number="value"
    />
</template>

<script>
import rules from '@/utils/validation-rules.js';

const debounce = (func, wait = 500) => {
  let timerId;
  return function(...args){
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};


export default {
    name: "SlopeSettingInput",
    props: {
        isEditable: Boolean,
        slopeValue: Number,
        suffixToDisplay: String,
        maxValue: Number,
        minValue: Number,
        decimalLength: Number,
        unValidZero: Boolean,
        step: Number
    },
    watch:{
        suffixToDisplay(newVal, oldVal) {
            if (newVal != oldVal) {
                this.updateValueAsPerSuffix();
            }
        },        
    },
    data() {
        return {
            originalValue: this.slopeValue, //this.is always ratio 1/n
            value: this.slopeValue,
            rules: {
                ...rules,
                required: v => (v !== null && v !== '' && v !== undefined) || '*',
                isZero: v => this.unValidZero === true ? true : (v !== 0 || '*'),
            },
        }
    },
    mounted(){
        //conver ratio to suffix
        this.updateValueAsPerSuffix();
    },
    methods:{
        onChange: debounce(function () {
            console.log(this.value);
            switch (this.suffixToDisplay) {
                case ("1/n"):
                    //given ratio return ratio
                    this.originalValue = this.value;
                    break;
                case ("%"):
                    //given percent return ratio
                    this.originalValue = 100 / this.value;
                    break;
                case ("°"):
                    //given degree return ratio
                    this.originalValue = 1 / Math.tan(this.value * (Math.PI / 180));
                    break;

            }
            console.log("input", this.originalValue);
            this.$emit("input", this.originalValue);
            // this.onDebouncedChange && this.onDebouncedChange();
        }),
        updateValueAsPerSuffix(){
            switch (this.suffixToDisplay) {
                    case ("1/n"):
                        //given ratio return ratio
                        this.value = (this.originalValue).toFixed(this.decimalLength);
                        break;
                    case ("%"):
                        //given ratio return percent
                        this.value =  (100/ this.originalValue).toFixed(this.decimalLength);
                        break;
                    case ("°"):
                        //given ratio return degree
                        this.value = (Math.atan(1 / this.originalValue) * 180 / Math.PI).toFixed(this.decimalLength);
                        break;
                }
        }
    }
}  
</script>

//input slope in ratio and display in degree
//input slope in degree and display in ratio
//input slope in percent and display in ratio