<template>
  <v-list dense>
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForPointSelection" :key="index"
      @click="point_selection_click($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<style>
.btn1 {
  width: 16px !important;
  min-width: 0px !important;
  background-color: rgba(0, 0, 0);
}
</style>

<script>
export default {
  data: () => ({
    itemsForPointSelection: [
      // { id: 1, title: "Undo" },
      // { id: 2, title: "Redo" },
      { id: 3, title: "Finishdrawing" },
    ],
    coordinates: []
  }),
  methods: {
    point_selection_click(e, id) {
      e.preventDefault()
      switch (id) {
        case 1:
          window["viewer"].undo()
          break;
        case 2:
          window["viewer"].redo()
          break;
        case 3:
          this.set_modes({
            name: 'ai_road_point_selection',
            value: false
          })
          window["viewer"].removeAIRoadPreview();
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
