import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import VueI18n from 'vue-i18n'
import router from './route/router'
import VueCookies from "vue-cookies"
import "./assets/style_custom.css";
import axios from 'axios'

// response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    const status = error.response.status
    if (status === 401 || status === 502 || status === 504) {
      window.location.replace("/login");
    }
    return Promise.reject(error)
  }
)

Vue.config.productionTip = false

const dict = require('./SCDesignDict.json')

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'ja',
  messages: dict
})

Vue.use(VueCookies)

new Vue({
  router,
  vuetify,
  store,
  i18n: i18n,
  render: h => h(App),
  methods: {
    handleClick_changeLanguage(lang) {
      this.$i18n.locale = lang
    }
  }
}).$mount('#app')
