<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title>{{$t("RoadadditionTerrainByAI")}}</v-card-title>
    <v-btn
      class="closeDialogIcon"
      fab
      absolute
      top
      right
      @click="closeProcess"
    >
      <v-icon> mdi-close </v-icon>
    </v-btn>
    <v-container style="width: 100%;" class="ma-1">
      <v-row>
        <v-col cols="4" sm="4">
          <!-- 設計データ選択 -->
          <v-row>
            <p>&nbsp;&nbsp;&nbsp;{{$t(("LABEL_DESIGN_FILE"))}}</p>
          </v-row>
          <v-row>
            <v-select
              style="padding-left: 10px; max-width: 305px !important;"
              v-model="designFilename"
              :items="design_items"
              item-text="design_name"
              :item-value="item => item"
              :disabled="disabledDesignFilename"
              outlined
            ></v-select>
          </v-row>
          <!-- 地形データ選択 -->
          <v-row>
            <p>&nbsp;&nbsp;&nbsp;{{$t(("LABEL_TERRAIN_FILE"))}}</p>
          </v-row>
          <v-row>
            <v-select
              style="padding-left: 10px; max-width: 305px !important;"
              v-model="pointCloudItem"
              :items="point_cloud_items"
              item-text="point_cloud_name"
              :item-value="item => item"
              :disabled="disabledPointCloudFilename"
              outlined
            ></v-select>
          </v-row>
        </v-col>
        <!-- 地形情報 -->
        <v-col cols="4" sm="4">
          <p>{{$t(("TERRAIN_INFO"))}}</p>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr
                    id="tr-terrain-item"
                    v-for="item in terrainList"
                    :key="item.name"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
        <!-- 座標 -->
        <v-col cols="4" sm="4">
          <v-row>
            {{$t(("LABEL_COORDINATE_TEXT"))}}
          </v-row>
          <!-- 地形指定 -->
          <v-row>
            <v-btn
              color="primary"
              class="cancelBtn"
              id="cancel_btn"
              rounded
              x-large
              :disabled="enableStartEndPointButton"
              @click="enableAIRoadPreview"
              >
              {{$t(("SET_TERRAIN_DATA"))}}
            </v-btn>
          </v-row>
          <!-- 始点 -->
          <v-row style="margin: 20px 0 -10px 0 !important;">
            {{$t(("LABEL_START_POINT"))}}
          </v-row>
          <v-row>  
            <v-col cols="12" sm="2" style="height: 28px;">
              <p class="font-weight-black">{{$t(("LABEL_X_COORDINATE"))}}</p>
            </v-col>
            <v-col cols="12" sm="10" style="height: 28px; margin-top: -25px;">
              <v-text-field
              v-model="startpointXval"
              maxlength="12" 
              placeholder=""
              regular
              readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" style="height: 28px;">
              <p class="font-weight-black">{{$t(("LABEL_Y_COORDINATE"))}}</p>
            </v-col>
            <v-col cols="12" sm="10" style="height: 28px; margin-top: -25px;">
              <v-text-field
              v-model="startpointYval"
              maxlength="12"
              placeholder=""
              regular
              readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row style="margin-bottom: 15px;">
            <v-col cols="12" sm="2" style="height: 28px;">
              <p class="font-weight-black">{{$t(("LABEL_Z_COORDINATE"))}}</p>
            </v-col>
            <v-col cols="12" sm="10" style="height: 28px; margin-top: -25px;">
              <v-text-field
              v-model="startpointZval"
              maxlength="12"
              placeholder=""
              regular
              readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- 終点 -->
          <v-row style="margin:20px 0 -10px 0 !important;">
            {{$t(("LABEL_END_POINT"))}}
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" style="height: 28px;">
              <p>{{$t(("LABEL_X_COORDINATE"))}}</p>
            </v-col>
            <v-col cols="12" sm="10" style="height: 28px; margin-top: -25px;">
              <v-text-field
              v-model="endpointXval"
              maxlength="12"
              placeholder=""
              regular
              readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" style="height: 28px;">
              <p>{{$t(("LABEL_Y_COORDINATE"))}}</p>
            </v-col>
            <v-col cols="12" sm="10" style="height: 28px; margin-top: -25px;">
              <v-text-field
              v-model="endpointYval"
              maxlength="12"
              placeholder=""
              regular
              readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" style="height: 28px;">
              <p>{{$t(("LABEL_Z_COORDINATE"))}}</p>
            </v-col>
            <v-col cols="12" sm="10" style="height: 28px; margin-top: -25px;">
              <v-text-field
              v-model="endpointZval"
              maxlength="12"
              placeholder=""
              regular
              readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
        <!-- キャンセル -->
        <v-col>
          <v-btn
            color="orange"
            class="cancelBtn"
            id="cancel_btn"
            rounded
            x-large
            @click="closeProcess"
          >
          {{$t(("CANCEL"))}}
          </v-btn>
        </v-col>
        <!-- 前処理 -->
        <v-col>
          <v-btn
            class="preProcessBtn"
            id="pre_process_btn"
            rounded
            x-large
            :disabled="isPreprocessConfirmBtnDesiable"
            @click="preprocessConfirm()"
          >
            {{$t(("PRE_PROCESS"))}}
          </v-btn>
        </v-col>
        <!-- 決定 -->
        <v-col>
          <v-btn
            class="OkBtn"
            id="ok_btn"
            :disabled="isOkbtnDisable"
            rounded
            x-large
            @click="addRoadByJson()"
          >
            {{$t(("OK"))}}
          </v-btn>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showPreprocessConfirmDialog" width="50%" persistent>
      <PreprocessConfirm @close="cancelPreprocessConfirmDialog" />
    </v-dialog>
  </v-card>
</template>
<script>
import PreprocessConfirm from "./PreprocessConfirm";
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: "addRoadByJson",
  components: {
    PreprocessConfirm,
  },
  props: {},
  data: () => ({
    // isbtnDisabled: true,
    isPreprocessConfirm: false,
    showPreprocessConfirmDialog: false,
    isAddRoadByJson: false,
    disabledDesignFilename: false,
    disabledPointCloudFilename: false,
    showAddRoadByJson: false,
    design_items: [],
    point_cloud_items: [],
  }),
  computed:{
    ...mapState(['ai_road_preview_data']),
    /**
     * 前処理ボタンの活性状態を制御する
     */
    isPreprocessConfirmBtnDesiable() {
      if (this.startpointXval === '' || this.startpointYval === '' || this.startpointZval === ''
        || this.endpointXval === '' || this.endpointYval === '' || this.endpointZval === '') {
        return true;
      }
      // 地形情報のvalueに空文字がない場合、設定済みとする
      const existTerrainInfo = this.terrainItems.every(terrainItem => terrainItem.value !== '');
      // 地形情報が未設定かつ、取得可能な場合活性状態
      if (!existTerrainInfo && this.enableGetTerrainInfo) return false;
      else return true;
    },
    /**
     * 地形情報取得条件を満たしているかチェックする
     */
    enableGetTerrainInfo() {
      if (this.pointCloudItem !== '' && this.designFilename !== '') return true;
      else return false;
    },
    enableStartEndPointButton() {
      if (this.pointCloudItem === '' || this.designFilename === '') return true;
      else return false;
    },
    isOkbtnDisable() {
      // 地形情報のvalueに空文字がない場合、設定済みとする
      const existTerrainInfo = this.terrainItems.every(terrainItem => terrainItem.value !== '');
      if ( ( !this.startpointXval
          || !this.startpointYval
          || !this.startpointZval
          || !this.endpointXval
          || !this.endpointYval 
          || !this.endpointZval)
          || this.pointCloudItem === ""
          || this.designFilename === "" 
          || !existTerrainInfo ) {
        return true;
      }
      else {
        return false;
      }
    },
    terrainList() {
      this.setTerrainItems();
      return this.terrainItems;
    },
    designFilename: {
      get() {
        return this.$store.getters['addRoadTerrainByAi/designFilename'];
      },
      set(designFilename) {
        this.$store.dispatch('addRoadTerrainByAi/update_design_file_name', designFilename);
      }
    },
    pointCloudItem: {
      get() {
        return this.$store.getters['addRoadTerrainByAi/pointCloudItem'];
      },
      set(pointCloudItem) {
        this.$store.dispatch('addRoadTerrainByAi/update_point_cloud_item', pointCloudItem);
      }
    },
    startpointXval : {
      get() {
        return this.ai_road_preview_data.x1
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'x1',
          value: newValue
        })
      }
    },
    startpointYval : {
      get() {
        return this.ai_road_preview_data.y1
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'y1',
          value: newValue
        })
      }
    },
    startpointZval : {
      get() {
        return this.ai_road_preview_data.z1
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'z1',
          value: newValue
        })
      }
    },
    endpointXval : {
      get() {
        return this.ai_road_preview_data.x2
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'x2',
          value: newValue
        })
      }
    },
    endpointYval : {
      get() {
        return this.ai_road_preview_data.y2
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'y2',
          value: newValue
        })
      }
    },
    endpointZval : {
      get() {
        return this.ai_road_preview_data.z2
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'z2',
          value: newValue
        })
      }
    },
    terrainItems: {
      get() {
        return this.$store.getters['addRoadTerrainByAi/terrainItems'];
      },
      set(pointValue) {
        this.$store.dispatch('addRoadTerrainByAi/update_terrain_items', pointValue);
      }
    },
  },
  watch: {
    // whenever value changes for this variables, this function will run
    designFilename: function() { 
      if (this.enableGetTerrainInfo) {
        // Mock data
        // this.getTerrainInfoMock();
        
        this.getTerrainInfo();
      }
    },
    pointCloudItem: function() { 
      if (this.enableGetTerrainInfo) {
        // Mock data
        // this.getTerrainInfoMock();
        
        this.getTerrainInfo();
      }
    },
  },
  async mounted() {
    console.log(`isAiRoadPreviewMode:${this.$store.getters['addRoadTerrainByAi/isAIRoadPreviewMode']}`);
    console.log(`siteId:${this.$route.query.siteId}`);
    const auth = this.$store.state.authObject;
    await fetch(
              `${process.env.VUE_APP_API_BASE}/design/${this.$route.query.siteId}`,
              { 
                method: 'GET',
                auth: auth,
              }
            )
      .then(response => response.json())
      .then(data => 
      {
        console.log(data);
        this.design_items = data;
      })
      .catch(error => console.log(error))

    await fetch(
        `${process.env.VUE_APP_API_BASE}/pointcloud/${this.$route.query.siteId}`,
        { 
          method: 'GET',
          auth: auth,
        }
      )
      .then(response => response.json())
      .then(data =>
        { 
          console.log(data);
          this.point_cloud_items = data;
        }
      )
      .catch(error => console.log(error))
  },
  methods: {
    ...mapActions(['get_point_cloud_list', 'get_design_list']),
    ...mapMutations(['set_modes','set_ai_road_preview_data_property','reset_ai_road_preview_data']),
    async fetchDesignItems() {
      // console.log("fetchDesignItems");
      const auth = this.$store.state.authObject;
      await fetch(
                `${process.env.VUE_APP_API_BASE}/design/${this.$route.query.siteId}`,
                { 
                  method: 'GET',
                  auth: auth,
                }
              )
        .then(response => response.json())
        .then(data => 
        {
          console.log(data);
          this.design_items = data;
        })
        .catch(error => console.log(error))
    },
    async fetchPointCloudItems() {
      // console.log("fetchPointCloudItems");
      const auth = this.$store.state.authObject;
      await fetch(
        `${process.env.VUE_APP_API_BASE}/pointcloud/${this.$route.query.siteId}`,
        { 
          method: 'GET',
          auth: auth,
        }
      )
      .then(response => response.json())
      .then(data =>
        { 
          console.log(data);
          this.point_cloud_items = data;
        }
      )
      .catch(error => console.log(error))
    },
    closeProcess() {
      console.log("closeProcess");
      // this.$refs.property_dialog_road_ai.resetProperty();
      this.resetProperty();
      this.$emit("close", undefined);
    },
    setDisabledDesignFilename() {
      if (this.ai_road_preview_data.x1 !== '' || this.ai_road_preview_data.y1 !== '' || this.ai_road_preview_data.z1 !== ''
        || this.ai_road_preview_data.x2 !== '' || this.ai_road_preview_data.y2 !== '' || this.ai_road_preview_data.z2 !== '') {
        this.disabledDesignFilename = true;
      } else {
        this.disabledDesignFilename = false;
      }
    },
    setDisabledPointCloudFilename() {
      console.log(`x1:${this.ai_road_preview_data.x1}, y1:${this.ai_road_preview_data.y1}, z1:${this.ai_road_preview_data.z1}`);
      console.log(`x2:${this.ai_road_preview_data.x2}, y2:${this.ai_road_preview_data.y2}, z2:${this.ai_road_preview_data.z2}`);
      if (this.ai_road_preview_data.x1 !== '' || this.ai_road_preview_data.y1 !== '' || this.ai_road_preview_data.z1 !== ''
        || this.ai_road_preview_data.x2 !== '' || this.ai_road_preview_data.y2 !== '' || this.ai_road_preview_data.z2 !== '') {
        this.disabledPointCloudFilename = true;
      } else {
        this.disabledPointCloudFilename = false;
      }
    },
    resetProperty() {
      // 地形指定モードで開くときはリセットしない
      if (!this.$store.getters['addRoadTerrainByAi/isAIRoadPreviewMode']) {
        this.terrainItems.forEach(item => item.value = "");
        this.designFilename = "";
        this.pointCloudItem = "";
        this.reset_ai_road_preview_data();
        this.isFlatGroundAdded = false;
        this.disabledDesignFilename = false;
        this.disabledPointCloudFilename = false;
      }
    },
    editedStartEndPoint() {
      if (this.ai_road_preview_data.x1 !== '' || this.ai_road_preview_data.y1 !== '' || this.ai_road_preview_data.z1 !== ''
        || this.ai_road_preview_data.x2 !== '' || this.ai_road_preview_data.y2 !== '' || this.ai_road_preview_data.z2 !== '') {
        return true;
      }
      return false;
    },
    setTerrainItems() {
      this.terrainItems[0].name = this.$t("TERRAIN_X_VALUE_MIN");
      this.terrainItems[1].name = this.$t("TERRAIN_X_VALUE_MAX");
      this.terrainItems[2].name = this.$t("TERRAIN_Y_VALUE_MIN");
      this.terrainItems[3].name = this.$t("TERRAIN_Y_VALUE_MAX");
      this.terrainItems[4].name = this.$t("DESIGN_X_VALUE_MIN");
      this.terrainItems[5].name = this.$t("DESIGN_X_VALUE_MAX");
      this.terrainItems[6].name = this.$t("DESIGN_Y_VALUE_MIN");
      this.terrainItems[7].name = this.$t("DESIGN_Y_VALUE_MAX");
      this.terrainItems[8].name = this.$t("TERRAIN_Z_VALUE_MIN");
      this.terrainItems[9].name = this.$t("TERRAIN_Z_VALUE_MAX");
      this.terrainItems[10].name = this.$t("DESIGN_Z_VALUE_MIN");
      this.terrainItems[11].name = this.$t("DESIGN_Z_VALUE_MAX");
      return this.terrainItems;
    },
    fileInp1Changed(){
      let txtContainer = document.querySelector(".fileInp1 .v-file-input__text");
      if(txtContainer){
        txtContainer.innerHTML = "";
      }
    },
    buttonVisibilityCheck() {
      if (this.file) {
        return false;
      } else return true;
    },
    setTerrainInfo(data) {
      // undefinedかnullになる場合は空文字を返す
      const convertToEmpty = (data, key) => {
        if (key in data) {
          if (data[key] === undefined || data[key] === null) {
            return '';
          } else {
            return data[key];
          }
        } else {
          return '';
        }
      }
      this.terrainItems[0].value = convertToEmpty(data, 'terrain_x_value_min');
      this.terrainItems[1].value = convertToEmpty(data, 'terrain_x_value_max');
      this.terrainItems[2].value = convertToEmpty(data, 'terrain_y_value_min');
      this.terrainItems[3].value = convertToEmpty(data, 'terrain_y_value_max');

      this.terrainItems[4].value = convertToEmpty(data, 'design_x_value_min');
      this.terrainItems[5].value = convertToEmpty(data, 'design_x_value_max');
      this.terrainItems[6].value = convertToEmpty(data, 'design_y_value_min');
      this.terrainItems[7].value = convertToEmpty(data, 'design_y_value_max');

      this.terrainItems[8].value = convertToEmpty(data, 'terrain_height_min');
      this.terrainItems[9].value = convertToEmpty(data, 'terrain_height_max');
      this.terrainItems[10].value = convertToEmpty(data, 'design_height_min');
      this.terrainItems[11].value = convertToEmpty(data, 'design_height_max');
    },
    async getTerrainInfo() {
      // スピナーを表示する
      this.$store.dispatch('updateIsLoading', true);
      const pointId = this.pointCloudItem.point_cloud_id;
      const designId = this.designFilename.design_id;
      const userId = this.$store.state.user?.id;
      await fetch(`${process.env.VUE_APP_API_BASE}/designRoadApp/?user_id=${userId}&point_id=${pointId}&design_id=${designId}&timestamp=${new Date().getTime()}`, 
        { 
          method: 'GET', 
        })
        .then(response => {
          if (response.ok) {
            // console.log(response);
            return response.json()
          }
        })
        .then(data =>
        { 
          console.log(data);
          this.setTerrainInfo(data);
        })
        .catch(error => {
          // エラーが発生した場合は地形情報を空にする
          this.setTerrainInfo({});
          console.log(error);
        })
        .finally(() => {
          // スピナーを表示にする
          this.$store.dispatch('updateIsLoading', false);
        });
    },
    getTerrainInfoMock() {
      // mock test data
      this.terrainItems[0].value = 22684;
      this.terrainItems[1].value = 22881;
      this.terrainItems[2].value = -44116;
      this.terrainItems[3].value = -43926;

      this.terrainItems[4].value = 22731;
      this.terrainItems[5].value = 22845;
      this.terrainItems[6].value = -44071;
      this.terrainItems[7].value = -43949;

      this.terrainItems[8].value = 0.272;
      this.terrainItems[9].value = 13.802;
      this.terrainItems[10].value = 3.5;
      this.terrainItems[11].value = 6.42;
    },
    addRoadByJson() {
      // current Dialog close
      this.$emit("close", undefined);
      
      const dummyId = 1;
      const event = new CustomEvent("openPropertyDialog", {
        detail: { id: dummyId, type: 4 },
      });
      window.dispatchEvent(event);
    },
    preprocessConfirm() {
      this.$store.state.terrainItems = this.terrainItems;
      this.isPreprocessConfirm = true;
      this.showPreprocessConfirmDialog = true;
    },
    closeConfirmDialog() {
        this.isPreprocessConfirm = false;
        this.showPreprocessConfirmDialog = false;
        this.isAIRoadAdd = false;
        this.showJsonDialog =  false;
    },
    cancelPreprocessDialog() {
        this.isAIRoadAdd = false;
        this.showJsonDialog =  false;
    },
    cancelPreprocessConfirmDialog() {
        this.isPreprocessConfirm = false;
        this.showPreprocessConfirmDialog = false;
    },
    async enableAIRoadPreview() {
      try {
        // 地形指定モードに設定
        this.$store.dispatch('addRoadTerrainByAi/update_is_ai_road_preview_mode', true);
        this.showJsonDialog = false;
        this.$emit("close", undefined);

        this.set_modes({
          name:'ai_road_point_selection',
          value:true
        })
        this.reset_ai_road_preview_data();
        this.disableAllEdits();
        this.globeVisibilityBeforeCreationMode = this.globeVisibility;
        this.$store.state.modes.inCreationMode = true;
        await window["viewer"].createAIRoadPreview();
        if(!this.ai_road_preview_data.startPointHeightAboveDesignData || !this.ai_road_preview_data.endPointHeightAboveDesignData) {
          this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_SELECT_START_END_POINT")}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        }
        const dummyId = 1;
        const event = new CustomEvent("openPropertyDialog", {
          detail: { id: dummyId, type: 2 },
        });
        window.dispatchEvent(event);
      } catch (e) {
        console.error(e);
      } finally {
        // 地形指定モードを解除
        this.$store.dispatch('addRoadTerrainByAi/update_is_ai_road_preview_mode', false);
        this.setDisabledDesignFilename();
        this.setDisabledPointCloudFilename();
      }
    },
    testButton() {
      this.startpointXval = 1
      this.startpointYval = 2;
      this.startpointZval = 3;
      this.endpointXval = 4;
      this.endpointYval = 5;
      this.endpointZval = 6;
    },
    setTargetPoint(startpoint, endpoint) {
      startpoint[0] = Number(startpoint[0].replace('(', ''));
      startpoint[1] = Number(startpoint[1]);
      startpoint[2] = Number(startpoint[2].replace(')', ''));
      endpoint[0] = Number(endpoint[0].replace('(', ''));
      endpoint[1] = Number(endpoint[1]);
      endpoint[2] = Number(endpoint[2].replace(')', ''));

      this.startpointXval = startpoint[0].toFixed(3);
      this.startpointYval = startpoint[1].toFixed(3);
      this.startpointZval = startpoint[2].toFixed(3);
      this.endpointXval = endpoint[0].toFixed(3);
      this.endpointYval = endpoint[1].toFixed(3);
      this.endpointZval = endpoint[2].toFixed(3);
    },
    disableAllEdits() {
      if (this.$store.state.modes.inEditMode == true) {
        this.$store.state.modes.inEditMode = false;
        this.$store.state.objects.forEach((obj) => {
          if (obj.edit) {
            obj.edit = false;
            if (obj.type === 0) {
              window["viewer"].changeRoadIn2DViewer(obj.id);
              window["viewer"].enableControPointDragging(obj.edit, obj.id);
            } else {
              this.$store.state.modes.inFlatGroundHoriDrag = true;
              window["viewer"].toggleFlatGroundPointDrag(obj.edit, obj.id);
              window["viewer"].toggleFlatGroundPointVerticalDrag(obj.edit, obj.id);
            }
          }
        });
        window["viewer"].clearCommandManager();
      }
    },
    closeJsonDialog() {
      this.isAIRoadAdd = false;
      this.showJsonDialog = false;

      this.isAddRoadByJson = false;
      this.showAddRoadByJson = false;
    },
  },
};
</script>
<style scoped>
.closeDialogIcon {
  margin-top: 5%;
}
.designFilename .v-label {
  font-size: 20px;
}
.uploadBtn {
  margin-bottom: 3%;
  margin-left: 80%;
}
#th-terrain-info {
  font-size: 20px;
}
#tr-terrain-item td {
  height: 40px;
}
.cancelBtn {
  margin-bottom: 3%;
  /*margin-left: 80%;*/
  color: #fff;
  height: 36px !important;
  padding: 10px 25px!important;
  background-color: #f44336!important;
  border-color: #f44336!important;
  border-radius: 5px!important; 
}
.preProcessBtn {
  margin-bottom: 3%;
  /*margin-left: 80%*/;
  color: #fff;
  height: 36px !important;
  padding: 10px 45px !important;
  background-color: #0064ba !important;
  border-color: #0064ba !important;
  border-radius: 5px!important;
}
.OkBtn {
  margin-bottom: 3%;
  /*margin-left: 80%*/;
  color: #fff;
  height: 36px !important;
  padding: 10px 50px!important;
  background-color: #0064ba !important;
  border-color: #0064ba !important;
  border-radius: 5px!important;
}
.v-data-table td {
  background: #6e6e6e;
  color: #000000;
}
.v-data-table tr:nth-child(odd) td {
  background: #9e9e9e;
  color: #000000;
}
.col-4 {
  width: 33% !important;
  padding-right: 100px !important;
  padding-left: 10px !important;
}
</style>
