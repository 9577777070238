<template>
  <div>
      <select-type @close="closeDialog()" />
      <upload-data v-if="false"/>
  </div>
</template>

<script>
import SelectType from "../AddData/SelectType.vue";
import UploadData from "../AddData/UploadData.vue";

export default {
  name: "Add",
  components: {
    SelectType,
    UploadData,
  },
  props: {
    id: String,
  },
  mounted: () => {},
  data: () => ({}),
  methods: {
    closeDialog(){
       this.$emit("close");
    }
  },
};
</script>
