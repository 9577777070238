<template>
  <v-footer app inset :height="this.mini ? '36px' : '240px'" color="panel_main" class="pa-0">
    <DragableDialog v-if="showDraggableDialog" :title="draggedTab.title" >
      <component :is="draggedTab.component"></component>
    </DragableDialog>
    <div style="display: flex; justify-content: space-between; width:100%">
      <div background-color="panel_sub">
        <v-btn v-for="tab in tabs()" :key="tab.id" :class="['tab-button', { active: currentTab.id === tab.id }]"
          @click="currentTab = tab">
          {{ $t(tab.title) }}
        </v-btn>
      </div>
      <div>
        <v-btn icon>
          <v-icon @click="toggleSplitDialog()"> icon-New-Window </v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="toggleChartBoardMini()">
            {{ this.mini ? "icon-Chevron-Up" : "icon-Chevron-Down" }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <component :is="currentTab.component" style="width: 100%; height: 100%"></component>
  </v-footer>
</template>

<script>
import DragableDialog from './DragableDialog.vue';
import ProfileViewDialog from './ProfileViewDialog.vue';
import SectionViewDialog from './SectionViewDialog.vue';
// import SectionDialog from "../SectionDialog.vue";

export default {
  components: { DragableDialog, ProfileViewDialog, SectionViewDialog },
  name: "ChartBoard",
  watch: {
    mini() {
      this.$nextTick(() => {
        // call request render
        window["viewer"].requestRender();
      });
    }
  },
  data: () => ({
    mini: false,
    showDraggableDialog: false,
    title: "Hello from Parent",
    currentTab: { id: "profile", title: "Longitudinalsectionview", component: 'ProfileViewDialog' },
    draggedTab: undefined,
    alltabs: [{ id: "profile", title: "Longitudinalsectionview", component: 'ProfileViewDialog' }, { id: "section", title: "Crosssectionview", component: 'SectionViewDialog' }]
  }),
  methods: {
    toggleChartBoardMini() {
      this.mini = !this.mini;
    },
    // updateTab() {
    //  },
    toggleSplitDialog() {
      this.showDraggableDialog = !this.showDraggableDialog;
      if (this.showDraggableDialog) {
        this.draggedTab = Object.assign({}, this.currentTab);
        this.currentTab = this.tabs()[0];
      }
    },

    tabs() {
      if (this.showDraggableDialog) {
        return this.alltabs.filter((tab) => tab.id !== this.draggedTab.id);
      }
      else {
        return this.alltabs;
      }
    },
  },
  created: function () {
    console.log("Chartboard Created func");
  },
  mounted: () => {
    console.log("Chartboard Mounted func");
  },
  updated: () => {
    console.log("Updated");
  },
  onresize: (size) => {
    console.log("resize called", size);
  },
  computed: {
    draggableparams: function () {
      return { title: this.draggedTab.title }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-footer {
  align-items: start !important;
  transition: all 0.2s;
}

.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}

.tab-button {
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.tab-button:hover {
  background: #302f2f;
}

.tab-button.active {
  background-color: transparent;
  color: #0064ba;
  border-bottom: 0.2em solid #0064ba;
}
</style>
