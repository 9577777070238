<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title>{{$t("RoadadditionbyAI")}}</v-card-title>
    <v-btn
      class="closeDialogIcon"
      fab
      absolute
      top
      right
      @click="closeProcess"
    >
      <v-icon> mdi-close </v-icon>
    </v-btn>
    <v-container style="width: 100%" class="ma-1">
      <v-row>
        <v-col cols="3" sm="3" style="padding:20px 100px 20px 20px;">
          <v-row>
            <p>{{$t(("LABEL_DESIGN_FILE"))}}</p>
          </v-row>
          <v-row>
            <v-text-field 
              class="designFilename" 
              :value=designFilename.design_name
              disabled
              filled
            ></v-text-field>
          </v-row>
          <v-row>
            <p>{{$t(("LABEL_TERRAIN_FILE"))}}</p>
          </v-row>
          <v-row>
            <v-text-field 
              class="terrainFilename" 
              :value=pointCloudItem.point_cloud_name
              disabled
              filled
            ></v-text-field>
          </v-row>
          <v-row>
            <p>{{$t(("TERRAIN_INFO"))}}</p>
          </v-row>
          <v-row>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      id="tr-terrain-item"
                      v-for="item in terrainItems"
                      :key="item.name"
                    >
                      <td>{{ item.name }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-row>
        </v-col>
        <v-col cols="4" sm="4" style="">
          <v-row>
            <p>{{$t(("LABEL_CONSTRUCTION_PARAM"))}}</p>
          </v-row>
          <v-row fill-height>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_X_VALUE_MIN"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['xValueMin']"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ xValueMin }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_X_VALUE_MAX"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['xValueMax']"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ xValueMax }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_Y_VALUE_MIN"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['yValueMin']"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ yValueMin }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_Y_VALUE_MAX"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['yValueMax']"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ yValueMax }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_XY_INTERVAL"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['xyInterval']"
                  :min="1"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ xyInterval }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_Z_VALUE_MIN"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['zValueMin']"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ zValueMin }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_Z_VALUE_MAX"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['zValueMax']"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ zValueMax }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_Z_INTERVAL"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['zInterval']"
                  :min="1"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ zInterval }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_ALLOW_MAX_GRADIENT"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['allowMaxGradient']"
                  :min="0"
                  :max="100"
                  placeholder=""
                  regular
                  type="number"
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ allowMaxGradient }}</v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <p class="font-weight-black">{{$t(("INPUT_SEARCH_EXPANSION_RADIUS"))}}</p>
                </v-col>
                <v-col cols="3" sm="3" style="margin-top: -30px;">
                  <v-text-field
                  v-model="inputTextField['searchExpansionRadius']"
                  placeholder=""
                  regular
                  type="number"
                  disabled
                  :rules="[rulesRequired, rulesCounter]"
                  >{{ searchExpansionRadius }}</v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <p>※{{$t(("LABEL_PROMPT_SEARCH_EXPANSION_RADIUS"))}} ≧ {{ searchExpansionRadiusMin }}</p>
              </v-row> -->
            </v-container>
          </v-row>
        </v-col>
        <v-col cols="3" sm="3" style="padding:20px 100px 20px 20px;">
          <v-row>
            <p>{{$t(("LABEL_IMPORTANCE_COEFFICIENT"))}}</p>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <p>{{ this.$t('LABEL_PRIORITY_CUT') }}</p>
            </v-col>
            <v-col cols="6" sm="6">
              <v-slider
                v-model="sliderValueCut"
                :min="sliderMin"
                :max="sliderMax"
                :step="sliderStep"
                class="align-center"
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="sliderValueCut"
                    :min="sliderMin"
                    :max="sliderMax"
                    :step="sliderStep"
                    :rules="[rulesRequired, rulesNumberLimit]"
                    class="mt-0 pt-0"
                    type="number"
                    style="width: 50px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <p>{{ this.$t('LABEL_PRIORITY_FILL') }}</p>
            </v-col>
            <v-col cols="6" sm="6">
              <v-slider
              v-model="sliderValueFill"
              :min="sliderMin"
              :max="sliderMax"
              :step="sliderStep"
              class="align-center"
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="sliderValueFill"
                    :min="sliderMin"
                    :max="sliderMax"
                    :step="sliderStep"
                    :rules="[rulesRequired, rulesNumberLimit]"
                    class="mt-0 pt-0"
                    type="number"
                    style="width: 50px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <p>{{ this.$t('LABEL_PRIORITY_OVER_FILL') }}</p>
            </v-col>
            <v-col cols="6" sm="6">
              <v-slider
              v-model="sliderValueOverFill"
              :min="sliderMin"
              :max="sliderMax"
              :step="sliderStep"
              class="align-center"
              >
                <template v-slot:append>
                  <v-text-field
                  v-model="sliderValueOverFill"
                  :min="sliderMin"
                  :max="sliderMax"
                  :step="sliderStep"
                  :rules="[rulesRequired, rulesNumberLimit]"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 50px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <p>{{ this.$t('LABEL_PRIORITY_OVER_CUT') }}</p>
            </v-col>
            <v-col cols="6" sm="6">
              <v-slider
              v-model="sliderValueOverCut"
              :min="sliderMin"
              :max="sliderMax"
              :step="sliderStep"
              class="align-center"
              >
                <template v-slot:append>
                  <v-text-field
                  v-model="sliderValueOverCut"
                  :min="sliderMin"
                  :max="sliderMax"
                  :step="sliderStep"
                  :rules="[rulesRequired, rulesNumberLimit]"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 50px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <p>{{ this.$t('LABEL_PRIORITY_CF_DIFF') }}</p>
            </v-col>
            <v-col cols="6" sm="6">
              <v-slider
              v-model="sliderValueCfDiff"
              :min="sliderMin"
              :max="sliderMax"
              :step="sliderStep"
              class="align-center"
              >
                <template v-slot:append>
                  <v-text-field
                  v-model="sliderValueCfDiff"
                  :min="sliderMin"
                  :max="sliderMax"
                  :step="sliderStep"
                  :rules="[rulesRequired, rulesNumberLimit]"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 50px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <p>{{ this.$t('LABEL_PRIORITY_ROUTE') }}</p>
            </v-col>
            <v-col cols="6" sm="6">
              <v-slider
              v-model="sliderValueRoute"
              :min="sliderMin"
              :max="sliderMax"
              :step="sliderStep"
              class="align-center"
              >
                <template v-slot:append>
                  <v-text-field
                  v-model="sliderValueRoute"
                  :min="sliderMin"
                  :max="sliderMax"
                  :step="sliderStep"
                  :rules="[rulesRequired, rulesNumberLimit]"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 50px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" style="min-width: 140px !important;">
              <v-btn
                color="orange"
                class="cancelBtn"
                id="cancel_btn"
                rounded
                x-large
                @click="closeProcess"
              >
              {{$t(("CANCEL"))}}
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" style="min-width: 140px !important;">
                <v-btn color="orange"
                       class="TempRoadBtn"
                       id="temp_road_btn"
                       rounded
                       x-large
                       :disabled="isTempRoadBtnDisabled"
                       @click="setRoadJsonAI()">
                    {{$t(("TEMPORARY_ROAD_AI"))}}
                </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import axios from 'axios';
import { mapMutations, mapState } from 'vuex';
import {convertCoordinateToCesiumCrs} from "./../../utils/cesium-common";

export default {
  name: "addRoadByJson",
  props: {},
  mounted() { 
    this.setInputTextField();
  },
  data: () => ({
    isComplete: false,
    file: null,
    isTempRoadBtnDisabled: true,
    inputTextField: 
    {
      'xValueMin' : '',
      'xValueMax' : '',
      'yValueMin' : '',
      'yValueMax' : '',
      'xyInterval': '',
      'zValueMin' : '',
      'zValueMax' : '',
      'zInterval' : '',
      'allowMaxGradient' : '',
      'searchExpansionRadius' : '',
    },
    terrainInfo: null,
    sliderMin: 0.1,
    sliderMax: 1.0,
    sliderStep: 0.01,
    sliderValueCut: 1.0,
    sliderValueFill: 1.0,
    sliderValueOverFill: 1.0,
    sliderValueOverCut: 1.0,
    sliderValueCfDiff: 1.0,
    sliderValueRoute: 1.0,
  }),
  watch: {
    // whenever value changes for this variables, this function will run
  },
  computed: {
    ...mapState(['ai_road_preview_data']),
    rulesRequired() {
      return value => !!value || this.$t("MESSAGE_ERROR_VALIDATE_REQUIRED");
    },
    rulesCounter() {
      return value => value.length <= 20 || this.$t("MESSAGE_ERROR_VALIDATE_COUNTER");
    },
    rulesNumberLimit() {
      return value => (value > 0 && value <= 1) || this.$t("MESSAGE_ERROR_VALIDATE_NUMBER_LIMIT");
    },
    designFilename: function() {
      return this.$store.getters['addRoadTerrainByAi/designFilename'];
    },
    pointCloudItem: function() {
      return this.$store.getters['addRoadTerrainByAi/pointCloudItem'];
    },
    terrainItems: function() {
      return this.$store.getters['addRoadTerrainByAi/terrainItems'];
    },
    startpointXval : {
      get() {
        return this.ai_road_preview_data.x1
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'x1',
          value: newValue
        })
      }
    },
    startpointYval : {
      get() {
        return this.ai_road_preview_data.y1
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'y1',
          value: newValue
        })
      }
    },
    startpointZval : {
      get() {
        return this.ai_road_preview_data.z1
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'z1',
          value: newValue
        })
      }
    },
    endpointXval : {
      get() {
        return this.ai_road_preview_data.x2
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'x2',
          value: newValue
        })
      }
    },
    endpointYval : {
      get() {
        return this.ai_road_preview_data.y2
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'y2',
          value: newValue
        })
      }
    },
    endpointZval : {
      get() {
        return this.ai_road_preview_data.z2
      },
      set(newValue) {
        this.set_ai_road_preview_data_property({
          name: 'z2',
          value: newValue
        })
      }
    },
    xValueMin: function () { return this.checkInputTextField(); },
    xValueMax: function () { return this.checkInputTextField(); },
    yValueMin: function () { return this.checkInputTextField(); },
    yValueMax: function () { return this.checkInputTextField(); },
    xyInterval: function () { return this.checkInputTextField(); },
    zValueMin: function () { return this.checkInputTextField(); },
    zValueMax: function () { return this.checkInputTextField(); },
    zInterval: function () { return this.checkInputTextField(); },
    allowMaxGradient: function () { return this.checkInputTextField(); },
    searchExpansionRadius: function () { return this.setSearchExpansionRadius(); },
    searchExpansionRadiusMin: function () { 
      return this.cal_suggest_radius(
        this.inputTextField['xyInterval'],
        this.inputTextField['zInterval'],
        this.inputTextField['allowMaxGradient']);
    },
  },
  methods: {
    ...mapMutations(['set_ai_road_preview_data_property', 'reset_ai_road_preview_data']),
    setInitialize() {
      this.setInputTextField();
    },
    setInputTextField() {
      this.terrainInfo = this.$store.getters['addRoadTerrainByAi/terrainItems'];
      this.inputTextField.xValueMin = String(Math.max(this.terrainInfo[0].value, this.terrainInfo[4].value));
      this.inputTextField.xValueMax = String(Math.min(this.terrainInfo[1].value, this.terrainInfo[5].value));
      this.inputTextField.yValueMin = String(Math.max(this.terrainInfo[2].value, this.terrainInfo[6].value));
      this.inputTextField.yValueMax = String(Math.min(this.terrainInfo[3].value, this.terrainInfo[7].value));
      this.inputTextField.xyInterval = '10';
      const min_z1 = this.terrainInfo[8].value;
      const min_z2 = this.terrainInfo[10].value;
      this.inputTextField.zValueMin = String(Math.floor(Math.min(Math.max(min_z1, min_z2), this.startpointZval, this.endpointZval)) - 1);
      
      const max_z1 = this.terrainInfo[9].value;
      const max_z2 = this.terrainInfo[11].value;
      this.inputTextField.zValueMax = String(Math.ceil(Math.max(Math.min(max_z1, max_z2), this.startpointZval, this.endpointZval)) + 1);

      this.inputTextField.zInterval = '5';
      this.inputTextField.allowMaxGradient = '15';
      this.inputTextField.searchExpansionRadius = this.cal_suggest_radius(this.inputTextField['xyInterval'],
                                                                          this.inputTextField['zInterval'],
                                                                          this.inputTextField['allowMaxGradient']);
    },
    setSearchExpansionRadius() {
      this.inputTextField['searchExpansionRadius'] = String(Math.ceil(this.cal_suggest_radius(this.inputTextField['xyInterval'],
                                                                          this.inputTextField['zInterval'],
                                                                          this.inputTextField['allowMaxGradient'])));
      return this.inputTextField['searchExpansionRadius'];
    },
    focusOutInputTextField(targetName) {
      this.inputTextField[targetName] = String(Math.floor(this.inputTextField[targetName]));
    },
    isErrorXvalMinRange() {
      // 「基準地形 X値 最小値」と「比較地形 X値 最小値」の大きい側を利用して入力値判定をおこなう
      const boundLower = Math.max(this.terrainInfo[0].value, this.terrainInfo[4].value);
      console.log(`基準地形 X値 最小値: ${this.terrainInfo[0].value}, 比較地形 X値 最小値:${this.terrainInfo[4].value}`);
      if (Number(this.inputTextField.xValueMin) < boundLower) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_X_VALUE_MIN")} ≧ ${boundLower}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        return true;
      }
      return false;
    },
    isErrorXvalMaxRange() {
      // 「基準地形 X値 最大値」と「比較地形 X値 最大値」の小さい側を利用して入力値判定をおこなう
      const boundUpper = Math.min(this.terrainInfo[1].value, this.terrainInfo[5].value);
      console.log(`基準地形 X値 最大値: ${this.terrainInfo[1].value}, 比較地形 X値 最大値:${this.terrainInfo[5].value}`);
      if (Number(this.inputTextField.xValueMax) > boundUpper) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_X_VALUE_MAX")} ≦ ${boundUpper}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        return true;
      }
      return false;
    },
    isErrorYvalMinRange() {
      // 「基準地形 Y値 最小値」と「比較地形 Y値 最小値」の大きい側を利用して入力値判定をおこなう
      const boundLower = Math.max(this.terrainInfo[2].value, this.terrainInfo[6].value);
      console.log(`基準地形 Y値 最小値: ${this.terrainInfo[2].value}, 比較地形 Y値 最小値:${this.terrainInfo[6].value}`);
      if (Number(this.inputTextField.yValueMin) < boundLower) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_Y_VALUE_MIN")} ≧ ${boundLower}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        return true;
      }
      return false;
    },
    isErrorYvalMaxRange() {
      // 「基準地形 Y値 最大値」と「比較地形 Y値 最大値」の小さい側を利用して入力値判定をおこなう
      const boundUpper = Math.min(this.terrainInfo[3].value, this.terrainInfo[7].value);
      console.log(`基準地形 Y値 最大値: ${this.terrainInfo[3].value}, 比較地形 Y値 最大値:${this.terrainInfo[7].value}`);
      if (Number(this.inputTextField.yValueMax) > boundUpper) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_Y_VALUE_MAX")} ≦ ${boundUpper}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        return true;
      }
      return false;
    },
    isErrorXYintervalRange() {
      let result = false;
      if (Number(this.inputTextField.xyInterval) < 1) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_XY_INTERVAL")}≧1`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        result = true;
      }
      return result;
    },
    isErrorZvalMinRange() {
      // console.log(`this.terrainInfo[8].value:${this.terrainInfo[8].value}, ${Math.ceil(this.terrainInfo[8].value)}`);
      // console.log(`this.terrainInfo[10].value:${this.terrainInfo[10].value}, ${Math.ceil(this.terrainInfo[10].value)}`);
      // console.log(`this.startpointZval:${this.startpointZval}`);
      // console.log(`this.endpointZval:${this.endpointZval}`);
      // console.log(`this.inputTextField.zValueMin:${this.inputTextField.zValueMin}`);
      const min_z1 = this.terrainInfo[8].value;
      const min_z2 = this.terrainInfo[10].value;

      const min_cmp = Math.min(Math.max(min_z1, min_z2), this.startpointZval, this.endpointZval)
      if (Number(this.inputTextField.zValueMin) >= min_cmp) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_Z_VALUE_MIN")} < ${min_cmp}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        return true;
      }
      return false;
    },
    isErrorZvalMaxRange() {
      // console.log(`this.terrainInfo[9].value:${this.terrainInfo[9].value}, ${Math.floor(this.terrainInfo[9].value)}`);
      // console.log(`this.terrainInfo[11].value:${this.terrainInfo[11].value}, ${Math.floor(this.terrainInfo[11].value)}`);
      // console.log(`this.startpointZval:${this.startpointZval}`);
      // console.log(`this.endpointZval:${this.endpointZval}`);
      // console.log(`this.inputTextField.zValueMax:${this.inputTextField.zValueMax}`);
      const max_z1 = this.terrainInfo[9].value;
      const max_z2 = this.terrainInfo[11].value;

      const max_cmp = Math.max(Math.min(max_z1, max_z2), this.startpointZval, this.endpointZval)
      if (max_cmp >= Number(this.inputTextField.zValueMax)) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${max_cmp} < ${this.$t("INPUT_Z_VALUE_MAX")}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        return true;
      }
      return false;
    },
    isErrorZintervalRange() {
      let result = false;
      if (Number(this.inputTextField.zInterval) < 1) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_Z_INTERVAL")}≧1`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        result = true;
      }
      return result;
    },
    isErrorAllowMaxGradientRange() {
      let result = false;
      if (Number(this.inputTextField.allowMaxGradient) < 0
      || Number(this.inputTextField.allowMaxGradient) > 100) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: 0≦${this.$t("INPUT_ALLOW_MAX_GRADIENT")}≦100`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        result = true;
      }
      return result;
    },
    isErrorSearchExpansionRadiusRange() {
      let result = false;
      if (Number(this.inputTextField.searchExpansionRadius) < this.searchExpansionRadiusMin) {
        this.$store.commit("set_snackbar", {
                  text: `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")}: ${this.$t("INPUT_SEARCH_EXPANSION_RADIUS")}≧${this.searchExpansionRadiusMin}`,
                  color: 'rgba(153, 0, 0, 0.72)'
                });
        result = true;
      }
      return result;
    },
    // validatePointZvalue(targetParamName) {
    //   const inputZvalueMin = Number(this.inputTextField.zValueMin);
    //   const inputZvalueMax = Number(this.inputTextField.zValueMax);

    //   const startpointZ = Number(this.startpointZval);
    //   const endpointZ = Number(this.endpointZval);
    //   const lowerZpoint = Math.min(startpointZ, endpointZ);
    //   const upperZpoint = Math.max(startpointZ, endpointZ);
    //   console.log(`startpoint:(${this.startpointXval},${this.startpointYval},${this.startpointZval})`);
    //   console.log(`endpoint:(${this.endpointXval},${this.endpointYval},${this.endpointZval})`);
    //   console.log(`lowerZpoint:${lowerZpoint}, upperZpoint:${upperZpoint}`);
    //   const hintMsg = (targetParamName === this.$t("INPUT_Z_VALUE_MIN"))?
    //                     `${targetParamName} ≦ ${lowerZpoint}`
    //                   : `${targetParamName} ≧ ${upperZpoint}`;
    //   const errorMsg = `${this.$t("MESSAGE_ERROR_INPUT_DATA_ERROR")} ${targetParamName}: ${this.$t("MESSAGE_ERROR_RANGE_START_END_POINT")}\n`
    //             + hintMsg;

    //   // 始点・終点のz座標が格子パラメータ(底面z値、上面z値)の範囲内かどうか
    //   const isErrorValue = (targetParamName === this.$t("INPUT_Z_VALUE_MIN"))?
    //                         (inputZvalueMin > lowerZpoint)
    //                       : (inputZvalueMax < upperZpoint);
    //   if (isErrorValue) {
    //     this.$store.commit("set_snackbar", {
    //               text: errorMsg,
    //               color: 'rgba(153, 0, 0, 0.72)'
    //             });
    //       return false;
    //   }
    //   return true;
    // },
    checkInputValueError() {
      const xvalMinRangeError = false;
      const xvalMaxRangeError = false;
      const yvalMinRangeError = false;
      const yvalMaxRangeError = false;
      const xyIntervalRangeError = this.isErrorXYintervalRange();
      const zvalMinRangeError = this.isErrorZvalMinRange();
      const zvalMaxRangeError = this.isErrorZvalMaxRange();
      const zIntervalRangeError = this.isErrorZintervalRange();
      const allowMaxGradientRangeError = this.isErrorAllowMaxGradientRange();
      const searchExpansionRadiusRangeError = this.isErrorSearchExpansionRadiusRange();
      
      // const zvalMinInvalid = !this.validatePointZvalue(this.$t("INPUT_Z_VALUE_MIN"));
      // const zvalMaxInvalid = !this.validatePointZvalue(this.$t("INPUT_Z_VALUE_MAX"));
      return (xvalMinRangeError || xvalMaxRangeError ||  yvalMinRangeError || yvalMaxRangeError
            || xyIntervalRangeError || zvalMinRangeError || zvalMaxRangeError || zIntervalRangeError
            || allowMaxGradientRangeError || searchExpansionRadiusRangeError);
    },
    closeProcess() {
      console.log("closeProcess AddRoadByJson");
      this.resetProperty();
      this.$emit("close", undefined);
    },
    resetProperty() {
      this.designFilename = "";
      this.pointCloudItem = "";
      this.terrainItems.forEach(item => item.value = "");

      this.inputTextField['xValueMin'] = '';
      this.inputTextField['xValueMax'] = '';
      this.inputTextField['yValueMin'] = '';
      this.inputTextField['yValueMax'] = '';
      this.inputTextField['xyInterval'] = '';
      this.inputTextField['zValueMin'] = '';
      this.inputTextField['zValueMax'] = '';
      this.inputTextField['zInterval'] = '';
      this.inputTextField['allowMaxGradient'] = '';
      this.inputTextField['searchExpansionRadius'] = '';

      this.reset_ai_road_preview_data();

      this.sliderValueCut = 1.0;
      this.sliderValueFill =  1.0;
      this.sliderValueOverFill = 1.0;
      this.sliderValueOverCut = 1.0;
      this.sliderValueCfDiff = 1.0;
      this.sliderValueRoute = 1.0;

    },
    // fileInp1Changed(){
    //   let txtContainer = document.querySelector(".fileInp1 .v-file-input__text");
    //   if(txtContainer){
    //     txtContainer.innerHTML = "";
    //   }
    // },
    buttonVisibilityCheck() {
      if (this.file) {
        return false;
      } else return true;
    },
    async convertCoordinates(pointVal){
      const sliceByNumber = (array, number) => {
        const length = Math.ceil(array.length / number)
        return new Array(length).fill().map((_, i) =>
          array.slice(i * number, (i + 1) * number)
        )
      }
      let pointArray = sliceByNumber(pointVal, 3);
      let convertedArray = new Array();
      for (let point of pointArray) { 
        let convert_p = await convertCoordinateToCesiumCrs({ x:point[0], y:point[1], z:point[2] });
        console.log(`x:${convert_p.x}, y:${convert_p.y}, z:${convert_p.z}`);
        convertedArray.push(convert_p.x);
        convertedArray.push(convert_p.y);
        convertedArray.push(convert_p.z);
      }
      console.log(`convertedArray:${convertedArray}`);
      return convertedArray;
    },
    /**
     * 計算ステータス確認APIのポーリングを開始する
     * @param {*} taskId 
     */
    async startPolling (taskId) { 
      let isComplete = await this.checkCalculationStatus(taskId);
      const timeId = setInterval(async()=>{
        if (isComplete) {
          clearInterval(timeId);
          return;
        }
        isComplete = await this.checkCalculationStatus(taskId);
      }, 3000)
    },
    /**
     * 計算ステータス確認APIを実行する
     * @param {*} taskId 
     * @returns isComplete 計算完了フラグ
     */
    async checkCalculationStatus(taskId) {
      let isComplete = false;
      try {
        const userId = this.$store.state.user?.id;
        const res = await axios.get(
          `${process.env.VUE_APP_API_BASE}/designRoadApp/taskResult/?user_id=${userId}&task_id=${taskId}&timestamp=${new Date().getTime()}`, 
          { 
            headers: { 'Content-Type': 'application/json' },
          }
        )
        // 正常応答の場合
        if (res.status == 200) {
          // 非同期処理が終了しているか確認。未終了の場合再実行
          const body = JSON.parse(res.data);
          
          if(body.ready === 'True') {
            isComplete = true;
            if(body.state === 'FAILURE'
             || (body.state === 'SUCCESS' && !body.data)) {
              // スピナーを非表示にする
              this.$store.dispatch('updateIsLoading', false);
              // Error process
              this.$store.commit("set_snackbar", {
                text: `${this.$t("MESSAGE_ERROR_CREATE_ROAD_AI")}`,
                color: 'rgba(153, 0, 0, 0.72)'
              });
            } else {
              // this.$emit("close", undefined);
              this.closeProcess();
              console.log(body.data);
              const roadPoints = await this.convertCoordinates(body.data.points);
              // スピナーを非表示にする
              this.$store.dispatch('updateIsLoading', false);
              // 仮設道路を作成する
              window["viewer"].createRoadFromPoints(
                roadPoints,
                (id) => {
                  const event = new CustomEvent("openPropertyDialog", {
                    detail: { id: id, type: 0 },
                  });
                  window.dispatchEvent(event);
                },
                this.$store.getters.activePointCloudId
              );
            }
          }
        }
        return isComplete;
      } catch(error) {
        console.error(error.message);
        this.$store.commit("set_snackbar", {
                text: `${this.$t("MESSAGE_ERROR_CREATE_ROAD_AI")}`,
                color: 'rgba(153, 0, 0, 0.72)'
              });
        // スピナーを非表示にする
        this.$store.dispatch('updateIsLoading', false);
        return isComplete;
      }
    },
    async setRoadJsonAI() {
      if (this.checkInputValueError()) {
        // input data check error
        return;
      }
      // スピナーを表示する
      this.$store.dispatch('updateIsLoading', true);
      const data = {
        userId: this.$store.state.user?.id,
        pointId: this.pointCloudItem.point_cloud_id,
        designId: this.designFilename.design_id,
        inputTextField: this.inputTextField,
        terrainInfo:
        {
          sliderValueCut: this.sliderValueCut,
          sliderValueFill: this.sliderValueFill,
          sliderValueOverFill: this.sliderValueOverFill,
          sliderValueOverCut: this.sliderValueOverCut,
          sliderValueCfDiff: this.sliderValueCfDiff,
          sliderValueRoute: this.sliderValueRoute,
        },
        // terrainXvalueMin: this.terrainItems[0].value,
        // terrainXvalueMax: this.terrainItems[1].value,
        // terrainYvalueMin: this.terrainItems[2].value,
        // terrainYvalueMax: this.terrainItems[3].value,
        // designXvalueMin: this.terrainItems[4].value,
        // designXvalueMax: this.terrainItems[5].value,
        // designYvalueMin: this.terrainItems[6].value,
        // designYvalueMax: this.terrainItems[7].value,
        // terrainHeightMin: this.terrainItems[8].value,
        // terrainHeightMax: this.terrainItems[9].value,
        // designHeightMin: this.terrainItems[10].value,
        // designHeightMax: this.terrainItems[11].value,
        startpointXval: this.startpointXval,
        startpointYval: this.startpointYval,
        startpointZval: this.startpointZval,
        endpointXval: this.endpointXval,
        endpointYval: this.endpointYval,
        endpointZval: this.endpointZval,
      };
      try {
        // 仮設道路AI計算APIを実行
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}/designRoadApp/roadAi/?timestamp=${new Date().getTime()}`, 
          data,
          { 
            headers: { 'Content-Type': 'application/json', },
          })
        if (res.status == 200) {
          // レスポンスのデータが文字列で返ってくるためtaskIdを取り出す
          // const taskId = res.data.split(',').find(item => item.search(/taskId:/) !== -1)
          // const taskId = res.data.taskId;
          const body = JSON.parse(res.data);
          const taskId = body.taskId;
          console.log(body);
          if (taskId) {
            // レスポンス内にtaskIdが存在する場合はポーリング開始
            this.startPolling(taskId);
            // this.startPolling(taskId.replace('taskId:', ''))
          }
        }
      } catch (error) {
        console.error(error.message);
        // スピナーを非表示にする
        this.$store.dispatch('updateIsLoading', false);
      }
    },
    checkInputTextField() {
      if (this.inputTextField['xValueMin'].length == 0 || this.inputTextField['xValueMax'].length == 0
      || this.inputTextField['yValueMin'].length == 0 || this.inputTextField['yValueMax'].length == 0
      || this.inputTextField['xyInterval'].length == 0 || this.inputTextField['zValueMin'].length == 0
      || this.inputTextField['zValueMax'].length == 0 || this.inputTextField['zInterval'].length == 0
      || this.inputTextField['allowMaxGradient'].length == 0 || this.inputTextField['searchExpansionRadius'].length == 0 ) {
        this.isTempRoadBtnDisabled = true;
      }
      else {
        this.isTempRoadBtnDisabled = false;
      }
    },
    cal_suggest_radius(xygrid_interval, zgrid_interval, max_gradient) {
      if (xygrid_interval == "" || zgrid_interval == "" || max_gradient == "") {
        return "1";
      }
      const x_min = 100 * zgrid_interval / max_gradient;
      let x = 0;
      for (let i = 0; i < 10000; i++) {
          x = xygrid_interval * i;
          if (x > x_min)  break;
      }
      const radius = Math.sqrt(x**2 + zgrid_interval**2);
      return radius.toFixed(3);
    }
  },
};
</script>
<style scoped>
.closeDialogIcon {
  margin-top: 5%;
}
.designFilename .v-label {
  font-size: 20px;
}
.terrainFilename .v-label {
  font-size: 20px;
}
.uploadBtn {
  margin-bottom: 3%;
  margin-left: 80%;
}
#th-terrain-info {
  font-size: 20px;
}
#tr-terrain-item td {
  height: 40px;
}
.cancelBtn {
  margin-bottom: 3%;
  /*margin-left: 80%;*/
  font-size: 14px!important;
  font-weight: 600!important;
  color: #fff;
  height: 36px !important;
  padding: 10px 15px !important;
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  border-radius: 5px !important;
}
.TempRoadBtn {
  margin-bottom: 3%;
  /*margin-left: 80%;*/
  font-size: 14px!important;
  font-weight: 600!important;
  color: #fff !important;
  height: 36px !important;
  padding: 10px 15px !important;
  background-color: #0064ba !important;
  border-color: #0064ba !important;
  border-radius: 5px !important;
}
.v-data-table td {
  background: #6e6e6e;
  color: #000000;
}
.v-data-table tr:nth-child(odd) td {
  background: #9e9e9e;
  color: #000000;
}
.col-sm-3 {
    flex: 0 0 33% !important;
    max-width: 33% !important;
}
.col-4 {
  width: 33% !important;
  padding-right: 100px !important;
  padding-left: 10px !important;
}
</style>
