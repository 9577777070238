<template>
    <v-expand-transition>
        <div v-show="obj.isOpen">
            <v-form v-model="isValid">
                <v-card-text class="py-0">
                    <v-card-actions>
                        {{ $t("FLATGROUND_PARAMETERS") }}
                        <v-btn icon @click="openPanel(15, obj)" absolute right>
                            <v-icon>
                                {{
                                    obj.panel.flatGround
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="obj.panel.flatGround">

                            <v-simple-table dense>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ $t("With_Slope") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details dense class="ma-0" :disabled="!obj.edit"
                                                @click="updateSettings"
                                                v-model="obj.settings.commonSettings.withSlope"></v-checkbox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("FLATGROUND_INTERVAL") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.1" suffix="m"
                                                :disabled="!obj.edit || !withSlope" @blur="updateSettings()"
                                                v-model.number="obj.settings.commonSettings.interpolationPitch"
                                                :rules="[rules.fgValidInterpolation]" />
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-expand-transition>
                    <!-- 切土設定 -->
                    <v-card-actions>

                        {{ $t("Cutsetting") }}
                        <v-btn icon @click="openPanel(13, obj)" absolute right>
                            <v-icon>
                                {{
                                    obj.panel.cutFlat
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="obj.panel.cutFlat">
                            <v-simple-table dense>
                                <tbody>
                                    <!-- 法面高さ -->
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("Slopeheight") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :rules="[
                                                    rules.required,
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]"
                                                :disabled="!(obj.edit && obj.settings.cutSettings.generateShelf && withSlope)"
                                                @blur="updateSettings()" v-model.number="obj.settings.cutSettings.height" />
                                        </td>
                                    </tr>
                                    <!-- 法面勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Slopegradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                                :is-editable="obj.edit && withSlope"
                                                :slope-value="obj.settings.cutSettings.moundSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="obj.settings.cutSettings.moundSlope"
                                                :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 小段あり -->
                                    <tr>
                                        <td>
                                            {{ $t("Thereisberm") }}
                                        </td>
                                        <td>
                                            <v-checkbox v-model="obj.settings.cutSettings.generateShelf" hide-details dense
                                                class="ma-0" :disabled="!obj.edit || !withSlope"
                                                @click="updateSettings()"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 小段幅 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermwidth") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]"
                                                :disabled="!(obj.edit && obj.settings.cutSettings.generateShelf && withSlope)"
                                                @blur="updateSettings()"
                                                v-model.number="obj.settings.cutSettings.shelfWidth" />
                                        </td>
                                    </tr>
                                    <!-- 小段勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermgradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                                :is-editable="obj.edit && obj.settings.cutSettings.generateShelf && withSlope"
                                                :slope-value="obj.settings.cutSettings.shelfSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="obj.settings.cutSettings.shelfSlope"
                                                :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("Flatgroundcolor") }}
                                        </td>
                                        <td>
                                            <template>
                                                <v-menu offset-y :disabled="!obj.edit || !withSlope">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar :style="horizontalTerrainColorStyle"
                                                            :color="obj.settings.cutSettings.color" size="20px"
                                                            v-bind="attrs" v-on="on">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" @input="updateGroundColor()"
                                                        v-model="obj.settings.cutSettings.color"></v-color-picker>
                                                </v-menu>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-expand-transition>
                    <!-- 盛土設定 -->
                    <v-card-actions>
                        {{ $t("Fillsetting") }}
                        <v-btn icon @click="openPanel(11, obj)" absolute right>
                            <v-icon>
                                {{
                                    obj.panel.shelf
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="obj.panel.shelf">
                            <v-simple-table dense>
                                <tbody>
                                    <!-- 法面高さ -->
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("Slopeheight") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :rules="[
                                                    rules.required,
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]"
                                                :disabled="!(obj.edit && obj.settings.embankmentSettings.generateShelf && withSlope)"
                                                @blur="updateSettings()"
                                                v-model.number="obj.settings.embankmentSettings.height" />
                                        </td>
                                    </tr>
                                    <!-- 法面勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Slopegradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                                :is-editable="obj.edit && withSlope"
                                                :slope-value="obj.settings.embankmentSettings.moundSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="obj.settings.embankmentSettings.moundSlope"
                                                :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 小段あり -->
                                    <tr>
                                        <td>
                                            {{ $t("Thereisberm") }}
                                        </td>
                                        <td>
                                            <v-checkbox v-model="obj.settings.embankmentSettings.generateShelf" hide-details
                                                dense class="ma-0" :disabled="!obj.edit || !withSlope"
                                                @click="updateSettings()"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 小段幅 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermwidth") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]"
                                                :disabled="!(obj.edit && obj.settings.embankmentSettings.generateShelf && withSlope)"
                                                @blur="updateSettings()"
                                                v-model.number="obj.settings.embankmentSettings.shelfWidth" />
                                        </td>
                                    </tr>
                                    <!-- 小段勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermgradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                                :is-editable="obj.edit && obj.settings.embankmentSettings.generateShelf && withSlope"
                                                :slope-value="obj.settings.embankmentSettings.shelfSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="obj.settings.embankmentSettings.shelfSlope"
                                                :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("Flatgroundcolor") }}
                                        </td>
                                        <td>
                                            <template>
                                                <v-menu offset-y :disabled="!obj.edit || !withSlope">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar :style="horizontalTerrainColorStyle"
                                                            :color="obj.settings.embankmentSettings.color" size="20px"
                                                            v-bind="attrs" v-on="on">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" @input="updateGroundColor()"
                                                        v-model="obj.settings.embankmentSettings.color"></v-color-picker>
                                                </v-menu>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-expand-transition>
                    <!-- 土量 -->
                    <v-card-actions v-show="config.useSoilVolumeCalculation">
                        {{ $t("Soilvolume") }}
                        <v-btn icon :disabled="!obj.edit" @click="calculateFlatGroundVolume(obj)" class="ml-3"
                            title="ボリュームを計算する" :loading="obj.loadingVolume">
                            <v-icon>
                                mdi-play-circle-outline
                            </v-icon>
                        </v-btn>
                        <v-btn icon @click="openPanel(12, obj)" absolute right>
                            <v-icon>
                                {{
                                    obj.panel.volume
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="obj.panel.volume">
                            <v-simple-table dense>
                                <tbody>
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("Cut") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.1"
                                                :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="obj.cutVolume" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("FILL") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.1"
                                                :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="obj.fillVolume" />
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-expand-transition>
                    <v-card-actions class="py-0 mr-3">
                        {{ $t("OPACITY") }}
                        <v-slider :disabled="!obj.edit" @input="updateFlatGroundTransparency(obj)"
                            v-model="obj.transparency" hide-details max="1" step="0.01" class="ml-1"></v-slider>
                    </v-card-actions>
                </v-card-text>
            </v-form>
        </div>
    </v-expand-transition>
</template>

<script>
import "@/assets/icons/style.css";
import config from "@/config.json";
import SlopeSettingInput from "../SlopeSettingInput.vue"
import { UnitTypes } from "../../../constant";
import {uploadFlatGroundObj, updateFlatGroundSettingsInViewer} from "../../../utils/cesium-common";


export default ({
    name: "FlatGroundPanel",
    components: {
        SlopeSettingInput,
    },
    props: {
        obj: Object,
        rules: Object,
    },
    computed: {
        /**
         * 水平地形色のスタイル
         */
        horizontalTerrainColorStyle() {
          // 編集不可のときに適用するCSS
          if (!this.obj.edit || !this.withSlope) {
            return  {
                      opacity: 0.5,
                      'pointer-events': 'none'
                    }
          } else {
          // 編集可のときに適用するCSS
            return  {
                      opacity: 1.0 
                    }
          }
        },
        withSlope: {
          get() {
            return this.obj.settings.commonSettings.withSlope;
          },
          set(value) {
            this.obj.settings.commonSettings.withSlope = value;
          }
        },
        cutSettingSlopeUnit() {
            return this.$store.state.cutSettingSlopeUnit;
        },
        fillSettingSlopeUnit() {
            return this.$store.state.fillSettingSlopeUnit;
        },
    },
    data: () => ({
        config,
        isValid: false,
    }),
    methods: {
        getSuffix(unitType) {
            const unit = UnitTypes.find(type => type.value === unitType);
            return unit.symbol;
        },
        updateSettings() {
            if (!this.isValid) return;
            updateFlatGroundSettingsInViewer(this.obj.id, this.obj);
            uploadFlatGroundObj(this.obj);

            // this.$emit('settingsUpdate');
        },
        updateGroundColor() {
            if (this.obj.id === null) return;
            window["viewer"].updateFlatGroundMaterialSettings(this.obj.id,
                { material: this.obj.settings.embankmentSettings.color, },
                { material: this.obj.settings.cutSettings.color, }
            );
            uploadFlatGroundObj(this.obj)
        },
        updateFlatGroundTransparency() {
            if (this.obj.id === null) return;
            window["viewer"].updateFlatGroundMaterialSettings(
                this.obj.id,
                { transparency: this.obj.transparency },
                { transparency: this.obj.transparency }
            );
        },
        openPanel(id, obj) {
            switch (id) {
                case 11:
                    obj.panel.shelf = !obj.panel.shelf;
                    obj.panel.volume = false;
                    obj.panel.cutFlat = false;
                    obj.panel.flatGround = false;
                    break;
                case 12:
                    obj.panel.shelf = false;
                    obj.panel.volume = !obj.panel.volume;
                    obj.panel.cutFlat = false;
                    obj.panel.flatGround = false;
                    break;
                case 13:
                    obj.panel.shelf = false;
                    obj.panel.volume = false;
                    obj.panel.flatGround = false;
                    obj.panel.cutFlat = !obj.panel.cutFlat;
                    break;
                case 15:
                    obj.panel.flatGround = !obj.panel.flatGround;
                    obj.panel.shelf = false;
                    obj.panel.volume = false;
                    obj.panel.cutFlat = false;
                    break;
                default:
            }
        },
        async calculateFlatGroundVolume(obj) {
            obj.loadingVolume = true;
            window["viewer"].volumeCalculationForFlatGround(obj.id)
                .then(res => {
                    obj.cutVolume = res.cutVolume.toFixed(3);
                    obj.fillVolume = res.fillVolume.toFixed(3);
                })
                .catch((error) => {
                    console.error(error.message);
                })
                .finally(() => {
                    obj.loadingVolume = false;
                });
        },
    },
})
</script>