<template>
    <v-simple-table dense>
        <tbody>
            <tr>
                <td style="width: 50%;">
                    {{ $t("Elevationarea") }}
                </td>
                <td>
                    {{ computedHeightRange.min.toFixed(3) }}m -
                    {{ computedHeightRange.max.toFixed(3) }}m
                </td>
            </tr>
            <tr>
                <td>
                    {{ $t("Gradientarea") }}
                </td>
                <td>
                    <SlopeRangeComponent :min="computedSlopeRange.min" :max="computedSlopeRange.max" :suffix="suffix"></SlopeRangeComponent>
                </td>
            </tr>
            <tr>
                <td>{{ $t("Setsuperelevation") }}</td>
                <td>
                    <v-select v-model="slopestring" :items="slopeTypes"
                        item-text="label" item-value="value" dense hide-details :disabled="!isEditable" class="ma-0"
                        v-on:change="updateSegmentSettings"></v-select>
                </td>
            </tr>
            <tr>
                <td>
                    <div v-if="isSuperElevation()">
                        {{ $t("Superelevation") }}
                    </div>
                    <div v-else>
                        {{ $t("Crossslope") }}
                    </div>
                </td>
                <td>
                    <SlopeSettingInput 
                    :suffix-to-display="suffix"
                    :is-editable="isEditable"
                    :slope-value="slopeVal"
                    @input="updateSegmentSettings"
                    v-model.number="slopeVal"
                    ></SlopeSettingInput> 
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import SlopeRangeComponent from "./SlopeRangeComponent.vue"
import SlopeSettingInput from "./SlopeSettingInput.vue";
import {uploadRoadObj, updateRoadSettingsInViewer} from "../../utils/cesium-common"

const debounce = (func, wait = 1000) => {
  let timerId;
  return function(...args){
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};
export default {
    name: "IndividualSegmentSetting",
    components:{
        SlopeSettingInput,
        SlopeRangeComponent
    },
    props: {
        roadId: String,
        index: Number,       
        isEditable: Boolean,
        suffix: String,

        startIndex:Number,
        endIndex: Number,
        heightRange: { min: Number, max: Number},
        slopeRange: { min: Number, max: Number},
        slopeType:String,
        slope:Number,
    },
    mounted() {
        this.slopestring = this.slopeType;
        this.slopeVal = this.slope;
    },
    data: function () {
        return {
            slopestring: this.slopeType,
            slopeVal: this.slope,
            slopeTypes: [
                {
                    label: this.$t("Crossslope"),
                    value: "cross",
                },
                {
                    label: this.$t("Superelevation"),
                    value: "straight",
                },
            ]
        }
    },
    computed: {        
        computedHeightRange() {
            const range = window["viewer"].getRoadHeightInfoOfSection(this.roadId, this.startIndex, this.endIndex) || this.heightRange || {min: NaN, max: NaN};
            return range;
        },
        computedSlopeRange() {
            const range = window["viewer"].getRoadSlopeInfoOfSection(this.roadId, this.startIndex, this.endIndex) || this.slopeRange || {min: NaN, max: NaN};
            return range;
        }
    },
    methods: {
        isSuperElevation() {
            return this.slopestring === "straight" ? true : false;
        },
        closeDialog() {
            this.$emit("close", false);
        },
        updateSegmentSettings: debounce(function () {
            try {
                const roadUISettings = this.$store.state.objects.filter(road => road.id === this.roadId);
                if (roadUISettings && roadUISettings.length > 0) {
                    if (roadUISettings[0].settings.commonSettings.segmentSettings[this.index]) {
                        roadUISettings[0].settings.commonSettings.segmentSettings[this.index].slope = this.slopeVal;
                        roadUISettings[0].settings.commonSettings.segmentSettings[this.index].slopeType = this.slopestring;
                    }
                    // const d = JSON.parse(JSON.stringify(roadUISettings[0].settings));
                    updateRoadSettingsInViewer(this.roadId, roadUISettings[0]);
                    // window["viewer"].updateRoadSettings(this.roadId, d);
                uploadRoadObj(roadUISettings[0]);

                }
            } catch (e) {
                console.error(e);
            } finally {
                console.log("update done")
            }
        }, 1000),
    },
};
</script>
