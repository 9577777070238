import Vue from 'vue'
import Router from 'vue-router'
import ListPage from '../pages/ListPage'
import ProjectPage from '../pages/ProjectPage'
import versionUpdate from '../utils/versionUpdate';
import { getCookie } from '../utils/getCookie';

Vue.use(Router)

let router1 = new Router({
  mode: 'history',
  routes: [
    {
      path: '/top',
      component: ListPage
    },
    {
      name: 'project',
      path: '/',
      component: ProjectPage
    }
  ]
});

router1.beforeEach(async (to, from, next) => {
  const login_auth = getCookie('login_auth');
  
  // ブラウザ更新また別タブで立ち上げたときRefresh機能適用
  if (!login_auth || !window.name) {
    window.name = 'init';
    window.location.href = `${process.env.VUE_APP_API_BASE}/login`;
  } else {
    //ソースがサーバーと一致するかどうかを判断し、一致しなければ画面をリフレッシュして最新版を取得します
    versionUpdate.isNewVersion();
    if( to.path == "/" && !to.query.siteId ) {
      next("/top")
    } else {
      next()
    }
  }
})

export default router1;