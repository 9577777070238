import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'

const dict = require('./SCDesignDict.json')

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: store.state.lang || 'ja',
  messages: dict
})
