<template>
    <span>
        <v-card-subtitle class="py-0 pt-3">
            {{ $t("NOT_STARTED") }}
        </v-card-subtitle>
        <div style="max-height:110px; overflow:auto; scroll-behavior: smooth;" class="mx-3 my-3" id="point_cloud_list">
            <v-card-text v-if="point_cloud_list.length == 0" class="pa-0 pl-1">
                {{ $t("NO_DATA") }}
            </v-card-text>
            <draggable handle=".handle" animation="553" v-model="point_cloud_list_draggable">
                <v-card-text
                    v-for="(entity) in point_cloud_list"
                    :key="entity.getId()"
                    class="pa-0"
                >
                    <point-cloud-list-item 
                        :entity="entity" 
                        :draggable="true" 
                    ></point-cloud-list-item>
                </v-card-text>
            </draggable>
        </div>
    </span>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import draggable from "vuedraggable";
    import PointCloudListItem from "./PointCloudListItem.vue";

    export default {
        components: {
            draggable,
            PointCloudListItem 
        },
        name: "PointCloudList",
        computed:{
            ...mapState(['point_cloud_list']),
            point_cloud_list_draggable: {
                get() {
                    return this.point_cloud_list;
                },
                set(value) {
                    this.set_point_cloud_list(value);
                }
            },
        },
        methods: {
            ...mapMutations(['set_point_cloud_list']),
        }
    };
</script>