import axios from 'axios'

const isNewVersion = () => {
  let url = `${process.env.VUE_APP_API_BASE}/version.json?t=${new Date().getTime()}`;
  axios.get(url).then(res => {
    if (res.status === 200) {
      let version = res.data.version;
      let localVersion = localStorage.getItem('version');
      if (localVersion && localVersion != version) {
        localStorage.setItem('version', version);
        window.location.reload();
        return;
      } else {
        localStorage.setItem('version', version);
      }
    }
  });
}

export default {
  isNewVersion
}