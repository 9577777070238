<template>
  <v-card>
    <v-card-text id="sectionView" tile style="width: 100%; height: 240px" class="pa-0" @resize="onresize()"
      ref="section_view"></v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SectionViewDialog",
  props: {
    id: String,
  },

  mounted() {
    this.$nextTick(function () {
      this.update();
      this.onScreenResize();
    })
  },
  data: () => ({}),
  methods: {
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.update();
      });
    },
    update() {
      const canvas =
        window["viewer"] && window["viewer"].getCanvasForView("sectionView");
      const element = this.$refs.section_view;
      if (canvas && element) {
        for (const node of element.children) {
          element.removeChild(node);
        }
        element.appendChild(canvas);
        canvas.height = element.getBoundingClientRect().height;
        canvas.width = element.getBoundingClientRect().width;
        window["viewer"].resize2dView(
          { width: canvas.width, height: canvas.height },
          "sectionView"
        );
      }
    }
  },
  onresize: () => {
    console.log("Resize from Section");
    this.update();
  },
};
</script>
