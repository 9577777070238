<template>
    <v-card color="rgba(41, 41, 47, 0.8)">
        <v-card-title> {{ $t("SegmentSetting") }} </v-card-title>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr key="start-point">
                            <td style="width: 50%">{{ $t("SegmentSelectionStartPointIndex") }}</td>
                            <td>
                                <v-select v-model="startValue" :items="startlist" hide-details class="ma-0"
                                    @change="onStartValueChange">
                                </v-select>
                            </td>
                        </tr>
                        <tr key="end-point">
                            <td>{{ $t("SegmentSelectionEndPointIndex") }}</td>
                            <td>
                                <v-select v-model="endValue" :items="endlist" hide-details class="ma-0"
                                    @change="onEndValueChange">
                                </v-select>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-row style="margin: 0;">
            <v-col>
                <v-card-actions class="justify-end">
                    <v-btn @click="closeDialog" color="red">
                        {{ $t("CANCEL") }}
                    </v-btn>
                    <v-btn @click="setSegment" color="primary" :disabled="!isValid">
                        {{ $t("ADD") }}
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import {uploadRoadObj, updateRoadSettingsInViewer} from "../../utils/cesium-common"
// import axios from "axios";

export default {
    name: "SegmentSelectionDialog",
    props: {
        id: String,
        startIndex: Number,
        pointsCount: Number,
    },
    watch: {
        startIndex(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.startValue = newVal;
                this.endValue = this.maxPossibleIndex;
            }
        },
        pointsCount(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.endValue = newVal - 1;
            }
        },
    },
    data: function () {
        return {
            startValue: this.startIndex,
            endValue: this.maxPossibleIndex,
            isValid: true,
        }
    },
    computed: {
        startlist: function () {
            return [this.startValue];
        },
        endlist: function () {
            return new Array(this.maxPossibleIndex - this.startValue).fill(-1).map((_v, i) => this.maxPossibleIndex - i)
        },
        maxPossibleIndex: function () {
            const roadUISettings = this.$store.state.objects.filter(road => road.id === this.id);
            let maxIndex = this.pointsCount - 1;
            if (roadUISettings[0].settings.commonSettings.segmentSettings) {
                for (const seg of roadUISettings[0].settings.commonSettings.segmentSettings) {
                    if (this.startValue >= seg.startIndex) {
                        if (this.startValue < seg.endIndex) {
                            maxIndex = this.startValue;
                            break;
                        }
                        continue;
                    }
                    else {
                        maxIndex = Math.min(maxIndex, seg.startIndex);
                    }
                }
            }
            return maxIndex;
        }
    },
    methods: {
        onStartValueChange(value) {
            this.startValue = value;
            this.isValid = this.startValue < this.endValue ? true : false;
        },
        onEndValueChange(value) {
            this.endValue = value;
            this.isValid = this.endValue > this.startValue ? true : false;
        },
        async setSegment() {
            if (this.endValue > this.startValue) {
                try {
                    const roadUISettings = this.$store.state.objects.filter(road => road.id === this.id);

                    if (roadUISettings && roadUISettings.length > 0) {
                        if (!roadUISettings[0].settings.commonSettings.segmentSettings) {
                            roadUISettings[0].settings.commonSettings["segmentSettings"] = []
                        }
                        let heightRange = { min: NaN, max: NaN };
                        let slopeRange = { min: NaN, max: NaN };

                        roadUISettings[0].settings.commonSettings.segmentSettings.push({
                            slopeType: roadUISettings[0].settings.commonSettings.isSuperElevation ? "straight" : "cross",
                            slope: roadUISettings[0].settings.commonSettings.leftSlope,
                            startIndex: this.startValue,
                            endIndex: this.endValue,
                            heightRange,
                            slopeRange
                        });

                        //sort
                        roadUISettings[0].settings.commonSettings.segmentSettings.sort((s1, s2) => s1.startIndex - s2.startIndex);

                        // const d = JSON.parse(JSON.stringify(roadUISettings[0].settings));
                        updateRoadSettingsInViewer(this.id, roadUISettings[0]);
                        // window["viewer"].updateRoadSettings(this.id, d);
                        const tempHeightRange = window["viewer"].getRoadHeightInfoOfSection(this.id, this.sectionStartIndex, this.sectionEndIndex) || { min: NaN, max: NaN };

                        const tempSlopeRange = window["viewer"].getRoadSlopeInfoOfSection(this.id, this.sectionStartIndex, this.sectionEndIndex) || { min: NaN, max: NaN };
                        heightRange.min = tempHeightRange.min;
                        heightRange.max = tempHeightRange.max;
                        slopeRange.min = tempSlopeRange.min;
                        slopeRange.max = tempSlopeRange.max;

                uploadRoadObj(roadUISettings[0]);

                    }

                } catch (e) {
                    console.error(e);
                } finally {
                    // オブジェクトリストの更新
                    await this.$store.dispatch('get_obj_list', this.$route.query.siteId);
                    this.$emit("close", false);
                }
            }
            else {
                this.$emit("close", false);
            }
        },
        closeDialog() {
            this.$emit("close", false);
        },

    },

};
</script>
