<template>
    <v-expand-transition>
        <div v-show="road.isOpen">
            <v-form v-model="isValid">
                <v-card-text class="py-0">
                    <!-- 道路・摺り付け -->
                    <v-card-actions>
                        {{ $t("Roadparameter") }}
                        <v-btn icon @click="openPanel(0, road)" absolute right>
                            <v-icon>
                                {{
                                    road.panel.road
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="road.panel.road">
                            <v-simple-table dense>
                                <tbody>
                                    <!-- 道路長さ -->
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("Lengthofroad") }}
                                        </td>
                                        <td>{{ road.info.length.toFixed(3) }}m</td>
                                    </tr>
                                    <!-- 道路幅左 -->
                                    <tr>
                                        <td>{{ $t("Widthofroad") }} {{ $t("Left") }}</td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :disabled="!road.edit" @blur="updateSettings()"
                                                v-model.number="road.settings.commonSettings.leftWidth" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" />
                                        </td>
                                    </tr>
                                    <!-- 道路幅右 -->
                                    <tr>
                                        <td>
                                            <span style="opacity: 0">{{
                                                $t("Widthofroad")
                                            }}</span>
                                            {{ $t("Right") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :disabled="!road.edit" @blur="updateSettings()"
                                                v-model.number="road.settings.commonSettings.rightWidth" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" />
                                        </td>
                                    </tr>
                                    <!-- 道路範囲 -->
                                    <tr>
                                        <td>
                                            {{ $t("Elevationarea") }}
                                        </td>
                                        <td>
                                            {{ road.info.height.min.toFixed(3) }}m -
                                            {{ road.info.height.max.toFixed(3) }}m
                                        </td>
                                    </tr>
                                    <!-- 勾配範囲 -->
                                    <tr>
                                        <td>
                                            {{ $t("Gradientarea") }}
                                        </td>
                                        <td>
                                            <SlopeRangeComponent :min="road.info.slope.min" :max="road.info.slope.max"
                                                :suffix="getSuffix(roadParameterSlopeUnit.gradientArea)">
                                            </SlopeRangeComponent>
                                        </td>
                                    </tr>
                                    <!-- 片勾配にする -->
                                    <tr>
                                        <td>{{ $t("Setsuperelevation") }}</td>
                                        <td>
                                            <v-select v-model="road.settings.commonSettings.isSuperElevation"
                                                :items="slopeTypes" item-text="label" item-value="value" dense hide-details
                                                :disabled="!road.edit" class="ma-0"
                                                v-on:change="updateSettings()"></v-select>
                                        </td>
                                    </tr>
                                    <!-- 片面勾配 -->
                                    <tr v-if="road.settings.commonSettings.isSuperElevation">
                                        <td>
                                            {{ $t("Superelevation") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(roadParameterSlopeUnit.superElevation)"
                                                :is-editable="road.edit"
                                                :slope-value="road.settings.commonSettings.leftSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="road.settings.commonSettings.leftSlope"
                                                :max-value="roadParameterSlopeUnit.superElevation === 'percent' ? 9999.99 : 999.99"
                                                :min-value="roadParameterSlopeUnit.superElevation === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="roadParameterSlopeUnit.superElevation === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 横断勾配 -->
                                    <tr v-else>
                                        <td>
                                            {{ $t("Crossslope") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(roadParameterSlopeUnit.crossSlope)"
                                                :is-editable="road.edit"
                                                :slope-value="road.settings.commonSettings.leftSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="road.settings.commonSettings.leftSlope"
                                                :max-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 9999.99 : 999.99"
                                                :min-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="roadParameterSlopeUnit.crossSlope === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 摺り付けピッチ -->
                                    <tr>
                                        <td>
                                            {{ $t("InterpolationPitch") }}
                                            <span class="red--text text--lighten-3">*</span>
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                value="5" :disabled="!road.edit" @blur="updateSettings()"
                                                v-model.number="road.settings.commonSettings.interpolationPitch" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" />
                                        </td>
                                    </tr>
                                    <!-- 路面色 -->
                                    <tr>
                                        <td>
                                            {{ $t("Roadsurfacecolor") }}
                                        </td>
                                        <td>
                                            <template>
                                                <v-menu offset-y :disabled="!road.edit">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar :color="road.settings.commonSettings.surfaceColor"
                                                            size="20px" v-bind="attrs" v-on="on">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" @input="updateRoadColor()"
                                                        v-model="road.settings.commonSettings.surfaceColor"></v-color-picker>
                                                </v-menu>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <IndividualSegmentSettingList v-show="road.settings.commonSettings.segmentSettings.length > 0"
                                style="width: 100%;" :roadId="road.id" :is-editable="road.edit"
                                :suffix="getSuffix(roadParameterSlopeUnit.gradientArea)"
                                :setting-list="road.settings.commonSettings.segmentSettings"></IndividualSegmentSettingList>
                        </div>
                    </v-expand-transition>
                    <!-- 切土設定 -->
                    <v-card-actions>
                        {{ $t("Cutsetting") }}
                        <v-btn icon @click="openPanel(1, road)" absolute right>
                            <v-icon>
                                {{
                                    road.panel.cut
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="road.panel.cut">
                            <v-simple-table dense>
                                <tbody>
                                    <!-- 法面高さ -->
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("Slopeheight") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :disabled="cutSlopeCondition(road)" :rules="[
                                                    rules.required,
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" v-model.number="road.settings.cutSettings.roadHeight"
                                                @blur="updateSettings()" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <!-- 法面勾配 -->
                                        <td>
                                            {{ $t("Slopegradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                                :is-editable="road.edit" :slope-value="road.settings.cutSettings.moundSlope"
                                                :step="0.01" @blur="updateSettings()"
                                                v-model.number="road.settings.cutSettings.moundSlope"
                                                :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 小段幅 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermwidth") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :disabled="cutSlopeCondition(road)" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" v-model.number="road.settings.cutSettings.shelfWidth"
                                                @blur="updateSettings()" />
                                        </td>
                                    </tr>
                                    <!-- 小段勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermgradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                                :is-editable="!cutSlopeCondition(road)"
                                                :slope-value="road.settings.cutSettings.shelfSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="road.settings.cutSettings.shelfSlope"
                                                :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("LeftRightSettings") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details dense class="ma-0" :disabled="!road.edit"
                                                @click="updateSettings()"
                                                v-model="road.settings.cutSettings.bLeftRightSettings"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 小段あり -->
                                    <tr>
                                        <td>
                                            {{ $t("Thereisberm") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details dense class="ma-0" :disabled="!road.edit"
                                                @click="updateSettings()"
                                                v-model="road.settings.cutSettings.generateShelf"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 道路色 -->
                                    <tr>
                                        <td>
                                            {{ $t("Roadcolor") }}
                                        </td>
                                        <td>
                                            <template>
                                                <v-menu offset-y :disabled="!road.edit">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar :color="road.settings.cutSettings.color" size="20px"
                                                            v-bind="attrs" v-on="on">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" @input="updateRoadColor()"
                                                        v-model="road.settings.cutSettings.color"></v-color-picker>
                                                </v-menu>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <!-- 左右設定 -->
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="py-0 px-2">
                                        {{ $t("Leftrightsetting") }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="pa-0">
                                        <v-row class="pa-0">
                                            <v-col class="pa-0">
                                                {{ $t("Leftcutsetting") }}
                                                <v-simple-table dense>
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td style="width: 50%">
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition(road)" :rules="[
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]"
                                                                    v-model.number="road.settings.cutSettings.leftSlopeHeight"
                                                                    @blur="updateSettings()" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(road.edit && road.settings.cutSettings.bLeftRightSettings)"
                                                                    :slope-value="road.settings.cutSettings.leftMoundSlope"
                                                                    :step="0.01" @blur="updateSettings()"
                                                                    v-model.number="road.settings.cutSettings.leftMoundSlope"
                                                                    :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition(road)" :rules="[
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]"
                                                                    v-model.number="road.settings.cutSettings.leftShelfWidth"
                                                                    @blur="updateSettings()" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!cutLeftRightCondition(road)"
                                                                    :slope-value="road.settings.cutSettings.leftShelfSlope"
                                                                    @blur="updateSettings()" :step="0.01"
                                                                    v-model.number="road.settings.cutSettings.leftShelfSlope"
                                                                    :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                                {{ $t("Rightcutsetting") }}
                                                <v-simple-table dense style="
                                      background-color: rgba(41, 41, 47, 0);
                                    ">
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td style="width: 50%">
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition(road)" :rules="[
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]"
                                                                    v-model.number="road.settings.cutSettings.rightSlopeHeight"
                                                                    @blur="updateSettings()" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(road.edit && road.settings.cutSettings.bLeftRightSettings)"
                                                                    :slope-value="road.settings.cutSettings.rightMoundSlope"
                                                                    :step="0.01" @blur="updateSettings()"
                                                                    v-model.number="road.settings.cutSettings.rightMoundSlope"
                                                                    :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition(road)" :rules="[
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]"
                                                                    v-model.number="road.settings.cutSettings.rightShelfWidth"
                                                                    @blur="updateSettings()" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!cutLeftRightCondition(road)"
                                                                    :slope-value="road.settings.cutSettings.rightShelfSlope"
                                                                    @blur="updateSettings()" :step="0.01"
                                                                    v-model.number="road.settings.cutSettings.rightShelfSlope"
                                                                    :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </v-expand-transition>
                    <!-- 盛土設定 -->
                    <v-card-actions>
                        {{ $t("Fillsetting") }}
                        <v-btn icon @click="openPanel(2, road)" absolute right>
                            <v-icon>
                                {{
                                    road.panel.fill
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="road.panel.fill">
                            <v-simple-table dense>
                                <tbody>
                                    <!-- 法面高さ -->
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("Slopeheight") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :disabled="embSlopeCondition(road)" :rules="[
                                                    rules.required,
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" v-model.number="road.settings.embarkmentSettings.roadHeight"
                                                @blur="updateSettings()" />
                                        </td>
                                    </tr>
                                    <!-- 法面勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Slopegradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                                :is-editable="!(!road.edit || road.settings.embarkmentSettings.bLeftRightSettings)"
                                                :slope-value="road.settings.embarkmentSettings.moundSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="road.settings.embarkmentSettings.moundSlope"
                                                :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 小段幅 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermwidth") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.01" suffix="m"
                                                :disabled="embSlopeCondition(road)" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" v-model.number="road.settings.embarkmentSettings.shelfWidth"
                                                @blur="updateSettings()" />
                                        </td>
                                    </tr>
                                    <!-- 小段勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermgradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                                :is-editable="!embSlopeCondition(road)"
                                                :slope-value="road.settings.embarkmentSettings.shelfSlope" :step="0.01"
                                                @blur="updateSettings()"
                                                v-model.number="road.settings.embarkmentSettings.shelfSlope"
                                                :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                :decimal-length="2" :un-valid-zero="unit === 'percent'"></SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("LeftRightSettings") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details dense class="ma-0" :disabled="!road.edit"
                                                @click="updateSettings()"
                                                v-model="road.settings.embarkmentSettings.bLeftRightSettings"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 小段あり -->
                                    <tr>
                                        <td>
                                            {{ $t("Thereisberm") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details dense class="ma-0" :disabled="!road.edit"
                                                @click="updateSettings()"
                                                v-model="road.settings.embarkmentSettings.generateShelf"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 道路色 -->
                                    <tr>
                                        <td>
                                            {{ $t("Roadcolor") }}
                                        </td>
                                        <td>
                                            <template>
                                                <v-menu offset-y :disabled="!road.edit">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar :color="road.settings.embarkmentSettings.color"
                                                            size="20px" v-bind="attrs" v-on="on">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" @input="updateRoadColor(road)"
                                                        v-model="road.settings.embarkmentSettings.color"></v-color-picker>
                                                </v-menu>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <!-- 左右設定 -->
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="py-0 px-2">
                                        {{ $t("Leftrightsetting") }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="pa-0">
                                        <v-row class="pa-0">
                                            <v-col class="pa-0">
                                                {{ $t("Leftfillsetting") }}
                                                <v-simple-table dense>
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td style="width: 50%">
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m" :rules="[
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]" :disabled="embLeftRightCondition(road)"
                                                                    @blur="updateSettings()"
                                                                    v-model.number="road.settings.embarkmentSettings.leftSlopeHeight" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(road.edit && road.settings.embarkmentSettings.bLeftRightSettings)"
                                                                    :slope-value="road.settings.embarkmentSettings.leftMoundSlope"
                                                                    :step="0.01" @blur="updateSettings()"
                                                                    v-model.number="road.settings.embarkmentSettings.leftMoundSlope"
                                                                    :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="embLeftRightCondition(road)" :rules="[
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]"
                                                                    v-model.number="road.settings.embarkmentSettings.leftShelfWidth"
                                                                    @blur="updateSettings()" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!embLeftRightCondition(road)"
                                                                    :slope-value="road.settings.embarkmentSettings.leftShelfSlope"
                                                                    :step="0.01" @blur="updateSettings()"
                                                                    v-model.number="road.settings.embarkmentSettings.leftShelfSlope"
                                                                    :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                                {{ $t("Rightfillsetting") }}
                                                <v-simple-table dense style="background-color: rgba(41, 41, 47, 0);">
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td style="width: 50%">
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m" :rules="[
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]" :disabled="embLeftRightCondition(road)"
                                                                    @blur="updateSettings()"
                                                                    v-model.number="road.settings.embarkmentSettings.rightSlopeHeight" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(road.edit && road.settings.embarkmentSettings.bLeftRightSettings)"
                                                                    :slope-value="road.settings.embarkmentSettings.rightMoundSlope"
                                                                    :step="0.01" @blur="updateSettings()"
                                                                    v-model.number="road.settings.embarkmentSettings.rightMoundSlope"
                                                                    :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field hide-details dense type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="embLeftRightCondition(road)" :rules="[
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                    ]"
                                                                    v-model.number="road.settings.embarkmentSettings.rightShelfWidth"
                                                                    @blur="updateSettings()" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!embLeftRightCondition(road)"
                                                                    :slope-value="road.settings.embarkmentSettings.rightShelfSlope"
                                                                    :step="0.01" @blur="updateSettings()"
                                                                    v-model.number="road.settings.embarkmentSettings.rightShelfSlope"
                                                                    :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </v-expand-transition>
                    <!-- 土量 -->
                    <v-card-actions v-show="config.useSoilVolumeCalculation">
                        {{ $t("Soilvolume") }}
                        <v-btn icon :disabled="!road.edit" @click="calculateRoadVolume(road)" class="ml-3"
                            title="ボリュームを計算する" :loading="road.loadingVolume">
                            <v-icon>
                                mdi-play-circle-outline
                            </v-icon>
                        </v-btn>
                        <v-btn icon @click="openPanel(3, road)" absolute right>
                            <v-icon>
                                {{
                                    road.panel.volume
                                    ? "icon-Chevron-Up"
                                    : "icon-Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="road.panel.volume">
                            <v-simple-table dense>
                                <tbody>
                                    <tr>
                                        <td style="width: 50%">
                                            {{ $t("Cut") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.1"
                                                :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="road.cutVolume" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("FILL") }}
                                        </td>
                                        <td>
                                            <v-text-field hide-details dense type="number" min="0" step="0.1"
                                                :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="road.fillVolume" />
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-expand-transition>
                    <v-card-actions class="py-0 mr-3">
                        {{ $t("OPACITY") }}
                        <v-slider @input="updateRoadTransparency()" v-model="road.transparency" :disabled="!road.edit"
                        hide-details max="1" step="0.01" class="ml-1"></v-slider>
                </v-card-actions>
            </v-card-text>
        </v-form>

    </div>
</v-expand-transition></template>

<script>
import "@/assets/icons/style.css";
import config from "@/config.json";
import SlopeSettingInput from "../SlopeSettingInput.vue";
import IndividualSegmentSettingList from "../IndividualSegmentSettingList.vue";
import SlopeRangeComponent from "../SlopeRangeComponent.vue";
import {uploadRoadObj, updateRoadSettingsInViewer} from "../../../utils/cesium-common";
import { UnitTypes } from "../../../constant";

export default ({
    name: "RoadPanel",
    components: {
        SlopeSettingInput,
        IndividualSegmentSettingList,
        SlopeRangeComponent,
    },
    props: {
        road: Object,
        rules: Object,
    },
    computed: {
        roadParameterSlopeUnit() {
            return this.$store.state.roadParameterSlopeUnit;
        },
        cutSettingSlopeUnit() {
            return this.$store.state.cutSettingSlopeUnit;
        },
        fillSettingSlopeUnit() {
            return this.$store.state.fillSettingSlopeUnit;
        },
        slopeTypes() {
            return [
                {
                    label: this.$t("Crossslope"),
                    value: false,
                },
                {
                    label: this.$t("Superelevation"),
                    value: true,
                },
            ];
        },
    },
    data: () => ({
        config,
        isValid: false,
    }),
    methods: {
        getSuffix(unitType) {
            const unit = UnitTypes.find(type => type.value === unitType);
            return unit.symbol;
        },
        isNum(val) {
            return typeof val == "number";
        },
        updateSettings() {
            if (!this.isValid) return;
            // window["viewer"].updateRoadSettings(this.road.id, roadData.roadSettings);
            updateRoadSettingsInViewer(this.road.id, this.road);
            uploadRoadObj(this.road);
        },
        updateRoadColor() {
            console.log("Road", this.road);
            // this.$emit('groundColorUpdate');
            if (this.road.id === null) return;
            window["viewer"].updateRoadMaterialSettings(
                this.road.id,
                { surfaceColor: this.road.settings.commonSettings.surfaceColor },
                { color: this.road.settings.embarkmentSettings.color },
                { color: this.road.settings.cutSettings.color }
            );
            uploadRoadObj(this.road);
        },
        updateRoadTransparency() {
            if (this.road.id === null) return;
            window["viewer"].updateRoadMaterialSettings(
                this.road.id,
                {},
                { transparency: this.road.transparency },
                { transparency: this.road.transparency }
            );
        },
        openPanel(id, obj) {
            switch (id) {
                case 0:
                    obj.panel.road = !obj.panel.road;
                    obj.panel.cut = false;
                    obj.panel.fill = false;
                    obj.panel.volume = false;
                    break;
                case 1:
                    obj.panel.road = false;
                    obj.panel.cut = !obj.panel.cut;
                    obj.panel.fill = false;
                    obj.panel.volume = false;
                    break;
                case 2:
                    obj.panel.road = false;
                    obj.panel.cut = false;
                    obj.panel.fill = !obj.panel.fill;
                    obj.panel.volume = false;
                    break;
                case 3:
                    obj.panel.road = false;
                    obj.panel.cut = false;
                    obj.panel.fill = false;
                    obj.panel.volume = !obj.panel.volume;
                    break;
                default:
            }
        },
        async calculateRoadVolume(obj) {
            obj.loadingVolume = true;
            window["viewer"].volumeCalculationForRoad(obj.id)
                .then(res => {
                    obj.cutVolume = res.cutVolume.toFixed(3);
                    obj.fillVolume = res.fillVolume.toFixed(3);
                })
                .catch((error) => {
                    console.error(error.message);
                })
                .finally(() => {
                    obj.loadingVolume = false;
                    this.$forceUpdate();                    
                });
                this.$forceUpdate();
        },
        unit() {
            return this.$store.state.slopeUnit
        },
        // Disabling condition for cut setting's - shelfSlope, shelfWidth
        cutSlopeCondition(obj) {
            return !obj.edit || (obj.settings.cutSettings.bLeftRightSettings || !obj.settings.cutSettings.generateShelf);
        },
        // Disabling condition for cut setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
        cutLeftRightCondition(obj) {
            return !(obj.edit && obj.settings.cutSettings.bLeftRightSettings && obj.settings.cutSettings.generateShelf);
        },
        // Disabling condition for embankment setting's - shelfSlope, shelfWidth
        embSlopeCondition(obj) {
            return !obj.edit || (obj.settings.embarkmentSettings.bLeftRightSettings || !obj.settings.embarkmentSettings.generateShelf);
        },
        // Disabling condition for embankment setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
        embLeftRightCondition(obj) {
            return !(obj.edit && obj.settings.embarkmentSettings.bLeftRightSettings && obj.settings.embarkmentSettings.generateShelf);
        },
    },
})
</script>