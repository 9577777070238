import Vue from 'vue';
import Vuex from 'vuex';
import * as objectFunction from './modules/object-function';
import addRoadTerrainByAi from './modules/add-road-terrain-by-ai';
import axios from 'axios';
import PointCloud from '@/models/PointCloud';
import Design from '@/models/Design';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        panelName:'',
        authObject: {
            username: '2022scdesign',
            password: 'passscdesign'
        },
        site: null,
        point_cloud_list: [],
        design_list: [],
        axis: "",
        user: null,
        lang: 'ja',
        roadParameterSlopeUnit: { // 勾配単位（道路・摺り付け）
          gradientArea: 'percent', // 勾配範囲
          crossSlope: 'percent', // 横断勾配
          superElevation: 'percent' // 片勾配
        },
        cutSettingSlopeUnit: { // 勾配単位（切土設定）
          slopeGradient: 'ratio', // 法面勾配
          bermGradient: 'percent' // 小段勾配
        },
        fillSettingSlopeUnit: { // 勾配単位（盛土設定）
          slopeGradient: 'ratio', // 法面勾配
          bermGradient: 'percent' // 小段勾配
        },
        longitudinalSectionViewerSlopeUnit: { // 勾配単位（縦断ビュー）
          nextGradient: 'percent' // 次地点までの勾配
        },
        crossSectionViewerSlopeUnit: { // 勾配単位（断面ビュー）
          slopeGradient: 'ratio', // 法面勾配
          bermGradient: 'percent' // 小段勾配
        },
        propertyDialog: false,
        modes: {
            inCreationMode: false,
            inEditMode: false,
            inFlatGroundHoriDrag: true,
            ai_road_point_selection: false 
        },
        objects: [],
        snackbars: [],
        isLoading:  false,
        ai_road_preview_data:{
            x1:undefined,
            y1:undefined,
            z1:undefined,
            x2:undefined,
            y2:undefined,
            z2:undefined,
            startPointHeightAbovePointCloud :undefined,
            startPointHeightAboveDesignData: undefined,
            endPointHeightAbovePointCloud: undefined,
            endPointHeightAboveDesignData: undefined
        },
        initial_site_data: {},
        isExclusion: false,
        viewer: undefined
    },
    mutations: {
        set_viewer(state, viewer) {
            state.viewer = viewer;
        },
        change_panel(state,panelName){
            state.panelName = panelName
        },
        set_modes(state,name_value){
            state.modes[name_value.name]=name_value.value;
        },
        set_ai_road_preview_data(state,obj){
            state.ai_road_preview_data.x1 = obj.x1 ;
            state.ai_road_preview_data.y1 = obj.y1 ;
            state.ai_road_preview_data.z1 = obj.z1 ;
            state.ai_road_preview_data.x2 = obj.x2 ;
            state.ai_road_preview_data.y2 = obj.y2 ;
            state.ai_road_preview_data.z2 = obj.z2 ;
            state.ai_road_preview_data.startPointHeightAbovePointCloud = obj.startPointHeightAbovePointCloud;
            state.ai_road_preview_data.startPointHeightAboveDesignData = obj.startPointHeightAboveDesignData;
            state.ai_road_preview_data.endPointHeightAbovePointCloud = obj.endPointHeightAbovePointCloud;
            state.ai_road_preview_data.endPointHeightAboveDesignData = obj.endPointHeightAboveDesignData;
        },
        set_ai_road_preview_data_property(state,property) {
            state.ai_road_preview_data[property.name] = property.value;
        },
        reset_ai_road_preview_data(state) {
            state.ai_road_preview_data = {
                x1:undefined,
                y1:undefined,
                z1:undefined,
                x2:undefined,
                y2:undefined,
                z2:undefined,
                startPointHeightAbovePointCloud :undefined,
                startPointHeightAboveDesignData: undefined,
                endPointHeightAbovePointCloud: undefined,
                endPointHeightAboveDesignData: undefined
            }
        },
        setSite(state, site) {
            state.site = site
        },
        set_point_cloud_list(state, point_cloud_list) {
            state.point_cloud_list = point_cloud_list;
        },
        set_point_cloud_loading(state, point_cloud_id) {
            const index = state.point_cloud_list.findIndex(item => item.point_cloud_id == point_cloud_id);
            Vue.set(state.point_cloud_list[index], "loading", true);
        },
        delete_point_cloud(state, point_cloud_id) {
            const index = state.point_cloud_list.findIndex(item => item.point_cloud_id == point_cloud_id);
            state.point_cloud_list.splice(index, 1);
        },
        set_design_list(state, design_list) {
            state.design_list = design_list;
        },
        set_design_loading(state, design_id) {
            const index = state.design_list.findIndex(item => item.design_id == design_id);
            Vue.set(state.design_list[index], "loading", true);
        },
        delete_design(state, design_id) {
            const index = state.design_list.findIndex(item => item.design_id == design_id);
            state.design_list.splice(index, 1);
        },
        setUser(state, user) {
            state.user = user
            this.commit('setLang')
        },
        setLang(state) {
            if (state.user.language === 'en-US') {
                state.lang = 'en'
            } else {
                state.lang = state.user.language
            }
        },
        togglePropertyDialog() {
            this.state.propertyDialog = !this.state.propertyDialog
        },
        toggleRoadParameterSlopeUnit(state,property) {
          for (const key of Object.keys(property)) {
            state.roadParameterSlopeUnit [key] = property[key];
          }
        },
        toggleCutSettingSlopeUnit(state,property) {
          for (const key of Object.keys(property)) {
            state.cutSettingSlopeUnit [key] = property[key];
          }
        },
        toggleFillSettingSlopeUnit(state, property) {
          for (const key of Object.keys(property)) {
            state.fillSettingSlopeUnit[key] = property[key];
          }
        },
        initObj(state) {
            state.objects = []
        },
        setObj(state, objects) {
            state.objects = objects;
        },
        set_snackbar(state, snackbar) {
            snackbar.showing = true;
            snackbar.id = "notification_"+Date.now();
            state.snackbars.push(snackbar);
        },
        clear_snackbars(state) {
            state.snackbars = state.snackbars.filter(snackbar => { return snackbar.showing == true; });
        },
        updateIsLoading(state, isLoading) {
          state.isLoading = isLoading;
        },
        set_initial_site_datas(state, ret) {
            state.initial_site_data = ret;
        },
        set_exclusion(state, flag) {
            state.isExclusion = flag;
        }
    },
    actions: {
        changePanel(context,panelName){
            context.commit('change_panel',panelName)
        },
        getUser() {
            axios.get(`${process.env.VUE_APP_API_BASE}/user/me`, {
                auth: this.state.authObject,
                withCredentials: true
            }).then((res) => {
                this.commit('setUser', res.data)
            })
        },
        async get_point_cloud_list({ state, commit }, site_id) {
            const pointCloudList = [];
            let res = await axios.get(
                `${process.env.VUE_APP_API_BASE}/pointcloud/${site_id}`,
                {
                    auth: this.state.authObject
                }
            )
            for (const pointCloud of res.data) {
              const currentPointCloud = state.point_cloud_list.find(
                entity => entity.getId() === pointCloud.point_cloud_id
                );
              // すでにリストに存在する場合は更新。そうでない場合は新規追加
              if (currentPointCloud) {
                currentPointCloud.update(pointCloud);
                pointCloudList.push(currentPointCloud);
              } else {
                const newPointCloud 
                  = new PointCloud (
                      pointCloud.point_cloud_id,
                      pointCloud.point_cloud_name,
                      pointCloud.asset_id,
                      pointCloud.processing_status,
                      pointCloud.processing_error,
                      pointCloud.create_at,
                      pointCloud.updated_at,
                      pointCloud.selected_date,
                      pointCloud.selected_time
                    );
                pointCloudList.push(newPointCloud);
              }
            }
            commit('set_point_cloud_list', pointCloudList);
        },
        // async get_point_cloud_data(_, point_cloud) {
        //     let response = await axios.get(
        //         `${process.env.VUE_APP_API_BASE}/pointcloud/data/${point_cloud.point_cloud_id}`,
        //         {
        //             auth: this.state.authObject,
        //         }
        //     );
        //     let offset = 0;
        //     try{          
        //       const localizedPoint = await convertCoordinateToPointCloudCrs({x:response.data[0],y:response.data[1],z:response.data[2]});
        //       if(localizedPoint.z !== localizedPoint.z){
        //         throw "conversion error";
        //       }
        //       const cesiumAltitude = window["viewer"].getAltitude({x:response.data[0],y:response.data[1],z:response.data[2]});
        //       offset = cesiumAltitude - localizedPoint.z; //await calculateVerticalOffsetForPointCloud({x:response.data[0],y:response.data[1],z:response.data[2]});
        //     }
        //     catch(e){
        //       console.error(e);
        //     }
        //     return window["viewer"].addTileset(point_cloud.asset_id, "pointcloud", response.data, offset);
        // },
        async delete_point_cloud({ commit }, { point_cloud_id, asset_id }) {
            commit('set_point_cloud_loading', point_cloud_id);
            await axios
                .delete(
                    `${process.env.VUE_APP_API_BASE}/pointcloud`,
                    {
                        data: {
                            point_cloud_id
                        }
                    }
                )
            window["viewer"].removeTileset(parseInt(asset_id), "pointcloud");
            commit('delete_point_cloud', point_cloud_id);
        },
        async get_design_list({ state, commit }, site_id) {
            let designList = [];
            let res = await axios.get(
                `${process.env.VUE_APP_API_BASE}/design/${site_id}`,
                {
                    auth: this.state.authObject
                }
            )
            for (const design of res.data) {
              const currentDesign = state.design_list.find(
                entity => entity.getId() === design.design_id
                );
              // すでにリストに存在する場合は更新。そうでない場合は新規追加
              if (currentDesign) {
                currentDesign.update({ ...design, properties: currentDesign.properties});
                designList.push(currentDesign);
              } else {
                const newDesign
                  = new Design (
                      design.design_id,
                      design.design_name,
                      design.asset_id,
                      design.processing_status,
                      design.processing_error,
                      design.create_at,
                      design.selected_date,
                      design.selected_time
                    );
                designList.push(newDesign);
              }
            }
            commit('set_design_list', designList);
        },
        // async get_design_data(_, design) {
        //     let response = await axios.get(
        //         `${process.env.VUE_APP_API_BASE}/design/data/${design.design_id}`,
        //         {
        //             auth: this.state.authObject,
        //         }
        //     );
        //     const localizedPoint = await convertCoordinateToPointCloudCrs({x:response.data.vertices[0],y:response.data.vertices[1],z:response.data.vertices[2]});
        //     const cesiumAltitude = window["viewer"].getAltitude({x:response.data.vertices[0],y:response.data.vertices[1],z:response.data.vertices[2]});
        //     const offset = cesiumAltitude - localizedPoint.z; //await calculateVerticalOffsetForPointCloud({x:response.data[0],y:response.data[1],z:response.data[2]});
        //     return window["viewer"].addTileset(design.asset_id, "design", response.data.vertices, offset, response.data.indices, { design: { mesh_color: hexToRgb(design.getMeshColor()) }, common: { transparency: design.getTransparency() } });
        // },
        async delete_design({ commit }, { design_id, asset_id }) {
            commit('set_design_loading', design_id);
            await axios
                .delete(
                    `${process.env.VUE_APP_API_BASE}/design`,
                    {
                        data: {
                            design_id
                        }
                    }
                )
            window["viewer"].removeTileset(parseInt(asset_id), "design");
            commit('delete_design', design_id);
        },
        async get_obj_list({ state, getters, commit }, site_id) {
          const objects = [];
          const response = await axios.get(
            `${process.env.VUE_APP_API_BASE}/obj/?siteId=${site_id}`,
            {
              auth: state.authObject
            }
          );
          for (const data of response.data) {
            const currentObj = state.objects.find(
              entity => entity.cid === data.props.cid
            );
            // リストに存在しない場合は新規追加
            if (currentObj) {
              objects.push(currentObj);
            } else {
              let obj = null;
              if (data.props.type === 0) {
                // 仮設道路オブジェクトの処理
                obj = JSON.parse(JSON.stringify(getters.defaultRoadProperty));
                objectFunction.copyRoadProps(obj, data.props);
                obj.json = typeof data.json === "object" ? JSON.stringify(data.json): data.json;
                // objectFunction.prepareTempRoadSlopesForRndering(obj);
              } else {
                // 平場オブジェクトの処理
                obj = JSON.parse(JSON.stringify(getters.defaultFlatGroundProperty));
                objectFunction.copyFlatProps(obj, data.props);
                obj.json = typeof data.json === "object" ? JSON.stringify(data.json): data.json;
                // objectFunction.prepareTempFlatSlopesForRndering(obj);   
              }
              objects.push(obj);
            }
          }
          commit('setObj', objects);
        },
        updateObj(_data, {json, props}) {

            // const objJSON = objProps.type == 0 ? window["viewer"].getRoadJSON(objProps.id) : window["viewer"].getFlatGroundJSON(objProps.id);
            axios.put(
                `${process.env.VUE_APP_API_BASE}/obj`,
                {
                    siteId: this.state.site.site_uuid,
                    objJSON: json,
                    objProps: props,
                },
                {
                    auth: this.state.authObject
                }
            ).then((response) => {
                console.log(response);
            });

        },
        toggleRoadParameterSlopeUnit({commit}, property) {
            commit('toggleRoadParameterSlopeUnit', property);
        },
        toggleCutSettingSlopeUnit({commit}, property) {
            commit('toggleCutSettingSlopeUnit', property);
        },
        toggleFillSettingSlopeUnit({commit}, property) {
            commit('toggleFillSettingSlopeUnit', property);
        },
        updateIsLoading({ commit }, isLoading) {
          commit("updateIsLoading", isLoading);
        },
    },
    getters: {
        lang: (state) => state.lang,
        defaultRoadProperty() {
            return {
                //Todo extract it as services
                id: null,
                cid: null, // cash id *first assigned id
                type: null,
                name: null,
                isShow: false,
                isOpen: false,
                edit: false,
                panel: {
                    road: false,
                    cut: false,
                    fill: false,
                    volume: false,
                },
                cutVolume: 0,
                fillVolume: 0,
                transparency: 1,
                info: {
                    length: 0,
                    height: null,
                    slope: null,
                },
                settings: {
                    commonSettings: {
                        leftWidth: 2.5,
                        rightWidth: 2.5,
                        leftSlope: 50,
                        rightSlope: 50,
                        isSuperElevation: false,
                        interpolationPitch: 5,
                        surfaceColor: "#DDDDDD",
                        segmentSettings:[]
                    },
                    cutSettings: {
                        roadHeight: 5,
                        moundSlope: 1,
                        shelfWidth: 1.5,
                        shelfSlope: 100,
                        bLeftRightSettings: false,
                        generateShelf: true,
                        leftSlopeHeight: 5,
                        leftMoundSlope: 1,
                        rightSlopeHeight: 5,
                        rightMoundSlope: 1,
                        leftShelfWidth: 1.5,
                        leftShelfSlope: 100,
                        rightShelfWidth: 1.5,
                        rightShelfSlope: 100,
                        color: "#FFA500",
                        outline: true
                    },
                    embarkmentSettings: {
                        roadHeight: 5,
                        moundSlope: 1.5,
                        shelfWidth: 1.5,
                        shelfSlope: 100,
                        bLeftRightSettings: false,
                        generateShelf: true,
                        leftSlopeHeight: 5,
                        leftMoundSlope: 1.5,
                        rightSlopeHeight: 5,
                        rightMoundSlope: 1.5,
                        leftShelfWidth: 1.5,
                        leftShelfSlope: 100,
                        rightShelfWidth: 1.5,
                        rightShelfSlope: 100,
                        color: "#008000",
                        outline: true
                    }
                },
                cash: {
                    leftShelfWidth: 2,
                    rightShelfWidth: 2,
                },
            };
        },
        defaultFlatGroundProperty() {
            return {
                id: null,
                cid: null, // cash id *first assigned id
                type: null,
                name: null,
                isShow: false,
                isOpen: false,
                edit: false,
                panel: {
                  shelf: false,
                  volume: false,
                  cutFlat: false,
                  flatGround: false
                },
                cutVolume: 0,
                fillVolume: 0,
                transparency: 1,
                settings: {
                  commonSettings: {
                    interpolationPitch : 5,
                    withSlope: true,
                  },
                  cutSettings: {
                    height: 7,
                    moundSlope: 1,
                    shelfWidth: 1.5,
                    shelfSlope: 100,
                    generateShelf: true,
                    color: "#FFA500",
                  },
                  embankmentSettings: {
                    height: 5,
                    moundSlope: 1.5,
                    shelfWidth: 1.5,
                    shelfSlope: 100,
                    generateShelf: true,
                    color: "#008000",
                  },
                },
              };
        },
        /**
         * 表示中の点群リストを返却する
         * @param {*} state 
         * @returns PointCloud 表示中の点群リスト
         */
        visiblePointClouds: state => state.point_cloud_list.filter(
          pointCloud => pointCloud.getVisibility()
        ),
        activePointCloudId: state => {
            const pointClouds = state.point_cloud_list.filter(pointCloud => pointCloud.getVisibility())
            const id = pointClouds[0] ? parseInt(pointClouds[0].asset_id) : undefined;
            return id;
        },
        viewer: state => state.viewer,
        isLoading: state => state.isLoading,
        pointCloudCount: state => state.point_cloud_list.length,
        designDataCount: state => state.design_list.length,
        segmentsByRoad: (state) => (roadId) => {
            const roadSettings = state.objects.filter(road => road.id === roadId);
            // const _segments = []
            // // console.log("roadSettings", roadSettings);
            // const roadJSON = JSON.parse(roadSettings[0].json);
            // // console.log("json", roadJSON);
            // if (roadJSON.roadSettings.commonSettings.segmentSettings) {
            //     Array.from(roadJSON.roadSettings.commonSettings.segmentSettings).forEach(seg => _segments.push(
            //         {                        
            //             slopeType: seg.slopeType,
            //             startIndex: seg.startIndex,
            //             endIndex: seg.endIndex,
            //             slope: seg.slope
            //         }
            //     ));
            // }
            // return _segments;
            return roadSettings[0].settings.commonSettings.segmentSettings;
        },
        getRoads: state => {
            return state.objects.filter(o => o.type === 0);
        },
        site: state => state.site,
    },
    modules: {
      addRoadTerrainByAi 
    }
})

export default store