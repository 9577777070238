<template>
  <v-navigation-drawer
    permanent
    :mini-variant.sync="this.mini"
    mini-variant-width="48"
    width="380"
    mobile-breakpoint="0"
    color="panel_sub"
    dark
    clipped
    app
  >
    <v-list-item :class="this.mini ? 'px-0' : ''">
      <v-list-item-avatar v-if="mini" width="48px" class="ma-0">
        <v-icon @click.stop="toggleToolPanelMini()">
          icon-Chevron-Right
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-title>
        <span @click="gotoList()" class="hover-blue">プロジェクト</span>
        <v-icon>icon-Chevron-Right</v-icon>
        {{ this.$store.state.site ? this.$store.state.site.site_name : "" }}
      </v-list-item-title>
      <v-btn icon @click.stop="toggleToolPanelMini()">
        <v-icon> icon-Chevron-Left </v-icon>
      </v-btn>
    </v-list-item>
    <v-tabs
      vertical
      background-color="panel_sub"
      style="width: 100%; height: calc(100vh - 96px)"
    >
      <v-tab class="px-0" @click="homeIconHandler">
        <v-icon> icon-Home </v-icon>
      </v-tab>
      <v-tab class="px-0">
        <v-icon> icon-Setting </v-icon>
      </v-tab>
      <v-tab class="px-0">
        <v-icon> mdi-rotate-45 mdi-ruler </v-icon>
      </v-tab>
      <v-tab-item
        class="panel_main"
        style="height: calc(100vh - 96px); overflow:hidden auto;"
      >
        <v-card class="panel_main" height="100%">
          <v-card-title class="py-1 justify-space-between">
            {{ $t("DATA_LAYERS") }}
            <v-btn 
              :disabled="$store.getters.isLoading"
              class="pa-0"
              height="22px"
              width="22px"
              min-width="0"
              @click="fetchDataList"
              icon
            >
            <v-icon color="white">mdi-sync</v-icon>
          </v-btn>
          </v-card-title>
          <v-card-actions>
            <v-btn
              color="primary"
              rounded
              min-height="31px"
              style="
                margin: 0px auto;
                display: block !important;
                white-space: normal !important;
                height: auto;
              "
              @click="openAddDataDialog()"
              class="py-1"
            >
              <v-icon> icon-Add </v-icon>
              {{ $t("ADD_DATA_LAYER") }}
            </v-btn>
            <v-dialog
              hide-overlay
              style="margin-left: 90%"
              v-model="showAddDataDialog"
              width="40%"
            >
              <add-data-dialog @close="closeAddDataDialog()" />
            </v-dialog>
          </v-card-actions>
          <v-card-actions>
            <v-dialog
              hide-overlay
              style="margin-left: 90%"
              v-model="isExclusion"
              width="18%"
            >
              <exclusivity-notice-dialog 
                @close="closeExclusivityNoticeDialog()"
                :exclusive_obj="exclusive_obj"
              />
            </v-dialog>
          </v-card-actions>
          <!-- 基準地形 -->
          <point-cloud-list></point-cloud-list>
          <!-- 設計データ -->
          <design-list></design-list>
          <!-- 仮設道路 -->
          <v-card-actions>
            <v-btn
              rounded
              min-height="31px"
              style="
                margin: 0px auto;
                display: block !important;
                white-space: normal !important;
                height: auto;
              "
              :width="enableFlatGroundCreation ? '120px' : ''"
              @click="roadCreation"
              :class="isRoadAdd ? `py-1 highlight ${isMultiplePCVisible ? 'multiple-pc' : ''}` : `py-1 Doing ${isMultiplePCVisible ? 'multiple-pc' : ''}`"
              :disabled="isFlatAdd || isAIRoadAdd || isCreateBtnDisabled"
              :title="isMultiplePCVisible ? $t('MULTIPLE_POINT_CLOUD_CANNOT_ADD') : null"
            >
              {{ $t("Addtemporaryroad") }}
            </v-btn>
            <!--Remove Flat Ground Creation button if VUE_APP_ENABLE_FLAT_GROUND_CREATION is not set-->
            <v-btn
              v-if="enableFlatGroundCreation"
              rounded
              width="120px"
              min-height="31px"
              style="
                margin: 0px auto;
                display: block !important;
                white-space: normal !important;
                height: auto;
              "
              @click="flatGroundCreation"
              :class="isFlatAdd ? `py-1 highlight ${isMultiplePCVisible ? 'multiple-pc' : ''}` : `py-1 Doing ${isMultiplePCVisible ? 'multiple-pc' : ''}`"
              :disabled="isRoadAdd || isAIRoadAdd || isCreateBtnDisabled"
              :title="isMultiplePCVisible ? $t('MULTIPLE_POINT_CLOUD_CANNOT_ADD') : null"
            >
              {{ $t("Addflatground") }}
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              v-if="enableAIRoadCreation"
              rounded
              width="256px"
              min-height="31px"
              style="
                margin: 0px auto;
                display: block;
                white-space: normal !important;
                height: auto;
              "
              @click="addRoadByJson"
              :class="isAIRoadAdd ? `py-1 highlight ${isMultiplePCVisible ? 'multiple-pc' : ''}` : `py-1 Doing ${isMultiplePCVisible ? 'multiple-pc' : ''}`"
              :disabled="(isRoadAdd || isFlatAdd || isEpsg4326() || isCreateBtnDisabled)"
              :title="isMultiplePCVisible ? $t('MULTIPLE_POINT_CLOUD_CANNOT_ADD') : null"
            >
              {{ $t("RoadadditionbyAI") }}
            </v-btn>
          </v-card-actions>
          <v-card-actions class="mx-3 my-3">
            {{ $t("globe") }} {{ $t("show") }} / {{ $t("hide") }}
            <v-switch
              v-model="globeVisibility"
              style="position:absolute; right:0;"
              inset
              color="success"
              @change="globeVisibilityChanged()"
            ></v-switch>
          </v-card-actions>
          <v-card-text
            v-for="(obj, r) in this.$store.state.objects"
            :key="`second-${r}`"
            class="pa-0 mx-3"
          >
            <v-card-actions
              class="py-0"
              :style="obj.edit ? 'background-color: #0064BA;' : ''"
            >
              <v-btn icon small @click="toggleVisibility(obj, r)">
                <v-icon>
                  {{ obj.isShow ? "icon-View" : "icon-View-Off" }}
                </v-icon>
              </v-btn>
              <v-btn icon @click="obj.isOpen = !obj.isOpen" class="ml-0">
                <v-icon>
                  mdi-play {{ obj.isOpen ? "mdi-rotate-90" : "" }}
                </v-icon>
              </v-btn>
              <input
                type="text"
                style="width: 100%; color: #ffffff; outline: none"
                readonly
                v-model="obj.name"
              />
              <v-btn @click="fitToView(r)" icon absolute right class="mr-6" :disabled="!obj.isShow">
                <v-icon small>icon-Search</v-icon>
              </v-btn>
              <template>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      absolute
                      right
                      v-bind="attrs"
                      v-on="on"
                      class="pr-0"
                    >
                      <v-icon> icon-Overflow-Menu-Vertical </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="exportByJson(r)" class="list-doing">
                      <v-list-item-icon
                        ><v-icon>icon-Export</v-icon></v-list-item-icon
                      >
                      <v-list-item-title>{{
                        $t("EXPORT_AS")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="enableControlPointDragging(r)"
                      class="list-doing"
                    >
                      <v-list-item-icon
                        ><v-icon>icon-Pencil</v-icon></v-list-item-icon
                      >
                      <v-list-item-title>{{ $t("EDIT") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteObj(r)" class="list-delayed">
                      <v-list-item-icon
                        ><v-icon>icon-Trash-Can</v-icon></v-list-item-icon
                      >
                      <v-list-item-title>{{ $t("REMOVE") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-card-actions>
            <div v-if="obj.type === 0">
              <road-panel
                :road="obj"
                :rules="rules"
              >
              </road-panel>
            </div>
            <div v-else-if="obj.type === 1">
              <flat-ground-panel
                :obj="obj"
                :rules="rules"
              ></flat-ground-panel>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        class="panel_main"
        style="height: calc(100vh - 96px); overflow: auto"
      >
        <v-card class="panel_main">
          <v-card-title class="py-1">
            {{ $t("PROJECT_SETTINGS") }}
          </v-card-title>
          <v-card-text>
            <v-card-subtitle class="px-0">
              {{ $t("DISTANCE_UNITS") }}
            </v-card-subtitle>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td style="width: 40%">
                    {{ $t("DISTANCE") }}
                  </td>
                  <td>{{ $t("METERS_WORD") }}</td>
                </tr>
                <tr>
                  <td>
                    {{ $t("AREA") }}
                  </td>
                  <td>{{ $t("SQUARE_METERS_WORD") }}</td>
                </tr>
                <tr>
                  <td>
                    {{ $t("VOLUME") }}
                  </td>
                  <td>{{ $t("METERS_CUBED_WORD") }}</td>
                </tr>
                <!-- <tr>
                  <td>
                    {{ $t("SLOPE") }}
                  </td>
                  <td>
                    <v-select
                      v-model="unit"
                      :items="unitTypes"
                      item-text="label"
                      item-value="value"
                      dense
                      @change="this.changeSlopeUnit"
                      hide-details
                      class="px-1"
                    ></v-select>
                  </td>
                </tr> -->
              </tbody>
            </v-simple-table>
            <v-card-subtitle class="px-0">
              {{ $t("COORDINATE_SYSTEM") }}
            </v-card-subtitle>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>rotation</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.rotation, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_easting</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.origin_easting, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_northing</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.origin_northing, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_latitude</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.origin_latitude, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_longitude</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.origin_longitude, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>scale_factor</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.scale_factor, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>vertical_offset</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.vertical_offset, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>incline_x</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.incline_x, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>incline_y</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.incline_y, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>toMeters</td>
                  <td>
                    {{ truncateDecimals(siteInfo?.to_meters, 5) }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 40%">
                    COORDINATE_SYSTEM
                  </td>
                  <td>
                    {{ siteInfo?.epsg }}
                  </td>
                </tr>
                <tr>
                  <td>VERTICAL_SHIFT</td>
                  <td>
                    {{ siteInfo?.epsg_v }}
                  </td>
                </tr>                
              </tbody>
            </v-simple-table>
            <v-btn
              class=".rounded-lg mr-5"
              color="#ff5050"
              width="115px"
              height="25px"
              depressed
              @click="showFileUploadDialog()">
              {{ $t("CIW0020000071") }}
              </v-btn>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        class="panel_main"
        style="height: calc(100vh - 96px); overflow:hidden auto;"
      >
        <v-card class="panel_main" height="100%">
          <v-btn
            class="mt-6 mx-auto py-1"
            width="180px"
            color="primary"
            min-height="31px"
            style="
              display: block !important;
              white-space: normal !important;
              height: auto;
            "
            @click="toggleMeasurementMode"
          >
            {{ $t("CREATE_LINEAR") }}
          </v-btn>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-dialog hide-overlay v-model="isFileDialogOpen" width="500px" v-if="isFileDialogOpen">
      <local-file-upload @close="closeFileUploadDialog" />
    </v-dialog>
  </v-navigation-drawer>
</template>


<script>
import "@/assets/icons/style.css";
import { LandXML } from "earthbrain-viewer";
import AddDataDialog from "./AddDataDialog.vue";
import FlatGroundPanel from "./ObjectPanels/FlatGroundPanel.vue"
import RoadPanel from "./ObjectPanels/RoadPanel.vue"

import {uploadRoadObj, uploadFlatGroundObj, updateRoadSettingsInViewer} from "../../utils/cesium-common"

import ExclusivityNoticeDialog from "./ExclusivityNoticeDialog.vue";

import axios from "axios";
import PointCloudList from "./PointCloudList";
import DesignList from "./DesignList";
import { UnitTypes } from "../../constant";
import rules from '@/utils/validation-rules.js';
import { mapState, mapMutations, mapActions } from 'vuex';
import * as cesiumCommon from '../../utils/cesium-common';
import dayjs from "dayjs";
import LocalFileUpload from "../AddData/LocalFileUpload.vue";
import config from "@/config.json";
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))

export default {
  name: "ToolPanel",
  components: {
    AddDataDialog,
    PointCloudList,
    DesignList,
    ExclusivityNoticeDialog,
    LocalFileUpload,
    FlatGroundPanel,
    RoadPanel,
  },
  mounted() {
    window.addEventListener("deletePointEvent", ({detail:{roadId, pointIndex}}) => {
      console.log(roadId, pointIndex)
      const roadSettings = this.$store.state.objects.filter((obj => obj.cid === roadId));

      let isDeleteFound = false;
      for (const seg of roadSettings[0].settings.commonSettings.segmentSettings) {
        if (isDeleteFound) {
          seg.startIndex--;
          seg.endIndex--;
        }
        else {
          if(seg.endIndex === pointIndex){
            seg.endIndex--;
          }
          if(seg.startIndex<=pointIndex && seg.endIndex >= pointIndex){
            isDeleteFound = true;
            seg.endIndex--;
          }          
        }
      }
      roadSettings[0].settings.commonSettings.segmentSettings = roadSettings[0].settings.commonSettings.segmentSettings.filter(seg=>seg.startIndex-seg.endIndex !== 0);

      // const d = JSON.parse(JSON.stringify(roadSettings[0].settings));
      updateRoadSettingsInViewer(roadId, roadSettings[0]);
      // window["viewer"].updateRoadSettings(roadId, d);
      uploadRoadObj(roadSettings[0]);

    });

    window.addEventListener("addPointEvent", ({detail:{roadId, pointIndex}}) => {
      console.log(roadId, pointIndex)
      const roadSettings = this.$store.state.objects.filter((obj => obj.cid === roadId));
      for (const seg of roadSettings[0].settings.commonSettings.segmentSettings) {
        if(seg.startIndex >= pointIndex){
          seg.startIndex++;
        }
        if(seg.endIndex >= pointIndex){
          seg.endIndex++;
        }
      }
      // const d = JSON.parse(JSON.stringify(roadSettings[0].settings));
      updateRoadSettingsInViewer(roadId, roadSettings[0]);
      // window["viewer"].updateRoadSettings(roadId, d);
      uploadRoadObj(roadSettings[0]);


    });

    this.$root.$on("end-create-mode", () => {      
        this.isFlatAdd = false;
        this.isRoadAdd = false;
    });

    this.$root.$on("cancel-create-mode", () => {      
      if (this.isRoadAdd) this.cancelRoadCreation();
      else if (this.isFlatAdd) this.cancelFlatGroundCreation();
    });
  },
  data: () => ({
    site: "現場名(モック)",
    config,
    mini: false,
    showAddDataDialog: false,
    showJsonDialog: false,
    showStartEndPointConfirmDialog: false,
    // isValid: false,
    isRoadAdd: false,
    isAIRoadAdd: false,
    isFlatAdd: false,
    isStartEndPointConfirm: false,
    globeVisibility: true,
    globeVisibilityBeforeCreationMode: false,
    disableRoadUpdate: false,
    rules: {
          ...rules,
          required: value => !!value || '*',
          fgValidInterpolation: v=>parseFloat(v) >0
        },
    exclusive_obj: null,
    enableFlatGroundCreation: process.env.VUE_APP_ENABLE_FLAT_GROUND_CREATION === 'true',
    enableAIRoadCreation: process.env.VUE_APP_ENABLE_AI_ROAD_CREATION === 'true',
    isFileDialogOpen: false,
  }),
  computed: {
    ...mapState(['modes']),
    siteInfo() {
      return this.$store.getters.site;
    },
    unitTypes() {
      return UnitTypes.map(t=> ({label: this.$t(t.label), value: t.value}));
      // [
      //   {
      //     label: "Percent",
      //     value: "percent",
      //     symbol: "%"

      //   },
      //   {
      //     label: this.$t("RATIO_WORD"),
      //     value: "ratio",
      //     symbol: "1/n"
      //   },
      //   {
      //     label: "Degree",
      //     value: "degree",
      //     symbol: "°",
      //   },
      // ];
    },
    isCreateBtnDisabled() {
      return this.$store.getters.visiblePointClouds.length !== 1;
    },
    isMultiplePCVisible() {
      return this.$store.getters.visiblePointClouds.length > 1;
    },
    isExclusion() {
      return this.$store.state.isExclusion;
    },
    // unit() {
    //   return this.$store.state.slopeUnit
    // },
    cutSettingSlopeUnit() {
      return this.$store.state.cutSettingSlopeUnit;
    },
    fillSettingSlopeUnit() {
      return this.$store.state.fillSettingSlopeUnit;
    },
  },
  watch: {
    isRoadAdd(val) {
      if (val) this.isFlatAdd = false;
    },
    isFlatAdd(val) {
      if (val) this.isRoadAdd = false;
    },
    showJsonDialog(val) {
      val && this.disableAllEdits()
      this.isAIRoadAdd = val;
    }
  },
  methods: {
    ...mapMutations(['set_snackbar' ,'reset_ai_road_preview_data','set_modes', 'set_initial_site_data']),
    ...mapActions(['get_point_cloud_list', 'get_design_list', 'get_obj_list']),
    /**
     * 小数点以下の指定した桁数nを超える部分を切り捨てる
     * @param {*} num フォーマット対象の数字
     * @param {*} n 小数点以下の桁数
     */
     truncateDecimals(num, n) {
      if (typeof num === 'number'){
        num = num.toString();
      }

      const regex = /^[+-]?\d+(\.\d+)?$/;
      // フォーマット対象が数値形式かチェック
      if (regex.test(num)) 
      {
        // 小数点以下の桁数がnより多い場合は余分な桁数を切り捨てる
        if (num.indexOf('.') !== -1 && num.split('.')[1].length > n) {
          return parseFloat(num).toFixed(n);
        } 
        // 小数点なし or 小数点以下の桁数がn以下の数値は形式だけ変換する
        return parseFloat(num);
      }
      // 数値でない場合はそのまま返す
      return num;
    },
    showFileUploadDialog() {
      this.isFileDialogOpen = true;
    },  
    closeFileUploadDialog() {
      this.isFileDialogOpen = false;
    },    
    async fetchDataList() {
      // スピナーを表示する
      this.$store.dispatch('updateIsLoading', true);
      try {
        await Promise.all([
          this.get_point_cloud_list(this.$route.query.siteId),
          this.get_design_list(this.$route.query.siteId)
        ]);
      } catch (e) {
        console.error(e);
      } finally {
        // スピナーを非表示にする
        this.$store.dispatch('updateIsLoading', false);
      }
    },
    globeVisibilityChanged() {
      window["viewer"].toggleGlobeVisibility(this.globeVisibility);
    },
    isEpsg4326() {
      return (this.$store.state.site && this.$store.state.site.epsg === '4326')? true : false;
    },
    addRoadByJson() {
      const dummyId = 1;
      const event = new CustomEvent("openPropertyDialog", {
          detail: { id: dummyId, type: 2 },
        });
        window.dispatchEvent(event);
    },
    startEndPointConfirm() {
      this.isStartEndPointConfirm = true;
      this.showStartEndPointConfirmDialog = true;
    },
    closeJsonDialog(dialogData) {
      let points = [];
      this.$store.state.modes.inCreationMode = false;
      if (dialogData) {
        this.isAIRoadAdd = false;
        this.showJsonDialog = false;
        var file_to_read = dialogData;
        var fileread = new FileReader();
        const epsg = this.$store.state.site.epsg;
        const auth = this.$store.state.authObject ;

        fileread.onload = (e) => {
          var content = e.target.result;
          var intern = JSON.parse(content);
          intern.controlPoints.forEach((item) => {
            points.push(item.position);
          });
          axios
            .post(
              `${process.env.VUE_APP_API_BASE}/pointcloud/processroadpoints`,
              { points, epsg},
              {
                auth
              }
            )
            .then((res) => {
              console.log(res.data);
              window["viewer"].createRoadFromPoints(
                res.data.points,
                (id) => {
                  const event = new CustomEvent("openPropertyDialog", {
                    detail: { id: id, type: 0 },
                  });
                  window.dispatchEvent(event);
                },
                this.$store.getters.activePointCloudId
              );
            });
        };
        fileread.readAsText(file_to_read);
      } else {
        this.isAIRoadAdd = false;
        this.showJsonDialog = false;
      }
    },
    closeStartEndPointConfirmDialog(dialogData) {
      let points = [];
      if (dialogData) {
        this.isStartEndPointConfirm = false;
        this.showStartEndPointConfirmDialog = false;
        var file_to_read = dialogData;
        var fileread = new FileReader();
        const epsg = this.$store.state.site.epsg;
        const auth = this.$store.state.authObject;

        fileread.onload = (e) => {
          var content = e.target.result;
          var intern = JSON.parse(content);
          intern.controlPoints.forEach((item) => {
            points.push(item.position);
          });
          axios
            .post(
              `${process.env.VUE_APP_API_BASE}/pointcloud/processroadpoints`,
              { points, epsg},
              {
                auth
              }
            )
            .then((res) => {
              console.log(res.data);
              window["viewer"].createRoadFromPoints(
                res.data.points,
                (id) => {
                  const event = new CustomEvent("openPropertyDialog", {
                    detail: { id: id, type: 0 },
                  });
                  window.dispatchEvent(event);
                },
                this.$store.getters.activePointCloudId
              );
            });
        };
        fileread.readAsText(file_to_read);
      } else {
        this.isStartEndPointConfirm = false;
        this.showStartEndPointConfirmDialog = false;
      }
    },
    toggleToolPanelMini() {
      this.mini = !this.mini;
    },
    updateGlobeVisibility(visibility) {
      this.globeVisibility = visibility;
      window["viewer"].toggleGlobeVisibility(visibility);
    },
    disableAllEdits() {
      if (this.$store.state.modes.inEditMode == true) {
        this.$store.state.modes.inEditMode = false;
        this.$store.state.objects.forEach((obj) => {
          if (obj.edit) {
            obj.edit = false;
            if (obj.type === 0) {
              window["viewer"].changeRoadIn2DViewer(obj.id);
              window["viewer"].enableRoadEdit(obj.edit, obj.id, ()=>{});
            } else {
              this.$store.state.modes.inFlatGroundHoriDrag = true;
              window["viewer"].toggleFlatGroundPointDrag(obj.edit, obj.id);
              window["viewer"].toggleFlatGroundPointVerticalDrag(obj.edit, obj.id);
            }
          }
        });
        window["viewer"].clearCommandManager();
      }
    },
    // road
    roadCreation() {
      this.isRoadAdd = !this.isRoadAdd;
      if (this.isRoadAdd) {
        this.disableAllEdits();
        this.globeVisibilityBeforeCreationMode = this.globeVisibility;
        this.updateGlobeVisibility(false);
        this.$store.state.modes.inCreationMode = true;
        window["viewer"].enablePathCreation(
          (id) => {
            const event = new CustomEvent("openPropertyDialog", {
              detail: { id: id, type: 0 },
            });
            console.log(this.globeVisibilityBeforeCreationMode);
            this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(true);
            window.dispatchEvent(event);
          },
          this.$store.getters.activePointCloudId
        );
      } else {
        this.cancelRoadCreation()
      }

    },
    cancelRoadCreation() {
        this.$store.state.modes.inCreationMode = false;
        this.isRoadAdd = false;
        window["viewer"].cancelRoadCreation();
        this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(true);
    },
    //平場
    flatGroundCreation() {
      this.isFlatAdd = !this.isFlatAdd;
      if (this.isFlatAdd) {
        this.disableAllEdits();
        this.globeVisibilityBeforeCreationMode = this.globeVisibility;
        this.$store.state.modes.inCreationMode = true;
        this.updateGlobeVisibility(false);
        window["viewer"].enablePolygonCreation(
          this.isFlatAdd,
          (id) => {
            const event = new CustomEvent("openPropertyDialog", {
              detail: { id: id, type: 1 },
            });
            this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(true);
            window.dispatchEvent(event);
          },
          this.$store.getters.activePointCloudId
        );
      } else {
        this.cancelFlatGroundCreation()
      }
    },
    cancelFlatGroundCreation() {
        this.$store.state.modes.inCreationMode = false;
        this.isFlatAdd = false;
        window["viewer"].cancelFlatGroundCreation();
        this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(true);
    },
    async enableControlPointDragging(index) {
      try {
        //排他制御チェック
        const isEditByOther = await this.exclusive_check();
        if(isEditByOther) {
          this.$store.commit('set_exclusion', true);
          return false;
        }
        if (this.$store.state.modes.inCreationMode == true) {
          this.$store.state.modes.inCreationMode = false;
          window["viewer"].clearCommandManager();
          if (this.isRoadAdd) {
            this.isRoadAdd = false;
            window["viewer"].cancelRoadCreation();
            this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(true);
          }
          if (this.isFlatAdd) {
            this.isFlatAdd = false;
            window["viewer"].cancelFlatGroundCreation();
            this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(true);
          }
        }
        const obj = this.$store.state.objects[index];
        obj.edit = !obj.edit;
        if (obj.edit) {
          this.$store.state.modes.inEditMode = true;
          window["viewer"].clearCommandManager();
          // Need to clear altitude view and section view by default
          // Reason: there are few cases where altitude view and section view is not clear when flat ground edit called.
          window["viewer"].clear2DViewes();
          this.$store.state.objects.forEach((obj, i) => {
            if (i === index) return;
            if (obj.edit) {
              obj.edit = false;
              if (obj.type === 0) {
                window["viewer"].changeRoadIn2DViewer(obj.id);
                window["viewer"].enableRoadEdit(obj.edit, obj.id, () => { uploadRoadObj(obj) });
                this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(!obj.edit);
              } else {
                this.$store.state.modes.inFlatGroundHoriDrag = true;
                window["viewer"].toggleFlatGroundPointDrag(obj.edit, obj.id, () => {  uploadFlatGroundObj(obj) });
                window["viewer"].toggleFlatGroundPointVerticalDrag(obj.edit, obj.id);
                this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(!obj.edit);
              }
            }
          });
        } else {
          this.$store.state.modes.inEditMode = false;
        }
        if (!obj.isShow && obj.edit) {
          this.toggleVisibility(obj, index);
        }
        if (obj.type === 0) {
          window["viewer"].changeRoadIn2DViewer(obj.id);
          window["viewer"].enableRoadEdit(obj.edit, obj.id, () => {
            //update segments range values 
            uploadRoadObj(obj);        
            this.updatePathInfoInStore(obj);
          });
          this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(!obj.edit);
        } else {
          window["viewer"].toggleFlatGroundPointDrag(obj.edit, obj.id, () => { uploadFlatGroundObj(obj) });
          this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(!obj.edit);
          if (obj.edit === false) {
            this.$store.state.modes.inFlatGroundHoriDrag = true;
            window["viewer"].toggleFlatGroundPointVerticalDrag(obj.edit, obj.id, () => { uploadFlatGroundObj(obj) });
            this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(!obj.edit);
          }
        }
        await this.update_site_info();
        const updateInfo = await this.get_site_info();
        // stateをかきかえる
        this.$store.commit('set_initial_site_datas', updateInfo)
      } catch (error) {
        console.error(error.message);
      }
    },
    updatePathInfoInStore(obj) {
      if (obj.settings.commonSettings.segmentSettings && obj.settings.commonSettings.segmentSettings.length > 0) {
        for (const segmentSetting of obj.settings.commonSettings.segmentSettings) {
          segmentSetting.heightRange = window["viewer"].getRoadHeightInfoOfSection(obj.id, segmentSetting.startIndex, segmentSetting.endIndex);
          segmentSetting.slopeRange = window["viewer"].getRoadSlopeInfoOfSection(obj.id, segmentSetting.startIndex, segmentSetting.endIndex);
        }
      }
      const lengthOfRoad = window["viewer"].getLengthOfRoad(obj.id);
      if (lengthOfRoad) {
        obj.info.length = parseFloat(lengthOfRoad.toFixed(3));
      }
      obj.info.height = window["viewer"].getRoadHeightInfo(obj.id);
      obj.info.slope = window["viewer"].getRoadSlopeInfo(obj.id);

      // this.$store.commit("setObj", this.$store.state.objects);      
    },
    async deleteObj(id) {
      try {
        const obj = this.$store.state.objects[id]
        //排他制御チェック
        const isEditByOther = await this.exclusive_check();
        if(isEditByOther) {
          this.$store.commit('set_exclusion', true)
          return false;
        }
        if (obj.type === 0) {
          window["viewer"].deleteRoad(obj.id);
          axios
            .delete(
              `${process.env.VUE_APP_API_BASE}/obj/?siteId=${this.$route.query.siteId}&cid=${obj.cid}`,
              {
                auth: this.$store.state.authObject
              }
            )
            .then(() => {
              console.log("delete success");
            });
        } else {
          window["viewer"].removePolygon(obj.id);
          axios
            .delete(
              `${process.env.VUE_APP_API_BASE}/obj/?siteId=${this.$route.query.siteId}&cid=${obj.cid}`,
              {
                auth: this.$store.state.authObject,
              }
            )
            .then(() => {
              console.log("delete success");
            });
        }
        this.$store.state.objects.splice(id, 1);
        await this.update_site_info();
        const updateInfo = await this.get_site_info();
        // stateをかきかえる
        this.$store.commit('set_initial_site_datas', updateInfo)
      } catch (error) {
        console.error(error.message);
      }
    },
    fitToView(id) {
      const item = this.$store.state.objects[id];
      this.$store.state.objects.forEach((obj, i) => {
        if (obj.id != item.id && obj.edit)
          this.enableControlPointDragging(i);
      });
      if (item.type == 0) {
        window["viewer"].fitToRoad(item.id);
      } else if (item.type == 1) {
        window["viewer"].fitToFlatGround(item.id);
      }
    },
    isNum(val) {
      return typeof val == "number";
    },
    exportByJson(index) {
      const obj = this.$store.state.objects[index]
      const event = new CustomEvent("openPropertyDialog", {
          detail: { id: obj.id, type: 6, pointId: index, name: obj.name },
        });
        window.dispatchEvent(event);
    },
    async exportLandXML(index) {
      const obj = this.$store.state.objects[index];
      const site = this.$store.state.site;
      if (obj.id === null) return;
      let geometry;
      if (obj.type === 0) {
        geometry = window["viewer"].getRoadMeshGeometry(obj.id)
      } else {
        geometry = window["viewer"].getFlatGroundMeshGeometry(obj.id);
      }
      if (geometry) {
        try {
          let response = await axios.post(
            `${process.env.VUE_APP_API_BASE}/pointcloud/convertGeometryVertices`,
            { points: geometry.vertices, epsg: this.$store.state.site.epsg },
            {
              auth: this.$store.state.authObject
            }
          )
          LandXML.exportMesh(response.data.points, geometry.indices, site.site_name, this.createFileName(new Date(), obj.name, site.site_name));          
        } catch (error) {
          console.error(error.message);
        }
      } else {
        console.error("Please check if the entity is created");
      }
    },
    createFileName(date, objName, siteName) {
      return (
        [
          date.getFullYear(),
          this.padSingleDigit(date.getMonth() + 1),
          this.padSingleDigit(date.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padSingleDigit(date.getHours()),
          this.padSingleDigit(date.getMinutes()),
          this.padSingleDigit(date.getSeconds()),
        ].join('_') +
        `_${siteName}_${objName}`
      );
    },
    padSingleDigit(num) {
      return num.toString().padStart(2, '0');
    },
    lang(lang) {
      this.$i18n.locale = lang;
    },
    openAddDataDialog() {
      this.showAddDataDialog = true;
    },
    closeAddDataDialog() {
      this.showAddDataDialog = false;
    },
    closeExclusivityNoticeDialog() {
      this.$store.commit('set_exclusion', false)
    },
    gotoList() {
      this.updateGlobeVisibility(true);
      const event = new Event("popstate");
      window.dispatchEvent(event);
    },
    toggleVisibility(obj, index) {
      obj.isShow = !obj.isShow;
      if (obj.edit && !obj.isShow) {
        this.enableControlPointDragging(index);
      }
      let activePointCloudId = this.$store.getters.activePointCloudId;
      if (obj.type === 0) {
        const  associatePointCloudId = JSON.parse(obj.json).associatePointCloudId;
        let isLoadedWithoutPointCloud = false;
        if(associatePointCloudId) {
          const pointCloud = this.$store.getters.visiblePointClouds.find(p => parseInt(p.asset_id) === associatePointCloudId);
          isLoadedWithoutPointCloud = !pointCloud;
        }
        cesiumCommon.toggleRoadVisibility(obj, activePointCloudId, isLoadedWithoutPointCloud);
      } else if (obj.type === 1) {
        cesiumCommon.toggleFlatGroundVisibility(obj, activePointCloudId);
      }
    },
    deleteAllObjFromCesium() {
      this.$store.state.objects.forEach((item) => {
        if (item.type === 0) {
          window["viewer"].deleteRoad(item.id);
        } else {
          window["viewer"].removePolygon(item.id);
        }
      });
      this.$store.commit("initObj");
    },
    escapeKeyDown(event) {
      if (event.key == "Escape") {
        this.isRoadAdd = false;
        this.isFlatAdd = false;
        this.globeVisibilityBeforeCreationMode && this.updateGlobeVisibility(true);
      }
    },
    changeDesignTransparency(design) {
      window["viewer"].updateTilesetTransparency(parseInt(design.id), "design", design.transparency);
    },
    toggleDesignVisibility(design) {
      design.isShow = !design.isShow
      this.changeDesignTransparency(design);
      window["viewer"].updateTilesetVisibility(parseInt(design.id), "design", design.isShow);
    },
    toggleMeasurementMode(){
      this.disableAllEdits();
      window["viewer"].checkRoadUnderCreation() && this.cancelRoadCreation();
      window["viewer"].checkFlatGroundUnderCreation() && this.cancelFlatGroundCreation();
      this.disableAllEdits();
      window["viewer"].enableMeasurement(true, "pointToPoint");
    },
    homeIconHandler() {
      if(this.measurementMode) {
       window["viewer"].enableMeasurement(false, "pointToPoint");
      }
    }
    ,
    async get_site_info() {
      const res = await axios.get(`${process.env.VUE_APP_API_BASE}/sites/${this.$route.query.siteId}`, {
        auth: this.$store.state.authObject,
        withCredentials: false      
      });
      return res ? res.data : null;
    },
    async exclusive_check() {
      let edit_site_data = await this.get_site_info();

      const isUpdated = dayjs(this.$store.state.initial_site_data.updated_at).isBefore(edit_site_data.updated_at);

      // 現場が更新されていなければ排他制御を行わない
      if (!isUpdated) return false;

      // 自分以外が現場を更新していれば排他制御を行う
      return edit_site_data.updated_by !== this.$store.state.user.id
    },
    // サイト情報更新
    async update_site_info() {
      const response = await axios.put(`${process.env.VUE_APP_API_BASE}/sites/touch/${this.$route.query.siteId}`, {
        auth: this.$store.state.authObject,
        withCredentials: false      
      });
      console.log('udpateSiteInfo',response);
    },
  },
  async created() {
    try {
      window.addEventListener("keydown", this.escapeKeyDown);
      const res = await this.get_site_info();
      this.$store.commit('set_initial_site_datas', res);
    } catch (error) {
      console.error(error.message);
    }
  },
  destroyed() {
    console.log("destroy all road");
    this.deleteAllObjFromCesium();
    window.removeEventListener("keydown", this.escapeKeyDown);
     this.set_modes({
      name: "ai_road_point_selection",
      value: false,
    });
    window["viewer"].removeAIRoadPreview();
  },
};
</script>

<style scoped>
.v-tab {
  min-width: 48px !important;
  width: 48px;
}

td {
  padding: 0px 8px 0px 8px !important;
}

.hover-blue:hover {
  color: #1976d2;
}

.highlight {
  background-color: #004f93 !important;
  border: #ffffff solid 1px;
}

.Doing {
  background-color: #0064ba !important;
  border: #0064ba solid 2px;
}

.Doing:hover {
  background-color: #0059a6 !important;
  border: #0059a6 solid 2px;
}

.list-doing:hover {
  background-color: #0064ba !important;
}

.list-delayed:hover {
  background-color: #b90007;
}

.v-btn--disabled.multiple-pc {
  pointer-events: unset !important;
}
</style>