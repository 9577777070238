
<template>
  <v-card class="confirm">
    <v-card-title class="pt-2 justify-space-between">
      <span class="text-h5">{{$t("EXCLUSIVE_EDIT")}}</span>
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-0" style="color: white">
      <p class="text-h6">
        {{editingUser}}{{$t("EXCLUSIVE_NOTICE")}}
      </p>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 pr-5 justify-end">
      <v-btn class="pa-3 mr-2 btn" color="primary" width="125px" height="45px" @click="$emit('close')">
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
export default {
  components: {
    CloseButton
  },
  props: {
    exclusive_obj: Object
  },
  data() {
    return {};
  },
  created() {
    console.log("state",this.$store.state.initial_site_data)
  },
  computed: {
    editingUser() {
      return this.$store.state.initial_site_data.updated_name;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
};
</script>
<style lang="sass" scoped>
.confirm
  .v-card__title
    font-size: 16px

.confirm .btn
    font-size: 13px

.confirm
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-input__slot
  .v-text-field__slot
    .v-label
      color: gray
      opacity: 0.8

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

</style>